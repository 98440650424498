import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import { IVariantOptions } from '../../types';

export enum ProductLinkLoc {
  Cart = 'cart',
  Accessories = 'accessories',
  Collar = 'collar',
}

interface IProductLinkProps extends Omit<LinkProps, 'to'> {
  productId: string;
  from?: ProductLinkLoc;
  variantOptions?: IVariantOptions;
}

export const ProductLink = ({ productId, from, variantOptions, children, ...otherProps }: IProductLinkProps) => {
  let search: string | undefined;
  if (variantOptions) {
    search = `?color=${variantOptions.color}&size=${variantOptions.size}`;
  }

  return (
    <Link
      to={{
        pathname: AppPaths.Products(productId),
        search,
        state: { from },
      }}
      {...otherProps}
    >
      {children}
    </Link>
  );
};
