import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as types from '../types';

export const petContextInitialState: types.Series3UpgradePetContext = {};

const name = 'series3UpgradePetContext' as const;

export const { actions: petContextActions, reducer: petContextReducer } = createSlice({
  name,
  initialState: petContextInitialState,
  reducers: {
    setPetId(context: types.Series3UpgradePetContext, { payload }: PayloadAction<string | undefined>) {
      return {
        ...context,
        petId: payload,
        preSelectedSubscriptionSku: undefined,
      };
    },

    setPreSelectedSubscriptionSku(context: types.Series3UpgradePetContext, { payload }: PayloadAction<string>) {
      return {
        ...context,
        preSelectedSubscriptionSku: payload,
      };
    },

    setInAppCancellationFlow(context: types.Series3UpgradePetContext, { payload }: PayloadAction<boolean>) {
      return {
        ...context,
        inAppCancellationFlow: payload,
      };
    },

    resetContext() {
      return { ...petContextInitialState };
    },
  },
});
