import React from 'react';
import styles from './NotFound.module.scss';

export default function NotFound() {
  return (
    <div className={styles.container}>
      <h1>Not Found</h1>
      <p>
        We weren't able to find the page you're looking for. Please reach out to{' '}
        <a href="mailto:support@tryfi.com">support@tryfi.com</a> for assistance.
      </p>
    </div>
  );
}
