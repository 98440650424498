import React, { useMemo } from 'react';
import { Series } from '../../types/Series';
import SeriesToggleButton from './SeriesToggleButton';
import styles from './SeriesToggle.module.scss';

interface ISeriesToggleProps {
  selectedSeries: Series;
  onSeriesChange(changes: Series): void;
  seriesOptionSet?: Set<Series>; // Limit the series options to show to a subset of all Series enum values
}

const SeriesToggle = ({ selectedSeries, onSeriesChange, seriesOptionSet }: ISeriesToggleProps) => {
  const seriesElements = useMemo(
    () =>
      Object.keys(Series).map((seriesKey) => {
        const seriesValue = Series[seriesKey as keyof typeof Series];
        if (seriesOptionSet && !seriesOptionSet.has(seriesValue)) {
          return null;
        }

        const selected = seriesValue === selectedSeries;
        return (
          <SeriesToggleButton
            index={seriesKey}
            key={seriesKey}
            value={seriesValue}
            selected={selected}
            onClick={(newSeries) => onSeriesChange(newSeries)}
          />
        );
      }),
    [onSeriesChange, selectedSeries, seriesOptionSet],
  );

  return <div className={styles.seriesToggle}>{seriesElements}</div>;
};

export default SeriesToggle;
