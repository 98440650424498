import * as React from 'react';
import styles from './OrderConfirmedBanner.module.scss';
import { ReactComponent as CheckmarkCircle } from '../../../../../assets/images/check-yellow-bg.svg';
import { ReactComponent as Checkmark } from '../../../../../assets/images/icons/check.svg';
import classNames from 'classnames';

export enum SupplementsPDP {
  SUBSCRIBE_AND_SAVE, // Subscribe and save version of the supplements PDP. Offers purchase of a single bag of supplements.
  ORIGINAL, // Existing/original version of the supplements PDP that only offers subscriptions for purchase.
}

export interface OrderConfirmedBannerProps {
  page: SupplementsPDP;
  email?: string;
}

// Static component for supplements page/experiment
export default function OrderConfirmedBanner({ page, email }: OrderConfirmedBannerProps) {
  return (
    <>
      <div className={classNames(styles.orderConfirmedBanner, { [styles.original]: page === SupplementsPDP.ORIGINAL })}>
        {page === SupplementsPDP.ORIGINAL && <CheckmarkCircle />}
        <div className={styles.text}>
          <h2 className={styles.confirmedText}>
            {page === SupplementsPDP.SUBSCRIBE_AND_SAVE && <Checkmark />}Your order is confirmed!
          </h2>
          <p className={styles.emailText}>Be on the lookout for an email {email && `at ${email}`} for tracking.</p>
        </div>
      </div>
    </>
  );
}
