import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getProductsAndVariantsBySku } from '../lib/product';
import * as types from '../types';

export type ProductSkuMap = Map<string, { product: types.IProduct; variant?: types.IVariant }>;
export default function useProductSkuMap(): ProductSkuMap {
  const products = useSelector((state: types.AppState) => state.config.products);
  return useMemo(() => getProductsAndVariantsBySku(products), [products]);
}
