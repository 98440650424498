import { useMemo, useState } from 'react';
import * as types from '../../../types';

function defaultForSubscriptions(subscriptionProducts: types.ISubscriptionProduct[]) {
  const mostPopular = subscriptionProducts.find((subscriptionProduct) => subscriptionProduct.mostPopular);
  return mostPopular ?? subscriptionProducts[0];
}

export default function useSubscriptionSelector(
  subscriptionProducts: types.ISubscriptionProduct[],
  preselectedSubscriptionSku?: string,
  requireSubscriptionSelection?: boolean,
) {
  const [selectedSubscriptionSku, setSelectedSubscriptionSku] = useState<string | undefined>(
    preselectedSubscriptionSku ?? defaultForSubscriptions(subscriptionProducts).sku,
  );
  const [hasUpdatedSubscriptionSku, setHasUpdatedSubscriptionSku] = useState<boolean>(
    !!preselectedSubscriptionSku && !requireSubscriptionSelection,
  );

  const selectSubscriptionSku = (sku: string | undefined) => {
    setSelectedSubscriptionSku(sku);
    setHasUpdatedSubscriptionSku(true);
  };

  const selectedSubscription = useMemo(() => {
    if (!selectedSubscriptionSku) {
      return undefined;
    }

    return subscriptionProducts.find((subscriptionProduct) => subscriptionProduct.sku === selectedSubscriptionSku);
  }, [selectedSubscriptionSku, subscriptionProducts]);

  return {
    selectedSubscription,
    selectSubscriptionSku,
    hasUpdatedSubscriptionSku,
  };
}
