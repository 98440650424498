import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import CurrentSubscription from '../components/CurrentSubscription';
import ManageSubscriptionButtons from './components/ManageSubscriptionButtons';
import S3UpgradeSplash from './components/S3UpgradeSplash/S3UpgradeSplash';
import { useDispatch } from 'react-redux';
import { petContextActions } from '../../../../reducers/series3UpgradePetContext';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { purchaseSubscriptionDeviceDetails } from '../../../../types/gql-op-types';
import { getSubscriptionCategory } from '../util/helpers';

/**
 * @returns true if this device has redeemed an upgrade and awaiting activation of their new device
 */
function isDeviceAwaitingUpgradeActivation(device: purchaseSubscriptionDeviceDetails): boolean {
  if (!device.upgradeRedeemed) {
    return false;
  }

  // purchaseableSubscriptionOptions will only have subscription plans that match the device series.
  // When the customer redeems an upgrade for this device, we assign the new subscription to their
  // old device while they wait for their new device to arrive. So if the user is awaiting upgrade activation,
  // the current subscription for this device will be on a plan not typically available for purchase on this device.
  const purchasableSubscriptionSkus = device.purchasableSubscriptionOptions.map((option) => option.sku);
  const currentSubscriptionSku = device.subscription?.subscriptionOption?.sku;
  const hasUpgradedSubscription =
    !!currentSubscriptionSku && !purchasableSubscriptionSkus.includes(currentSubscriptionSku);

  return hasUpgradedSubscription;
}

export default function SubscriptionSplash() {
  const dispatch = useDispatch();
  dispatch(petContextActions.setInAppCancellationFlow(true));
  const { device } = usePurchaseSubscriptionContext();
  const currentSubscriptionCategory = getSubscriptionCategory(device.subscription?.subscriptionOption.renewalMonths);

  // If a device has been upgraded, don't let the user make any further modifications to their
  // subscription. Without this, users can get into a state where they have upgraded to S3, but
  // then modify their old S2 subscription and downgrade their subscription back to S2.
  const awaitingUpgradeActivation = isDeviceAwaitingUpgradeActivation(device);

  // If device is in a renewable state, user can renew and we show canceled design states.
  const canRenew = !!device.subscriptionRenewalStatus?.shouldOfferSubscriptionRenewal;

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          title={'Membership'}
          backButtonExitsWebview={true}
          backButtonAnalyticsEvent={() => events.manageMembershipBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.manageMembershipClose({ petId: device.pet?.id })}
        />
        {awaitingUpgradeActivation ? (
          <>
            <div className={styles.subscriptionContainer}>
              <p>
                This subscription has been upgraded to Series 3. You cannot make further modifications at this time.
                Once you receive and activate your Series 3 collar you will be able to manage your membership.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={styles.subscriptionContainer}>
              <h1>Your current plan</h1>
              <CurrentSubscription canRenew={canRenew} />
              <ManageSubscriptionButtons canRenew={canRenew} subscriptionCategory={currentSubscriptionCategory} />
              <S3UpgradeSplash />
            </div>
          </>
        )}
      </div>
    </>
  );
}
