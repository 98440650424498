import React, { useEffect } from 'react';

import Question from './Question';
import styles from './FAQ.module.scss';
import { ReactComponent as IconHelp } from '../../../../../assets/images/icons/icon_help.svg';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { showNanoVariant } from '../../../../../components/NanoBanner/NanoBanner';
import { closeDecagonChat, openDecagonChat } from '../../../../../components/AppBar/NavBar';
import { series3CollarFAQChatButtonClick } from '../../../../../lib/analytics/events';
import { useLocation } from 'react-router-dom';

export default function FAQ() {
  const { pathname } = useLocation();
  const isGiftingPDP = pathname.includes('gift-fi');
  const decagonChatEnabled = useFeatureValue('decagon-chat-widget', false);
  const showDecagonChat = showNanoVariant() && decagonChatEnabled;

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.origin !== 'https://decagon.ai') return;

      const { data } = event;
      if (data.type === 'DECAGON_INACTIVE') {
        closeDecagonChat();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
      // Close the chat when navigating away
      closeDecagonChat();
    };
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>FAQ</h2>
      <div>
        {isGiftingPDP && (
          <Question title="How does gifting work?" page="Collar">
            When you gift a Fi Collar, it arrives gift-wrapped and includes a prepaid membership. Once the initial
            period ends, the recipient can continue the membership by adding their payment information.
          </Question>
        )}
        {isGiftingPDP && (
          <Question title="When does a Fi gift membership start?" page="Collar">
            The Fi gift membership begins when your recipient activates their Fi Collar or 7 days after the Fi Series 3
            ships, whichever comes first.
          </Question>
        )}
        {!isGiftingPDP && (
          <Question title="When does my Fi membership start?" page="Collar">
            Your Fi membership starts when you activate your Fi, or 7 days after the Series 3 is shipped.
          </Question>
        )}
        <Question title="Why is Fi a membership?" page="Collar">
          The Fi membership provides a lower-cost way for customers to try the most advanced technology for their dogs
          without a high upfront cost. Fi also believes in continuing to deliver new features, analytics, and benefits,
          and a membership model allows us to continue investing in the best possible customer experience.
        </Question>
        {!isGiftingPDP && (
          <Question title="Can I lower my monthly payment cost?" page="Collar">
            The longer you commit to Fi, the lower your monthly cost. Once you are a member, you can always switch to an
            annual membership to lock in savings. Additionally, our referral program gives you a free month for each
            person you refer.
          </Question>
        )}
        {!isGiftingPDP && (
          <Question title="What is the commitment period on the month to month and 3 month memberships?" page="Collar">
            When you sign up for Fi, you can try it for 30 days. If for any reason you decide that Fi is not for you,
            you can return your Fi within the return window. Otherwise, the month to month and 3 month memberships have
            a minimum commitment of 6 months.
          </Question>
        )}
        <Question title="What happens when a  new generation of hardware is released?" page="Collar">
          When you have an active Fi membership, you don't have to worry about having outdated hardware; you are
          eligible to upgrade to the latest generation of hardware.
        </Question>
      </div>
      {showDecagonChat && (
        <div className={styles.decagonChatContainer}>
          <IconHelp />
          <div className={styles.decagonChatTitle}>Something else on your mind?</div>
          <button
            className={styles.decagonChatButton}
            onClick={() => {
              series3CollarFAQChatButtonClick();
              openDecagonChat();
            }}
          >
            Chat with Fi
          </button>
        </div>
      )}
    </div>
  );
}
