import { combineReducers } from '@reduxjs/toolkit';
import * as types from '../types';
import { checkoutInitialState, createCheckoutSlice } from './checkout';
import { cartActions, cartInitialState, cartReducer } from './series3UpgradeCart';
import { petContextActions, petContextInitialState, petContextReducer } from './series3UpgradePetContext';

const initialState: types.Series3UpgradeShopState = {
  cart: cartInitialState,
  checkout: checkoutInitialState,
  upgradePetContext: petContextInitialState,
};

const name = 'series3UpgradeShop' as const;

const { actions: checkoutActions, reducer: checkoutReducer } = createCheckoutSlice(name);

export const series3UpgradeShopActions = {
  ...cartActions,
  ...checkoutActions,
  ...petContextActions,
};
export const series3UpgradeShopReducer = combineReducers({
  cart: cartReducer,
  checkout: checkoutReducer,
  upgradePetContext: petContextReducer,
});
export const series3UpgradeShopInitialState = { [name]: initialState };
