import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './AppBar.module.scss';

interface IShoppingBagProps {
  count: number;
}

function ShoppingBagIcon({ count }: IShoppingBagProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="16.165" y="17.9922" width="16" height="16" fill="black" />
      <text textAnchor="middle" x="24" y="30" fill="#FFFFFF" fontSize="12">
        {count}
      </text>
      <path
        fillRule="evenodd"
        d="M26.2852 13.8799C25.8656 13.4609 25.3311 13.1756 24.7495 13.0601C24.1679 12.9445 23.5654 13.0041 23.0176 13.231C22.4697 13.4578 22.0011 13.8419 21.6714 14.3347C21.3417 14.8276 21.1656 15.407 21.165 16V17H27.165V16C27.1643 15.2046 26.8479 14.442 26.2852 13.8799ZM29.165 17V16C29.165 14.6739 28.6384 13.402 27.7007 12.4644C26.763 11.5267 25.4911 11 24.165 11C22.839 11 21.5671 11.5267 20.6294 12.4644C19.6917 13.402 19.165 14.6739 19.165 16V17H14.165V35H34.165V17H29.165ZM16.165 19V33H32.165V19H16.165Z"
        fill="black"
      />
    </svg>
  );
}

export function EmptyShoppingBagIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1201 13.8799C25.7006 13.4609 25.1661 13.1756 24.5845 13.0601C24.0029 12.9445 23.4004 13.0041 22.8525 13.231C22.3047 13.4578 21.836 13.8419 21.5063 14.3347C21.1767 14.8276 21.0005 15.407 21 16V17H27V16C26.9993 15.2046 26.6829 14.442 26.1201 13.8799ZM29 17V16C29 14.6739 28.4733 13.402 27.5356 12.4644C26.598 11.5267 25.3261 11 24 11C22.6739 11 21.402 11.5267 20.4644 12.4644C19.5267 13.402 19 14.6739 19 16V17H14V35H34V17H29ZM16 19V33H32V19H16Z"
        fill="black"
      />
    </svg>
  );
}

function ShoppingBag({ count }: IShoppingBagProps) {
  const [shownCount, setShownCount] = useState(count);
  const [growing, setGrowing] = useState(false);
  const [shrinking, setShrinking] = useState(false);

  const iconDiv = React.createRef<HTMLDivElement>();

  // Called when either the growing or shrinking animations end.
  // Either way, makes sure we're showing the right number, that we're
  // shrinking if we were growing previously, and that we're not growing.
  const handleAnimationEnd = useCallback(() => {
    setShownCount(count);
    setShrinking(growing);
    setGrowing(false);
  }, [count, growing]);

  // Respond to a change in the count, possibly with an animation!
  useEffect(() => {
    // We added a thing to the bag
    if (count > shownCount) {
      setShrinking(false);
      setGrowing(true);
    } else if (count < shownCount) {
      setShownCount(count);
    }
  }, [count, shownCount]);

  // Attach a listener to the shopping bag icon to handle animation ends
  useEffect(() => {
    if (iconDiv.current) {
      const eventListener = () => handleAnimationEnd();
      const element = iconDiv.current;
      element.addEventListener('animationend', eventListener);
      return () => {
        element.removeEventListener('animationend', eventListener);
      };
    }
  }, [iconDiv, growing, handleAnimationEnd]);

  const iconClasses = classNames([
    styles.ShoppingBagIcon,
    { [styles.growing]: growing, [styles.shrinking]: shrinking },
  ]);

  return (
    <Link className={classNames(styles.shoppingBag, styles.link)} to="/bag">
      <div className={iconClasses} ref={iconDiv}>
        {shownCount <= 0 ? <EmptyShoppingBagIcon /> : <ShoppingBagIcon count={shownCount} />}
      </div>
    </Link>
  );
}

export default ShoppingBag;
