// THIS FILE AUTOMATICALLY GENERATED BY yarn download-schema. Do not modify.

const fragmentTypes = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Card',
        possibleTypes: [
          {
            name: 'SimpleCard',
          },
          {
            name: 'WalkCard',
          },
          {
            name: 'LabeledCard',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'FiFeedItem',
        possibleTypes: [
          {
            name: 'FiFeedActivityItem',
          },
          {
            name: 'FiFeedGenericNotificationItem',
          },
          {
            name: 'FiFeedGoalStreakItem',
          },
          {
            name: 'FiFeedRankingUpdateItem',
          },
          {
            name: 'FiFeedPhotoLikedItem',
          },
          {
            name: 'FiFeedPhotoAddedItem',
          },
          {
            name: 'FiFeedPetFollowedItem',
          },
          {
            name: 'FiFeedFollowedPetPhotoAddedItem',
          },
          {
            name: 'FiFeedClappableWalkItem',
          },
          {
            name: 'FiFeedLikableWalkItem',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'DiscoverFeedItem',
        possibleTypes: [
          {
            name: 'DiscoverFeedPhotoItem',
          },
          {
            name: 'DiscoverFeedArticleItem',
          },
          {
            name: 'DiscoverFeedCarouselItem',
          },
          {
            name: 'DiscoverFeedTextPostItem',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'NotificationFeedItem',
        possibleTypes: [
          {
            name: 'NotificationFeedPetFollowedItem',
          },
          {
            name: 'NotificationFeedFollowedPetPhotoAddedItem',
          },
          {
            name: 'NotificationFeedTextPostCommentItem',
          },
          {
            name: 'NotificationFeedTextPostLikedItem',
          },
          {
            name: 'NotificationFeedPhotoLikedItem',
          },
          {
            name: 'NotificationFeedPhotoCommentItem',
          },
          {
            name: 'NotificationFeedArticleItem',
          },
          {
            name: 'NotificationFeedFiNewsItem',
          },
          {
            name: 'NotificationFeedWalkClappedItem',
          },
          {
            name: 'NotificationFeedWalkLikedItem',
          },
          {
            name: 'NotificationFeedNearbyPetJoinedFiItem',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'AdminBaseUserProfile',
        possibleTypes: [
          {
            name: 'LimitedAdminUserProfile',
          },
          {
            name: 'FullAdminUserProfile',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'ConnectionState',
        possibleTypes: [
          {
            name: 'ConnectedToUser',
          },
          {
            name: 'ConnectedToBase',
          },
          {
            name: 'ConnectedToCellular',
          },
          {
            name: 'ConnectedToWifi',
          },
          {
            name: 'UnknownConnectivity',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'OnlineConnectionState',
        possibleTypes: [
          {
            name: 'ConnectedToUser',
          },
          {
            name: 'ConnectedToBase',
          },
          {
            name: 'ConnectedToWifi',
          },
          {
            name: 'ConnectedToCellular',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'GPSTestResult',
        possibleTypes: [
          {
            name: 'GPSTestCompleteResult',
          },
          {
            name: 'GPSTestConnectionStolenResult',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'AdminGPSTestResult',
        possibleTypes: [
          {
            name: 'AdminGPSTestConclusiveResult',
          },
          {
            name: 'AdminGPSTestTimeoutResult',
          },
          {
            name: 'AdminGPSTestErrorResult',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'TEvent',
        possibleTypes: [
          {
            name: 'TLocationReportEvent',
          },
          {
            name: 'TMobilityReportEvent',
          },
          {
            name: 'TActivityReportEvent',
          },
          {
            name: 'TOperationParamsChangeEvent',
          },
          {
            name: 'TNotificationSendEvent',
          },
          {
            name: 'TAppLaunchEvent',
          },
          {
            name: 'TCollapsedEvents',
          },
          {
            name: 'TModemRegistrationFailuresEvent',
          },
          {
            name: 'TDeviceRestartEvent',
          },
          {
            name: 'TStatsChangedEvent',
          },
          {
            name: 'TDeviceInfoEvent',
          },
          {
            name: 'TConnectionHistoryEvent',
          },
          {
            name: 'TFirmwareUpdateEvent',
          },
          {
            name: 'TLedEvent',
          },
          {
            name: 'TBaseLogEvent',
          },
          {
            name: 'TModuleLogEvent',
          },
          {
            name: 'TGenericLogEvent',
          },
          {
            name: 'TLostDogModeToggleEvent',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'PetCollarState',
        possibleTypes: [
          {
            name: 'NeedsReportsState',
          },
          {
            name: 'HasReportsState',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CollarStatus',
        possibleTypes: [
          {
            name: 'OnlineCollarStatus',
          },
          {
            name: 'OfflineCollarStatus',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'OngoingActivity',
        possibleTypes: [
          {
            name: 'OngoingRest',
          },
          {
            name: 'OngoingWalk',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PackAvatar',
        possibleTypes: [
          {
            name: 'AvatarImage',
          },
          {
            name: 'AvatarAbbreviation',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Activity',
        possibleTypes: [
          {
            name: 'Walk',
          },
          {
            name: 'Rest',
          },
          {
            name: 'Travel',
          },
          {
            name: 'MissingReports',
          },
          {
            name: 'Play',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MapPath',
        possibleTypes: [
          {
            name: 'MapMatchedPath',
          },
          {
            name: 'UnmatchedPath',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'RestSummaryData',
        possibleTypes: [
          {
            name: 'ConcreteRestSummaryData',
          },
          {
            name: 'UnavailableRestSummaryData',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'DailyRestSummary',
        possibleTypes: [
          {
            name: 'ConcreteDailyRestSummary',
          },
          {
            name: 'UnavailableDailyRestSummary',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'OvernightRestSummary',
        possibleTypes: [
          {
            name: 'UnavailableOvernightRestSummary',
          },
          {
            name: 'ConcreteOvernightRestSummary',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'DeviceEventBase',
        possibleTypes: [
          {
            name: 'ConnectionStateChangeDeviceEvent',
          },
          {
            name: 'StatDeltaDeviceEvent',
          },
          {
            name: 'DeviceActivity',
          },
          {
            name: 'FirmwareVersionChangeDeviceEvent',
          },
          {
            name: 'PowerStateChangeDeviceEvent',
          },
          {
            name: 'RebootDeviceEvent',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'BatteryHistoryOverviewDeviceEvent',
        possibleTypes: [
          {
            name: 'ConnectionStateChangeDeviceEvent',
          },
          {
            name: 'FirmwareVersionChangeDeviceEvent',
          },
          {
            name: 'PowerStateChangeDeviceEvent',
          },
          {
            name: 'RebootDeviceEvent',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'InAppPromotionBanner',
        possibleTypes: [
          {
            name: 'S3UpgradePromotionBanner',
          },
          {
            name: 'TwoLinePromotionBanner',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'ModerationFeed',
        possibleTypes: [
          {
            name: 'PhotoModerationFeed',
          },
          {
            name: 'TextPostModerationFeed',
          },
          {
            name: 'CommentModerationFeed',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'ModerationItem',
        possibleTypes: [
          {
            name: 'ModerationCommentItem',
          },
          {
            name: 'ModerationPhotoItem',
          },
          {
            name: 'ModerationTextPostItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ModerationSubject',
        possibleTypes: [
          {
            name: 'ModerationCommentPhotoSubject',
          },
          {
            name: 'ModerationCommentArticleSubject',
          },
          {
            name: 'ModerationCommentTextPostSubject',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'TokenValidity',
        possibleTypes: [
          {
            name: 'InvalidToken',
          },
          {
            name: 'ValidToken',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'BasePet',
        possibleTypes: [
          {
            name: 'Pet',
          },
          {
            name: 'StrangerPet',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'FeatureFlag',
        possibleTypes: [
          {
            name: 'StringFeatureFlag',
          },
          {
            name: 'NumberFeatureFlag',
          },
          {
            name: 'BooleanFeatureFlag',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'BooleanSettingPlatformOverride',
        possibleTypes: [
          {
            name: 'BooleanSettingForceOnPlatformOverride',
          },
          {
            name: 'BooleanSettingForceOffPlatformOverride',
          },
          {
            name: 'BooleanSettingMinVersionPlatformOverride',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'BooleanSetting',
        possibleTypes: [
          {
            name: 'TrueBooleanSetting',
          },
          {
            name: 'FalseBooleanSetting',
          },
          {
            name: 'GroupsBooleanSetting',
          },
          {
            name: 'UserIdsBooleanSetting',
          },
          {
            name: 'PercentageOfUsersBooleanSetting',
          },
          {
            name: 'RandomPercentageBooleanSetting',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PaymentInfo',
        possibleTypes: [
          {
            name: 'ObfuscatedCardInfo',
          },
          {
            name: 'PayPalInfo',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'ChipContactInfoResponse',
        possibleTypes: [
          {
            name: 'ChipContactHistoryEntry',
          },
          {
            name: 'ChipTransfer',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'BaseChip',
        possibleTypes: [
          {
            name: 'AssignedChip',
          },
          {
            name: 'StrangerChip',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PhotoPoster',
        possibleTypes: [
          {
            name: 'StrangerUser',
          },
          {
            name: 'ExternalUser',
          },
        ],
      },
    ],
  },
};
export default fragmentTypes;
