import React from 'react';

import styles from './ProductInfo.module.scss';
import { ReactComponent as IconCheck } from '../../../../../assets/images/icons/check.svg';
import classNames from 'classnames';
import { ISupplementSubscriptionProduct, SupplementCheckoutFlow } from '../../../../../types';

interface TermsAndConditionsProps {
  hideFree: boolean;
  selectedProduct: ISupplementSubscriptionProduct;
  flow: SupplementCheckoutFlow;
}

export default function TermsAndConditions({ hideFree, selectedProduct, flow }: TermsAndConditionsProps) {
  if (flow === 'ecomm') {
    return (
      <div className={styles.terms}>
        {!hideFree && (
          <div className={styles.term}>
            <IconCheck />
            Recurring subscription starts with shipment of 2nd bag
          </div>
        )}
        <div className={styles.term}>
          <IconCheck />
          Free shipping
        </div>
        <div className={styles.term}>
          <IconCheck />
          Try risk free, cancel anytime
        </div>
        <div className={classNames(styles.term, styles.purchaseDisclaimer)}>
          By purchasing, you agree to our{' '}
          <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
            terms and conditions
          </a>
          .
        </div>
      </div>
    );
  } else {
    return (
      <div className={classNames(styles.terms, styles.modifyFirstChild)}>
        <div className={styles.term}>
          <IconCheck />
          Saving 15% on every shipment
        </div>
        <div className={styles.term}>
          <IconCheck />
          Free shipping every {selectedProduct.weeksBetweenShipments} weeks
        </div>
        <div className={styles.term}>
          <IconCheck />
          Skip, change frequency, or cancel anytime
        </div>
        <div className={classNames(styles.term, styles.purchaseDisclaimer)}>
          By subscribing, you agree to our{' '}
          <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
            terms and conditions
          </a>
          .
        </div>
      </div>
    );
  }
}
