import { CarouselController } from '../../../../../components/Carousel';
import classNames from 'classnames';
import ImageGallery from '../../../../../components/ImageGallery/ImageGallery';
import React from 'react';
import styles from './ImageGallery.module.scss';
import * as types from '../../../../../types';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../types';
import { showNanoVariant } from '../../../../../components/NanoBanner/NanoBanner';
import { useLocation } from 'react-router-dom';

const DESKTOP_IMAGE_GALLERY_SIZE = 7;

const LIFESTYLE_IMAGES: types.IImage[] = [
  {
    type: 'image',
    url: '/product_images/smart-collar-v3/yellow-lifestyle.jpg',
    descriptors: ['1500w', '3000w', '4000w'],
  },
  {
    type: 'image',
    url: '/product_images/smart-collar-v3/pink-lifestyle.jpg',
    descriptors: ['1500w', '3000w', '4000w'],
  },
  {
    type: 'image',
    url: '/product_images/smart-collar-v3/gray-lifestyle.jpg',
    descriptors: ['1500w', '3000w', '4000w'],
  },
  {
    type: 'image',
    url: '/product_images/smart-collar-v3/blue-lifestyle.jpg',
    descriptors: ['1500w', '3000w', '4000w'],
  },
];

interface DesktopImageGalleryProps {
  carouselControllerRef?: React.MutableRefObject<CarouselController | undefined>;
  selectedVariant?: types.IVariant;
  onChange?: (index: number) => void;
  children?: React.ReactNode;
}

export function imageUrlForVariant(variant: types.IVariant) {
  return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}.jpg`;
}

export function cirlceImageUrlForVariant(variant: types.IVariant, showMetalCollars = false) {
  // If the collar size is L/XL use metal circle image
  switch (variant.options.size) {
    case types.SizeOption.XSmall:
    case types.SizeOption.Small:
    case types.SizeOption.Medium:
      return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}-circle${
        showMetalCollars ? '-plastic' : ''
      }.jpg`;
    case types.SizeOption.Large:
    case types.SizeOption.XLarge:
      return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}-circle${
        showMetalCollars ? '-metal' : ''
      }.jpg`;
    default:
      return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}-circle.jpg`;
  }
}

function nanoLifestyleImageUrlForVariant(variant: types.IVariant): types.IImage {
  return {
    type: 'image',
    url: lifestyleImageUrlForVariant(variant),
    descriptors: ['1500w', '3000w', '4000w'],
  };
}

export function lifestyleImageUrlForVariant(variant: types.IVariant) {
  return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}-lifestyle.jpg`;
}

export function flatImageUrlForVariant(variant: types.IVariant) {
  return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}-flat.jpg`;
}

export function secondaryImageUrlForVariant(variant: types.IVariant) {
  return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}2.jpg`;
}

export function giftCollarImageUrlForVariant(variant: types.IVariant) {
  return `/product_images/gifting/${variant.options.color.replace(/[\s/]/g, '_')}-collar.png`;
}

export function giftBoxImageUrlForVariant(variant: types.IVariant) {
  return `/product_images/gifting/${variant.options.color.replace(/[\s/]/g, '_')}-giftbox.png`;
}

export function DesktopImageGallery({
  carouselControllerRef,
  selectedVariant,
  onChange,
  children,
}: DesktopImageGalleryProps) {
  const { pathname } = useLocation();
  const showMetalCollars = useSelector(
    (state: AppState) => state.config.siteConfig.showMetalCollarCircleImages ?? false,
  );
  const productImages: types.IImage[] = [
    {
      type: 'image',
      url: cirlceImageUrlForVariant(selectedVariant!, showMetalCollars),
      descriptors: ['1500w', '3000w', '4000w'],
    },
    {
      type: 'image',
      url: flatImageUrlForVariant(selectedVariant!),
      descriptors: ['1500w', '3000w', '4000w'],
    },
  ];
  if (selectedVariant?.options.color === types.ColorOption.Strava) {
    productImages.push({
      type: 'image',
      url: secondaryImageUrlForVariant(selectedVariant),
      retinaWidth: 1200,
      retinaHeight: 1200,
    });
  }

  const lifestyleImages = LIFESTYLE_IMAGES;
  // Maintain a maximum of 7 images in carousel
  const images = [...productImages, ...lifestyleImages].slice(0, DESKTOP_IMAGE_GALLERY_SIZE);

  let media: (types.IImage | types.IVideo)[] = images;
  const showNanoMedia = showNanoVariant();
  if (showNanoMedia) {
    media = [
      nanoLifestyleImageUrlForVariant(selectedVariant!),
      ...productImages,
      {
        type: 'video',
        url: `/videos/sizzle-vid.mp4`,
        thumbnail: {
          type: 'image',
          url: `/videos/sizzle-vid-thumbnail.jpg`,
        },
      },
    ];
  }

  const showGiftingMedia = pathname.includes('gift-fi');
  if (showGiftingMedia) {
    media = [
      {
        type: 'image',
        url: giftCollarImageUrlForVariant(selectedVariant!),
        descriptors: ['1500w', '3000w', '4000w'],
      },
    ];
    media = [...media, ...lifestyleImages];
    media.push({
      type: 'image',
      url: giftBoxImageUrlForVariant(selectedVariant!),
      descriptors: ['1500w', '3000w', '4000w'],
    });
  }

  return (
    <div className={classNames(styles.imageGalleryContainer, styles.desktopImageGallery)}>
      {children}
      <ImageGallery
        parentCarouselControllerRef={carouselControllerRef}
        media={media}
        pageDots={true}
        disableAnimation={true}
        friction={0.4}
        onChange={onChange}
      />
    </div>
  );
}
