import React from 'react';
import { ReactComponent as BatteryIcon } from '../../../../../assets/images/icons/icon_battery.svg';
import { ReactComponent as InsightsIcon } from '../../../../../assets/images/icons/icon_insights.svg';
import { ReactComponent as LocationIcon } from '../../../../../assets/images/icons/icon_location.svg';
import { ReactComponent as PullResistanceIcon } from '../../../../../assets/images/icons/icon_pull_resistance.svg';
import { ReactComponent as WaterproofIcon } from '../../../../../assets/images/icons/icon_waterproof.svg';
import FeatureList from '../../../../../components/FeatureList';
import styles from './ProductFeatures.module.scss';

export default function ProductFeatures() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Key features</div>
      <FeatureList
        iconRows={[
          [
            { title: '24/7 location tracking', icon: <LocationIcon /> },
            { title: 'Up to 3 month battery life', icon: <BatteryIcon /> },
            { title: 'Waterproof rated IP68', icon: <WaterproofIcon /> },
            { title: 'Activity and sleep tracking', icon: <InsightsIcon /> },
            { title: '300lbs pull resistance', icon: <PullResistanceIcon /> },
          ],
        ]}
      />
    </div>
  );
}
