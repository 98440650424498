import * as React from 'react';
import * as types from '../../types';
import { getProductsBySku } from '../../lib/product';
import { centsToDollarsNumber } from '../../lib/util';
import { getSha1Hash } from '../../lib/util/hash';
import { skusForCartItem } from '../../lib/cart';
import { useSelector } from 'react-redux';
import { productCategoryNameForEvent } from '../../lib/analytics/events';

interface IImpactTrackConversionComponentProps {
  cart: types.Cart;
  cartPricing: types.CartPricing;
  orderId: string;
  session: types.Session | null;
}

interface ImpactOrderItem {
  subTotal: number;
  category: string;
  sku: string;
  quantity: number;
  name: string;
}

export default function ImpactTrackConversionComponent(props: IImpactTrackConversionComponentProps) {
  // TODO: update this if/when we support multiple currencies
  const currency = 'USD';
  const products = useSelector((state: types.AppState) => state.config.products);
  const productsBySku = getProductsBySku(products);
  const [emailHash, setEmailHash] = React.useState('');
  const [loadedEmail, setLoadedEmail] = React.useState(false);
  React.useEffect(() => {
    async function getEmailHash() {
      if (props.session?.email) {
        setEmailHash(await getSha1Hash(props.session.email));
      }
      setLoadedEmail(true);
    }
    getEmailHash();

    const itemsForTracking: ImpactOrderItem[] = [];
    for (const item of Object.values(props.cart.cartItems)) {
      const skus = skusForCartItem(item);
      for (const sku of skus) {
        const product = productsBySku.get(sku);
        if (!product) {
          continue;
        }

        if (product.category === types.ProductCategory.SUBSCRIPTION && product.freeTrial) {
          continue;
        }

        let category = '';
        try {
          category = productCategoryNameForEvent(product.category);
        } catch {
          category = 'unknown';
        }

        itemsForTracking.push({
          subTotal: centsToDollarsNumber(product.priceInCents),
          category: category,
          sku: item.lineItem.sku,
          quantity: item.quantity,
          name: product.name,
        });

        // activation fees are not listed as individual cart items, but we need to send them as individual items to Impact
        if (product.category === types.ProductCategory.SUBSCRIPTION && product.activationFeeInCents) {
          itemsForTracking.push({
            subTotal: centsToDollarsNumber(product.activationFeeInCents),
            category: 'setup-fee',
            sku: 'setup-fee',
            quantity: 1,
            name: 'Activation fee',
          });
        }
      }
    }

    const body = document.querySelector('body');
    const script = document.createElement('script');

    script.setAttribute('type', 'text/javascript');
    script.innerText = loadedEmail
      ? `ire('trackConversion', 39254, 
          {
            orderId: "${props.orderId}",
            customerId: "${props.session?.userId ?? ''}",
            customerEmail: "${emailHash}",
            currencyCode: "${currency}",
            orderPromoCode: "${props.cart.couponCode ?? ''}",
            orderDiscount: ${centsToDollarsNumber(props.cartPricing.appliedDiscountInCents)},
            items: ${JSON.stringify(itemsForTracking)}
          },
          {
            verifySiteDefinitionMatch:true
          }
        );`
      : '';
    body?.appendChild(script);
    return () => {
      body?.removeChild(script);
    };
  }, [
    props.session?.email,
    productsBySku,
    props.cart.cartItems,
    emailHash,
    loadedEmail,
    props.cart.couponCode,
    props.orderId,
    props.session?.userId,
    props.cartPricing.appliedDiscountInCents,
  ]);

  return <></>;
}
