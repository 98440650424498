import React, { useState } from 'react';
import styles from '../../ManageSubscription/styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../../ManageSubscription/components/SubscriptionAppBar';
import Chooser from '../../../components/Chooser';
import { gqlTypes } from '../../../types';
import Button from '../../../components/Button';
import { usePurchaseSubscriptionContext } from '../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { maybeTruncatePetName } from '../../ManageSubscription/Collar/util/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import { troubleshoot as events } from '../../../lib/analytics/events';
import { SeriesAbbrev } from '../../../types/Series';

interface TroubleshootStartState {
  previousPath: string;
}

export default function TroubleshootStart() {
  const location = useLocation<TroubleshootStartState>();
  const previousPath = location.state?.previousPath ?? null;
  const [selectedTroubleshootingReason, setSelectedTroubleshootingReason] = useState<
    gqlTypes.zendeskCancellationReason | undefined
  >(undefined);

  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();

  const showUpgradeForModuleVersions: SeriesAbbrev[] = ['S1', 'S2'];
  const provideReplaceOptionForModuleVersions: SeriesAbbrev[] = ['S3'];
  const collarModel = device.currentCollarModel as SeriesAbbrev;

  const petName = device.pet?.name ?? 'your dog';
  const shortPetName = maybeTruncatePetName(petName);

  events.pageLoad({ petId: device.pet?.id }, 'Troubleshoot Start');

  const handleNavigate = () => {
    if (!selectedTroubleshootingReason) {
      throw new Error('Something went wrong.');
    }

    events.selectedAndSubmittedTroubleshootingReason({
      troubleshootingReason: selectedTroubleshootingReason,
      petId: device.pet?.id,
    });

    if (selectedTroubleshootingReason === gqlTypes.zendeskCancellationReason.COLLAR_DOES_NOT_WORK_PROPERLY) {
      history.push({
        pathname: AppPaths.Troubleshoot.TellUsMore(device.moduleId),
        state: {
          selectedTroubleshootingReason,
        },
        search: window.location.search,
      });
    } else if (selectedTroubleshootingReason === gqlTypes.zendeskCancellationReason.COLLAR_IS_PHYSICALLY_BROKEN) {
      if (provideReplaceOptionForModuleVersions.includes(collarModel)) {
        history.push({
          pathname: AppPaths.Troubleshoot.Replace(device.moduleId),
          state: {
            selectedTroubleshootingReason,
          },
          search: window.location.search,
        });
      } else if (showUpgradeForModuleVersions.includes(collarModel)) {
        history.push({
          pathname: AppPaths.Troubleshoot.Upgrade(device.moduleId),
          state: {
            selectedTroubleshootingReason,
          },
          search: window.location.search,
        });
      }
    } else if (selectedTroubleshootingReason === gqlTypes.zendeskCancellationReason.OTHER) {
      history.push({
        pathname: AppPaths.Troubleshoot.Other(device.moduleId),
        state: {
          selectedTroubleshootingReason,
        },
        search: window.location.search,
      });
    } else {
      throw new Error('Something went wrong.');
    }
  };

  return (
    <div className={styles.fullWidthWrapper}>
      <SubscriptionAppBar
        backButtonAnalyticsEvent={() => {
          events.troubleshootStartBack({ petId: device.pet?.id });
        }}
        closeButtonAnalyticsEvent={() => {}}
        noCloseButton
        forceBackButtonBehavior={() => {
          if (previousPath) {
            history.push(previousPath);
          } else {
            window.open('fi://closeappview');
          }
        }}
      />
      <div className={styles.subscriptionContainer}>
        <h1>Troubleshoot {shortPetName}'s collar</h1>
        <p>To help us guide you in the right direction, please select one of the options below.</p>
        <p>What's wrong?</p>
        <div className={styles.content}>
          <Chooser
            onSelect={(selectedReason) => {
              setSelectedTroubleshootingReason(selectedReason);
            }}
            selectedOption={selectedTroubleshootingReason}
            groups={[
              {
                options: [
                  {
                    content: 'The collar doesn’t work right',
                    value: gqlTypes.zendeskCancellationReason.COLLAR_DOES_NOT_WORK_PROPERLY,
                  },
                  {
                    content: 'The collar is physically broken',
                    value: gqlTypes.zendeskCancellationReason.COLLAR_IS_PHYSICALLY_BROKEN,
                  },
                  {
                    content: 'Other',
                    value: gqlTypes.zendeskCancellationReason.OTHER,
                  },
                ],
              },
            ]}
            showRadio
            compact
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          disabled={selectedTroubleshootingReason === undefined}
          onClick={handleNavigate}
        >
          Continue
        </Button>
        <div className={styles.buttonSpacer}></div>
      </div>
    </div>
  );
}
