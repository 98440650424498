import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import Button from '../../../../components/Button';
import { SubscriptionAppBarSpacer } from '../../components/SubscriptionAppBar';
import { inAppCancellation as events } from '../../../../lib/analytics/events';

export default function RenewConfirmation() {
  const { device } = usePurchaseSubscriptionContext();

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBarSpacer />
        <div className={styles.subscriptionContainer}>
          <h1>Your Fi Membership has been reactivated</h1>
          <p>Thank you for staying with us! Your Fi Membership will automatically be billed at your renewal date.</p>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              events.renewConfirmationDoneClicked({ petId: device.pet?.id });
              window.open('fi://closeappview');
            }}
          >
            Done
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
