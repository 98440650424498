import React from 'react';
import { gqlTypes } from '../../../../types';
import classNames from 'classnames';
import styles from './PetView.module.scss';

interface PetViewProps {
  pet: gqlTypes.petDetails;
  title: string;
  onClick?(): void;
}

export default function PetView({ pet, onClick, title }: PetViewProps) {
  const avatarUrl = pet.photos.first?.image.fullSize ?? '/dog_placeholder.png';
  return (
    <div className={classNames(styles.main, onClick && styles.clickable)} onClick={onClick}>
      <img src={avatarUrl} width="40" alt="Pet avatar" />
      <h4 className={styles.petTitle}>{title}</h4>
      <h4 className={classNames(styles.textHighlight, styles.petArrow)}>&gt;</h4>
    </div>
  );
}
