import React from 'react';
import styles from '../../../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import Button from '../../../../../../components/Button';
import { SubscriptionAppBarSpacer } from '../../../../components/SubscriptionAppBar';
import { inAppCancellation as events } from '../../../../../../lib/analytics/events';

export default function ResumeMembershipConfirmation() {
  const { device } = usePurchaseSubscriptionContext();

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBarSpacer />
        <div className={styles.subscriptionContainer}>
          <h1>Your Fi Membership has been resumed</h1>
          <p>We're happy to have you back!</p>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              events.resumeConfirmationDoneClicked({ petId: device.pet?.id });
              window.open('fi://closeappview?success=Success!%20Your%20membership%20has%20resumed');
            }}
          >
            Done
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
