import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppPaths from '../../../../AppPaths';
import * as types from '../../../../types';
import styles from './LoginButton.module.scss';

interface LoginButtonProps {
  returnTo?: string;
}

export default function LoginButton({ returnTo }: LoginButtonProps) {
  const loggedIn = useSelector((state: types.AppState) => !!state.session);
  if (loggedIn) {
    return null;
  }

  const loginPath = returnTo ? `${AppPaths.Login}?returnTo=${returnTo}` : AppPaths.Login;

  return (
    <div className={styles.container}>
      Already have an account? <Link to={loginPath}>Sign in</Link>
    </div>
  );
}
