import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import SentryRoute from '../../SentryRoute';
import useCheckoutPaths from '../../hooks/useCheckoutPaths';
import { cartHasItems } from '../../lib/cart';
import CheckoutContext, { CheckoutContextProvider } from '../../lib/CheckoutContext';
import { storeShopActions } from '../../reducers/storeShop';
import * as types from '../../types';
import Billing from './pages/Billing';
import Confirm from './pages/Confirm';
import Shipping from './pages/Shipping';
import Supplements from './pages/Supplements/Supplements';
import ThankYou from './pages/ThankYou';
import { FeaturesReady } from '@growthbook/growthbook-react';

// Wraps a component to redirect home if the cart is empty
function requireCartItems<P extends JSX.IntrinsicAttributes>(Component: React.ComponentType<P>) {
  return function RequireCartItems(props: P) {
    const { cart } = useContext(CheckoutContext);

    if (!cartHasItems(cart)) {
      return <Redirect to="/" />;
    }

    return <Component {...props} />;
  };
}

// Wraps a component to redirect home if the user is not logged in
function requireSession<P extends JSX.IntrinsicAttributes>(Component: (...anyProps: any) => JSX.Element) {
  return function RequireSession(props: P) {
    const { session } = useContext(CheckoutContext);

    if (!session) {
      return <Redirect to="/" />;
    }

    return <Component {...props} />;
  };
}

const RequireCartItemsShipping = requireCartItems(Shipping);
const RequireCartItemsBilling = requireCartItems(Billing);
const RequireCartItemsConfirm = requireCartItems(Confirm);
const RequireSessionThankYou = requireSession(ThankYou);
const RequiresSessionSupplements = requireSession(Supplements);

export default function Checkout() {
  const dispatch = useDispatch();
  const session = useSelector((state: types.AppState) => state.session);
  const checkoutPaths = useCheckoutPaths();

  // Reset the checkout session on logout
  const [previousSession, setPreviousSession] = useState(session);
  useEffect(() => {
    if (previousSession !== session) {
      // Only reset on logout
      if (!session) {
        dispatch(storeShopActions.resetCheckout());
      }

      // Always track the previous session on change
      setPreviousSession(session);
    }
  }, [dispatch, session, previousSession, setPreviousSession]);

  return (
    <CheckoutContextProvider>
      <Switch>
        <SentryRoute path={checkoutPaths.Shipping}>
          <RequireCartItemsShipping />
        </SentryRoute>
        <SentryRoute path={checkoutPaths.Payment}>
          <RequireCartItemsBilling />
        </SentryRoute>
        <SentryRoute path={checkoutPaths.Review}>
          <RequireCartItemsConfirm />
        </SentryRoute>
        <SentryRoute path={checkoutPaths.Supplements}>
          <FeaturesReady>
            <RequiresSessionSupplements />
          </FeaturesReady>
        </SentryRoute>
        <SentryRoute path={checkoutPaths.ThankYou}>
          <RequireSessionThankYou />
        </SentryRoute>
      </Switch>
    </CheckoutContextProvider>
  );
}
