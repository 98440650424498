import React from 'react';
import styles from './AdditionalLink.module.scss';

interface Link {
  description: string;
  text: string;
  url: string;
}

export interface AdditionalLinkProps {
  link: Link;
}

export default function AdditionalLink({ link }: AdditionalLinkProps) {
  return (
    <div className={styles.additionalLink}>
      <p className={styles.description}>{link.description}</p>
      <a className={styles.link} target="_blank" rel="noopener noreferrer" href={link.url}>
        {link.text}
      </a>
    </div>
  );
}
