import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import Button from '../../components/Button';
import ErrorMessage, { useError } from '../../components/ErrorMessage';
import { sendPasswordResetEmail } from '../../lib/authentication';
import ErrorMessages, { logInternalError } from '../../lib/errors';
import styles from './ForgotPassword.module.scss';
import { isAxiosError } from '../../lib/fi-api/apiUtils';
import { validateEmail } from '../../lib/util';

function ForgotPasswordForm() {
  const history = useHistory();
  const { error, errorID, setError } = useError();
  const [submitting, setSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleLogin = useCallback(
    (event: any) => {
      event.preventDefault();
      const email = event.target.email.value.trim();
      const missingEmailError = 'Please enter a valid email address.';

      if (email && validateEmail(email)) {
        sendPasswordResetEmail(email)
          .then(() => {
            setSuccess(true);
          })
          .catch((err) => {
            if (isAxiosError(err) && err.response?.status === 400) {
              // The password reset endpoint should swallow all errors to avoid providing any indication of account
              // validity. If it's a 400 it should only be due to a blank email address.
              setError(missingEmailError);
            } else {
              logInternalError(err);
              setError(ErrorMessages.INPUT_ERROR);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        setError(missingEmailError);
      }
    },
    [setError],
  );

  if (success) {
    return (
      <>
        <p>Check your email for a link to reset your password.</p>
        <Button type="button" onClick={() => history.push(AppPaths.Home)}>
          Return to store
        </Button>
      </>
    );
  }

  return (
    <>
      <form onSubmit={handleLogin} className={styles.forgotPasswordForm}>
        <div className={styles.formSection}>
          <input type="text" name="email" placeholder="Email address" />
          <Button type="submit" disabled={submitting}>
            Submit
          </Button>
        </div>
        {error && <ErrorMessage errors={[error]} errorID={errorID} />}
      </form>
      <Link to={AppPaths.Login}>Nevermind, take me back</Link>
    </>
  );
}

export default function ForgotPassword() {
  return (
    <div className={styles.container}>
      <h2>Forgot password</h2>
      <ForgotPasswordForm />
    </div>
  );
}
