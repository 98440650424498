import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logInternalError } from '../lib/errors';
import * as types from '../types';
import { Dispatch } from 'redux';
import { useCartMode } from '../lib/cartModes';

export default function useApplyPromotionFromCookie<T>(options: {
  getCookieValue: () => [T, () => void] | undefined;
  applyPromotion: (args: { value: T; dispatch: Dispatch<any>; cart: types.Cart }) => Promise<void>;
}) {
  const { cartSelector } = useCartMode();
  const dispatch = useDispatch();
  const config = useSelector((state: types.AppState) => state.config);
  const cart = useSelector(cartSelector);
  const [initialCart] = useState(cart);
  const [attempted, setAttempted] = useState(false);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (attempted) {
      return;
    }

    async function tryToApplyPromotion() {
      try {
        const result = options.getCookieValue();
        if (result) {
          const [value, removeFn] = result;
          await options.applyPromotion({
            value,
            dispatch,
            cart: initialCart,
          });
          removeFn();
        }
      } catch (err) {
        logInternalError(err);
      } finally {
        setFinished(true);
      }
    }

    setAttempted(true);
    tryToApplyPromotion();
  }, [dispatch, initialCart, config, options, attempted]);

  return { finished };
}
