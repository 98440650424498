import { useCartMode } from '../lib/cartModes';
import AppPaths from '../AppPaths';

interface CheckoutPaths {
  Shipping: string;
  Payment: string;
  Review: string;
  ThankYou: string;
  Supplements: string;
}

export default function useCheckoutPaths(): CheckoutPaths {
  const { rootPath } = useCartMode();

  const prefix = rootPath === '/' ? '' : rootPath;
  return {
    Shipping: `${prefix}${AppPaths.CheckoutShipping}`,
    Payment: `${prefix}${AppPaths.CheckoutPayment}`,
    Review: `${prefix}${AppPaths.CheckoutReview}`,
    ThankYou: `${prefix}${AppPaths.CheckoutThankYou}`,
    Supplements: `${prefix}${AppPaths.CheckoutSupplements}`,
  };
}
