import React, { useCallback } from 'react';
import { getFiGrowthBook } from '../../../lib/growthbook';
import styles from './UpgradeSplash.module.scss';
import * as types from '../../../types';
import { getPriceForRateUnit, centsToDollarsNumber } from '../../../lib/util';
import classNames from 'classnames';

interface UpgradeOfferProps {
  offeredPlan: types.ISubscriptionProduct;
}

interface SelectableUpgradeOfferProps extends UpgradeOfferProps {
  onSelect: (subscriptionProduct: types.ISubscriptionProduct) => void;
  promotionalDiscountAmountInCents: number;
  selected: boolean;
}

function renewalTitle(subscriptionProduct: types.ISubscriptionProduct) {
  if (subscriptionProduct.buyItMembership) {
    return 'BUY IT';
  }

  const renewalMonths = subscriptionProduct.renewalMonths;
  if (renewalMonths % 12 === 0) {
    const years = Math.round(renewalMonths / 12);
    return `${years} YEAR`;
  }

  if (renewalMonths === 1) {
    return 'MONTHLY';
  }

  return `${renewalMonths} MONTHS`;
}

export function UpgradeOffer({ offeredPlan }: UpgradeOfferProps) {
  return (
    <>
      <div className={styles.offer}>
        <div className={styles.offerLabel}>Your offer</div>
        <div className={styles.offerHeader}>
          <h1>
            {offeredPlan.displayedRate}/{offeredPlan.displayedRateUnit}
          </h1>
        </div>
        <div className={styles.offerBody}>
          <div>
            {offeredPlan.savings} and get credit for the remainder of your Series 2 subscription. No activation fee.
          </div>
        </div>
      </div>
    </>
  );
}

export function SelectableUpgradeOffer({
  offeredPlan,
  onSelect,
  promotionalDiscountAmountInCents,
  selected,
}: SelectableUpgradeOfferProps) {
  const { unit: displayRateUnit, displayRate } = getPriceForRateUnit(offeredPlan, 'week');
  const onClick = useCallback(() => onSelect(offeredPlan), [offeredPlan, onSelect]);
  const blackFridayCyberMondayEnabled =
    getFiGrowthBook().getFeatureValue<string>('black-friday-cyber-monday-upgrade-flow-coupon-code', '') !== '';

  const originalActivationFeeInCents = offeredPlan.discountedFromOriginalActivationFeeInCents ?? 0;
  const activationFeeInCents = offeredPlan.activationFeeInCents ?? 0;
  const activationFeeDiscountInCents = originalActivationFeeInCents - activationFeeInCents;

  const totalDiscountInCents = promotionalDiscountAmountInCents + activationFeeDiscountInCents;

  return (
    <>
      <div
        className={classNames(styles.offer, styles.selectable, {
          [styles.selected]: !!selected,
        })}
        onClick={onClick}
      >
        <div className={styles.offerLabel}>{renewalTitle(offeredPlan)}</div>
        <div className={styles.offerHeader}>
          <h2>
            {displayRate}/{displayRateUnit}
          </h2>
          {blackFridayCyberMondayEnabled && offeredPlan.couponExtensionMonths && offeredPlan.couponExtensionMonths > 0 && (
            <div className={styles.offerDiscount}>
              <div className={styles.offerDiscountSeparator}>+</div>
              <div className={styles.offerDiscountAmount}>{offeredPlan.couponExtensionMonths} MONTHS FREE</div>
            </div>
          )}
          {!blackFridayCyberMondayEnabled && totalDiscountInCents > 0 && (
            <div className={styles.offerDiscount}>
              <div className={styles.offerDiscountSeparator}>+</div>
              <div className={styles.offerDiscountAmount}>${centsToDollarsNumber(totalDiscountInCents)} off</div>
            </div>
          )}
        </div>
        <div className={styles.offerFooter}>
          {promotionalDiscountAmountInCents > 0 && (
            <div>${centsToDollarsNumber(promotionalDiscountAmountInCents)} offer credit</div>
          )}
          {activationFeeDiscountInCents > 0 && (
            <div>${centsToDollarsNumber(originalActivationFeeInCents)} activation fee waived</div>
          )}
        </div>
      </div>
    </>
  );
}
