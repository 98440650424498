import React, { useState } from 'react';
import styles from '../../ManageSubscription/styles/SubscriptionContainer.module.scss';
import { useHistory } from 'react-router-dom';
import { usePurchaseSubscriptionContext } from '../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { gqlTypes } from '../../../types';
import AppPaths from '../../../AppPaths';
import SubscriptionAppBar from '../../ManageSubscription/components/SubscriptionAppBar';
import Chooser from '../../../components/Chooser';
import Button from '../../../components/Button';
import { troubleshoot as events } from '../../../lib/analytics/events';

export default function TellUsMore() {
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const [selectedTroubleshootingReason, setSelectedTroubleshootingReason] = useState<
    gqlTypes.zendeskCancellationReason | undefined
  >(undefined);

  events.pageLoad({ petId: device.pet?.id }, 'Tell Us More');

  const handleContinue = () => {
    if (!selectedTroubleshootingReason) {
      throw new Error('Something went wrong.');
    }
    events.tellUsMoreContinue({ petId: device.pet?.id, troubleshootingReason: selectedTroubleshootingReason });
    history.push({
      pathname: AppPaths.Troubleshoot.ContactCx(device.moduleId),
      state: { selectedTroubleshootingReason: selectedTroubleshootingReason },
      search: window.location.search,
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.tellUsMoreBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.tellUsMoreClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Tell us more</h1>
          <div className={styles.content}>
            <div className={styles.content}>
              <Chooser
                onSelect={(selectedReason) => {
                  setSelectedTroubleshootingReason(selectedReason);
                }}
                selectedOption={selectedTroubleshootingReason}
                groups={[
                  {
                    options: [
                      {
                        content: "My dog's location is not correct",
                        value: gqlTypes.zendeskCancellationReason.LOCATION_REPORTS_INCORRECT,
                      },
                      {
                        content: 'The app took too long to notify me',
                        value: gqlTypes.zendeskCancellationReason.TOOK_TOO_LONG_TO_NOTIFY,
                      },
                      {
                        content: "The collar's battery drains too quickly",
                        value: gqlTypes.zendeskCancellationReason.BATTERY_DRAINS_TOO_QUICKLY,
                      },
                      {
                        content: 'Other',
                        value: gqlTypes.zendeskCancellationReason.OTHER,
                      },
                    ],
                  },
                ]}
                showRadio
                compact
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            disabled={selectedTroubleshootingReason === undefined}
            onClick={handleContinue}
          >
            Continue
          </Button>
          {/* styles.buttonSpacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
