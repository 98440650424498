import { useCartMode } from '../lib/cartModes';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { cartHasSomePhysicalProducts } from '../lib/cart';
import * as types from '../types';

export default function useRequiresShippingDetails() {
  const { cartSelector } = useCartMode();
  const cart = useSelector(cartSelector);
  const products = useSelector((state: types.AppState) => state.config.products);

  const requiresShippingDetails = useMemo(() => cartHasSomePhysicalProducts(cart, products), [cart, products]);
  return requiresShippingDetails;
}
