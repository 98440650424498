import classNames from 'classnames';
import React from 'react';
import { useCartPricing } from '../../../../contexts/CartPricingContext';
import { centsToDollars } from '../../../../lib/util';
import * as types from '../../../../types';
import styles from './Confirm.module.scss';

interface PricingSummaryProps {
  selectedShippingOption?: types.IShippingOption;
}

function displayDollarsWithCents(priceInCents: number) {
  const dollars = centsToDollars(priceInCents);

  if (dollars.match(/\.\d+$/)) {
    return `$${dollars}`;
  }

  return `$${dollars}.00`;
}

function displayShippingPrice(shippingPriceInCents: number | undefined) {
  if (!shippingPriceInCents) {
    return 'Free';
  }

  return `${displayDollarsWithCents(shippingPriceInCents)}`;
}

export default function PricingSummary({ selectedShippingOption }: PricingSummaryProps) {
  const cartPricing = useCartPricing();

  return (
    <div className={styles.cartSummary}>
      <div className={styles.row}>
        <div>Subtotal</div>
        <div>{displayDollarsWithCents(cartPricing.subtotalInCents)}</div>
      </div>
      {selectedShippingOption && (
        <div className={styles.row}>
          <div>Shipping</div>
          <div>{displayShippingPrice(cartPricing.shippingInCents)}</div>
        </div>
      )}
      {!!cartPricing.appliedDiscountInCents && (
        <div className={classNames(styles.row, styles.cartSavings)}>
          <div>Discount</div>
          <div>-{displayDollarsWithCents(cartPricing.appliedDiscountInCents)}</div>
        </div>
      )}
      <div className={styles.row}>
        <div>Taxes</div>
        <div>{displayDollarsWithCents(cartPricing.taxInCents ?? 0)}</div>
      </div>
      {!!cartPricing.appliedGiftCardAmountInCents && (
        <div className={classNames(styles.row, styles.cartSavings)}>
          <div>
            Gift card
            {cartPricing.totalValueOfRedeemedGiftCardInCents && (
              <span>(${centsToDollars(cartPricing.totalValueOfRedeemedGiftCardInCents)} value)</span>
            )}
          </div>
          <div>-{displayDollarsWithCents(cartPricing.appliedGiftCardAmountInCents)}</div>
        </div>
      )}
      {!!cartPricing.appliedAccountBalanceInCents && (
        <div className={classNames(styles.row, styles.cartSavings)}>
          <div>Account balance</div>
          <div>-{displayDollarsWithCents(cartPricing.appliedAccountBalanceInCents)}</div>
        </div>
      )}
      {!!cartPricing.upgradeCreditAmountInCents && (
        <div className={classNames(styles.row, styles.cartSavings)}>
          <div>Credits</div>
          <div>-{displayDollarsWithCents(cartPricing.upgradeCreditAmountInCents)}</div>
        </div>
      )}
      <div className={classNames(styles.row, styles.cartTotal)}>
        <div>Total</div>
        <div>{displayDollarsWithCents(cartPricing.totalInCents)}</div>
      </div>
    </div>
  );
}
