import React from 'react';
import styles from './Video.module.scss';
import { IVideo } from '../../types';

export const Video = ({ video }: { video: IVideo }) => {
  return (
    <picture>
      <video
        className={styles.video}
        preload="auto"
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
        src={video.url}
      />
    </picture>
  );
};
