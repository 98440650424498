import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as types from '../types';
import { isModuleSubscriptionProduct } from '../types';

export default function useSubscriptionProducts(collarProductId: string) {
  const products = useSelector((state: types.AppState) => state.config.products);
  return useMemo(
    () =>
      products
        .filter(isModuleSubscriptionProduct)
        .filter((subscriptionProduct) => subscriptionProduct.canBePurchasedForProductIds.includes(collarProductId)),
    [collarProductId, products],
  );
}
