import React from 'react';
import { RescuePlacementModel, useRescuePlacementNavigator } from '../rescuePlacementUtils';
import PetView from '../PetView/PetView';
import styles from './PetSelector.module.scss';

export default function PetSelector({ model }: { model: RescuePlacementModel }) {
  const navigator = useRescuePlacementNavigator();

  return (
    <div className={styles.main}>
      <div>
        <h2>Select the dog you want to place</h2>
        {model.petsToBePlaced.map((pet) => (
          <PetView
            key={pet.id}
            title={pet.name}
            pet={pet}
            onClick={() => navigator.go({ placementView: 'petPlacement', petId: pet.id })}
          />
        ))}
        {model.petsToBePlaced.length === 0 && <p>There are no dogs to be placed.</p>}
      </div>
      <div>
        <h2>Select a dog to recall into the rescue</h2>
        {model.petsToBeRecalled.map((pet) => (
          <PetView
            key={pet.id}
            pet={pet}
            title={pet.name}
            onClick={() => navigator.go({ placementView: 'petRecall', petId: pet.id })}
          />
        ))}
        {model.petsToBeRecalled.length === 0 && <p>There are no dogs to be recalled.</p>}
      </div>
    </div>
  );
}
