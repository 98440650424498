import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import Button from '../../../../components/Button';
import styles from './AddToBagButton.module.scss';

interface AddToBagProps {
  onAddToBag: () => boolean;
  disabled?: boolean;
  ctaText?: string;
}

const AddToBagButton = ({ onAddToBag, disabled, ctaText }: AddToBagProps): React.ReactElement => {
  const buttonDiv = React.createRef<HTMLButtonElement>();

  const [isBuying, setIsBuying] = useState(false);

  const handleAnimationEnd = useCallback(() => {
    setIsBuying(false);
  }, []);

  // Attach a listener to the shopping bag icon to handle animation ends
  useEffect(() => {
    if (buttonDiv.current) {
      const eventListener = () => handleAnimationEnd();
      const element = buttonDiv.current;
      element.addEventListener('animationend', eventListener);

      return () => {
        element.removeEventListener('animationend', eventListener);
      };
    }
  }, [buttonDiv, handleAnimationEnd]);

  const onClick = useCallback(
    (e) => {
      e.preventDefault();

      if (disabled) {
        return;
      }

      if (!isBuying && onAddToBag()) {
        setIsBuying(true);
      }
    },
    [disabled, isBuying, onAddToBag],
  );

  const buttonClasses = classNames(styles.main, {
    [styles.isBuying]: isBuying,
  });

  return (
    <Button className={buttonClasses} onClick={onClick} elementRef={buttonDiv} disabled={disabled}>
      <div className={styles.overflowContainer}>
        <div className={styles.buttonContents}>
          <div className={styles.added}>Added!</div>
          <div>{ctaText ?? 'Add To Cart'}</div>
        </div>
      </div>
    </Button>
  );
};

export default AddToBagButton;
