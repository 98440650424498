import { useRecurly } from '@recurly/react-recurly';
import {
  ApplePayPaymentAuthorizedEvent,
  ApplePayPaymentMethodSelectedEvent,
  ApplePayPaymentRequest,
  ApplePayShippingContactSelectedEvent,
  ApplePayShippingMethodSelectedEvent,
} from '@recurly/recurly-js';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCartPricing } from '../../contexts/CartPricingContext';
import CheckoutContext from '../../lib/CheckoutContext';
import {
  FiApplePayError,
  IRecurlyApplePay,
  FiApplePayErrorUpdate,
  FiApplePaySelectionUpdate,
  ShippingContactField,
} from '../../lib/RecurlyProvider';
import { cartHasSomePhysicalProducts } from '../../lib/cart';
import { APPLE_PAY_ERROR_CODE_CUSTOM_ACCOUNT_EXISTS, APPLE_PAY_ERROR_CODE_CUSTOM_API } from '../../lib/errors';
import * as types from '../../types';
import ApplePayPaymentState from './ApplePayPaymentState';
import ApplePayPurchaseResults from './ApplePayPurchaseResults';
import { IApplePayEvents } from './Events';
import usePaymentAuthorized from './usePaymentAuthorized';
import useUpdatePaymentSheet, { paymentSheetLineItems } from './useUpdatePaymentSheet';
import useApplePayRecurring from '../../hooks/useApplePayRecurring';

const APPLE_PAY_REQUIRED_BASIC_CONTACT_FIELDS: ShippingContactField[] = ['email', 'name'];

// We only need these additional 2 fields if we are shipping physical products
const APPLE_PAY_REQUIRED_FIELDS_FOR_SHIPPING: ShippingContactField[] = [
  ...APPLE_PAY_REQUIRED_BASIC_CONTACT_FIELDS,
  'phone',
  'postalAddress',
];

interface UseApplePayProps {
  events: IApplePayEvents;
  onCancel: () => void;
  onCustomError: (error: FiApplePayError) => void;
  onError: (error: Error) => void;
  onReady: () => void;
  onSuccess(results: ApplePayPurchaseResults): void;
}

/**
 * @returns applePay A Recurly.js ApplePay instance.
 */
export default function useApplePay({
  events,
  onCancel,
  onCustomError,
  onError,
  onReady,
  onSuccess,
}: UseApplePayProps) {
  const useRecurringPaymentRequest = useApplePayRecurring();

  const recurly = useRecurly();
  const paymentState = useMemo(() => new ApplePayPaymentState(), []);

  const { cart } = useContext(CheckoutContext);
  const products = useSelector((state: types.AppState) => state.config.products);
  const addressRequired = useMemo(() => cartHasSomePhysicalProducts(cart, products), [cart, products]);

  // This is cart pricing from the context provider, it provides an initial total for the Apple Pay payment sheet
  const contextCartPricing = useCartPricing();

  // This is a local version of cart pricing that gets updated as the user changes shipping options in the Apple Pay
  // payment sheet
  const [cartPricing, setCartPricing] = useState<types.CartPricing>(contextCartPricing);

  const updatePaymentSheet = useUpdatePaymentSheet({
    events,
    paymentState,
    setCartPricing,
  });

  const onPaymentAuthorized = usePaymentAuthorized({
    addressRequired,
    cartPricing,
    events,
    onSuccess,
    paymentState,
  });

  // Cast to our own typings because the Recurly.JS typings are missing some things
  return useMemo(() => {
    const paymentSheet = paymentSheetLineItems(cartPricing, useRecurringPaymentRequest);
    const paymentRequest: ApplePayPaymentRequest = {
      total: paymentSheet.finalTotalLineItem,
      lineItems: paymentSheet.lineItems,
      requiredShippingContactFields: addressRequired
        ? APPLE_PAY_REQUIRED_FIELDS_FOR_SHIPPING
        : APPLE_PAY_REQUIRED_BASIC_CONTACT_FIELDS,

      recurringPaymentRequest: paymentSheet.recurringPaymentRequest,
    };

    const applePay = (recurly.ApplePay as IRecurlyApplePay)({
      country: 'US',
      currency: 'USD',
      requiredShippingContactFields: addressRequired
        ? APPLE_PAY_REQUIRED_FIELDS_FOR_SHIPPING
        : APPLE_PAY_REQUIRED_BASIC_CONTACT_FIELDS,

      callbacks: {
        onPaymentAuthorized: onPaymentAuthorized,
        onPaymentMethodSelected: async (_evt: ApplePayPaymentMethodSelectedEvent) => {
          return await updatePaymentSheet();
        },
        onShippingContactSelected: async (evt: ApplePayShippingContactSelectedEvent) => {
          paymentState.updateFromShippingContactSelected(evt);

          // We need to make sure to set shipping method options after a shipping address is provided
          return await updatePaymentSheet({
            updateShippingMethods: true,
          });
        },
        onShippingMethodSelected: async (evt: ApplePayShippingMethodSelectedEvent) => {
          paymentState.updateFromShippingMethodSelected(evt);
          return await updatePaymentSheet();
        },
      },
      paymentRequest,
    });

    applePay.on('cancel', () => {
      // Reset paymentState (selected address and shipping method) and cart pricing
      paymentState.clear();
      setCartPricing(contextCartPricing);

      events.applePayCancel();
      onCancel();
    });

    applePay.on('error', (error: Error) => {
      events.applePayError(error.message);
      onError(error);
    });

    applePay.on('ready', onReady);

    // We need to override recurly.js callback handlers to account for custom error codes:
    // https://github.com/recurly/recurly-js/blob/9accefa4627a92fe6990eeb4d850881c67c9c56c/lib/recurly/apple-pay/apple-pay.js#L326-L377
    // Our overrides add a special case for our custom error codes because we want to exit out of the payment sheet
    // and handle the error in our own UI.
    const maybeHandleCustomError = (
      update: FiApplePayErrorUpdate | FiApplePaySelectionUpdate,
      originalHandler: () => void,
    ) => {
      if ('customErrors' in update && update.customErrors && update.customErrors.length > 0) {
        // For any errors that aren't something we can display on the payment sheet, we need to abort the payment
        // session and handle the error in our own UI. In these cases, we can assume there is only 1 error returned.
        const firstError = update.customErrors[0];
        if (
          firstError.code === APPLE_PAY_ERROR_CODE_CUSTOM_ACCOUNT_EXISTS ||
          firstError.code === APPLE_PAY_ERROR_CODE_CUSTOM_API
        ) {
          applePay.session.abort();
          onCustomError(firstError);
          return;
        }
      }

      originalHandler();
    };

    const originalPaymentMethodSelected = applePay.onPaymentMethodSelected.bind(applePay);
    applePay.onPaymentMethodSelected = (
      event: ApplePayPaymentMethodSelectedEvent,
      update: FiApplePaySelectionUpdate = {},
    ) => {
      maybeHandleCustomError(update, () => {
        originalPaymentMethodSelected(event, update);
      });
    };

    const originalShippingContactSelected = applePay.onShippingContactSelected.bind(applePay);
    applePay.onShippingContactSelected = (
      event: ApplePayShippingContactSelectedEvent,
      update: FiApplePaySelectionUpdate = {},
    ) => {
      maybeHandleCustomError(update, () => {
        originalShippingContactSelected(event, update);
      });
    };

    const originalShippingMethodSelected = applePay.onShippingMethodSelected.bind(applePay);
    applePay.onShippingMethodSelected = (
      event: ApplePayShippingMethodSelectedEvent,
      update: FiApplePaySelectionUpdate = {},
    ) => {
      maybeHandleCustomError(update, () => {
        originalShippingMethodSelected(event, update);
      });
    };

    const originalPaymentAuthorized = applePay.onPaymentAuthorized.bind(applePay);
    applePay.onPaymentAuthorized = (event: ApplePayPaymentAuthorizedEvent, result: FiApplePayErrorUpdate = {}) => {
      maybeHandleCustomError(result, () => {
        originalPaymentAuthorized(event, result);
      });
    };

    return applePay;
  }, [
    cartPricing,
    useRecurringPaymentRequest,
    addressRequired,
    recurly.ApplePay,
    onPaymentAuthorized,
    onReady,
    updatePaymentSheet,
    paymentState,
    contextCartPricing,
    events,
    onCancel,
    onError,
    onCustomError,
  ]);
}
