import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import * as events from '../../lib/analytics/events';
import { logInternalError } from '../../lib/errors';
import { applyReferralCodeToCart, isValidCodeResult } from '../../lib/promoCode';
import { cartActions as storeCartActions } from '../../reducers/storeCart';
import * as types from '../../types';
import Loading from '../Loading';

export default function ReferralRedirect() {
  const cart = useSelector((state: types.AppState) => state.storeShop.cart);
  const dispatch = useDispatch();
  const location = useLocation();
  const { code } = useParams<{ code: string }>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function applyReferralCode() {
      try {
        const result = await applyReferralCodeToCart(cart, code);

        // When auto applying referral code, we only don't want to do it if it is not a valid code.
        // If it's other errors (e.g. "Not applicable to any items in cart") then we still want to apply it.
        if (isValidCodeResult(result)) {
          let referrerName = undefined;
          if (result.kind === 'success') {
            referrerName = result.value.referrerName;
          }
          dispatch(storeCartActions.addReferral({ code, referrerName }));
        }
      } catch (err) {
        logInternalError(err);
      } finally {
        setLoading(false);
      }
    }

    events.referralRedirect.loaded(code);
    applyReferralCode();
  }, [cart, code, dispatch]);

  if (loading) {
    return <Loading />;
  }

  return <Redirect to={`/${location.search}`} />;
}
