import React from 'react';
import styles from './CouponPill.module.scss';

interface CouponPillProps {
  title?: string;
  subtitle?: string;
}

export default function CouponPill({ title, subtitle }: CouponPillProps) {
  return (
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}
      {subtitle && <div className={styles.body}>{subtitle}</div>}
    </div>
  );
}
