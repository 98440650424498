import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { REFERRAL_COOKIE_NAME, ReferralCookieData } from '../lib/util/referralCookie';
import { cartActions as storeCartActions } from '../reducers/storeCart';
import { applyReferralCodeToCart, isValidCodeResult } from '../lib/promoCode';
import useApplyPromotionFromCookie from './useApplyPromotionFromCookie';

export default function useAutoApplyReferral() {
  const { finished } = useApplyPromotionFromCookie<ReferralCookieData>(
    useMemo(
      () => ({
        getCookieValue: () => {
          const dataValue = Cookies.getJSON(REFERRAL_COOKIE_NAME);
          if (dataValue) {
            return [
              dataValue,
              () => {
                /* Noop */
              },
            ];
          }

          return undefined;
        },

        applyPromotion: async ({ value, dispatch, cart }) => {
          const result = await applyReferralCodeToCart(cart, value.code);

          // When auto applying referral, we only don't want to do it if the referral is not a valid code.
          // If it's other errors (e.g. "Not applicable to any items in cart") then we still want to apply it.
          if (isValidCodeResult(result)) {
            dispatch(storeCartActions.addReferral({ code: value.code, referrerName: value.name }));
          } else {
            Cookies.remove(REFERRAL_COOKIE_NAME);
          }
        },
      }),
      [],
    ),
  );

  return { finished };
}
