import React, { useMemo, useReducer } from 'react';
import { Redirect } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import Button from '../../components/Button';
import Chooser from '../../components/Chooser';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import useGiftCardProducts from '../../hooks/useGiftCardProducts';
import { expectUnreachable } from '../../lib/util';
import DeliveryDetails from './components/DeliveryDetails/DeliveryDetails';
import styles from './GiftCard.module.scss';
import GiftCardContext, {
  emptyGiftCardState,
  GiftCardAction,
  IGiftCardState,
  setErrors,
  setSelectedId,
} from './GiftCardContext';
import { GIFT_CARD_IMAGES } from './GiftCardImages.constant';
import useCheckout from './useCheckout';
import useTracking from './useTracking';

/**
 * Configure details for the gift card.
 */
export default function GiftCard() {
  const giftCardProducts = useGiftCardProducts();
  const initialState: IGiftCardState = useMemo((): IGiftCardState => {
    return {
      ...emptyGiftCardState,
      giftCardProducts,
    };
  }, [giftCardProducts]);

  const [giftCardState, giftCardDispatch] = useReducer((state: IGiftCardState, action: GiftCardAction) => {
    if (action.type === 'SetSelectedId') {
      return { ...state, selectedId: action.newSelectedId };
    } else if (action.type === 'UpdateGiftCardDelivery') {
      return {
        ...state,
        delivery: { ...state.delivery, ...action.newValues },
      };
    } else if (action.type === 'SetErrors') {
      return { ...state, errors: action.errors };
    } else if (action.type === 'ClearError') {
      const { [action.errorName]: ignoredValue, ...newErrors } = state.errors;
      return { ...state, errors: newErrors };
    } else if (action.type === 'SetDeliverAtIsSet') {
      return { ...state, deliverAtIsSet: action.newValue };
    } else {
      expectUnreachable(action);
      return state;
    }
  }, initialState);

  const canContinue = giftCardState.selectedId !== null;

  const product = useMemo(
    () => giftCardProducts.find((p) => giftCardState.selectedId === p.id),
    [giftCardProducts, giftCardState.selectedId],
  );

  // Analytics
  useTracking(product);

  const { addedToBag, addToBag } = useCheckout((e) => {
    giftCardDispatch(setErrors(e));
  });

  if (addedToBag) {
    return <Redirect to={AppPaths.Bag} />;
  }

  return (
    <GiftCardContext.Provider value={{ giftCardState, giftCardDispatch }}>
      <div className={styles.giftCardView}>
        <div className={styles.imageCarousel}>
          <ImageGallery media={GIFT_CARD_IMAGES} />
        </div>

        <div className={styles.giftCardDetails}>
          <h1>Fi GIFT CARD</h1>
          <p>Select the gift card you'd like to send. We'll email the recipient a link to redeem their gift.</p>
          <p className={styles.finePrint}>Gift cards can be used for any collar kits or accessories on tryfi.com</p>
          <Chooser
            groups={giftCardProducts.map((giftCardProduct) => {
              return {
                options: [
                  {
                    value: giftCardProduct.id,
                    content: (
                      <div className={styles.giftCardChooser}>
                        <p className={styles.giftCardChooserTitle}>Gift card</p>
                        <p className={styles.giftCardChooserDenomination}>
                          <strong>{giftCardProduct.priceInCents / 100}</strong>
                        </p>
                        <p className={styles.giftCardChooserDescription}>{giftCardProduct.nameForBanner}</p>
                      </div>
                    ),
                  },
                ],
              };
            })}
            selectedOption={giftCardState.selectedId}
            onSelect={(useGiftCard) => giftCardDispatch(setSelectedId(useGiftCard))}
          />
          <DeliveryDetails />

          <Button
            className={styles.continueButton}
            onClick={() => {
              if (canContinue && product) {
                addToBag(product, giftCardState);
                return <Redirect to={AppPaths.Bag} />;
              } else {
                console.error('cannot continue!', canContinue, product);
              }
            }}
            disabled={!canContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </GiftCardContext.Provider>
  );
}
