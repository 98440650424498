import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import Button from '../../../../components/Button';
import ErrorMessage from '../../../../components/ErrorMessage';
import {
  RescuePlacementModel,
  RedirectToRescuePlacementHome,
  transferringOwnershipTitle,
  rescuePlacementMainQuery,
  AccountInformation,
} from '../rescuePlacementUtils';
import { gqlTypes } from '../../../../types';
import { gql, useMutation } from '@apollo/client';
import PetView from '../PetView/PetView';
import GoBackLink from '../components/GoBackLink/GoBackLink';
import EditCreditCardBillingInfo, {
  UpdateCreditCardBillingInfoFunction,
} from '../../../Checkout/pages/Billing/EditCreditCardBillingInfo';
import { UserFacingError, getCustomerMessageFromApolloError, logInternalError } from '../../../../lib/errors';
import Checkbox from '../../../../components/Checkbox';
import RecurlyProvider from '../../../../lib/RecurlyProvider';
import styles from './PetPermanentPlacement.module.scss';

const rescueTransferPetPermanentlyMutation = gql`
  mutation ECOMMERCE_rescueTransferPetPermanently($input: RescueTransferPetPermanentlyInput!) {
    rescueTransferPetPermanently(input: $input) {
      accountCreated
      newAccountTemporaryPassword
      billingAccount {
        billingInfo {
          firstName
          lastName
          address {
            line1
            line2
            city
            state
            zip
            country
          }
          paymentInfo {
            __typename
            ... on ObfuscatedCardInfo {
              lastFour
              type
              month
              year
            }
          }
        }
      }
    }
  }
`;

function PermanentPlacementSuccess({
  data,
  pet,
  email,
}: {
  data?: gqlTypes.ECOMMERCE_rescueTransferPetPermanently;
  pet: gqlTypes.petDetails;
  email: string;
}) {
  return (
    <div>
      <h2>Success!</h2>
      <p>
        Ownership of {pet.name} was transferred to <strong>{email}</strong>.
      </p>
      {data && data.rescueTransferPetPermanently && <AccountInformation data={data.rescueTransferPetPermanently} />}
    </div>
  );
}

interface NoBillingInfoFormProps {
  onSubmit(data: { firstName: string; lastName: string }): void;
  error?: string | null;
  submitting: boolean;
}

function NoBillingInfoForm({ onSubmit, submitting, error: errorFromProps }: NoBillingInfoFormProps) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const errorToShow = error || errorFromProps;

  const doSubmit = () => {
    if (!firstName || !lastName) {
      setError(`Please enter a valid first and last name.`);
      return;
    }
    onSubmit({ firstName, lastName });
  };

  return (
    <div className={styles.main}>
      <p>
        <strong>Important!</strong> If you don't enter billing information for the adopter they will have to activate
        their Fi subscription for themselves.
      </p>
      <p>They will receive an email with the gift card redemption code, that they can then redeem within the app.</p>
      <div className={classNames(styles.basicForm, styles.wide)}>
        {errorToShow && (
          <div className={styles.textDanger}>
            <div>{errorToShow}</div>
          </div>
        )}
        <div className={styles.formRow}>
          <input
            type="text"
            placeholder="First name"
            value={firstName}
            onChange={(e) => {
              setError(null);
              setFirstName(e.currentTarget.value.trim());
            }}
          />
          <input
            type="text"
            placeholder="Last name"
            value={lastName}
            onChange={(e) => {
              setError(null);
              setLastName(e.currentTarget.value.trim());
            }}
          />
        </div>
      </div>
      <div className={styles.actionContainer}>
        <Button disabled={submitting} type="submit" onClick={doSubmit}>
          TRANSFER
        </Button>
      </div>
    </div>
  );
}

async function validateGiftCardCode(code: string) {
  const provider = new RecurlyProvider();
  const pricing = provider.value.Pricing.Checkout();
  try {
    await pricing.giftCard(code);
    return true;
  } catch (err) {
    console.warn(err);
    return false;
  }
}

export default function PetPermanentPlacement({ model, petId }: { model: RescuePlacementModel; petId: string }) {
  const [error, setError] = useState<string | null>(null);
  const [transferPetPermanently, { data }] = useMutation<
    gqlTypes.ECOMMERCE_rescueTransferPetPermanently,
    gqlTypes.ECOMMERCE_rescueTransferPetPermanentlyVariables
  >(rescueTransferPetPermanentlyMutation, {
    refetchQueries: [{ query: rescuePlacementMainQuery }],
  });

  // Note: after the pet is transferred the current user won't have access to it,
  // so after the refetch query runs it will be gone from the model. In order to
  // properly display a "success" message, keep the pet in React state that will
  // remain unchanged.
  const [pet] = useState(() => model.getPetById(petId));

  const [email, setEmail] = useState('');
  const [giftCardRedemptionCode, setGiftCardRedemptionCode] = useState('');
  const [complete, setComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [noBilling, setNoBilling] = useState(false);
  const [noBillingError, setNoBillingError] = useState<string | null>(null);

  const doTransfer = useCallback(
    async ({
      firstName,
      lastName,
      billingAccountInput,
    }: {
      firstName: string;
      lastName: string;
      billingAccountInput?: gqlTypes.BillingAccountInput;
    }) => {
      setSubmitting(true);
      try {
        await transferPetPermanently({
          variables: {
            input: {
              billingAccountInput,
              firstName,
              lastName,
              email,
              giftCardRedemptionCode,
              petId,
            },
          },
        });
      } finally {
        setSubmitting(false);
      }

      setComplete(true);
    },
    [email, giftCardRedemptionCode, petId, transferPetPermanently],
  );

  const noBillingSubmit = useCallback(
    ({ firstName, lastName }: { firstName: string; lastName: string }) => {
      if (!email) {
        setNoBillingError(`Please enter an email address above!`);
        return;
      }
      if (!giftCardRedemptionCode) {
        setNoBillingError(`Please enter a gift card redemption code above!`);
        return;
      }

      validateGiftCardCode(giftCardRedemptionCode)
        .then((valid) => {
          if (valid) {
            return doTransfer({ firstName, lastName }).catch((err) => {
              const customerMessage = getCustomerMessageFromApolloError(err);
              if (customerMessage) {
                setNoBillingError(customerMessage);
              } else {
                logInternalError(err);
                setNoBillingError(
                  'Failed to process adoption transfer. Please try again or contact support@tryfi.com if the problem persists.',
                );
              }
            });
          } else {
            throw new Error('Invalid gift card code');
          }
        })
        .catch(() => {
          setNoBillingError(`That gift card code was not found. Please enter a valid gift card code.`);
        });
    },
    [doTransfer, email, giftCardRedemptionCode],
  );

  const updateBillingInfo: UpdateCreditCardBillingInfoFunction = useCallback(
    async ({ input: billingAccountInput, firstName, lastName }) => {
      if (!email) {
        throw new UserFacingError(`Please enter an email address above!`);
      }
      if (!giftCardRedemptionCode) {
        throw new UserFacingError(`Please enter a gift card redemption code above!`);
      }
      await doTransfer({
        billingAccountInput,
        firstName,
        lastName,
      });
    },
    [doTransfer, email, giftCardRedemptionCode],
  );

  if (!pet) {
    return <RedirectToRescuePlacementHome />;
  }

  let body: JSX.Element;

  if (complete) {
    body = <PermanentPlacementSuccess data={data ?? undefined} email={email} pet={pet} />;
  } else {
    body = (
      <div className={styles.main}>
        <h2>Transfer to an adopter</h2>
        <div>
          <div className={classNames(styles.basicForm, styles.wide)}>
            <h3>Email address</h3>
            <p>Enter the email address of the user you wish to transfer this pet to.</p>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setNoBillingError(null);
                setEmail(e.currentTarget.value.trim());
              }}
              data-hj-whitelist
            />
          </div>
        </div>
        <div>
          <div className={classNames(styles.basicForm, styles.wide)}>
            <h3>Gift card code</h3>
            <p>
              Enter the code for one of the gift cards you have purchased. This will cover the first year of the GPS
              subscription.
            </p>
            <input
              type="text"
              placeholder="Gift card code"
              value={giftCardRedemptionCode}
              onChange={(e) => {
                setNoBillingError(null);
                setGiftCardRedemptionCode(e.currentTarget.value);
              }}
              data-hj-whitelist
            />
          </div>
        </div>
        <div>
          <div className={styles.wide}>
            <h3>Billing</h3>
            <Checkbox
              checked={!noBilling}
              onChange={(value) => setNoBilling(!value)}
              label="I have billing information for the adopter"
            />
            {noBilling ? (
              <NoBillingInfoForm submitting={submitting} onSubmit={noBillingSubmit} error={noBillingError} />
            ) : (
              <>
                <p>
                  Enter the billing information for the adopter. This is necessary to apply the gift card to their Fi
                  account. This payment information will be used to charge for their subscription renewal in one year.
                </p>
                <p className={styles.rescuePlacementDisclaimer}>
                  (Note: even though the $99 gift card will be applied towards their first year of the subscription
                  plan, this card might be charged a few dollars immediately if their state charges tax.)
                </p>
                <EditCreditCardBillingInfo
                  submitting={submitting}
                  setError={(errorMessage) => setError(errorMessage)}
                  clearError={() => setError(null)}
                  updateBillingInfo={updateBillingInfo}
                  actionText="TRANSFER"
                />
                {error && <ErrorMessage errors={[error]} />}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <GoBackLink />
      <PetView pet={pet} title={transferringOwnershipTitle(pet)} />
      {body}
    </div>
  );
}
