import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import Button from '../../../../components/Button';
import { SubscriptionAppBarSpacer } from '../../components/SubscriptionAppBar';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';

export default function DowngradeS3Confirmed() {
  const { device } = usePurchaseSubscriptionContext();
  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBarSpacer />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <h1>Your plan has been updated!</h1>

            <p>
              Thank you for being a Fi member. Your Series 3 membership will be billed as per your new plan at renewal.
            </p>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              events.downgradeS3ConfirmationDone({ petId: device.pet?.id });
              window.open('fi://closeappview');
            }}
          >
            Done
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
