import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { gqlTypes } from '../../../../types';
import ActionContainer from '../../../../components/ActionContainer';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import * as events from '../../../../lib/analytics/events';
import * as types from '../../../../types';
import styles from './Shipping.module.scss';

enum SelectedAddressOption {
  Input = 'input',
  Matched = 'matched',
}

function AddressDetails({ address }: { address: types.Address }) {
  // sentry-mask class is used to mask text in Sentry replays
  return (
    <div className={classNames(styles.address, 'sentry-mask')}>
      {address.line1}
      <br />
      {address.line2}
      {address.line2 && <br />}
      {address.city}, {address.state} {address.zip}
    </div>
  );
}

interface ConfirmButtonProps {
  address: types.Address;
  onSubmit: (selectedAddress: types.Address) => void;
}

function ConfirmButton({ address, onSubmit }: ConfirmButtonProps) {
  return (
    <Button
      className={styles.selectAddressButton}
      onClick={() => {
        events.shipping.verifyAddressConfirmed();

        onSubmit(address);
      }}
    >
      Use this address
    </Button>
  );
}

interface MatchedAddressLayoutProps {
  inputAddress: types.Address;
  matchedAddress: types.Address;
  onSubmit: (selectedAddress: types.Address) => void;
}

function MatchedAddressLayout({ inputAddress, matchedAddress, onSubmit }: MatchedAddressLayoutProps) {
  const [selectedAddress, setSelectedAddress] = useState(SelectedAddressOption.Matched);

  return (
    <div>
      <p>To ensure accurate delivery, consider using the suggested address below.</p>
      <ul className={styles.addressList}>
        <li>
          <h4>We suggest:</h4>
          <div className={styles.addressItem}>
            <Checkbox
              className={styles.checkbox}
              checked={selectedAddress === SelectedAddressOption.Matched}
              onChange={() => {
                setSelectedAddress(SelectedAddressOption.Matched);
              }}
              label={<AddressDetails address={matchedAddress} />}
            />
            <div
              className={classNames(styles.cta, {
                [styles.invisible]: selectedAddress !== SelectedAddressOption.Matched,
              })}
            >
              <ConfirmButton address={matchedAddress} onSubmit={onSubmit} />
            </div>
          </div>
        </li>
        <li>
          <h4>You entered:</h4>
          <div className={styles.addressItem}>
            <Checkbox
              className={styles.checkbox}
              checked={selectedAddress === SelectedAddressOption.Input}
              onChange={() => {
                setSelectedAddress(SelectedAddressOption.Input);
              }}
              label={<AddressDetails address={inputAddress} />}
            />
            {matchedAddress && (
              <div
                className={classNames(styles.cta, {
                  [styles.invisible]: selectedAddress !== SelectedAddressOption.Input,
                })}
              >
                <ConfirmButton address={inputAddress} onSubmit={onSubmit} />
              </div>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
}

interface UnverifiedAddressLayoutProps {
  inputAddress: types.Address;
  onSubmit: (selectedAddress: types.Address) => void;
  onCancel: () => void;
}

function UnverifiedAddressLayout({ inputAddress, onSubmit, onCancel }: UnverifiedAddressLayoutProps) {
  return (
    <div>
      <p>
        The shipping address you entered is not recognized by the United States Postal Service. Please verify your
        address to ensure accurate delivery of your order.
      </p>
      <ul className={styles.addressList}>
        <li>
          <h4>You entered:</h4>
          <div className={styles.addressItem}>
            <Checkbox
              className={styles.checkbox}
              checked={true}
              onChange={() => {}}
              label={<AddressDetails address={inputAddress} />}
            />
          </div>
        </li>
      </ul>
      <ActionContainer>
        <ConfirmButton address={inputAddress} onSubmit={onSubmit} />
        <Button
          secondary
          onClick={() => {
            events.shipping.verifyAddressEdit();
            onCancel();
          }}
        >
          Edit address
        </Button>
      </ActionContainer>
    </div>
  );
}

interface ModalBodyProps {
  status: gqlTypes.ValidateAddressResponseStatus | null;
  inputAddress: types.Address | null;
  matchedAddress: types.Address | null;
  onCancel: () => void;
  onSubmit: (selectedAddress: types.Address) => void;
}

function ModalBody({ inputAddress, matchedAddress, status, onSubmit, onCancel }: ModalBodyProps) {
  if (!inputAddress) {
    return null;
  }

  if (matchedAddress && status !== gqlTypes.ValidateAddressResponseStatus.NOT_VERIFIED) {
    return <MatchedAddressLayout matchedAddress={matchedAddress} inputAddress={inputAddress} onSubmit={onSubmit} />;
  }

  return <UnverifiedAddressLayout inputAddress={inputAddress} onSubmit={onSubmit} onCancel={onCancel} />;
}

interface AddressConfirmationModalProps extends ModalBodyProps {
  open: boolean;
}

export default function AddressConfirmationModal({
  open,
  inputAddress,
  matchedAddress,
  status,
  onSubmit,
  onCancel,
}: AddressConfirmationModalProps) {
  useEffect(() => {
    if (open) {
      events.shipping.verifyAddressViewed();
    }
  }, [open]);

  // Use static class name for Popup instead of css module because that component munges the class name internally
  return (
    <Popup className="s3-popup" open={open} closeOnDocumentClick={false} onClose={onCancel}>
      <div className={styles.addressConfirmationModal}>
        <h3>Verify your shipping address</h3>
        <ModalBody
          status={status}
          inputAddress={inputAddress}
          matchedAddress={matchedAddress}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </div>
    </Popup>
  );
}
