export function minBy<A, B>(arr: A[], keyFn: (x: A) => B): A | null {
  if (arr.length === 1) {
    return arr[0]; // Don't bother invoking keyFn
  }
  let minElem: A | null = null;
  let minKey: B | null = null;
  for (const elem of arr) {
    const key = keyFn(elem);
    // eslint-disable-next-line eqeqeq
    if (minKey == null || key < minKey) {
      minElem = elem;
      minKey = key;
    }
  }
  return minElem;
}
