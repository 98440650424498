import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AffiliateData } from '../types';

const initialState: AffiliateData = {};

export interface ISetCJEvent {
  cjevent: string;
}

const name = 'affiliateData' as const;

const { actions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    setCJEvent(state: AffiliateData, { payload }: PayloadAction<ISetCJEvent>) {
      return {
        cjevent: payload.cjevent,
        ...state,
      };
    },
  },
});

export const affiliateDataActions = actions;
export const affiliateDataReducer = reducer;
export const affiliateDataInitialState = { [name]: initialState };
