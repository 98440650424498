import { Elements, useRecurly } from '@recurly/react-recurly';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PayPalLogo } from '../../assets/images/paypal.svg';
import FiRecurlyProvider from '../../components/FiRecurlyProvider';
import useIsPayPalEnabled from '../../hooks/useIsPayPalEnabled';
import CheckoutContext from '../../lib/CheckoutContext';
import hotjar from '../../lib/analytics/HotJar';
import { skusForCartItem } from '../../lib/cart';
import { getProductsBySku } from '../../lib/product';
import { notEmpty } from '../../lib/util';
import * as types from '../../types';
import { isModuleSubscriptionProduct } from '../../types';
import Button from '../Button';
import { logInternalError } from '../../lib/errors';

interface PayPalError extends Error {
  cause: {
    code: string;
    message: string;
  };
  code: string;
  name: string;
}

function isPayPalError(err: any): err is PayPalError {
  return err?.code && err?.name && err?.cause && err.cause?.message && err.cause?.code;
}

interface PayPalEvents {
  paypalUnavailable(products: types.IProduct[]): void;
}

interface PayPalButtonEvents {
  paypalButtonBegin(): void;
  paypalButtonSuccess(): void;
  paypalButtonError(message: string): void;
  paypalButtonCancel(): void;
}

interface PayPalButtonProps {
  events: PayPalButtonEvents & PayPalEvents;
  onComplete(result: types.BillingToken): void;
  onError(error: Error): void;
  onStart?(): void;
  onCancel?(): void;
}

enum PayPalPopupState {
  Open,
  Closed,
}

export function validatePayPalTender(
  cartItemsInCart: types.CartItem[],
  products: types.IProduct[],
  events?: PayPalEvents,
): Error | undefined {
  const productSkuMap = getProductsBySku(products);
  const productsInCartUnavailableForPayPal = cartItemsInCart
    .flatMap((cartItem) => skusForCartItem(cartItem).map((sku) => productSkuMap.get(sku)))
    .filter(notEmpty)
    .filter((product) => (product.unavailableForTenders ?? []).includes(types.TenderType.PayPal));

  if (productsInCartUnavailableForPayPal.length > 0) {
    events?.paypalUnavailable(productsInCartUnavailableForPayPal);
    const allSubscriptions = productsInCartUnavailableForPayPal.every((product) =>
      isModuleSubscriptionProduct(product),
    );

    let message: string;
    // Small hack to get the right wording
    if (allSubscriptions) {
      message = 'Sorry 2 & 3 year plans are not supported by PayPal yet.';
    } else {
      // Backup wording in case we limit more products and forget
      const productNames = productsInCartUnavailableForPayPal.map((product) => product.name).join(', ');
      const isAre = productsInCartUnavailableForPayPal.length > 2 ? 'are' : 'is';
      message = `Sorry ${productNames} ${isAre} not supported by PayPal yet.`;
    }

    return new Error(message);
  }
}

function PayPalButton({ events, onStart, onComplete, onCancel, onError }: PayPalButtonProps) {
  const isPayPalEnabled = useIsPayPalEnabled();
  const recurly = useRecurly();
  const paypal = useMemo(
    () =>
      recurly.PayPal({
        display: {
          displayName: 'Fi Smart Dog Collar',
        },
      }),
    [recurly],
  );
  const [payPalPopupState, setPayPalPopupState] = useState(PayPalPopupState.Closed);

  const products = useSelector((state: types.AppState) => state.config.products);

  const { cart } = useContext(CheckoutContext);
  const cartItems = useMemo(() => Object.values(cart.cartItems), [cart]);
  const disabled = useMemo(() => payPalPopupState !== PayPalPopupState.Closed, [payPalPopupState]);

  const handleStart = useCallback(
    (evt: React.MouseEvent | React.TouchEvent) => {
      // It's important to prevent the default action because we fire both onClick and onTouchEnd and this
      // should prevent the action from firing twice
      // https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Supporting_both_TouchEvent_and_MouseEvent
      evt.preventDefault();

      // Only fire this if the popup is closed, this way we don't open multiple
      // PayPal windows which can confuse things
      if (payPalPopupState === PayPalPopupState.Closed) {
        const validationError = validatePayPalTender(cartItems, products, events);

        if (validationError) {
          alert(validationError.message);
        } else {
          setPayPalPopupState(PayPalPopupState.Open);
          events.paypalButtonBegin();

          // The start function must be called within a user-initiated event like ‘click’ or ‘touchend’
          // or the window will be blocked as a pop-up
          // See https://developers.recurly.com/reference/recurly-js/#paypal
          paypal.start();
          onStart && onStart();

          hotjar.tagRecording(['PayPal']);
        }
      }
    },
    [cartItems, paypal, payPalPopupState, products, setPayPalPopupState, onStart, events],
  );

  const handleFinished = useCallback(() => {
    paypal.off();
    setPayPalPopupState(PayPalPopupState.Closed);
  }, [paypal, setPayPalPopupState]);

  useEffect(() => {
    // This conditional is added because the event listeners seem to be removed after the window
    // is closed, so to keep working if the user opens / closes the window multiple times
    // we re-attach these listeners whenever the popup state is "closed"
    if (payPalPopupState === PayPalPopupState.Closed) {
      paypal.on('token', (token: any) => {
        handleFinished();
        events.paypalButtonSuccess();
        onComplete(token);
      });

      paypal.on('error', (err: any) => {
        handleFinished();

        // If you click the "Cancel and return to XYZ's Test Store" at the bottom of the PayPal
        // window, this path is fired. Oddly, this doesn't happen if you just close the window
        if (isPayPalError(err) && err.cause.code === 'paypal-canceled') {
          events.paypalButtonCancel();
          onCancel && onCancel();
        } else {
          logInternalError(err, {
            fingerprint: ['{{ default }}', err.message],
            tags: {
              paymentMethod: 'paypal',
              checkoutStep: 'paypalToken',
            },
          });
          events.paypalButtonError(err.message);
          onError(err);
        }
      });

      paypal.on('cancel', () => {
        handleFinished();
        events.paypalButtonCancel();
        onCancel && onCancel();
      });
    }
  }, [payPalPopupState, events, paypal, handleFinished, onComplete, onCancel, onError]);

  if (!isPayPalEnabled) {
    return null;
  }

  // We use onTouchEnd and onClick so Safari works on mobile
  return (
    <Button disabled={disabled} onTouchEnd={handleStart} onClick={handleStart}>
      <PayPalLogo />
    </Button>
  );
}

// Anything that uses the `useRecurly` hook must be wrapped in Recurly's <Elements /> component
export default function PayPalButtonWrapper(props: PayPalButtonProps) {
  return (
    <FiRecurlyProvider nonEssential>
      <Elements>
        <PayPalButton {...props} />
      </Elements>
    </FiRecurlyProvider>
  );
}
