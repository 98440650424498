import React from 'react';
import { AppState, ProductCategory, IGiftCardProduct } from '../../types';
import { useSelector } from 'react-redux';
import NotFound from '../NotFound';
import { RESCUE_GIFT_CARD_PRODUCT_ID } from '../../lib/RescueGiftCardProductId.constant';
import { Rescue } from './Rescue';

interface RescueContainerProps {
  addToBag?: (product: IGiftCardProduct, emailAddress: string) => void;
}

export default function RescueContainer({ addToBag }: RescueContainerProps) {
  const products = useSelector((state: AppState) => state.config.products);

  const giftCardProducts = products.flatMap((p) =>
    p.id === RESCUE_GIFT_CARD_PRODUCT_ID && p.category === ProductCategory.GIFT_CARD ? [p] : [],
  );

  if (giftCardProducts.length === 0) {
    return <NotFound />;
  }

  return <Rescue addToBag={addToBag} product={giftCardProducts[0]} />;
}
