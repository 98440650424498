import React from 'react';
import { ReactComponent as IconMessage } from '../../../../../assets/images/icons/icon_message.svg';
import { ReactComponent as IconPhone } from '../../../../../assets/images/icons/icon_phone.svg';
import { ReactComponent as IconWarranty } from '../../../../../assets/images/icons/icon_warranty.svg';
import styles from '../../../ProductDetails.module.scss';
import ValuePropsBox from '../ValuePropsBox';
import { showNanoVariant } from '../../../../../components/NanoBanner/NanoBanner';
import { useLocation } from 'react-router-dom';

interface MembershipValuePropsProps {
  inAppManagementVariant?: boolean;
}

export default function MembershipValueProps({ inAppManagementVariant = false }: MembershipValuePropsProps) {
  const { pathname } = useLocation();
  const nanoVariantEnabled = showNanoVariant();
  const isGiftingPDP = pathname.includes('gift-fi');
  const headerText = isGiftingPDP ? 'Gift Membership includes' : 'Membership includes';

  return (
    <div className={styles.valuePropsSection}>
      {!nanoVariantEnabled && (
        <ValuePropsBox
          header={<div className={styles.subSectionHeader}>{headerText}</div>}
          valueProps={[
            {
              icon: <IconWarranty />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>Fi Series 3 Warranty</span> with unlimited replacements
                    on active memberships.
                  </span>
                </div>
              ),
            },
            {
              icon: <IconMessage />,
              text: (
                <div className={styles.valueProp}>
                  <div className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>7 days a week customer service</span> with email and
                    live chat support.
                  </div>
                </div>
              ),
            },
            {
              icon: <IconPhone />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>Premium Phone Support</span> with all plans.
                  </span>
                </div>
              ),
            },
          ]}
        />
      )}
      {!inAppManagementVariant && !isGiftingPDP && (
        <>
          <div className={styles.multipleDogsHeader}>Have multiple dogs?</div>
          <div className={styles.multipleDogsDescription}>Get 5% off all Fi memberships & no activation fee.</div>
        </>
      )}
    </div>
  );
}
