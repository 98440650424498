import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionOption } from '../../components/SubscriptionOptionDetails';
import { useSubscriptionCartMode } from '../../lib/cartModes';
import { useLocation } from 'react-router-dom';
import { thankYou as events } from '../../lib/analytics/events';
import React, { useEffect } from 'react';
import { subscriptionShopActions } from '../../reducers/subscriptionShop';
import { Image } from '../../components/Image';
import styles from './MonthlyUpgradeSelection.module.scss';
import { centsToDollarsNumber } from '../../lib/util';
import Button from '../../components/Button';

import CollarHero from '../../assets/images/collar_hero_blue_3.png';
import Check from '../../assets/images/icons/check_green.svg';

export interface MonthlyUpgradeCompleteState {
  subscriptionDescription?: string;
  subscriptionProduct?: SubscriptionOption;
  chargedTodayCents: number;
  originalPeriodCostCents: number;
  creditAppliedCents: number;
}

export default function MonthlyUpgradeComplete() {
  const { cartSelector } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  const dispatch = useDispatch();
  const location = useLocation<MonthlyUpgradeCompleteState>();
  events.viewed();

  const subscriptionProduct = location.state.subscriptionProduct;
  const chargedTodayCents = location.state.chargedTodayCents;
  const originalPeriodCostCents = location.state.originalPeriodCostCents;
  const hasDiscount = chargedTodayCents < originalPeriodCostCents;
  const subscriptionDescription = location.state.subscriptionDescription;
  const originalSubscriptionDescription = 'monthly';
  const creditAppliedCents = location.state.creditAppliedCents;

  useEffect(() => {
    if (Object.keys(cart.cartItems).length > 0) {
      dispatch(subscriptionShopActions.resetCart());
      dispatch(subscriptionShopActions.resetCheckout());
    }
  }, [cart.cartItems, dispatch]);

  return (
    <div className={styles.simpleFullWidthWrapper}>
      <div className={styles.simpleContainer}>
        <div className={styles.simpleSpacer}></div>
        <div className={styles.simpleHeaderContainer}>
          <h1 className={styles.simpleHeader}>Thank You</h1>
          <p className={styles.simpleSubheader}>
            You've successfully switched your {originalSubscriptionDescription} plan to a {subscriptionDescription}{' '}
            prepaid plan.
          </p>
        </div>
        <div className={styles.offerContainer}>
          <div className={styles.offerDetailsContainer}>
            <p className={styles.upsellSubscriptionTermText}>{subscriptionDescription}</p>
            <div className={styles.pricesContainer}>
              <p className={styles.upsellSubscriptionPriceText}>
                ${Math.ceil(centsToDollarsNumber(chargedTodayCents))}
              </p>
              {hasDiscount && (
                <p className={styles.currentSubscriptionPriceText}>${centsToDollarsNumber(originalPeriodCostCents)}</p>
              )}
            </div>
            {hasDiscount && (
              <p className={styles.savingsText}>
                You're saving ${Math.floor(centsToDollarsNumber(originalPeriodCostCents - chargedTodayCents))} over the
                next {subscriptionProduct?.renewalMonths} months
                {creditAppliedCents > 0 && (
                  <span>
                    {' '}
                    with a rollover credit of ${Math.round(centsToDollarsNumber(creditAppliedCents))} from recent bill
                  </span>
                )}
                .
              </p>
            )}
            <Image className={styles.checkmark} image={{ type: 'image', url: Check }} />
          </div>
        </div>
        <div className={styles.collarContainer}>
          <Image className={styles.collarImage} image={{ type: 'image', url: CollarHero }} />
        </div>
        <Button
          className={styles.simpleButton}
          onClick={() => {
            window.open('fi://closeappview');
          }}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
