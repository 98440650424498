import React, { useRef, useState } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import AppPaths from '../../../../AppPaths';
import { useHistory, useLocation } from 'react-router-dom';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { Carousel, CarouselController } from '../../../../components/Carousel';
import { AppState, gqlTypes } from '../../../../types';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import classNames from 'classnames';
import { petsWithActiveSubscriptionQuery, hasMultidogDiscountQuery } from '../../../../graphql-operations';
import { useQuery } from '@apollo/client';
import lunaStepCountImg from '../../../../assets/images/in_app_cancellation/luna_step_count.png';
import maxLostDogImg from '../../../../assets/images/in_app_cancellation/max_lost_dog_map.png';
import featureIconChartImg from '../../../../assets/images/in_app_cancellation/feature_icon_chart.png';
import featureIconMapMarkerImg from '../../../../assets/images/in_app_cancellation/feature_icon_map_marker.png';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';
import { BaseInAppCancellationState, SubscriptionCategory } from '../util/types';
import { useSelector } from 'react-redux';

export default function TooExpensive() {
  const history = useHistory();
  const location = useLocation<BaseInAppCancellationState>();
  const { subscriptionCategory } = location.state;
  const { device } = usePurchaseSubscriptionContext();
  const [selectedPageIdx, setSelectedPageIdx] = useState(0);
  const [showNextButton, setShowNextButton] = useState(true);
  const carouselControllerRef = useRef<CarouselController>();
  events.pageLoad({ petId: device.pet!.id, subscriptionCategory }, 'Too Expensive');
  const { mutation, loading } = useZendeskCancellationTicket({
    analyticsEvent: events.dogDoesNotRunAwayCancel,
    selectedCancelReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE,
    device,
    subscriptionCategory,
  });
  const query = useQuery<gqlTypes.ECOMMERCE_petsWithActiveSubscription>(petsWithActiveSubscriptionQuery, {});
  const multidogDiscountQuery = useQuery<gqlTypes.ECOMMERCE_hasMultidogDiscount>(hasMultidogDiscountQuery, {});
  const showMonthToMonthSpecialOffers = useSelector(
    (state: AppState) => state.config.siteConfig.showMonthToMonthSpecialOffers ?? false,
  );

  if (!device.subscription?.id) {
    throw new Error('No recurly subscription found.');
  }

  if (loading) {
    return loading;
  }

  const petsWithActiveSubscription = query.data?.petsWithActiveSubscription ?? [];
  const isMultidogHousehold = petsWithActiveSubscription.length > 1;
  const hasMultidogDiscount = multidogDiscountQuery.data?.currentUser.hasMultidogDiscount ?? false;
  const offerMultidogDiscount = isMultidogHousehold && !hasMultidogDiscount;
  const offerFreeMonth = !!device.shouldOfferFreeMonthDuringCancellation;
  const isS3Device = device.currentCollarModel === 'S3';
  const offerS3Downgrade = isS3Device && !!device.shouldOfferDowngradeDuringCancellation;

  const handleKeep = () => {
    events.tooExpensiveKeepMembership({ petId: device.pet?.id, subscriptionCategory });
    history.push({
      pathname: AppPaths.Subscription.KeepMembershipConfirmation(device.moduleId),
      state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE, subscriptionCategory },
      search: window.location.search,
    });
  };

  const handleRetentionOffers = () => {
    events.tooExpensiveContinue({ petId: device.pet?.id, subscriptionCategory });

    if (subscriptionCategory === SubscriptionCategory.MONTHLY && showMonthToMonthSpecialOffers) {
      // Take S3 monthly users to special offers screen.
      history.push({
        pathname: AppPaths.Subscription.SpecialOffers(device.moduleId),
        state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE, subscriptionCategory },
        search: window.location.search,
      });
    } else if (offerMultidogDiscount) {
      history.push({
        pathname: AppPaths.Subscription.MultidogDiscount(device.moduleId),
        search: window.location.search,
        state: { subscriptionCategory },
      });
    } else if (offerS3Downgrade) {
      history.push({
        pathname: AppPaths.Subscription.DowngradeS3(device.moduleId),
        state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE, subscriptionCategory },
        search: window.location.search,
      });
    } else if (offerFreeMonth) {
      history.push({
        pathname: AppPaths.Subscription.FreeMonthOffer(device.moduleId),
        state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE, subscriptionCategory },
        search: window.location.search,
      });
    } else {
      throw new Error('Something went wrong.');
    }
  };

  const handleCancel = () => {
    events.tooExpensiveContinue({ petId: device.pet?.id, subscriptionCategory });
    mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription!.id,
          cancellationReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() =>
            events.tooExpensiveBack({ petId: device.pet?.id, subscriptionCategory }, selectedPageIdx + 1)
          }
          closeButtonAnalyticsEvent={() =>
            events.tooExpensiveClose({ petId: device.pet?.id, subscriptionCategory }, selectedPageIdx + 1)
          }
        />
        <div className={styles.subscriptionContainer}>
          <>
            <div className={styles.imagesCarouselContainer}>
              <Carousel
                cellAlign="center"
                className={styles.imageCarousel}
                pageDots={true}
                controllerRef={carouselControllerRef}
                prevNextButtons={true}
                onChange={(idx) => {
                  idx === 2 ? setShowNextButton(false) : setShowNextButton(true);
                  const analyticsPageIdx = selectedPageIdx + 1;
                  if (idx > selectedPageIdx) {
                    events.tooExpensiveNext({ petId: device.pet?.id, subscriptionCategory }, analyticsPageIdx);
                  } else if (idx < selectedPageIdx) {
                    events.tooExpensiveBack({ petId: device.pet?.id, subscriptionCategory }, analyticsPageIdx);
                  }
                  setSelectedPageIdx(idx);
                }}
              >
                <div className={styles.imageCarouselImage}>
                  <div className={styles.largeHeader}>
                    <h1>Health monitoring saved Luna</h1>
                  </div>
                  <img
                    className={styles.stepCount}
                    src={lunaStepCountImg}
                    alt="A bar graph showing declining step count day over day"
                  />
                  <p>
                    <strong>Fi can tell you more than just dog location.</strong>
                    <br />
                    Active Frenchie Luna had taken a 20,000-step plunge in activity, and a quick trip to the vet
                    confirmed what the data was suggesting: Luna had swallowed a rock. Data from Fi led to the
                    intervention that saved her life!
                  </p>
                </div>
                <div className={classNames(styles.imageCarouselImage, styles.xray)}>
                  <div className={styles.largeHeader}>
                    <h1>Lost Dog Mode saved Max</h1>
                  </div>
                  <img src={maxLostDogImg} alt="Map showing a lost dog" />
                  <p>
                    <strong>Because every second counts.</strong>
                    <br />
                    German Shepherd Max spotted a squirrel and took off. His owner Matt immediately put Fi into Lost Dog
                    Mode, and found Max in a nearby creek. Without Fi, Matt wouldn't have found him so fast, or maybe at
                    all.
                  </p>
                </div>
                <div className={styles.imageCarouselImage}>
                  <div className={styles.largeHeader}>
                    <h1>A dog’s health and safety will always be our #1 priority</h1>
                  </div>
                  <div className={styles.featureDescription}>
                    <img src={featureIconChartImg} alt="Chart icon" className={styles.icon} />
                    <p>
                      <strong>Full Health Monitoring.</strong> Monitor your dog's activity and sleep. Soon you'll be
                      able to also track advanced behaviors—like licking—that offer deeper insight into your dog's
                      health.
                    </p>
                  </div>
                  <div className={styles.featureDescription}>
                    <img src={featureIconMapMarkerImg} alt="Map marker icon" className={styles.icon} />
                    <p>
                      <strong>Lost Dog Mode.</strong> When your dog goes missing, now you can track every step in
                      real-time on the map to make recovery even faster.
                    </p>
                  </div>
                  <div className={styles.featureDescription}>
                    <p className={styles.finalThought}>
                      Hundreds of thousands of dog parents trust Fi to be there when it counts.
                    </p>
                  </div>
                </div>
              </Carousel>
            </div>
          </>
        </div>
        <div className={styles.buttonContainer}>
          {showNextButton ? (
            <>
              <Button
                className={styles.button}
                onClick={() => {
                  const temp: any = document.querySelector('.flickity-button.flickity-prev-next-button.next');
                  temp.click();
                }}
              >
                Next
              </Button>
              {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
              <div className={styles.buttonSpacer}></div>
            </>
          ) : (
            <>
              <Button className={styles.button} onClick={handleKeep}>
                Keep Membership
              </Button>

              {showMonthToMonthSpecialOffers || offerS3Downgrade || offerFreeMonth || offerMultidogDiscount ? (
                <Button onClick={handleRetentionOffers} className={styles.button} tertiary={true}>
                  Continue with Cancellation
                </Button>
              ) : (
                <CancelConfirmationModal
                  onContinue={() => handleCancel()}
                  pageName={'Too Expensive'}
                  petId={device.pet!.id}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
