import React from 'react';

import Button from '../../../../components/Button';
import * as events from '../../../../lib/analytics/events';
import styles from '../../../../components/modals/modals.module.scss';
import classNames from 'classnames';
import Popup from 'reactjs-popup';
import { CancellationDeterrents } from '../util/types';

interface ICancelDeterrentConfirmationModalProps {
  onContinue: () => void;
  header: string;
  body: string;
  deterrent: CancellationDeterrents;
  trigger: React.ReactElement;
}

export default function CancelDeterrentConfirmationModal({
  onContinue,
  header,
  body,
  deterrent,
  trigger,
}: ICancelDeterrentConfirmationModalProps) {
  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      onOpen={() => events.supplementsManagement.deterrentTapped({}, deterrent)}
    >
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={classNames(styles.body, styles.shrink)}>
            {
              <>
                <div className={styles.header}>
                  <h3>{header}</h3>
                </div>
                <p>{body}</p>
              </>
            }
          </div>
          <div className={classNames(styles.actions, styles.shrink)}>
            <Button
              className={styles.button}
              onClick={() => {
                close();
                events.supplementsManagement.deterrentAccepted({}, deterrent);
                onContinue();
              }}
            >
              Confirm
            </Button>
            <Button
              className={styles.button}
              tertiary
              onClick={() => {
                close();
                events.supplementsManagement.deterrentConfirmationGoBack({}, deterrent);
              }}
            >
              Go back
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
}
