import React, { useContext } from 'react';

export interface ShowPromotionsContextType {
  showPromotions: boolean;
  setShowPromotions(value: boolean): void;
}

export const ShowPromotionsContext = React.createContext<ShowPromotionsContextType>({
  showPromotions: true,
  setShowPromotions() {},
});

export const useShowPromotions = () => useContext(ShowPromotionsContext).showPromotions;
