import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import * as qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import * as events from '../../lib/analytics/events';
import { logInternalError } from '../../lib/errors';
import { SimpleDismissableModal } from '../modals';
import styles from './ReferralCode.module.scss';

const getShareInformation = (code: string, source: string) => ({
  title: `Check out the Fi Smart Dog Collar!`,
  text: `Use my referral code ${code} when buying a Fi collar and get a free additional band with your purchase!`,
  url: `https://shop.tryfi.com/r/${code}/?utm_source=${source}`,
});

function WebShareButton({ code, source }: ReferralCodeProps) {
  const handleShare = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    events.referrals.shareClicked(true);
    // Web share API: https://alligator.io/js/web-share-api/
    (navigator as any).share(getShareInformation(code, source)).catch((err: Error) => {
      if (err.toString().includes('AbortError')) {
        // This just means they canceled the share
        return;
      }

      logInternalError(err);
    });
  };

  return (
    <div className={styles.shareButton} onClick={handleShare}>
      Share
    </div>
  );
}

function AndroidShareButton({ code, source }: ReferralCodeProps) {
  const handleShare = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    events.referrals.shareClicked(true);
    (window as any).android.share(JSON.stringify(getShareInformation(code, source)));
  };

  return (
    <div className={styles.shareButton} onClick={handleShare}>
      Share
    </div>
  );
}

function FallbackShareButton({ code, source }: ReferralCodeProps) {
  const shareInformation = getShareInformation(code, source);
  const mailtoLink = `mailto:?${qs.stringify({
    subject: shareInformation.title,
    body: `${shareInformation.text}\n\n${shareInformation.url}`,
  })}`;

  return (
    <SimpleDismissableModal
      trigger={<div className={styles.shareButton}>Share</div>}
      onOpen={() => events.referrals.shareClicked(false)}
    >
      <div>
        <a className={styles.shareLink} href={mailtoLink} target="_blank" rel="noopener noreferrer">
          ✉ Share via email
        </a>
      </div>
    </SimpleDismissableModal>
  );
}

function ShareButton({ code, source }: ReferralCodeProps) {
  return (navigator as any).share ? (
    <WebShareButton code={code} source={source} />
  ) : (window as any).android && (window as any).android.share ? (
    <AndroidShareButton code={code} source={source} />
  ) : (
    <FallbackShareButton code={code} source={source} />
  );
}

function ToastController({ children }: { children: (triggerToast: () => void) => JSX.Element | JSX.Element[] }) {
  const [shown, setShown] = useState(false);
  const contentDiv = React.createRef<HTMLDivElement>();

  const handleAnimationEnd = useCallback(() => {
    setShown(false);
  }, []);

  useEffect(() => {
    if (contentDiv.current) {
      const eventListener = () => handleAnimationEnd();
      const element = contentDiv.current;
      element.addEventListener('animationend', eventListener);
      return () => {
        element.removeEventListener('animationend', eventListener);
      };
    }
  }, [contentDiv, handleAnimationEnd]);

  const triggerToast = () => {
    if (shown) {
      return;
    }
    setShown(true);
  };

  return (
    <>
      <div className={styles.toast}>
        <div className={classNames(styles.toastContent, { [styles.shown]: shown })} ref={contentDiv}>
          Code copied to clipboard!
        </div>
      </div>
      {children(triggerToast)}
    </>
  );
}

interface ReferralCodeProps {
  code: string;
  source: string;
}

export default function ReferralCode({ code, source }: ReferralCodeProps) {
  return (
    <div className={styles.container}>
      <ToastController>
        {(triggerToast) => {
          const handleCodeClick = () => {
            events.referrals.codeCopied();
            copy(code);
            triggerToast();
          };

          return (
            <>
              <div className={styles.codeContainer}>
                <div className={styles.header}>Your invite code</div>
                <div className={styles.code} onClick={handleCodeClick}>
                  {code}
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <ShareButton code={code} source={source} />
              </div>
            </>
          );
        }}
      </ToastController>
    </div>
  );
}
