import React from 'react';

/**
 * Adds styles to hide the Zendesk chat widget
 */
export default function HideChatWidget({ mobileOnly }: { mobileOnly?: boolean }) {
  return (
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html: `
        ${mobileOnly && '@media (max-width: 768px) {'}
          iframe#launcher, iframe#ada-button-frame {
            display: none !important;
          }
          iframe[src*="decagon.ai"]:not(.visible) {
            opacity: 0;
            display: none !important;
            transition: opacity 0.5s ease;
            pointer-events: none !important;
          }
        ${mobileOnly && '}'}
        `,
      }}
    />
  );
}
