import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as types from '../types';
import { ProductCategory } from '../types';

export function useDefaultCollarProduct() {
  const collarSortPreference = useCallback((product: types.IProduct) => {
    if (product.id === 'smart-collar-v3') {
      return 0;
    } else if (product.id === 'smart-collar-v2') {
      return 1;
    } else {
      return 2;
    }
  }, []);

  const products = useSelector((state: types.AppState) => state.config.products);
  return products
    .filter((p) => p.category === ProductCategory.COLLAR_KIT)
    .sort((a, b) => collarSortPreference(a) - collarSortPreference(b))[0];
}

export default function useDefaultProduct() {
  const defaultCollarProduct = useDefaultCollarProduct();
  const products = useSelector((state: types.AppState) => state.config.products);
  const featuredProduct = products.find((p) => p.featured);

  return featuredProduct ?? defaultCollarProduct;
}
