import Cookies from 'js-cookie';
import { DEV_MODE } from './devMode';

export interface CouponCookieData {
  code: string;
  bannerText?: string;
}

export function removeCookie(name: string) {
  Cookies.remove(name, { domain: DEV_MODE ? undefined : '.tryfi.com' });
}

export const COUPON_COOKIE_NAME = 'coupon_data';
export const COUPON_COOKIE_EXPIRATION_HOURS = 72;
