import classNames from 'classnames';
import React from 'react';
import { ReactComponent as Facebook } from '../../assets/images/icons/social/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/images/icons/social/instagram.svg';
import { ReactComponent as Pinterest } from '../../assets/images/icons/social/pinterest.svg';
import { ReactComponent as TikTok } from '../../assets/images/icons/social/tiktok.svg';
import { ReactComponent as Twitter } from '../../assets/images/icons/social/twitter.svg';
import { ReactComponent as AppleAppStore } from '../../assets/images/apple_app_store.svg';
import { ReactComponent as GoogleAppStore } from '../../assets/images/google_app_store.svg';
import { Copyright } from './Copyright';
import styles from './Footer.module.scss';
import JoinEmailForm from './JoinEmailForm';
import { SocialButton } from './SocialButton';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={classNames(styles.footerSection, styles.footerSectionNav)}>
        <ul role="navigation" className={styles.footerNav}>
          <li>
            <a href="https://jobs.lever.co/fi" target="_blank" rel="noopener noreferrer">
              Jobs
            </a>
          </li>
          <li>
            <a href="mailto:press@tryfi.com" target="_blank" rel="noopener noreferrer">
              Press
            </a>
          </li>
          <li>
            <a href="http://www.tryfi.com/privacypolicy" target="_blank" rel="noreferrer">
              Privacy policy
            </a>
          </li>
          <li>
            <a href="https://support.tryfi.com/hc/en-us" target="_blank" rel="noopener noreferrer">
              Support
            </a>
          </li>
          <li>
            <a href="https://nano.tryfi.com/" target="_blank" rel="noopener noreferrer">
              Nano
            </a>
          </li>
        </ul>
        <div className={styles.copyrightDesktop}>
          <Copyright />
        </div>
      </div>
      <div className={styles.footerSection}>
        <div>
          <div className={styles.emailFormHeader}>Join our newsletter</div>
          <div className={styles.emailFormSubHeader}>
            All the dog news you&rsquo;ll obsess over, in one place, twice a month.
          </div>
        </div>
        <JoinEmailForm placeholder="Enter email" />
        <div className={styles.appStoreButtons}>
          <a
            href="https://apps.apple.com/us/app/fi-gps-dog-tracker/id1438036784"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.appStoreButton}
          >
            <AppleAppStore />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.barkinglabs.fi"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.appStoreButton}
          >
            <GoogleAppStore />
          </a>
        </div>
        <div className={styles.socialLinks}>
          <SocialButton href="https://www.facebook.com/dogcollarsbyfi/" icon={<Facebook />} />
          <SocialButton href="https://www.instagram.com/fi.dogs/" icon={<Instagram />} />
          <SocialButton href="https://twitter.com/fi_dogs" icon={<Twitter />} />
          <SocialButton href="https://www.pinterest.com/fidogs/" icon={<Pinterest />} />
          <SocialButton href="https://www.tiktok.com/@fi.dogs" icon={<TikTok />} />
        </div>
      </div>
      <div className={styles.copyrightMobile}>
        <Copyright />
      </div>
    </footer>
  );
}
