import React, { useMemo } from 'react';
import { gqlTypes } from '../../../../types';
import { useQuery } from '@apollo/client';
import { getLoadingOrErrorElement, expectUnreachable } from '../../../../lib/util';
import { useLocation } from 'react-router-dom';
import {
  RescuePlacementModel,
  rescuePlacementMainQuery,
  RescuePlacementNavigationState,
  defaultRescuePlacementNavigationState,
} from '../rescuePlacementUtils';
import PetSelector from '../PetSelector/PetSelector';
import PetPlacement from '../PetPlacement/PetPlacement';
import PetRecall from '../PetRecall/PetRecall';
import PetTemporaryPlacement from '../PetTemporaryPlacement/PetTemporaryPlacement';
import PetPermanentPlacement from '../PetPermanentPlacement/PetPermanentPlacement';
import NotFound from '../../../NotFound';
import HideChatWidget from '../../../../components/ZendeskChat/HideChatWidget';
import styles from './RescuePlacement.module.scss';

export default function RescuePlacement() {
  const { loading, error, data } = useQuery<gqlTypes.ECOMMERCE_rescuePlacementMain>(rescuePlacementMainQuery);
  const location = useLocation<any>();
  const model = useMemo(() => (data ? new RescuePlacementModel(data) : undefined), [data]);

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, error);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const navigationState: RescuePlacementNavigationState = {
    ...defaultRescuePlacementNavigationState,
    ...location.state,
  };

  let body: JSX.Element | undefined;
  switch (navigationState.placementView) {
    case 'petSelector':
      body = <PetSelector model={model!} />;
      break;

    case 'petPlacement':
      body = <PetPlacement model={model!} petId={navigationState.petId} />;
      break;

    case 'petTemporaryPlacement':
      body = <PetTemporaryPlacement model={model!} petId={navigationState.petId} />;
      break;

    case 'petPermanentPlacement':
      body = <PetPermanentPlacement model={model!} petId={navigationState.petId} />;
      break;

    case 'petRecall':
      body = <PetRecall model={model!} petId={navigationState.petId} />;
      break;

    default:
      expectUnreachable(navigationState);
      body = <NotFound />;
      break;
  }

  return (
    <div className={styles.rescuePlacementContainer}>
      {body}
      <HideChatWidget />
    </div>
  );
}
