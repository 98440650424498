import classNames from 'classnames';
import React, { useCallback, useMemo, useRef } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import { sanitize } from '../../lib/util';
import styles from './SurveyQuestion.module.scss';

interface SurveyQuestionProps {
  question: string;
  onChoose: (answer: string) => void;
  options: string[];
  className?: string;
}

export default function SurveyQuestion({ question, onChoose, options, className }: SurveyQuestionProps): JSX.Element {
  // Ref for the input field of the 'Other' option
  const otherInputRef = useRef<HTMLInputElement>(null);

  // Ref for the input field of the 'Met us at an event' option to let us know what event
  const atFiEventInputRef = useRef<HTMLInputElement>(null);

  const handleChoose = useCallback(
    (answer: string) => {
      onChoose(answer);
    },
    [onChoose],
  );

  const handleInputAndSubmit = useCallback(
    (label: string, input: string | undefined, inputRequired: boolean) => {
      if (!input && inputRequired) {
        return;
      }

      const answer = input ? `${label}-${sanitize(input)}` : label;
      handleChoose(answer);
    },
    [handleChoose],
  );

  /**
   * For the 'Other' and 'Met us at an event' options which have text inputs for additional info, we want to submit the
   * answer when the user presses Enter while focused on the input field.
   */
  const submitOnEnter = useCallback(
    (
      event: React.KeyboardEvent<HTMLInputElement>,
      label: string,
      inputRef: React.RefObject<HTMLInputElement>,
      required: boolean,
    ) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleInputAndSubmit(label, inputRef.current?.value, required);
      }
    },
    [handleInputAndSubmit],
  );

  const answers = useMemo(() => {
    const optionsWithoutOther = options.map((option) => {
      return (
        <div className={styles.option} onClick={() => handleChoose(option)} key={option}>
          {option}
        </div>
      );
    });

    /** Split out the response 'Met us at an event', because it accepts input so
     * requires different behavior from the other options.
     */
    const atFiEventLabel = 'Met us at an event';
    const atFiEvent = (
      <div
        className={styles.option}
        onClick={() => {
          // Since specifying the event name is optional, we'll allow them to click the larger button to just submit
          // the default answer. User should still be able to click into the input field to specify the event name.
          handleChoose(atFiEventLabel);
        }}
      >
        {atFiEventLabel}
        <div
          className={classNames(styles.form)}
          onClick={(e) => e.stopPropagation()} // Prevent clicks on the form from triggering the parent onClick
        >
          <input
            className={styles.input}
            type="text"
            placeholder={'Name of the event (optional)'}
            required={false}
            ref={atFiEventInputRef}
            maxLength={500}
            onKeyPress={(e) => submitOnEnter(e, atFiEventLabel, atFiEventInputRef, false /* input not required */)}
          />
          <button
            type="submit"
            onClick={(e) => {
              e.stopPropagation(); // Prevent the click from bubbling up to the parent div
              handleInputAndSubmit(atFiEventLabel, atFiEventInputRef?.current?.value, false /* input not required */);
            }}
            className={styles.submit}
            aria-label="How did you hear about us"
          >
            <ArrowIcon width={64} height={64} />
          </button>
        </div>
      </div>
    );

    /** Split out the response 'Other', because it accepts input so
     * requires different behavior from the other options.
     */
    const otherLabel = 'Other';
    const other = (
      <div className={styles.option}>
        {otherLabel}
        <div className={classNames(styles.form)}>
          <input
            className={styles.input}
            type="text"
            placeholder={'Please specify'}
            required={true}
            ref={otherInputRef}
            maxLength={500}
            onKeyPress={(e) => submitOnEnter(e, otherLabel, otherInputRef, true /* input required */)}
          />
          <button
            type="submit"
            onClick={() => handleInputAndSubmit(otherLabel, otherInputRef?.current?.value, true /* input required */)}
            className={styles.submit}
            aria-label="How did you hear about us"
          >
            <ArrowIcon width={64} height={64} />
          </button>
        </div>
      </div>
    );

    return (
      <>
        {optionsWithoutOther}
        {atFiEvent}
        {other}
      </>
    );
  }, [options, handleChoose, submitOnEnter, handleInputAndSubmit]);

  return (
    <div className={classNames([styles.surveyQuestionWrapper, className])}>
      <div className={styles.question}>{question}</div>
      {answers}
    </div>
  );
}
