import React from 'react';
import styles from './ValuePropsBox.module.scss';
import classNames from 'classnames';

export interface ValueProp {
  icon: React.ReactNode;
  text: React.ReactElement | string;
  fade?: boolean; // Makes value prop light gray
}

interface ValuePropsBoxProps {
  className?: string;
  header?: React.ReactElement;
  valueProps: ValueProp[];
}

// To accommodate different lengths of value props, dynamically calculate the number of rows the
// value prop box should have, when the number of columns is 2.
function getGridTemplateRowsValue(valueProps: ValueProp[]) {
  if (!valueProps.length) {
    return '';
  }

  const NUM_COLUMNS = 2;
  const numRows = Math.round(valueProps.length / NUM_COLUMNS);
  return '1fr '.repeat(numRows);
}

export default function ValuePropsBox({ className, header, valueProps }: ValuePropsBoxProps) {
  return (
    <div
      className={classNames(styles.container, className)}
      style={{ gridTemplateRows: getGridTemplateRowsValue(valueProps) }}
    >
      {header}
      {valueProps.map((valueProp, i) => (
        <div className={styles.valueProp} key={i}>
          <div className={classNames(styles.icon, { [styles.fade]: valueProp.fade })}>{valueProp.icon}</div>
          <div className={classNames(styles.text, { [styles.fade]: valueProp.fade })}>{valueProp.text}</div>
        </div>
      ))}
    </div>
  );
}
