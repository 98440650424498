import React, { useState } from 'react';
import styles from './TextArea.module.scss';

interface TextAreaProps {
  rows?: number;
  placeholderText?: string;
  characterLimit?: number;
  onChange: (newValue: string | null) => void;
  disabled?: boolean;
}
/**
 * A generic textarea with character limit.
 */
export function TextArea({ rows, characterLimit, placeholderText, onChange, disabled }: TextAreaProps) {
  const [charactersRemaining, setCharactersRemaining] = useState(characterLimit);

  const updateValue = (newValue: string) => {
    const value = newValue ? newValue.substr(0, characterLimit) : null;
    if (characterLimit) {
      setCharactersRemaining(Math.max(0, characterLimit - (value?.length ?? 0)));
    }
    onChange(value);
  };

  return (
    <div>
      <textarea
        rows={rows}
        placeholder={placeholderText}
        onChange={(e) => updateValue(e.currentTarget.value)}
        maxLength={characterLimit}
        disabled={disabled === true}
      />
      {characterLimit ? (
        <div className={styles.characterLimitRemaining}>{charactersRemaining} characters remaining</div>
      ) : null}
    </div>
  );
}
