import React from 'react';
import { IGiftCardDelivery, IGiftCardProduct } from '../../types';

export type FormErrors = Partial<Record<'emailAddress' | 'gifterName' | 'gifteeName' | 'deliverAt', string>>;

export interface IGiftCardState {
  selectedId: string | null;
  delivery: IGiftCardDelivery;
  giftCardProducts: IGiftCardProduct[];
  errors: FormErrors;
  /**
   * Whether delivery.deliverAt has been set by the user. This needs to be separate
   * since both string and null are valid values for deliverAt (one indicates a date,
   * the other indicates that the gift card should be sent immediately.)
   */
  deliverAtIsSet: boolean;
}

export const emptyGiftCardState: IGiftCardState = {
  selectedId: null,
  deliverAtIsSet: false,
  delivery: {
    deliverAt: null,
    emailAddress: '',
    firstName: null,
    gifterName: null,
    lastName: null,
    personalMessage: null,
  },
  errors: {},
  giftCardProducts: [],
};

export interface SetSelectedIdAction {
  type: 'SetSelectedId';
  newSelectedId: string | null;
}

export interface UpdateGiftCardDeliveryAction {
  type: 'UpdateGiftCardDelivery';
  newValues: Partial<IGiftCardDelivery>;
}

export interface SetErrorsAction {
  type: 'SetErrors';
  errors: FormErrors;
}

export interface ClearErrorAction {
  type: 'ClearError';
  errorName: keyof FormErrors;
}

export interface SetDeliverAtIsSetAction {
  type: 'SetDeliverAtIsSet';
  newValue: boolean;
}

export type GiftCardAction =
  | SetSelectedIdAction
  | UpdateGiftCardDeliveryAction
  | SetErrorsAction
  | ClearErrorAction
  | SetDeliverAtIsSetAction;

export const clearError = (errorName: keyof FormErrors): ClearErrorAction => ({
  type: 'ClearError',
  errorName,
});

export const setErrors = (errors: FormErrors): SetErrorsAction => ({
  type: 'SetErrors',
  errors,
});

export const updateGiftCardDelivery = (newValues: Partial<IGiftCardDelivery>): UpdateGiftCardDeliveryAction => ({
  type: 'UpdateGiftCardDelivery',
  newValues,
});

export const setSelectedId = (newSelectedId: string | null): SetSelectedIdAction => ({
  type: 'SetSelectedId',
  newSelectedId,
});

export const setDeliverAtIsSet = (newValue: boolean): SetDeliverAtIsSetAction => ({
  type: 'SetDeliverAtIsSet',
  newValue,
});

const GiftCardContext = React.createContext<{
  giftCardState: IGiftCardState;
  giftCardDispatch: React.Dispatch<GiftCardAction>;
}>(undefined as any);

export default GiftCardContext;
