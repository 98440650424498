import React from 'react';
import { centsToDollars } from '../../../../lib/util';
import styles from './ProductTitle.module.scss';

interface FlatPrice {
  priceInCents: number;
}

interface LowestSubscriptionBundlePrice {
  cheapestSubscriptionRate: string;
  cheapestSubscriptionRateUnit: string;
}

export type ProductTitlePrice = FlatPrice | LowestSubscriptionBundlePrice;

interface ProductTitleProps {
  productName: string;
  price: ProductTitlePrice;
}

function priceIsBundlePrice(price: ProductTitlePrice): price is LowestSubscriptionBundlePrice {
  return 'cheapestSubscriptionRate' in price;
}

export default function ProductTitle({ productName, price }: ProductTitleProps) {
  const priceCopy = priceIsBundlePrice(price)
    ? `Starting at ${price.cheapestSubscriptionRate}/${price.cheapestSubscriptionRateUnit}`
    : `$${centsToDollars(price.priceInCents)}`;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{productName}</h2>
      <span className={styles.price}>{priceCopy}</span>
    </div>
  );
}
