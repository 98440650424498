import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import WebViewAppBar from '../../../components/WebViewAppBar';
import SubscriptionPill from '../../../components/SubscriptionPill/SubscriptionPill';
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg';
import { subscriptionShopActions } from '../../../reducers/subscriptionShop';

import styles from './PurchaseSubscriptionComplete.module.scss';
import { SubscriptionOption } from '../../../components/SubscriptionOptionDetails';
import { useSubscriptionCartMode } from '../../../lib/cartModes';
import * as types from '../../../types';
import { thankYou as events } from '../../../lib/analytics/events';

export interface PurchaseSubscriptionCompleteState {
  subscription?: { __typename?: 'BillingSubscription'; currentTermEndsAt: string };
  subscriptionProduct?: SubscriptionOption;
  showAppBar?: boolean;
}

export default function PurchaseSubscriptionComplete() {
  const { cartSelector, checkoutType } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  const dispatch = useDispatch();
  const location = useLocation<PurchaseSubscriptionCompleteState>();
  events.viewed();

  const deviceSubscription = location.state?.subscription;
  const subscriptionProduct = location.state?.subscriptionProduct;
  const showAppBar = location.state?.showAppBar ?? true; // Default to show app bar

  useEffect(() => {
    if (Object.keys(cart.cartItems).length > 0) {
      dispatch(subscriptionShopActions.resetCart());
      dispatch(subscriptionShopActions.resetCheckout());
    }
  }, [cart.cartItems, dispatch]);

  // "Buy it" subscriptions will still have a currentTermEndsAt date, but we don't want to surface that
  // to the user
  const overrideSubscriptionPillTitle =
    deviceSubscription && !subscriptionProduct?.buyItMembership
      ? `Renews: ${DateTime.fromISO(deviceSubscription.currentTermEndsAt).toFormat('LLLL d, yyyy')}`
      : undefined;

  return (
    <div className={styles.main}>
      {showAppBar && <WebViewAppBar />}
      <div className={styles.checkIcon}>
        <CheckIcon />
      </div>
      <h1>Thank you!</h1>
      {checkoutType === types.CheckoutType.Subscription && <p>You've activated your Fi plan.</p>}
      {checkoutType === types.CheckoutType.MembershipUpgrade && <p>You've upgraded your Fi membership.</p>}
      {subscriptionProduct && (
        <SubscriptionPill
          disableSavingsDisplay={true}
          subscriptionProduct={subscriptionProduct}
          overrideTitle={overrideSubscriptionPillTitle}
        />
      )}
    </div>
  );
}
