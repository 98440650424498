import './series3/polyfills';
import { ApolloProvider } from '@apollo/client';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import FatalErrorMessage from './series3/components/FatalErrorMessage/FatalErrorMessage';
import client from './series3/lib/fi-api/client';
import reduxStore from './series3/lib/reduxStore';
import { unregister as unregisterServiceWorker } from './series3/registerServiceWorker';
import '@barkinglabs/promotions-react/dist/index.css';
import Series3App from './series3/Series3App';
import * as Sentry from '@sentry/react';
import { BrowserOptions as SentryBrowserOptions } from '@sentry/browser';
import { HttpClient } from '@sentry/integrations';

/**
 * Create history for React browser router. This history object will be shared between Sentry RUM and the Router
 * component. https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v4v5
 */
const history = createBrowserHistory();

/**
 * Sentry.io configuration
 */
function getSentryConfig(): SentryBrowserOptions {
  const baseConfig: SentryBrowserOptions = {
    dsn: 'https://941c0176eac6d78488430189ded5a5c2@o4506027582619648.ingest.sentry.io/4506061077676032',
    environment: process.env.REACT_APP_ENVIRONMENT,
  };

  // RUM = Real User Monitoring
  if (process.env.REACT_APP_RUM_ENABLE === 'true') {
    return {
      ...baseConfig,
      integrations: [
        // Creates events for failed client side HTTP requests
        new HttpClient(),
        // Performance Monitoring
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        // Session Replay
        new Sentry.Replay({
          blockAllMedia: false, // default is true
          maskAllText: false, // default is true
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 0.1, // TODO: bump this up to 100% after we fix the high frequency errors
      // Performance Monitoring
      tracePropagationTargets: ['localhost', /^https:\/\/api.tryfi.com/],
      tracesSampleRate: 0.01,
    };
  }

  return baseConfig;
}

Sentry.init(getSentryConfig());

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={FatalErrorMessage}>
    <Router history={history}>
      <ApolloProvider client={client}>
        <ReduxProvider store={reduxStore}>
          <Series3App />
        </ReduxProvider>
      </ApolloProvider>
    </Router>
  </Sentry.ErrorBoundary>,
  document.getElementById('root') as HTMLElement,
);

unregisterServiceWorker();
