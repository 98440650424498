import Decimal from 'decimal.js';

export function centsToDollarsNumber(cents: number) {
  return new Decimal(Math.round(cents)).dividedBy(100).toNumber();
}

export function centsToDollars(cents: number): string {
  return centsToDollarsNumber(cents).toFixed(2);
}

export function formattedCentsToDollars(cents: number): string {
  let dollars = centsToDollars(cents);

  // If it's a whole number, don't include the decimal point
  if (dollars.endsWith('.00')) {
    dollars = dollars.slice(0, -3);
  }

  // Add a dollar sign
  return `$${dollars}`;
}

export function dollarsToCents(dollars: number): number {
  return Math.round(new Decimal(dollars).times(100).toNumber());
}
