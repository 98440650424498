import React from 'react';
import SubscriptionAppBar from '../../../../components/SubscriptionAppBar/SubscriptionAppBar';
import PurchaseSubscriptionPaymentBody from '../../../../../PurchaseSubscription/PurchaseSubscriptionPayment/PurchaseSubscriptionPaymentBody';
import { usePurchaseSubscriptionContext } from '../../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { inAppCancellation as events } from '../../../../../../lib/analytics/events';
import styles from './UpdatePayment.module.scss';

export default function UpdatePayment() {
  const { device } = usePurchaseSubscriptionContext();
  return (
    <>
      <SubscriptionAppBar
        title="Update Payment"
        backButtonAnalyticsEvent={() => events.updatePaymentBack({ petId: device.pet?.id })}
        closeButtonAnalyticsEvent={() => events.updatePaymentClose({ petId: device.pet?.id })}
      />
      <div className={styles.billingInfo}>
        <PurchaseSubscriptionPaymentBody onCancel={() => events.updatePaymentBack({ petId: device.pet?.id })} />
      </div>
    </>
  );
}
