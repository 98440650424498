import React from 'react';
import RescueContainer from '../RescueContainer';
import * as types from '../../../types';
import { useDispatch } from 'react-redux';
import { cartActions as storeCartActions } from '../../../reducers/storeCart';

export default function PurchaseGiftCards() {
  const dispatch = useDispatch();

  return (
    <RescueContainer
      addToBag={(product: types.IGiftCardProduct, emailAddress: string) => {
        dispatch(
          storeCartActions.addGiftCardCartItem({
            sku: product.sku,
            giftCardDelivery: {
              deliverAt: null,
              emailAddress,
              firstName: `Fi Customer`,
              gifterName: `Fi`,
              lastName: null,
              personalMessage: null,
            },
          }),
        );
      }}
    />
  );
}
