import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import { DEV_MODE } from './devMode';

const NANO_REFERRAL_PET_DETAILS_COOKIE_EXPIRATION_HOURS = 1;
const NANO_REFERRAL_PET_NAME_COOKIE_NAME = 'nano_referral_pet_name';
const NANO_REFERRAL_PET_AGE_COOKIE_NAME = 'nano_referral_pet_age';
const NANO_REFERRAL_PET_GENDER_COOKIE_NAME = 'nano_referral_pet_gender';
const NANO_REFERRAL_PET_BREED_COOKIE_NAME = 'nano_referral_pet_breed';
const NANO_REFERRAL_PET_WEIGHT_COOKIE_NAME = 'nano_referral_pet_weight';

export function setNanoReferralPetNameCookie(petName: string) {
  Cookies.set(NANO_REFERRAL_PET_NAME_COOKIE_NAME, petName, {
    expires: DateTime.utc().plus({ hours: NANO_REFERRAL_PET_DETAILS_COOKIE_EXPIRATION_HOURS }).toJSDate(),
    domain: DEV_MODE ? undefined : 'tryfi.com',
    secure: !DEV_MODE,
  });
}

export function getNanoReferralPetNameCookie() {
  return Cookies.get(NANO_REFERRAL_PET_NAME_COOKIE_NAME);
}

export function setNanoReferralPetAgeCookie(petAge: string) {
  Cookies.set(NANO_REFERRAL_PET_AGE_COOKIE_NAME, petAge, {
    expires: DateTime.utc().plus({ hours: NANO_REFERRAL_PET_DETAILS_COOKIE_EXPIRATION_HOURS }).toJSDate(),
    domain: DEV_MODE ? undefined : 'tryfi.com',
    secure: !DEV_MODE,
  });
}

export function getNanoReferralPetAgeCookie() {
  return Cookies.get(NANO_REFERRAL_PET_AGE_COOKIE_NAME);
}

export function setNanoReferralPetGenderCookie(petGender: string) {
  Cookies.set(NANO_REFERRAL_PET_GENDER_COOKIE_NAME, petGender, {
    expires: DateTime.utc().plus({ hours: NANO_REFERRAL_PET_DETAILS_COOKIE_EXPIRATION_HOURS }).toJSDate(),
    domain: DEV_MODE ? undefined : 'tryfi.com',
    secure: !DEV_MODE,
  });
}

export function getNanoReferralPetGenderCookie() {
  return Cookies.get(NANO_REFERRAL_PET_GENDER_COOKIE_NAME);
}

export function setNanoReferralPetBreedCookie(petBreed: string) {
  Cookies.set(NANO_REFERRAL_PET_BREED_COOKIE_NAME, petBreed, {
    expires: DateTime.utc().plus({ hours: NANO_REFERRAL_PET_DETAILS_COOKIE_EXPIRATION_HOURS }).toJSDate(),
    domain: DEV_MODE ? undefined : 'tryfi.com',
    secure: !DEV_MODE,
  });
}

export function getNanoReferralPetBreedCookie() {
  return Cookies.get(NANO_REFERRAL_PET_BREED_COOKIE_NAME);
}

export function setNanoReferralPetWeightCookie(petWeight: string) {
  Cookies.set(NANO_REFERRAL_PET_WEIGHT_COOKIE_NAME, petWeight, {
    expires: DateTime.utc().plus({ hours: NANO_REFERRAL_PET_DETAILS_COOKIE_EXPIRATION_HOURS }).toJSDate(),
    domain: DEV_MODE ? undefined : 'tryfi.com',
    secure: !DEV_MODE,
  });
}

export function getNanoReferralPetWeightCookie() {
  return Cookies.get(NANO_REFERRAL_PET_WEIGHT_COOKIE_NAME);
}
