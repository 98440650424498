import classNames from 'classnames';
import React from 'react';
import styles from './ActionContainer.module.scss';

interface ActionContainerProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * A container to hold evenly spaced, evenly sized children
 */
export default function ActionContainer({ children, className }: ActionContainerProps) {
  return <div className={classNames(className, styles.actionContainer)}>{children}</div>;
}
