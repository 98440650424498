import React, { useState } from 'react';
import styles from '../../ManageSubscription/styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { useZendeskReplacementTicket } from '../../ManageSubscription/Collar/hooks/useZendesk';
import { troubleshoot as events } from '../../../lib/analytics/events';

import SubscriptionAppBar from '../../ManageSubscription/components/SubscriptionAppBar';
import { TextArea } from '../../../components/TextArea/TextArea';
import Button from '../../../components/Button';
import { useLocation } from 'react-router-dom';
import { BaseInAppCancellationState } from '../../ManageSubscription/Collar/util/types';
import ErrorScreen from '../../ManageSubscription/components/ErrorScreen/ErrorScreen';

export default function ReplaceCollar() {
  const location = useLocation<BaseInAppCancellationState>();
  const { subscriptionCategory } = location.state;
  const { device } = usePurchaseSubscriptionContext();
  const [replaceReasonText, setReplaceReasonText] = useState('');

  events.pageLoad({ petId: device.pet?.id }, 'Replace Collar');

  const { mutation, loading } = useZendeskReplacementTicket({
    analyticsEvent: events.submittedReplacementTicket,
    replacementReason: replaceReasonText,
    device,
    subscriptionCategory,
  });

  if (loading) {
    return loading;
  }

  if (!device.subscription?.id) {
    return <ErrorScreen />;
  }

  const handleSubmit = () => {
    if (!replaceReasonText || !device.subscription?.id) {
      throw new Error('Something went wrong');
    }
    mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription.id,
          replacementReason: replaceReasonText,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.replaceCollarBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.replaceCollarClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.sectionHeaderContainer}>
            <h2 className={styles.sectionSubheader}>Replacement for Your Collar</h2>
          </div>
          <div className={styles.content}>
            <p>
              Exciting News! You have the opportunity to get a brand new S3 collar replacement! Tell what happened to
              your current collar.
            </p>
            <div className={styles.textArea}>
              <TextArea
                onChange={(value) => {
                  value && setReplaceReasonText(value);
                }}
                rows={12}
                placeholderText="Type your response here..."
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={() => handleSubmit()} className={styles.button} disabled={replaceReasonText.length === 0}>
            Submit
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
