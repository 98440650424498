import { useCallback } from 'react';
import useProductSkuMap from '../../../hooks/useProductSkuMap';
import * as types from '../../../types';
import { nameForBandMaker } from '../../../lib/product';

/**
 * Hook for a helper function that returns the maker name and upcharge for a third-party maker band.
 */
export default function useMakerBandForCartItem(): (cartItem: types.CartItem) => types.MakerBandDetails | undefined {
  const productSkuMap = useProductSkuMap();

  return useCallback(
    (cartItem: types.CartItem): types.MakerBandDetails | undefined => {
      const { variant } = productSkuMap.get(cartItem.lineItem.sku) ?? {};
      if (!variant) {
        return;
      }

      // This is only meant for third-party makers
      if (variant.bandMaker === types.BandMaker.Fi) {
        return;
      }

      return {
        bandMakerName: nameForBandMaker(variant.bandMaker),
        bandPriceInCents: variant.additionalPriceInCents,
      };
    },
    [productSkuMap],
  );
}
