import classNames from 'classnames';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import useCheckoutPaths from '../../hooks/useCheckoutPaths';
import useRequiresShippingDetails from '../../hooks/useRequiresShippingDetails';
import CheckoutBreadcrumb, { useBreadcrumbForCheckoutSection } from '../../models/CheckoutBreadcrumb';
import CheckoutSection from '../../models/CheckoutSection';
import styles from './Breadcrumbs.module.scss';

export default function Breadcrumbs() {
  const history = useHistory();
  const location = useLocation<any>();
  const checkoutPaths = useCheckoutPaths();
  const crumb = useBreadcrumbForCheckoutSection(location.pathname);
  const requiresShippingDetails = useRequiresShippingDetails();

  if (crumb === undefined) {
    return null;
  }

  const stepOneName = requiresShippingDetails ? 'Shipping' : 'Account';
  const onWouldRevisit = (checkoutSection: CheckoutSection) => {
    if (crumb <= checkoutSection) {
      return;
    }

    if (checkoutSection === CheckoutSection.shipping) {
      history.push(checkoutPaths.Shipping);
    } else if (checkoutSection === CheckoutSection.payment) {
      history.push(checkoutPaths.Payment);
    } else if (checkoutSection === CheckoutSection.confirm) {
      history.push(checkoutPaths.Review);
    }
  };

  const steps = [
    {
      name: stepOneName,
      section: CheckoutSection.shipping,
      breadcrumb: CheckoutBreadcrumb.shipping,
    },
    {
      name: 'Payment',
      section: CheckoutSection.payment,
      breadcrumb: CheckoutBreadcrumb.payment,
    },
    {
      name: 'Review',
      section: CheckoutSection.confirm,
      breadcrumb: CheckoutBreadcrumb.review,
    },
  ];

  return (
    <div className={styles.breadcrumbs}>
      {steps.map(({ breadcrumb, name, section }, index) => (
        <div
          key={section}
          className={classNames(styles.breadcrumb, {
            [styles.selected]: crumb === breadcrumb,
            [styles.disabled]: crumb <= breadcrumb,
          })}
          onClick={() => onWouldRevisit(section)}
        >
          <div className={styles.sectionNumber}>{breadcrumb < crumb ? <Check /> : <div>{index + 1}</div>}</div>
          <div>{name}</div>
        </div>
      ))}
    </div>
  );
}
