import React, { useContext } from 'react';
import { IGiftCardDelivery } from '../../../../types';
import SimpleError from '../SimpleError/SimpleError';
import GiftCardContext, { clearError, updateGiftCardDelivery } from '../../GiftCardContext';
import { TextArea } from '../../../../components/TextArea/TextArea';
import DeliveryDate from '../DeliveryDate/DeliveryDate';
import styles from './DeliveryDetails.module.scss';

const SPLIT_NAME_REGEX = /([^ ]*) *(.*)/;

/**
 * Configure details for the gift card.
 */
export default function DeliveryDetails() {
  const { giftCardState, giftCardDispatch } = useContext(GiftCardContext);
  const setValue = (newValue: string | null, propertyName: keyof IGiftCardDelivery, nonNull = false) =>
    giftCardDispatch(
      updateGiftCardDelivery({
        [propertyName]: nonNull ? newValue : newValue || null,
      }),
    );
  const setGifteeName = (fullName: string): void => {
    const [, firstName, lastName] = fullName.match(SPLIT_NAME_REGEX)!;
    setValue(firstName, 'firstName');
    setValue(lastName, 'lastName');
  };

  return (
    <div className={styles.deliveryDetails}>
      <h3>Who is it for</h3>
      {/* TODO: Add validation */}
      <input
        type="text"
        placeholder="Name"
        onChange={(e) => setGifteeName(e.currentTarget.value)}
        onBlur={() => giftCardDispatch(clearError('gifteeName'))}
      />
      <SimpleError error={giftCardState.errors['gifteeName']} />
      <input
        type="email"
        placeholder="Email Address"
        onChange={(e) => setValue(e.currentTarget.value, 'emailAddress', true)}
        onBlur={() => giftCardDispatch(clearError('emailAddress'))}
      />
      <SimpleError error={giftCardState.errors['emailAddress']} />
      <h3>From</h3>
      <input
        type="text"
        placeholder="Your Name"
        onChange={(e) => setValue(e.currentTarget.value, 'gifterName', false)}
        onBlur={() => giftCardDispatch(clearError('gifterName'))}
      />
      <SimpleError error={giftCardState.errors['gifterName']} />
      <h3>Message</h3>
      <TextArea
        onChange={(e) => setValue(e, 'personalMessage', false)}
        rows={10}
        placeholderText="Type in any message you want delivered with the gift."
        characterLimit={255}
      />
      <DeliveryDate />
    </div>
  );
}
