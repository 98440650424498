import React from 'react';
import styles from './ConfirmCheckoutDisclaimer.module.scss';

export default function ConfirmCheckoutDisclaimer() {
  return (
    <div className={styles.container}>
      By clicking place order you agree to our{' '}
      <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
        terms and conditions.
      </a>
    </div>
  );
}
