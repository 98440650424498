import { SubscriptionCategory } from './types';

export const maybeTruncatePetName = (name: string) => {
  const MAX_NAME_LENGTH = 16;
  if (name.length <= MAX_NAME_LENGTH) {
    return name;
  }

  return `${name.slice(0, MAX_NAME_LENGTH - 2)}..`;
};

export function getSubscriptionCategory(renewalMonths?: number) {
  // default to PREPAID if no renewal months value provided
  if (renewalMonths === undefined) {
    return SubscriptionCategory.PREPAID;
  }

  if (renewalMonths === 1) {
    return SubscriptionCategory.MONTHLY;
  } else {
    return SubscriptionCategory.PREPAID;
  }
}
