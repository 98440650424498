import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebViewAppBar from '../../../components/WebViewAppBar';
import { useSeries3UpgradeContext } from '../../../contexts/Series3UpgradeContext';
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts';
import { minBy } from '../../../lib/util';
import UpgradeablePet from '../../../models/UpgradeablePet';
import { cartActions as upgradeCartActions } from '../../../reducers/series3UpgradeCart';
import { series3UpgradeShopActions } from '../../../reducers/series3UpgradeShop';
import { petContextActions } from '../../../reducers/series3UpgradePetContext';
import * as types from '../../../types';
import ValuePropsBox from '../../ProductDetails/Series3CollarDetails/components/ValuePropsBox';
import { WHATS_NEW_VALUE_PROPS } from '../../ProductDetails/Series3CollarDetails/components/ValuePropsBox/WhatsNewValueProps';
import { PetList } from '../components/PetList';
import styles from './UpgradeSplash.module.scss';
import { UpgradeOffer } from './UpgradeOffer';

function displayedPlanRate(plan: types.ISubscriptionProduct) {
  return `${plan.displayedRate}/${plan.displayedRateUnit}`;
}

interface OfferDetailsProps {
  offeredPlan: types.ISubscriptionProduct;
  upgradeablePets: UpgradeablePet[];
}

function OfferDetails({ offeredPlan, upgradeablePets }: OfferDetailsProps) {
  return (
    <>
      <UpgradeOffer offeredPlan={offeredPlan} />
      <div className={styles.valuePropsSection}>
        <h2>What's new?</h2>
        <ValuePropsBox valueProps={WHATS_NEW_VALUE_PROPS} />
      </div>
      <PetList upgradeablePets={upgradeablePets} darkBackground />
    </>
  );
}

export default function UpgradeSplash() {
  const dispatch = useDispatch();
  const { offeredPlan, series3Collar, upgradeablePets } = useSeries3UpgradeContext();
  const series3UpgradeCart = useSelector((state: types.AppState) => state.series3UpgradeShop.cart);
  const contextPetId = useSelector((state: types.AppState) => state.series3UpgradeShop.upgradePetContext.petId);

  const subscriptionProducts = useSubscriptionProducts(series3Collar.id);
  const basePlan = minBy(subscriptionProducts, (p) => p.renewalMonths);
  const basePlanRate = basePlan ? displayedPlanRate(basePlan) : null;

  const canShowOffer = upgradeablePets.length > 0;

  useEffect(() => {
    // Clear cart and checkout progress upon mount
    if (Object.keys(series3UpgradeCart.cartItems).length > 0) {
      dispatch(series3UpgradeShopActions.resetCheckout());
      dispatch(upgradeCartActions.resetCart());
    }

    // This context does not apply to this legacy upgrade flow where we can upgrade multiple pets in the same txn
    if (contextPetId) {
      dispatch(petContextActions.resetContext());
    }
  }, [contextPetId, dispatch, series3UpgradeCart.cartItems]);

  return (
    <>
      <div className={styles.fixedBackground} />
      <div className={styles.fullWidthWrapper}>
        <WebViewAppBar darkBackground />
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.primaryHeader}>
              <div className={styles.headerDefault}>
                <h1>Series 3</h1>
              </div>
            </div>
            <div className={styles.secondaryHeader}>
              <h2>{basePlanRate}</h2>
              <h3>One single price. All included.</h3>
            </div>
          </div>
          {canShowOffer && offeredPlan ? (
            <OfferDetails offeredPlan={offeredPlan} upgradeablePets={upgradeablePets} />
          ) : (
            <h3>No upgradeable collars</h3>
          )}
        </div>
      </div>
    </>
  );
}
