import React, { useState } from 'react';
import styles from '../../../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../../../components/SubscriptionAppBar';
import Button from '../../../../../components/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { usePurchaseSubscriptionContext } from '../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { inAppCancellation as events } from '../../../../../lib/analytics/events';
import retentionOfferDogImage from '../../../../../assets/images/in_app_cancellation/retention_offer_dog.jpeg';
import { BaseInAppCancellationState } from '../../util/types';
import AppPaths from '../../../../../AppPaths';
import pauseImage from '../../../../../assets/images/pause.png';
import { ConfirmModal } from '../../../../../components/modals';
import { DateTime } from 'luxon';
import { useMutation } from '@apollo/client';
import { pauseMembershipMutation } from '../../../../../graphql-operations';
import { gqlTypes } from '../../../../../types';
import ErrorScreen from '../../../components/ErrorScreen/ErrorScreen';
import Loading from '../../../../../components/Loading';
import ErrorMessage from '../../../../../components/ErrorMessage';

export default function PauseMembership() {
  const NUM_TERMS_TO_PAUSE = 1; // As of 10/25, we only allow users to pause for one month at a time.
  const { device } = usePurchaseSubscriptionContext();
  const history = useHistory();
  const location = useLocation<BaseInAppCancellationState>();
  const [error, setError] = useState('');
  const { subscriptionCategory } = location.state;
  if (!device.pet) {
    throw new Error('No pet found');
  }

  events.pageLoad({ petId: device.pet.id, subscriptionCategory }, 'Pause Membership');

  const petName = device.pet.name;
  const petAvatarPath = device.pet.photos.first?.image?.fullSize ?? retentionOfferDogImage;

  const [pauseMutation, pauseMutationState] = useMutation<
    gqlTypes.ECOMMERCE_pauseMembership,
    gqlTypes.ECOMMERCE_pauseMembershipVariables
  >(pauseMembershipMutation, {
    onCompleted: (data) => {
      const success = !!data.pauseMembership?.success;
      if (!success) {
        setError(
          `Error: ${data.pauseMembership?.reasonForFailure}` ?? 'Failed to pause membership: contact customer support.',
        );
      } else {
        history.push({
          pathname: AppPaths.Subscription.PauseMembershipConfirmed(device.moduleId),
          search: window.location.search,
          state: { subscriptionCategory },
        });
      }
    },
  });

  if (pauseMutationState.loading) {
    return <Loading />;
  }

  if (!device.subscription?.id) {
    return <ErrorScreen />;
  }

  const handleClaimPauseMembership = () => {
    events.claimPauseMembership({ petId: device.pet!.id, subscriptionCategory });
    pauseMutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription!.id,
          moduleId: device.moduleId,
          numTerms: NUM_TERMS_TO_PAUSE,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() =>
            events.claimPauseMembershipBack({ petId: device.pet?.id, subscriptionCategory })
          }
          closeButtonAnalyticsEvent={() =>
            events.claimPauseMembershipClose({ petId: device.pet?.id, subscriptionCategory })
          }
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <div className={styles.largeHeader}>
              <h1>Consider a pause?</h1>
            </div>

            <p>
              We're happy to pause {petName}'s membership for a month if you need. We won't bill you while paused, no
              questions asked.
            </p>
            {error && <ErrorMessage errors={[error]} />}

            <div className={styles.offerContainer}>
              <div className={styles.offer}>
                <div className={styles.description}>Pause membership for</div>
                <div className={styles.pauseDuration}>1 month</div>
              </div>
            </div>

            <div className={styles.pauseMonthOffer}>
              <div className={styles.pauseMonthOfferImages}>
                <div className={styles.pauseMonthOfferAvatar}>
                  <img src={petAvatarPath} alt={petName} />
                </div>
                <div className={styles.pauseMonthOfferPauseIcon}>
                  <img src={pauseImage} alt="Pause button" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <ConfirmModal
            trigger={<Button className={styles.button}>Pause membership</Button>}
            body={
              <>
                <h2>Are you sure?</h2>
                <p>
                  This will pause your access to the Fi app, GPS tracking, and {petName}'s data on{' '}
                  {DateTime.fromISO(device.subscription?.currentTermEndsAt).toFormat('L/d/yy')} and skip billing for 1
                  month.
                </p>
              </>
            }
            acceptText={'Confirm'}
            declineText={'No, go back'}
            onAccept={handleClaimPauseMembership}
          />
          <Button
            className={styles.button}
            tertiary={true}
            onClick={() => {
              history.push({
                pathname: AppPaths.Subscription.Cancel(device.moduleId),
                state: { subscriptionCategory: subscriptionCategory },
                search: window.location.search,
              });
            }}
          >
            Continue with Cancellation
          </Button>
        </div>
      </div>
    </>
  );
}
