import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import TrackedExternalLink from '../../../../components/TrackedExternalLink/TrackedExternalLink';
import Button from '../../../../components/Button';
import * as events from '../../../../lib/analytics/events';
import { setPassword as submitSetPassword } from '../../../../lib/authentication';
import styles from './ThankYou.module.scss';

interface SaveAccountEntryProps {
  onSuccess(): void;
}

function SaveAccountEntry({ onSuccess }: SaveAccountEntryProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');

  const onSubmit = useCallback(() => {
    if (loading || !password) {
      return;
    }

    setLoading(true);

    submitSetPassword(password)
      .then(() => {
        events.thankYou.setPasswordSuccess();
        onSuccess();
      })
      .catch((e) => {
        events.thankYou.setPasswordFailure();

        setError(true);
        setPassword('');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loading, onSuccess, password]);

  return (
    <>
      <h2>Save your account</h2>
      <div className={styles.passwordForm}>
        <div className={styles.formSection}>
          <input
            type="password"
            name="password"
            placeholder={error ? 'Oops! An error occurred.' : 'Password'}
            className={classNames({ [styles.passwordError]: error })}
            value={password}
            onChange={(evt) => {
              setPassword(evt.target.value);

              if (error) {
                setError(false);
              }
            }}
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                evt.preventDefault();
                onSubmit();
              }
            }}
          />
        </div>
        <Button className={styles.submitPasswordButton} disabled={loading} onClick={onSubmit}>
          Continue
        </Button>
      </div>
    </>
  );
}

function SaveAccountComplete() {
  return (
    <>
      <h2>Password set!</h2>
      <p>
        Thanks for creating an account.{' '}
        <TrackedExternalLink
          href="https://tryfi.com/app"
          className="text--highlight"
          target="_blank"
          {...events.thankYou.downloadLinkDetails()}
        >
          Download
        </TrackedExternalLink>{' '}
        and log in to the Fi mobile app for iOS &amp; Android!
      </p>
    </>
  );
}

export default function SaveAccount() {
  const [finished, setFinished] = useState(false);

  return (
    <div className={styles.saveAccount}>
      {finished ? <SaveAccountComplete /> : <SaveAccountEntry onSuccess={() => setFinished(true)} />}
    </div>
  );
}
