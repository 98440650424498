import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { ReactComponent as FiveStarBlack } from '../../assets/images/icons/five_stars_black.svg';
import useAutoTimer from '../../hooks/useAutoTimer';
import { ChicletRow } from './ChicletRow';
import { Review } from './Review';
import { BrianReview, PressReviewData, ReviewData } from './ReviewData';
import styles from './ReviewsCarousel.module.scss';
import * as events from '../../lib/analytics/events';

const CAROUSEL_INTERVAL_MS = 5000;

export default function ReviewsCarousel() {
  const reviews: ReviewData[] = [BrianReview, ...PressReviewData];
  const { current, setCurrent } = useAutoTimer({
    total: reviews.length,
    autoproceedInterval: CAROUSEL_INTERVAL_MS,
  });

  const currentReview = reviews[current];

  return (
    <section className={styles.carouselWrapper}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={currentReview.author.name}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
        >
          {currentReview && <Review key={currentReview.author.name} {...currentReview} starAsset={<FiveStarBlack />} />}
        </CSSTransition>
      </SwitchTransition>
      <ChicletRow value={current} total={reviews.length} onChicletClick={setCurrent} />
      <a
        href="https://www.tryfi.com/reviews"
        className={styles.moreReviewsButton}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          events.series3CollarMoreReviews();
        }}
      >
        More reviews
      </a>
    </section>
  );
}
