import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SizeOption } from '../../types';
import { MAKER_ITEMS } from './MakerItems.constant';
import { FI_MAKERS_ANCHOR } from '../../AppPaths';
import * as types from '../../types';
import { Series } from '../../types/Series';
import styles from './Accessories.module.scss';
import { ModuleItem } from './AccessoryTypes';
import AccessoryGroup from './components/AccessoryGroup/AccessoryGroup';

export type SeriesProducts = Record<Series, ModuleItem[]>;

function useFindProducts() {
  const products = useSelector((state: types.AppState) => state.config.products);

  return useCallback(
    (
      descriptors: Array<{
        id: string;
        nameOverride?: string;
      }>,
    ): ModuleItem[] => {
      const items: ModuleItem[] = [];

      // Make sure to return the products in the same order as the `names` array was passed in.
      for (const { id, nameOverride } of descriptors) {
        const product: types.IProduct | undefined = products.find((p) => p.id === id);
        if (!product) {
          continue;
        }

        if (product.category === types.ProductCategory.BAND) {
          // We only need 1 per color/type so we'll grab the medium size variant by default.
          const variants = product.variants.filter((v) => v.options.size === SizeOption.Medium);
          for (const variant of variants) {
            items.push({
              productId: product.id,
              productName: product.name,
              category: product.category,
              priceInCents: product.priceInCents,
              variantOptions: variant.options,
              bandSeries: product.bandSeries,
              nameOverride,
            });
          }
        } else if (product.category === types.ProductCategory.CHARGING_BASE) {
          items.push({
            productId: product.id,
            productName: product.name,
            category: product.category,
            priceInCents: product.priceInCents,
            nameOverride,
          });
        }
      }

      return items;
    },
    [products],
  );
}

function Accessories() {
  const findProducts = useFindProducts();

  const s2Items = useMemo((): ModuleItem[] => {
    return findProducts([
      { id: 'additional-band' },
      {
        id: 'martingale-band',
        nameOverride: `Series 2 Martingale Band`,
      },
      { id: 'additional-base' },
    ]);
  }, [findProducts]);

  const s3Items = useMemo((): ModuleItem[] => {
    return findProducts([{ id: 'additional-band-s3' }, { id: 'additional-base-s3' }]);
  }, [findProducts]);

  const allItems: SeriesProducts = {
    [Series.Series2]: s2Items,
    [Series.Series3]: s3Items,
  };

  const makerHeaderLink = <a href="mailto:makers@tryfi.com">Become a Fi maker</a>;

  return (
    <div className={styles.container}>
      <div className={styles.accessoriesModule}>
        <h2>ACCESSORIES</h2>
        <AccessoryGroup enableSeriesToggle={true} items={allItems} />
        <hr />
        <div id={FI_MAKERS_ANCHOR} className={styles.fiMakersSection}>
          <AccessoryGroup
            title="Fi Makers"
            subtitle="Approved third party accessories"
            headerLink={makerHeaderLink}
            makerItems={MAKER_ITEMS}
          />
        </div>
      </div>
    </div>
  );
}

export default Accessories;
