import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import setCouponCookie from '../../lib/setCouponCookie';
import setReferralCookie from '../../lib/setReferralCookie';
import { COUPON_COOKIE_NAME, expectUnreachable } from '../../lib/util';
import { useCartPricing } from '../../contexts/CartPricingContext';
import usePromoCodeEligibility from '../../hooks/usePromoCodeEligibility';
import * as events from '../../lib/analytics/events';
import CheckoutContext from '../../lib/CheckoutContext';
import { CouponError, GiftCardError, ReferralCodeError, logInternalError, PromoCodeError } from '../../lib/errors';
import { validatePromoCode, PromoCodeValidationErrorResponse } from '../../lib/promoCode';
import { REFERRAL_COOKIE_NAME } from '../../lib/util/referralCookie';
import * as types from '../../types';
import ErrorMessage from '../ErrorMessage';
import styles from './PromoCode.module.scss';
import PromoCodeForm from './PromoCodeForm';
import { isAxiosError } from '../../lib/fi-api/apiUtils';

// These are prefixes of coupon codes that won't work with experimental pricing that we'll override error message to
// indicate an adjusted code to try
const PREPAID_COUPON_PREFIXES_TO_REPLACE = [
  'bark-',
  'app-stunt6-',
  'goody1-',
  'goody6-',
  'forma1-',
  'forma6-',
  'fringe1-',
  'fringe6-',
  'lt-',
  'thrive1-',
  'thrive2-',
  'thrive6-',
];

export default function PromoCode() {
  const dispatch = useDispatch();
  const cartPricing = useCartPricing();
  const { cart, checkoutActions } = useContext(CheckoutContext);
  const [error, setError] = useState<string | null>(null);
  const showCoupons = useSelector((state: types.AppState) => !!state.config.siteConfig.showCoupons);
  const [pendingCodeValue, setPendingCodeValue] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const onResolved = useCallback((couponCodeOverride?: string) => {
    setSubmitting(false);
    setPendingCodeValue(couponCodeOverride ?? '');
  }, []);

  const { couponCodeEligibility, giftCardEligibility, referralCodeEligibility } = usePromoCodeEligibility({
    cart,
    showCoupons,
  });

  // When promo codes are applied from url redirects, they can get attached to carts even if they're not valid for the
  // current cart. This is because we don't want to lose the promo code if the user adds an item to the cart
  // that makes it valid. When applying a new code, we want to ignore any invalid codes that are attached just in case
  // the backend would reject the new code due to stacking restrictions.
  const cartExcludingInvalidCodes: types.Cart = useMemo(
    () => ({
      ...cart,
      couponCode: cartPricing.validationErrors?.couponCode ? undefined : cart.couponCode,
      referralCode: cartPricing.validationErrors?.referralCode ? undefined : cart.referralCode,
      redeemedGiftCardCode: cartPricing.validationErrors?.redeemedGiftCardCode ? undefined : cart.redeemedGiftCardCode,
    }),
    [
      cart,
      cartPricing.validationErrors?.couponCode,
      cartPricing.validationErrors?.redeemedGiftCardCode,
      cartPricing.validationErrors?.referralCode,
    ],
  );

  /**
   * Even though we may have already determined eligibility for our code types via usePromoCodeEligibility, we still do
   * the checks for each when submitting the form because we use an onmibox and we want to differentiate error messages
   * between a code that is not found and a code that is found but cannot be applied due to eligibility reasons.
   */
  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    // Reset error state
    setError(null);
    let overrideCouponCode: string | undefined;

    try {
      const result = await validatePromoCode(cartExcludingInvalidCodes, pendingCodeValue);
      if (result.promoCodeType === 'coupon') {
        if (!couponCodeEligibility.canApplyCode) {
          throw CouponError.ineligible(couponCodeEligibility.ineligibilityReason);
        }

        dispatch(checkoutActions.addCoupon(pendingCodeValue));
        events.cartPage.couponApplied(pendingCodeValue);
        setCouponCookie(pendingCodeValue);
      } else if (result.promoCodeType === 'referral') {
        if (!referralCodeEligibility.canApplyCode) {
          throw ReferralCodeError.ineligible(referralCodeEligibility.ineligibilityReason);
        }

        dispatch(checkoutActions.addReferral({ code: pendingCodeValue, referrerName: result.referrerName }));
        events.cartPage.referralCodeApplied(pendingCodeValue);
        setReferralCookie(pendingCodeValue, result.referrerName);
      } else if (result.promoCodeType === 'giftCard') {
        if (!giftCardEligibility.canApplyCode) {
          throw GiftCardError.ineligible(giftCardEligibility.ineligibilityReason);
        }

        dispatch(checkoutActions.addGiftCard(pendingCodeValue));
        events.cartPage.giftCardApplied(pendingCodeValue);
      } else if (result.promoCodeType === 'internalCoupon') {
        if (!couponCodeEligibility.canApplyCode) {
          throw CouponError.ineligible(couponCodeEligibility.ineligibilityReason);
        }

        dispatch(checkoutActions.addCoupon(pendingCodeValue));
        events.cartPage.couponApplied(pendingCodeValue);
        setCouponCookie(pendingCodeValue);
      } else {
        expectUnreachable(result.promoCodeType);
        throw new Error('Unexpected promo code type');
      }
    } catch (err) {
      if (isAxiosError(err) && err.response?.data.error) {
        const errorResponseData: PromoCodeValidationErrorResponse = err.response?.data.error;
        const matchingOverridePrefix = PREPAID_COUPON_PREFIXES_TO_REPLACE.find(
          (prefix) => pendingCodeValue.startsWith(prefix) && !pendingCodeValue.startsWith(`${prefix}a-`),
        );
        if (errorResponseData.code === 'coupon_no_eligible_items' && matchingOverridePrefix) {
          const codeToUse = pendingCodeValue.replace(matchingOverridePrefix, `${matchingOverridePrefix}a-`);
          setError(`Coupon error. Try using ${codeToUse} instead.`);

          // Set the override code so that the form is updated with the adjusted code
          overrideCouponCode = codeToUse;
        } else {
          setError(errorResponseData.message);

          // This is a user-facing error but we'll log it to Sentry as well. We like to know about spikes
          // even in coupon errors that are just validation errors (e.g. that coupon doesn't apply to XYZ) as it could
          // indicate a misconfiguration of the coupon. Sentry allows for a more intuitive interface than OpenSearch
          // anomaly detection alerts.
          Sentry.captureMessage(errorResponseData.message, {
            level: 'info',
            tags: {
              messageType: 'promoCodeValidationError',
            },
          });
        }
      } else if (err instanceof PromoCodeError) {
        setError(err.message);

        // This is a user-facing error but we'll log it to Sentry as well. We like to know about spikes
        // even in coupon errors that are just validation errors (e.g. that coupon doesn't apply to XYZ) as it could
        // indicate a misconfiguration of the coupon. Sentry allows for a more intuitive interface than OpenSearch
        // anomaly detection alerts.
        Sentry.captureMessage(err.message, {
          level: 'info',
          tags: {
            messageType: 'promoCodeValidationError',
          },
        });
      } else {
        setError('An unknown error occurred. Please try again.');
        logInternalError(err, {
          // For unknown coupon errors, separate errors by the message
          fingerprint: ['{{ default }}', err.message],
          tags: {
            checkoutStep: 'promoCode',
          },
        });
      }

      Cookies.remove(COUPON_COOKIE_NAME);
      Cookies.remove(REFERRAL_COOKIE_NAME);
    } finally {
      onResolved(overrideCouponCode);
    }
  }, [
    cartExcludingInvalidCodes,
    checkoutActions,
    couponCodeEligibility,
    dispatch,
    giftCardEligibility,
    onResolved,
    pendingCodeValue,
    referralCodeEligibility,
  ]);

  // If they cannot apply any codes, don't show the form
  if (
    !couponCodeEligibility.canApplyCode &&
    !giftCardEligibility.canApplyCode &&
    !referralCodeEligibility.canApplyCode
  ) {
    return null;
  }

  const allowedCodeTypes = [];
  if (couponCodeEligibility.canApplyCode) {
    allowedCodeTypes.push('Coupon');
  }

  if (referralCodeEligibility.canApplyCode) {
    allowedCodeTypes.push('Referral');
  }

  if (giftCardEligibility.canApplyCode) {
    allowedCodeTypes.push('Gift card');
  }

  const placeholder = `${allowedCodeTypes.join(' / ')} code`;

  const disabled = submitting || pendingCodeValue === '';

  return (
    <div className={styles.main}>
      <PromoCodeForm
        actionLabel="Apply"
        disabled={disabled}
        onChange={(code: string) => {
          setPendingCodeValue(code);
          setError(null);
        }}
        onSubmit={onSubmit}
        pendingCodeValue={pendingCodeValue}
        placeholder={placeholder}
      />
      {error && <ErrorMessage errors={[error]} />}
    </div>
  );
}
