import React from 'react';

import { centsToDollars, centsToDollarsNumber } from '../../lib/util';
import ConfirmModal from '../../components/modals/ConfirmModal';
import * as events from '../../lib/analytics/events';
import { SubscriptionOption } from '../../components/SubscriptionOptionDetails';
import { DateTime } from 'luxon';
import styles from '../ManageSubscription/styles/SubscriptionContainer.module.scss';

interface IMonthlyUpgradeConfirmationModalProps {
  onContinue: () => void;
  subscriptionOption?: SubscriptionOption;
  previewRenewalDate: string;
  trigger: React.ReactElement;
  open?: boolean;
  upgradeNow?: boolean;
}

export default function MonthlyUpgradeConfirmationModal({
  onContinue,
  trigger,
  subscriptionOption,
  previewRenewalDate,
  open,
  upgradeNow,
}: IMonthlyUpgradeConfirmationModalProps) {
  const onAccept = () => {
    events.monthlyUpgrade.confirmationModalContinue({ newSku: subscriptionOption?.sku });
    onContinue();
  };
  const onDecline = () => {
    events.monthlyUpgrade.confirmationModalGoBack({ newSku: subscriptionOption?.sku });
  };

  const getBillingCadence = (subscription: SubscriptionOption): string => {
    if (subscription.renewalMonths === 1) {
      return `every month`;
    } else if (subscription.renewalMonths % 12 === 0) {
      const years = Math.round(subscription.renewalMonths / 12);
      if (years === 1) {
        return `annually`;
      } else if (years === 2) {
        return `biennially`;
      } else {
        return `every ${years} years`;
      }
    } else {
      return `every ${subscription.renewalMonths} months`;
    }
  };

  if (upgradeNow) {
    return (
      <ConfirmModal
        open={open}
        trigger={trigger}
        body={
          <>
            <h2>Confirm new plan</h2>
            <p>
              Your membership will switch now and you will be charged{' '}
              <span className={styles.emphasize}>
                $
                {centsToDollars(
                  subscriptionOption
                    ? subscriptionOption.planSwitchAmountChargedInCents ?? subscriptionOption.priceInCents
                    : 0,
                )}
              </span>
              , then ${centsToDollarsNumber(subscriptionOption ? subscriptionOption.priceInCents : 0)}{' '}
              {subscriptionOption && getBillingCadence(subscriptionOption)}, starting on{' '}
              <span className={styles.emphasize}>{DateTime.fromISO(previewRenewalDate).toFormat('M/d/yyyy')}</span>.
            </p>
          </>
        }
        acceptText={'Confirm'}
        declineText={'Go back'}
        onAccept={onAccept}
        onDecline={onDecline}
      />
    );
  }

  return (
    <ConfirmModal
      open={open}
      trigger={trigger}
      body={
        <>
          <h2>Confirm new plan</h2>
          <p>
            Your membership will renew on{' '}
            <span className={styles.emphasize}>{DateTime.fromISO(previewRenewalDate).toFormat(`M/d/yyyy`)}</span> and
            you will be charged{' '}
            <span className={styles.emphasize}>
              ${centsToDollarsNumber(subscriptionOption ? subscriptionOption.priceInCents : 0)}
            </span>{' '}
            {subscriptionOption && getBillingCadence(subscriptionOption)}.
          </p>
        </>
      }
      acceptText={'Confirm'}
      declineText={'Go back'}
      onAccept={onAccept}
      onDecline={onDecline}
    />
  );
}
