import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import WebViewAppBar from '../../components/WebViewAppBar';
import { Series3UpgradeContextProvider } from '../../contexts/Series3UpgradeContext';
import WebViewAppBarContext from '../../contexts/WebViewAppBarContext';
import useCheckoutPaths from '../../hooks/useCheckoutPaths';
import useUpgradePetId from '../../hooks/useUpgradePetId';
import * as types from '../../types';
import Checkout from '../Checkout';
import UpgradeCart from './UpgradeCart';
import UpgradeProductSelector from './UpgradeProductSelector';
import UpgradeSplash, { UpgradeSinglePetSplash } from './UpgradeSplash';
import { inAppCancellation } from '../../lib/analytics/events';

// Used to drive the web view app bar back button. We want a little more control over where it goes rather than just
// always using history.goBack
function usePreviousPath() {
  const series3UpgradeCart = useSelector((state: types.AppState) => state.series3UpgradeShop.cart);
  const location = useLocation();
  const checkoutPaths = useCheckoutPaths();

  // If we're upgrading a specific pet, the back button should go to the splash page for that pet
  const petId = useUpgradePetId();

  return useMemo(() => {
    const pathnameWithoutTrailingSlash = location.pathname.replace(/\/$/, '');
    const atTheBeginning =
      pathnameWithoutTrailingSlash === AppPaths.Series3Upgrade.Root ||
      (!!petId && pathnameWithoutTrailingSlash === AppPaths.Series3Upgrade.SplashPageForPet(petId));

    const atTheEnd = pathnameWithoutTrailingSlash === checkoutPaths.ThankYou;

    const hasCartItems = Object.keys(series3UpgradeCart.cartItems).length > 0;
    const shouldProceedToCheckout = pathnameWithoutTrailingSlash === AppPaths.Series3Upgrade.Bag && hasCartItems;

    if (atTheBeginning || atTheEnd || shouldProceedToCheckout) {
      return null; // Returning null hides the back button
    } else if (hasCartItems) {
      return AppPaths.Series3Upgrade.Bag;
    } else if (petId) {
      return AppPaths.Series3Upgrade.SplashPageForPet(petId);
    } else {
      return AppPaths.Series3Upgrade.Root;
    }
  }, [checkoutPaths.ThankYou, location.pathname, petId, series3UpgradeCart.cartItems]);
}

function useAnalyticsEvents(): { backButton: () => void; closeButton: () => void } | null {
  const location = useLocation();
  const petId = useUpgradePetId();
  const inAppCancellationFlow = useSelector(
    (state: types.AppState) => state.series3UpgradeShop.upgradePetContext.inAppCancellationFlow,
  );
  if (!inAppCancellationFlow) {
    return null;
  }

  let pageName = 'Unknown';
  const pathname = location.pathname;
  if (pathname.startsWith('/series3upgrade/product/')) {
    pageName = 'S3 Product Selection';
  } else if (pathname.startsWith('/series3upgrade/bag')) {
    pageName = 'Cart';
  } else if (pathname.startsWith('/series3upgrade/checkout/shipping')) {
    pageName = 'Checkout > Shipping';
  } else if (pathname.startsWith('/series3upgrade/checkout/payment')) {
    pageName = 'Checkout > Payment';
  } else if (pathname.startsWith('/series3upgrade/checkout/review')) {
    pageName = 'Checkout > Review';
  } else if (pathname.startsWith('/series3upgrade/checkout/thank-you')) {
    pageName = 'Checkout > Thank You';
  }

  return {
    backButton: () => {
      inAppCancellation.upgradeS2CheckoutFlow({ petId }, pageName + ' > Back');
    },
    closeButton: () => {
      inAppCancellation.upgradeS2CheckoutFlow({ petId }, pageName + ' > Close');
    },
  };
}

/**
 * All the routes for the upgrade flow need the upgrade context provider, but it's important to wrap each
 * individually inside of the routes because the context provider needs to know about the route params.
 */
function withContext(Component: React.ComponentType) {
  return function WithContext() {
    return (
      <Series3UpgradeContextProvider>
        <Component />
      </Series3UpgradeContextProvider>
    );
  };
}

export default function Upgrade() {
  const previousPath = usePreviousPath();
  const analyticsEvents = useAnalyticsEvents();

  return (
    <Switch>
      {/** Route to show upgrade offer for all eligible pets */}
      <SentryRoute exact path={AppPaths.Series3Upgrade.Root} component={withContext(UpgradeSplash)} />
      {/** Route to show upgrade offer for a specific pet */}
      <SentryRoute
        exact
        path={AppPaths.Series3Upgrade.SplashPageForPet()}
        component={withContext(UpgradeSinglePetSplash)}
      />
      <SentryRoute>
        <WebViewAppBarContext.Provider value={{ previousPath, analyticsEvents }}>
          <WebViewAppBar />
        </WebViewAppBarContext.Provider>
        <Switch>
          <SentryRoute
            exact
            path={AppPaths.Series3Upgrade.ProductSelector()}
            component={withContext(UpgradeProductSelector)}
          />
          <SentryRoute exact path={AppPaths.Series3Upgrade.Bag} component={withContext(UpgradeCart)} />
          <SentryRoute path={AppPaths.Series3Upgrade.Checkout} component={withContext(Checkout)} />
        </Switch>
      </SentryRoute>
    </Switch>
  );
}
