import { useLocation } from 'react-router-dom';
import AppPaths from '../AppPaths';
import { useSelector } from 'react-redux';
import { AppState } from '../types';
import { showNanoVariant } from '../components/NanoBanner/NanoBanner';
import { cartHasGiftItems } from '../lib/cart';

interface PathBasedAppCustomization {
  hideNav: boolean;
  inCheckoutFlow: boolean;
  showPromotionBanner: boolean;
  inBag: boolean;
}

export function usePathBasedAppCustomization(): PathBasedAppCustomization {
  const { pathname } = useLocation();
  const showPromoBannerImprovements = useSelector(
    (state: AppState) => !!state.config.siteConfig.enableEcommPromoBannerImprovements,
  );
  const cart = useSelector((state: AppState) => state.storeShop.cart);

  const hideNav = pathname.startsWith('/series2upgrade') || pathname.startsWith('/purchasesubscription');
  const inCheckoutFlow = pathname.startsWith('/checkout') && !pathname.endsWith('/thank-you');
  const inBag = pathname === AppPaths.Bag;
  const forceNanoBanner = showNanoVariant();
  const isGiftingCart = cartHasGiftItems(cart);

  // Show the promotion banner on either S2 or S3 page, on the cart page, and, if promo banner flag is on, through the checkout flow
  const showPromotionBanner =
    pathname.startsWith('/products/smart-collar-v') ||
    forceNanoBanner ||
    (pathname.startsWith('/bag') && !isGiftingCart) ||
    (showPromoBannerImprovements && inCheckoutFlow && pathname !== AppPaths.CheckoutSupplements && !isGiftingCart);

  return {
    hideNav,
    inCheckoutFlow,
    showPromotionBanner,
    inBag,
  };
}
