import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import * as events from '../../../lib/analytics/events';
import { cartActions as storeCartActions } from '../../../reducers/storeCart';
import AddToBagButton from '../components/AddToBagButton';
import ProductTitle from '../components/ProductTitle';
import styles from '../ProductDetails.module.scss';
import classNames from 'classnames';
import Dropdown from '../../../components/Dropdown';

interface NanoDetailsProps {
  productName: string;
  priceInCents: number;
  sku: string;
}

export default function NanoDetails({ productName, priceInCents, sku }: NanoDetailsProps) {
  useEffect(() => {
    events.nanoViewed({ priceInCents, productName, sku });
  }, [priceInCents, productName, sku]);

  const dispatch = useDispatch();
  const history = useHistory();
  const [quantity, setQuantity] = useState(1);
  const quantityOptions = useMemo(() => {
    const list = [];
    for (let i = 1; i <= 20; i++) {
      list.push({ value: i, label: i.toString() });
    }
    return list;
  }, []);
  const selectedQuantity = useMemo(() => {
    if (!quantity) {
      return;
    }

    return quantityOptions.find(({ value }) => value === quantity);
  }, [quantityOptions, quantity]);

  const onAddToBag = useCallback(() => {
    dispatch(storeCartActions.addSingleLineCartItem({ sku, quantity }));

    events.nanoAddedToBag({
      priceInCents,
      productName,
      sku,
    });

    history.push(AppPaths.Bag);

    return true;
  }, [dispatch, history, priceInCents, productName, sku, quantity]);

  return (
    <>
      <div className={styles.productContainer}>
        <div className={classNames(styles.imageGalleryContainer, styles.imageGallerySquare)}>
          <ImageGallery
            media={[
              {
                type: 'image',
                url: `/product_images/nano-20.jpg`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                type: 'image',
                url: `/lifestyle_images/nano-20/01.jpg`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                type: 'image',
                url: `/lifestyle_images/nano-20/02.jpg`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                type: 'image',
                url: `/lifestyle_images/nano-20/03.jpg`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
            ]}
            squareAspectRatio
          />
        </div>
        <div className={classNames(styles.productDetails, styles.nanoProductDetails)}>
          <div className={styles.productDetailsHeader}>
            <div className={styles.productDetailsTitle}>
              <ProductTitle productName={productName} price={{ priceInCents }} />
            </div>
            <p className={styles.description}>
              A new generation of microchip. This box includes 20 ISO and ICAR registered Fi Nano microchips, small
              gauge needles and syringes. Internationally scannable on all existing scanners and shipped in air sealed
              envelopes to maintain sterile environment.
            </p>
          </div>
          <div>
            <div className={styles.quantityTitle}>Quantity</div>
            <div className={styles.quantityContainer}>
              <Dropdown
                id="quantity"
                options={quantityOptions}
                selectedOption={selectedQuantity}
                onChange={(option) => {
                  if (option) {
                    setQuantity(option.value);
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.productAction}>
            <AddToBagButton onAddToBag={onAddToBag} />
          </div>
        </div>
      </div>
    </>
  );
}
