import { gql } from '@apollo/client';

export const purchaseMonthlyUpgradeMutation = gql`
  mutation ECOMMERCE_purchaseMonthlyUpgrade($input: UpgradeMembershipInput!) {
    purchaseMonthlyUpgradePromotion(input: $input) {
      chargeInvoice {
        number
      }
      giftCardInvoices {
        number
      }
      isReturningCustomer
    }
  }
`;
