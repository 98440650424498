import * as React from 'react';
import * as types from '../../types';
import { useSelector } from 'react-redux';
import { getSha1Hash } from '../../lib/util/hash';

export default function ImpactIdentifyComponent() {
  const session = useSelector((state: types.AppState) => state.session);

  const [emailHash, setEmailHash] = React.useState('');
  const [loadedEmail, setLoadedEmail] = React.useState(false);

  React.useEffect(() => {
    async function getEmailHash() {
      if (session?.email) {
        setEmailHash(await getSha1Hash(session.email));
      }
      setLoadedEmail(true);
    }
    getEmailHash();
    const body = document.querySelector('body');
    const script = document.createElement('script');

    script.setAttribute('type', 'text/javascript');
    script.innerText = loadedEmail
      ? `ire('identify', {customerId: '${session?.userId ?? ''}', customerEmail: '${emailHash}'});`
      : '';

    body?.appendChild(script);
    return () => {
      body?.removeChild(script);
    };
  }, [session?.userId, session?.email, emailHash, loadedEmail]);

  return <></>;
}
