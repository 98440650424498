import React from 'react';
import { usePurchaseSubscriptionContext } from '../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { gqlTypes } from '../../../../../types';
import { mkError } from '../../../../../lib/util';
import { gqlBillingCadenceToBillingCadence } from '../../../../../lib/subscription';
import SubscriptionPill, {
  LifetimeSubscriptionPill,
  SubscriptionOverridePill,
  SubscriptionPillStyle,
} from '../../../../../components/SubscriptionPill/SubscriptionPill';
import { DateTime } from 'luxon';

interface CurrentSubscriptionProps {
  canRenew: boolean;
}

export default function CurrentSubscription({ canRenew }: CurrentSubscriptionProps) {
  const { device } = usePurchaseSubscriptionContext();

  const hasSubscription = device.subscriptionAccessType === gqlTypes.SubscriptionAccessType.FULL_ACCESS;
  if (!hasSubscription) {
    return mkError('No subscription found');
  }

  const subscription = device.subscription;
  const isLifetime = device.subscription?.subscriptionOption.buyItMembership;
  if (isLifetime) {
    return <LifetimeSubscriptionPill />;
  }

  return (
    <>
      {subscription ? (
        <SubscriptionPill
          style={SubscriptionPillStyle.Gray}
          renewalDate={subscription.currentTermEndsAt ? DateTime.fromISO(subscription.currentTermEndsAt) : undefined}
          expirationDate={subscription.expiresAt ? DateTime.fromISO(subscription.expiresAt) : undefined}
          pauseDate={subscription.pausedAt ? DateTime.fromISO(subscription.pausedAt) : undefined}
          subscriptionProduct={{
            ...subscription.subscriptionOption,
            billingCadence: gqlBillingCadenceToBillingCadence(subscription.subscriptionOption.billingCadence),
          }}
          renewScreens={canRenew}
          isPaused={!!device.subscription?.isPaused}
          overrideTitle="Monthly"
        />
      ) : (
        <SubscriptionOverridePill style={SubscriptionPillStyle.Gray} />
      )}
    </>
  );
}
