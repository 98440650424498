import classNames from 'classnames';
import React from 'react';
import { centsToDollars } from '../../../../lib/util';
import styles from './ShippingOptionsList.module.scss';

interface IShippingOptionsItemProps {
  name: string;
  priceInCents?: number;
  disabled?: boolean;
}

export default function ShippingOptionsItem({ name, priceInCents, disabled }: IShippingOptionsItemProps) {
  return (
    <div
      className={classNames(styles.shippingOptionsItem, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.shippingMethod}>
        <div>{name}</div>
      </div>
      <div className={styles.price}>{priceInCents ? `$${centsToDollars(priceInCents)}` : 'Free'}</div>
    </div>
  );
}
