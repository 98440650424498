import { ProductSkuMap } from '../../../../hooks/useProductSkuMap';
import { expectUnreachable, generateID } from '../../../../lib/util';
import { ISupplementSubscriptionProduct, ISupplementProduct, StoreCartItem, SizeOption, Cart } from '../../../../types';

export function createSupplementCartItem(product: ISupplementSubscriptionProduct | ISupplementProduct): StoreCartItem {
  return {
    cartItemId: generateID(),
    lineItem: {
      lineItemId: generateID(),
      sku: product.sku,
    },
    // Only ever purchase one supplement product
    quantity: 1,
  };
}

export function sizeSortOrder(size: SizeOption) {
  switch (size) {
    case SizeOption.XSmall:
      return 0;
    case SizeOption.Small:
      return 1;
    case SizeOption.Medium:
      return 3;
    case SizeOption.Large:
      return 4;
    case SizeOption.XLarge:
      return 5;
    default:
      expectUnreachable(size);
      throw new Error(`Unexpected product size: ${size}`);
  }
}

/**
 * Returns the supplement that matches the dog size, or undefined if none match.
 * @param supplements - list of supplements to search
 * @param dogSize - dog size to match
 */
export function getMatchingSupplementForDogSize(
  supplements: ISupplementSubscriptionProduct[],
  dogSize: SizeOption,
): ISupplementSubscriptionProduct | undefined {
  let matchingSize = dogSize;
  // XSmall and XLarge supplements are not available, so use the next smallest/largest size
  if (matchingSize === SizeOption.XSmall) {
    matchingSize = SizeOption.Small;
  } else if (matchingSize === SizeOption.XLarge) {
    matchingSize = SizeOption.Large;
  }

  return supplements.find((s) => s.recommendedForDogSize === matchingSize);
}

/**
 * Returns the smallest dog collar size ordered, or undefined if none are ordered.
 */
export function getSmallestCollarOrdered(cart: Cart, productSkuMap: ProductSkuMap): SizeOption | undefined {
  let smallestSize: SizeOption | undefined = undefined;

  for (const cartItem of Object.values(cart.cartItems)) {
    const { variant } = productSkuMap.get(cartItem.lineItem.sku) ?? {};

    if (variant && (!smallestSize || sizeSortOrder(variant.options.size) < sizeSortOrder(smallestSize))) {
      smallestSize = variant.options.size;
    }
  }

  return smallestSize;
}
