import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import { ReactComponent as CloseIcon } from '../../assets/images/close-navigation.svg';
import useDefaultProduct from '../../hooks/useDefaultProduct';
import * as types from '../../types';
import { series3CollarId, ProductCategory } from '../../types';
import Breadcrumbs from '../Breadcrumbs';
import styles from './AppBar.module.scss';
import LoginLogoutButton from './LoginLogoutButton';
import { getFiGrowthBook } from '../../lib/growthbook';

interface IMainNavigationProps {
  dismissMobileMenu(): void;
}

const NavLinks = [
  { path: 'https://tryfi.com/collar', text: 'Collar' },
  { path: 'https://tryfi.com/theapp', text: 'App' },
  { path: 'https://tryfi.com/learn', text: 'Difference' },
  { path: 'https://tryfi.com/membership', text: 'Membership' },
  { path: 'https://shop.tryfi.com/accessories', text: 'Accessories' },
  { path: 'https://blog.tryfi.com', text: 'Blog' },
];

function MainNavigation({ dismissMobileMenu }: IMainNavigationProps) {
  const isReseller = useSelector((state: types.AppState) => state.config.siteConfig.isReseller);
  const defaultProduct = useDefaultProduct();
  const giftFiEnabled = getFiGrowthBook().getFeatureValue<boolean>('ecom-navbar-gift-fi', false);

  return (
    <div className={styles.nav}>
      <div className={styles.close}>
        <CloseIcon style={{ fill: 'black' }} onClick={dismissMobileMenu} className={styles.toggle} />
      </div>
      <div className={styles.container}>
        <div className={classNames(styles.linkGroup, styles.checkoutLinks)}>
          <Breadcrumbs />
        </div>
        <div className={classNames(styles.linkGroup, styles.storeLinks)}>
          {isReseller && defaultProduct.category === ProductCategory.MICROCHIP && (
            <Link to={AppPaths.Products(defaultProduct.id)} className={styles.link} onClick={dismissMobileMenu}>
              Fi Nano
            </Link>
          )}
          <Link to={AppPaths.Products(series3CollarId)} className={styles.link} onClick={dismissMobileMenu}>
            Series 3
          </Link>

          <Link to={AppPaths.Accessories} className={styles.link} onClick={dismissMobileMenu}>
            Accessories
          </Link>
          {giftFiEnabled && (
            <Link to={AppPaths.GiftFi} className={styles.link} onClick={dismissMobileMenu}>
              Buy as a gift
            </Link>
          )}
          <Link to={AppPaths.Gift} className={styles.link} onClick={dismissMobileMenu}>
            Gift cards
          </Link>
        </div>
        <div className={classNames(styles.linkGroup, styles.marketingLinks)}>
          {NavLinks.map((link, i) => (
            <a className={styles.link} key={'link-' + i} href={link.path}>
              {link.text}
            </a>
          ))}
        </div>
        <div className={classNames(styles.linkGroup, styles.accountLinks)}>
          <LoginLogoutButton onClick={dismissMobileMenu} />
        </div>
      </div>
    </div>
  );
}

export default MainNavigation;
