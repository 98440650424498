import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ActionContainer from '../../../../components/ActionContainer';
import Button from '../../../../components/Button';
import ConfirmCheckoutDisclaimer from '../../../../components/ConfirmCheckoutDisclaimer';
import ErrorMessage, { useError } from '../../../../components/ErrorMessage';
import ItemsInCart from '../../../../components/ItemsInCart';
import { useCartPricing } from '../../../../contexts/CartPricingContext';
import useHandlePurchase from '../../../../hooks/useHandlePurchase';
import useShippingOptions from '../../../../hooks/useShippingOptions';
import * as events from '../../../../lib/analytics/events';
import CheckoutContext from '../../../../lib/CheckoutContext';
import ThankYouLocationState from '../../../../models/ThankYouLocationState';
import * as types from '../../../../types';
import BillingLineItem from './BillingLineItem';
import styles from './Confirm.module.scss';
import PricingSummary from './PricingSummary';
import ShippingLineItem from './ShippingLineItem';
import useCheckoutPaths from '../../../../hooks/useCheckoutPaths';
import { logInternalError } from '../../../../lib/errors';
import { cartOnlyHasGiftItems } from '../../../../lib/cart';

interface ConfirmSectionProps {
  email: string;
  selectedShippingOption?: types.IShippingOption;
}

export default function ConfirmSection({ email }: ConfirmSectionProps) {
  const history = useHistory<ThankYouLocationState>();
  const checkoutPaths = useCheckoutPaths();

  const { checkoutState, requiresShippingDetails } = useContext(CheckoutContext);
  const cartPricing = useCartPricing();

  const shippingOptions = useShippingOptions();
  const selectedShippingOption = useMemo(
    () => shippingOptions.find((option) => option.code === checkoutState.shippingCode),
    [shippingOptions, checkoutState.shippingCode],
  );

  const { error, errorID, setError } = useError();

  const errorCallback = useCallback(
    (errorMessage: string) => {
      setError(errorMessage);
      events.confirmation.error(errorMessage);
    },
    [setError],
  );

  const successCallback = useCallback(
    (
      orderedCart: types.Cart,
      orderedCartPricing: types.CartPricing,
      invoiceNumber: string,
      isReturningCustomer: boolean,
    ) => {
      // Any failures in analytics shouldn't prevent the user from seeing the thank you page since we
      // successfully charged the card at this point.
      try {
        const cartItemsArray = Object.values(orderedCart.cartItems);

        events.confirmation.finalConfirm(cartItemsArray, orderedCartPricing);
        events.orderCompleted(
          orderedCart,
          orderedCartPricing,
          invoiceNumber,
          checkoutState.shippingAddress,
          checkoutState.billingInfo,
          {
            isReturningCustomer,
          },
        );
      } catch (err) {
        logInternalError(err);
      }

      const showSupplementsUsell = !cartOnlyHasGiftItems(orderedCart);
      if (showSupplementsUsell) {
        // Show the supplements page
        history.replace(checkoutPaths.Supplements, {
          orderId: invoiceNumber,
          orderedCart,
          orderedCartPricing,
        });
      } else {
        history.replace(checkoutPaths.ThankYou, {
          orderId: invoiceNumber,
          orderedCart,
          orderedCartPricing,
        });
      }
    },
    [
      checkoutPaths.Supplements,
      checkoutPaths.ThankYou,
      checkoutState.billingInfo,
      checkoutState.shippingAddress,
      history,
    ],
  );

  const { handlePurchase, purchaseMutationLoading } = useHandlePurchase({
    cartPricing,
    onError: errorCallback,
    onSuccess: successCallback,
  });

  return (
    <div className={styles.main}>
      <div className={styles.cartItemsContainer}>
        <div className={styles.cartItems}>
          <ItemsInCart />
          {selectedShippingOption && checkoutState.shippingAddress && requiresShippingDetails && (
            <ShippingLineItem
              customerName={checkoutState.customerName}
              shippingAddress={checkoutState.shippingAddress}
              shippingOption={selectedShippingOption}
            />
          )}
          {checkoutState.billingInfo && <BillingLineItem billingInfo={checkoutState.billingInfo} />}
        </div>
      </div>

      <div className={styles.summaryContainer}>
        <PricingSummary selectedShippingOption={selectedShippingOption} />
      </div>

      <div className={styles.actionContainer}>
        <ActionContainer>
          <Button
            onClick={(evt) => {
              evt.preventDefault();
              handlePurchase(email);
            }}
            disabled={purchaseMutationLoading}
          >
            Place order
          </Button>
        </ActionContainer>

        <ConfirmCheckoutDisclaimer />
      </div>
      {error && <ErrorMessage errors={[error]} errorID={errorID} />}
    </div>
  );
}
