import { typedWindow } from '../Window';

class HotJar {
  tagRecording(tags: string[]) {
    if (typedWindow.hj) {
      typedWindow.hj('tagRecording', tags);
    }
  }
}

const hotjar = new HotJar();
export default hotjar;
