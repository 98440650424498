import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import Button from '../../../../components/Button';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { SubscriptionAppBarSpacer } from '../../components/SubscriptionAppBar';

export default function FreeMonthConfirmed() {
  const { device } = usePurchaseSubscriptionContext();

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBarSpacer />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <div className={styles.largeHeader}>
              <h2>You're all set!</h2>
            </div>

            <p>Your Fi membership has been extended by one month, absolutely free.</p>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              events.claimFreeMonthDone({ petId: device.pet!.id });
              window.open('fi://closeappview');
            }}
          >
            Done
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
