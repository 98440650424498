import { useFeatureValue } from '@growthbook/growthbook-react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/images/fi-logo.svg';
import { ReactComponent as HamburgerMenuIcon } from '../../assets/images/hamburger-menu.svg';
import { ReactComponent as ChatButton } from '../../assets/images/icons/chat.svg';
import { usePathBasedAppCustomization } from '../../hooks/usePathBasedAppCustomization';
import useShoppingCartBadgeItemCount from '../../hooks/useShoppingCartItemBadgeCount';
import { isMobileDevice } from '../../lib/util/isMobileDevice';
import styles from './AppBar.module.scss';
import MainNavigation from './MainNavigation';
import ShoppingBag from './ShoppingBag';
import OrderConfirmedShoppingBag from './OrderConfirmedShoppingBag';

export function openDecagonChat() {
  // Select iframe and add visible class since entire chat widget is in a single iframe and button cannot be
  // hidden separately
  const chatIframe = document.querySelector('iframe[src*="decagon.ai"]');
  chatIframe?.classList.add('visible');
  // Only attempt open if duet is available (may not be available if script was blocked by browser)
  if ((window as any).duet && 'open' in (window as any).duet) {
    (window as any).duet.open();
  }
}

export function closeDecagonChat() {
  const chatIframe = document.querySelector('iframe[src*="decagon.ai"]');
  chatIframe?.classList.remove('visible');
}

export default function NavBar({ navBarOpacity, postCheckout }: { navBarOpacity?: number; postCheckout?: boolean }) {
  const { inCheckoutFlow, inBag } = usePathBasedAppCustomization();
  const [mainNavigationHidden, setMainNavigationHidden] = useState(true);
  const decagonChatEnabled = useFeatureValue('decagon-chat-widget', false);

  const shoppingCartBadgeItemCount = useShoppingCartBadgeItemCount();

  const dismissMobileMenu = () => {
    // Hide nav
    setMainNavigationHidden(true);
  };

  const toggleMainNavigation = () => {
    setMainNavigationHidden(!mainNavigationHidden);
  };

  const appBarClasses = classNames(styles.main, {
    [styles.open]: !mainNavigationHidden,
    // eslint-disable-next-line no-useless-computed-key
    ['nav-bar-open']: !mainNavigationHidden,
    [styles.checkout]: inCheckoutFlow,
  });

  const checkEscToDismissMenu = useCallback(
    (event) => {
      if (event.key === 'Escape' && !mainNavigationHidden) {
        dismissMobileMenu();
      }
    },
    [mainNavigationHidden],
  );

  // Display the chat button in place of bag if we are on the bag page or in checkout and on a mobile device
  const displayChatButtonInsteadOfBag = inBag && isMobileDevice();

  useEffect(() => {
    document.addEventListener('keydown', checkEscToDismissMenu, false);

    return () => {
      document.removeEventListener('keydown', checkEscToDismissMenu, false);
    };
  }, [checkEscToDismissMenu]);

  const handleChatClick = () => {
    if (decagonChatEnabled) {
      openDecagonChat();
    } else {
      // Only attempt open if ada is available (may not be available if script was blocked by browser)
      if ((window as any).adaEmbed && 'toggle' in (window as any).adaEmbed) {
        (window as any).adaEmbed.toggle();
      }
    }
  };

  return (
    <header
      className={classNames(appBarClasses, 'navBar')}
      style={
        navBarOpacity !== undefined
          ? navBarOpacity > 0
            ? { opacity: navBarOpacity }
            : { opacity: navBarOpacity, zIndex: -1 }
          : {}
      }
    >
      <div className={classNames(styles.leftNav, 'navBarIconContainer')}>
        <HamburgerMenuIcon onClick={toggleMainNavigation} className={styles.toggle} />
        <a href="https://tryfi.com">
          <Logo style={{ fill: 'black' }} />
        </a>
      </div>
      <div className={styles.overlay} onClick={dismissMobileMenu}></div>
      <MainNavigation dismissMobileMenu={dismissMobileMenu} />
      <div className={classNames(styles.rightNav, 'navBarIconContainer')}>
        {displayChatButtonInsteadOfBag ? (
          <ChatButton className={styles.chatButton} onClick={handleChatClick}>
            Open Chat
          </ChatButton>
        ) : postCheckout ? (
          <OrderConfirmedShoppingBag />
        ) : (
          <ShoppingBag count={shoppingCartBadgeItemCount} />
        )}
      </div>
    </header>
  );
}
