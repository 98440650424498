import * as types from '../types';

const STATES_WITHOUT_EXPEDITED_SHIPPING = ['VI', 'PR'];
export function isExpeditedShippingAvailableToAddress(addressLines: string[]): boolean {
  return addressLines.every((line) => {
    const upcaseLine = line.toUpperCase();
    return !upcaseLine.startsWith('PO') && !upcaseLine.startsWith('P.O.');
  });
}

export function isExpeditedShippingAvailableToState(state: string): boolean {
  return !STATES_WITHOUT_EXPEDITED_SHIPPING.includes(state);
}

export function isExpeditedShippingAvailable(shippingAddress: Pick<types.Address, 'line1' | 'line2' | 'state'>) {
  const addressLines = [shippingAddress.line1].concat(shippingAddress.line2 ? [shippingAddress.line2] : []);
  return (
    isExpeditedShippingAvailableToAddress(addressLines) && isExpeditedShippingAvailableToState(shippingAddress.state)
  );
}
