import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ThankYouCheck } from '../../../../assets/images/check-yellow-bg.svg';
import Button from '../../../../components/Button';
import { Tracker } from '../../../../components/Impact/Tracker';
import SurveyQuestion from '../../../../components/SurveyQuestion/SurveyQuestion';
import CheckoutContext from '../../../../lib/CheckoutContext';
import { clearGlobalVariablesForConversionTracking } from '../../../../lib/analytics/analyticsUtils';
import * as events from '../../../../lib/analytics/events';
import { logInternalError } from '../../../../lib/errors';
import ThankYouLocationState from '../../../../models/ThankYouLocationState';
import * as types from '../../../../types';
import ThankYouPageReferralsSection from './ReferralsSection';
import SaveAccount from './SaveAccount';
import styles from './ThankYou.module.scss';

export interface ThankYouProps {
  isWebView?: boolean;
}

export default function ThankYou({ isWebView }: ThankYouProps): JSX.Element {
  const dispatch = useDispatch();
  const session = useSelector((state: types.AppState) => state.session);
  const showThankYouPageSurvey = useSelector((state: types.AppState) => state.config.siteConfig.showThankYouPageSurvey);
  const petId = useSelector((state: types.AppState) => state.series3UpgradeShop.upgradePetContext.petId);
  const inAppCancellationFlow = useSelector(
    (state: types.AppState) => state.series3UpgradeShop.upgradePetContext.inAppCancellationFlow,
  );
  const noPasswordInSession = !!session?.noPassword;

  const { cart, checkoutActions } = useContext(CheckoutContext);

  const location = useLocation<ThankYouLocationState>();
  const locationState = location?.state;
  const orderId = locationState?.orderId;
  const orderedCart = locationState?.orderedCart;
  const orderedCartPricing = locationState?.orderedCartPricing;

  const [hasTrackedPageLoad, setHasTrackedPageLoad] = useState(false);
  const [hasTrackedSurveySubmit, setHasTrackedSurveySubmit] = useState(false);

  useEffect(() => {
    if (hasTrackedPageLoad) {
      return;
    }

    // Resetting the cart below triggers a re-render, so we need to make sure we don't do it multiple (infinity) times
    if (Object.keys(cart.cartItems).length === 0) {
      return;
    }

    // On mount: Track view, clear cart
    events.thankYou.viewed();
    inAppCancellationFlow && events.inAppCancellation.upgradeToS3CheckoutComplete({ petId });
    dispatch(checkoutActions.resetCart({ resetCoupon: true }));
    dispatch(checkoutActions.resetCheckout({}));
    setHasTrackedPageLoad(true);

    if (!orderId || !orderedCart) {
      logInternalError('ThankYou: Missing orderId or orderedCart in state');
    }
  }, [
    cart.cartItems,
    checkoutActions,
    dispatch,
    hasTrackedPageLoad,
    orderId,
    orderedCart,
    inAppCancellationFlow,
    petId,
  ]);

  useEffect(() => {
    // On unmount: clear tracking variables
    return () => {
      clearGlobalVariablesForConversionTracking();
    };
  }, []);

  const actionButton = useMemo(() => {
    if (isWebView) {
      return <Button onClick={() => window.open(`fi://closeappview`)}>Close Window</Button>;
    }

    return null;
  }, [isWebView]);

  const onSurveyQuestionSelect = useCallback(
    (answer: string): void => {
      if (hasTrackedSurveySubmit) {
        return;
      }
      events.thankYou.surveySubmit({
        selectedValue: answer,
        userId: session?.userId,
        email: session?.email,
      });

      setHasTrackedSurveySubmit(true);

      // Scroll back to the top of the page now that survey has been completed
      window.scrollTo(0, 0);
    },
    [session, hasTrackedSurveySubmit, setHasTrackedSurveySubmit],
  );

  return (
    <div className={styles.container}>
      {orderId && orderedCart && (
        <Tracker orderId={orderId} cart={orderedCart} cartPricing={orderedCartPricing} session={session} />
      )}
      <div className={styles.main}>
        <ThankYouCheck />
        <h1>Thank you!</h1>
        <p>Keep an eye out for an email containing more info about your order.</p>
        {actionButton}
        {!hasTrackedSurveySubmit && showThankYouPageSurvey && (
          <SurveyQuestion
            className={styles.surveyQuestionWrapper}
            question="How did you hear about us?"
            onChoose={onSurveyQuestionSelect}
            options={['Google', 'Friends and Family', 'Facebook/Instagram', 'TikTok', 'TV', 'Dog park', 'Amazon']}
          />
        )}
      </div>
      {!isWebView && noPasswordInSession && <SaveAccount />}
      {!isWebView && <ThankYouPageReferralsSection />}
    </div>
  );
}
