import { DateTime } from 'luxon';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as events from '../../lib/analytics/events';
import { cartActions as storeCartActions } from '../../reducers/storeCart';
import { validateEmail } from '../../lib/util';
import { IGiftCardProduct } from '../../types';
import { FormErrors, IGiftCardState } from './GiftCardContext';

interface ICheckoutHookResponse {
  addToBag: (product: IGiftCardProduct, state: IGiftCardState) => void;
  addedToBag: boolean;
}

export default function useCheckout(setErrors: (errors: FormErrors) => void): ICheckoutHookResponse {
  const [addedToBag, setAddedToBag] = useState(false);

  const dispatch = useDispatch();

  const addToBag = (product: IGiftCardProduct, state: IGiftCardState) => {
    const errors: FormErrors = {};
    const { delivery, deliverAtIsSet } = state;

    if (!delivery.firstName) {
      errors.gifteeName = 'Please enter a name for the giftee.';
    }
    if (!validateEmail(delivery.emailAddress)) {
      errors.emailAddress = 'Please enter a valid email address.';
    }
    if (!delivery.gifterName) {
      errors.gifterName = 'Please enter your name.';
    }
    const genericDeliverAtError = `Please enter a date in the future, or select "Now"`;
    if (!deliverAtIsSet) {
      errors.deliverAt = genericDeliverAtError;
    } else if (delivery.deliverAt) {
      const deliverAtDate = DateTime.fromISO(delivery.deliverAt);
      if (deliverAtDate < DateTime.local().endOf('day')) {
        errors.deliverAt = genericDeliverAtError;
      } else if (deliverAtDate > DateTime.local().plus({ years: 1 })) {
        errors.deliverAt = 'Delivery date must be less than a year in the future.';
      }
    }

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      setErrors(errors);
    } else {
      events.giftCardAddedToBag({
        priceInCents: product.priceInCents,
        productName: product.name,
        sku: product.sku,
      });
      dispatch(
        storeCartActions.addGiftCardCartItem({
          sku: product.sku,
          giftCardDelivery: delivery,
        }),
      );
      setAddedToBag(true);
    }
  };

  return { addToBag, addedToBag };
}
