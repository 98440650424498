import {
  defaultRescuePlacementNavigationState,
  rescuePlacementPath,
  RescuePlacementNavigationState,
} from '../../rescuePlacementUtils';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './GoBackLink.module.scss';

export default function GoBackLink({ state }: { state?: RescuePlacementNavigationState }) {
  return (
    <Link
      className={styles.main}
      to={{
        pathname: rescuePlacementPath,
        state: state || defaultRescuePlacementNavigationState,
      }}
    >
      &lt; Go back
    </Link>
  );
}
