import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import { ReactComponent as AddIcon } from '../../assets/images/icons/add.svg';
import Button from '../../components/Button';
import CartSummary from '../../components/CartSummary';
import ItemsInCart from '../../components/ItemsInCart';
import { CartPricingProvider } from '../../contexts/CartPricingContext';
import * as events from '../../lib/analytics/events';
import { useCartMode } from '../../lib/cartModes';
import CheckoutContext, { CheckoutContextProvider } from '../../lib/CheckoutContext';
import { priceCartForPreview } from '../../lib/pricing';
import * as types from '../../types';
import styles from './Cart.module.scss';
import MultidogDiscountExistingCollars from '../../components/MultidogDiscount';
import { useDispatch, useSelector } from 'react-redux';
import CartValueProps from '../../components/CartSummary/CartValueProps';
import { showNanoVariant } from '../../components/NanoBanner/NanoBanner';
import { inCartS3CollarCount } from '../../components/ItemsInCart/CouponLineItemV2';
import { getFiGrowthBook } from '../../lib/growthbook';
import { applyCouponToCart, isValidCodeResult } from '../../lib/promoCode';
import { cartActions } from '../../reducers/storeCart';
import { cartHasGiftItems } from '../../lib/cart';

function useTracking({ cartItems }: types.Cart) {
  const [hasLoggedPageLoad, setHasLoggedPageLoad] = useState(false);
  useEffect(() => {
    if (!hasLoggedPageLoad && Object.keys(cartItems).length > 0) {
      events.cartPage.viewed(cartItems);
      setHasLoggedPageLoad(true);
    }
  }, [cartItems, hasLoggedPageLoad]);
}

function CartContainer() {
  const dispatch = useDispatch();
  const { cart, session } = useContext(CheckoutContext);
  const { rootPath } = useCartMode();
  const history = useHistory();
  const cartV2Enabled = showNanoVariant() || getFiGrowthBook().getFeatureValue('nano-ecom-test-1', false);
  const isCartEmpty = Object.keys(cart.cartItems).length <= 0;
  const hasS3CollarInCart = inCartS3CollarCount(Object.values(cart.cartItems)) > 0;
  const multiDogDiscountAmount = useSelector((state: types.AppState) => state.config.siteConfig.multiDogDiscountAmount);
  const multiDogHouseholdDiscountEnabled = useSelector(
    (state: types.AppState) => state.config.siteConfig.multiDogHouseholdDiscountEnabled ?? false,
  );
  const [isCouponValidated, setIsCouponValidated] = useState(false);
  const isGiftingCart = cartHasGiftItems(cart);
  const isLoggedIn = !!session;

  useTracking(cart);

  useEffect(() => {
    const validateCoupon = async () => {
      if (!cart.couponCode) {
        setIsCouponValidated(true);
        return;
      }

      const result = await applyCouponToCart(cart, cart.couponCode);
      if (!isValidCodeResult(result)) {
        dispatch(cartActions.removeCoupon());
      }
      setIsCouponValidated(true);
    };

    validateCoupon();
    // Run coupon code validation once on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPricing = useCallback(async () => {
    if (!isCouponValidated) return Promise.resolve(undefined);

    return priceCartForPreview(cart);
  }, [cart, isCouponValidated]);

  if (isCartEmpty) {
    return <Redirect to={rootPath} />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.leftContainer}>
        <h2>Your cart</h2>
      </div>
      <div className={styles.rightContainer}>
        <CartPricingProvider fetchPricing={fetchPricing}>
          <div className={styles.cartItemsContainer}>
            <div className={styles.cartItems}>
              <ItemsInCart allowRemoveCartItem allowRemoveCode />
            </div>
          </div>
          {cartV2Enabled && <CartValueProps />}
          {hasS3CollarInCart && (
            <div className={styles.cartItemsContainer}>
              <div className={styles.addItemsButtonContainer}>
                <Button
                  className={styles.addItemsButton}
                  secondary
                  onClick={() => {
                    history.push(isGiftingCart ? AppPaths.GiftFi : AppPaths.Home);
                  }}
                >
                  <AddIcon />
                  Add another collar
                </Button>
                {multiDogHouseholdDiscountEnabled && !!multiDogDiscountAmount && !isGiftingCart && (
                  <>
                    <div className={styles.multidogDiscountBanner}>
                      <div className={styles.multidogDiscountBannerBoldText}>Have multiple dogs?</div>
                      <div>Get {multiDogDiscountAmount}% off all Fi memberships & no activation fee.</div>
                    </div>
                    {isLoggedIn && <MultidogDiscountExistingCollars />}
                  </>
                )}
              </div>
            </div>
          )}
          <CartSummary
            onCheckOut={(checkoutCart, cartPricing) => {
              events.cartPage.checkout(checkoutCart.cartItems, cartPricing);
            }}
          />
        </CartPricingProvider>
      </div>
    </div>
  );
}

export default function Cart() {
  // Cart needs to be wrapped with checkout context to handle Apple Pay, which is a one-click checkout from the cart
  return (
    <CheckoutContextProvider>
      <CartContainer />
    </CheckoutContextProvider>
  );
}
