import { useFeatureValue } from '@growthbook/growthbook-react';
import React from 'react';

export function ChatEmbed() {
  const decagonChatEnabled = useFeatureValue('decagon-chat-widget', false);

  // If experiment is enabled for session, use Decagon chat, otherwise use Ada chat
  if (decagonChatEnabled) {
    const chatWidgetScript = document.createElement('script');
    chatWidgetScript.id = 'chat-loader';
    chatWidgetScript.src = 'https://decagon.ai/loaders/fi.js';
    document.body.appendChild(chatWidgetScript);
  } else {
    if (document.getElementById('__ada')) return <></>;
    const adaScript = document.createElement('script');
    adaScript.src = 'https://static.ada.support/embed2.js';
    adaScript.dataset.handle = 'fi';
    adaScript.id = '__ada';
    document.body.appendChild(adaScript);
  }

  return <></>;
}
