import classNames from 'classnames';
import React from 'react';
import { centsToDollars } from '../../../../lib/util';
import styles from './OrderSummary.module.scss';
import { useCartPricing } from '../../../../contexts/CartPricingContext';

function SummaryRow({ title, cents, final }: { title: string; cents: number | undefined; final?: boolean }) {
  return (
    <div className={classNames(styles.row, { [styles.finalRow]: final })}>
      <div>{title}</div>
      <div>{typeof cents === 'number' ? `$${centsToDollars(cents)}` : '-'}</div>
    </div>
  );
}

interface OrderSummaryProps {
  totalFootnote?: string;
}
export default function OrderSummary({ totalFootnote }: OrderSummaryProps) {
  const cartPricing = useCartPricing();

  return (
    <div className={styles.main}>
      {cartPricing?.activationFeeInCents && cartPricing?.activationFeeInCents > 0 ? (
        <SummaryRow title="One time activation fee" cents={cartPricing.activationFeeInCents} />
      ) : null}
      {cartPricing?.appliedGiftCardAmountInCents && cartPricing.appliedGiftCardAmountInCents > 0 ? (
        <SummaryRow title="Gift card applied" cents={cartPricing.appliedGiftCardAmountInCents * -1} />
      ) : null}
      {cartPricing?.appliedDiscountInCents && cartPricing.appliedDiscountInCents > 0 ? (
        <SummaryRow title="Discount applied" cents={cartPricing.appliedDiscountInCents * -1} />
      ) : null}
      <SummaryRow title="Taxes" cents={cartPricing?.taxInCents} />
      {cartPricing?.upgradeCreditAmountInCents && cartPricing.upgradeCreditAmountInCents > 0 ? (
        <SummaryRow title="Refund (current plan)" cents={cartPricing.upgradeCreditAmountInCents * -1} />
      ) : null}
      {cartPricing?.appliedAccountBalanceInCents && cartPricing.appliedAccountBalanceInCents > 0 ? (
        <SummaryRow title="Account balance applied" cents={cartPricing.appliedAccountBalanceInCents * -1} />
      ) : null}
      <SummaryRow final title="Total" cents={cartPricing?.totalInCents} />
      {totalFootnote && <div className={styles.footnote}>{totalFootnote}</div>}
    </div>
  );
}
