import classNames from 'classnames';
import React from 'react';
import styles from './AppBar.module.scss';
import { EmptyShoppingBagIcon } from './ShoppingBag';

function ShoppingBagWithCheckmarkIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="16.165" y="17.9922" width="16" height="16" fill="black" />
      <svg id="checkmark" width="12" height="12" viewBox="0 0 21 15" x="18" y="20" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 0.75L7.5 13.25L1.25 7.00001" stroke="white" stroke-width="2" stroke-miterlimit="10" />
      </svg>
      <path
        fillRule="evenodd"
        d="M26.2852 13.8799C25.8656 13.4609 25.3311 13.1756 24.7495 13.0601C24.1679 12.9445 23.5654 13.0041 23.0176 13.231C22.4697 13.4578 22.0011 13.8419 21.6714 14.3347C21.3417 14.8276 21.1656 15.407 21.165 16V17H27.165V16C27.1643 15.2046 26.8479 14.442 26.2852 13.8799ZM29.165 17V16C29.165 14.6739 28.6384 13.402 27.7007 12.4644C26.763 11.5267 25.4911 11 24.165 11C22.839 11 21.5671 11.5267 20.6294 12.4644C19.6917 13.402 19.165 14.6739 19.165 16V17H14.165V35H34.165V17H29.165ZM16.165 19V33H32.165V19H16.165Z"
        fill="black"
      />
    </svg>
  );
}

function MessageWithText() {
  return (
    <svg width="234" height="50" viewBox="0 0 160 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_3216_111355)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 4C11.5817 4 8 7.58172 8 12V40C8 44.4183 11.5817 48 16 48H180.024C184.443 48 188.024 44.4183 188.024 40V31.9164C188.107 31.8773 188.188 31.8319 188.268 31.78L194.567 27.6757C195.778 26.8868 195.778 25.1132 194.567 24.3243L188.267 20.22C188.188 20.1681 188.107 20.1227 188.024 20.0836V12C188.024 7.58172 184.443 4 180.024 4H16Z"
          fill="black"
        />
        <text textAnchor="left" x="20" y="30" fill="#FFFFFF" fontSize="10">
          Your order is confirmed!
        </text>
      </g>
      <defs>
        <filter
          id="filter0_dd_3216_111355"
          x="0"
          y="0"
          width="203.475"
          height="60"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3216_111355" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3216_111355" result="effect2_dropShadow_3216_111355" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3216_111355" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

function OrderConfirmedShoppingBag() {
  return (
    <div className={classNames(styles.orderConfirmedShoppingBag, styles.link)}>
      <div className={styles.orderConfirmed}>
        <MessageWithText />
        <ShoppingBagWithCheckmarkIcon />
      </div>
      <div className={styles.emptyShoppingBackIcon}>
        <EmptyShoppingBagIcon />
      </div>
    </div>
  );
}

export default OrderConfirmedShoppingBag;
