import { Location } from 'history';
import { useEffect, useRef, useState } from 'react';
import { analyticsCoordinator } from '../lib/analytics/coordinator';
import { fbInit } from '../lib/analytics/fb';
import { northbeamInit } from '../lib/analytics/northbeam';

/**
 * - loading: Segment's analytics.ready() has not been called yet
 * - waiting: Waiting for Optimize to potentially do its magic in the background.
 *   At this stage, we show the loading indicator but also render the page in the
 *   background, behind the async-hide style.
 * - done: Done.
 */
type AnalyticsState = 'loading' | 'waiting' | 'done';

export default function useAnalytics(location: Location, sessionInitialized: boolean) {
  const [analyticsState, setAnalyticsState] = useState<AnalyticsState>('loading');

  // Kick off analytics on mount
  useEffect(() => {
    if (sessionInitialized && analyticsState === 'loading') {
      analyticsCoordinator
        .on('ready', () => setAnalyticsState('waiting'))
        .on('finished', () => {
          setAnalyticsState('done');
          // Initialize a Facebook Pixel separate from analytics.js integration. It will fire to a separate
          // pixel ID and will live side-by-side with the pixel ID that is configured with analytics.js.
          fbInit();

          // Northbeam pixel integration
          northbeamInit();
        })
        .start();
    }
  }, [analyticsState, sessionInitialized]);

  // Navigation hooks (before page change, after page change)
  const [handledLocation, setHandledLocation] = useState(location);
  if (location !== handledLocation) {
    analyticsCoordinator.beforePageChange();
    setHandledLocation(location);
  }
  // Only fire pageChange() *after* component is mounted.
  // https://stackoverflow.com/a/53351556/1480571
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      analyticsCoordinator.pageChange();
    } else {
      mounted.current = true;
    }
  }, [location]);

  return { analyticsState };
}
