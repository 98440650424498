import React, { useCallback, useState, useMemo } from 'react';
import { Series } from '../../../../types/Series';
import { SeriesProducts } from '../../Accessories';
import SeriesToggle from '../../../../components/SeriesToggle/SeriesToggle';
import ItemTile from '../ItemTile/ItemTile';
import MakerTile, { MakerItem } from '../Maker/MakerTile';
import styles from './AccessoryGroup.module.scss';

interface IAccessoryGroupProps {
  items?: SeriesProducts;
  makerItems?: MakerItem[];
  title?: string;
  subtitle?: string;
  headerLink?: JSX.Element;
  enableSeriesToggle?: boolean;
}

const AccessoryGroup = ({
  items,
  makerItems,
  title,
  subtitle,
  headerLink,
  enableSeriesToggle,
}: IAccessoryGroupProps) => {
  const getSeriesItems = (series: Series): JSX.Element[] => {
    if (items) {
      return items[series].map((item, i) => <ItemTile key={i} item={item} />);
    } else if (makerItems) {
      return makerItems.map((item, i) => <MakerTile {...item} key={i} />);
    }
    return [];
  };

  const allSeriesWithItems = useMemo(() => {
    if (!items) {
      return;
    }

    const seriesSet = new Set<Series>();
    if (items[Series.Series3] && items[Series.Series3].length > 0) {
      seriesSet.add(Series.Series3);
    }

    if (items[Series.Series2] && items[Series.Series2].length > 0) {
      seriesSet.add(Series.Series2);
    }

    return seriesSet;
  }, [items]);

  const [series, setSeries] = useState(allSeriesWithItems?.has(Series.Series3) ? Series.Series3 : Series.Series2);
  const changeSeries = useCallback(setSeries, [series, setSeries]);

  return (
    <div className={styles.accessoriesGroupModule}>
      {enableSeriesToggle ? (
        <div className={styles.accessoryGroupSeriesToggles}>
          <SeriesToggle selectedSeries={series} onSeriesChange={changeSeries} seriesOptionSet={allSeriesWithItems} />
        </div>
      ) : null}
      {title || subtitle || headerLink ? (
        <div className={styles.accessoriesGroupModuleHeader}>
          {title ? <div className={styles.accessoriesGroupModuleHeaderTitle}>{title}</div> : null}
          {subtitle ? <div className={styles.accessoriesGroupModuleHeaderSubtitle}>{subtitle}</div> : null}
          {headerLink ? <div className={styles.accessoriesGroupModuleHeaderLink}>{headerLink}</div> : null}
        </div>
      ) : null}
      <div className={styles.accessoriesGroupModuleItems}>{getSeriesItems(series)}</div>
    </div>
  );
};

export default AccessoryGroup;
