import { withSentryRouting } from '@sentry/react';
import { Route } from 'react-router';

/**
 * SentryRoute is a wrapped React Router Route component used for paramaterized transaction names in Sentry performance
 * monitoring. For example, /teams/:teamid/user/:userid instead of /teams/123/user/345
 * https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#parameterized-transaction-names
 */
const SentryRoute = withSentryRouting(Route);
export default SentryRoute;
