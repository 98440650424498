import classNames from 'classnames';
import React from 'react';
import styles from './SeriesToggleButton.module.scss';
import { Series } from '../../types/Series';

interface ISeriesToggleButtonProps {
  index: string;
  value: string;
  selected: boolean;
  onClick(newSeries: Series): void;
}

const SeriesToggleButton = ({ index, value, selected, onClick }: ISeriesToggleButtonProps) => {
  const handleClick: React.MouseEventHandler<HTMLElement> = (_event) => {
    if (!selected) {
      onClick(Series[index as keyof typeof Series]);
    }
  };

  return (
    <div onClick={handleClick} className={classNames(styles.seriesToggleButton, selected ? styles.selected : '')}>
      {value}
    </div>
  );
};

export default SeriesToggleButton;
