import React from 'react';
import { ReviewData } from './ReviewData';
import styles from './ReviewsCarousel.module.scss';

export function Review({ quote, author, starAsset }: ReviewData & { starAsset: React.ReactChild }) {
  return (
    <div className={styles.reviewBlock}>
      {starAsset}
      <h2 className={styles.pullquote}>{quote}</h2>
      <div>
        {author.icon ? (
          <img className={styles.publicationIcon} src={author.icon} alt={author.name} />
        ) : (
          <h4 className={styles.authorName}>{author.name}</h4>
        )}
      </div>
    </div>
  );
}
