import React from 'react';
import Select, { GroupedOptionsType, OptionsType, OptionTypeBase } from 'react-select';
import styles from './Dropdown.module.scss';
import { ReactComponent as DropdownIndicator } from '../../assets/images/icons/chevron_icon.svg';

interface DropdownProps<T extends OptionTypeBase> {
  id?: string;
  placeholder?: React.ReactNode;
  options?: OptionsType<T> | GroupedOptionsType<T>;
  selectedOption?: T;
  onChange?: (selectedValue: T | null | undefined) => void;
}

/**
 * A wrapper around react-select component to add consistent CSS
 */
export default function Dropdown<T extends OptionTypeBase>({
  id,
  placeholder,
  options,
  selectedOption,
  onChange,
}: DropdownProps<T>) {
  return (
    <Select
      className={styles.container}
      classNamePrefix={styles.container}
      inputId={id}
      options={options}
      value={selectedOption || null}
      placeholder={placeholder}
      onChange={onChange}
      components={{
        DropdownIndicator: () => <DropdownIndicator />,
      }}
      styles={{
        option: (base) => ({
          ...base,
          cursor: 'pointer',
        }),
      }}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
    />
  );
}
