import { useSelector } from 'react-redux';
import * as types from '../../../types';
import { useMemo } from 'react';

export default function useIsSkuBlocked(sku: string) {
  const blockedOutOfStockSkus = useSelector(
    (state: types.AppState) => state.config.siteConfig.blockedOutOfStockSkus || [],
  );

  return useMemo(() => blockedOutOfStockSkus.includes(sku), [blockedOutOfStockSkus, sku]);
}
