import React from 'react';

import Button from '../../../../components/Button';
import Popup from 'reactjs-popup';
import styles from '../../../../components/modals/modals.module.scss';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import { DateTime } from 'luxon';
import SelectShipDateModal from './SelectShipDateModal';
import { BillingSubscription } from '../SplashPage';
import classNames from 'classnames';
import { supplementsManagement as events } from '../../../../lib/analytics/events';

interface ISkipShipmentModalProps {
  handleSelectDate: (date: string) => void;
  trigger: React.ReactElement;
  subscription: BillingSubscription;
}

export default function SkipShipmentModal({ handleSelectDate, trigger, subscription }: ISkipShipmentModalProps) {
  const latestSkipDate = DateTime.fromISO(subscription.supplementShipmentDates!.latestSkipDate);
  const previewSkippingShipmentDate = DateTime.fromISO(
    subscription.supplementShipmentDates!.previewSkippingShipmentDate,
  );

  // If skipping the next shipment will mean the shipment goes past the skip deadline, surface modal that says user is unable to skip
  const canSkip = previewSkippingShipmentDate <= latestSkipDate;

  function expectedDeliveryDateText() {
    const threeDaysOut = previewSkippingShipmentDate.plus({ days: 3 }).toFormat('M/dd');
    const fiveDaysOut = previewSkippingShipmentDate.plus({ days: 5 }).toFormat('M/dd');
    return `Delivery expected ${threeDaysOut} - ${fiveDaysOut}`;
  }

  function getNumberSuffix(numString: string) {
    const num = parseInt(numString);
    const th = 'th';
    const rd = 'rd';
    const nd = 'nd';
    const st = 'st';

    if (num === 11 || num === 12 || num === 13) return th;

    const lastDigit = num.toString().slice(-1);

    switch (lastDigit) {
      case '1':
        return st;
      case '2':
        return nd;
      case '3':
        return rd;
      default:
        return th;
    }
  }

  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      onOpen={() => {
        events.skipShipmentTapped({ subscriptionId: subscription.id });
        if (!canSkip) {
          events.unableToSkipViewed({ subscriptionId: subscription.id });
        }
      }}
    >
      {(close: () => void) =>
        canSkip ? (
          <div className={styles.container}>
            <div className={classNames(styles.body, styles.shrink)}>
              <div className={styles.header}>
                <h3>Skip your upcoming shipment?</h3>
                <CloseIcon
                  onClick={() => {
                    events.modalCloseEvent({}, 'Skip Shipment');
                    close();
                  }}
                />
              </div>
              <hr className={styles.divider} />
              <p>This will update your scheduled shipment date:</p>
              <div className={styles.highlight}>
                <div className={styles.title}>{previewSkippingShipmentDate.toFormat('cccc, LLL d')}</div>
                <div className={styles.subtitle}>{expectedDeliveryDateText()}</div>
              </div>
            </div>
            <div className={classNames(styles.actions, styles.shrink)}>
              <Button
                className={styles.button}
                onClick={() => {
                  events.defaultSkipAccepted({ subscriptionId: subscription.id });
                  handleSelectDate(previewSkippingShipmentDate.toISO());
                }}
              >
                Update shipment date
              </Button>
              <SelectShipDateModal
                trigger={
                  <Button className={styles.button} tertiary>
                    Choose different shipment date
                  </Button>
                }
                onContinue={(date: string) => handleSelectDate(date)}
                subscription={subscription}
                referrer="Skip Shipment"
              />
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.body}>
              <div className={styles.header}>
                <h3>Unable to Skip</h3>
                <CloseIcon
                  onClick={() => {
                    events.modalCloseEvent({}, 'Unable to Skip');
                    close();
                  }}
                />
              </div>
              <p>You’ve reached the limit for modifying your delivery schedule for your supplements.</p>
              <p>
                While you cannot skip any more shipments, you can adjust the delivery date up to{' '}
                {latestSkipDate.toFormat('LLLL d')}
                {getNumberSuffix(latestSkipDate.day.toString())} if needed.
              </p>
            </div>
            <div className={classNames(styles.actions, styles.shrink)}>
              <SelectShipDateModal
                trigger={<Button className={styles.button}>Update shipment date</Button>}
                onContinue={(date: string) => handleSelectDate(date)}
                subscription={subscription}
                referrer="Skip shipment > Unable to skip"
              />
              <Button
                className={styles.button}
                tertiary
                onClick={() => {
                  events.modalCloseEvent({}, 'Unable to Skip');
                  close();
                }}
              >
                Close
              </Button>
            </div>
          </div>
        )
      }
    </Popup>
  );
}
