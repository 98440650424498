import React, { useState } from 'react';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import Button from '../../../components/Button';
import { getLoadingOrErrorElement } from '../../../lib/util';
import * as gqlTypes from '../../../types/gql-op-types';
import { supplementsManagement as events } from '../../../lib/analytics/events';
import Chooser from '../../../components/Chooser';
import { useHistory, useLocation } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import { useMutation } from '@apollo/client';
import { cancelSubscriptionMutation, supplementsSubscriptionsQuery } from '../../../graphql-operations';
import { BillingSubscription } from './SplashPage';
import CancelConfirmationModal from '../components/CancelConfirmationModal';
import { TextArea } from '../../../components/TextArea/TextArea';
import ErrorScreen from '../components/ErrorScreen/ErrorScreen';

interface SurveyState {
  subscription: BillingSubscription;
}

export default function Survey() {
  const pageName = 'Survey';
  const history = useHistory();
  const location = useLocation<SurveyState>();

  const { subscription } = location.state;
  events.pageLoad({ subscriptionId: subscription.id }, pageName);
  const [selectedCancelReason, setSelectedCancelReason] = useState<gqlTypes.supplementCancellationReason | undefined>(
    undefined,
  );
  const [otherText, setOtherText] = useState('');
  const [cancelMutation, cancelMutationState] = useMutation<
    gqlTypes.ECOMMERCE_cancelSupplementSubscription,
    gqlTypes.ECOMMERCE_cancelSupplementSubscriptionVariables
  >(cancelSubscriptionMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.cancelSupplementSubscription?.success;
      if (!success) {
        throw new Error('Failed to cancel subscription: contact customer support.');
      }
      history.push({
        pathname: AppPaths.Supplements.Root,
        state: { selectedCancelReason, toast: { success: true, change: 'canceled' }, refetch: false },
        search: window.location.search,
      });
    },
  });

  const continueButtonDisabled =
    selectedCancelReason === undefined ||
    (selectedCancelReason === gqlTypes.supplementCancellationReason.OTHER && otherText === '');
  if (!subscription) {
    return <ErrorScreen />;
  }

  const loadingOrErrorElement = getLoadingOrErrorElement(cancelMutationState.loading, cancelMutationState.error);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const handleCancel = () => {
    if (!selectedCancelReason) {
      throw new Error('Something went wrong.');
    }
    cancelMutation({
      variables: {
        input: {
          recurlySubscriptionId: subscription.id,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.goBackFromPage({ subscriptionId: subscription.id }, pageName)}
          closeButtonAnalyticsEvent={() => events.closePage({ subscriptionId: subscription.id }, pageName)}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Tell us more</h1>
          <p>To help us guide you in the right direction, please select one of the options below.</p>

          <div className={styles.reasonHeader}>Reason for cancellation:</div>
          <div className={styles.content}>
            <Chooser
              onSelect={(selectedReason) => {
                setSelectedCancelReason(selectedReason);
              }}
              selectedOption={selectedCancelReason}
              groups={[
                {
                  options: [
                    {
                      content: "My dog doesn't like it",
                      value: gqlTypes.supplementCancellationReason.DOG_DOES_NOT_LIKE_IT,
                    },
                    {
                      content: 'Subscription is too expensive',
                      value: gqlTypes.supplementCancellationReason.TOO_EXPENSIVE,
                    },
                    {
                      content: "Didn't realize this was a subscription",
                      value: gqlTypes.supplementCancellationReason.SUBSCRIBED_BY_ACCIDENT,
                    },
                    {
                      content: 'Other',
                      value: gqlTypes.supplementCancellationReason.OTHER,
                    },
                  ],
                },
              ]}
              showRadio
              compact
            />
            {selectedCancelReason === gqlTypes.supplementCancellationReason.OTHER && (
              <div className={styles.textArea}>
                <TextArea
                  onChange={(value) => {
                    value && setOtherText(value);
                  }}
                  rows={12}
                  placeholderText="Describe your reason here..."
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <CancelConfirmationModal
            onContinue={handleCancel}
            pageName={pageName}
            subscription={subscription}
            subscriptionType="supplement"
            cancellationReason={selectedCancelReason}
            cancellationText={otherText}
            trigger={
              <div>
                <Button className={styles.button} disabled={continueButtonDisabled}>
                  Continue
                </Button>
                {/* styles.buttonSpacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
                <div className={styles.buttonSpacer}></div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}
