import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { removeCookie } from '../lib/util';
import * as events from '../lib/analytics/events';
import { applyGiftCardToCart, isValidCodeResult } from '../lib/promoCode';
import { cartActions as storeCartActions } from '../reducers/storeCart';
import { giftCardCookieName } from '../types';
import useApplyPromotionFromCookie from './useApplyPromotionFromCookie';

export default function useApplyGiftCardFromCookie() {
  useApplyPromotionFromCookie<string>(
    useMemo(
      () => ({
        getCookieValue: () => {
          const value = Cookies.get(giftCardCookieName);
          if (value) {
            return [value, () => removeCookie(giftCardCookieName)];
          }
          return undefined;
        },

        applyPromotion: async ({ value, dispatch, cart }) => {
          const result = await applyGiftCardToCart(cart, value);
          // When auto applying gift card, we only don't want to do it if it is not a valid code.
          // If it's other errors (e.g. "Not applicable to any items in cart") then we still want to apply it.
          if (isValidCodeResult(result)) {
            dispatch(storeCartActions.addGiftCard(value));
            events.cartPage.giftCardApplied(value);
          }
        },
      }),
      [],
    ),
  );
}
