import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { usePurchaseSubscriptionContext } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { formattedCentsToDollars } from '../../lib/util/money';

export default function useGiftedSubscriptionOption() {
  const { device, subscriptionOptions } = usePurchaseSubscriptionContext();

  return useMemo(() => {
    const prepaidSubscription = device.prepaidSubscription;

    const devicePreselectedPlanCode = prepaidSubscription?.planCode;
    if (!devicePreselectedPlanCode) {
      return null;
    }

    const giftedSubscriptionOption = subscriptionOptions.find((option) => option.sku === devicePreselectedPlanCode);
    if (!giftedSubscriptionOption) {
      return null;
    }

    const nextBillingDate = DateTime.now()
      .plus({ months: giftedSubscriptionOption.renewalMonths })
      .toFormat('MM/dd/yyyy');

    const formattedPrice = formattedCentsToDollars(giftedSubscriptionOption.priceInCents);

    return {
      subscriptionOption: giftedSubscriptionOption,
      nextBillingDate,
      formattedPrice,
    };
  }, [device.prepaidSubscription, subscriptionOptions]);
}
