import React from 'react';
import { useSelector } from 'react-redux';
import * as types from '../../types';
import styles from './ReferralBanner.module.scss';
import { ReactComponent as ReferralIcon } from '../../assets/images/icons/icon_gift_box.svg';

export default function ReferralBanner() {
  const cart = useSelector((state: types.AppState) => state.storeShop.cart);
  const referrerName = cart.referrerName;

  // Referrer name may not exist if referral code was applied before referrer name was fetched which can
  // occur for older sessions that were created before we started fetching referrer name on referral code apply.
  const referralMessage = referrerName ? `${referrerName} invited you to Fi!` : 'You were invited to Fi!';
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.top}>
          <ReferralIcon width={23} height={20} className={styles.icon} />
          {referralMessage}
        </div>
        <div className={styles.bottom}>Special offer was applied to your cart</div>
      </div>
    </div>
  );
}
