import React from 'react';
import { errorScreen as events } from '../../../../lib/analytics/events';
import styles from '../../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../SubscriptionAppBar';
import Button from '../../../../components/Button';
import { DIGITAL_SUPPORT_LINE_NAME, PHONE_SUPPORT_NUMBER } from '../../../../lib/util/phoneSupport';

export default function ErrorScreen() {
  events.pageLoad();

  const phoneIconUrl = '/phone_icon.png';

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.back()}
          closeButtonAnalyticsEvent={() => events.close()}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Error loading page</h1>

          <div className={styles.content}>
            <p>
              We couldn't find a subscription for your collar, so we can't process your request. Please try again or
              contact our Customer Experience team for help.
            </p>

            <div className={styles.inlineButtons}>
              <Button
                className={styles.button}
                secondary={true}
                onClick={() => {
                  events.callCx();
                  const phoneSupportDeeplinkUrl = `fi://phone_support?digitalSupportLineName=${DIGITAL_SUPPORT_LINE_NAME}&phoneSupportNumber=${PHONE_SUPPORT_NUMBER}`;
                  window.open(phoneSupportDeeplinkUrl);
                }}
              >
                <img src={phoneIconUrl} alt="Call" className={styles.buttonIcon} />
                Call
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
