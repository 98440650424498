import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import { useSeries3UpgradeContext } from '../../../contexts/Series3UpgradeContext';
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts';
import { cartActions as series3UpgradeCartActions } from '../../../reducers/series3UpgradeCart';
import * as types from '../../../types';
import Series3CollarDetails from '../../ProductDetails/Series3CollarDetails';

export default function UpgradeProductSelector() {
  const { offeredPlan, series3Collar } = useSeries3UpgradeContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const { moduleId } = useParams<{ moduleId: string }>();

  // If the user is coming from the UpgradeSinglePetSplash page, they may have already selected a plan there.
  const preselectedPlanSku = useSelector(
    (state: types.AppState) => state.series3UpgradeShop.upgradePetContext.preSelectedSubscriptionSku,
  );

  // The backend gives us an offered plan for a particular module, but we want to consider that to be the
  // _minimum_ plan that the user can upgrade to. They are free to choose a plan with a longer duration than the
  // offered plan if they'd like.
  const offeredPlanRenewalMonths = offeredPlan?.renewalMonths ?? 0;

  const availableSubscriptions = useSubscriptionProducts(types.series3CollarId).filter((subscriptionProduct) => {
    return subscriptionProduct.renewalMonths >= offeredPlanRenewalMonths;
  });

  return (
    <Series3CollarDetails
      availableSubscriptions={availableSubscriptions}
      addBundleCartItem={(variantSku, subscriptionSku) => {
        dispatch(
          series3UpgradeCartActions.addUpgradeCartItem({
            sku: variantSku,
            subscriptionSku: subscriptionSku,
            moduleId,
          }),
        );

        history.push(AppPaths.Series3Upgrade.Bag);
      }}
      hideSubscriptionOptions={!!preselectedPlanSku}
      priceInCents={series3Collar.priceInCents}
      preselectedSubscriptionSku={preselectedPlanSku ?? offeredPlan?.sku}
      productName={series3Collar.name}
      variants={series3Collar.variants}
      isUpgradePath={true}
    />
  );
}
