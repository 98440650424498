import React from 'react';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import PurchaseSubscriptionPaymentBody from '../../../PurchaseSubscription/PurchaseSubscriptionPayment/PurchaseSubscriptionPaymentBody';
import { supplementsManagement as events } from '../../../../lib/analytics/events';
import styles from '../../Collar/SubscriptionSplash/components/UpdatePayment/UpdatePayment.module.scss';

interface AddPaymentProps {
  onSubmit?: () => void;
}

export default function AddPayment({ onSubmit }: AddPaymentProps) {
  const pageName = 'Add Payment';
  events.pageLoad({}, pageName);

  return (
    <>
      <SubscriptionAppBar
        title="Add Billing Info"
        backButtonAnalyticsEvent={() => events.goBackFromPage({}, pageName)}
        closeButtonAnalyticsEvent={() => events.closePage({}, pageName)}
      />
      <div className={styles.billingInfo}>
        <PurchaseSubscriptionPaymentBody onSubmit={onSubmit} />
      </div>
    </>
  );
}
