import AddToBagButton from '../../../components/AddToBagButton';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './CollarAddToBagButton.module.scss';
import classNames from 'classnames';

interface AddToBagButtonProps {
  disabled?: boolean;
  onAddToBag: () => boolean;
  ctaText?: string;
  descriptionText: string | null;
  fadeInRef?: React.RefObject<HTMLDivElement>;
  isVisibleOverride?: boolean;
}

export default function CollarAddToBagButton({
  disabled,
  onAddToBag,
  ctaText,
  descriptionText,
  fadeInRef,
  isVisibleOverride,
}: AddToBagButtonProps) {
  const onAddToBagClicked = useCallback(() => {
    if (disabled) {
      return false;
    }

    return onAddToBag();
  }, [disabled, onAddToBag]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (!fadeInRef?.current) return;

      const offsetY = Array.from(document.querySelectorAll('.promotionBanner')).reduce(
        (total, el) => (total + (el as HTMLElement).style.zIndex === '-1' ? 0 : (el as HTMLElement).offsetHeight),
        0,
      );

      const targetTop = fadeInRef.current.getBoundingClientRect().top - offsetY;
      setIsVisible(targetTop < 0);
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [fadeInRef]);

  return (
    <div
      className={classNames(styles.productAction, styles.stickyCTA, {
        [styles.addToCartCTA]: descriptionText,
        [styles.visibleStickyCTA]: isVisible || isVisibleOverride,
      })}
    >
      {descriptionText && <div className={styles.descriptionText}>{descriptionText}</div>}
      <AddToBagButton disabled={disabled} onAddToBag={onAddToBagClicked} ctaText={ctaText} />
    </div>
  );
}
