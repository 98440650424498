import React from 'react';
import classNames from 'classnames';
import styles from './ChicletRow.module.scss';

interface Props {
  total: number;
  value: number;
  onChicletClick?: (value: number) => void;
}

function Chiclet({ active, onClick }: { active?: boolean; onClick?: VoidFunction }) {
  return <div onClick={onClick} className={classNames(styles.chiclet, { [styles.active]: active })} />;
}

export function ChicletRow({ total, value, onChicletClick }: Props) {
  return (
    <div className={styles.chicletRow} aria-label={`Showing item ${value + 1} out of ${total}`}>
      {[...Array(total)].map((_, i) => (
        <Chiclet key={i} active={i === value} onClick={() => onChicletClick?.(i)} />
      ))}
    </div>
  );
}
