import React from 'react';
import styles from '../../../Checkout/pages/Shipping/Shipping.module.scss';
import USStateSelector from './USStateDropdown';

interface ChangeHandler {
  onChange(fieldName: string, value: string): void;
}

interface ShippingAddressFields {
  line1?: string;
  line2?: string;
  city?: string;
  zip?: string;
  state?: string;
}

export function ShippingAddressDetails({
  onChange,
  line1,
  line2,
  city,
  zip,
  state,
}: ShippingAddressFields & ChangeHandler) {
  return (
    <div className={styles.formSection}>
      <h5 className={styles.small}>Shipment address</h5>
      <input
        type="text"
        name="line1"
        placeholder="Street address"
        value={line1}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="street-address"
        data-hj-whitelist
      />
      <input
        type="text"
        name="line2"
        placeholder="Apt/suite/other (optional)"
        value={line2}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="address-line-2"
        data-hj-whitelist
      />
      <input
        type="text"
        name="city"
        placeholder="City"
        value={city}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        autoComplete="address-level2"
        data-hj-whitelist
      />
      <div className={styles.formRow}>
        <USStateSelector state={state} onChange={(newState) => onChange('state', newState)} />
        <input
          type="text"
          name="zip"
          placeholder="Zip Code"
          value={zip}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          autoComplete="postal-code"
          data-hj-whitelist
        />
      </div>
    </div>
  );
}
