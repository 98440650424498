import React from 'react';
import { Switch } from 'react-router';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import PurchaseSubscriptionComplete from '../PurchaseSubscription/PurchaseSubscriptionComplete/PurchaseSubscriptionComplete';
import MonthlyUpgradeSelection from './MonthlyUpgradeSelection';
import { PurchaseSubscriptionContextProvider } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { CartModeProvider, CartModes } from '../../lib/cartModes';
import MonthlyUpgradeComplete from './MonthlyUpgradeComplete';

export default function MonthlyUpgrade() {
  return (
    <PurchaseSubscriptionContextProvider>
      <CartModeProvider mode={CartModes.membershipUpgrade}>
        <Switch>
          <SentryRoute exact path={AppPaths.MonthlyUpgrade.PlanSelection()} component={MonthlyUpgradeSelection} />
          <SentryRoute exact path={AppPaths.MonthlyUpgrade.Complete()} component={PurchaseSubscriptionComplete} />
          <SentryRoute exact path={AppPaths.MonthlyUpgrade.CompleteV2()} component={MonthlyUpgradeComplete} />
        </Switch>
      </CartModeProvider>
    </PurchaseSubscriptionContextProvider>
  );
}
