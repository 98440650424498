import React from 'react';
import Button from '../../../../components/Button';
import {
  RescuePlacementModel,
  useRescuePlacementNavigator,
  RedirectToRescuePlacementHome,
  transferringOwnershipTitle,
} from '../rescuePlacementUtils';
import GoBackLink from '../components/GoBackLink/GoBackLink';
import PetView from '../PetView/PetView';
import styles from './PetPlacement.module.scss';

export default function PetPlacement({ model, petId }: { model: RescuePlacementModel; petId: string }) {
  const navigator = useRescuePlacementNavigator();

  const pet = model.getPetById(petId);

  if (!pet) {
    return <RedirectToRescuePlacementHome />;
  }

  return (
    <div className={styles.main}>
      <GoBackLink />
      <PetView pet={pet} title={transferringOwnershipTitle(pet)} />
      <Button onClick={() => navigator.go({ placementView: 'petTemporaryPlacement', petId })}>
        TRANSFER TO A FOSTER PARENT
      </Button>
      <Button onClick={() => navigator.go({ placementView: 'petPermanentPlacement', petId })}>
        TRANSFER TO AN ADOPTER
      </Button>
    </div>
  );
}
