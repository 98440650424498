import React from 'react';
import Button from '../../../../../components/Button';
import ConfirmModal from '../../../../../components/modals/ConfirmModal';
import styles from '../../../styles/SubscriptionContainer.module.scss';
import { inAppCancellation as events } from '../../../../../lib/analytics/events';

interface IRenewConfirmationModalProps {
  onContinue: () => void;
  pageName: string;
  petId: string;
  trigger?: React.ReactElement;
}

export default function RenewConfirmationModal({ onContinue, pageName, petId, trigger }: IRenewConfirmationModalProps) {
  const onAccept = () => {
    events.renewConfirmationModalContinue({ petId }, pageName);
    onContinue();
  };
  const onDecline = () => {
    events.renewConfirmationModalGoBack({ petId }, pageName);
  };
  return (
    <ConfirmModal
      trigger={trigger ?? <Button className={styles.button}>Renew</Button>}
      body={
        <>
          <h2>Are you sure?</h2>
          <p>This will reactivate your Fi Membership and you will be billed at your usual renewal date.</p>
        </>
      }
      acceptText={'Yes, continue'}
      declineText={'No, go back'}
      onAccept={onAccept}
      onDecline={onDecline}
    />
  );
}
