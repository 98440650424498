import { createContext, useContext } from 'react';

interface WebViewAppBarContextProps {
  previousPath?: string | null;
  analyticsEvents?: { backButton?: () => void; closeButton?: () => void } | null;
}

const WebViewAppBarContext = createContext<WebViewAppBarContextProps>({
  previousPath: null,
  analyticsEvents: null,
});
export default WebViewAppBarContext;

export function usePreviousPath() {
  const { previousPath } = useContext(WebViewAppBarContext);
  return previousPath;
}

export function useAnalyticsEvents() {
  const { analyticsEvents } = useContext(WebViewAppBarContext);
  return analyticsEvents;
}
