import classNames from 'classnames';
import React from 'react';
import { ReactComponent as IconBroadcast } from '../../../../../assets/images/icons/icon_broadcast.svg';
import { ReactComponent as IconCompact } from '../../../../../assets/images/icons/icon_compact.svg';
import { ReactComponent as IconDrip } from '../../../../../assets/images/icons/icon_drip.svg';
import { ReactComponent as IconLte } from '../../../../../assets/images/icons/icon_lte.svg';
import { ReactComponent as IconReinforced } from '../../../../../assets/images/icons/icon_reinforced.svg';
import { ReactComponent as IconLightning } from '../../../../../assets/images/icons/icon_lightning.svg';
import styles from '../../../ProductDetails.module.scss';
import { ValueProp } from './index';

export const WHATS_NEW_VALUE_PROPS: ValueProp[] = [
  {
    icon: <IconCompact />,
    text: (
      <div className={classNames(styles.valueProp, styles.valuePropCheat)}>
        <span className={styles.valuePropHeader}>50% smaller.</span>{' '}
        <span className={styles.valuePropBody}>Light &amp; comfortable for dogs of all sizes.</span>
      </div>
    ),
  },
  {
    icon: <IconBroadcast />,
    text: (
      <div className={classNames(styles.valueProp, styles.valuePropCheat)}>
        <span className={styles.valuePropBadge}>NEW</span>{' '}
        <span className={styles.valuePropHeader}>Real-time Live GPS Tracking when your dog is lost.</span>
      </div>
    ),
  },
  {
    icon: <IconLte />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>200% LTE signal.</span>{' '}
        <span className={styles.valuePropBody}>Track in most remote areas and on hikes.</span>
      </div>
    ),
  },
  {
    icon: <IconReinforced />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>400lbs+ resistance.</span>{' '}
        <span className={styles.valuePropBody}>Handles the worst chewers and pullers.</span>
      </div>
    ),
  },
  {
    icon: <IconDrip />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>IP68 & IP66k.</span>{' '}
        <span className={styles.valuePropBody}>More waterproof than ever before.</span>
      </div>
    ),
  },
  {
    icon: <IconLightning />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Up to 3 months battery life from one charge.</span>
      </div>
    ),
  },
];
