import React from 'react';
import { ReactComponent as IconBone } from '../../../../../assets/images/icons/icon_bone.svg';
import { ReactComponent as IconHeart } from '../../../../../assets/images/icons/icon_heart.svg';
import { ReactComponent as IconStomach } from '../../../../../assets/images/icons/icon_stomach.svg';
import { ReactComponent as IconVirus } from '../../../../../assets/images/icons/icon_virus.svg';
import styles from '../../../../ProductDetails/ProductDetails.module.scss';
import { ValueProp } from '../../../../ProductDetails/Series3CollarDetails/components/ValuePropsBox';

export const BENEFITS_VALUE_PROPS: ValueProp[] = [
  {
    icon: <IconBone />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Promotes joint + bone health</span>
      </div>
    ),
  },
  {
    icon: <IconStomach />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Supports digestive health</span>
      </div>
    ),
  },
  {
    icon: <IconHeart />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Formulated to support healthy heart, liver, and brain</span>
      </div>
    ),
  },
  {
    icon: <IconVirus />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Helps overall antioxidant levels</span>
      </div>
    ),
  },
];
