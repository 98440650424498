import { useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../assets/images/icons/add.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/icons/edit.svg';
import ApplePayCheckout from '../../../components/ApplePayCheckout';
import Button from '../../../components/Button';
import { SubscriptionOption } from '../../../components/SubscriptionOptionDetails';
import SubscriptionPill from '../../../components/SubscriptionPill/SubscriptionPill';
import WebViewAppBar from '../../../components/WebViewAppBar';
import { CartPricingProvider, useCartPricing } from '../../../contexts/CartPricingContext';
import WebViewAppBarContext from '../../../contexts/WebViewAppBarContext';
import { billingAccountQuery, deviceSubscriptionQuery } from '../../../graphql-operations';
import useHandlePurchase from '../../../hooks/useHandlePurchase';
import { CheckoutContextProvider } from '../../../lib/CheckoutContext';
import * as events from '../../../lib/analytics/events';
import { useSubscriptionCartMode } from '../../../lib/cartModes';
import { logInternalError } from '../../../lib/errors';
import client from '../../../lib/fi-api/client';
import { priceCartForCheckout } from '../../../lib/pricing';
import { cartActions } from '../../../reducers/subscriptionCart';
import * as types from '../../../types';
import { gqlTypes } from '../../../types';
import { PurchaseSubscriptionCompleteState } from '../PurchaseSubscriptionComplete/PurchaseSubscriptionComplete';
import OrderSummary from '../components/OrderSummary/OrderSummary';
import PaymentInfo from '../components/PaymentInfo/PaymentInfo';
import PromoCodeEntry from '../components/PromoCodeEntry/PromoCodeEntry';
import { usePurchaseSubscriptionContext } from '../context/PurchaseSubscriptionContext';
import styles from './PurchaseSubscriptionCheckout.module.scss';

export interface PurchaseSubscriptionCheckoutState {
  subscriptionOption?: SubscriptionOption;
  petId?: string;
  upgradeOldSku?: string;
}

interface PurchaseSubscriptionCheckoutMainProps {
  onGiftCardCodeChanged: (code: string | null) => void;
  onCouponCodeChanged: (code: string | null) => void;
  subscriptionProduct: SubscriptionOption;
  petId?: string;
  upgradeOldSku?: string;
}

function PurchaseSubscriptionCheckoutMain({
  onGiftCardCodeChanged,
  onCouponCodeChanged,
  subscriptionProduct,
  petId,
  upgradeOldSku,
}: PurchaseSubscriptionCheckoutMainProps) {
  const { allowPromoCodes, cartSelector, checkoutContext, checkoutPaths } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);

  const cartPricing = useCartPricing();
  const { device } = usePurchaseSubscriptionContext();
  const history = useHistory();
  const emailAddress = useSelector((state: types.AppState) => state.session?.email);
  const [enteringPromoCode, setEnteringPromoCode] = useState(false);
  const [giftCardCode, setGiftCardCode] = useState<string | null>(cart.redeemedGiftCardCode ?? null);
  const [couponCode, setCouponCode] = useState<string | null>(cart.couponCode ?? null);
  const [error, setError] = useState<string | null>(null);

  const { data: accountData, loading: accountLoading } = useQuery<gqlTypes.billingAccount>(billingAccountQuery, {
    onError(err) {
      logInternalError(err);
    },
  });

  const onMutationSuccess = useCallback(() => {
    client
      .query<gqlTypes.ECOMMERCE_deviceSubscription, gqlTypes.ECOMMERCE_deviceSubscriptionVariables>({
        query: deviceSubscriptionQuery,
        variables: { moduleId: device.moduleId },
        // Since the upgrade membership checkout changes the term end date on an existing subscription, make sure we
        // ignore cache and re-fetch the renewal date after the upgrade is processed.
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const state: PurchaseSubscriptionCompleteState = {
          subscription: data?.device?.subscription ?? undefined,
          subscriptionProduct,
        };
        history.push({
          pathname: checkoutPaths.Complete(device.moduleId),
          state,
          search: window.location.search,
        });
      })
      .catch((err) => {
        logInternalError(err);

        // Since they already paid, we'll just show them the thank you page with the subscription details we have
        history.push({
          pathname: checkoutPaths.Complete(device.moduleId),
          state: {
            subscriptionProduct,
          },
          search: window.location.search,
        });
      });
  }, [checkoutPaths, device.moduleId, history, subscriptionProduct]);

  const { handlePurchase, purchaseMutationLoading: mutationLoading } = useHandlePurchase({
    cartPricing,
    onError: setError,
    onSuccess: onMutationSuccess,
  });

  const disabled = useMemo(
    () => mutationLoading || !accountData?.currentUser.billingAccount?.billingInfo,
    [accountData?.currentUser.billingAccount, mutationLoading],
  );

  const doPurchase = useCallback(() => {
    if (disabled) {
      return;
    }
    if (checkoutContext) {
      events.confirmation.placeOrder(subscriptionProduct, checkoutContext, petId, upgradeOldSku);
    }

    handlePurchase(emailAddress);
  }, [disabled, emailAddress, handlePurchase, subscriptionProduct, checkoutContext, petId, upgradeOldSku]);

  const doPaymentDetailsCheck = useCallback(() => {
    if (!accountData?.currentUser.billingAccount?.billingInfo) {
      setError('Please enter payment details that can be used to renew your membership when it expires.');
    }
  }, [setError, accountData]);

  return (
    <WebViewAppBarContext.Provider value={{ previousPath: checkoutPaths.PlanSelection(device.moduleId) }}>
      <div className={styles.main}>
        <WebViewAppBar />
        <h1>Checkout</h1>
        <SubscriptionPill subscriptionProduct={subscriptionProduct} />
        <div className={styles.paymentInfoContainer}>
          <PaymentInfo billingAccount={accountData?.currentUser.billingAccount ?? null} loading={accountLoading} />
        </div>
        {allowPromoCodes && (
          <div className={styles.giftCardContainer}>
            <div className={styles.giftCardHeader}>Gift card or Coupon</div>
            {giftCardCode && <div>{giftCardCode} applied</div>}
            {couponCode && <div>{couponCode} applied</div>}
            {enteringPromoCode ? (
              <>
                <PromoCodeEntry
                  moduleId={device.moduleId}
                  onGiftCardCodeChange={(code) => {
                    setGiftCardCode(code);
                    setEnteringPromoCode(false);
                    onGiftCardCodeChanged(code);
                  }}
                  onCouponCodeChange={(code) => {
                    setCouponCode(code);
                    setEnteringPromoCode(false);
                    onCouponCodeChanged(code);
                  }}
                  subscriptionProduct={subscriptionProduct}
                />
              </>
            ) : (
              <div className={styles.plusButton} onClick={() => setEnteringPromoCode(true)}>
                {giftCardCode ? <EditIcon /> : <AddIcon />}
              </div>
            )}
          </div>
        )}
        <div className={styles.summaryContainer}>
          <OrderSummary />
        </div>
        {error && <div className={styles.errorContainer}>{error}</div>}
        <div className={styles.actionContainer}>
          <Button
            loading={mutationLoading}
            disabled={disabled}
            onClick={doPurchase}
            onDisabledClick={doPaymentDetailsCheck}
          >
            Place Order
          </Button>
          <ApplePayCheckout
            errorAlertOnAccountExists
            events={events.cartPage}
            onError={(err) => setError(err.message)}
            onSuccess={onMutationSuccess}
            showNonSafariApplePayButton={false}
          />
        </div>
      </div>
    </WebViewAppBarContext.Provider>
  );
}

function PurchaseSubscriptionContextProvider({ children }: { children: React.ReactNode }) {
  const { cartSelector, checkoutType } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  const fetchPricing = useCallback(() => priceCartForCheckout(cart, { checkoutType }), [cart, checkoutType]);

  return (
    <CheckoutContextProvider>
      <CartPricingProvider fetchPricing={fetchPricing}>{children}</CartPricingProvider>
    </CheckoutContextProvider>
  );
}

export default function PurchaseSubscriptionCheckout() {
  const dispatch = useDispatch();
  const location = useLocation<PurchaseSubscriptionCheckoutState>();
  const { device } = usePurchaseSubscriptionContext();
  const { checkoutPaths } = useSubscriptionCartMode();
  const { subscriptionOption, petId, upgradeOldSku } = location.state;

  const setGiftCardCode = useCallback(
    (code: string | null) => {
      if (code) {
        dispatch(cartActions.addGiftCard(code));
      } else {
        dispatch(cartActions.removeGiftCard());
      }
    },
    [dispatch],
  );

  const setCouponCode = useCallback(
    (code: string | null) => {
      if (code) {
        dispatch(cartActions.addCoupon(code));
      } else {
        dispatch(cartActions.removeCoupon());
      }
    },
    [dispatch],
  );

  const fallbackComponent = () => <Redirect to={checkoutPaths.PlanSelection(device.moduleId)} />;

  if (!subscriptionOption) {
    return fallbackComponent();
  }

  return (
    <PurchaseSubscriptionContextProvider>
      <PurchaseSubscriptionCheckoutMain
        onGiftCardCodeChanged={setGiftCardCode}
        onCouponCodeChanged={setCouponCode}
        subscriptionProduct={subscriptionOption}
        petId={petId}
        upgradeOldSku={upgradeOldSku}
      />
    </PurchaseSubscriptionContextProvider>
  );
}
