import store from './reduxStore';
import { configActions } from '../reducers/config';
import { sessionActions } from '../reducers/session';
import { storeShopActions } from '../reducers/storeShop';

/**
 * Clears all the user-specific data from the Redux store.
 */
export function clearUserSessionData() {
  const reduxState = store.getState();
  if (reduxState.session?.userId) {
    store.dispatch(sessionActions.destroySession());

    // The site config fetched from the server is sometimes dependent on the current user (e.g. nano resellers will
    // have nano boxes in the returned product data). So if we want to clear the user session data, we also need to
    // clear the site config so it gets re-fetched.
    store.dispatch(configActions.clearConfig());
  }

  if (reduxState.storeShop.checkout.customerName || reduxState.storeShop.checkout.shippingAddress) {
    store.dispatch(storeShopActions.resetCheckout());
  }
}
