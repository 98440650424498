import { DateTime } from 'luxon';
import React from 'react';
import styles from '../../styles/lineItem.module.scss';
import { GiftCardDelivery } from '../../types';

export default function GiftCardInformation({ delivery }: { delivery: GiftCardDelivery }) {
  let deliveryDate = 'Right away';
  if (delivery.deliverAt) {
    deliveryDate = DateTime.fromISO(delivery.deliverAt).toLocaleString(DateTime.DATE_SHORT);
  }

  return (
    <div className={styles.giftCardDeliveryInformation}>
      <div className={styles.giftLine}>{delivery.emailAddress}</div>
      <div className={styles.giftLine}>{deliveryDate}</div>
    </div>
  );
}
