import classNames from 'classnames';
import React, { useState } from 'react';
import Button from '../../components/Button';
import { IGiftCardProduct } from '../../types';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../reducers/storeCart';
import setCouponCookie from '../../lib/setCouponCookie';
import { validateEmail } from '../../lib/util';
import { Redirect } from 'react-router-dom';
import styles from './Rescue.module.scss';

interface RescueProps {
  addToBag?: (product: IGiftCardProduct, emailAddress: string) => void;
  product: IGiftCardProduct;
}

const MAX_CARDS = 99; // Impose a sane limit

export function Rescue({ addToBag, product }: RescueProps) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [submitted, setSubmitted] = useState(false);

  if (submitted) {
    return <Redirect to="/bag" />;
  }

  const handleSubmit = () => {
    const number = parseInt(inputValue);
    if (isNaN(number) || number <= 0 || number > MAX_CARDS) {
      window.alert(`Please enter a number greater than 0 and less than ${MAX_CARDS}`);
      return;
    }
    if (!emailValue) {
      window.alert(`Please enter an email address`);
      return;
    }
    if (!validateEmail(emailValue)) {
      window.alert('Please enter a valid email address');
      return;
    }
    for (let i = 0; i < number; i++) {
      if (addToBag) {
        addToBag(product, emailValue);
      } else {
        dispatch(
          cartActions.addGiftCardCartItem({
            sku: product.sku,
            giftCardDelivery: {
              deliverAt: null,
              emailAddress: emailValue,
              firstName: `Fi Customer`,
              gifterName: `Fi`,
              lastName: null,
              personalMessage: null,
            },
            quantity: 1,
          }),
        );
      }

      // Set a bogus coupon cookie so that Unbounce modals don't display in the cart.
      setCouponCookie('RESCUEGC');
    }
    setSubmitted(true);
  };

  // Also mark email_captured cookie so that Unbounce modals don't display
  const emailCaptured = (
    <img src="https://api.tryfi.com/api/ecommerce/emailcaptured.gif" alt="" style={{ display: 'none' }} />
  );

  return (
    <div className={styles.rescuePage}>
      {emailCaptured}

      <div className={styles.rescueHero}>
        <img src="/rescue_hero.jpg" alt="A dog" />
      </div>

      <div className={classNames([styles.rescueMain, styles.splitContainer])}>
        <div className={styles.rescueLeft}>
          <h5>Welcome</h5>
          <h2>Fi Partners</h2>
          <div>
            <img src="/gift_cards_multi.png" width="92px" alt="Several Fi gift cards" />
            <p> You can use this page to add any number of gift cards for 1-year subscriptions to your cart.</p>
          </div>
        </div>
        <div className={styles.rescueRight}>
          <div className={styles.rescueForm}>
            <div>
              <p>How many cards would you like?</p>
              <input
                type="text"
                placeholder="Enter a number"
                value={inputValue}
                onChange={(e) => setInputValue(e.currentTarget.value)}
                data-hj-whitelist
              />
            </div>
            <div>
              <p>Who should we send this to?</p>
              <input
                type="text"
                placeholder="Email to send them to"
                value={emailValue}
                onChange={(e) => setEmailValue(e.currentTarget.value)}
                data-hj-whitelist
              />
            </div>
            <div>
              <Button onClick={handleSubmit} className={styles.button}>
                Add to Cart
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
