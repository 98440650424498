import React, { useCallback, useState } from 'react';
import { gqlTypes } from '../../../../types';
import Button from '../../../../components/Button';
import { logInternalError } from '../../../../lib/errors';
import {
  RescuePlacementModel,
  rescuePlacementMainQuery,
  rescuePlacementPath,
  defaultRescuePlacementNavigationState,
  RedirectToRescuePlacementHome,
} from '../rescuePlacementUtils';
import { useMutation } from '@apollo/client';
import GoBackLink from '../components/GoBackLink/GoBackLink';
import PetView from '../PetView/PetView';
import { Redirect } from 'react-router-dom';
import { fullName } from '../../../../lib/util';
import Checkbox from '../../../../components/Checkbox';
import styles from './PetRecall.module.scss';
import { rescueRecallPetMutation } from '../../../../graphql-operations';

function BaseSelector({
  bases,
  selectedBases,
  setSelectedBases,
}: {
  bases: gqlTypes.baseDetails[];
  selectedBases: string[];
  setSelectedBases(newBases: string[]): void;
}) {
  if (bases.length === 0) {
    return null;
  }

  return (
    <div>
      <p>Also unassign the following bases:</p>
      {bases.map((base, i) => (
        <div key={i} className={styles.rescueBase}>
          <Checkbox
            checked={selectedBases.includes(base.baseId)}
            onChange={(newChecked) => {
              if (newChecked && !selectedBases.includes(base.baseId)) {
                setSelectedBases(selectedBases.concat([base.baseId]));
              } else if (!newChecked) {
                setSelectedBases(selectedBases.filter((b) => b !== base.baseId));
              }
            }}
            label={base.name ?? base.baseId}
          />
        </div>
      ))}
      <p>Unassigning these bases will allow an adopter to later associate them to their account.</p>
    </div>
  );
}

function RecallPetView({ pet, bases }: { pet: gqlTypes.petDetails; bases: gqlTypes.baseDetails[] }) {
  const [recallPet, { loading, error }] = useMutation<
    gqlTypes.ECOMMERCE_rescueRecallPet,
    gqlTypes.ECOMMERCE_rescueRecallPetVariables
  >(rescueRecallPetMutation, {
    refetchQueries: [{ query: rescuePlacementMainQuery }],
  });

  const [selectedBases, setSelectedBases] = useState<string[]>([]);
  const [done, setDone] = useState(false);

  const onSubmit = useCallback(() => {
    if (loading) {
      return;
    }

    recallPet({
      variables: {
        input: {
          petId: pet.id,
          unassignBaseIds: selectedBases,
        },
      },
    })
      .then(() => {
        setDone(true);
      })
      .catch((err) => {
        logInternalError(err);
      });
  }, [loading, pet.id, recallPet, selectedBases]);

  if (done) {
    return (
      <Redirect
        to={{
          pathname: rescuePlacementPath,
          state: defaultRescuePlacementNavigationState,
        }}
      />
    );
  }

  return (
    <div>
      <BaseSelector bases={bases} selectedBases={selectedBases} setSelectedBases={setSelectedBases} />
      <Button onClick={onSubmit}>RECALL INTO FOSTER ORGANIZATION</Button>
      {error && <div className={styles.textDanger}>{error.message}</div>}
    </div>
  );
}

export default function PetRecall({ model, petId }: { model: RescuePlacementModel; petId: string }) {
  const pet = model.getPetById(petId);

  if (!pet) {
    return <RedirectToRescuePlacementHome />;
  }

  const associatedUser = model.associatedUserForPet(pet);
  const bases = model.getUnassignableBasesForPet(petId);

  return (
    <div>
      <GoBackLink />
      <PetView pet={pet} title={`Recalling ${pet.name}`} />
      {associatedUser && (
        <p>
          This pet is currently associated with{' '}
          <strong>
            {fullName(associatedUser)} ({associatedUser.email})
          </strong>
        </p>
      )}
      <RecallPetView pet={pet} bases={bases} />
    </div>
  );
}
