import React, { useContext, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { CartPricingProvider } from '../../../../contexts/CartPricingContext';
import useCheckoutPaths from '../../../../hooks/useCheckoutPaths';
import * as events from '../../../../lib/analytics/events';
import CheckoutContext from '../../../../lib/CheckoutContext';
import { priceCartForCheckout } from '../../../../lib/pricing';
import CheckoutSection from '../../../../models/CheckoutSection';
import StepContainer from '../../components/StepContainer';
import ConfirmSection from './ConfirmSection';
import { useCartMode } from '../../../../lib/cartModes';

interface AddressForTaxCalculation {
  country: string;
  postalCode: string;
}

export default function Confirm() {
  const { cart, requiresShippingDetails, session, checkoutState } = useContext(CheckoutContext);
  const { checkoutType } = useCartMode();
  const checkoutPaths = useCheckoutPaths();

  const address: AddressForTaxCalculation | undefined = useMemo(() => {
    if (checkoutState.shippingAddress && checkoutState.shippingCode) {
      return {
        country: checkoutState.shippingAddress.country,
        postalCode: checkoutState.shippingAddress.zip,
      };
    }
    return undefined;
  }, [checkoutState.shippingAddress, checkoutState.shippingCode]);

  const fetchPricing = useCallback(() => {
    return priceCartForCheckout(cart, {
      shippingCode: checkoutState.shippingCode,
      address: address,
      checkoutType,
    });
  }, [cart, checkoutState.shippingCode, checkoutType, address]);

  const email = session?.email;
  const missingShipping = requiresShippingDetails && (!checkoutState.shippingAddress || !checkoutState.shippingCode);

  if (missingShipping || !email) {
    return <Redirect to={checkoutPaths.Shipping} />;
  } else if (!checkoutState.billingInfo) {
    return <Redirect to={checkoutPaths.Payment} />;
  }

  return (
    <StepContainer checkoutSection={CheckoutSection.confirm}>
      <CartPricingProvider fetchPricing={fetchPricing} trackError={(message) => events.confirmation.error(message)}>
        <ConfirmSection email={email} />
      </CartPricingProvider>
    </StepContainer>
  );
}
