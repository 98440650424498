import React from 'react';
import { ReactComponent as ReturnsIcon } from '../../assets/images/icons/returns.svg';
import { ReactComponent as ShippingIcon } from '../../assets/images/icons/shipping.svg';
import styles from './CartSummary.module.scss';

export default function CartValueProps() {
  return (
    <div className={styles.cartValuePropsContainer}>
      <div className={styles.cartValuePropsCelledSection}>
        <div className={styles.cartValueProp}>
          <div className={styles.cartValuePropIcon}>
            <ShippingIcon />
          </div>
          <div className={styles.cartValuePropText}>
            <div>Free ground shipping.</div>
            <div>1-2 day available at checkout.</div>
          </div>
        </div>
        <div className={styles.cartValueProp}>
          <div className={styles.cartValuePropIcon}>
            <ReturnsIcon />
          </div>
          <div className={styles.cartValuePropText}>
            <div>30-day risk free</div>
          </div>
        </div>
      </div>
    </div>
  );
}
