import { ApolloClient, HttpLink } from '@apollo/client';
import { clearUserSessionData } from '../session';
import cache from './cache';

async function customFetch(input: RequestInfo, init?: RequestInit) {
  const response = await fetch(input, init);

  // We only use Apollo when we know the user is logged in. If we've made a GQL request and we get a 401 response,
  // that means we thought the user was authenticated but the user's session has expired. We should clear the
  // stored user session data in this case so the site will behave as if the user is logged out.
  if (response.status === 401) {
    clearUserSessionData();
  }

  return response;
}

const link = new HttpLink({
  uri: `${process.env.REACT_APP_WEB_API_URL}/graphql`,
  credentials: 'include',
  fetch: customFetch,
});

const client = new ApolloClient({
  cache,
  link,
});

export default client;
