import classNames from 'classnames';
import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import Chooser, { ChooserGroup } from '../Chooser';
import * as events from '../../lib/analytics/events';
import * as types from '../../types';
import { SubscriptionOption } from '../SubscriptionOptionDetails';
import styles from './SubscriptionOptions.module.scss';
import { getCartV2FormattedTotal } from '../CartSummary/CartActions';

interface ChooserGroupsProps {
  subscriptionProducts: SubscriptionOption[];
}

function durationForSubscriptionProduct(subscriptionProduct: SubscriptionOption): string {
  if (subscriptionProduct.renewalMonths === 6) {
    return '6 month membership';
  } else if (subscriptionProduct.renewalMonths === 12) {
    return '1 year membership';
  } else if (subscriptionProduct.renewalMonths === 24) {
    return '2 year membership';
  }

  return subscriptionProduct.name;
}

function savingsForSubscriptionProduct(subscriptionProduct: SubscriptionOption): string | null {
  if (subscriptionProduct.renewalMonths === 12) {
    return 'Save 5%';
  } else if (subscriptionProduct.renewalMonths === 24) {
    return 'Save 15%';
  }

  return null;
}

function GiftFiSubscriptionOptionButton({ subscriptionProduct }: { subscriptionProduct: SubscriptionOption }) {
  const savings = savingsForSubscriptionProduct(subscriptionProduct);

  return (
    <div className={styles.giftFiSubscriptionOptionButton}>
      <div className={styles.giftFiSubscriptionOptionButtonPrice}>
        ${getCartV2FormattedTotal(subscriptionProduct.priceInCents)}
        {savings && (
          <div className={styles.giftFiSubscriptionOptionButtonDiscount}>
            {savingsForSubscriptionProduct(subscriptionProduct)}
          </div>
        )}
      </div>
      <div className={styles.giftFiSubscriptionOptionButtonDetails}>
        <span>Fi Series 3</span> + <span>{durationForSubscriptionProduct(subscriptionProduct)}</span>
      </div>
    </div>
  );
}

function useSubscriptionChooserGroups({ subscriptionProducts }: ChooserGroupsProps) {
  // For the gifting subscription chooser, we only want to show plans that are 6 months or longer
  const filteredSubscriptionProducts = useMemo(
    () => subscriptionProducts.filter((subscriptionProduct) => subscriptionProduct.renewalMonths >= 6),
    [subscriptionProducts],
  );

  const chooserGroup = useCallback(
    (subscriptionProductGroup: SubscriptionOption[]): ChooserGroup<string | undefined> => ({
      options: subscriptionProductGroup.map((subscriptionProduct) => ({
        content: <GiftFiSubscriptionOptionButton subscriptionProduct={subscriptionProduct} />,
        value: subscriptionProduct.sku,
      })),
    }),
    [],
  );

  return [chooserGroup(filteredSubscriptionProducts)];
}

interface SubscriptionOptionsProps {
  onSubscriptionChange: (sku: string | undefined) => void;
  selectedSubscriptionSku?: string;
  subscriptionProducts: types.ISubscriptionProduct[];
  title: string;
  subscriptionPickerRef?: MutableRefObject<HTMLDivElement | null>;
  startAsUnselected: boolean;
}

export default function SubscriptionOptions({
  onSubscriptionChange,
  selectedSubscriptionSku,
  subscriptionProducts,
  title,
  subscriptionPickerRef,
  startAsUnselected,
}: SubscriptionOptionsProps) {
  const chooserGroups = useSubscriptionChooserGroups({
    subscriptionProducts,
  });
  const [showSelected, setShowSelected] = useState<boolean>(!startAsUnselected);

  return (
    <div className={classNames('subscription-options', styles.variant)} ref={subscriptionPickerRef}>
      <div className={classNames(styles.variantTitleGroup)}>
        <div className={classNames(styles.variantTitle)}>{title}</div>
      </div>
      <Chooser
        autoHeight
        onSelect={(subscriptionSku: string | undefined) => {
          setShowSelected(true);
          onSubscriptionChange(subscriptionSku);

          if (!subscriptionSku) {
            return;
          }

          const subscription = subscriptionProducts.find((product) => product.sku === subscriptionSku);
          if (subscription) {
            events.subscriptionSelected(subscription);
          }
        }}
        selectedOption={showSelected ? selectedSubscriptionSku : undefined}
        groups={chooserGroups}
      />
    </div>
  );
}
