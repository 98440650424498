import { useQuery } from '@apollo/client';
import React from 'react';
import { Helmet } from 'react-helmet';
import * as qs from 'query-string';
import { getLoadingOrErrorElement } from '../../lib/util';
import { gqlTypes } from '../../types';
import { SimpleDismissableModal } from '../../components/modals';
import * as events from '../../lib/analytics/events';
import FiLogo from '../../assets/images/logo-qr-code.svg';
import { QRCode } from 'react-qrcode-logo';
import { ReactComponent as LinkIcon } from '../../assets/images/icons/icon_link.svg';
import { referralsQuery } from '../../graphql-operations';
import InviteList from './components/InviteList/InviteList';
import styles from './Referrals.module.scss';

export const getShareInformation = (code: string, message: string) => ({
  title: `Join the pack`,
  text: message,
  url: getCodeUrl(code),
});

interface ReferralCodeProps {
  code: string;
  message: string;
}

function WebShareButton({ code, message }: ReferralCodeProps) {
  const handleShare = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    events.referrals.shareClicked(true);
    // Web share API: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
    (navigator as any).share(getShareInformation(code, message));
  };

  return (
    <div className={styles.shareButton} onClick={handleShare}>
      <div className={styles.code}>
        <div className={styles.heading}>Your invite code</div>
        <div>{code}</div>
      </div>
      <div className={styles.icon}>
        <LinkIcon />
      </div>
    </div>
  );
}

function AndroidShareButton({ code, message }: ReferralCodeProps) {
  const handleShare = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    events.referrals.shareClicked(true);
    (window as any).android.share(JSON.stringify(getShareInformation(code, message)));
  };

  return (
    <div className={styles.shareButton} onClick={handleShare}>
      <div className={styles.code}>
        <div className={styles.heading}>Your invite code</div>
        <div>{code}</div>
      </div>
      <div className={styles.icon}>
        <LinkIcon />
      </div>
    </div>
  );
}

// This will be used if a native share integration is not available
function FallbackShareButton({ code, message }: ReferralCodeProps) {
  const shareInformation = getShareInformation(code, message);
  const mailtoLink = `mailto:?${qs.stringify({
    subject: shareInformation.title,
    body: shareInformation.text,
  })}`;

  return (
    <SimpleDismissableModal
      trigger={
        <div className={styles.shareButton}>
          <div className={styles.code}>
            <div className={styles.heading}>Your invite code</div>
            <div>{code}</div>
          </div>
          <div className={styles.icon}>
            <LinkIcon />
          </div>
        </div>
      }
      onOpen={() => events.referrals.shareClicked(false)}
    >
      <div>
        <a href={mailtoLink} target="_blank" rel="noopener noreferrer">
          ✉ Share via email
        </a>
      </div>
    </SimpleDismissableModal>
  );
}

// Determine which type of sharing integration is available on the client platform
function ShareButton({ code, message }: ReferralCodeProps): JSX.Element {
  if ((navigator as any).share) {
    // View supports native sharing https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
    return <WebShareButton code={code} message={message} />;
  } else if ((window as any).android && (window as any).android.share) {
    // Use android specific native sharing
    return <AndroidShareButton code={code} message={message} />;
  } else {
    // No native sharing exists
    return <FallbackShareButton code={code} message={message} />;
  }
}

function getCodeUrl(code: string): string {
  return `https://shop.tryfi.com/r/${code}/?utm_source=referrals`;
}

interface ProgramDetailsProps {
  totalDollarsEarned?: number;
  numActiveReferrals?: number;
}

function ProgramDetails({ totalDollarsEarned, numActiveReferrals }: ProgramDetailsProps): JSX.Element {
  return (
    <>
      <div className={styles.give1MonthGet1DollarPerMonthDetails}>
        {totalDollarsEarned && totalDollarsEarned > 0 && (
          <div className={styles.give1MonthGet1DollarPerMonthDetailsTitle}>
            <h3>Congrats!</h3>
            <p>You earned ${totalDollarsEarned} in Fi credits.</p>
          </div>
        )}
        {!totalDollarsEarned && (
          <div className={styles.give1MonthGet1DollarPerMonthDetailsTitle}>
            <h3>You have 5 invitations!</h3>
            <p>Invite your friends and earn Fi credit.</p>
          </div>
        )}
        {numActiveReferrals && numActiveReferrals > 0 && (
          <div className={styles.give1MonthGet1DollarPerMonthDetailsPendingEarn}>
            Congrats! You have {numActiveReferrals} active referrals. ${numActiveReferrals} will be added to your
            balance this month.
          </div>
        )}
      </div>
      <hr />
    </>
  );
}

export default function Referrals() {
  // Retrieve current user's referral information
  const { loading, error, data } = useQuery<gqlTypes.ECOMMERCE_referrals>(referralsQuery);

  let finalError: Error | undefined = error;
  if (data && data.currentUser.referrals.codeSuspended) {
    finalError = new Error(
      'That referral code has been suspended. Please contact support@tryfi.com for further information!',
    );
  }

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, finalError);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  if (!data) {
    return <>No referral code was found, please try again or contact support@tryfi.com for further information</>;
  }

  const { referralCode, referralMessage, referralProgramName, give1MonthGet1DollarPerMonthDetails, invitedReferees } =
    data.currentUser.referrals;

  return (
    <div className={styles.main}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0" />
      </Helmet>
      <div className={styles.content}>
        {referralProgramName === 'Give1MonthGet1DollarPerMonthNoLimit' &&
          give1MonthGet1DollarPerMonthDetails &&
          (give1MonthGet1DollarPerMonthDetails.totalDollarsEarned > 0 ||
            give1MonthGet1DollarPerMonthDetails.numActiveReferrals > 0) && (
            <ProgramDetails
              numActiveReferrals={give1MonthGet1DollarPerMonthDetails.numActiveReferrals}
              totalDollarsEarned={give1MonthGet1DollarPerMonthDetails.totalDollarsEarned}
            />
          )}
        {referralProgramName === 'Give1MonthGet1DollarPerMonthWithLimit' && (
          <>
            <ProgramDetails
              totalDollarsEarned={give1MonthGet1DollarPerMonthDetails?.totalDollarsEarned}
              numActiveReferrals={give1MonthGet1DollarPerMonthDetails?.numActiveReferrals}
            />
            <InviteList
              maxInvites={5}
              invites={invitedReferees.map((referee) => ({
                name: referee.firstName,
                profileImageUrl: referee.profilePicture?.nearestResized,
              }))}
              code={referralCode}
              message={referralMessage}
            />
          </>
        )}
        <h2>Invite Your Friends</h2>
        <p>
          {referralProgramName === 'Give1MonthGet1DollarPerMonthNoLimit' ||
          referralProgramName === 'Give1MonthGet1DollarPerMonthWithLimit'
            ? 'Invite friends to Fi and earn up to $100 in Fi credit per referral!'
            : 'Invite more friends to Fi and get a free month of subscription when they activate their collar.'}
        </p>
        <p className={styles.scanOrShareCode}>Scan or share code</p>
        <div className={styles.qrCodeWrapper}>
          <QRCode
            size={140}
            value={getCodeUrl(referralCode)}
            logoImage={FiLogo}
            logoWidth={40}
            logoHeight={40}
            quietZone={0}
            removeQrCodeBehindLogo={false}
            qrStyle="squares"
          />
        </div>

        <ShareButton code={referralCode} message={referralMessage} />

        {(referralProgramName === 'Give1MonthGet1DollarPerMonthNoLimit' ||
          referralProgramName === 'Give1MonthGet1DollarPerMonthWithLimit') && (
          <p className={styles.footerDescription}>
            Every friend you refer will contribute $1 to your Fi balance every month. Capped at $100 per referral. That
            simple.
          </p>
        )}
      </div>
    </div>
  );
}
