import { useQuery } from '@apollo/client';
import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import FatalErrorMessage from '../components/FatalErrorMessage';
import Loading from '../components/Loading';
import WebViewAppBar from '../components/WebViewAppBar';
import { series3UpgradeOfferQuery } from '../graphql-operations';
import useUpgradePetId from '../hooks/useUpgradePetId';
import { getCustomerMessageFromApolloError, logInternalError } from '../lib/errors';
import { getProductsBySku } from '../lib/product';
import UpgradeablePet from '../models/UpgradeablePet';
import * as types from '../types';
import { gqlTypes } from '../types';

export interface Series3UpgradeStore {
  moduleIdToPetNameMap: Map<string, string>;
  offeredPlan: types.ISubscriptionProduct | null;
  series3Collar: types.ICollarKitProduct;
  upgradeablePets: UpgradeablePet[];
}

const Series3UpgradeContext = createContext<Series3UpgradeStore | null>(null);
export default Series3UpgradeContext;

interface Series3UpgradeContextProviderProps {
  children: React.ReactNode;
}

export function Series3UpgradeContextProvider({ children }: Series3UpgradeContextProviderProps) {
  const series3UpgradeCartItems = useSelector((state: types.AppState) => state.series3UpgradeShop.cart.cartItems);
  const products = useSelector((state: types.AppState) => state.config.products);
  const productsBySku = getProductsBySku(products);
  const petId = useUpgradePetId();

  const {
    data,
    loading: offerLoading,
    error: offerError,
  } = useQuery<gqlTypes.ECOMMERCE_series3UpgradeOffer, gqlTypes.ECOMMERCE_series3UpgradeOfferVariables>(
    series3UpgradeOfferQuery,
    {
      variables: { petId },
    },
  );

  const contextValue: Series3UpgradeStore | null = useMemo(() => {
    if (!data) {
      return null;
    }

    const series3Collar = products.find((product) => product.id === types.series3CollarId);
    if (!series3Collar || series3Collar.category !== types.ProductCategory.COLLAR_KIT) {
      return null;
    }

    const offerData = data.series3UpgradeOffer;
    const offeredPlan = offerData ? productsBySku.get(offerData.planCode) : null;
    const deviceOffers = offerData ? offerData.deviceOffers : [];

    const moduleIdsInCart = new Set(Object.values(series3UpgradeCartItems).map((cartItem) => cartItem.forModuleId));

    const moduleIdToPetNameMap = new Map<string, string>();
    const upgradeablePetsNotAlreadyInCart: UpgradeablePet[] = [];
    for (const deviceOffer of deviceOffers) {
      const moduleId = deviceOffer.device.moduleId;
      const pet = deviceOffer.pet;
      if (!moduleIdsInCart.has(moduleId)) {
        upgradeablePetsNotAlreadyInCart.push({
          proratedCreditAmountInCents: deviceOffer.proratedCreditAmountInCents,
          promoDiscounts: deviceOffer.promoDiscounts,
          imageUrl: pet.photos.first?.image.fullSize ?? null,
          moduleId,
          name: pet.name,
          id: pet.id,
        });
      }

      moduleIdToPetNameMap.set(moduleId, pet.name);
    }

    return {
      moduleIdToPetNameMap,
      offeredPlan: offeredPlan && types.isModuleSubscriptionProduct(offeredPlan) ? offeredPlan : null,
      series3Collar,
      upgradeablePets: upgradeablePetsNotAlreadyInCart,
    };
  }, [data, products, productsBySku, series3UpgradeCartItems]);

  if (offerError) {
    const customerFacingErrorMessage = getCustomerMessageFromApolloError(offerError);
    if (!customerFacingErrorMessage) {
      logInternalError(offerError.message);
    }

    return (
      <>
        <WebViewAppBar />
        <FatalErrorMessage
          errorMessage={
            customerFacingErrorMessage
              ? // A common customer-facing error thrown from the backend is 'We were unable to continue because
                // the billing address on your account is invalid.' when Recurly is unable to calculate tax for their
                // saved billing address. This is low volume enough that for now we will just direct them to contact
                // support rather than presenting them with a way to update their billing address.
                `${customerFacingErrorMessage} Please contact support@tryfi.com`
              : `An error occurred loading your upgrade offer. If the problem persists, reach out to support@tryfi.com.`
          }
        />
      </>
    );
  }

  if (offerLoading) {
    return <Loading />;
  }

  return <Series3UpgradeContext.Provider value={contextValue}>{children}</Series3UpgradeContext.Provider>;
}

export function useSeries3UpgradeContext() {
  const context = useContext(Series3UpgradeContext);
  if (!context) {
    throw new Error('useSeries3UpgradeContext must be used within a Series3UpgradeContextProvider');
  }
  return context;
}
