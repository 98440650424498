import { gqlTypes } from '../types';
import * as types from '../types';
import { expectUnreachable } from './util';

export function gqlBillingCadenceToBillingCadence(gqlBillingCadence: gqlTypes.BillingCadence): types.BillingCadence {
  switch (gqlBillingCadence) {
    case gqlTypes.BillingCadence.MONTH:
      return types.BillingCadence.Month;
    case gqlTypes.BillingCadence.YEAR:
      return types.BillingCadence.Year;
    case gqlTypes.BillingCadence.WEEK:
      return types.BillingCadence.Week;
    default:
      expectUnreachable(gqlBillingCadence);
      throw new Error(`Unknown billing cadence: ${gqlBillingCadence}`);
  }
}

export function commonDetailsForSubscription(subscription: types.ISubscriptionProduct): { subscriptionName: string } {
  const renewalMonths = subscription.renewalMonths;
  if (renewalMonths % 12 === 0) {
    const years = Math.round(renewalMonths / 12);
    return { subscriptionName: `${years} Year` };
  }

  if (renewalMonths === 1) {
    return { subscriptionName: 'Monthly' };
  }

  return { subscriptionName: `${renewalMonths} Months` };
}
