import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RESCUE_GIFT_CARD_PRODUCT_ID } from '../lib/RescueGiftCardProductId.constant';
import * as types from '../types';

export default function useGiftCardProducts(): types.IGiftCardProduct[] {
  const products = useSelector((state: types.AppState) => state.config.products);
  return useMemo(
    () =>
      products.flatMap((product) => {
        if (product.category === types.ProductCategory.GIFT_CARD && product.id !== RESCUE_GIFT_CARD_PRODUCT_ID) {
          return product;
        }

        return [];
      }),
    [products],
  );
}
