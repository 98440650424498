import { combineReducers } from '@reduxjs/toolkit';
import * as types from '../types';
import { checkoutInitialState, createCheckoutSlice } from './checkout';
import { cartActions, cartInitialState, cartReducer } from './storeCart';

const initialState: types.StoreShopState = {
  cart: cartInitialState,
  checkout: checkoutInitialState,
};

const name = 'storeShop' as const;

const { actions: checkoutActions, reducer: checkoutReducer } = createCheckoutSlice(name);

export const storeShopActions = {
  ...cartActions,
  ...checkoutActions,
};
export const storeShopReducer = combineReducers({
  cart: cartReducer,
  checkout: checkoutReducer,
});
export const storeShopInitialState = { [name]: initialState };
