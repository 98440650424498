import { gql } from '@apollo/client';

export const purchaseConsumerStoreCartMutation = gql`
  mutation ECOMMERCE_purchaseConsumerStoreCart($input: ConsumerStoreCartInput!) {
    purchaseConsumerStoreCart(input: $input) {
      chargeInvoice {
        number
      }
      giftCardInvoices {
        number
      }
      isReturningCustomer
    }
  }
`;

export const purchaseSeries3UpgradeCartMutation = gql`
  mutation ECOMMERCE_purchaseSeries3UpgradeCart($input: Series3UpgradeCartInput!) {
    purchaseSeries3UpgradeCart(input: $input) {
      chargeInvoice {
        number
      }
      giftCardInvoices {
        number
      }
      isReturningCustomer
    }
  }
`;
