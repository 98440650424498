import classNames from 'classnames';
import React from 'react';
import styles from './RoundOptionButton.module.scss';

export interface RoundOptionButtonProps<OptionType> {
  available: boolean;
  className?: string;
  contents?: React.ReactNode;
  onSelect(value: OptionType): void;
  selected: boolean;
  value: OptionType;
}

export default function RoundOptionButton<OptionType>({
  available,
  className,
  contents,
  onSelect,
  selected,
  value,
}: RoundOptionButtonProps<OptionType>) {
  const handleClick: React.MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();

    if (available && !selected) {
      onSelect(value);
    }
  };

  const classes = classNames(className, styles.roundButton, {
    [styles.selected]: selected,
    [styles.unavailable]: !available,
  });

  return (
    <div className={classes} onClick={handleClick}>
      <div className={styles.contents}>{contents}</div>
    </div>
  );
}
