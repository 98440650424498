import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import Chooser from '../../components/Chooser';
import Dropdown from '../../components/Dropdown';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import SeriesToggle from '../../components/SeriesToggle/SeriesToggle';
import * as events from '../../lib/analytics/events';
import { centsToDollars } from '../../lib/util';
import { cartActions as storeCartActions } from '../../reducers/storeCart';
import * as types from '../../types';
import { BandSeries, ProductCategory } from '../../types';
import { Series } from '../../types/Series';
import AddToBagButton from '../ProductDetails/components/AddToBagButton';
import AdditionalLink from './components/AdditionalLink';
import styles from './EndlinksDetails.module.scss';

interface QuantityOption {
  label: string;
  value: number;
}
export default function EndlinksDetails() {
  const dispatch = useDispatch();
  const history = useHistory();

  const f1Endlinks: types.IEndlinkProduct[] = useSelector((state: types.AppState) =>
    state.config.products.flatMap((product) => {
      if (product.category === ProductCategory.ENDLINK && product.bandSeries === BandSeries.F1) {
        return product;
      }
      return [];
    }),
  );

  const f3Endlinks: types.IEndlinkProduct[] = useSelector((state: types.AppState) =>
    state.config.products.flatMap((product) => {
      if (product.category === ProductCategory.ENDLINK && product.bandSeries === BandSeries.F3) {
        return product;
      }
      return [];
    }),
  );

  const seriesOptionSet: Set<Series> = useMemo(() => {
    const seriesSet: Set<Series> = new Set();
    if (f3Endlinks.length > 0) {
      seriesSet.add(Series.Series3);
    }

    if (f1Endlinks.length > 0) {
      seriesSet.add(Series.Series2);
    }

    return seriesSet;
  }, [f1Endlinks.length, f3Endlinks.length]);

  // Default to S3 endlinks if we have any
  const [selectedSeries, setSelectedSeries] = useState(
    seriesOptionSet.has(Series.Series3) ? Series.Series3 : Series.Series2,
  );

  // If no S3 endlinks product exists, we will fallback to single product page with F1 endlink product
  const [selectedProduct, setSelectedProduct] = useState(f3Endlinks[0] ?? f1Endlinks[0]);
  const [quantity, setQuantity] = useState(1);
  const changeSeries = useCallback(setSelectedSeries, [selectedSeries, setSelectedSeries]);
  const quantityOptions: QuantityOption[] = useMemo(() => {
    const values: QuantityOption[] = [];
    for (let i = 1; i <= 10; i++) {
      values.push({ label: `${i} box${i > 1 ? 'es' : ''} (25 sets)`, value: i });
    }
    return values;
  }, []);

  const selectedQuantity = useMemo(() => {
    if (!quantity) {
      return;
    }

    return quantityOptions.find(({ value }) => value === quantity);
  }, [quantityOptions, quantity]);

  const setSelectedProductBySku = (sku: string) => {
    const foundProduct = [...f3Endlinks, ...f1Endlinks].find((product) => product.sku === sku);
    if (foundProduct) {
      setSelectedProduct(foundProduct);
    }
  };

  const addToBag = useCallback(
    (selectedValues: { quantity: number }) => {
      dispatch(storeCartActions.addSingleLineCartItem({ sku: selectedProduct.sku, quantity: selectedValues.quantity }));

      events.endlinksAddedToBag({
        priceInCents: selectedProduct.priceInCents,
        productName: selectedProduct.name,
        quantity: selectedValues.quantity,
        sku: selectedProduct.sku,
      });

      history.push(AppPaths.Bag);

      return true;
    },
    [dispatch, history, selectedProduct.name, selectedProduct.priceInCents, selectedProduct.sku],
  );

  useEffect(() => {
    events.endlinksViewed({
      priceInCents: selectedProduct.priceInCents,
      productName: selectedProduct.name,
      sku: selectedProduct.sku,
    });
  }, [selectedProduct.name, selectedProduct.priceInCents, selectedProduct.sku]);

  return (
    <>
      <div className={styles.endlinkContainer}>
        <div className={styles.imageGalleryContainer}>
          <ImageGallery
            grayBackground
            media={[
              {
                type: 'image',
                url: `/product_images/${selectedProduct.id}.jpg`,
                retinaWidth: 1920,
                retinaHeight: 1920,
              },
            ]}
            squareAspectRatio
          />
        </div>
        <div className={styles.details}>
          <div className={styles.heading}>
            <h1>Collar Endlinks</h1>
            <h3>${centsToDollars(selectedProduct.priceInCents)} for a box of 50</h3>
            <p className={styles.description}>
              Use these to create 25 of your own compatible Fi bands to ensure they fit onto any Fi tracking module.
            </p>
            <AdditionalLink
              link={{
                description: `Want to be featured in our store?`,
                text: `Get in touch →`,
                url: `mailto:custom-collars@tryfi.com`,
              }}
            />
          </div>
          <div>
            <h2>Model</h2>
            <SeriesToggle
              selectedSeries={selectedSeries}
              seriesOptionSet={seriesOptionSet}
              onSeriesChange={(series) => {
                if (series === selectedSeries) {
                  return;
                }

                if (series === Series.Series3) {
                  setSelectedProduct(f3Endlinks[0]);
                } else {
                  setSelectedProduct(f1Endlinks[0]);
                }
                changeSeries(series);
              }}
            />
          </div>
          <div>
            {/* Only Series 3 has multiple sizes */}
            {selectedSeries === Series.Series3 ? (
              <>
                <h2>Size</h2>
                <Chooser
                  horizontal={true}
                  selectedOption={selectedProduct.sku}
                  groups={[
                    {
                      options: f3Endlinks.map((product) => ({
                        value: product.sku,
                        content: product.size,
                      })),
                    },
                  ]}
                  onSelect={(selectedOption) => {
                    setSelectedProductBySku(selectedOption);
                  }}
                />
              </>
            ) : null}
          </div>

          <div>
            <h2>Amount</h2>
            <Dropdown
              id="quantity"
              options={quantityOptions}
              selectedOption={selectedQuantity}
              placeholder="Amount"
              onChange={(option) => {
                if (option) {
                  setQuantity(option.value);
                }
              }}
            />
          </div>

          <div className={styles.action}>
            <AddToBagButton
              onAddToBag={() => {
                addToBag({ quantity });
                return true;
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
