import { combineReducers } from '@reduxjs/toolkit';
import * as types from '../types';
import { checkoutInitialState, createCheckoutSlice } from './checkout';
import { cartActions, cartInitialState, cartReducer } from './subscriptionCart';

const initialState: types.SubscriptionShopState = {
  cart: cartInitialState,
  checkout: checkoutInitialState,
};

const name = 'subscriptionShop' as const;

const { actions: checkoutActions, reducer: checkoutReducer } = createCheckoutSlice(name);

export const subscriptionShopActions = {
  ...cartActions,
  ...checkoutActions,
};
export const subscriptionShopReducer = combineReducers({
  cart: cartReducer,
  checkout: checkoutReducer,
});
export const subscriptionShopInitialState = { [name]: initialState };
