import useCheckoutPaths from '../hooks/useCheckoutPaths';

enum CheckoutBreadcrumb {
  shipping = 0,
  payment = 1,
  review = 2,
}

export function useBreadcrumbForCheckoutSection(locationPathName: string): CheckoutBreadcrumb | undefined {
  const checkoutPaths = useCheckoutPaths();

  if (locationPathName === checkoutPaths.Shipping) {
    return CheckoutBreadcrumb.shipping;
  } else if (locationPathName === checkoutPaths.Payment) {
    return CheckoutBreadcrumb.payment;
  } else if (locationPathName === checkoutPaths.Review) {
    return CheckoutBreadcrumb.review;
  } else {
    return undefined;
  }
}

export default CheckoutBreadcrumb;
