import { ApplePayPaymentAuthorizedEvent } from '@recurly/recurly-js';
import { useCallback } from 'react';
import * as yup from 'yup';
import useHandlePurchase from '../../hooks/useHandlePurchase';
import useShippingOptions from '../../hooks/useShippingOptions';
import { FiApplePayErrorUpdate } from '../../lib/RecurlyProvider';
import { addressValidationSchema } from '../../lib/addressValidation';
import * as authentication from '../../lib/authentication';
import {
  APPLE_PAY_ERROR_CODE_CUSTOM_ACCOUNT_EXISTS,
  APPLE_PAY_ERROR_CODE_CUSTOM_API,
  APPLE_PAY_ERROR_CODE_SHIPPING,
  ApplePayAccountExistsError,
  UserFacingError,
} from '../../lib/errors';
import { isExpeditedShippingAvailableToAddress, isExpeditedShippingAvailableToState } from '../../lib/shipping';
import * as types from '../../types';
import ApplePayPaymentState from './ApplePayPaymentState';
import ApplePayPurchaseResults from './ApplePayPurchaseResults';
import { IApplePayEvents } from './Events';
import useTenancy from '../../hooks/useTenancy';

interface PaymentAuthorizedHookProps {
  addressRequired: boolean;
  cartPricing: types.CartPricing;
  events: IApplePayEvents;
  onSuccess: (results: ApplePayPurchaseResults) => void;
  paymentState: ApplePayPaymentState;
}

function addressValidationPathToApplePayContactField(path: string): ApplePayJS.ApplePayErrorContactField | undefined {
  switch (path) {
    case 'email':
      return 'emailAddress';
    case 'firstName':
    case 'lastName':
      return 'name';
    case 'line1':
    case 'line2':
      return 'addressLines';
    case 'city':
      return 'locality';
    case 'state':
      return 'administrativeArea';
    case 'zip':
      return 'postalCode';
    case 'phone':
      return 'phoneNumber';
    default:
      return undefined;
  }
}

/**
 * @returns a callback that can be used to create the purchase using Fi's API after the Apple Pay payment has been
 * authorized and Recurly has returned a token. The callback returns an object with errors to display
 * on the Apple Pay payment sheet, if any, or undefined if there were no errors.
 */
export default function usePaymentAuthorized({
  addressRequired,
  cartPricing,
  events,
  onSuccess,
  paymentState,
}: PaymentAuthorizedHookProps): (event: ApplePayPaymentAuthorizedEvent) => Promise<FiApplePayErrorUpdate> {
  const shippingOptions = useShippingOptions();
  const tenancy = useTenancy();

  // Rethrow the error when handlePurchase fails so we can catch handlePurchase in the onPaymentAuthorized callback
  // returned by this usePaymentAuthorized hook. When catching the error, the callback can return an error object to
  // the Apple Pay payment sheet which will abort the payment sheet.
  const handlePurchaseErrorCallback = useCallback((errorMessage: string) => {
    throw new UserFacingError(errorMessage);
  }, []);

  const handlePurchaseSuccessCallback = useCallback(
    (
      orderedCart: types.Cart,
      orderedCartPricing: types.CartPricing,
      invoiceNumber: string,
      isReturningCustomer: boolean,
    ) => {
      events.applePaySuccess();

      onSuccess({
        orderedCart,
        orderedCartPricing,
        invoiceNumber,
        isReturningCustomer,
      });
    },
    [events, onSuccess],
  );

  const { handlePurchase } = useHandlePurchase({
    cartPricing,
    onError: handlePurchaseErrorCallback,
    onSuccess: handlePurchaseSuccessCallback,
  });

  return useCallback(
    async (event: ApplePayPaymentAuthorizedEvent) => {
      // Make sure we have a shipping address
      const shippingContact = event.payment.shippingContact;
      if (!shippingContact) {
        return {
          errors: [
            {
              code: APPLE_PAY_ERROR_CODE_SHIPPING,
              contactField: 'addressLines',
              message: 'A shipping address is required',
            },
          ],
        };
      }

      // Validate shipping address
      try {
        addressValidationSchema.validateSync(
          {
            email: shippingContact.emailAddress,
            firstName: shippingContact.givenName,
            lastName: shippingContact.familyName,
            line1: shippingContact.addressLines?.[0],
            line2: shippingContact.addressLines?.[1],
            city: shippingContact.locality,
            state: shippingContact.administrativeArea,
            zip: shippingContact.postalCode,
            phone: shippingContact.phoneNumber,
            shippingCode: paymentState.selectedShippingCode,
          },
          {
            abortEarly: false,
            context: {
              requiresShippingDetails: addressRequired,
            },
          },
        );
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          return {
            errors: err.inner.map((e) => ({
              code: APPLE_PAY_ERROR_CODE_SHIPPING,
              contactField: e.path ? addressValidationPathToApplePayContactField(e.path) : undefined,
              message: e.message,
            })),
          };
        }
      }

      // If they have selected expedited shipping, make sure it's available to their address. This is the only
      // time we can do this validation because for the callback event when the shipping address changed we're only
      // able to access the country and zip code.
      const shippingCode = paymentState.selectedShippingCode;
      const shippingOption = shippingOptions.find((so) => so.code === shippingCode);
      const hasSelectedExpeditedShipping = !!shippingOption?.isExpedited;

      if (hasSelectedExpeditedShipping) {
        if (shippingContact.addressLines && !isExpeditedShippingAvailableToAddress(shippingContact.addressLines)) {
          return {
            errors: [
              {
                code: APPLE_PAY_ERROR_CODE_SHIPPING,
                contactField: 'addressLines',
                message: `Expedited shipping unavailable to PO Box`,
              },
            ],
          };
        }

        if (
          shippingContact.administrativeArea &&
          !isExpeditedShippingAvailableToState(shippingContact.administrativeArea)
        ) {
          return {
            errors: [
              {
                code: APPLE_PAY_ERROR_CODE_SHIPPING,
                contactField: 'administrativeArea',
                message: `Expedited shipping unavailable to territories`,
              },
            ],
          };
        }
      }

      // Hit our Apple Pay REST endpoint to create/update the user's Recurly account and login
      try {
        const tokenId = event.payment.recurlyToken.id;
        await authentication.applePay(tokenId, shippingContact, tenancy);
      } catch (err) {
        events.applePayError(err.message);

        if (err instanceof ApplePayAccountExistsError) {
          return {
            customErrors: [
              {
                code: APPLE_PAY_ERROR_CODE_CUSTOM_ACCOUNT_EXISTS,
                message: err.message,
              },
            ],
          };
        }

        // The REST endpoint might throw some validation errors
        return {
          customErrors: [{ code: APPLE_PAY_ERROR_CODE_CUSTOM_API, message: err.message }],
        };
      }

      // Now execute the purchase mutation
      try {
        await handlePurchase(shippingContact.emailAddress, shippingOption?.code);
      } catch (err) {
        events.applePayError(err.message);

        // When there was an error here it's most likely an error returned by the Recurly API (e.g. payment failed)
        return {
          customErrors: [{ code: APPLE_PAY_ERROR_CODE_CUSTOM_API, message: err.message }],
        };
      }

      return {};
    },
    [addressRequired, events, handlePurchase, paymentState.selectedShippingCode, shippingOptions, tenancy],
  );
}
