import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { affiliateDataActions } from '../../reducers/affiliateData';
import * as types from '../../types';

interface ICJEventComponentProps {
  cjevent?: string;
  setCJEvent(cjevent: string): void;
}

class CJEventComponent extends React.Component<ICJEventComponentProps> {
  public static cjeventImageUrl(cjevent: string) {
    return `https://api.tryfi.com/api/ecommerce/cjevent.gif?cjevent=${cjevent}`;
  }

  public componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const cjEventFromSearch = searchParams.get('cjevent');
    if (cjEventFromSearch) {
      this.props.setCJEvent(cjEventFromSearch);
    } else {
      const cjEventFromCookie = Cookies.get('cjevent');
      if (cjEventFromCookie) {
        this.props.setCJEvent(cjEventFromCookie);
      }
    }
  }

  public render() {
    if (this.props.cjevent) {
      return <img src={CJEventComponent.cjeventImageUrl(this.props.cjevent)} style={{ display: 'none' }} alt="" />;
    }
    return null;
  }
}

const mapStateToProps = (state: types.AppState) => ({
  cjevent: state.affiliateData.cjevent,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setCJEvent(cjevent: string) {
    dispatch(affiliateDataActions.setCJEvent({ cjevent }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CJEventComponent);
