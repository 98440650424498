import { gql } from '@apollo/client';

export const referralsQuery = gql`
  query ECOMMERCE_referrals {
    currentUser {
      id
      ...referralsFragment
    }
  }

  fragment referralsFragment on User {
    referrals {
      referralProgramName
      referralCode
      numConfirmedReferrals
      numPendingReferrals
      unlockedRewardTypes
      codeSuspended
      referralMessage
      referralProgramTitle
      invitedReferees {
        firstName
        profilePicture {
          nearestResized(height: 40, width: 40)
        }
      }
      give1MonthGet1DollarPerMonthDetails {
        totalDollarsEarned
        numActiveReferrals
      }
    }
  }
`;
