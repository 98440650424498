import React, { useCallback, useState } from 'react';
import Button from '../../../../components/Button';
import { logInternalError } from '../../../../lib/errors';
import {
  RescuePlacementModel,
  rescuePlacementMainQuery,
  RedirectToRescuePlacementHome,
  transferringOwnershipTitle,
  AccountInformation,
} from '../rescuePlacementUtils';
import { gqlTypes } from '../../../../types';
import { gql, useMutation } from '@apollo/client';
import PetView from '../PetView/PetView';
import GoBackLink from '../components/GoBackLink/GoBackLink';
import styles from './PetTemporaryPlacement.module.scss';

const rescueTransferPetTemporarilyMutation = gql`
  mutation ECOMMERCE_rescueTransferPetTemporarily($input: RescueTransferPetTemporarilyInput!) {
    rescueTransferPetTemporarily(input: $input) {
      accountCreated
      newAccountTemporaryPassword
    }
  }
`;

const firstNameLastNameRegex = /([^ ]*)\s*(.*)/;

function TemporaryPlacementSuccess({
  data,
  pet,
  email,
}: {
  data: gqlTypes.ECOMMERCE_rescueTransferPetTemporarily;
  pet: gqlTypes.petDetails;
  email: string;
}) {
  if (!data.rescueTransferPetTemporarily) {
    return null;
  }

  return (
    <div>
      <h2>Success!</h2>
      <p>
        Permission to access {pet.name} was granted to <strong>{email}</strong>.
      </p>
      {data && data.rescueTransferPetTemporarily && <AccountInformation data={data.rescueTransferPetTemporarily} />}
    </div>
  );
}

function TransferView({ pet }: { pet: gqlTypes.petDetails }) {
  const [transferPetTemporarily, { loading, error, data }] = useMutation<
    gqlTypes.ECOMMERCE_rescueTransferPetTemporarily,
    gqlTypes.ECOMMERCE_rescueTransferPetTemporarilyVariables
  >(rescueTransferPetTemporarilyMutation, {
    refetchQueries: [{ query: rescuePlacementMainQuery }],
  });

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const onSubmit = useCallback(() => {
    if (loading) {
      return;
    }

    const nameMatch = name.match(firstNameLastNameRegex);
    const firstName = (nameMatch && nameMatch[1]) || '';
    const lastName = (nameMatch && nameMatch[2]) || null;
    transferPetTemporarily({
      variables: {
        input: {
          email,
          firstName,
          lastName,
          petId: pet.id,
        },
      },
    }).catch((err) => {
      logInternalError(err);
    });
  }, [email, loading, name, pet.id, transferPetTemporarily]);

  if (data) {
    return <TemporaryPlacementSuccess data={data} pet={pet} email={email} />;
  }

  return (
    <div className={styles.transferView}>
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
        placeholder="Email"
        data-hj-whitelist
      />
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        placeholder="Name"
        data-hj-whitelist
      />
      <Button onClick={onSubmit}>TRANSFER TO A NEW FOSTER PARENT</Button>
      {error && <div className={styles.textDanger}>{error.message}</div>}
    </div>
  );
}

export default function PetTemporaryPlacement({ model, petId }: { model: RescuePlacementModel; petId: string }) {
  const pet = model.getPetById(petId);

  if (!pet) {
    return <RedirectToRescuePlacementHome />;
  }

  return (
    <div className={styles.main}>
      <GoBackLink />
      <PetView pet={pet} title={transferringOwnershipTitle(pet)} />
      <p>Enter the information for the foster where {pet.name} is being placed.</p>
      <TransferView pet={pet} />
    </div>
  );
}
