import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckoutContext from '../lib/CheckoutContext';
import * as types from '../types';

export default function useShippingOptions() {
  const dispatch = useDispatch();

  const allShippingOptions = useSelector((state: types.AppState) => state.config.shippingOptions);
  const { checkoutActions, checkoutState } = useContext(CheckoutContext);

  const availableShippingOptions = useMemo(
    () =>
      allShippingOptions.filter((so) => {
        if (so.inPersonEventOnly) {
          return false;
        }

        return true;
      }),
    [allShippingOptions],
  );

  // If the saved shipping option is no longer available, clear it.
  useEffect(() => {
    if (!checkoutState.shippingCode) {
      return;
    }

    const hasInvalidShippingCode = !availableShippingOptions.map((so) => so.code).includes(checkoutState.shippingCode);
    if (hasInvalidShippingCode) {
      dispatch(checkoutActions.setShippingCode({ shippingCode: null }));
    }
  });

  return availableShippingOptions;
}
