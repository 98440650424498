import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCartMode } from '../lib/cartModes';
import { getProductsBySku } from '../lib/product';
import * as types from '../types';

/**
 * Number of products in the cart to display in the app bar shopping bag icon. Considers physical products
 * only.
 */
export default function useShoppingCartBadgeItemCount() {
  const { cartSelector } = useCartMode();
  const cart = useSelector(cartSelector);
  const products = useSelector((state: types.AppState) => state.config.products);
  const productsBySku = useMemo(() => getProductsBySku(products), [products]);

  return useMemo(() => {
    return Object.values(cart.cartItems).reduce((sum, cartItem) => {
      const product = productsBySku.get(cartItem.lineItem.sku);
      if (product && product.category !== types.ProductCategory.SUBSCRIPTION) {
        return sum + cartItem.quantity;
      }

      return sum;
    }, 0);
  }, [cart.cartItems, productsBySku]);
}
