import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as types from '../types';

export const DEFAULT_SHIPPING_OPTION: types.ShippingCode = 'so-free';

interface SetCustomerName {
  firstName: string;
  lastName: string | null;
}

interface SetShippingAddress {
  shippingAddress: types.Address | undefined;
}

interface SetShippingCode {
  shippingCode: types.ShippingCode;
}

interface SetBillingInfo {
  billingInfo: types.BillingInfo;
}

interface SetAddressValidationStatus {
  status?: types.AddressValidationStatus;
}

export const checkoutInitialState: types.CheckoutState = {
  shippingCode: DEFAULT_SHIPPING_OPTION,
};

/**
 * Factory for a checkout slice. Allows for a reusable slice for multiple checkouts (e.g. store, upgrade,
 * and subscription)
 */
export function createCheckoutSlice(prefix: string) {
  return createSlice({
    name: `${prefix}:checkout`,
    initialState: checkoutInitialState,
    reducers: {
      resetCheckout: () => {
        return checkoutInitialState;
      },
      setCustomerName: (state: types.CheckoutState, { payload }: PayloadAction<SetCustomerName>) => {
        return {
          ...state,
          customerName: {
            firstName: payload.firstName,
            lastName: payload.lastName,
          },
        };
      },
      setShippingAddress: (state: types.CheckoutState, { payload }: PayloadAction<SetShippingAddress>) => {
        return {
          ...state,
          shippingAddress: payload.shippingAddress,
        };
      },
      setAddressValidationStatus: (
        state: types.CheckoutState,
        { payload }: PayloadAction<SetAddressValidationStatus>,
      ) => {
        return {
          ...state,
          addressValidationStatus: payload.status,
        };
      },
      setShippingCode: (state: types.CheckoutState, { payload }: PayloadAction<SetShippingCode>) => {
        return {
          ...state,
          shippingCode: payload.shippingCode,
        };
      },
      setBillingInfo: (state: types.CheckoutState, { payload }: PayloadAction<SetBillingInfo>) => {
        return {
          ...state,
          unlinkBillingInfo: false,
          billingToken: undefined, // Reset any billing tokens now that we've got the billing info
          billingInfo: payload.billingInfo,
        };
      },
      unlinkBillingInfo: (state: types.CheckoutState) => {
        return {
          ...state,
          unlinkBillingInfo: true,
          billingToken: undefined,
          billingInfo: undefined,
        };
      },
    },
  });
}
