import React, { useState, useMemo, useEffect } from 'react';
import usStates from '../../lib/usStates';
import Dropdown from '../Dropdown';
import styles from './USStateDropdown.module.scss';
import { isMobileDevice } from '../../lib/util/isMobileDevice';
import { ReactComponent as DropdownIndicator } from '../../assets/images/icons/chevron_icon.svg';
import classNames from 'classnames';

interface USStateSelectorProps {
  state: string | undefined;
  onChange: (newState: string) => void;
}

export default function USStateSelector({ state, onChange }: USStateSelectorProps) {
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const options = useMemo(
    () =>
      usStates.map((item) => ({
        value: item.abbreviation,
        label: item.name,
      })),
    [],
  );

  const selectedState = useMemo(() => {
    if (!state) {
      return;
    }

    return options.find(({ value }) => value === state);
  }, [options, state]);

  if (isMobile) {
    return (
      <div className={styles.container}>
        <select
          id="state"
          className={classNames(styles.selectContainer, { [styles.valid]: state !== '' })}
          value={state || ''}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="" disabled>
            State
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <DropdownIndicator />
      </div>
    );
  }

  return (
    <Dropdown
      id="state"
      options={options}
      selectedOption={selectedState}
      placeholder="State"
      onChange={(option) => {
        if (option) {
          onChange(option.value);
        }
      }}
    />
  );
}
