import React, { useState } from 'react';
import * as types from '../../types';

type ImageProps = {
  desiredWidth?: number | string;
  image: types.IImage;
  offset?: number;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const Image = ({ image, desiredWidth, ...extraProps }: ImageProps) => {
  // This state can be overridden if there is an error loading that source
  const [webpEnabled, setWebpEnabled] = useState(true);

  if (image.retinaWidth) {
    extraProps['width'] = image.retinaWidth / 2;
  }
  if (image.retinaHeight) {
    extraProps['height'] = image.retinaHeight / 2;
  }

  if (typeof desiredWidth === 'number' && image.retinaWidth && image.retinaHeight) {
    const aspectRatio = image.retinaWidth / image.retinaHeight;
    extraProps['width'] = desiredWidth;
    extraProps['height'] = Math.trunc(desiredWidth / aspectRatio);
  } else if (typeof desiredWidth === 'string') {
    extraProps['width'] = desiredWidth;
    extraProps['height'] = undefined;
  }

  const imageExtension = image.url.substr(image.url.lastIndexOf('.') + 1);
  let webpUrl = '';
  if (webpEnabled && (imageExtension === 'jpg' || imageExtension === 'png')) {
    webpUrl = image.url.replace(/(?:\.jpg|\.png)/i, '.webp');
  }

  let srcSet = '';
  if (image.descriptors) {
    srcSet = image.descriptors.map((size) => `${image.url.replace(/(\.jpg|\.png)$/, `@${size}$1`)} ${size}`).join(', ');
  }
  if (webpEnabled && srcSet && (imageExtension === 'jpg' || imageExtension === 'png')) {
    webpUrl = srcSet.replace(/(?:\.jpg|\.png)/i, '.webp');
  }

  return (
    <picture
      onError={
        /* Force fallback to the non-webp image in the case where there is an error loading the source which may
         even just be a 404 for the webp image in the case where image url is external to this app */
        () => {
          setWebpEnabled(false);
        }
      }
    >
      {webpUrl !== '' && <source srcSet={webpUrl} type="image/webp" />}
      {srcSet !== '' && <source srcSet={srcSet} type="image/jpeg" />}
      <img src={image.url} alt="" {...extraProps} />
    </picture>
  );
};
