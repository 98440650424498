import {
  purchaseConsumerStoreCartMutation,
  purchaseSeries3UpgradeCartMutation,
  purchaseSubscriptionMutation,
} from '../graphql-operations';
import { purchaseMembershipUpgradeMutation } from '../graphql-operations/MembershipUpgrade.graphql';
import { purchaseMonthlyUpgradeMutation } from '../graphql-operations/MonthlyUpgrade.graphql';
import * as types from '../types';
import { gqlTypes } from '../types';
import { isBundleCartItem, isForModuleCartItem, isGiftCardCartItem } from './cart';
import client from './fi-api/client';

const NO_PURCHASE_RESULTS_ERROR_MESSAGE = 'No data returned from purchase mutation';

export async function executeStoreShopMutation(
  cart: types.Cart,
  checkoutState: types.CheckoutState,
): Promise<gqlTypes.CartPurchaseResult> {
  const cartItems = Object.values(cart.cartItems).flatMap((cartItem): gqlTypes.StoreCartItemInput => {
    if (isForModuleCartItem(cartItem)) {
      throw new Error('Unxpected cart item type');
    }

    // Be explicit about mapping to gqlTypes here to avoid any issues with the GraphQL schema
    // return cartItem; works but doesn't report type errors if cartItem has more properties than expected
    if (isBundleCartItem(cartItem)) {
      return {
        cartItemId: cartItem.cartItemId,
        lineItem: cartItem.lineItem,
        quantity: cartItem.quantity,
        subscriptionLineItem: cartItem.subscriptionLineItem,
        addonLineItems: cartItem.addonLineItems,
        isGift: cartItem.isGift,
      };
    }

    if (isGiftCardCartItem(cartItem)) {
      return {
        cartItemId: cartItem.cartItemId,
        lineItem: cartItem.lineItem,
        quantity: cartItem.quantity,
        giftCardDelivery: cartItem.giftCardDelivery,
      };
    }

    return {
      cartItemId: cartItem.cartItemId,
      lineItem: cartItem.lineItem,
      quantity: cartItem.quantity,
    };
  });

  const { data } = await client.mutate<
    gqlTypes.ECOMMERCE_purchaseConsumerStoreCart,
    gqlTypes.ECOMMERCE_purchaseConsumerStoreCartVariables
  >({
    mutation: purchaseConsumerStoreCartMutation,
    variables: {
      input: {
        cartItems,
        couponCode: cart.couponCode,
        redeemedGiftCardCode: cart.redeemedGiftCardCode,
        referralCode: cart.referralCode,
        shippingCode: checkoutState.shippingCode,
        giftShippingAddress: cart.giftShippingAddress,
      },
    },
  });
  if (!data) {
    throw new Error(NO_PURCHASE_RESULTS_ERROR_MESSAGE);
  }

  return data.purchaseConsumerStoreCart;
}

export async function executeUpgradeShopMutation(
  cart: types.Cart,
  checkoutState: types.CheckoutState,
): Promise<gqlTypes.CartPurchaseResult> {
  const cartItems = Object.values(cart.cartItems).flatMap((cartItem): gqlTypes.ForModuleBundleCartItemInput => {
    if (isForModuleCartItem(cartItem) && isBundleCartItem(cartItem)) {
      // Be explicit about mapping to gqlTypes here to avoid any issues with the GraphQL schema
      // return cartItem; works but doesn't report type errors if cartItem has more properties than expected
      return {
        cartItemId: cartItem.cartItemId,
        forModuleId: cartItem.forModuleId,
        lineItem: cartItem.lineItem,
        quantity: cartItem.quantity,
        subscriptionLineItem: cartItem.subscriptionLineItem,
      };
    }

    throw new Error('Unxpected cart item type');
  });

  const { data } = await client.mutate<
    gqlTypes.ECOMMERCE_purchaseSeries3UpgradeCart,
    gqlTypes.ECOMMERCE_purchaseSeries3UpgradeCartVariables
  >({
    mutation: purchaseSeries3UpgradeCartMutation,
    variables: {
      input: {
        cartItems,
        shippingCode: checkoutState.shippingCode,
        couponCode: cart.couponCode,
      },
    },
  });
  if (!data) {
    throw new Error(NO_PURCHASE_RESULTS_ERROR_MESSAGE);
  }

  return data.purchaseSeries3UpgradeCart;
}

export async function executeSubscriptionShopMutation(cart: types.Cart): Promise<gqlTypes.CartPurchaseResult> {
  const cartItem = Object.values(cart.cartItems)[0];
  if (!cartItem || !isForModuleCartItem(cartItem)) {
    throw new Error('Unxpected cart item type');
  }

  const { data } = await client.mutate<
    gqlTypes.ECOMMERCE_purchaseSubscription,
    gqlTypes.ECOMMERCE_purchaseSubscriptionVariables
  >({
    mutation: purchaseSubscriptionMutation,
    variables: {
      input: {
        sku: cartItem.lineItem.sku,
        moduleId: cartItem.forModuleId,
        redeemGiftCard: cart.redeemedGiftCardCode ? { redemptionCode: cart.redeemedGiftCardCode } : undefined,
        couponCode: cart.couponCode,
      },
    },
  });
  if (!data) {
    throw new Error(NO_PURCHASE_RESULTS_ERROR_MESSAGE);
  }

  return data.purchaseSubscription;
}

export async function executeMembershipUpgradeMutation(cart: types.Cart): Promise<gqlTypes.CartPurchaseResult> {
  // There should be one and only one cart item in the membership upgrade cart
  const cartItem = Object.values(cart.cartItems)[0];
  if (!cartItem || !isForModuleCartItem(cartItem)) {
    throw new Error('Unxpected cart item type');
  }

  const { data } = await client.mutate<
    gqlTypes.ECOMMERCE_purchaseMembershipUpgrade,
    gqlTypes.ECOMMERCE_purchaseMembershipUpgradeVariables
  >({
    mutation: purchaseMembershipUpgradeMutation,
    variables: {
      input: {
        sku: cartItem.lineItem.sku,
        moduleId: cartItem.forModuleId,
      },
    },
  });
  if (!data) {
    throw new Error(NO_PURCHASE_RESULTS_ERROR_MESSAGE);
  }

  return data.purchaseMembershipUpgrade;
}

export async function executeMonthlyUpgradeMutation(cart: types.Cart): Promise<gqlTypes.CartPurchaseResult> {
  // There should be one and only one cart item in the monthly upgrade cart
  const cartItem = Object.values(cart.cartItems)[0];
  if (!cartItem || !isForModuleCartItem(cartItem)) {
    throw new Error('Unxpected cart item type');
  }

  const { data } = await client.mutate<
    gqlTypes.ECOMMERCE_purchaseMonthlyUpgrade,
    gqlTypes.ECOMMERCE_purchaseMonthlyUpgradeVariables
  >({
    mutation: purchaseMonthlyUpgradeMutation,
    variables: {
      input: {
        sku: cartItem.lineItem.sku,
        moduleId: cartItem.forModuleId,
      },
    },
  });
  if (!data) {
    throw new Error(NO_PURCHASE_RESULTS_ERROR_MESSAGE);
  }

  return data.purchaseMonthlyUpgradePromotion;
}
