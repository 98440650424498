import * as types from '../types';
import { Series } from '../types/Series';
import { expectUnreachable } from './util';

export const MARTINGALE_BAND_PRODUCT_ID = 'martingale-band';

export function allSkusForProduct(product: types.IProduct) {
  if (product.category === types.ProductCategory.BAND || product.category === types.ProductCategory.COLLAR_KIT) {
    return product.variants.map((variant) => variant.sku);
  }

  return [product.sku];
}

export function getProductsAndVariantsBySku(
  products: types.IProduct[],
): Map<string, { product: types.IProduct; variant?: types.IVariant }> {
  const result: Map<string, { product: types.IProduct; variant?: types.IVariant }> = new Map();

  for (const product of products) {
    if (product.category === types.ProductCategory.BAND || product.category === types.ProductCategory.COLLAR_KIT) {
      for (const variant of product.variants) {
        result.set(variant.sku, { product, variant });
      }
    } else {
      result.set(product.sku, { product });
    }
  }

  return result;
}

export function getProductsBySku(products: types.IProduct[]) {
  const productsBySku: Map<string, types.IProduct> = new Map();

  for (const product of products) {
    for (const sku of allSkusForProduct(product)) {
      productsBySku.set(sku, product);
    }
  }

  return productsBySku;
}

const bandMakerNames: Record<types.BandMaker, string> = {
  [types.BandMaker.Fi]: 'Fi',
  [types.BandMaker.LandsharkSupply]: 'Landshark Supply',
  [types.BandMaker.MimiGreen]: 'Mimi Green',
  [types.BandMaker.RopeHounds]: 'Rope Hounds',
  [types.BandMaker.StuntPuppy]: 'Stunt Puppy',
  [types.BandMaker.ZeeDog]: 'Zee.Dog',
};

export function nameForBandMaker(maker: types.BandMaker): string {
  return bandMakerNames[maker];
}

const colorOptionNames: Record<types.ColorOption, string> = {
  [types.ColorOption.Yellow]: 'Yellow',
  [types.ColorOption.YellowScribble]: 'Yellow with pattern out',
  [types.ColorOption.Gray]: 'Gray',
  [types.ColorOption.GrayScribble]: 'Gray with pattern out',
  [types.ColorOption.Blue]: 'Blue',
  [types.ColorOption.BlueScribble]: 'Blue with pattern out',
  [types.ColorOption.Pink]: 'Pink',
  [types.ColorOption.PinkScribble]: 'Pink with pattern out',
  [types.ColorOption.Strava]: 'Limited Edition Strava Collar',
  [types.ColorOption.LandsharkSupplyBlackBlue]: 'Black/Blue',
  [types.ColorOption.LandsharkSupplyBlackRed]: 'Black/Red',
  [types.ColorOption.MimiGreenPinkReflective]: 'Pink Reflective',
  [types.ColorOption.MimiGreenRedBiothane]: 'Red Biothane',
  [types.ColorOption.RopeHoundsBubblegumPop]: 'Bubblegum Pop',
  [types.ColorOption.RopeHoundsMountainTop]: 'Mountain Top',
  [types.ColorOption.StuntPuppyFishtailTeal]: 'Fishtail Teal Everyday',
  [types.ColorOption.StuntPuppyFloraFrolic]: 'Flora Frolic Fall Everyday',
  [types.ColorOption.StuntPuppyGoDog]: 'Go Dog Glo Dry',
  [types.ColorOption.StuntPuppyPinesBlue]: 'Pines Blue Everyday',
  [types.ColorOption.StuntPuppyTealBiothane]: 'Teal Biothane Dry',
  [types.ColorOption.ZeeDogPhantom]: 'Phantom',
};

export function colorAndDesignVariantName(color: types.ColorOption): string {
  return colorOptionNames[color];
}

/**
 * For Fi Series 1 and 2 bands, we have a special case for how we want to display the pink color. Also, for Fi band
 * colors, this method is meant to display color without the scribble in vs. out pattern distinction.
 */
export function colorVariantName(color: types.ColorOption, bandSeries: types.BandSeries) {
  if (color === types.ColorOption.Yellow || color === types.ColorOption.YellowScribble) {
    return 'Yellow';
  } else if (color === types.ColorOption.Gray || color === types.ColorOption.GrayScribble) {
    return 'Gray';
  } else if (color === types.ColorOption.Blue || color === types.ColorOption.BlueScribble) {
    return 'Blue';
  } else if (color === types.ColorOption.Pink || color === types.ColorOption.PinkScribble) {
    return bandSeries === types.BandSeries.F1 ? 'Pink Ombre' : 'Pink';
  } else {
    // For all other colors we can fallback to the full name
    return colorAndDesignVariantName(color);
  }
}

export function colorVariantDescription(color: types.ColorOption): string | undefined {
  if (color === types.ColorOption.ZeeDogPhantom) {
    return 'Zee.Dog was founded in Brazil with one major purpose: to Connect Dogs and People.\n\nMade of soft and resistant polyester, these collars are smooth on the fur, fully adjustable and come with a buckle built with a 4-point lock system for added safety.';
  } else if (color === types.ColorOption.StuntPuppyGoDog || color === types.ColorOption.StuntPuppyTealBiothane) {
    return 'Stunt Puppy, based in Minnesota, makes gear for dogs that brave autumn without an argyle sweater, ride shotgun in the pickup, and nudge their running partner awake before the alarm goes off.\n\nWaterproof. Stinkproof. Designed for dogs on the move. Constructed from BioThane®, a virtually indestructible material, it also provides excellent flexibility in cold weather.';
  } else if (
    color === types.ColorOption.StuntPuppyFishtailTeal ||
    color === types.ColorOption.StuntPuppyFloraFrolic ||
    color === types.ColorOption.StuntPuppyPinesBlue
  ) {
    return 'Stunt Puppy, based in Minnesota, makes gear for dogs that brave autumn without an argyle sweater, ride shotgun in the pickup, and nudge their running partner awake before the alarm goes off.\n\nStunt Puppy’s Everyday Collars are made with polyester webbing, anodized aluminum hardware and USA-Made Duraflex® acetal Buckles.';
  } else if (color === types.ColorOption.MimiGreenRedBiothane) {
    return 'Mimi Green, a family-owned business since 2007. They take great pride in handcrafting each collar in New Mexico.\n\nThis waterproof dog collar is made of Biothane®, a water-resistant and stink proof material. They’re built to last through play dates, walks in the rain, and so much more.';
  } else if (color === types.ColorOption.MimiGreenPinkReflective) {
    return 'Mimi Green, a family-owned business since 2007. They take great pride in handcrafting each collar in New Mexico. \n\nKeeping your pup safe is a big job, especially at night. With this Reflective Nylon Dog Collar, your dog will be seen.';
  } else if (color === types.ColorOption.RopeHoundsMountainTop) {
    return 'Handcrafted in Bentonville, Arkansas, Rope Hounds Dog Gear is made using strong, colorful rock climbing rope along with durable hardware - making it perfect for your next adventure! \n\nTheir 1" wide band features reinforced stitching on all stress points and an easy-to-use, quick-release buckle for convenience.';
  } else if (color === types.ColorOption.RopeHoundsBubblegumPop) {
    return 'Handcrafted in Bentonville, Arkansas, Rope Hounds Dog Gear is made using strong, colorful rock climbing rope along with durable hardware - making it perfect for your next adventure! \n\nTheir 1" wide band features reinforced stitching on all stress points and an easy-to-use, quick-release buckle for convenience.';
  } else if (color === types.ColorOption.LandsharkSupplyBlackBlue) {
    return 'Landshark Supply builds high caliber gear for working dogs, sport dogs, and beloved family pets. Made in the USA.\n\nEach Landshark collar is made with an outer layer of mil-spec nylon, and a comfortable, colorful inner liner. An authentic AustriAlpin COBRA® Buckle and all metal hardware ensure long lasting durability and a secure fit for every adventure, job, and competition.';
  } else if (color === types.ColorOption.LandsharkSupplyBlackRed) {
    return 'Landshark Supply builds high caliber gear for working dogs, sport dogs, and beloved family pets. Made in the USA.\n\nEach Landshark collar is made with an outer layer of mil-spec nylon, and a comfortable, colorful inner liner. An authentic AustriAlpin COBRA® Buckle and all metal hardware ensure long lasting durability and a secure fit for every adventure, job, and competition.';
  } else {
    return '';
  }
}

export function sizeName(size: types.SizeOption) {
  if (size === types.SizeOption.XSmall) {
    return 'XS';
  } else if (size === types.SizeOption.Small) {
    return 'S';
  } else if (size === types.SizeOption.Medium) {
    return 'M';
  } else if (size === types.SizeOption.Large) {
    return 'L';
  } else if (size === types.SizeOption.XLarge) {
    return 'XL';
  } else {
    expectUnreachable(size);
    return size;
  }
}

export function variantDescriptionForAnalytics(variant: types.IVariant) {
  return `${variant.options.size} ${variant.options.color}`;
}

function partialVariantOptionsMatch(l: Partial<types.IVariantOptions>, r: types.IVariantOptions) {
  return (
    (l.color === undefined || l.color === r.color) &&
    (l.size === undefined || l.size === r.size) &&
    (l.collarType === undefined || l.collarType === r.collarType)
  );
}

export function variantWithOptions(
  variants: types.IVariant[],
  options: Partial<types.IVariantOptions>,
): types.IVariant | undefined {
  return variants.find((v) => partialVariantOptionsMatch(options, v.options));
}

/**
 * @returns Whether two variant.option objects are equal
 */
export function variantOptionsEqual(l: Partial<types.IVariantOptions>, r: Partial<types.IVariantOptions>): boolean {
  return l.color === r.color && l.size === r.size && l.collarType === r.collarType;
}

export function exactVariantForOptions(
  variants: types.IVariant[],
  options: Partial<types.IVariantOptions>,
): types.IVariant | undefined {
  return variants.filter((v) => variantOptionsEqual(v.options, options))[0];
}

export function bandSeriesToCollarSeries(bandSeries: types.BandSeries) {
  if (bandSeries === types.BandSeries.F1) {
    return Series.Series2;
  } else if (bandSeries === types.BandSeries.F3) {
    return Series.Series3;
  } else {
    expectUnreachable(bandSeries);
    throw new Error(`Unknown band series: ${bandSeries}`);
  }
}
