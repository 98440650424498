import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COUPON_COOKIE_NAME, generateID, removeCookie } from '../lib/util';
import * as types from '../types';
import { baseCartReducers, giftCardRedemptionCartReducers } from './baseCart';

interface SetSubscriptionCartItem {
  sku: string;
  moduleId: string;
}

export const cartInitialState: types.SubscriptionCart = {
  cartItems: {},
};

/**
 * Reducers that only apply to subscription-only carts that can apply coupons
 *
 */
const couponSubscriptionCartReducers = {
  addCoupon<T extends types.SubscriptionCart>(cart: T, { payload: couponCode }: PayloadAction<string>) {
    // Coupon and/or referral code and/or gift card cannot be stacked
    // Referral code shouldn't be set on subscription carts, but just in case
    const { referralCode, redeemedGiftCardCode, ...cartWithoutReferralCodeOrGiftCard } = cart;

    return {
      ...cartWithoutReferralCodeOrGiftCard,
      couponCode,
    };
  },
  removeCoupon<T extends types.SubscriptionCart>(cart: T) {
    removeCookie(COUPON_COOKIE_NAME);

    return {
      ...cart,
      couponCode: undefined,
    };
  },
};

const name = 'subscriptionCart' as const;
export const { actions: cartActions, reducer: cartReducer } = createSlice({
  name,
  initialState: cartInitialState,
  reducers: {
    ...baseCartReducers,
    ...couponSubscriptionCartReducers,
    ...giftCardRedemptionCartReducers,
    setCartItem(cart: types.SubscriptionCart, { payload }: PayloadAction<SetSubscriptionCartItem>) {
      const cartItem: types.ForModuleCartItem = {
        cartItemId: generateID(),
        lineItem: {
          lineItemId: generateID(),
          sku: payload.sku,
        },
        forModuleId: payload.moduleId,
        quantity: 1,
      };

      return {
        ...cart,
        cartItems: {
          [cartItem.cartItemId]: cartItem,
        },
      };
    },

    resetCart() {
      return { ...cartInitialState };
    },
  },
});
