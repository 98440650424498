import React, { useEffect, useState } from 'react';
import {
  getNanoReferralPetAgeCookie,
  getNanoReferralPetBreedCookie,
  getNanoReferralPetNameCookie,
  getNanoReferralPetGenderCookie,
  getNanoReferralPetWeightCookie,
  setNanoReferralPetAgeCookie,
  setNanoReferralPetBreedCookie,
  setNanoReferralPetNameCookie,
  setNanoReferralPetGenderCookie,
  setNanoReferralPetWeightCookie,
} from '../../lib/util/nanoReferralPetNameCookie';
import styles from './NanoBanner.module.scss';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { StoreCart } from '../../types';
import { inCartS3CollarCount } from '../ItemsInCart/CouponLineItemV2';

export function showNanoVariant() {
  const petName = getNanoVariantPetName();
  const petAge = getNanoVariantPetAgeMonths();
  const petGender = getNanoVariantPetGender();
  const petWeight = getNanoVariantPetWeight();
  const petBreed = getNanoVariantPetBreed();

  if (!!petName && petAge >= 0 && !!petGender && !!petWeight && !!petBreed) {
    setNanoReferralPetNameCookie(petName);
    setNanoReferralPetAgeCookie(petAge.toString());
    setNanoReferralPetGenderCookie(petGender);
    setNanoReferralPetWeightCookie(petWeight);
    setNanoReferralPetBreedCookie(petBreed);
    return true;
  }

  return false;
}

export function getNanoVariantPetName() {
  let petName = new URLSearchParams(window.location.search).get('pet_name');
  if (!petName || petName.length > 10) {
    petName = 'your dog';
  }
  return petName || getNanoReferralPetNameCookie();
}

export function getNanoVariantPetAgeMonths() {
  return Number(new URLSearchParams(window.location.search).get('pet_age') || getNanoReferralPetAgeCookie());
}

export function getNanoVariantPetGender() {
  return new URLSearchParams(window.location.search).get('pet_gender') || getNanoReferralPetGenderCookie();
}

export function getNanoVariantPetBreed() {
  return new URLSearchParams(window.location.search).get('pet_breed') || getNanoReferralPetBreedCookie();
}

export function getNanoVariantPetWeight() {
  return new URLSearchParams(window.location.search).get('pet_weight') || getNanoReferralPetWeightCookie();
}

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const now = DateTime.local();
    let targetTime = now.set({ hour: 9, minute: 0, second: 0, millisecond: 0 });

    // If the current time is already past 9:00 AM today, set the target time to 9:00 AM tomorrow
    if (now >= targetTime) {
      targetTime = targetTime.plus({ days: 1 });
    }

    return targetTime.diff(now, ['hours', 'minutes', 'seconds']);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const hours = Math.floor(timeLeft.hours).toString().padStart(2, '0');
  const minutes = Math.floor(timeLeft.minutes).toString().padStart(2, '0');
  const seconds = Math.floor(timeLeft.seconds).toString().padStart(2, '0');

  return (
    <div className={styles.countdown}>
      <div className={styles.expiresSoonDesktop}>
        <div>EXPIRES</div>
        <div>SOON</div>
      </div>
      <div className={styles.expiresSoonMobile}>
        <div>EXPIRES SOON</div>
      </div>
      <div className={styles.countdownClock}>
        <div className={styles.hours}>{hours}</div>
        <div className={styles.timeSeparator}>:</div>
        <div className={styles.minutes}>{minutes}</div>
        <div className={styles.timeSeparator}>:</div>
        <div className={styles.seconds}>{seconds}</div>
      </div>
    </div>
  );
};

export default function NanoBanner({
  cart,
  onClick,
  promoCodeApplied,
}: {
  cart: StoreCart;
  onClick?: () => void;
  promoCodeApplied: boolean;
}) {
  const { pathname } = useLocation();
  const cartItems = Object.values(cart.cartItems);
  useEffect(() => {
    const totalCollarCount = inCartS3CollarCount(cartItems);
    const monthToMonthCollarCount = inCartS3CollarCount(cartItems, 'sub-monthly-1m-001');
    // From the cart, we want to auto-apply the promo code by simulating a click event on the banner.
    if (
      !promoCodeApplied &&
      pathname.startsWith('/bag') &&
      totalCollarCount > 0 &&
      totalCollarCount - monthToMonthCollarCount > 0
    ) {
      onClick && onClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <button className={styles.nanoBanner} onClick={onClick}>
      <div className={styles.textContainer}>
        {!promoCodeApplied && <div className={styles.image}>🐶</div>}
        <div className={styles.titleContainer}>
          <div className={styles.title}>{promoCodeApplied ? 'Offer applied to cart!' : 'Exclusive Fi Nano Offer'}</div>
          {!promoCodeApplied && <div className={styles.subtitle}>$20 off with code: NANO20</div>}
        </div>
      </div>
      <CountdownTimer />
    </button>
  );
}
