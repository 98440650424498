import { createAction, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import cache from '../lib/fi-api/cache';
import * as types from '../types';

export interface CreateSession {
  session: types.Session;
}

export const USER_EMAIL_ADDRESS_VARIABLE_NAME = `fi__userEmailAddress`;

const name = 'session' as const;
const removeSession = createAction<{}>('session/removeSession');

const { actions, reducer } = createSlice({
  name,
  initialState: null as types.Session | null,
  reducers: {
    createSession(state: types.Session | null, { payload }: PayloadAction<CreateSession>) {
      (window as any)[USER_EMAIL_ADDRESS_VARIABLE_NAME] = payload.session.email || undefined;
      return payload.session;
    },

    /**
     * Mark the user as having set their password.
     */
    didSetPassword(state: types.Session | null, { payload }: PayloadAction<{}>) {
      if (!state) {
        return null;
      }
      const { noPassword, ...newState } = state;
      return newState;
    },
  },

  extraReducers: {
    [removeSession.type](state, { payload }: PayloadAction<{}>) {
      return null;
    },
  },
});

export const sessionActions = {
  ...actions,

  destroySession: () => (dispatch: Dispatch) => {
    Cookies.remove('fi_session_id');
    cache.reset();
    (window as any)[USER_EMAIL_ADDRESS_VARIABLE_NAME] = undefined;
    dispatch(removeSession({}));
  },
};

export const sessionReducer = reducer;
export const sessionInitialState = { [name]: null as types.Session | null };
