import * as types from '../../types';
import { skusForCartItem } from '../cart';
import { getProductsBySku } from '../product';
import store from '../reduxStore';
import { centsToDollarsNumber } from '../util';

type RenewalMonths = number;

export interface CartInformation {
  accessoriesTotal: number;
  accessoriesQty: number;
  revenueIncludingFreeTrials: number;
  series3TotalByRenewalMonths: Map<RenewalMonths, number>;
  series3QtyByRenewalMonths: Map<RenewalMonths, number>;
  shipping?: number;
  tax?: number;
}

export function getCartInformation(cartItems: types.CartItem[], cartPricing: types.CartPricing): CartInformation {
  let accessoriesQty = 0;
  let accessoriesTotal = 0;
  let revenueIncludingFreeTrials = centsToDollarsNumber(cartPricing.subtotalInCents);

  const series3QtyByRenewalMonths = new Map<RenewalMonths, number>();
  const series3TotalByRenewalMonths = new Map<RenewalMonths, number>();

  const products = store.getState().config.products;
  const productsBySku = getProductsBySku(products);

  const accessoriesAndSuppsCategories = new Set([
    types.ProductCategory.BAND,
    types.ProductCategory.CHARGING_BASE,
    types.ProductCategory.MICROCHIP,
    types.ProductCategory.SUPPLEMENT,
  ]);

  for (const cartItem of cartItems) {
    const skus = skusForCartItem(cartItem);
    const qty = cartItem.quantity;

    for (const sku of skus) {
      const product = productsBySku.get(sku);
      if (!product) {
        continue;
      }

      if (accessoriesAndSuppsCategories.has(product.category)) {
        accessoriesQty += qty;
        accessoriesTotal += centsToDollarsNumber(product.priceInCents) * qty;
      }

      if (types.isModuleSubscriptionProduct(product)) {
        const productTotal = centsToDollarsNumber(product.priceInCents) * qty;

        // If the subscription included a free trial, it wouldn't have been included in the cart pricing
        // since they don't owe us anything for that subscription upon checkout. But some analytics want
        // this value included so we'll manually add it separately.
        if (product.freeTrial) {
          revenueIncludingFreeTrials += productTotal;
        }

        // S3 month
        if (product.canBePurchasedForProductIds.includes(types.series3CollarId)) {
          const renewalMonths = product.renewalMonths;
          const currentTotal = series3TotalByRenewalMonths.get(renewalMonths) || 0;
          series3TotalByRenewalMonths.set(renewalMonths, currentTotal + productTotal);

          const currentQty = series3QtyByRenewalMonths.get(renewalMonths) || 0;
          series3QtyByRenewalMonths.set(renewalMonths, currentQty + qty);
        }
      }
    }
  }

  const shippingInCents = cartPricing.shippingInCents;
  const taxInCents = cartPricing.taxInCents;

  const shipping = shippingInCents !== undefined ? centsToDollarsNumber(shippingInCents) : undefined;
  const tax = taxInCents !== undefined ? centsToDollarsNumber(taxInCents) : undefined;

  return {
    accessoriesQty,
    accessoriesTotal,
    revenueIncludingFreeTrials,
    series3QtyByRenewalMonths,
    series3TotalByRenewalMonths,
    shipping,
    tax,
  };
}

// Variables for Google Tag Manager.
const ACCESSORIES_QTY_VARIABLE_NAME = `fi__accessoriesQtyForConversionTracking`;
const ACCESSORIES_TOTAL_VARIABLE_NAME = `fi__accessoriesTotalForConversionTracking`;
const ORDER_EMAIL_VARIABLE_NAME = `fi__orderEmailForConversionTracking`;
const ORDER_ID_VARIABLE_NAME = `fi__orderIdForConversionTracking`;
const ORDER_TOTAL_VARIABLE_NAME = `fi__orderTotalForConversionTracking`;
const S3_QTY_VARIABLE_PREFIX = `fi__s3QtyForConversionTracking:`;
const S3_SUB_TOTAL_VARIABLE_PREFIX = `fi__s3SubTotalForConversionTracking:`;

export function setGlobalVariablesForConversionTracking(
  orderId: string,
  cartItems: types.CartItem[],
  cartPricing: types.CartPricing,
  email?: string,
) {
  const cartInformation = getCartInformation(cartItems, cartPricing);
  (window as any)[ACCESSORIES_QTY_VARIABLE_NAME] = cartInformation.accessoriesQty;
  (window as any)[ACCESSORIES_TOTAL_VARIABLE_NAME] = cartInformation.accessoriesTotal;
  (window as any)[ORDER_EMAIL_VARIABLE_NAME] = email;
  (window as any)[ORDER_ID_VARIABLE_NAME] = orderId;
  (window as any)[ORDER_TOTAL_VARIABLE_NAME] = cartInformation.revenueIncludingFreeTrials;

  for (const [renewalMonths, total] of cartInformation.series3TotalByRenewalMonths.entries()) {
    (window as any)[`${S3_SUB_TOTAL_VARIABLE_PREFIX}${renewalMonths}m`] = total;
  }

  for (const [renewalMonths, qty] of cartInformation.series3QtyByRenewalMonths.entries()) {
    (window as any)[`${S3_QTY_VARIABLE_PREFIX}${renewalMonths}m`] = qty;
  }
}

export function clearGlobalVariablesForConversionTracking() {
  (window as any)[ACCESSORIES_QTY_VARIABLE_NAME] = undefined;
  (window as any)[ACCESSORIES_TOTAL_VARIABLE_NAME] = undefined;
  (window as any)[ORDER_EMAIL_VARIABLE_NAME] = undefined;
  (window as any)[ORDER_ID_VARIABLE_NAME] = undefined;
  (window as any)[ORDER_TOTAL_VARIABLE_NAME] = undefined;

  const products = store.getState().config.products;
  const subscriptionProducts = products.filter(types.isModuleSubscriptionProduct);
  const renewalMonths = new Set(subscriptionProducts.map((product) => product.renewalMonths));
  for (const month of renewalMonths) {
    (window as any)[`${S3_SUB_TOTAL_VARIABLE_PREFIX}${month}m`] = undefined;
    (window as any)[`${S3_QTY_VARIABLE_PREFIX}${month}m`] = undefined;
  }
}
