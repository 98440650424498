import React from 'react';
import { Image } from '../Image';
import { centsToDollars } from '../../lib/util';
import styles from '../../styles/lineItem.module.scss';
import { CartItemID, GiftCardDelivery } from '../../types';
import { GIFT_CARD_IMAGES } from '../../views/GiftCard/GiftCardImages.constant';
import RemoveLineItemButton from '../RemoveLineItemButton';
import GiftCardInformation from './GiftCardInformation';

interface GiftCardLineItemProps {
  id: CartItemID;
  productName: string;
  productPriceInCents: number;
  giftCardDelivery: GiftCardDelivery;
  removeCartItem?: () => void;
}

export default function GiftCardCartItem({
  productName,
  productPriceInCents,
  giftCardDelivery,
  removeCartItem,
}: GiftCardLineItemProps) {
  return (
    <div className={styles.lineItem}>
      {removeCartItem && (
        <div className={styles.removeButtonRow}>
          <RemoveLineItemButton onClick={removeCartItem} />
        </div>
      )}
      <div className={styles.itemRow}>
        <div className={styles.lineItemImage}>
          <Image image={GIFT_CARD_IMAGES[0]} desiredWidth={100} />
        </div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            <div className={styles.lineItemPriceRow}>
              <div className={styles.lineItemDetails}>
                <div className={styles.productName}>Fi gift card</div>
                <GiftCardInformation delivery={giftCardDelivery} />
              </div>

              <div className={styles.lineItemPrice}>${centsToDollars(productPriceInCents)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
