import * as React from 'react';
import styles from './RemoveLineItemButton.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/close-button.svg';

interface RemoveButtonProps {
  onClick(): void;
}

export default function RemoveLineItemButton({ onClick }: RemoveButtonProps) {
  return (
    <div
      className={styles.removeButton}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <CloseIcon />
    </div>
  );
}
