export interface SizingGuideDeterminationInterface {
  [breed: string]: {
    average_weight: number;
    weight_stddev: number;
  };
}

export const SizingGuideDetermination: SizingGuideDeterminationInterface = {
  'German Shorthaired Pointer': {
    average_weight: 57.48218208052641,
    weight_stddev: 13.086405337909008,
  },
  'English Cocker Spaniel': {
    average_weight: 30.8932418357766,
    weight_stddev: 7.359050190086095,
  },
  Labrabull: {
    average_weight: 65.13620096459427,
    weight_stddev: 19.865235080733452,
  },
  'Bearded Collie': {
    average_weight: 49.673561589429056,
    weight_stddev: 16.072689302220315,
  },
  'Japanese Spitz': {
    average_weight: 22.814748639405227,
    weight_stddev: 18.193422389499244,
  },
  'Jack Chi': {
    average_weight: 15.739825985269372,
    weight_stddev: 5.369439094489633,
  },
  Shihpoo: {
    average_weight: 14.817027279295061,
    weight_stddev: 5.011951340394829,
  },
  'Caucasian Ovcharka': {
    average_weight: 140.1842287915983,
    weight_stddev: 41.31877841961538,
  },
  Chipit: {
    average_weight: 30.635615727581797,
    weight_stddev: 7.619439832977598,
  },
  Vizsla: {
    average_weight: 52.02908800952134,
    weight_stddev: 12.5085199076681,
  },
  'Siberian Husky': {
    average_weight: 57.203857383951906,
    weight_stddev: 14.92842117339653,
  },
  'Treeing Walker Coonhound': {
    average_weight: 60.65009836861913,
    weight_stddev: 16.83275531583398,
  },
  'Doberman Pinscher': {
    average_weight: 77.32185512176848,
    weight_stddev: 18.742091623341665,
  },
  'Bullboxer Pit': {
    average_weight: 63.146210503766305,
    weight_stddev: 15.162496436663034,
  },
  'Dogue de Bordeaux': {
    average_weight: 109.39761609724029,
    weight_stddev: 28.64626368553396,
  },
  'Blue Lacy': {
    average_weight: 48.49267409692577,
    weight_stddev: 13.673952606442654,
  },
  'Doberman Shepherd': {
    average_weight: 66.56998673444312,
    weight_stddev: 18.374014926919646,
  },
  Bordoodle: {
    average_weight: 46.12881796775023,
    weight_stddev: 13.036823078559731,
  },
  'Beagle-Harrier': {
    average_weight: 42.13631710408191,
    weight_stddev: 15.469836769207943,
  },
  Maltese: {
    average_weight: 13.326501967456373,
    weight_stddev: 5.035273098004142,
  },
  Leonberger: {
    average_weight: 131.9465720570791,
    weight_stddev: 35.07131586468661,
  },
  'Chow Shepherd': {
    average_weight: 52.506950576418525,
    weight_stddev: 15.35729877355849,
  },
  'Sharberian Husky': {
    average_weight: 57.21559985617283,
    weight_stddev: 14.909509744189325,
  },
  Maltipoo: {
    average_weight: 14.331142400557974,
    weight_stddev: 4.937790253645709,
  },
  Chusky: {
    average_weight: 53.802300757047625,
    weight_stddev: 18.082163760994735,
  },
  'Border Collie Pyrenees': {
    average_weight: 71.74383932703141,
    weight_stddev: 16.33619282141882,
  },
  Other: {
    average_weight: 41.202389009324456,
    weight_stddev: 24.925035711937777,
  },
  'Cocker Spaniel': {
    average_weight: 28.020054418157123,
    weight_stddev: 9.744402380409756,
  },
  'American Staffordshire Terrier': {
    average_weight: 59.8318463305988,
    weight_stddev: 15.548982961849797,
  },
  'Shetland Sheepdog': {
    average_weight: 27.760716657837182,
    weight_stddev: 11.328734556622113,
  },
  McNab: {
    average_weight: 47.0339714278853,
    weight_stddev: 11.410448434114125,
  },
  Dachshund: {
    average_weight: 18.14269441770213,
    weight_stddev: 8.595266994922522,
  },
  Puggle: {
    average_weight: 29.43549817588954,
    weight_stddev: 8.527314352656651,
  },
  'Miniature Bull Terrier': {
    average_weight: 36.36981768386548,
    weight_stddev: 16.457547733125573,
  },
  'Coton De Tulear': {
    average_weight: 15.03923602193175,
    weight_stddev: 5.5112990989380135,
  },
  'German Malinois': {
    average_weight: 68.9979182086348,
    weight_stddev: 15.271414148523073,
  },
  Huskita: {
    average_weight: 63.37243219964298,
    weight_stddev: 15.78275693230054,
  },
  Yorkipoo: {
    average_weight: 13.77004625984257,
    weight_stddev: 5.4042923443327595,
  },
  'Australian Kelpie': {
    average_weight: 44.78856736920098,
    weight_stddev: 11.866202749992489,
  },
  'Small Munsterlander': {
    average_weight: 46.89809705596956,
    weight_stddev: 11.349901400879151,
  },
  'Border Terrier': {
    average_weight: 24.136678140563546,
    weight_stddev: 11.556210005020379,
  },
  'Saint Bernard': {
    average_weight: 118.07010326464845,
    weight_stddev: 34.71367881547596,
  },
  'Dogo Argentino': {
    average_weight: 91.55501029049948,
    weight_stddev: 23.669582949290078,
  },
  Chiweenie: {
    average_weight: 14.885522927668562,
    weight_stddev: 4.735876099866351,
  },
  Pug: {
    average_weight: 22.66281355908401,
    weight_stddev: 9.209714381825139,
  },
  'Nova Scotia Duck Tolling Retriever': {
    average_weight: 44.760415820871536,
    weight_stddev: 12.35392365647926,
  },
  'German Longhaired Pointer': {
    average_weight: 64.67481713763843,
    weight_stddev: 20.937915534382082,
  },
  Pitsky: {
    average_weight: 59.60219253948827,
    weight_stddev: 13.178979511350107,
  },
  'Tibetan Mastiff': {
    average_weight: 111.53620473884345,
    weight_stddev: 30.94717554686512,
  },
  'Cattle Collie Dog': {
    average_weight: 43.25906602209557,
    weight_stddev: 15.69674055674382,
  },
  'Seppala Siberian Sleddog': {
    average_weight: 48.445169047317215,
    weight_stddev: 8.653469978623269,
  },
  'German Wirehaired Pointer': {
    average_weight: 61.97689647700874,
    weight_stddev: 13.653203427939753,
  },
  'American Bulldog': {
    average_weight: 72.53612916463759,
    weight_stddev: 19.885228457472433,
  },
  'Anatolian Shepherd Dog': {
    average_weight: 97.98255104871107,
    weight_stddev: 30.69518433092394,
  },
  Schnoodle: {
    average_weight: 25.499090816907913,
    weight_stddev: 16.856278249647634,
  },
  Bullmastiff: {
    average_weight: 107.51842557675069,
    weight_stddev: 26.011503782362873,
  },
  'American Village Dog': {
    average_weight: 41.847522619591516,
    weight_stddev: 15.010114023557806,
  },
  'American Bully': {
    average_weight: 66.56120541145016,
    weight_stddev: 19.801116810930377,
  },
  'Spinone Italiano': {
    average_weight: 78.43838924280185,
    weight_stddev: 17.48048188757723,
  },
  'Ibizan Hound': {
    average_weight: 51.48897029912816,
    weight_stddev: 11.675052063697274,
  },
  'American Mastiff': {
    average_weight: 98.38571944496783,
    weight_stddev: 31.391745912900873,
  },
  Shichon: {
    average_weight: 15.233302121770159,
    weight_stddev: 5.253530801277324,
  },
  Mastiff: {
    average_weight: 111.22880024961664,
    weight_stddev: 35.460843548434696,
  },
  'Staffordshire Bull Terrier': {
    average_weight: 54.745395488561435,
    weight_stddev: 17.21120891179718,
  },
  'Karelian Bear Dog': {
    average_weight: 59.81302700678676,
    weight_stddev: 14.928628721272922,
  },
  Wolfdog: {
    average_weight: 78.04098403152463,
    weight_stddev: 29.863768106898053,
  },
  'Border-Aussie': {
    average_weight: 49.25775272778322,
    weight_stddev: 15.024530519324744,
  },
  'Affen Terrier': {
    average_weight: 27.082394659396645,
    weight_stddev: 21.170861884858695,
  },
  'White German Shepherd': {
    average_weight: 75.62558845149731,
    weight_stddev: 23.75921745393938,
  },
  Labstaff: {
    average_weight: 62.078487713462714,
    weight_stddev: 22.666372505335637,
  },
  'Finnish Lapphund': {
    average_weight: 41.56959325007067,
    weight_stddev: 10.38657239233677,
  },
  'Chinese Shar-Pei': {
    average_weight: 51.67098364536937,
    weight_stddev: 14.744664062575195,
  },
  Weimaraner: {
    average_weight: 71.69110713591304,
    weight_stddev: 15.958755953796368,
  },
  Pointer: {
    average_weight: 54.41139810622641,
    weight_stddev: 13.812621384188974,
  },
  'Shih Tzu': {
    average_weight: 15.661977845740044,
    weight_stddev: 6.227403502826454,
  },
  'Kangal Dog': {
    average_weight: 120.67347212607908,
    weight_stddev: 28.83168266234429,
  },
  'Greater Swiss Mountain Dog': {
    average_weight: 106.58649711721672,
    weight_stddev: 28.221746628292628,
  },
  'American Boston Bull Terrier': {
    average_weight: 30.4706676098902,
    weight_stddev: 14.090915505232223,
  },
  'Corgi Cattle Dog': {
    average_weight: 32.70543197822276,
    weight_stddev: 12.214937321388396,
  },
  'Pitt Plott': {
    average_weight: 57.2142496470424,
    weight_stddev: 14.888218047731455,
  },
  'Belgian Sheepdog': {
    average_weight: 57.17598819132324,
    weight_stddev: 14.099842214802507,
  },
  Harrier: {
    average_weight: 51.18294396511157,
    weight_stddev: 11.757566627771428,
  },
  'Redbone Coonhound': {
    average_weight: 63.454853494362546,
    weight_stddev: 17.54104716747258,
  },
  'Glen of Imaal Terrier': {
    average_weight: 36.108540527044475,
    weight_stddev: 11.129073071805546,
  },
  'Welsh Terrier': {
    average_weight: 24.604282983315045,
    weight_stddev: 7.227363757480602,
  },
  'American French Bulldog': {
    average_weight: 27.369403361277,
    weight_stddev: 6.964853219929692,
  },
  Beaglier: {
    average_weight: 27.44299365445701,
    weight_stddev: 9.07694878272292,
  },
  'Aussie Siberian': {
    average_weight: 55.151263594571446,
    weight_stddev: 16.76336660128911,
  },
  'East Asian Village Dog': {
    average_weight: 37.02730670160304,
    weight_stddev: 16.203664107155532,
  },
  'German Australian Shepherd': {
    average_weight: 60.38869740371809,
    weight_stddev: 19.388664900651506,
  },
  'Saint Berdoodle': {
    average_weight: 89.4429087753261,
    weight_stddev: 23.336077846300764,
  },
  'Tamaskan Dog': {
    average_weight: 74.33071213797683,
    weight_stddev: 19.163812376755892,
  },
  Rottweiler: {
    average_weight: 89.89849117829081,
    weight_stddev: 26.827902512767118,
  },
  'Plott Hound': {
    average_weight: 59.19178081985211,
    weight_stddev: 16.707181148064727,
  },
  'Maremma Sheepdog': {
    average_weight: 97.98538672431123,
    weight_stddev: 28.090053589936538,
  },
  Aussiedoodle: {
    average_weight: 42.31024952077082,
    weight_stddev: 16.731787029297347,
  },
  'Portuguese Water Dog': {
    average_weight: 52.63848816069853,
    weight_stddev: 10.956654157109947,
  },
  'English Pointer': {
    average_weight: 53.07229295098258,
    weight_stddev: 12.617595321224705,
  },
  'Czechoslovakian Wolfdog': {
    average_weight: 80.72584466203922,
    weight_stddev: 19.992029872004895,
  },
  'Border Beagle': {
    average_weight: 36.739299521011056,
    weight_stddev: 9.567730707765177,
  },
  Aussiedor: {
    average_weight: 60.70447744726913,
    weight_stddev: 13.569025299478737,
  },
  'Arubian Cunucu Dog': {
    average_weight: 46.42766840156467,
    weight_stddev: 11.843162234028638,
  },
  'Gordon Setter': {
    average_weight: 57.59443914646171,
    weight_stddev: 16.374572680457078,
  },
  'Sheltie Shepherd': {
    average_weight: 34.549561678910024,
    weight_stddev: 16.350515682054002,
  },
  Pyredoodle: {
    average_weight: 79.45471571793078,
    weight_stddev: 21.70142374538734,
  },
  Newfoundland: {
    average_weight: 116.31509023608726,
    weight_stddev: 28.95815000936868,
  },
  Bloodhound: {
    average_weight: 90.44235031616753,
    weight_stddev: 23.84914998955687,
  },
  'Dutch Shepherd Dog': {
    average_weight: 64.57453459472357,
    weight_stddev: 15.960761478003112,
  },
  'Chow Chow': {
    average_weight: 52.089526580265236,
    weight_stddev: 14.757305514233346,
  },
  'Olde English Bulldogge': {
    average_weight: 65.53228550005386,
    weight_stddev: 15.043991436798132,
  },
  'Taco Terrier': {
    average_weight: 22.77043992033148,
    weight_stddev: 10.85345391919515,
  },
  Keeshond: {
    average_weight: 39.44354437709573,
    weight_stddev: 8.763263540402836,
  },
  'Gerberian Shepsky': {
    average_weight: 67.29497385303333,
    weight_stddev: 16.27677941989385,
  },
  'Belgian Malinois': {
    average_weight: 64.96858946439404,
    weight_stddev: 15.004060107595778,
  },
  'English Springer Spaniel': {
    average_weight: 48.20761229287921,
    weight_stddev: 12.236082991709077,
  },
  'Flat-Coated Retriever': {
    average_weight: 64.9042507303253,
    weight_stddev: 17.17870793566849,
  },
  'Welsh Springer Spaniel': {
    average_weight: 43.30573184045156,
    weight_stddev: 9.55882912633186,
  },
  'German Pinscher': {
    average_weight: 38.82342912301342,
    weight_stddev: 15.990711673509148,
  },
  'Transylvanian Hound': {
    average_weight: 61.356034354103315,
    weight_stddev: 13.958648492113188,
  },
  'Afghan Hound': {
    average_weight: 53.034987597912064,
    weight_stddev: 20.08043283695313,
  },
  'Perro de Presa Canario': {
    average_weight: 103.69120451645567,
    weight_stddev: 28.24253244335463,
  },
  'Boykin Spaniel': {
    average_weight: 37.64588984977346,
    weight_stddev: 7.515096762722058,
  },
  'Great Dane': {
    average_weight: 121.46210163018702,
    weight_stddev: 32.92617981107014,
  },
  'Catahoula Leopard Dog': {
    average_weight: 59.50488633454575,
    weight_stddev: 18.29470622933815,
  },
  Greyhound: {
    average_weight: 66.4132565554347,
    weight_stddev: 14.0015864973902,
  },
  'Australian Stumpy Tail Cattle Dog': {
    average_weight: 41.23796162099013,
    weight_stddev: 13.916566955119555,
  },
  Saluki: {
    average_weight: 48.827151350697534,
    weight_stddev: 11.188125705406112,
  },
  Jagdterrier: {
    average_weight: 24.020566255572636,
    weight_stddev: 7.346568357405004,
  },
  Pomchi: {
    average_weight: 13.6098873745211,
    weight_stddev: 4.864380776794236,
  },
  'German Sheprador': {
    average_weight: 69.15596761273157,
    weight_stddev: 19.590467782286876,
  },
  'Lakeland Terrier': {
    average_weight: 17.1744588311553,
    weight_stddev: 3.272871952814579,
  },
  'Irish Doodle': {
    average_weight: 50.726191066913216,
    weight_stddev: 14.565947284708088,
  },
  'Old English Mastiff': {
    average_weight: 151.52233056718873,
    weight_stddev: 37.018905341916934,
  },
  Havanese: {
    average_weight: 14.84822928797549,
    weight_stddev: 4.574933419335636,
  },
  'Pembroke Welsh Corgi': {
    average_weight: 29.35469484777806,
    weight_stddev: 7.981867936886233,
  },
  'Basset Hound': {
    average_weight: 51.904906540595064,
    weight_stddev: 13.589623765865007,
  },
  'American Eskimo Dog': {
    average_weight: 28.334771779200036,
    weight_stddev: 11.47187452942029,
  },
  'Yorkshire Terrier': {
    average_weight: 12.263405053992157,
    weight_stddev: 6.444118445563249,
  },
  'Black and Tan Coonhound': {
    average_weight: 62.42924815377953,
    weight_stddev: 16.97590080083105,
  },
  'Anatolian Pyrenees': {
    average_weight: 98.41535303232172,
    weight_stddev: 24.46496160604365,
  },
  'Brussels Griffon': {
    average_weight: 14.88675902073221,
    weight_stddev: 6.976864631192357,
  },
  'French Bulldog': {
    average_weight: 27.05620687559932,
    weight_stddev: 6.339811974802361,
  },
  Bulldog: {
    average_weight: 58.25936647524058,
    weight_stddev: 20.456205107033462,
  },
  'Thai Ridgeback': {
    average_weight: 45.53440765660526,
    weight_stddev: 13.820663294616294,
  },
  'Bluetick Coonhound': {
    average_weight: 64.87832684042442,
    weight_stddev: 17.37336655278754,
  },
  Cockapoo: {
    average_weight: 22.775402395362217,
    weight_stddev: 7.156090931459341,
  },
  Jindo: {
    average_weight: 35.377544789359526,
    weight_stddev: 14.359899685563281,
  },
  'American Foxhound': {
    average_weight: 56.0637661236723,
    weight_stddev: 15.356196724052921,
  },
  Morkie: {
    average_weight: 12.624502183630945,
    weight_stddev: 4.726459971492662,
  },
  'Wire Fox Terrier': {
    average_weight: 23.48285708624926,
    weight_stddev: 10.377459459629451,
  },
  'Cardigan Welsh Corgi': {
    average_weight: 32.359396524024675,
    weight_stddev: 12.825497288193127,
  },
  'Shiloh Shepherd dog': {
    average_weight: 95.63655822229185,
    weight_stddev: 20.489539432616123,
  },
  'Labrador Husky': {
    average_weight: 64.61095407764955,
    weight_stddev: 16.852307281385638,
  },
  'Nederlandse Kooikerhondje': {
    average_weight: 25.767606820114704,
    weight_stddev: 6.639712712833263,
  },
  'Aussie-Corgi': {
    average_weight: 31.650542105709423,
    weight_stddev: 11.256654925165353,
  },
  Borador: {
    average_weight: 57.90001128235486,
    weight_stddev: 17.258740401008275,
  },
  'American Bull Mastiff': {
    average_weight: 98.55789490850168,
    weight_stddev: 30.5152510420368,
  },
  'Swedish Vallhund': {
    average_weight: 30.798115709374294,
    weight_stddev: 7.773500135797454,
  },
  'German Shepherd Dog': {
    average_weight: 74.25831278993715,
    weight_stddev: 19.600613844554207,
  },
  Goldendoodle: {
    average_weight: 51.406547923288834,
    weight_stddev: 20.64499525848857,
  },
  'Cairn Terrier': {
    average_weight: 19.278783440459236,
    weight_stddev: 6.862772134134529,
  },
  Collie: {
    average_weight: 58.50212803761387,
    weight_stddev: 17.32426652065015,
  },
  Poodle: {
    average_weight: 45.68574570312461,
    weight_stddev: 20.626544319461686,
  },
  Pudelpointer: {
    average_weight: 58.8848092528447,
    weight_stddev: 10.656368825430267,
  },
  Schipperke: {
    average_weight: 21.68985284704055,
    weight_stddev: 12.272231421401248,
  },
  Bullypit: {
    average_weight: 63.543655762212545,
    weight_stddev: 18.682824627635444,
  },
  Labraheeler: {
    average_weight: 53.126563688296,
    weight_stddev: 14.157558698799589,
  },
  'Akita Shepherd': {
    average_weight: 74.75168651203755,
    weight_stddev: 20.019936951190786,
  },
  Goldadoor: {
    average_weight: 74.8371337964995,
    weight_stddev: 12.80643289208911,
  },
  'Husky Jack': {
    average_weight: 55.99783807686108,
    weight_stddev: 24.070467408045243,
  },
  'Australian Cattle Dog': {
    average_weight: 46.70032248062701,
    weight_stddev: 13.610847278074859,
  },
  Pomsky: {
    average_weight: 28.393608342665473,
    weight_stddev: 11.970560984759523,
  },
  'Bernese Mountain Dog': {
    average_weight: 91.79475703610153,
    weight_stddev: 23.62736011791184,
  },
  'Cavalier King Charles Spaniel': {
    average_weight: 20.253987080850806,
    weight_stddev: 6.816554591591526,
  },
  'Miniature Pinscher': {
    average_weight: 18.225035501006346,
    weight_stddev: 8.101460757658314,
  },
  Papillon: {
    average_weight: 14.41183498579619,
    weight_stddev: 7.254780422065192,
  },
  'Airedale Terrier': {
    average_weight: 60.3909092030662,
    weight_stddev: 19.0828384584981,
  },
  Labbe: {
    average_weight: 55.95300145728852,
    weight_stddev: 16.0431824687798,
  },
  'Spanish Greyhound': {
    average_weight: 52.65953358141548,
    weight_stddev: 9.766500929438559,
  },
  Sheepadoodle: {
    average_weight: 57.61775240502004,
    weight_stddev: 17.850368715109816,
  },
  'Andalusian Podenco': {
    average_weight: 36.86400374165304,
    weight_stddev: 14.651297566922135,
  },
  Springerdoodle: {
    average_weight: 46.21157434362424,
    weight_stddev: 14.945010638343172,
  },
  Brittany: {
    average_weight: 41.19575070406853,
    weight_stddev: 10.749435235074492,
  },
  'Alaskan Klee Kai': {
    average_weight: 21.041688793974323,
    weight_stddev: 8.966639226603835,
  },
  'Manchester Terrier': {
    average_weight: 25.85136137631421,
    weight_stddev: 9.683170664330211,
  },
  Whippet: {
    average_weight: 36.25111266739095,
    weight_stddev: 11.803344573748904,
  },
  'Australian Retriever': {
    average_weight: 57.338078810238464,
    weight_stddev: 16.152558980586335,
  },
  Labradane: {
    average_weight: 80.74106885971608,
    weight_stddev: 22.52831163782095,
  },
  'Smooth and Wire Fox Terrier': {
    average_weight: 24.446517058090464,
    weight_stddev: 13.490507810406111,
  },
  Labsky: {
    average_weight: 64.76107820959594,
    weight_stddev: 16.921983983915972,
  },
  Bassador: {
    average_weight: 53.72640626065777,
    weight_stddev: 12.816823278366662,
  },
  'Labrador Retriever': {
    average_weight: 70.8536566119103,
    weight_stddev: 18.465905147332563,
  },
  'Shiba Inu': {
    average_weight: 26.954412712622386,
    weight_stddev: 9.023843788970725,
  },
  'Texas Heeler': {
    average_weight: 47.195969111358366,
    weight_stddev: 13.557925772545929,
  },
  'Cane Corso': {
    average_weight: 107.76615762860044,
    weight_stddev: 25.687523857846106,
  },
  Mastador: {
    average_weight: 101.18820261163195,
    weight_stddev: 23.58527899785135,
  },
  'Treeing Tennessee Brindle': {
    average_weight: 54.80810699722218,
    weight_stddev: 16.525285227585393,
  },
  'Tibetan Spaniel': {
    average_weight: 16.42422613024405,
    weight_stddev: 4.918725989799461,
  },
  Akita: {
    average_weight: 85.54113959328126,
    weight_stddev: 24.50824202640928,
  },
  'Rhodesian Ridgeback': {
    average_weight: 79.49466689743717,
    weight_stddev: 23.241082582999283,
  },
  'Bichon Frise': {
    average_weight: 16.81426977963589,
    weight_stddev: 9.510337114712929,
  },
  'Staffy Bull Pit': {
    average_weight: 59.28256584118831,
    weight_stddev: 14.319480762164792,
  },
  'Kai Ken': {
    average_weight: 35.256323485931745,
    weight_stddev: 8.608697081676885,
  },
  Borzoi: {
    average_weight: 75.46588141009339,
    weight_stddev: 16.07693333853185,
  },
  'Bich-poo': {
    average_weight: 17.06917838196321,
    weight_stddev: 7.374036579606689,
  },
  Chug: {
    average_weight: 17.110138092930114,
    weight_stddev: 8.062484788383298,
  },
  'Great Pyredane': {
    average_weight: 86.36726372908363,
    weight_stddev: 16.317904271296097,
  },
  Beabull: {
    average_weight: 46.96225708334969,
    weight_stddev: 9.813068827792543,
  },
  'American Pit Bull Terrier': {
    average_weight: 60.275359571768966,
    weight_stddev: 15.58879588238827,
  },
  'English Setter': {
    average_weight: 49.671749893705915,
    weight_stddev: 13.753994709568934,
  },
  'Lhasa Apso': {
    average_weight: 18.6605118095633,
    weight_stddev: 5.755366205164852,
  },
  'Bouvier des Flandres': {
    average_weight: 80.74011732705516,
    weight_stddev: 18.008271553979846,
  },
  'Tibetan Terrier': {
    average_weight: 27.018746474439432,
    weight_stddev: 6.9900318578225775,
  },
  'Berger Picard': {
    average_weight: 54.803380595629676,
    weight_stddev: 10.674966020938221,
  },
  'Icelandic Sheepdog': {
    average_weight: 34.94704939038328,
    weight_stddev: 10.174075761224252,
  },
  'Bull-Boxer': {
    average_weight: 60.8836001129947,
    weight_stddev: 13.41654859538331,
  },
  'Native American Indian Dog': {
    average_weight: 75.1957816662903,
    weight_stddev: 23.050284939246087,
  },
  'American White Shepherd': {
    average_weight: 68.05998237360318,
    weight_stddev: 21.284337783585798,
  },
  Ausky: {
    average_weight: 54.45449817949506,
    weight_stddev: 15.218018339259732,
  },
  'English Foxhound': {
    average_weight: 60.34936587173249,
    weight_stddev: 15.41062745621032,
  },
  'Australian Shepherd': {
    average_weight: 48.411131314242155,
    weight_stddev: 15.083989600787735,
  },
  'Lab-Pointer': {
    average_weight: 60.882747669541786,
    weight_stddev: 16.51859994176952,
  },
  'Belgian Tervuren': {
    average_weight: 57.737594048887125,
    weight_stddev: 12.672739596915498,
  },
  'Boston Terrier': {
    average_weight: 24.016783868788686,
    weight_stddev: 8.92986125762703,
  },
  'Chesapeake Bay Retriever': {
    average_weight: 79.72441004767761,
    weight_stddev: 17.69741426270739,
  },
  'Norwich Terrier': {
    average_weight: 15.894743097151842,
    weight_stddev: 4.972061398774452,
  },
  'Foxy Rat Terrier': {
    average_weight: 26.4630829226253,
    weight_stddev: 11.148054397259939,
  },
  'Pyrenean Shepherd': {
    average_weight: 70.89363511109025,
    weight_stddev: 31.098759881725616,
  },
  'Norwegian Elkhound': {
    average_weight: 51.7485977620135,
    weight_stddev: 13.338162409669964,
  },
  'Corgi Basset': {
    average_weight: 37.09627766437405,
    weight_stddev: 11.453299125752926,
  },
  'Alaskan Malamute': {
    average_weight: 87.26800922192501,
    weight_stddev: 24.24805493578864,
  },
  'Miniature Schnauzer': {
    average_weight: 18.66198571293753,
    weight_stddev: 6.070723378914803,
  },
  'English Shepherd': {
    average_weight: 55.50946177465832,
    weight_stddev: 13.753882026039589,
  },
  Chihuahua: {
    average_weight: 15.520042857817758,
    weight_stddev: 9.868333788605671,
  },
  'West Highland White Terrier': {
    average_weight: 19.457593580147332,
    weight_stddev: 5.315789376302245,
  },
  'Parson Russell Terrier': {
    average_weight: 21.789591106686526,
    weight_stddev: 8.68741934722913,
  },
  'Scottish Terrier': {
    average_weight: 23.003587546810135,
    weight_stddev: 6.947439527796692,
  },
  'Smooth Fox Terrier': {
    average_weight: 26.099962709632937,
    weight_stddev: 12.465773593151907,
  },
  'Wire Hair Snauzer': {
    average_weight: 29.94532590040349,
    weight_stddev: 15.244034856295437,
  },
  Pyrador: {
    average_weight: 82.99599491729074,
    weight_stddev: 15.632121066061934,
  },
  Sheprador: {
    average_weight: 61.99806288780177,
    weight_stddev: 23.396254142892698,
  },
  'Border Collie': {
    average_weight: 46.806044407147695,
    weight_stddev: 13.627753829423526,
  },
  Boxer: {
    average_weight: 62.122100174504055,
    weight_stddev: 16.649417268665037,
  },
  'Great Pyrenees': {
    average_weight: 94.42282691026588,
    weight_stddev: 24.17230713226233,
  },
  'Akbash Dog': {
    average_weight: 98.90527235239435,
    weight_stddev: 26.303338365872047,
  },
  'Jack Russell Terrier': {
    average_weight: 20.863665169656255,
    weight_stddev: 8.931928504727443,
  },
  'Carolina Dog': {
    average_weight: 51.59680636413628,
    weight_stddev: 15.740305481354236,
  },
  'Taiwan Dog': {
    average_weight: 39.92962848669431,
    weight_stddev: 10.09801289507544,
  },
  'Chinese Crested': {
    average_weight: 15.540885578286005,
    weight_stddev: 5.63368715012209,
  },
  Beauceron: {
    average_weight: 83.98115219922822,
    weight_stddev: 19.704820162069804,
  },
  Labloodhound: {
    average_weight: 69.72503808120545,
    weight_stddev: 20.56128112325366,
  },
  'Canaan Dog': {
    average_weight: 48.55173890376406,
    weight_stddev: 12.850981099127885,
  },
  'Englian Mastiff': {
    average_weight: 138.7624778014466,
    weight_stddev: 35.374010574293656,
  },
  'Russell Terrier': {
    average_weight: 23.527468942890152,
    weight_stddev: 10.7227049715902,
  },
  'Field Spaniel': {
    average_weight: 40.68608502373539,
    weight_stddev: 10.675782824788056,
  },
  'English Bulldog': {
    average_weight: 56.78824174094839,
    weight_stddev: 12.629245457076546,
  },
  'Lab Pei': {
    average_weight: 58.344024470756636,
    weight_stddev: 19.680273011898876,
  },
  'Golden Retriever': {
    average_weight: 71.37971106306325,
    weight_stddev: 16.99472439568028,
  },
  'Black Mouth Cur': {
    average_weight: 58.7680691593557,
    weight_stddev: 15.11383557465376,
  },
  'Rat Terrier': {
    average_weight: 22.708902212795113,
    weight_stddev: 9.79169516338173,
  },
  Pomeranian: {
    average_weight: 15.656298373374412,
    weight_stddev: 11.89545254837226,
  },
  'Lagotto Romagnolo': {
    average_weight: 34.74013486085343,
    weight_stddev: 6.357349346318842,
  },
  'Mal-Shi': {
    average_weight: 14.02581754443749,
    weight_stddev: 4.425957973019953,
  },
  'Standard Schnauzer': {
    average_weight: 33.69413788223094,
    weight_stddev: 12.889290061920294,
  },
  'Wirehaired Vizsla': {
    average_weight: 60.34121777005965,
    weight_stddev: 12.139267359948255,
  },
  'King Shepherd': {
    average_weight: 104.06769163481549,
    weight_stddev: 21.6526679223048,
  },
  Goberian: {
    average_weight: 63.81293623532442,
    weight_stddev: 12.709136342528847,
  },
  'Norfolk Terrier': {
    average_weight: 19.20191754084981,
    weight_stddev: 8.728505813366182,
  },
  'Irish Terrier': {
    average_weight: 35.450672313710854,
    weight_stddev: 12.51434262845181,
  },
  Dingo: {
    average_weight: 42.80043761737821,
    weight_stddev: 15.590109329731986,
  },
  Chinook: {
    average_weight: 62.71502780147845,
    weight_stddev: 14.880940002136096,
  },
  'Finnish Spitz': {
    average_weight: 29.865107875554678,
    weight_stddev: 11.877536011228607,
  },
  'Anatolian Shepherd': {
    average_weight: 99.07059099805807,
    weight_stddev: 27.40322781644915,
  },
  Xoloitzcuintli: {
    average_weight: 37.87483646390558,
    weight_stddev: 31.279405992350913,
  },
  'Golden Cocker Retriever': {
    average_weight: 43.39885267649635,
    weight_stddev: 14.654570009459617,
  },
  'Golden Shepherd': {
    average_weight: 65.91726112340116,
    weight_stddev: 22.790171454761776,
  },
  'Catahoula Bulldog': {
    average_weight: 54.83084191999175,
    weight_stddev: 27.02108925044822,
  },
  'Miniature Australian Shepherd': {
    average_weight: 29.674896346398477,
    weight_stddev: 10.264202774626497,
  },
  Boxador: {
    average_weight: 65.56413752798777,
    weight_stddev: 13.77082852922188,
  },
  'Soft Coated Wheaten Terrier': {
    average_weight: 38.062467466868426,
    weight_stddev: 10.083267195669618,
  },
  'Pharaoh Hound': {
    average_weight: 52.34998795749497,
    weight_stddev: 12.800270680074991,
  },
  Samoyed: {
    average_weight: 56.7997921516807,
    weight_stddev: 16.280814631768852,
  },
  'Shepherd Chow': {
    average_weight: 56.99719536664412,
    weight_stddev: 16.402946639224986,
  },
  'Pit Bull': {
    average_weight: 62.4440451648606,
    weight_stddev: 16.78917877217251,
  },
  'Toy Poodle': {
    average_weight: 11.93768347444457,
    weight_stddev: 5.4466225829910435,
  },
  Cavachon: {
    average_weight: 19.43569361979972,
    weight_stddev: 11.382810534018823,
  },
  Africanis: {
    average_weight: 32.24007678783088,
    weight_stddev: 15.650367575443878,
  },
  'Silky Terrier': {
    average_weight: 14.417801375749793,
    weight_stddev: 5.232372387331595,
  },
  Dalmatian: {
    average_weight: 56.468785103158034,
    weight_stddev: 14.379144807651643,
  },
  'Old English Sheepdog': {
    average_weight: 67.55648183635263,
    weight_stddev: 20.579433466201234,
  },
  'Double Doodle': {
    average_weight: 49.800791783398864,
    weight_stddev: 16.11700493299762,
  },
  Affenpinscher: {
    average_weight: 20.47486100832548,
    weight_stddev: 21.926420974985383,
  },
  'Australian Labradoodle': {
    average_weight: 38.13622481081277,
    weight_stddev: 15.439599524411456,
  },
  Newfypoo: {
    average_weight: 84.80174177259688,
    weight_stddev: 24.227913123720185,
  },
  'Bedlington Terrier': {
    average_weight: 24.551390037416805,
    weight_stddev: 10.641870940918572,
  },
  Beagle: {
    average_weight: 32.84523685807689,
    weight_stddev: 11.679693733524157,
  },
  Basenji: {
    average_weight: 31.312714430787388,
    weight_stddev: 11.62581436479895,
  },
  'American English Coonhound': {
    average_weight: 59.05707137330947,
    weight_stddev: 14.700982291681903,
  },
  'Irish Setter': {
    average_weight: 62.48551380672271,
    weight_stddev: 16.10107040761466,
  },
  'Kerry Blue Terrier': {
    average_weight: 33.035168906806014,
    weight_stddev: 8.386736834258244,
  },
  'Wirehaired Pointing Griffon': {
    average_weight: 60.739636653061204,
    weight_stddev: 12.812449523927658,
  },
  Boerboel: {
    average_weight: 133.56107119271707,
    weight_stddev: 37.90807535973585,
  },
  Cavapoo: {
    average_weight: 18.883245913276216,
    weight_stddev: 8.648142394571552,
  },
  Whoodle: {
    average_weight: 38.8044113468191,
    weight_stddev: 13.431959291261963,
  },
  Shepadoodle: {
    average_weight: 66.37785572613087,
    weight_stddev: 20.327719231815163,
  },
  Labradoodle: {
    average_weight: 54.44932880521357,
    weight_stddev: 20.721690505970777,
  },
  'Miniature American Shepherd': {
    average_weight: 29.373843126625086,
    weight_stddev: 9.17202612692007,
  },
  Bernedoodle: {
    average_weight: 59.18407077800625,
    weight_stddev: 23.046782293994323,
  },
  'Mountain Cur': {
    average_weight: 52.29302554302575,
    weight_stddev: 14.965956427491909,
  },
  Feist: {
    average_weight: 29.377122557887684,
    weight_stddev: 11.14092098624749,
  },
  'German Shorthaired Lab': {
    average_weight: 65.86849711778017,
    weight_stddev: 16.7595225985222,
  },
  'Mountain Feist': {
    average_weight: 32.139564324596336,
    weight_stddev: 10.891492200186422,
  },
  'Bagle Hound': {
    average_weight: 44.19888371923452,
    weight_stddev: 16.096368274506325,
  },
  Pekingese: {
    average_weight: 16.367765823387963,
    weight_stddev: 6.642758431754123,
  },
  'Black Russian Terrier': {
    average_weight: 76.26636999114766,
    weight_stddev: 45.329994235366485,
  },
  'Bull Terrier': {
    average_weight: 52.61038262159049,
    weight_stddev: 18.381368597548473,
  },
  'Miniature Poodle': {
    average_weight: 17.682263194408243,
    weight_stddev: 7.393553141519598,
  },
  'Italian Greyhound': {
    average_weight: 19.601169780001406,
    weight_stddev: 11.606713189876,
  },
  'Toy Fox Terrier': {
    average_weight: 15.863664779996217,
    weight_stddev: 8.54263525422845,
  },
  'Shorkie Tzu': {
    average_weight: 13.355403094237325,
    weight_stddev: 3.3921993722673642,
  },
  'Jack-Rat Terrier': {
    average_weight: 21.805748605023624,
    weight_stddev: 7.400502457187257,
  },
  Labrottie: {
    average_weight: 74.49078236421941,
    weight_stddev: 23.50499610207513,
  },
  'Golden Pyrenees': {
    average_weight: 80.1353404730636,
    weight_stddev: 18.30356531497405,
  },
  'Irish Wolfhound': {
    average_weight: 108.77564318277844,
    weight_stddev: 43.61793572507014,
  },
  'Alaskan Husky': {
    average_weight: 59.38981755736411,
    weight_stddev: 18.312584039955613,
  },
  'Australian Terrier': {
    average_weight: 28.078441785462402,
    weight_stddev: 16.029953565863146,
  },
  'Patterdale Terrier': {
    average_weight: 26.564871724755267,
    weight_stddev: 11.072448261899098,
  },
  'Giant Schnauzer': {
    average_weight: 82.39612090845064,
    weight_stddev: 19.084206918961975,
  },
  'Bichon/Yorkie': {
    average_weight: 13.888454675902722,
    weight_stddev: 10.710017559314906,
  },
  'Pocket Pitbull': {
    average_weight: 54.38145118884671,
    weight_stddev: 19.9513042478749,
  },
  'Australian Yorkshire Terrier': {
    average_weight: 13.640775551245993,
    weight_stddev: 5.416708216753515,
  },
  Chorkie: {
    average_weight: 16.40313926122201,
    weight_stddev: 26.854651159448526,
  },
  'Golden Border Retriever': {
    average_weight: 62.53430534106459,
    weight_stddev: 24.84494031762721,
  },
  Puli: {
    average_weight: 32.71237081935015,
    weight_stddev: 10.532089485454508,
  },
  Otterhound: {
    average_weight: 100.95607348146957,
    weight_stddev: 25.309915635218992,
  },
  'Miniature Shar Pei': {
    average_weight: 36.85103986591902,
    weight_stddev: 8.23525717262626,
  },
  Corgidor: {
    average_weight: 37.10996172921738,
    weight_stddev: 11.929937555838428,
  },
};

export interface SizingGuideItem {
  name?: string;
  weight?: number;
  isPuppy?: boolean;
  total: number;
  XS: number;
  S: number;
  M: number;
  L: number;
  XL: number;
}

export const AdultSizingGuide: SizingGuideItem[] = [
  {
    name: 'Affen Terrier',
    weight: 10,
    total: 82,
    XS: 17.07317073170732,
    S: 18.29268292682927,
    M: 30.48780487804878,
    L: 31.70731707317073,
    XL: 2.4390243902439,
  },
  {
    name: 'Affen Terrier',
    weight: 20,
    total: 58,
    XS: 32.75862068965517,
    S: 27.58620689655172,
    M: 20.68965517241379,
    L: 15.51724137931034,
    XL: 3.44827586206897,
  },
  {
    name: 'Affen Terrier',
    weight: 30,
    total: 36,
    XS: 5.55555555555556,
    S: 13.88888888888889,
    M: 72.22222222222221,
    L: 8.33333333333333,
    XL: 0,
  },
  {
    name: 'Afghan Hound',
    weight: 50,
    total: 35,
    XS: 0,
    S: 8.57142857142857,
    M: 57.14285714285714,
    L: 34.28571428571429,
    XL: 0,
  },
  {
    name: 'Afghan Hound',
    weight: 60,
    total: 37,
    XS: 2.7027027027027,
    S: 2.7027027027027,
    M: 62.16216216216216,
    L: 32.43243243243243,
    XL: 0,
  },
  {
    name: 'Africanis',
    weight: 20,
    total: 26,
    XS: 34.61538461538462,
    S: 50,
    M: 15.38461538461538,
    L: 0,
    XL: 0,
  },
  {
    name: 'Airedale Terrier',
    weight: 30,
    total: 30,
    XS: 6.66666666666667,
    S: 16.66666666666667,
    M: 60,
    L: 13.33333333333333,
    XL: 3.33333333333333,
  },
  {
    name: 'Airedale Terrier',
    weight: 40,
    total: 42,
    XS: 0,
    S: 7.14285714285714,
    M: 69.04761904761905,
    L: 23.80952380952381,
    XL: 0,
  },
  {
    name: 'Airedale Terrier',
    weight: 50,
    total: 128,
    XS: 0,
    S: 2.34375,
    M: 56.25,
    L: 39.84375,
    XL: 1.5625,
  },
  {
    name: 'Airedale Terrier',
    weight: 60,
    total: 120,
    XS: 0,
    S: 0.83333333333333,
    M: 40.83333333333333,
    L: 57.5,
    XL: 0.83333333333333,
  },
  {
    name: 'Airedale Terrier',
    weight: 70,
    total: 104,
    XS: 0.96153846153846,
    S: 2.88461538461538,
    M: 27.88461538461538,
    L: 68.26923076923077,
    XL: 0,
  },
  {
    name: 'Airedale Terrier',
    weight: 80,
    total: 75,
    XS: 0,
    S: 1.33333333333333,
    M: 24,
    L: 69.33333333333333,
    XL: 5.33333333333333,
  },
  {
    name: 'Airedale Terrier',
    weight: 90,
    total: 39,
    XS: 0,
    S: 0,
    M: 20.51282051282051,
    L: 71.7948717948718,
    XL: 7.69230769230769,
  },
  {
    name: 'Akbash Dog',
    weight: 80,
    total: 30,
    XS: 0,
    S: 0,
    M: 20,
    L: 53.33333333333333,
    XL: 26.66666666666667,
  },
  {
    name: 'Akbash Dog',
    weight: 90,
    total: 29,
    XS: 0,
    S: 0,
    M: 20.68965517241379,
    L: 62.06896551724138,
    XL: 17.24137931034483,
  },
  {
    name: 'Akbash Dog',
    weight: 100,
    total: 37,
    XS: 0,
    S: 2.7027027027027,
    M: 24.32432432432432,
    L: 27.02702702702703,
    XL: 45.94594594594595,
  },
  {
    name: 'Akita',
    weight: 40,
    total: 31,
    XS: 0,
    S: 6.45161290322581,
    M: 58.06451612903226,
    L: 35.48387096774194,
    XL: 0,
  },
  {
    name: 'Akita',
    weight: 50,
    total: 50,
    XS: 0,
    S: 6,
    M: 32,
    L: 62,
    XL: 0,
  },
  {
    name: 'Akita',
    weight: 60,
    total: 80,
    XS: 0,
    S: 0,
    M: 25,
    L: 67.5,
    XL: 7.5,
  },
  {
    name: 'Akita',
    weight: 70,
    total: 139,
    XS: 0,
    S: 0,
    M: 23.7410071942446,
    L: 64.02877697841727,
    XL: 12.23021582733813,
  },
  {
    name: 'Akita',
    weight: 80,
    total: 169,
    XS: 0,
    S: 1.18343195266272,
    M: 13.6094674556213,
    L: 61.53846153846154,
    XL: 23.66863905325444,
  },
  {
    name: 'Akita',
    weight: 90,
    total: 169,
    XS: 0,
    S: 1.18343195266272,
    M: 13.01775147928994,
    L: 50.88757396449704,
    XL: 34.9112426035503,
  },
  {
    name: 'Akita',
    weight: 100,
    total: 162,
    XS: 0,
    S: 1.23456790123457,
    M: 18.51851851851852,
    L: 33.95061728395062,
    XL: 46.2962962962963,
  },
  {
    name: 'Akita',
    weight: 110,
    total: 89,
    XS: 0,
    S: 0,
    M: 8.98876404494382,
    L: 29.21348314606742,
    XL: 61.79775280898876,
  },
  {
    name: 'Akita',
    weight: 120,
    total: 49,
    XS: 0,
    S: 0,
    M: 14.28571428571429,
    L: 24.48979591836735,
    XL: 61.22448979591837,
  },
  {
    name: 'Alaskan Husky',
    weight: 20,
    total: 52,
    XS: 7.69230769230769,
    S: 19.23076923076923,
    M: 51.92307692307692,
    L: 17.30769230769231,
    XL: 3.84615384615385,
  },
  {
    name: 'Alaskan Husky',
    weight: 30,
    total: 137,
    XS: 0.72992700729927,
    S: 11.67883211678832,
    M: 66.42335766423358,
    L: 21.16788321167883,
    XL: 0,
  },
  {
    name: 'Alaskan Husky',
    weight: 40,
    total: 419,
    XS: 0,
    S: 2.38663484486874,
    M: 57.75656324582339,
    L: 38.90214797136038,
    XL: 0.95465393794749,
  },
  {
    name: 'Alaskan Husky',
    weight: 50,
    total: 949,
    XS: 0.21074815595364,
    S: 1.7913593256059,
    M: 48.99894625922023,
    L: 47.83983140147524,
    XL: 1.15911485774499,
  },
  {
    name: 'Alaskan Husky',
    weight: 60,
    total: 796,
    XS: 0,
    S: 1.50753768844221,
    M: 33.16582914572864,
    L: 63.31658291457286,
    XL: 2.01005025125628,
  },
  {
    name: 'Alaskan Husky',
    weight: 70,
    total: 547,
    XS: 0,
    S: 0.36563071297989,
    M: 23.40036563071298,
    L: 72.21206581352834,
    XL: 4.02193784277879,
  },
  {
    name: 'Alaskan Husky',
    weight: 80,
    total: 331,
    XS: 0.30211480362538,
    S: 0.90634441087613,
    M: 17.22054380664653,
    L: 73.71601208459215,
    XL: 7.85498489425982,
  },
  {
    name: 'Alaskan Husky',
    weight: 90,
    total: 138,
    XS: 0,
    S: 0.72463768115942,
    M: 18.11594202898551,
    L: 61.59420289855072,
    XL: 19.56521739130435,
  },
  {
    name: 'Alaskan Husky',
    weight: 100,
    total: 62,
    XS: 0,
    S: 1.61290322580645,
    M: 11.29032258064516,
    L: 66.12903225806453,
    XL: 20.96774193548387,
  },
  {
    name: 'Alaskan Klee Kai',
    weight: 10,
    total: 67,
    XS: 29.85074626865672,
    S: 58.2089552238806,
    M: 10.44776119402985,
    L: 1.49253731343284,
    XL: 0,
  },
  {
    name: 'Alaskan Klee Kai',
    weight: 20,
    total: 344,
    XS: 7.55813953488372,
    S: 59.01162790697674,
    M: 33.13953488372093,
    L: 0.2906976744186,
    XL: 0,
  },
  {
    name: 'Alaskan Klee Kai',
    weight: 30,
    total: 141,
    XS: 0,
    S: 21.98581560283688,
    M: 74.46808510638299,
    L: 3.54609929078014,
    XL: 0,
  },
  {
    name: 'Alaskan Klee Kai',
    weight: 40,
    total: 45,
    XS: 0,
    S: 6.66666666666667,
    M: 75.55555555555556,
    L: 17.77777777777778,
    XL: 0,
  },
  {
    name: 'Alaskan Malamute',
    weight: 40,
    total: 36,
    XS: 0,
    S: 8.33333333333333,
    M: 61.11111111111111,
    L: 30.55555555555556,
    XL: 0,
  },
  {
    name: 'Alaskan Malamute',
    weight: 50,
    total: 89,
    XS: 0,
    S: 0,
    M: 30.33707865168539,
    L: 64.04494382022472,
    XL: 5.61797752808989,
  },
  {
    name: 'Alaskan Malamute',
    weight: 60,
    total: 145,
    XS: 0,
    S: 0.68965517241379,
    M: 21.37931034482759,
    L: 71.03448275862068,
    XL: 6.89655172413793,
  },
  {
    name: 'Alaskan Malamute',
    weight: 70,
    total: 227,
    XS: 0,
    S: 0.88105726872247,
    M: 24.66960352422907,
    L: 64.75770925110132,
    XL: 9.69162995594714,
  },
  {
    name: 'Alaskan Malamute',
    weight: 80,
    total: 282,
    XS: 0,
    S: 1.41843971631206,
    M: 23.40425531914894,
    L: 61.70212765957447,
    XL: 13.47517730496454,
  },
  {
    name: 'Alaskan Malamute',
    weight: 90,
    total: 262,
    XS: 0,
    S: 0.38167938931298,
    M: 20.22900763358779,
    L: 51.52671755725191,
    XL: 27.86259541984733,
  },
  {
    name: 'Alaskan Malamute',
    weight: 100,
    total: 234,
    XS: 0,
    S: 0.42735042735043,
    M: 21.36752136752137,
    L: 46.58119658119658,
    XL: 31.62393162393162,
  },
  {
    name: 'Alaskan Malamute',
    weight: 110,
    total: 102,
    XS: 0,
    S: 1.96078431372549,
    M: 15.68627450980392,
    L: 32.35294117647059,
    XL: 50,
  },
  {
    name: 'Alaskan Malamute',
    weight: 120,
    total: 56,
    XS: 0,
    S: 1.78571428571429,
    M: 17.85714285714286,
    L: 42.85714285714286,
    XL: 37.5,
  },
  {
    name: 'Alaskan Malamute',
    weight: 130,
    total: 53,
    XS: 0,
    S: 1.88679245283019,
    M: 22.64150943396226,
    L: 20.75471698113208,
    XL: 54.71698113207547,
  },
  {
    name: 'American Boston Bull Terrier',
    weight: 20,
    total: 44,
    XS: 2.27272727272727,
    S: 59.09090909090909,
    M: 36.36363636363636,
    L: 2.27272727272727,
    XL: 0,
  },
  {
    name: 'American Boston Bull Terrier',
    weight: 30,
    total: 44,
    XS: 0,
    S: 27.27272727272727,
    M: 65.9090909090909,
    L: 6.81818181818182,
    XL: 0,
  },
  {
    name: 'American Bulldog',
    weight: 40,
    total: 44,
    XS: 0,
    S: 6.81818181818182,
    M: 54.54545454545455,
    L: 36.36363636363636,
    XL: 2.27272727272727,
  },
  {
    name: 'American Bulldog',
    weight: 50,
    total: 109,
    XS: 0.91743119266055,
    S: 5.5045871559633,
    M: 41.28440366972477,
    L: 50.45871559633028,
    XL: 1.8348623853211,
  },
  {
    name: 'American Bulldog',
    weight: 60,
    total: 172,
    XS: 0,
    S: 1.74418604651163,
    M: 26.74418604651163,
    L: 65.69767441860465,
    XL: 5.81395348837209,
  },
  {
    name: 'American Bulldog',
    weight: 70,
    total: 204,
    XS: 0,
    S: 1.47058823529412,
    M: 15.68627450980392,
    L: 73.52941176470588,
    XL: 9.31372549019608,
  },
  {
    name: 'American Bulldog',
    weight: 80,
    total: 159,
    XS: 0,
    S: 1.88679245283019,
    M: 20.12578616352201,
    L: 62.89308176100629,
    XL: 15.09433962264151,
  },
  {
    name: 'American Bulldog',
    weight: 90,
    total: 111,
    XS: 0,
    S: 0,
    M: 23.42342342342342,
    L: 50.45045045045045,
    XL: 26.12612612612613,
  },
  {
    name: 'American Bulldog',
    weight: 100,
    total: 62,
    XS: 0,
    S: 0,
    M: 11.29032258064516,
    L: 46.7741935483871,
    XL: 41.93548387096774,
  },
  {
    name: 'American Bulldog',
    weight: 110,
    total: 29,
    XS: 0,
    S: 0,
    M: 10.3448275862069,
    L: 48.27586206896552,
    XL: 41.37931034482759,
  },
  {
    name: 'American Bully',
    weight: 20,
    total: 42,
    XS: 9.52380952380952,
    S: 28.57142857142857,
    M: 40.47619047619048,
    L: 16.66666666666667,
    XL: 4.76190476190476,
  },
  {
    name: 'American Bully',
    weight: 30,
    total: 76,
    XS: 0,
    S: 9.21052631578947,
    M: 57.89473684210526,
    L: 30.26315789473684,
    XL: 2.63157894736842,
  },
  {
    name: 'American Bully',
    weight: 40,
    total: 135,
    XS: 0,
    S: 1.48148148148148,
    M: 51.85185185185185,
    L: 44.44444444444444,
    XL: 2.22222222222222,
  },
  {
    name: 'American Bully',
    weight: 50,
    total: 275,
    XS: 0,
    S: 0.72727272727273,
    M: 32.72727272727273,
    L: 61.45454545454545,
    XL: 5.09090909090909,
  },
  {
    name: 'American Bully',
    weight: 60,
    total: 372,
    XS: 0.26881720430108,
    S: 1.34408602150538,
    M: 19.08602150537634,
    L: 71.50537634408602,
    XL: 7.79569892473118,
  },
  {
    name: 'American Bully',
    weight: 70,
    total: 335,
    XS: 0,
    S: 0.59701492537313,
    M: 20.29850746268657,
    L: 63.88059701492537,
    XL: 15.22388059701493,
  },
  {
    name: 'American Bully',
    weight: 80,
    total: 203,
    XS: 0,
    S: 1.97044334975369,
    M: 12.80788177339901,
    L: 63.05418719211823,
    XL: 22.16748768472906,
  },
  {
    name: 'American Bully',
    weight: 90,
    total: 125,
    XS: 0,
    S: 0.8,
    M: 12,
    L: 48,
    XL: 39.2,
  },
  {
    name: 'American Bully',
    weight: 100,
    total: 59,
    XS: 0,
    S: 0,
    M: 8.47457627118644,
    L: 40.67796610169492,
    XL: 50.84745762711864,
  },
  {
    name: 'American Bully',
    weight: 110,
    total: 34,
    XS: 0,
    S: 2.94117647058824,
    M: 8.82352941176471,
    L: 29.41176470588235,
    XL: 58.82352941176471,
  },
  {
    name: 'American English Coonhound',
    weight: 40,
    total: 44,
    XS: 0,
    S: 2.27272727272727,
    M: 81.81818181818181,
    L: 15.90909090909091,
    XL: 0,
  },
  {
    name: 'American English Coonhound',
    weight: 50,
    total: 145,
    XS: 0.68965517241379,
    S: 0.68965517241379,
    M: 57.93103448275862,
    L: 40,
    XL: 0.68965517241379,
  },
  {
    name: 'American English Coonhound',
    weight: 60,
    total: 119,
    XS: 0,
    S: 0,
    M: 41.17647058823529,
    L: 58.82352941176471,
    XL: 0,
  },
  {
    name: 'American English Coonhound',
    weight: 70,
    total: 112,
    XS: 0,
    S: 0,
    M: 28.57142857142857,
    L: 69.64285714285714,
    XL: 1.78571428571429,
  },
  {
    name: 'American English Coonhound',
    weight: 80,
    total: 55,
    XS: 0,
    S: 0,
    M: 16.36363636363636,
    L: 78.18181818181819,
    XL: 5.45454545454545,
  },
  {
    name: 'American Eskimo Dog',
    weight: 20,
    total: 114,
    XS: 2.63157894736842,
    S: 48.24561403508772,
    M: 44.73684210526316,
    L: 4.3859649122807,
    XL: 0,
  },
  {
    name: 'American Eskimo Dog',
    weight: 30,
    total: 127,
    XS: 0,
    S: 20.47244094488189,
    M: 74.01574803149606,
    L: 4.7244094488189,
    XL: 0.78740157480315,
  },
  {
    name: 'American Eskimo Dog',
    weight: 40,
    total: 42,
    XS: 0,
    S: 0,
    M: 83.33333333333333,
    L: 16.66666666666667,
    XL: 0,
  },
  {
    name: 'American Foxhound',
    weight: 30,
    total: 33,
    XS: 0,
    S: 9.09090909090909,
    M: 84.84848484848484,
    L: 6.06060606060606,
    XL: 0,
  },
  {
    name: 'American Foxhound',
    weight: 40,
    total: 169,
    XS: 0,
    S: 5.32544378698225,
    M: 77.51479289940828,
    L: 16.56804733727811,
    XL: 0.59171597633136,
  },
  {
    name: 'American Foxhound',
    weight: 50,
    total: 363,
    XS: 0,
    S: 1.65289256198347,
    M: 68.87052341597796,
    L: 29.20110192837466,
    XL: 0.27548209366391,
  },
  {
    name: 'American Foxhound',
    weight: 60,
    total: 276,
    XS: 0,
    S: 0.36231884057971,
    M: 44.56521739130435,
    L: 54.71014492753623,
    XL: 0.36231884057971,
  },
  {
    name: 'American Foxhound',
    weight: 70,
    total: 213,
    XS: 0,
    S: 1.40845070422535,
    M: 30.51643192488263,
    L: 66.19718309859155,
    XL: 1.87793427230047,
  },
  {
    name: 'American Foxhound',
    weight: 80,
    total: 80,
    XS: 0,
    S: 1.25,
    M: 25,
    L: 68.75,
    XL: 5,
  },
  {
    name: 'American Foxhound',
    weight: 90,
    total: 39,
    XS: 2.56410256410256,
    S: 0,
    M: 20.51282051282051,
    L: 69.23076923076923,
    XL: 7.69230769230769,
  },
  {
    name: 'American French Bulldog',
    weight: 10,
    total: 29,
    XS: 10.3448275862069,
    S: 68.96551724137932,
    M: 10.3448275862069,
    L: 10.3448275862069,
    XL: 0,
  },
  {
    name: 'American French Bulldog',
    weight: 20,
    total: 120,
    XS: 1.66666666666667,
    S: 44.16666666666667,
    M: 49.16666666666667,
    L: 5,
    XL: 0,
  },
  {
    name: 'American French Bulldog',
    weight: 30,
    total: 287,
    XS: 0,
    S: 20.90592334494774,
    M: 60.62717770034843,
    L: 17.77003484320557,
    XL: 0.69686411149826,
  },
  {
    name: 'American French Bulldog',
    weight: 40,
    total: 86,
    XS: 0,
    S: 5.81395348837209,
    M: 51.16279069767442,
    L: 40.69767441860465,
    XL: 2.32558139534884,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 20,
    total: 134,
    XS: 2.23880597014925,
    S: 25.37313432835821,
    M: 49.25373134328358,
    L: 20.14925373134328,
    XL: 2.98507462686567,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 30,
    total: 399,
    XS: 0,
    S: 15.53884711779449,
    M: 64.16040100250628,
    L: 20.30075187969925,
    XL: 0,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 40,
    total: 1170,
    XS: 0.42735042735043,
    S: 3.24786324786325,
    M: 63.84615384615385,
    L: 31.79487179487179,
    XL: 0.68376068376068,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 50,
    total: 2646,
    XS: 0.18896447467876,
    S: 1.24716553287982,
    M: 45.99395313681028,
    L: 51.32275132275132,
    XL: 1.24716553287982,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 60,
    total: 3016,
    XS: 0.09946949602122,
    S: 0.72944297082228,
    M: 30.13925729442971,
    L: 66.84350132625995,
    XL: 2.18832891246684,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 70,
    total: 2437,
    XS: 0.04103405826836,
    S: 0.49240869922035,
    M: 21.99425523184243,
    L: 72.17890849405006,
    XL: 5.29339351661879,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 80,
    total: 1260,
    XS: 0.15873015873016,
    S: 0.63492063492063,
    M: 18.88888888888889,
    L: 69.92063492063492,
    XL: 10.3968253968254,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 90,
    total: 506,
    XS: 0,
    S: 0.19762845849802,
    M: 19.1699604743083,
    L: 61.85770750988142,
    XL: 18.77470355731225,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 100,
    total: 178,
    XS: 0,
    S: 0,
    M: 14.60674157303371,
    L: 52.80898876404494,
    XL: 32.58426966292135,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 110,
    total: 53,
    XS: 0,
    S: 0,
    M: 16.9811320754717,
    L: 45.28301886792453,
    XL: 37.73584905660377,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 20,
    total: 37,
    XS: 2.7027027027027,
    S: 29.72972972972973,
    M: 59.45945945945946,
    L: 8.10810810810811,
    XL: 0,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 30,
    total: 179,
    XS: 0.55865921787709,
    S: 11.73184357541899,
    M: 73.74301675977654,
    L: 13.96648044692737,
    XL: 0,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 40,
    total: 502,
    XS: 0,
    S: 3.78486055776892,
    M: 69.32270916334662,
    L: 26.29482071713147,
    XL: 0.59760956175299,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 50,
    total: 1305,
    XS: 0.15325670498084,
    S: 0.76628352490421,
    M: 46.74329501915709,
    L: 51.4176245210728,
    XL: 0.91954022988506,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 60,
    total: 1449,
    XS: 0,
    S: 1.24223602484472,
    M: 28.91649413388544,
    L: 67.90890269151139,
    XL: 1.93236714975845,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 70,
    total: 1234,
    XS: 0.08103727714749,
    S: 0.6482982171799,
    M: 22.44732576985413,
    L: 71.63695299837924,
    XL: 5.18638573743922,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 80,
    total: 593,
    XS: 0.16863406408094,
    S: 0.67453625632378,
    M: 20.74198988195616,
    L: 69.64586846543003,
    XL: 8.76897133220911,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 90,
    total: 235,
    XS: 0.42553191489362,
    S: 0.85106382978723,
    M: 17.02127659574468,
    L: 60,
    XL: 21.70212765957447,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 100,
    total: 79,
    XS: 0,
    S: 0,
    M: 15.18987341772152,
    L: 53.16455696202532,
    XL: 31.64556962025316,
  },
  {
    name: 'American Village Dog',
    weight: 30,
    total: 32,
    XS: 0,
    S: 18.75,
    M: 71.875,
    L: 9.375,
    XL: 0,
  },
  {
    name: 'American Village Dog',
    weight: 40,
    total: 49,
    XS: 0,
    S: 6.12244897959184,
    M: 79.59183673469389,
    L: 14.28571428571429,
    XL: 0,
  },
  {
    name: 'American Village Dog',
    weight: 50,
    total: 48,
    XS: 0,
    S: 2.08333333333333,
    M: 70.83333333333333,
    L: 27.08333333333333,
    XL: 0,
  },
  {
    name: 'American White Shepherd',
    weight: 70,
    total: 27,
    XS: 0,
    S: 0,
    M: 18.51851851851852,
    L: 70.37037037037037,
    XL: 11.11111111111111,
  },
  {
    name: 'American White Shepherd',
    weight: 80,
    total: 28,
    XS: 0,
    S: 0,
    M: 25,
    L: 64.28571428571429,
    XL: 10.71428571428571,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 70,
    total: 45,
    XS: 0,
    S: 2.22222222222222,
    M: 13.33333333333333,
    L: 60,
    XL: 24.44444444444444,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 80,
    total: 69,
    XS: 0,
    S: 1.44927536231884,
    M: 17.39130434782609,
    L: 46.3768115942029,
    XL: 34.78260869565217,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 90,
    total: 75,
    XS: 0,
    S: 1.33333333333333,
    M: 18.66666666666667,
    L: 40,
    XL: 40,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 100,
    total: 76,
    XS: 0,
    S: 1.31578947368421,
    M: 17.10526315789474,
    L: 34.21052631578947,
    XL: 47.36842105263158,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 110,
    total: 39,
    XS: 0,
    S: 0,
    M: 12.82051282051282,
    L: 28.20512820512821,
    XL: 58.97435897435897,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 120,
    total: 33,
    XS: 0,
    S: 0,
    M: 12.12121212121212,
    L: 21.21212121212121,
    XL: 66.66666666666667,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 50,
    total: 30,
    XS: 0,
    S: 0,
    M: 40,
    L: 53.33333333333333,
    XL: 6.66666666666667,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 60,
    total: 31,
    XS: 0,
    S: 3.2258064516129,
    M: 41.93548387096774,
    L: 45.16129032258065,
    XL: 9.67741935483871,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 70,
    total: 57,
    XS: 0,
    S: 3.50877192982456,
    M: 24.56140350877193,
    L: 59.64912280701754,
    XL: 12.28070175438596,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 80,
    total: 69,
    XS: 1.44927536231884,
    S: 0,
    M: 13.04347826086957,
    L: 69.56521739130434,
    XL: 15.94202898550725,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 90,
    total: 77,
    XS: 0,
    S: 0,
    M: 19.48051948051948,
    L: 54.54545454545455,
    XL: 25.97402597402597,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 100,
    total: 100,
    XS: 0,
    S: 2,
    M: 13,
    L: 42,
    XL: 43,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 110,
    total: 64,
    XS: 0,
    S: 1.5625,
    M: 7.8125,
    L: 37.5,
    XL: 53.125,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 120,
    total: 73,
    XS: 0,
    S: 0,
    M: 12.32876712328767,
    L: 19.17808219178082,
    XL: 68.4931506849315,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 130,
    total: 41,
    XS: 0,
    S: 0,
    M: 12.19512195121951,
    L: 12.19512195121951,
    XL: 75.60975609756098,
  },
  {
    name: 'Anatolian Shepherd Dog',
    weight: 70,
    total: 39,
    XS: 0,
    S: 2.56410256410256,
    M: 23.07692307692308,
    L: 69.23076923076923,
    XL: 5.12820512820513,
  },
  {
    name: 'Anatolian Shepherd Dog',
    weight: 80,
    total: 58,
    XS: 0,
    S: 0,
    M: 25.86206896551724,
    L: 60.3448275862069,
    XL: 13.79310344827586,
  },
  {
    name: 'Anatolian Shepherd Dog',
    weight: 90,
    total: 38,
    XS: 0,
    S: 0,
    M: 21.05263157894737,
    L: 44.73684210526316,
    XL: 34.21052631578947,
  },
  {
    name: 'Anatolian Shepherd Dog',
    weight: 100,
    total: 59,
    XS: 0,
    S: 1.69491525423729,
    M: 20.33898305084746,
    L: 38.98305084745763,
    XL: 38.98305084745763,
  },
  {
    name: 'Anatolian Shepherd Dog',
    weight: 110,
    total: 40,
    XS: 0,
    S: 0,
    M: 10,
    L: 17.5,
    XL: 72.5,
  },
  {
    name: 'Anatolian Shepherd Dog',
    weight: 120,
    total: 33,
    XS: 0,
    S: 0,
    M: 27.27272727272727,
    L: 24.24242424242424,
    XL: 48.48484848484848,
  },
  {
    name: 'Anatolian Shepherd Dog',
    weight: 130,
    total: 33,
    XS: 0,
    S: 0,
    M: 21.21212121212121,
    L: 18.18181818181818,
    XL: 60.60606060606061,
  },
  {
    name: 'Andalusian Podenco',
    weight: 40,
    total: 29,
    XS: 0,
    S: 20.68965517241379,
    M: 55.17241379310345,
    L: 24.13793103448276,
    XL: 0,
  },
  {
    name: 'Arubian Cunucu Dog',
    weight: 50,
    total: 34,
    XS: 0,
    S: 0,
    M: 70.58823529411765,
    L: 29.41176470588235,
    XL: 0,
  },
  {
    name: 'Aussie-Corgi',
    weight: 20,
    total: 57,
    XS: 5.26315789473684,
    S: 43.85964912280702,
    M: 47.36842105263158,
    L: 3.50877192982456,
    XL: 0,
  },
  {
    name: 'Aussie-Corgi',
    weight: 30,
    total: 83,
    XS: 1.20481927710843,
    S: 13.25301204819277,
    M: 81.92771084337349,
    L: 3.6144578313253,
    XL: 0,
  },
  {
    name: 'Aussie-Corgi',
    weight: 40,
    total: 49,
    XS: 2.04081632653061,
    S: 6.12244897959184,
    M: 75.51020408163265,
    L: 14.28571428571429,
    XL: 2.04081632653061,
  },
  {
    name: 'Aussiedoodle',
    weight: 10,
    total: 72,
    XS: 26.38888888888889,
    S: 54.16666666666667,
    M: 15.27777777777778,
    L: 4.16666666666667,
    XL: 0,
  },
  {
    name: 'Aussiedoodle',
    weight: 20,
    total: 362,
    XS: 6.62983425414365,
    S: 63.8121546961326,
    M: 27.34806629834254,
    L: 2.20994475138122,
    XL: 0,
  },
  {
    name: 'Aussiedoodle',
    weight: 30,
    total: 424,
    XS: 1.17924528301887,
    S: 30.42452830188679,
    M: 63.91509433962264,
    L: 4.24528301886792,
    XL: 0.23584905660377,
  },
  {
    name: 'Aussiedoodle',
    weight: 40,
    total: 393,
    XS: 0.76335877862595,
    S: 6.36132315521628,
    M: 79.1348600508906,
    L: 13.74045801526718,
    XL: 0,
  },
  {
    name: 'Aussiedoodle',
    weight: 50,
    total: 438,
    XS: 0,
    S: 2.96803652968037,
    M: 62.78538812785388,
    L: 33.78995433789954,
    XL: 0.45662100456621,
  },
  {
    name: 'Aussiedoodle',
    weight: 60,
    total: 291,
    XS: 0,
    S: 1.71821305841924,
    M: 47.76632302405498,
    L: 50.17182130584192,
    XL: 0.34364261168385,
  },
  {
    name: 'Aussiedoodle',
    weight: 70,
    total: 130,
    XS: 0,
    S: 1.53846153846154,
    M: 36.15384615384615,
    L: 60.76923076923077,
    XL: 1.53846153846154,
  },
  {
    name: 'Aussiedoodle',
    weight: 80,
    total: 41,
    XS: 0,
    S: 0,
    M: 31.70731707317073,
    L: 65.85365853658537,
    XL: 2.4390243902439,
  },
  {
    name: 'Aussiedor',
    weight: 60,
    total: 28,
    XS: 0,
    S: 0,
    M: 35.71428571428571,
    L: 64.28571428571429,
    XL: 0,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 10,
    total: 70,
    XS: 21.42857142857143,
    S: 40,
    M: 34.28571428571429,
    L: 4.28571428571429,
    XL: 0,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 20,
    total: 344,
    XS: 3.77906976744186,
    S: 36.04651162790698,
    M: 52.90697674418605,
    L: 6.68604651162791,
    XL: 0.58139534883721,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 30,
    total: 1158,
    XS: 0.43177892918826,
    S: 13.81692573402418,
    M: 77.0293609671848,
    L: 8.20379965457686,
    XL: 0.51813471502591,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 40,
    total: 2496,
    XS: 0.12019230769231,
    S: 3.44551282051282,
    M: 78.28525641025641,
    L: 17.90865384615385,
    XL: 0.24038461538462,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 50,
    total: 2823,
    XS: 0.17711654268509,
    S: 1.70031880977683,
    M: 62.20332979100248,
    L: 35.38788522848034,
    XL: 0.53134962805526,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 60,
    total: 1366,
    XS: 0.29282576866764,
    S: 0.80527086383602,
    M: 42.89897510980966,
    L: 54.83162518301611,
    XL: 1.17130307467057,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 70,
    total: 551,
    XS: 0,
    S: 1.08892921960073,
    M: 27.22323049001815,
    L: 69.3284936479129,
    XL: 2.35934664246824,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 80,
    total: 209,
    XS: 0,
    S: 0.47846889952153,
    M: 22.00956937799043,
    L: 71.29186602870813,
    XL: 6.2200956937799,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 90,
    total: 48,
    XS: 0,
    S: 2.08333333333333,
    M: 10.41666666666667,
    L: 68.75,
    XL: 18.75,
  },
  {
    name: 'Australian Kelpie',
    weight: 30,
    total: 71,
    XS: 1.40845070422535,
    S: 15.49295774647887,
    M: 70.42253521126761,
    L: 11.26760563380282,
    XL: 1.40845070422535,
  },
  {
    name: 'Australian Kelpie',
    weight: 40,
    total: 143,
    XS: 0,
    S: 2.0979020979021,
    M: 81.81818181818181,
    L: 14.68531468531469,
    XL: 1.3986013986014,
  },
  {
    name: 'Australian Kelpie',
    weight: 50,
    total: 173,
    XS: 0,
    S: 1.15606936416185,
    M: 63.00578034682081,
    L: 35.83815028901734,
    XL: 0,
  },
  {
    name: 'Australian Kelpie',
    weight: 60,
    total: 76,
    XS: 0,
    S: 1.31578947368421,
    M: 47.36842105263158,
    L: 48.68421052631579,
    XL: 2.63157894736842,
  },
  {
    name: 'Australian Kelpie',
    weight: 70,
    total: 31,
    XS: 0,
    S: 0,
    M: 25.80645161290323,
    L: 74.19354838709677,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 10,
    total: 43,
    XS: 11.62790697674419,
    S: 48.83720930232558,
    M: 37.2093023255814,
    L: 2.32558139534884,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 20,
    total: 283,
    XS: 4.59363957597173,
    S: 56.8904593639576,
    M: 36.04240282685512,
    L: 2.47349823321555,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 30,
    total: 677,
    XS: 0.73855243722304,
    S: 25.40620384047267,
    M: 70.90103397341211,
    L: 2.65878877400295,
    XL: 0.29542097488922,
  },
  {
    name: 'Australian Labradoodle',
    weight: 40,
    total: 514,
    XS: 0,
    S: 4.86381322957198,
    M: 82.8793774319066,
    L: 12.2568093385214,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 50,
    total: 274,
    XS: 0.36496350364964,
    S: 2.18978102189781,
    M: 63.5036496350365,
    L: 33.94160583941606,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 60,
    total: 117,
    XS: 0.85470085470085,
    S: 4.27350427350427,
    M: 38.46153846153846,
    L: 56.41025641025641,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 70,
    total: 63,
    XS: 0,
    S: 1.58730158730159,
    M: 25.3968253968254,
    L: 73.01587301587303,
    XL: 0,
  },
  {
    name: 'Australian Shepherd',
    weight: 10,
    total: 165,
    XS: 15.15151515151515,
    S: 50.3030303030303,
    M: 30.3030303030303,
    L: 4.24242424242424,
    XL: 0,
  },
  {
    name: 'Australian Shepherd',
    weight: 20,
    total: 843,
    XS: 3.55871886120996,
    S: 47.09371293001186,
    M: 42.70462633451957,
    L: 6.40569395017794,
    XL: 0.23724792408066,
  },
  {
    name: 'Australian Shepherd',
    weight: 30,
    total: 1578,
    XS: 0.69708491761724,
    S: 21.1660329531052,
    M: 70.53231939163499,
    L: 7.3510773130545,
    XL: 0.25348542458809,
  },
  {
    name: 'Australian Shepherd',
    weight: 40,
    total: 2953,
    XS: 0,
    S: 5.24889942431426,
    M: 81.30714527599052,
    L: 13.0037250253979,
    XL: 0.44023027429732,
  },
  {
    name: 'Australian Shepherd',
    weight: 50,
    total: 4305,
    XS: 0.16260162601626,
    S: 1.92799070847851,
    M: 71.24274099883856,
    L: 26.27177700348432,
    XL: 0.39488966318235,
  },
  {
    name: 'Australian Shepherd',
    weight: 60,
    total: 2651,
    XS: 0.0377216144851,
    S: 1.6220294228593,
    M: 51.37683892870615,
    L: 45.90720482836665,
    XL: 1.0562052055828,
  },
  {
    name: 'Australian Shepherd',
    weight: 70,
    total: 1154,
    XS: 0,
    S: 0.77989601386482,
    M: 38.38821490467938,
    L: 58.14558058925477,
    XL: 2.68630849220104,
  },
  {
    name: 'Australian Shepherd',
    weight: 80,
    total: 351,
    XS: 0,
    S: 0.56980056980057,
    M: 26.78062678062678,
    L: 67.52136752136752,
    XL: 5.12820512820513,
  },
  {
    name: 'Australian Shepherd',
    weight: 90,
    total: 110,
    XS: 0,
    S: 0.90909090909091,
    M: 17.27272727272727,
    L: 73.63636363636364,
    XL: 8.18181818181818,
  },
  {
    name: 'Australian Shepherd',
    weight: 100,
    total: 31,
    XS: 0,
    S: 0,
    M: 25.80645161290323,
    L: 54.83870967741935,
    XL: 19.35483870967742,
  },
  {
    name: 'Australian Stumpy Tail Cattle Dog',
    weight: 30,
    total: 39,
    XS: 0,
    S: 10.25641025641026,
    M: 79.48717948717949,
    L: 7.69230769230769,
    XL: 2.56410256410256,
  },
  {
    name: 'Australian Stumpy Tail Cattle Dog',
    weight: 40,
    total: 50,
    XS: 0,
    S: 2,
    M: 82,
    L: 16,
    XL: 0,
  },
  {
    name: 'Australian Stumpy Tail Cattle Dog',
    weight: 50,
    total: 37,
    XS: 0,
    S: 0,
    M: 54.05405405405405,
    L: 45.94594594594595,
    XL: 0,
  },
  {
    name: 'Australian Terrier',
    weight: 20,
    total: 45,
    XS: 0,
    S: 62.22222222222222,
    M: 37.77777777777778,
    L: 0,
    XL: 0,
  },
  {
    name: 'Australian Terrier',
    weight: 30,
    total: 35,
    XS: 0,
    S: 25.71428571428571,
    M: 68.57142857142857,
    L: 5.71428571428571,
    XL: 0,
  },
  {
    name: 'Australian Yorkshire Terrier',
    weight: 20,
    total: 26,
    XS: 0,
    S: 73.07692307692308,
    M: 19.23076923076923,
    L: 7.69230769230769,
    XL: 0,
  },
  {
    name: 'Bagle Hound',
    weight: 30,
    total: 27,
    XS: 0,
    S: 18.51851851851852,
    M: 74.07407407407408,
    L: 7.40740740740741,
    XL: 0,
  },
  {
    name: 'Bagle Hound',
    weight: 40,
    total: 40,
    XS: 0,
    S: 5,
    M: 80,
    L: 15,
    XL: 0,
  },
  {
    name: 'Bagle Hound',
    weight: 50,
    total: 33,
    XS: 0,
    S: 0,
    M: 60.60606060606061,
    L: 36.36363636363636,
    XL: 3.03030303030303,
  },
  {
    name: 'Bagle Hound',
    weight: 60,
    total: 26,
    XS: 0,
    S: 0,
    M: 50,
    L: 50,
    XL: 0,
  },
  {
    name: 'Basenji',
    weight: 20,
    total: 136,
    XS: 2.94117647058824,
    S: 59.55882352941176,
    M: 34.55882352941176,
    L: 2.94117647058824,
    XL: 0,
  },
  {
    name: 'Basenji',
    weight: 30,
    total: 271,
    XS: 0.3690036900369,
    S: 28.41328413284133,
    M: 68.63468634686348,
    L: 2.5830258302583,
    XL: 0,
  },
  {
    name: 'Basenji',
    weight: 40,
    total: 124,
    XS: 0.80645161290323,
    S: 0.80645161290323,
    M: 85.48387096774194,
    L: 12.09677419354839,
    XL: 0.80645161290323,
  },
  {
    name: 'Basenji',
    weight: 50,
    total: 65,
    XS: 0,
    S: 0,
    M: 61.53846153846154,
    L: 38.46153846153846,
    XL: 0,
  },
  {
    name: 'Basset Hound',
    weight: 20,
    total: 32,
    XS: 6.25,
    S: 18.75,
    M: 62.5,
    L: 9.375,
    XL: 3.125,
  },
  {
    name: 'Basset Hound',
    weight: 30,
    total: 98,
    XS: 0,
    S: 10.20408163265306,
    M: 66.3265306122449,
    L: 22.44897959183673,
    XL: 1.02040816326531,
  },
  {
    name: 'Basset Hound',
    weight: 40,
    total: 247,
    XS: 0,
    S: 2.02429149797571,
    M: 61.53846153846154,
    L: 34.41295546558704,
    XL: 2.02429149797571,
  },
  {
    name: 'Basset Hound',
    weight: 50,
    total: 418,
    XS: 0.23923444976077,
    S: 1.67464114832536,
    M: 40.43062200956938,
    L: 54.06698564593301,
    XL: 3.58851674641148,
  },
  {
    name: 'Basset Hound',
    weight: 60,
    total: 283,
    XS: 0,
    S: 1.06007067137809,
    M: 24.73498233215548,
    L: 69.6113074204947,
    XL: 4.59363957597173,
  },
  {
    name: 'Basset Hound',
    weight: 70,
    total: 145,
    XS: 0,
    S: 0,
    M: 17.24137931034483,
    L: 74.48275862068967,
    XL: 8.27586206896552,
  },
  {
    name: 'Basset Hound',
    weight: 80,
    total: 48,
    XS: 0,
    S: 2.08333333333333,
    M: 8.33333333333333,
    L: 72.91666666666667,
    XL: 16.66666666666667,
  },
  {
    name: 'Beagle',
    weight: 10,
    total: 89,
    XS: 16.85393258426966,
    S: 38.20224719101124,
    M: 40.44943820224719,
    L: 4.49438202247191,
    XL: 0,
  },
  {
    name: 'Beagle',
    weight: 20,
    total: 1422,
    XS: 2.53164556962025,
    S: 41.56118143459916,
    M: 51.33614627285513,
    L: 4.28973277074543,
    XL: 0.28129395218003,
  },
  {
    name: 'Beagle',
    weight: 30,
    total: 3912,
    XS: 0.25562372188139,
    S: 12.67893660531697,
    M: 81.4161554192229,
    L: 5.34253578732106,
    XL: 0.30674846625767,
  },
  {
    name: 'Beagle',
    weight: 40,
    total: 2273,
    XS: 0.08798944126705,
    S: 2.85965684117906,
    M: 82.7540695116586,
    L: 14.07831060272767,
    XL: 0.21997360316762,
  },
  {
    name: 'Beagle',
    weight: 50,
    total: 974,
    XS: 0,
    S: 1.54004106776181,
    M: 68.37782340862422,
    L: 29.36344969199179,
    XL: 0.71868583162218,
  },
  {
    name: 'Beagle',
    weight: 60,
    total: 291,
    XS: 0,
    S: 0.6872852233677,
    M: 41.5807560137457,
    L: 56.70103092783505,
    XL: 1.03092783505155,
  },
  {
    name: 'Beagle',
    weight: 70,
    total: 118,
    XS: 0,
    S: 1.69491525423729,
    M: 25.42372881355932,
    L: 70.33898305084746,
    XL: 2.54237288135593,
  },
  {
    name: 'Beagle',
    weight: 80,
    total: 37,
    XS: 0,
    S: 0,
    M: 16.21621621621622,
    L: 75.67567567567568,
    XL: 8.10810810810811,
  },
  {
    name: 'Bearded Collie',
    weight: 50,
    total: 39,
    XS: 0,
    S: 0,
    M: 43.58974358974359,
    L: 56.41025641025641,
    XL: 0,
  },
  {
    name: 'Beauceron',
    weight: 80,
    total: 39,
    XS: 0,
    S: 2.56410256410256,
    M: 17.94871794871795,
    L: 71.7948717948718,
    XL: 7.69230769230769,
  },
  {
    name: 'Bedlington Terrier',
    weight: 20,
    total: 35,
    XS: 5.71428571428571,
    S: 57.14285714285714,
    M: 34.28571428571429,
    L: 0,
    XL: 2.85714285714286,
  },
  {
    name: 'Belgian Malinois',
    weight: 10,
    total: 47,
    XS: 10.63829787234043,
    S: 21.27659574468085,
    M: 46.80851063829787,
    L: 19.14893617021277,
    XL: 2.12765957446809,
  },
  {
    name: 'Belgian Malinois',
    weight: 20,
    total: 119,
    XS: 0,
    S: 24.36974789915966,
    M: 44.53781512605042,
    L: 29.41176470588235,
    XL: 1.68067226890756,
  },
  {
    name: 'Belgian Malinois',
    weight: 30,
    total: 198,
    XS: 0,
    S: 19.19191919191919,
    M: 44.94949494949495,
    L: 34.34343434343434,
    XL: 1.51515151515152,
  },
  {
    name: 'Belgian Malinois',
    weight: 40,
    total: 367,
    XS: 1.63487738419619,
    S: 8.99182561307902,
    M: 55.858310626703,
    L: 31.33514986376022,
    XL: 2.17983651226158,
  },
  {
    name: 'Belgian Malinois',
    weight: 50,
    total: 961,
    XS: 0.10405827263267,
    S: 5.51508844953174,
    M: 53.38189386056191,
    L: 39.75026014568158,
    XL: 1.24869927159209,
  },
  {
    name: 'Belgian Malinois',
    weight: 60,
    total: 1359,
    XS: 0,
    S: 3.82634289919058,
    M: 39.22001471670346,
    L: 55.62913907284768,
    XL: 1.32450331125828,
  },
  {
    name: 'Belgian Malinois',
    weight: 70,
    total: 1383,
    XS: 0.43383947939262,
    S: 3.18148951554591,
    M: 29.79031091829356,
    L: 64.28054953000724,
    XL: 2.31381055676067,
  },
  {
    name: 'Belgian Malinois',
    weight: 80,
    total: 763,
    XS: 0.13106159895151,
    S: 2.88335517693316,
    M: 22.28047182175623,
    L: 70.64220183486239,
    XL: 4.06290956749672,
  },
  {
    name: 'Belgian Malinois',
    weight: 90,
    total: 265,
    XS: 0.75471698113208,
    S: 1.13207547169811,
    M: 21.88679245283019,
    L: 68.67924528301887,
    XL: 7.54716981132075,
  },
  {
    name: 'Belgian Malinois',
    weight: 100,
    total: 96,
    XS: 0,
    S: 2.08333333333333,
    M: 26.04166666666667,
    L: 59.375,
    XL: 12.5,
  },
  {
    name: 'Belgian Sheepdog',
    weight: 50,
    total: 28,
    XS: 0,
    S: 3.57142857142857,
    M: 53.57142857142857,
    L: 42.85714285714286,
    XL: 0,
  },
  {
    name: 'Belgian Sheepdog',
    weight: 60,
    total: 28,
    XS: 3.57142857142857,
    S: 0,
    M: 32.14285714285714,
    L: 64.28571428571429,
    XL: 0,
  },
  {
    name: 'Belgian Tervuren',
    weight: 50,
    total: 70,
    XS: 0,
    S: 0,
    M: 55.71428571428571,
    L: 44.28571428571429,
    XL: 0,
  },
  {
    name: 'Belgian Tervuren',
    weight: 60,
    total: 48,
    XS: 0,
    S: 2.08333333333333,
    M: 43.75,
    L: 47.91666666666667,
    XL: 6.25,
  },
  {
    name: 'Belgian Tervuren',
    weight: 70,
    total: 37,
    XS: 0,
    S: 0,
    M: 24.32432432432432,
    L: 72.97297297297297,
    XL: 2.7027027027027,
  },
  {
    name: 'Berger Picard',
    weight: 60,
    total: 30,
    XS: 0,
    S: 0,
    M: 26.66666666666667,
    L: 66.66666666666667,
    XL: 6.66666666666667,
  },
  {
    name: 'Bernedoodle',
    weight: 10,
    total: 67,
    XS: 7.46268656716418,
    S: 44.77611940298507,
    M: 40.29850746268657,
    L: 7.46268656716418,
    XL: 0,
  },
  {
    name: 'Bernedoodle',
    weight: 20,
    total: 286,
    XS: 4.8951048951049,
    S: 55.59440559440559,
    M: 35.66433566433566,
    L: 3.14685314685315,
    XL: 0.6993006993007,
  },
  {
    name: 'Bernedoodle',
    weight: 30,
    total: 564,
    XS: 1.41843971631206,
    S: 23.40425531914894,
    M: 68.43971631205675,
    L: 6.73758865248227,
    XL: 0,
  },
  {
    name: 'Bernedoodle',
    weight: 40,
    total: 675,
    XS: 0.2962962962963,
    S: 11.40740740740741,
    M: 73.77777777777779,
    L: 14.22222222222222,
    XL: 0.2962962962963,
  },
  {
    name: 'Bernedoodle',
    weight: 50,
    total: 514,
    XS: 0.38910505836576,
    S: 4.28015564202335,
    M: 60.11673151750973,
    L: 34.43579766536965,
    XL: 0.77821011673152,
  },
  {
    name: 'Bernedoodle',
    weight: 60,
    total: 461,
    XS: 0,
    S: 3.90455531453362,
    M: 36.8763557483731,
    L: 57.9175704989154,
    XL: 1.30151843817787,
  },
  {
    name: 'Bernedoodle',
    weight: 70,
    total: 468,
    XS: 0,
    S: 2.35042735042735,
    M: 25.42735042735043,
    L: 69.87179487179488,
    XL: 2.35042735042735,
  },
  {
    name: 'Bernedoodle',
    weight: 80,
    total: 371,
    XS: 0,
    S: 1.61725067385445,
    M: 18.59838274932615,
    L: 71.69811320754717,
    XL: 8.08625336927224,
  },
  {
    name: 'Bernedoodle',
    weight: 90,
    total: 209,
    XS: 0.47846889952153,
    S: 1.91387559808612,
    M: 16.26794258373206,
    L: 68.42105263157895,
    XL: 12.91866028708134,
  },
  {
    name: 'Bernedoodle',
    weight: 100,
    total: 109,
    XS: 0,
    S: 1.8348623853211,
    M: 23.85321100917431,
    L: 53.21100917431193,
    XL: 21.10091743119266,
  },
  {
    name: 'Bernedoodle',
    weight: 110,
    total: 29,
    XS: 0,
    S: 0,
    M: 17.24137931034483,
    L: 44.82758620689655,
    XL: 37.93103448275862,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 20,
    total: 38,
    XS: 5.26315789473684,
    S: 23.68421052631579,
    M: 39.47368421052632,
    L: 21.05263157894737,
    XL: 10.52631578947368,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 30,
    total: 73,
    XS: 1.36986301369863,
    S: 23.28767123287671,
    M: 49.31506849315068,
    L: 21.91780821917808,
    XL: 4.10958904109589,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 40,
    total: 64,
    XS: 0,
    S: 6.25,
    M: 62.5,
    L: 28.125,
    XL: 3.125,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 50,
    total: 109,
    XS: 0.91743119266055,
    S: 3.6697247706422,
    M: 41.28440366972477,
    L: 47.70642201834862,
    XL: 6.42201834862385,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 60,
    total: 127,
    XS: 0.78740157480315,
    S: 1.5748031496063,
    M: 27.55905511811024,
    L: 59.05511811023622,
    XL: 11.02362204724409,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 70,
    total: 194,
    XS: 0,
    S: 2.06185567010309,
    M: 21.1340206185567,
    L: 58.76288659793814,
    XL: 18.04123711340206,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 80,
    total: 302,
    XS: 0,
    S: 1.65562913907285,
    M: 15.89403973509934,
    L: 60.26490066225166,
    XL: 22.18543046357616,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 90,
    total: 347,
    XS: 0.28818443804035,
    S: 0.57636887608069,
    M: 15.85014409221902,
    L: 53.02593659942363,
    XL: 30.25936599423631,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 100,
    total: 313,
    XS: 0.31948881789137,
    S: 0.63897763578275,
    M: 12.77955271565495,
    L: 30.35143769968051,
    XL: 55.91054313099042,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 110,
    total: 188,
    XS: 0,
    S: 0,
    M: 16.48936170212766,
    L: 24.46808510638298,
    XL: 59.04255319148936,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 120,
    total: 126,
    XS: 0,
    S: 0.79365079365079,
    M: 14.28571428571429,
    L: 13.49206349206349,
    XL: 71.42857142857143,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 130,
    total: 65,
    XS: 0,
    S: 0,
    M: 20,
    L: 10.76923076923077,
    XL: 69.23076923076923,
  },
  {
    name: 'Bich-poo',
    weight: 10,
    total: 32,
    XS: 50,
    S: 43.75,
    M: 6.25,
    L: 0,
    XL: 0,
  },
  {
    name: 'Bich-poo',
    weight: 20,
    total: 74,
    XS: 17.56756756756757,
    S: 59.45945945945946,
    M: 20.27027027027027,
    L: 2.7027027027027,
    XL: 0,
  },
  {
    name: 'Bichon Frise',
    weight: 10,
    total: 73,
    XS: 23.28767123287671,
    S: 60.27397260273973,
    M: 12.32876712328767,
    L: 1.36986301369863,
    XL: 2.73972602739726,
  },
  {
    name: 'Bichon Frise',
    weight: 20,
    total: 385,
    XS: 5.19480519480519,
    S: 68.83116883116882,
    M: 24.41558441558442,
    L: 1.2987012987013,
    XL: 0.25974025974026,
  },
  {
    name: 'Bichon Frise',
    weight: 30,
    total: 64,
    XS: 0,
    S: 23.4375,
    M: 75,
    L: 1.5625,
    XL: 0,
  },
  {
    name: 'Bichon/Yorkie',
    weight: 10,
    total: 58,
    XS: 63.79310344827586,
    S: 34.48275862068966,
    M: 0,
    L: 0,
    XL: 1.72413793103448,
  },
  {
    name: 'Bichon/Yorkie',
    weight: 20,
    total: 58,
    XS: 17.24137931034483,
    S: 60.3448275862069,
    M: 22.41379310344828,
    L: 0,
    XL: 0,
  },
  {
    name: 'Biewer',
    weight: 10,
    total: 26,
    XS: 84.61538461538461,
    S: 11.53846153846154,
    M: 3.84615384615385,
    L: 0,
    XL: 0,
  },
  {
    name: 'Black Mouth Cur',
    weight: 30,
    total: 40,
    XS: 0,
    S: 10,
    M: 80,
    L: 10,
    XL: 0,
  },
  {
    name: 'Black Mouth Cur',
    weight: 40,
    total: 150,
    XS: 0,
    S: 2,
    M: 70,
    L: 28,
    XL: 0,
  },
  {
    name: 'Black Mouth Cur',
    weight: 50,
    total: 324,
    XS: 0,
    S: 0.30864197530864,
    M: 56.17283950617284,
    L: 43.20987654320988,
    XL: 0.30864197530864,
  },
  {
    name: 'Black Mouth Cur',
    weight: 60,
    total: 321,
    XS: 0,
    S: 0.62305295950156,
    M: 32.398753894081,
    L: 65.73208722741433,
    XL: 1.24610591900312,
  },
  {
    name: 'Black Mouth Cur',
    weight: 70,
    total: 246,
    XS: 0,
    S: 0.8130081300813,
    M: 23.98373983739837,
    L: 71.95121951219512,
    XL: 3.2520325203252,
  },
  {
    name: 'Black Mouth Cur',
    weight: 80,
    total: 120,
    XS: 0,
    S: 0.83333333333333,
    M: 18.33333333333333,
    L: 76.66666666666667,
    XL: 4.16666666666667,
  },
  {
    name: 'Black Mouth Cur',
    weight: 90,
    total: 37,
    XS: 0,
    S: 0,
    M: 16.21621621621622,
    L: 70.27027027027027,
    XL: 13.51351351351351,
  },
  {
    name: 'Black and Tan Coonhound',
    weight: 40,
    total: 65,
    XS: 0,
    S: 4.61538461538462,
    M: 75.38461538461539,
    L: 20,
    XL: 0,
  },
  {
    name: 'Black and Tan Coonhound',
    weight: 50,
    total: 148,
    XS: 0,
    S: 0,
    M: 63.51351351351351,
    L: 34.45945945945946,
    XL: 2.02702702702703,
  },
  {
    name: 'Black and Tan Coonhound',
    weight: 60,
    total: 186,
    XS: 0,
    S: 1.0752688172043,
    M: 36.02150537634409,
    L: 60.75268817204301,
    XL: 2.1505376344086,
  },
  {
    name: 'Black and Tan Coonhound',
    weight: 70,
    total: 145,
    XS: 0,
    S: 0,
    M: 24.82758620689655,
    L: 73.10344827586206,
    XL: 2.06896551724138,
  },
  {
    name: 'Black and Tan Coonhound',
    weight: 80,
    total: 98,
    XS: 0,
    S: 1.02040816326531,
    M: 21.42857142857143,
    L: 67.3469387755102,
    XL: 10.20408163265306,
  },
  {
    name: 'Black and Tan Coonhound',
    weight: 90,
    total: 44,
    XS: 0,
    S: 0,
    M: 18.18181818181818,
    L: 70.45454545454545,
    XL: 11.36363636363636,
  },
  {
    name: 'Bloodhound',
    weight: 30,
    total: 29,
    XS: 0,
    S: 20.68965517241379,
    M: 24.13793103448276,
    L: 31.03448275862069,
    XL: 24.13793103448276,
  },
  {
    name: 'Bloodhound',
    weight: 40,
    total: 28,
    XS: 0,
    S: 7.14285714285714,
    M: 46.42857142857143,
    L: 25,
    XL: 21.42857142857143,
  },
  {
    name: 'Bloodhound',
    weight: 50,
    total: 46,
    XS: 0,
    S: 2.17391304347826,
    M: 45.65217391304348,
    L: 50,
    XL: 2.17391304347826,
  },
  {
    name: 'Bloodhound',
    weight: 60,
    total: 91,
    XS: 0,
    S: 0,
    M: 27.47252747252747,
    L: 61.53846153846154,
    XL: 10.98901098901099,
  },
  {
    name: 'Bloodhound',
    weight: 70,
    total: 137,
    XS: 0.72992700729927,
    S: 0.72992700729927,
    M: 24.08759124087591,
    L: 57.66423357664234,
    XL: 16.78832116788321,
  },
  {
    name: 'Bloodhound',
    weight: 80,
    total: 170,
    XS: 0,
    S: 0.58823529411765,
    M: 18.82352941176471,
    L: 62.35294117647059,
    XL: 18.23529411764706,
  },
  {
    name: 'Bloodhound',
    weight: 90,
    total: 154,
    XS: 0.64935064935065,
    S: 0,
    M: 20.77922077922078,
    L: 50.64935064935065,
    XL: 27.92207792207792,
  },
  {
    name: 'Bloodhound',
    weight: 100,
    total: 160,
    XS: 0,
    S: 0,
    M: 11.875,
    L: 39.375,
    XL: 48.75,
  },
  {
    name: 'Bloodhound',
    weight: 110,
    total: 94,
    XS: 0,
    S: 0,
    M: 13.82978723404255,
    L: 26.59574468085106,
    XL: 59.57446808510638,
  },
  {
    name: 'Bloodhound',
    weight: 120,
    total: 62,
    XS: 0,
    S: 0,
    M: 17.74193548387097,
    L: 24.19354838709677,
    XL: 58.06451612903226,
  },
  {
    name: 'Bloodhound',
    weight: 130,
    total: 43,
    XS: 0,
    S: 0,
    M: 27.90697674418605,
    L: 16.27906976744186,
    XL: 55.81395348837209,
  },
  {
    name: 'Blue Lacy',
    weight: 40,
    total: 38,
    XS: 2.63157894736842,
    S: 0,
    M: 81.57894736842105,
    L: 13.15789473684211,
    XL: 2.63157894736842,
  },
  {
    name: 'Blue Lacy',
    weight: 50,
    total: 58,
    XS: 0,
    S: 0,
    M: 62.06896551724138,
    L: 37.93103448275862,
    XL: 0,
  },
  {
    name: 'Blue Lacy',
    weight: 60,
    total: 30,
    XS: 0,
    S: 0,
    M: 36.66666666666667,
    L: 60,
    XL: 3.33333333333333,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 30,
    total: 32,
    XS: 0,
    S: 25,
    M: 62.5,
    L: 12.5,
    XL: 0,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 40,
    total: 83,
    XS: 0,
    S: 7.2289156626506,
    M: 73.49397590361446,
    L: 19.27710843373494,
    XL: 0,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 50,
    total: 208,
    XS: 0,
    S: 3.36538461538462,
    M: 51.92307692307692,
    L: 43.75,
    XL: 0.96153846153846,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 60,
    total: 222,
    XS: 0,
    S: 0.9009009009009,
    M: 42.34234234234234,
    L: 56.30630630630631,
    XL: 0.45045045045045,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 70,
    total: 216,
    XS: 0,
    S: 0.92592592592593,
    M: 25.92592592592593,
    L: 71.75925925925927,
    XL: 1.38888888888889,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 80,
    total: 156,
    XS: 0,
    S: 2.56410256410256,
    M: 24.35897435897436,
    L: 67.94871794871796,
    XL: 5.12820512820513,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 90,
    total: 75,
    XS: 0,
    S: 0,
    M: 21.33333333333333,
    L: 64,
    XL: 14.66666666666667,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 100,
    total: 37,
    XS: 0,
    S: 2.7027027027027,
    M: 13.51351351351351,
    L: 62.16216216216216,
    XL: 21.62162162162162,
  },
  {
    name: 'Borador',
    weight: 50,
    total: 29,
    XS: 0,
    S: 0,
    M: 48.27586206896552,
    L: 51.72413793103448,
    XL: 0,
  },
  {
    name: 'Border Beagle',
    weight: 40,
    total: 29,
    XS: 0,
    S: 3.44827586206897,
    M: 82.75862068965517,
    L: 13.79310344827586,
    XL: 0,
  },
  {
    name: 'Border Collie',
    weight: 10,
    total: 58,
    XS: 17.24137931034483,
    S: 17.24137931034483,
    M: 51.72413793103448,
    L: 12.06896551724138,
    XL: 1.72413793103448,
  },
  {
    name: 'Border Collie',
    weight: 20,
    total: 313,
    XS: 2.23642172523962,
    S: 30.03194888178914,
    M: 60.70287539936102,
    L: 6.38977635782748,
    XL: 0.63897763578275,
  },
  {
    name: 'Border Collie',
    weight: 30,
    total: 1188,
    XS: 0.67340067340067,
    S: 12.20538720538721,
    M: 81.48148148148148,
    L: 5.47138047138047,
    XL: 0.16835016835017,
  },
  {
    name: 'Border Collie',
    weight: 40,
    total: 3014,
    XS: 0.13271400132714,
    S: 3.45056403450564,
    M: 83.74253483742535,
    L: 12.20968812209688,
    XL: 0.46449900464499,
  },
  {
    name: 'Border Collie',
    weight: 50,
    total: 3024,
    XS: 0,
    S: 1.7526455026455,
    M: 74.07407407407408,
    L: 23.74338624338624,
    XL: 0.42989417989418,
  },
  {
    name: 'Border Collie',
    weight: 60,
    total: 1444,
    XS: 0,
    S: 1.45429362880886,
    M: 49.93074792243767,
    L: 47.50692520775623,
    XL: 1.10803324099723,
  },
  {
    name: 'Border Collie',
    weight: 70,
    total: 589,
    XS: 0.16977928692699,
    S: 0.33955857385399,
    M: 32.08828522920204,
    L: 64.17657045840407,
    XL: 3.2258064516129,
  },
  {
    name: 'Border Collie',
    weight: 80,
    total: 221,
    XS: 0,
    S: 0.45248868778281,
    M: 23.98190045248869,
    L: 72.39819004524887,
    XL: 3.16742081447964,
  },
  {
    name: 'Border Collie',
    weight: 90,
    total: 58,
    XS: 0,
    S: 0,
    M: 18.96551724137931,
    L: 68.96551724137932,
    XL: 12.06896551724138,
  },
  {
    name: 'Border Collie Pyrenees',
    weight: 70,
    total: 26,
    XS: 0,
    S: 0,
    M: 23.07692307692308,
    L: 69.23076923076923,
    XL: 7.69230769230769,
  },
  {
    name: 'Border Terrier',
    weight: 20,
    total: 252,
    XS: 3.96825396825397,
    S: 61.11111111111111,
    M: 32.93650793650794,
    L: 1.98412698412698,
    XL: 0,
  },
  {
    name: 'Border Terrier',
    weight: 30,
    total: 132,
    XS: 0,
    S: 26.51515151515152,
    M: 68.93939393939394,
    L: 4.54545454545455,
    XL: 0,
  },
  {
    name: 'Border Terrier',
    weight: 40,
    total: 60,
    XS: 0,
    S: 6.66666666666667,
    M: 83.33333333333333,
    L: 10,
    XL: 0,
  },
  {
    name: 'Border-Aussie',
    weight: 40,
    total: 62,
    XS: 0,
    S: 6.45161290322581,
    M: 69.35483870967742,
    L: 24.19354838709677,
    XL: 0,
  },
  {
    name: 'Border-Aussie',
    weight: 50,
    total: 94,
    XS: 0,
    S: 0,
    M: 72.34042553191489,
    L: 26.59574468085106,
    XL: 1.06382978723404,
  },
  {
    name: 'Border-Aussie',
    weight: 60,
    total: 55,
    XS: 0,
    S: 0,
    M: 50.90909090909091,
    L: 49.09090909090909,
    XL: 0,
  },
  {
    name: 'Bordoodle',
    weight: 40,
    total: 35,
    XS: 0,
    S: 2.85714285714286,
    M: 82.85714285714286,
    L: 11.42857142857143,
    XL: 2.85714285714286,
  },
  {
    name: 'Bordoodle',
    weight: 50,
    total: 38,
    XS: 0,
    S: 0,
    M: 68.42105263157895,
    L: 31.57894736842105,
    XL: 0,
  },
  {
    name: 'Boston Terrier',
    weight: 10,
    total: 93,
    XS: 13.97849462365591,
    S: 62.36559139784946,
    M: 22.58064516129032,
    L: 1.0752688172043,
    XL: 0,
  },
  {
    name: 'Boston Terrier',
    weight: 20,
    total: 932,
    XS: 1.71673819742489,
    S: 60.83690987124464,
    M: 35.08583690987124,
    L: 1.82403433476395,
    XL: 0.53648068669528,
  },
  {
    name: 'Boston Terrier',
    weight: 30,
    total: 902,
    XS: 0.33259423503326,
    S: 27.0509977827051,
    M: 63.41463414634146,
    L: 8.980044345898,
    XL: 0.22172949002217,
  },
  {
    name: 'Boston Terrier',
    weight: 40,
    total: 185,
    XS: 0,
    S: 8.10810810810811,
    M: 70.27027027027027,
    L: 21.08108108108108,
    XL: 0.54054054054054,
  },
  {
    name: 'Boston Terrier',
    weight: 50,
    total: 47,
    XS: 0,
    S: 0,
    M: 61.70212765957447,
    L: 38.29787234042553,
    XL: 0,
  },
  {
    name: 'Bouvier des Flandres',
    weight: 90,
    total: 30,
    XS: 0,
    S: 0,
    M: 13.33333333333333,
    L: 66.66666666666667,
    XL: 20,
  },
  {
    name: 'Boxer',
    weight: 10,
    total: 56,
    XS: 5.35714285714286,
    S: 57.14285714285714,
    M: 19.64285714285714,
    L: 16.07142857142857,
    XL: 1.78571428571429,
  },
  {
    name: 'Boxer',
    weight: 20,
    total: 102,
    XS: 1.96078431372549,
    S: 44.11764705882353,
    M: 35.29411764705882,
    L: 18.62745098039216,
    XL: 0,
  },
  {
    name: 'Boxer',
    weight: 30,
    total: 217,
    XS: 1.84331797235023,
    S: 13.82488479262673,
    M: 53.45622119815668,
    L: 30.4147465437788,
    XL: 0.46082949308756,
  },
  {
    name: 'Boxer',
    weight: 40,
    total: 492,
    XS: 0.20325203252033,
    S: 5.08130081300813,
    M: 61.78861788617886,
    L: 32.31707317073171,
    XL: 0.60975609756098,
  },
  {
    name: 'Boxer',
    weight: 50,
    total: 1377,
    XS: 0.14524328249818,
    S: 1.74291938997821,
    M: 44.29920116194626,
    L: 52.79593318809005,
    XL: 1.01670297748729,
  },
  {
    name: 'Boxer',
    weight: 60,
    total: 1687,
    XS: 0.1778304682869,
    S: 0.8891523414345,
    M: 31.23888559573207,
    L: 65.79727326615293,
    XL: 1.8968583283936,
  },
  {
    name: 'Boxer',
    weight: 70,
    total: 1425,
    XS: 0.14035087719298,
    S: 0.84210526315789,
    M: 25.33333333333333,
    L: 70.3859649122807,
    XL: 3.29824561403509,
  },
  {
    name: 'Boxer',
    weight: 80,
    total: 806,
    XS: 0,
    S: 0.49627791563275,
    M: 20.09925558312655,
    L: 73.8213399503722,
    XL: 5.58312655086849,
  },
  {
    name: 'Boxer',
    weight: 90,
    total: 369,
    XS: 0,
    S: 0.5420054200542,
    M: 16.80216802168022,
    L: 68.02168021680217,
    XL: 14.63414634146341,
  },
  {
    name: 'Boxer',
    weight: 100,
    total: 126,
    XS: 0,
    S: 0,
    M: 20.63492063492063,
    L: 57.93650793650794,
    XL: 21.42857142857143,
  },
  {
    name: 'Boxer',
    weight: 110,
    total: 29,
    XS: 0,
    S: 0,
    M: 13.79310344827586,
    L: 58.62068965517241,
    XL: 27.58620689655172,
  },
  {
    name: 'Boykin Spaniel',
    weight: 20,
    total: 40,
    XS: 2.5,
    S: 35,
    M: 52.5,
    L: 7.5,
    XL: 2.5,
  },
  {
    name: 'Boykin Spaniel',
    weight: 30,
    total: 155,
    XS: 0.64516129032258,
    S: 11.61290322580645,
    M: 83.87096774193547,
    L: 3.87096774193548,
    XL: 0,
  },
  {
    name: 'Boykin Spaniel',
    weight: 40,
    total: 293,
    XS: 0.34129692832765,
    S: 4.09556313993174,
    M: 81.22866894197952,
    L: 13.99317406143345,
    XL: 0.34129692832765,
  },
  {
    name: 'Boykin Spaniel',
    weight: 50,
    total: 116,
    XS: 0,
    S: 2.58620689655172,
    M: 63.79310344827586,
    L: 32.75862068965517,
    XL: 0.86206896551724,
  },
  {
    name: 'Brittany',
    weight: 20,
    total: 76,
    XS: 6.57894736842105,
    S: 31.57894736842105,
    M: 56.57894736842105,
    L: 3.94736842105263,
    XL: 1.31578947368421,
  },
  {
    name: 'Brittany',
    weight: 30,
    total: 431,
    XS: 0.92807424593968,
    S: 19.48955916473318,
    M: 76.79814385150812,
    L: 2.55220417633411,
    XL: 0.23201856148492,
  },
  {
    name: 'Brittany',
    weight: 40,
    total: 862,
    XS: 0,
    S: 4.98839907192575,
    M: 89.21113689095128,
    L: 5.80046403712297,
    XL: 0,
  },
  {
    name: 'Brittany',
    weight: 50,
    total: 567,
    XS: 0,
    S: 1.05820105820106,
    M: 78.83597883597884,
    L: 20.10582010582011,
    XL: 0,
  },
  {
    name: 'Brittany',
    weight: 60,
    total: 152,
    XS: 0,
    S: 0,
    M: 54.60526315789474,
    L: 44.07894736842105,
    XL: 1.31578947368421,
  },
  {
    name: 'Brittany',
    weight: 70,
    total: 35,
    XS: 0,
    S: 0,
    M: 48.57142857142857,
    L: 51.42857142857143,
    XL: 0,
  },
  {
    name: 'Brussels Griffon',
    weight: 10,
    total: 39,
    XS: 41.02564102564103,
    S: 51.28205128205128,
    M: 7.69230769230769,
    L: 0,
    XL: 0,
  },
  {
    name: 'Brussels Griffon',
    weight: 20,
    total: 53,
    XS: 9.43396226415094,
    S: 71.69811320754717,
    M: 18.86792452830189,
    L: 0,
    XL: 0,
  },
  {
    name: 'Bull Terrier',
    weight: 30,
    total: 46,
    XS: 2.17391304347826,
    S: 15.21739130434783,
    M: 67.3913043478261,
    L: 15.21739130434783,
    XL: 0,
  },
  {
    name: 'Bull Terrier',
    weight: 40,
    total: 76,
    XS: 0,
    S: 3.94736842105263,
    M: 71.05263157894737,
    L: 22.36842105263158,
    XL: 2.63157894736842,
  },
  {
    name: 'Bull Terrier',
    weight: 50,
    total: 145,
    XS: 0,
    S: 2.06896551724138,
    M: 45.51724137931034,
    L: 51.03448275862069,
    XL: 1.37931034482759,
  },
  {
    name: 'Bull Terrier',
    weight: 60,
    total: 137,
    XS: 0,
    S: 0,
    M: 37.22627737226277,
    L: 60.58394160583942,
    XL: 2.18978102189781,
  },
  {
    name: 'Bull Terrier',
    weight: 70,
    total: 53,
    XS: 0,
    S: 0,
    M: 26.41509433962264,
    L: 64.15094339622642,
    XL: 9.43396226415094,
  },
  {
    name: 'Bullboxer Pit',
    weight: 50,
    total: 80,
    XS: 0,
    S: 2.5,
    M: 43.75,
    L: 53.75,
    XL: 0,
  },
  {
    name: 'Bullboxer Pit',
    weight: 60,
    total: 86,
    XS: 0,
    S: 0,
    M: 26.74418604651163,
    L: 69.76744186046513,
    XL: 3.48837209302326,
  },
  {
    name: 'Bullboxer Pit',
    weight: 70,
    total: 81,
    XS: 0,
    S: 0,
    M: 18.51851851851852,
    L: 75.30864197530865,
    XL: 6.17283950617284,
  },
  {
    name: 'Bullboxer Pit',
    weight: 80,
    total: 40,
    XS: 0,
    S: 0,
    M: 15,
    L: 72.5,
    XL: 12.5,
  },
  {
    name: 'Bulldog',
    weight: 20,
    total: 53,
    XS: 3.77358490566038,
    S: 35.84905660377358,
    M: 50.94339622641509,
    L: 7.54716981132075,
    XL: 1.88679245283019,
  },
  {
    name: 'Bulldog',
    weight: 30,
    total: 138,
    XS: 0,
    S: 18.11594202898551,
    M: 60.14492753623188,
    L: 21.01449275362319,
    XL: 0.72463768115942,
  },
  {
    name: 'Bulldog',
    weight: 40,
    total: 157,
    XS: 0,
    S: 1.91082802547771,
    M: 57.96178343949045,
    L: 37.5796178343949,
    XL: 2.54777070063694,
  },
  {
    name: 'Bulldog',
    weight: 50,
    total: 292,
    XS: 0,
    S: 2.3972602739726,
    M: 37.67123287671233,
    L: 52.73972602739726,
    XL: 7.19178082191781,
  },
  {
    name: 'Bulldog',
    weight: 60,
    total: 271,
    XS: 0,
    S: 2.2140221402214,
    M: 25.83025830258303,
    L: 59.40959409594096,
    XL: 12.54612546125461,
  },
  {
    name: 'Bulldog',
    weight: 70,
    total: 207,
    XS: 0,
    S: 1.93236714975845,
    M: 24.63768115942029,
    L: 56.52173913043478,
    XL: 16.90821256038647,
  },
  {
    name: 'Bulldog',
    weight: 80,
    total: 123,
    XS: 1.6260162601626,
    S: 0,
    M: 20.32520325203252,
    L: 58.53658536585366,
    XL: 19.51219512195122,
  },
  {
    name: 'Bulldog',
    weight: 90,
    total: 68,
    XS: 0,
    S: 1.47058823529412,
    M: 22.05882352941176,
    L: 51.47058823529412,
    XL: 25,
  },
  {
    name: 'Bulldog',
    weight: 100,
    total: 43,
    XS: 0,
    S: 2.32558139534884,
    M: 20.93023255813953,
    L: 39.53488372093023,
    XL: 37.2093023255814,
  },
  {
    name: 'Bullmastiff',
    weight: 90,
    total: 29,
    XS: 0,
    S: 0,
    M: 6.89655172413793,
    L: 58.62068965517241,
    XL: 34.48275862068966,
  },
  {
    name: 'Bullmastiff',
    weight: 100,
    total: 43,
    XS: 0,
    S: 0,
    M: 9.30232558139535,
    L: 30.23255813953488,
    XL: 60.46511627906977,
  },
  {
    name: 'Bullmastiff',
    weight: 110,
    total: 38,
    XS: 2.63157894736842,
    S: 0,
    M: 13.15789473684211,
    L: 13.15789473684211,
    XL: 71.05263157894737,
  },
  {
    name: 'Bullmastiff',
    weight: 120,
    total: 28,
    XS: 0,
    S: 0,
    M: 10.71428571428571,
    L: 7.14285714285714,
    XL: 82.14285714285714,
  },
  {
    name: 'Bullmastiff',
    weight: 130,
    total: 33,
    XS: 0,
    S: 3.03030303030303,
    M: 15.15151515151515,
    L: 9.09090909090909,
    XL: 72.72727272727273,
  },
  {
    name: 'Bullypit',
    weight: 40,
    total: 53,
    XS: 0,
    S: 3.77358490566038,
    M: 58.49056603773585,
    L: 35.84905660377358,
    XL: 1.88679245283019,
  },
  {
    name: 'Bullypit',
    weight: 50,
    total: 95,
    XS: 1.05263157894737,
    S: 1.05263157894737,
    M: 40,
    L: 54.73684210526316,
    XL: 3.15789473684211,
  },
  {
    name: 'Bullypit',
    weight: 60,
    total: 129,
    XS: 0.77519379844961,
    S: 1.55038759689922,
    M: 28.68217054263566,
    L: 65.11627906976744,
    XL: 3.87596899224806,
  },
  {
    name: 'Bullypit',
    weight: 70,
    total: 107,
    XS: 0,
    S: 0.93457943925234,
    M: 24.29906542056075,
    L: 66.35514018691589,
    XL: 8.41121495327103,
  },
  {
    name: 'Bullypit',
    weight: 80,
    total: 77,
    XS: 0,
    S: 0,
    M: 18.18181818181818,
    L: 66.23376623376623,
    XL: 15.58441558441558,
  },
  {
    name: 'Bullypit',
    weight: 90,
    total: 42,
    XS: 0,
    S: 0,
    M: 14.28571428571429,
    L: 59.52380952380952,
    XL: 26.19047619047619,
  },
  {
    name: 'Cairn Terrier',
    weight: 10,
    total: 41,
    XS: 17.07317073170732,
    S: 75.60975609756098,
    M: 4.8780487804878,
    L: 2.4390243902439,
    XL: 0,
  },
  {
    name: 'Cairn Terrier',
    weight: 20,
    total: 467,
    XS: 3.64025695931478,
    S: 66.38115631691649,
    M: 28.05139186295503,
    L: 1.71306209850107,
    XL: 0.21413276231263,
  },
  {
    name: 'Cairn Terrier',
    weight: 30,
    total: 163,
    XS: 0.61349693251534,
    S: 32.51533742331288,
    M: 65.03067484662577,
    L: 0.61349693251534,
    XL: 1.22699386503067,
  },
  {
    name: 'Canaan Dog',
    weight: 50,
    total: 35,
    XS: 0,
    S: 2.85714285714286,
    M: 54.28571428571429,
    L: 42.85714285714286,
    XL: 0,
  },
  {
    name: 'Cane Corso',
    weight: 40,
    total: 27,
    XS: 0,
    S: 11.11111111111111,
    M: 40.74074074074074,
    L: 44.44444444444444,
    XL: 3.7037037037037,
  },
  {
    name: 'Cane Corso',
    weight: 50,
    total: 55,
    XS: 0,
    S: 5.45454545454545,
    M: 29.09090909090909,
    L: 50.90909090909091,
    XL: 14.54545454545455,
  },
  {
    name: 'Cane Corso',
    weight: 60,
    total: 57,
    XS: 1.75438596491228,
    S: 3.50877192982456,
    M: 15.78947368421053,
    L: 63.15789473684211,
    XL: 15.78947368421053,
  },
  {
    name: 'Cane Corso',
    weight: 70,
    total: 87,
    XS: 0,
    S: 4.59770114942529,
    M: 21.83908045977011,
    L: 54.02298850574713,
    XL: 19.54022988505747,
  },
  {
    name: 'Cane Corso',
    weight: 80,
    total: 120,
    XS: 0,
    S: 0.83333333333333,
    M: 10.83333333333333,
    L: 58.33333333333333,
    XL: 30,
  },
  {
    name: 'Cane Corso',
    weight: 90,
    total: 183,
    XS: 0,
    S: 0.54644808743169,
    M: 15.30054644808743,
    L: 44.80874316939891,
    XL: 39.34426229508197,
  },
  {
    name: 'Cane Corso',
    weight: 100,
    total: 211,
    XS: 0,
    S: 1.4218009478673,
    M: 17.06161137440758,
    L: 36.49289099526066,
    XL: 45.02369668246445,
  },
  {
    name: 'Cane Corso',
    weight: 110,
    total: 159,
    XS: 0,
    S: 2.51572327044025,
    M: 20.75471698113208,
    L: 23.89937106918239,
    XL: 52.83018867924528,
  },
  {
    name: 'Cane Corso',
    weight: 120,
    total: 136,
    XS: 0,
    S: 1.47058823529412,
    M: 17.64705882352941,
    L: 14.70588235294118,
    XL: 66.17647058823529,
  },
  {
    name: 'Cane Corso',
    weight: 130,
    total: 132,
    XS: 0,
    S: 0.75757575757576,
    M: 15.15151515151515,
    L: 12.87878787878788,
    XL: 71.2121212121212,
  },
  {
    name: 'Cane Corso',
    weight: 140,
    total: 63,
    XS: 0,
    S: 0,
    M: 17.46031746031746,
    L: 14.28571428571429,
    XL: 68.25396825396825,
  },
  {
    name: 'Cane Corso',
    weight: 150,
    total: 47,
    XS: 0,
    S: 4.25531914893617,
    M: 8.51063829787234,
    L: 8.51063829787234,
    XL: 78.72340425531915,
  },
  {
    name: 'Cardigan Welsh Corgi',
    weight: 20,
    total: 103,
    XS: 2.9126213592233,
    S: 24.27184466019417,
    M: 67.96116504854369,
    L: 3.88349514563107,
    XL: 0.97087378640777,
  },
  {
    name: 'Cardigan Welsh Corgi',
    weight: 30,
    total: 263,
    XS: 0.38022813688213,
    S: 7.98479087452471,
    M: 81.36882129277566,
    L: 9.88593155893536,
    XL: 0.38022813688213,
  },
  {
    name: 'Cardigan Welsh Corgi',
    weight: 40,
    total: 162,
    XS: 0.61728395061728,
    S: 1.23456790123457,
    M: 70.98765432098764,
    L: 25.92592592592593,
    XL: 1.23456790123457,
  },
  {
    name: 'Cardigan Welsh Corgi',
    weight: 50,
    total: 59,
    XS: 0,
    S: 3.38983050847458,
    M: 54.23728813559322,
    L: 37.28813559322034,
    XL: 5.08474576271186,
  },
  {
    name: 'Carolina Dog',
    weight: 30,
    total: 37,
    XS: 0,
    S: 18.91891891891892,
    M: 70.27027027027027,
    L: 10.81081081081081,
    XL: 0,
  },
  {
    name: 'Carolina Dog',
    weight: 40,
    total: 88,
    XS: 0,
    S: 2.27272727272727,
    M: 70.45454545454545,
    L: 27.27272727272727,
    XL: 0,
  },
  {
    name: 'Carolina Dog',
    weight: 50,
    total: 139,
    XS: 0,
    S: 0,
    M: 57.55395683453237,
    L: 41.72661870503597,
    XL: 0.71942446043165,
  },
  {
    name: 'Carolina Dog',
    weight: 60,
    total: 93,
    XS: 0,
    S: 0,
    M: 37.63440860215054,
    L: 62.36559139784946,
    XL: 0,
  },
  {
    name: 'Carolina Dog',
    weight: 70,
    total: 58,
    XS: 0,
    S: 1.72413793103448,
    M: 34.48275862068966,
    L: 62.06896551724138,
    XL: 1.72413793103448,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 20,
    total: 45,
    XS: 22.22222222222222,
    S: 46.66666666666667,
    M: 22.22222222222222,
    L: 8.88888888888889,
    XL: 0,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 30,
    total: 105,
    XS: 0.95238095238095,
    S: 9.52380952380952,
    M: 74.28571428571429,
    L: 14.28571428571429,
    XL: 0.95238095238095,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 40,
    total: 257,
    XS: 0,
    S: 2.72373540856031,
    M: 74.31906614785991,
    L: 22.95719844357977,
    XL: 0,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 50,
    total: 552,
    XS: 0,
    S: 2.17391304347826,
    M: 59.23913043478261,
    L: 38.04347826086957,
    XL: 0.54347826086957,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 60,
    total: 587,
    XS: 0.17035775127768,
    S: 0.51107325383305,
    M: 41.396933560477,
    L: 57.06984667802385,
    XL: 0.85178875638842,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 70,
    total: 461,
    XS: 0,
    S: 0,
    M: 29.93492407809111,
    L: 67.67895878524946,
    XL: 2.38611713665944,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 80,
    total: 258,
    XS: 0,
    S: 0,
    M: 25.58139534883721,
    L: 69.76744186046513,
    XL: 4.65116279069767,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 90,
    total: 125,
    XS: 0,
    S: 0,
    M: 19.2,
    L: 67.2,
    XL: 13.6,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 100,
    total: 49,
    XS: 0,
    S: 2.04081632653061,
    M: 20.40816326530612,
    L: 55.10204081632653,
    XL: 22.44897959183673,
  },
  {
    name: 'Cattle Collie Dog',
    weight: 40,
    total: 39,
    XS: 0,
    S: 7.69230769230769,
    M: 69.23076923076923,
    L: 17.94871794871795,
    XL: 5.12820512820513,
  },
  {
    name: 'Cattle Collie Dog',
    weight: 50,
    total: 36,
    XS: 0,
    S: 5.55555555555556,
    M: 58.33333333333333,
    L: 33.33333333333333,
    XL: 2.77777777777778,
  },
  {
    name: 'Cavachon',
    weight: 10,
    total: 27,
    XS: 44.44444444444444,
    S: 48.14814814814815,
    M: 7.40740740740741,
    L: 0,
    XL: 0,
  },
  {
    name: 'Cavachon',
    weight: 20,
    total: 184,
    XS: 8.15217391304348,
    S: 67.3913043478261,
    M: 23.91304347826087,
    L: 0.54347826086957,
    XL: 0,
  },
  {
    name: 'Cavachon',
    weight: 30,
    total: 62,
    XS: 0,
    S: 41.93548387096774,
    M: 58.06451612903226,
    L: 0,
    XL: 0,
  },
  {
    name: 'Cavalier King Charles Spaniel',
    weight: 10,
    total: 140,
    XS: 19.28571428571429,
    S: 70.71428571428571,
    M: 9.28571428571429,
    L: 0.71428571428571,
    XL: 0,
  },
  {
    name: 'Cavalier King Charles Spaniel',
    weight: 20,
    total: 1163,
    XS: 3.61134995700774,
    S: 68.09974204643164,
    M: 27.08512467755804,
    L: 0.94582975064488,
    XL: 0.2579535683577,
  },
  {
    name: 'Cavalier King Charles Spaniel',
    weight: 30,
    total: 481,
    XS: 0.20790020790021,
    S: 36.59043659043659,
    M: 58.62785862785863,
    L: 4.57380457380457,
    XL: 0,
  },
  {
    name: 'Cavalier King Charles Spaniel',
    weight: 40,
    total: 50,
    XS: 0,
    S: 6,
    M: 80,
    L: 12,
    XL: 2,
  },
  {
    name: 'Cavapoo',
    weight: 10,
    total: 181,
    XS: 40.33149171270718,
    S: 51.93370165745856,
    M: 6.07734806629834,
    L: 1.10497237569061,
    XL: 0.5524861878453,
  },
  {
    name: 'Cavapoo',
    weight: 20,
    total: 700,
    XS: 7,
    S: 73.14285714285714,
    M: 19,
    L: 0.57142857142857,
    XL: 0.28571428571429,
  },
  {
    name: 'Cavapoo',
    weight: 30,
    total: 206,
    XS: 0.48543689320388,
    S: 38.3495145631068,
    M: 57.76699029126214,
    L: 2.9126213592233,
    XL: 0.48543689320388,
  },
  {
    name: 'Cavapoo',
    weight: 40,
    total: 29,
    XS: 0,
    S: 10.3448275862069,
    M: 75.86206896551724,
    L: 13.79310344827586,
    XL: 0,
  },
  {
    name: 'Chesapeake Bay Retriever',
    weight: 50,
    total: 27,
    XS: 0,
    S: 3.7037037037037,
    M: 37.03703703703704,
    L: 55.55555555555556,
    XL: 3.7037037037037,
  },
  {
    name: 'Chesapeake Bay Retriever',
    weight: 60,
    total: 47,
    XS: 2.12765957446809,
    S: 2.12765957446809,
    M: 25.53191489361702,
    L: 68.08510638297872,
    XL: 2.12765957446809,
  },
  {
    name: 'Chesapeake Bay Retriever',
    weight: 70,
    total: 87,
    XS: 0,
    S: 1.14942528735632,
    M: 31.03448275862069,
    L: 65.51724137931033,
    XL: 2.29885057471264,
  },
  {
    name: 'Chesapeake Bay Retriever',
    weight: 80,
    total: 92,
    XS: 0,
    S: 0,
    M: 19.56521739130435,
    L: 71.73913043478261,
    XL: 8.69565217391304,
  },
  {
    name: 'Chesapeake Bay Retriever',
    weight: 90,
    total: 65,
    XS: 0,
    S: 0,
    M: 16.92307692307692,
    L: 53.84615384615385,
    XL: 29.23076923076923,
  },
  {
    name: 'Chesapeake Bay Retriever',
    weight: 100,
    total: 46,
    XS: 0,
    S: 0,
    M: 19.56521739130435,
    L: 63.04347826086957,
    XL: 17.39130434782609,
  },
  {
    name: 'Chihuahua',
    weight: 10,
    total: 1274,
    XS: 47.72370486656201,
    S: 41.83673469387755,
    M: 7.14285714285714,
    L: 2.66875981161695,
    XL: 0.62794348508634,
  },
  {
    name: 'Chihuahua',
    weight: 20,
    total: 2324,
    XS: 9.50946643717728,
    S: 68.80378657487091,
    M: 19.32013769363167,
    L: 1.93631669535284,
    XL: 0.4302925989673,
  },
  {
    name: 'Chihuahua',
    weight: 30,
    total: 507,
    XS: 1.18343195266272,
    S: 28.99408284023669,
    M: 66.27218934911242,
    L: 3.15581854043393,
    XL: 0.39447731755424,
  },
  {
    name: 'Chihuahua',
    weight: 40,
    total: 109,
    XS: 0.91743119266055,
    S: 2.75229357798165,
    M: 87.15596330275228,
    L: 8.25688073394495,
    XL: 0.91743119266055,
  },
  {
    name: 'Chinese Crested',
    weight: 20,
    total: 66,
    XS: 13.63636363636364,
    S: 62.12121212121212,
    M: 24.24242424242424,
    L: 0,
    XL: 0,
  },
  {
    name: 'Chinese Shar-Pei',
    weight: 30,
    total: 35,
    XS: 0,
    S: 14.28571428571429,
    M: 68.57142857142857,
    L: 17.14285714285714,
    XL: 0,
  },
  {
    name: 'Chinese Shar-Pei',
    weight: 40,
    total: 121,
    XS: 0,
    S: 1.65289256198347,
    M: 59.50413223140496,
    L: 38.01652892561983,
    XL: 0.82644628099174,
  },
  {
    name: 'Chinese Shar-Pei',
    weight: 50,
    total: 169,
    XS: 0,
    S: 2.36686390532544,
    M: 46.74556213017751,
    L: 49.70414201183432,
    XL: 1.18343195266272,
  },
  {
    name: 'Chinese Shar-Pei',
    weight: 60,
    total: 117,
    XS: 0,
    S: 1.70940170940171,
    M: 30.76923076923077,
    L: 65.8119658119658,
    XL: 1.70940170940171,
  },
  {
    name: 'Chinese Shar-Pei',
    weight: 70,
    total: 58,
    XS: 0,
    S: 3.44827586206897,
    M: 20.68965517241379,
    L: 68.96551724137932,
    XL: 6.89655172413793,
  },
  {
    name: 'Chinese Shar-Pei',
    weight: 80,
    total: 30,
    XS: 0,
    S: 0,
    M: 23.33333333333333,
    L: 70,
    XL: 6.66666666666667,
  },
  {
    name: 'Chipit',
    weight: 30,
    total: 35,
    XS: 0,
    S: 28.57142857142857,
    M: 71.42857142857143,
    L: 0,
    XL: 0,
  },
  {
    name: 'Chiweenie',
    weight: 10,
    total: 60,
    XS: 33.33333333333333,
    S: 48.33333333333333,
    M: 18.33333333333333,
    L: 0,
    XL: 0,
  },
  {
    name: 'Chiweenie',
    weight: 20,
    total: 198,
    XS: 9.5959595959596,
    S: 69.6969696969697,
    M: 19.6969696969697,
    L: 0.50505050505051,
    XL: 0.50505050505051,
  },
  {
    name: 'Chorkie',
    weight: 10,
    total: 27,
    XS: 62.96296296296296,
    S: 29.62962962962963,
    M: 7.40740740740741,
    L: 0,
    XL: 0,
  },
  {
    name: 'Chorkie',
    weight: 20,
    total: 26,
    XS: 11.53846153846154,
    S: 61.53846153846154,
    M: 19.23076923076923,
    L: 7.69230769230769,
    XL: 0,
  },
  {
    name: 'Chow Chow',
    weight: 30,
    total: 52,
    XS: 0,
    S: 5.76923076923077,
    M: 73.07692307692308,
    L: 21.15384615384615,
    XL: 0,
  },
  {
    name: 'Chow Chow',
    weight: 40,
    total: 133,
    XS: 0,
    S: 6.01503759398496,
    M: 56.39097744360902,
    L: 36.09022556390977,
    XL: 1.50375939849624,
  },
  {
    name: 'Chow Chow',
    weight: 50,
    total: 256,
    XS: 0,
    S: 1.171875,
    M: 43.359375,
    L: 51.171875,
    XL: 4.296875,
  },
  {
    name: 'Chow Chow',
    weight: 60,
    total: 187,
    XS: 0,
    S: 1.06951871657754,
    M: 25.66844919786096,
    L: 65.24064171122996,
    XL: 8.02139037433155,
  },
  {
    name: 'Chow Chow',
    weight: 70,
    total: 95,
    XS: 0,
    S: 2.10526315789474,
    M: 18.94736842105263,
    L: 65.26315789473684,
    XL: 13.68421052631579,
  },
  {
    name: 'Chow Chow',
    weight: 80,
    total: 46,
    XS: 0,
    S: 4.34782608695652,
    M: 19.56521739130435,
    L: 65.21739130434783,
    XL: 10.8695652173913,
  },
  {
    name: 'Chow Shepherd',
    weight: 40,
    total: 29,
    XS: 0,
    S: 6.89655172413793,
    M: 65.51724137931033,
    L: 27.58620689655172,
    XL: 0,
  },
  {
    name: 'Chow Shepherd',
    weight: 50,
    total: 45,
    XS: 0,
    S: 0,
    M: 57.77777777777778,
    L: 42.22222222222222,
    XL: 0,
  },
  {
    name: 'Chow Shepherd',
    weight: 60,
    total: 38,
    XS: 0,
    S: 0,
    M: 31.57894736842105,
    L: 68.42105263157895,
    XL: 0,
  },
  {
    name: 'Chug',
    weight: 20,
    total: 41,
    XS: 4.8780487804878,
    S: 68.29268292682927,
    M: 26.82926829268293,
    L: 0,
    XL: 0,
  },
  {
    name: 'Cockapoo',
    weight: 10,
    total: 65,
    XS: 30.76923076923077,
    S: 52.30769230769231,
    M: 15.38461538461538,
    L: 1.53846153846154,
    XL: 0,
  },
  {
    name: 'Cockapoo',
    weight: 20,
    total: 490,
    XS: 6.53061224489796,
    S: 61.63265306122449,
    M: 30.61224489795918,
    L: 1.22448979591837,
    XL: 0,
  },
  {
    name: 'Cockapoo',
    weight: 30,
    total: 388,
    XS: 1.54639175257732,
    S: 31.44329896907216,
    M: 63.4020618556701,
    L: 3.35051546391753,
    XL: 0.25773195876289,
  },
  {
    name: 'Cockapoo',
    weight: 40,
    total: 68,
    XS: 0,
    S: 16.17647058823529,
    M: 75,
    L: 8.82352941176471,
    XL: 0,
  },
  {
    name: 'Cocker Spaniel',
    weight: 20,
    total: 302,
    XS: 3.97350993377483,
    S: 52.64900662251656,
    M: 40.72847682119205,
    L: 2.31788079470199,
    XL: 0.33112582781457,
  },
  {
    name: 'Cocker Spaniel',
    weight: 30,
    total: 505,
    XS: 0.99009900990099,
    S: 21.98019801980198,
    M: 72.67326732673267,
    L: 4.35643564356436,
    XL: 0,
  },
  {
    name: 'Cocker Spaniel',
    weight: 40,
    total: 186,
    XS: 0,
    S: 3.2258064516129,
    M: 83.87096774193547,
    L: 12.36559139784946,
    XL: 0.53763440860215,
  },
  {
    name: 'Cocker Spaniel',
    weight: 50,
    total: 48,
    XS: 0,
    S: 4.16666666666667,
    M: 70.83333333333333,
    L: 25,
    XL: 0,
  },
  {
    name: 'Collie',
    weight: 30,
    total: 37,
    XS: 0,
    S: 5.40540540540541,
    M: 86.48648648648648,
    L: 8.10810810810811,
    XL: 0,
  },
  {
    name: 'Collie',
    weight: 40,
    total: 90,
    XS: 0,
    S: 3.33333333333333,
    M: 78.88888888888889,
    L: 16.66666666666667,
    XL: 1.11111111111111,
  },
  {
    name: 'Collie',
    weight: 50,
    total: 168,
    XS: 0,
    S: 2.38095238095238,
    M: 51.78571428571429,
    L: 45.23809523809524,
    XL: 0.5952380952381,
  },
  {
    name: 'Collie',
    weight: 60,
    total: 182,
    XS: 0,
    S: 2.1978021978022,
    M: 40.10989010989011,
    L: 55.49450549450549,
    XL: 2.1978021978022,
  },
  {
    name: 'Collie',
    weight: 70,
    total: 141,
    XS: 0.70921985815603,
    S: 0,
    M: 34.04255319148936,
    L: 61.70212765957447,
    XL: 3.54609929078014,
  },
  {
    name: 'Collie',
    weight: 80,
    total: 70,
    XS: 0,
    S: 0,
    M: 18.57142857142857,
    L: 74.28571428571429,
    XL: 7.14285714285714,
  },
  {
    name: 'Collie',
    weight: 90,
    total: 33,
    XS: 0,
    S: 3.03030303030303,
    M: 18.18181818181818,
    L: 66.66666666666667,
    XL: 12.12121212121212,
  },
  {
    name: 'Corgi Cattle Dog',
    weight: 20,
    total: 48,
    XS: 12.5,
    S: 27.08333333333333,
    M: 58.33333333333333,
    L: 2.08333333333333,
    XL: 0,
  },
  {
    name: 'Corgi Cattle Dog',
    weight: 30,
    total: 93,
    XS: 0,
    S: 10.75268817204301,
    M: 82.79569892473118,
    L: 6.45161290322581,
    XL: 0,
  },
  {
    name: 'Corgi Cattle Dog',
    weight: 40,
    total: 57,
    XS: 0,
    S: 1.75438596491228,
    M: 77.19298245614036,
    L: 21.05263157894737,
    XL: 0,
  },
  {
    name: 'Coton De Tulear',
    weight: 10,
    total: 38,
    XS: 36.84210526315789,
    S: 52.63157894736842,
    M: 10.52631578947368,
    L: 0,
    XL: 0,
  },
  {
    name: 'Coton De Tulear',
    weight: 20,
    total: 129,
    XS: 5.42635658914729,
    S: 71.31782945736434,
    M: 23.25581395348837,
    L: 0,
    XL: 0,
  },
  {
    name: 'Dachshund',
    weight: 10,
    total: 742,
    XS: 43.1266846361186,
    S: 47.03504043126685,
    M: 7.54716981132075,
    L: 2.02156334231806,
    XL: 0.26954177897574,
  },
  {
    name: 'Dachshund',
    weight: 20,
    total: 2401,
    XS: 10.07913369429404,
    S: 68.63806747188671,
    M: 19.49187838400666,
    L: 1.4993752603082,
    XL: 0.29154518950437,
  },
  {
    name: 'Dachshund',
    weight: 30,
    total: 594,
    XS: 0.33670033670034,
    S: 28.95622895622896,
    M: 66.32996632996633,
    L: 4.04040404040404,
    XL: 0.33670033670034,
  },
  {
    name: 'Dachshund',
    weight: 40,
    total: 162,
    XS: 0,
    S: 7.40740740740741,
    M: 80.24691358024691,
    L: 11.7283950617284,
    XL: 0.61728395061728,
  },
  {
    name: 'Dachshund',
    weight: 50,
    total: 44,
    XS: 0,
    S: 0,
    M: 79.54545454545455,
    L: 20.45454545454545,
    XL: 0,
  },
  {
    name: 'Dalmatian',
    weight: 20,
    total: 39,
    XS: 12.82051282051282,
    S: 35.8974358974359,
    M: 38.46153846153846,
    L: 10.25641025641026,
    XL: 2.56410256410256,
  },
  {
    name: 'Dalmatian',
    weight: 30,
    total: 69,
    XS: 1.44927536231884,
    S: 14.49275362318841,
    M: 66.66666666666667,
    L: 15.94202898550725,
    XL: 1.44927536231884,
  },
  {
    name: 'Dalmatian',
    weight: 40,
    total: 130,
    XS: 0.76923076923077,
    S: 7.69230769230769,
    M: 69.23076923076923,
    L: 20,
    XL: 2.30769230769231,
  },
  {
    name: 'Dalmatian',
    weight: 50,
    total: 382,
    XS: 0,
    S: 3.1413612565445,
    M: 64.13612565445025,
    L: 32.46073298429319,
    XL: 0.26178010471204,
  },
  {
    name: 'Dalmatian',
    weight: 60,
    total: 344,
    XS: 0,
    S: 2.03488372093023,
    M: 45.93023255813953,
    L: 52.03488372093023,
    XL: 0,
  },
  {
    name: 'Dalmatian',
    weight: 70,
    total: 205,
    XS: 0,
    S: 1.95121951219512,
    M: 28.29268292682927,
    L: 68.78048780487805,
    XL: 0.97560975609756,
  },
  {
    name: 'Dalmatian',
    weight: 80,
    total: 67,
    XS: 1.49253731343284,
    S: 1.49253731343284,
    M: 22.38805970149254,
    L: 68.65671641791045,
    XL: 5.97014925373134,
  },
  {
    name: 'Doberman Pinscher',
    weight: 20,
    total: 66,
    XS: 4.54545454545455,
    S: 37.87878787878788,
    M: 39.39393939393939,
    L: 16.66666666666667,
    XL: 1.51515151515152,
  },
  {
    name: 'Doberman Pinscher',
    weight: 30,
    total: 122,
    XS: 2.45901639344262,
    S: 18.85245901639344,
    M: 45.08196721311475,
    L: 31.14754098360656,
    XL: 2.45901639344262,
  },
  {
    name: 'Doberman Pinscher',
    weight: 40,
    total: 167,
    XS: 0,
    S: 8.98203592814371,
    M: 53.89221556886228,
    L: 37.12574850299401,
    XL: 0,
  },
  {
    name: 'Doberman Pinscher',
    weight: 50,
    total: 256,
    XS: 0.78125,
    S: 3.125,
    M: 46.875,
    L: 46.875,
    XL: 2.34375,
  },
  {
    name: 'Doberman Pinscher',
    weight: 60,
    total: 416,
    XS: 0.24038461538462,
    S: 2.40384615384615,
    M: 43.26923076923077,
    L: 52.16346153846154,
    XL: 1.92307692307692,
  },
  {
    name: 'Doberman Pinscher',
    weight: 70,
    total: 765,
    XS: 0,
    S: 2.35294117647059,
    M: 31.76470588235294,
    L: 63.92156862745098,
    XL: 1.96078431372549,
  },
  {
    name: 'Doberman Pinscher',
    weight: 80,
    total: 813,
    XS: 0.1230012300123,
    S: 1.230012300123,
    M: 21.77121771217712,
    L: 74.04674046740467,
    XL: 2.8290282902829,
  },
  {
    name: 'Doberman Pinscher',
    weight: 90,
    total: 607,
    XS: 0,
    S: 0.82372322899506,
    M: 19.43986820428336,
    L: 74.95881383855026,
    XL: 4.77759472817133,
  },
  {
    name: 'Doberman Pinscher',
    weight: 100,
    total: 335,
    XS: 0,
    S: 1.19402985074627,
    M: 20.29850746268657,
    L: 71.044776119403,
    XL: 7.46268656716418,
  },
  {
    name: 'Doberman Pinscher',
    weight: 110,
    total: 119,
    XS: 0,
    S: 0,
    M: 23.52941176470588,
    L: 61.34453781512605,
    XL: 15.12605042016807,
  },
  {
    name: 'Doberman Pinscher',
    weight: 120,
    total: 54,
    XS: 0,
    S: 0,
    M: 16.66666666666667,
    L: 57.40740740740741,
    XL: 25.92592592592593,
  },
  {
    name: 'Doberman Shepherd',
    weight: 50,
    total: 29,
    XS: 3.44827586206897,
    S: 6.89655172413793,
    M: 48.27586206896552,
    L: 41.37931034482759,
    XL: 0,
  },
  {
    name: 'Doberman Shepherd',
    weight: 70,
    total: 37,
    XS: 0,
    S: 0,
    M: 24.32432432432432,
    L: 72.97297297297297,
    XL: 2.7027027027027,
  },
  {
    name: 'Doberman Shepherd',
    weight: 80,
    total: 32,
    XS: 0,
    S: 0,
    M: 28.125,
    L: 68.75,
    XL: 3.125,
  },
  {
    name: 'Dogo Argentino',
    weight: 70,
    total: 29,
    XS: 0,
    S: 0,
    M: 31.03448275862069,
    L: 65.51724137931033,
    XL: 3.44827586206897,
  },
  {
    name: 'Dogo Argentino',
    weight: 80,
    total: 52,
    XS: 0,
    S: 1.92307692307692,
    M: 15.38461538461538,
    L: 73.07692307692308,
    XL: 9.61538461538462,
  },
  {
    name: 'Dogo Argentino',
    weight: 90,
    total: 66,
    XS: 0,
    S: 1.51515151515152,
    M: 21.21212121212121,
    L: 51.51515151515152,
    XL: 25.75757575757576,
  },
  {
    name: 'Dogo Argentino',
    weight: 100,
    total: 58,
    XS: 0,
    S: 1.72413793103448,
    M: 17.24137931034483,
    L: 48.27586206896552,
    XL: 32.75862068965517,
  },
  {
    name: 'Dogo Argentino',
    weight: 110,
    total: 45,
    XS: 0,
    S: 2.22222222222222,
    M: 13.33333333333333,
    L: 28.88888888888889,
    XL: 55.55555555555556,
  },
  {
    name: 'Dogo Argentino',
    weight: 120,
    total: 28,
    XS: 0,
    S: 0,
    M: 7.14285714285714,
    L: 32.14285714285714,
    XL: 60.71428571428571,
  },
  {
    name: 'Double Doodle',
    weight: 30,
    total: 28,
    XS: 3.57142857142857,
    S: 21.42857142857143,
    M: 71.42857142857143,
    L: 3.57142857142857,
    XL: 0,
  },
  {
    name: 'Double Doodle',
    weight: 40,
    total: 38,
    XS: 0,
    S: 2.63157894736842,
    M: 71.05263157894737,
    L: 26.31578947368421,
    XL: 0,
  },
  {
    name: 'Double Doodle',
    weight: 50,
    total: 48,
    XS: 0,
    S: 0,
    M: 47.91666666666667,
    L: 50,
    XL: 2.08333333333333,
  },
  {
    name: 'Double Doodle',
    weight: 60,
    total: 38,
    XS: 0,
    S: 2.63157894736842,
    M: 50,
    L: 47.36842105263158,
    XL: 0,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 20,
    total: 30,
    XS: 0,
    S: 23.33333333333333,
    M: 63.33333333333333,
    L: 10,
    XL: 3.33333333333333,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 30,
    total: 40,
    XS: 0,
    S: 20,
    M: 55,
    L: 22.5,
    XL: 2.5,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 40,
    total: 87,
    XS: 0,
    S: 9.19540229885057,
    M: 58.62068965517241,
    L: 31.03448275862069,
    XL: 1.14942528735632,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 50,
    total: 214,
    XS: 0,
    S: 5.14018691588785,
    M: 51.86915887850467,
    L: 40.65420560747664,
    XL: 2.33644859813084,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 60,
    total: 284,
    XS: 0,
    S: 4.22535211267606,
    M: 32.04225352112676,
    L: 63.02816901408451,
    XL: 0.70422535211268,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 70,
    total: 247,
    XS: 0.40485829959514,
    S: 2.83400809716599,
    M: 35.22267206477733,
    L: 59.10931174089069,
    XL: 2.42914979757085,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 80,
    total: 146,
    XS: 0.68493150684932,
    S: 5.47945205479452,
    M: 20.54794520547945,
    L: 67.80821917808218,
    XL: 5.47945205479452,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 90,
    total: 71,
    XS: 0,
    S: 1.40845070422535,
    M: 16.90140845070423,
    L: 76.05633802816901,
    XL: 5.63380281690141,
  },
  {
    name: 'East Asian Village Dog',
    weight: 30,
    total: 28,
    XS: 3.57142857142857,
    S: 17.85714285714286,
    M: 67.85714285714286,
    L: 7.14285714285714,
    XL: 3.57142857142857,
  },
  {
    name: 'East Asian Village Dog',
    weight: 40,
    total: 36,
    XS: 0,
    S: 5.55555555555556,
    M: 86.11111111111111,
    L: 8.33333333333333,
    XL: 0,
  },
  {
    name: 'English Bulldog',
    weight: 30,
    total: 43,
    XS: 0,
    S: 11.62790697674419,
    M: 48.83720930232558,
    L: 39.53488372093023,
    XL: 0,
  },
  {
    name: 'English Bulldog',
    weight: 40,
    total: 52,
    XS: 0,
    S: 3.84615384615385,
    M: 46.15384615384615,
    L: 40.38461538461538,
    XL: 9.61538461538462,
  },
  {
    name: 'English Bulldog',
    weight: 50,
    total: 135,
    XS: 0,
    S: 3.7037037037037,
    M: 25.18518518518519,
    L: 55.55555555555556,
    XL: 15.55555555555556,
  },
  {
    name: 'English Bulldog',
    weight: 60,
    total: 139,
    XS: 0,
    S: 0.71942446043165,
    M: 20.14388489208633,
    L: 51.79856115107914,
    XL: 27.33812949640288,
  },
  {
    name: 'English Bulldog',
    weight: 70,
    total: 68,
    XS: 0,
    S: 2.94117647058824,
    M: 17.64705882352941,
    L: 50,
    XL: 29.41176470588235,
  },
  {
    name: 'English Bulldog',
    weight: 80,
    total: 27,
    XS: 0,
    S: 0,
    M: 11.11111111111111,
    L: 40.74074074074074,
    XL: 48.14814814814815,
  },
  {
    name: 'English Cocker Spaniel',
    weight: 20,
    total: 84,
    XS: 2.38095238095238,
    S: 53.57142857142857,
    M: 42.85714285714286,
    L: 1.19047619047619,
    XL: 0,
  },
  {
    name: 'English Cocker Spaniel',
    weight: 30,
    total: 287,
    XS: 0.34843205574913,
    S: 21.60278745644599,
    M: 75.26132404181185,
    L: 2.78745644599303,
    XL: 0,
  },
  {
    name: 'English Cocker Spaniel',
    weight: 40,
    total: 149,
    XS: 0,
    S: 6.04026845637584,
    M: 83.89261744966443,
    L: 9.39597315436242,
    XL: 0.67114093959732,
  },
  {
    name: 'English Pointer',
    weight: 40,
    total: 68,
    XS: 0,
    S: 1.47058823529412,
    M: 80.88235294117646,
    L: 17.64705882352941,
    XL: 0,
  },
  {
    name: 'English Pointer',
    weight: 50,
    total: 150,
    XS: 0,
    S: 3.33333333333333,
    M: 62,
    L: 33.33333333333333,
    XL: 1.33333333333333,
  },
  {
    name: 'English Pointer',
    weight: 60,
    total: 98,
    XS: 0,
    S: 1.02040816326531,
    M: 47.95918367346939,
    L: 51.02040816326531,
    XL: 0,
  },
  {
    name: 'English Pointer',
    weight: 70,
    total: 70,
    XS: 0,
    S: 0,
    M: 37.14285714285714,
    L: 62.85714285714286,
    XL: 0,
  },
  {
    name: 'English Setter',
    weight: 30,
    total: 61,
    XS: 1.63934426229508,
    S: 18.0327868852459,
    M: 65.57377049180327,
    L: 14.75409836065574,
    XL: 0,
  },
  {
    name: 'English Setter',
    weight: 40,
    total: 260,
    XS: 0.38461538461538,
    S: 6.92307692307692,
    M: 83.46153846153847,
    L: 9.23076923076923,
    XL: 0,
  },
  {
    name: 'English Setter',
    weight: 50,
    total: 403,
    XS: 0,
    S: 4.21836228287841,
    M: 74.19354838709677,
    L: 20.59553349875931,
    XL: 0.99255583126551,
  },
  {
    name: 'English Setter',
    weight: 60,
    total: 212,
    XS: 0,
    S: 1.41509433962264,
    M: 51.41509433962264,
    L: 46.22641509433962,
    XL: 0.94339622641509,
  },
  {
    name: 'English Setter',
    weight: 70,
    total: 79,
    XS: 0,
    S: 1.26582278481013,
    M: 45.56962025316456,
    L: 53.16455696202532,
    XL: 0,
  },
  {
    name: 'English Setter',
    weight: 80,
    total: 28,
    XS: 0,
    S: 0,
    M: 28.57142857142857,
    L: 71.42857142857143,
    XL: 0,
  },
  {
    name: 'English Shepherd',
    weight: 40,
    total: 51,
    XS: 0,
    S: 3.92156862745098,
    M: 88.23529411764706,
    L: 5.88235294117647,
    XL: 1.96078431372549,
  },
  {
    name: 'English Shepherd',
    weight: 50,
    total: 91,
    XS: 0,
    S: 4.3956043956044,
    M: 63.73626373626374,
    L: 28.57142857142857,
    XL: 3.2967032967033,
  },
  {
    name: 'English Shepherd',
    weight: 60,
    total: 78,
    XS: 0,
    S: 6.41025641025641,
    M: 41.02564102564103,
    L: 52.56410256410256,
    XL: 0,
  },
  {
    name: 'English Shepherd',
    weight: 70,
    total: 38,
    XS: 0,
    S: 0,
    M: 28.94736842105263,
    L: 71.05263157894737,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 20,
    total: 41,
    XS: 0,
    S: 31.70731707317073,
    M: 63.41463414634146,
    L: 4.8780487804878,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 30,
    total: 100,
    XS: 2,
    S: 12,
    M: 78,
    L: 8,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 40,
    total: 373,
    XS: 0.26809651474531,
    S: 5.36193029490617,
    M: 82.03753351206434,
    L: 12.33243967828418,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 50,
    total: 496,
    XS: 0.20161290322581,
    S: 1.20967741935484,
    M: 70.96774193548387,
    L: 27.62096774193548,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 60,
    total: 208,
    XS: 0,
    S: 4.80769230769231,
    M: 46.63461538461538,
    L: 48.55769230769231,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 70,
    total: 63,
    XS: 0,
    S: 1.58730158730159,
    M: 23.80952380952381,
    L: 73.01587301587303,
    XL: 1.58730158730159,
  },
  {
    name: 'Feist',
    weight: 20,
    total: 37,
    XS: 5.40540540540541,
    S: 62.16216216216216,
    M: 29.72972972972973,
    L: 2.7027027027027,
    XL: 0,
  },
  {
    name: 'Feist',
    weight: 30,
    total: 40,
    XS: 0,
    S: 22.5,
    M: 72.5,
    L: 5,
    XL: 0,
  },
  {
    name: 'Feist',
    weight: 40,
    total: 29,
    XS: 0,
    S: 10.3448275862069,
    M: 75.86206896551724,
    L: 13.79310344827586,
    XL: 0,
  },
  {
    name: 'Flat-Coated Retriever',
    weight: 50,
    total: 55,
    XS: 0,
    S: 0,
    M: 40,
    L: 58.18181818181818,
    XL: 1.81818181818182,
  },
  {
    name: 'Flat-Coated Retriever',
    weight: 60,
    total: 83,
    XS: 0,
    S: 1.20481927710843,
    M: 34.93975903614458,
    L: 61.44578313253012,
    XL: 2.40963855421687,
  },
  {
    name: 'Flat-Coated Retriever',
    weight: 70,
    total: 89,
    XS: 0,
    S: 2.24719101123596,
    M: 28.08988764044944,
    L: 67.41573033707866,
    XL: 2.24719101123596,
  },
  {
    name: 'Flat-Coated Retriever',
    weight: 80,
    total: 55,
    XS: 0,
    S: 0,
    M: 25.45454545454545,
    L: 70.9090909090909,
    XL: 3.63636363636364,
  },
  {
    name: 'Flat-Coated Retriever',
    weight: 90,
    total: 29,
    XS: 0,
    S: 0,
    M: 20.68965517241379,
    L: 68.96551724137932,
    XL: 10.3448275862069,
  },
  {
    name: 'Foxy Rat Terrier',
    weight: 20,
    total: 43,
    XS: 9.30232558139535,
    S: 55.81395348837209,
    M: 30.23255813953488,
    L: 4.65116279069767,
    XL: 0,
  },
  {
    name: 'Foxy Rat Terrier',
    weight: 30,
    total: 28,
    XS: 0,
    S: 28.57142857142857,
    M: 64.28571428571429,
    L: 7.14285714285714,
    XL: 0,
  },
  {
    name: 'French Bulldog',
    weight: 10,
    total: 241,
    XS: 10.78838174273859,
    S: 65.56016597510373,
    M: 21.57676348547718,
    L: 2.0746887966805,
    XL: 0,
  },
  {
    name: 'French Bulldog',
    weight: 20,
    total: 1209,
    XS: 0.99255583126551,
    S: 41.77005789909016,
    M: 48.9660876757651,
    L: 7.77502067824648,
    XL: 0.49627791563275,
  },
  {
    name: 'French Bulldog',
    weight: 30,
    total: 2677,
    XS: 0.29884198729922,
    S: 18.78968995143818,
    M: 56.48113559955174,
    L: 24.05677997758685,
    XL: 0.37355248412402,
  },
  {
    name: 'French Bulldog',
    weight: 40,
    total: 679,
    XS: 0.14727540500736,
    S: 8.68924889543446,
    M: 47.5699558173785,
    L: 42.70986745213549,
    XL: 0.88365243004418,
  },
  {
    name: 'French Bulldog',
    weight: 50,
    total: 53,
    XS: 0,
    S: 1.88679245283019,
    M: 45.28301886792453,
    L: 45.28301886792453,
    XL: 7.54716981132075,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 40,
    total: 34,
    XS: 0,
    S: 0,
    M: 47.05882352941176,
    L: 50,
    XL: 2.94117647058824,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 50,
    total: 82,
    XS: 0,
    S: 2.4390243902439,
    M: 41.46341463414634,
    L: 56.09756097560976,
    XL: 0,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 60,
    total: 111,
    XS: 0,
    S: 0.9009009009009,
    M: 33.33333333333333,
    L: 63.96396396396396,
    XL: 1.8018018018018,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 70,
    total: 112,
    XS: 0,
    S: 0.89285714285714,
    M: 20.53571428571429,
    L: 75.89285714285714,
    XL: 2.67857142857143,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 80,
    total: 71,
    XS: 0,
    S: 0,
    M: 19.71830985915493,
    L: 60.56338028169014,
    XL: 19.71830985915493,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 90,
    total: 36,
    XS: 0,
    S: 0,
    M: 13.88888888888889,
    L: 72.22222222222221,
    XL: 13.88888888888889,
  },
  {
    name: 'German Australian Shepherd',
    weight: 40,
    total: 35,
    XS: 0,
    S: 2.85714285714286,
    M: 68.57142857142857,
    L: 25.71428571428571,
    XL: 2.85714285714286,
  },
  {
    name: 'German Australian Shepherd',
    weight: 50,
    total: 57,
    XS: 1.75438596491228,
    S: 3.50877192982456,
    M: 38.59649122807018,
    L: 56.14035087719298,
    XL: 0,
  },
  {
    name: 'German Australian Shepherd',
    weight: 60,
    total: 43,
    XS: 0,
    S: 0,
    M: 30.23255813953488,
    L: 69.76744186046513,
    XL: 0,
  },
  {
    name: 'German Australian Shepherd',
    weight: 70,
    total: 55,
    XS: 0,
    S: 0,
    M: 23.63636363636364,
    L: 74.54545454545455,
    XL: 1.81818181818182,
  },
  {
    name: 'German Malinois',
    weight: 60,
    total: 31,
    XS: 0,
    S: 9.67741935483871,
    M: 41.93548387096774,
    L: 48.38709677419355,
    XL: 0,
  },
  {
    name: 'German Malinois',
    weight: 70,
    total: 37,
    XS: 0,
    S: 0,
    M: 16.21621621621622,
    L: 78.37837837837839,
    XL: 5.40540540540541,
  },
  {
    name: 'German Malinois',
    weight: 80,
    total: 28,
    XS: 0,
    S: 3.57142857142857,
    M: 32.14285714285714,
    L: 60.71428571428571,
    XL: 3.57142857142857,
  },
  {
    name: 'German Pinscher',
    weight: 40,
    total: 41,
    XS: 0,
    S: 4.8780487804878,
    M: 80.48780487804878,
    L: 14.63414634146341,
    XL: 0,
  },
  {
    name: 'German Shepherd Dog',
    weight: 10,
    total: 105,
    XS: 5.71428571428571,
    S: 23.80952380952381,
    M: 35.23809523809524,
    L: 29.52380952380952,
    XL: 5.71428571428571,
  },
  {
    name: 'German Shepherd Dog',
    weight: 20,
    total: 274,
    XS: 3.28467153284672,
    S: 27.73722627737226,
    M: 40.51094890510949,
    L: 26.64233576642336,
    XL: 1.82481751824818,
  },
  {
    name: 'German Shepherd Dog',
    weight: 30,
    total: 666,
    XS: 1.2012012012012,
    S: 10.06006006006006,
    M: 57.50750750750751,
    L: 30.18018018018018,
    XL: 1.05105105105105,
  },
  {
    name: 'German Shepherd Dog',
    weight: 40,
    total: 1318,
    XS: 0.15174506828528,
    S: 4.85584218512898,
    M: 59.25644916540212,
    L: 34.44613050075873,
    XL: 1.28983308042489,
  },
  {
    name: 'German Shepherd Dog',
    weight: 50,
    total: 2955,
    XS: 0.20304568527919,
    S: 2.23350253807107,
    M: 46.32825719120135,
    L: 50.08460236886633,
    XL: 1.15059221658206,
  },
  {
    name: 'German Shepherd Dog',
    weight: 60,
    total: 4383,
    XS: 0.0684462696783,
    S: 1.57426420260096,
    M: 30.59548254620123,
    L: 65.61715719826603,
    XL: 2.14464978325348,
  },
  {
    name: 'German Shepherd Dog',
    weight: 70,
    total: 5905,
    XS: 0.01693480101609,
    S: 1.11769686706181,
    M: 22.23539373412362,
    L: 73.09060118543607,
    XL: 3.5393734123624,
  },
  {
    name: 'German Shepherd Dog',
    weight: 80,
    total: 5884,
    XS: 0.08497620666213,
    S: 0.67980965329708,
    M: 18.04894629503739,
    L: 73.94629503738953,
    XL: 7.23997280761387,
  },
  {
    name: 'German Shepherd Dog',
    weight: 90,
    total: 3867,
    XS: 0.07757951900698,
    S: 0.62063615205586,
    M: 17.76570985259891,
    L: 68.96819239720713,
    XL: 12.56788207913111,
  },
  {
    name: 'German Shepherd Dog',
    weight: 100,
    total: 2341,
    XS: 0.1281503630927,
    S: 0.34173430158052,
    M: 15.20717642033319,
    L: 63.43442973088424,
    XL: 20.88850918410935,
  },
  {
    name: 'German Shepherd Dog',
    weight: 110,
    total: 802,
    XS: 0,
    S: 0.12468827930175,
    M: 16.58354114713217,
    L: 50.37406483790524,
    XL: 32.91770573566085,
  },
  {
    name: 'German Shepherd Dog',
    weight: 120,
    total: 332,
    XS: 0,
    S: 0.30120481927711,
    M: 12.34939759036145,
    L: 50.30120481927711,
    XL: 37.04819277108434,
  },
  {
    name: 'German Shepherd Dog',
    weight: 130,
    total: 130,
    XS: 0,
    S: 1.53846153846154,
    M: 15.38461538461538,
    L: 33.84615384615385,
    XL: 49.23076923076923,
  },
  {
    name: 'German Shepherd Dog',
    weight: 140,
    total: 57,
    XS: 0,
    S: 0,
    M: 17.54385964912281,
    L: 29.82456140350877,
    XL: 52.63157894736842,
  },
  {
    name: 'German Sheprador',
    weight: 50,
    total: 35,
    XS: 0,
    S: 0,
    M: 37.14285714285714,
    L: 60,
    XL: 2.85714285714286,
  },
  {
    name: 'German Sheprador',
    weight: 60,
    total: 41,
    XS: 0,
    S: 2.4390243902439,
    M: 31.70731707317073,
    L: 63.41463414634146,
    XL: 2.4390243902439,
  },
  {
    name: 'German Sheprador',
    weight: 70,
    total: 45,
    XS: 0,
    S: 0,
    M: 20,
    L: 73.33333333333333,
    XL: 6.66666666666667,
  },
  {
    name: 'German Sheprador',
    weight: 80,
    total: 34,
    XS: 0,
    S: 0,
    M: 17.64705882352941,
    L: 82.3529411764706,
    XL: 0,
  },
  {
    name: 'German Shorthaired Lab',
    weight: 50,
    total: 43,
    XS: 0,
    S: 4.65116279069767,
    M: 60.46511627906977,
    L: 34.88372093023256,
    XL: 0,
  },
  {
    name: 'German Shorthaired Lab',
    weight: 60,
    total: 49,
    XS: 0,
    S: 0,
    M: 32.6530612244898,
    L: 67.3469387755102,
    XL: 0,
  },
  {
    name: 'German Shorthaired Lab',
    weight: 70,
    total: 54,
    XS: 0,
    S: 0,
    M: 20.37037037037037,
    L: 77.77777777777779,
    XL: 1.85185185185185,
  },
  {
    name: 'German Shorthaired Lab',
    weight: 80,
    total: 32,
    XS: 0,
    S: 0,
    M: 6.25,
    L: 87.5,
    XL: 6.25,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 10,
    total: 36,
    XS: 2.77777777777778,
    S: 33.33333333333333,
    M: 47.22222222222222,
    L: 16.66666666666667,
    XL: 0,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 20,
    total: 144,
    XS: 2.08333333333333,
    S: 38.88888888888889,
    M: 45.13888888888889,
    L: 13.88888888888889,
    XL: 0,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 30,
    total: 232,
    XS: 0.86206896551724,
    S: 15.94827586206897,
    M: 66.8103448275862,
    L: 15.94827586206897,
    XL: 0.43103448275862,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 40,
    total: 805,
    XS: 0.24844720496894,
    S: 5.09316770186335,
    M: 75.03105590062113,
    L: 19.37888198757764,
    XL: 0.24844720496894,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 50,
    total: 2177,
    XS: 0.32154340836013,
    S: 1.8833256775379,
    M: 66.51355075792375,
    L: 30.7762976573266,
    XL: 0.50528249885163,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 60,
    total: 2249,
    XS: 0.13339261894175,
    S: 1.33392618941752,
    M: 49.53312583370387,
    L: 48.51044908848377,
    XL: 0.48910626945309,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 70,
    total: 1254,
    XS: 0,
    S: 0.7177033492823,
    M: 36.04465709728868,
    L: 62.67942583732057,
    XL: 0.55821371610845,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 80,
    total: 492,
    XS: 0,
    S: 0.60975609756098,
    M: 27.4390243902439,
    L: 68.69918699186992,
    XL: 3.2520325203252,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 90,
    total: 126,
    XS: 0,
    S: 0,
    M: 26.19047619047619,
    L: 68.25396825396825,
    XL: 5.55555555555556,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 100,
    total: 33,
    XS: 0,
    S: 0,
    M: 18.18181818181818,
    L: 72.72727272727273,
    XL: 9.09090909090909,
  },
  {
    name: 'German Wirehaired Pointer',
    weight: 40,
    total: 41,
    XS: 2.4390243902439,
    S: 7.31707317073171,
    M: 73.17073170731707,
    L: 17.07317073170732,
    XL: 0,
  },
  {
    name: 'German Wirehaired Pointer',
    weight: 50,
    total: 156,
    XS: 0,
    S: 1.92307692307692,
    M: 62.82051282051282,
    L: 35.25641025641026,
    XL: 0,
  },
  {
    name: 'German Wirehaired Pointer',
    weight: 60,
    total: 165,
    XS: 0.60606060606061,
    S: 1.21212121212121,
    M: 46.66666666666667,
    L: 49.09090909090909,
    XL: 2.42424242424242,
  },
  {
    name: 'German Wirehaired Pointer',
    weight: 70,
    total: 167,
    XS: 0,
    S: 1.19760479041916,
    M: 32.93413173652695,
    L: 64.07185628742515,
    XL: 1.79640718562874,
  },
  {
    name: 'German Wirehaired Pointer',
    weight: 80,
    total: 66,
    XS: 0,
    S: 0,
    M: 19.6969696969697,
    L: 78.7878787878788,
    XL: 1.51515151515152,
  },
  {
    name: 'Giant Schnauzer',
    weight: 60,
    total: 40,
    XS: 0,
    S: 0,
    M: 40,
    L: 60,
    XL: 0,
  },
  {
    name: 'Giant Schnauzer',
    weight: 70,
    total: 77,
    XS: 0,
    S: 0,
    M: 36.36363636363636,
    L: 63.63636363636364,
    XL: 0,
  },
  {
    name: 'Giant Schnauzer',
    weight: 80,
    total: 79,
    XS: 0,
    S: 1.26582278481013,
    M: 21.51898734177215,
    L: 67.0886075949367,
    XL: 10.12658227848101,
  },
  {
    name: 'Giant Schnauzer',
    weight: 90,
    total: 87,
    XS: 0,
    S: 1.14942528735632,
    M: 17.24137931034483,
    L: 70.11494252873563,
    XL: 11.49425287356322,
  },
  {
    name: 'Giant Schnauzer',
    weight: 100,
    total: 53,
    XS: 0,
    S: 0,
    M: 30.18867924528302,
    L: 52.83018867924528,
    XL: 16.9811320754717,
  },
  {
    name: 'Goldadoor',
    weight: 70,
    total: 44,
    XS: 0,
    S: 0,
    M: 20.45454545454545,
    L: 75,
    XL: 4.54545454545455,
  },
  {
    name: 'Goldadoor',
    weight: 80,
    total: 33,
    XS: 0,
    S: 0,
    M: 15.15151515151515,
    L: 72.72727272727273,
    XL: 12.12121212121212,
  },
  {
    name: 'Golden Border Retriever',
    weight: 50,
    total: 32,
    XS: 0,
    S: 3.125,
    M: 53.125,
    L: 43.75,
    XL: 0,
  },
  {
    name: 'Golden Pyrenees',
    weight: 70,
    total: 36,
    XS: 0,
    S: 0,
    M: 5.55555555555556,
    L: 86.11111111111111,
    XL: 8.33333333333333,
  },
  {
    name: 'Golden Retriever',
    weight: 10,
    total: 106,
    XS: 10.37735849056604,
    S: 33.9622641509434,
    M: 33.0188679245283,
    L: 19.81132075471698,
    XL: 2.83018867924528,
  },
  {
    name: 'Golden Retriever',
    weight: 20,
    total: 330,
    XS: 5.75757575757576,
    S: 26.66666666666667,
    M: 44.24242424242424,
    L: 22.42424242424242,
    XL: 0.90909090909091,
  },
  {
    name: 'Golden Retriever',
    weight: 30,
    total: 626,
    XS: 1.75718849840256,
    S: 14.85623003194888,
    M: 51.43769968051118,
    L: 30.67092651757188,
    XL: 1.2779552715655,
  },
  {
    name: 'Golden Retriever',
    weight: 40,
    total: 911,
    XS: 0.21953896816685,
    S: 5.26893523600439,
    M: 49.28649835345774,
    L: 44.12733260153677,
    XL: 1.09769484083425,
  },
  {
    name: 'Golden Retriever',
    weight: 50,
    total: 2009,
    XS: 0.44798407167745,
    S: 2.88700846192135,
    M: 39.42259830761573,
    L: 56.14733698357392,
    XL: 1.09507217521155,
  },
  {
    name: 'Golden Retriever',
    weight: 60,
    total: 3679,
    XS: 0.16308779559663,
    S: 1.41342756183746,
    M: 27.01821147050829,
    L: 69.58412612122859,
    XL: 1.82114705082903,
  },
  {
    name: 'Golden Retriever',
    weight: 70,
    total: 5073,
    XS: 0.07884880741179,
    S: 1.26158091858861,
    M: 19.2193968066233,
    L: 76.04967474866943,
    XL: 3.39049871870688,
  },
  {
    name: 'Golden Retriever',
    weight: 80,
    total: 4226,
    XS: 0.04732607666824,
    S: 0.82820634169427,
    M: 16.94273544723142,
    L: 75.01183151916706,
    XL: 7.169900615239,
  },
  {
    name: 'Golden Retriever',
    weight: 90,
    total: 1944,
    XS: 0.05144032921811,
    S: 0.61728395061728,
    M: 15.84362139917695,
    L: 70.11316872427984,
    XL: 13.37448559670782,
  },
  {
    name: 'Golden Retriever',
    weight: 100,
    total: 862,
    XS: 0,
    S: 0.81206496519722,
    M: 13.68909512761021,
    L: 62.18097447795824,
    XL: 23.31786542923434,
  },
  {
    name: 'Golden Retriever',
    weight: 110,
    total: 219,
    XS: 0.45662100456621,
    S: 0.91324200913242,
    M: 11.41552511415525,
    L: 53.88127853881279,
    XL: 33.33333333333333,
  },
  {
    name: 'Golden Retriever',
    weight: 120,
    total: 65,
    XS: 0,
    S: 0,
    M: 9.23076923076923,
    L: 53.84615384615385,
    XL: 36.92307692307692,
  },
  {
    name: 'Golden Retriever',
    weight: 130,
    total: 31,
    XS: 0,
    S: 3.2258064516129,
    M: 3.2258064516129,
    L: 22.58064516129032,
    XL: 70.96774193548387,
  },
  {
    name: 'Goldendoodle',
    weight: 10,
    total: 264,
    XS: 21.21212121212121,
    S: 44.31818181818182,
    M: 26.51515151515152,
    L: 7.57575757575758,
    XL: 0.37878787878788,
  },
  {
    name: 'Goldendoodle',
    weight: 20,
    total: 1411,
    XS: 4.39404677533664,
    S: 58.39829907866761,
    M: 34.01842664776754,
    L: 3.11835577604536,
    XL: 0.07087172218285,
  },
  {
    name: 'Goldendoodle',
    weight: 30,
    total: 2190,
    XS: 0.95890410958904,
    S: 27.71689497716895,
    M: 65.79908675799086,
    L: 5.38812785388128,
    XL: 0.13698630136986,
  },
  {
    name: 'Goldendoodle',
    weight: 40,
    total: 1980,
    XS: 0.3030303030303,
    S: 7.07070707070707,
    M: 75.70707070707071,
    L: 16.46464646464646,
    XL: 0.45454545454545,
  },
  {
    name: 'Goldendoodle',
    weight: 50,
    total: 2357,
    XS: 0.25456088247773,
    S: 2.24862112855325,
    M: 54.43360203648706,
    L: 42.21467967755622,
    XL: 0.84853627492575,
  },
  {
    name: 'Goldendoodle',
    weight: 60,
    total: 2095,
    XS: 0.1909307875895,
    S: 1.47971360381862,
    M: 36.65871121718377,
    L: 61.05011933174224,
    XL: 0.62052505966587,
  },
  {
    name: 'Goldendoodle',
    weight: 70,
    total: 1611,
    XS: 0.18621973929236,
    S: 1.11731843575419,
    M: 23.02917442582247,
    L: 73.9292364990689,
    XL: 1.73805090006207,
  },
  {
    name: 'Goldendoodle',
    weight: 80,
    total: 875,
    XS: 0.11428571428571,
    S: 0.57142857142857,
    M: 19.42857142857143,
    L: 74.28571428571429,
    XL: 5.6,
  },
  {
    name: 'Goldendoodle',
    weight: 90,
    total: 341,
    XS: 0.29325513196481,
    S: 0.87976539589443,
    M: 16.42228739002933,
    L: 74.19354838709677,
    XL: 8.21114369501466,
  },
  {
    name: 'Goldendoodle',
    weight: 100,
    total: 127,
    XS: 0,
    S: 0,
    M: 14.96062992125984,
    L: 70.07874015748031,
    XL: 14.96062992125984,
  },
  {
    name: 'Goldendoodle',
    weight: 110,
    total: 31,
    XS: 3.2258064516129,
    S: 3.2258064516129,
    M: 12.90322580645161,
    L: 51.61290322580645,
    XL: 29.03225806451613,
  },
  {
    name: 'Gordon Setter',
    weight: 50,
    total: 37,
    XS: 0,
    S: 2.7027027027027,
    M: 62.16216216216216,
    L: 35.13513513513514,
    XL: 0,
  },
  {
    name: 'Gordon Setter',
    weight: 60,
    total: 33,
    XS: 0,
    S: 0,
    M: 42.42424242424242,
    L: 57.57575757575758,
    XL: 0,
  },
  {
    name: 'Great Dane',
    weight: 20,
    total: 30,
    XS: 16.66666666666667,
    S: 23.33333333333333,
    M: 16.66666666666667,
    L: 23.33333333333333,
    XL: 20,
  },
  {
    name: 'Great Dane',
    weight: 30,
    total: 43,
    XS: 2.32558139534884,
    S: 11.62790697674419,
    M: 41.86046511627907,
    L: 25.58139534883721,
    XL: 18.6046511627907,
  },
  {
    name: 'Great Dane',
    weight: 40,
    total: 38,
    XS: 0,
    S: 7.89473684210526,
    M: 50,
    L: 31.57894736842105,
    XL: 10.52631578947368,
  },
  {
    name: 'Great Dane',
    weight: 50,
    total: 66,
    XS: 0,
    S: 1.51515151515152,
    M: 51.51515151515152,
    L: 36.36363636363636,
    XL: 10.60606060606061,
  },
  {
    name: 'Great Dane',
    weight: 60,
    total: 102,
    XS: 0,
    S: 1.96078431372549,
    M: 28.43137254901961,
    L: 53.92156862745098,
    XL: 15.68627450980392,
  },
  {
    name: 'Great Dane',
    weight: 70,
    total: 144,
    XS: 0.69444444444444,
    S: 3.47222222222222,
    M: 22.22222222222222,
    L: 60.41666666666667,
    XL: 13.19444444444444,
  },
  {
    name: 'Great Dane',
    weight: 80,
    total: 203,
    XS: 0,
    S: 0.98522167487685,
    M: 21.67487684729064,
    L: 60.09852216748768,
    XL: 17.24137931034483,
  },
  {
    name: 'Great Dane',
    weight: 90,
    total: 225,
    XS: 0,
    S: 0.44444444444444,
    M: 19.11111111111111,
    L: 52.44444444444444,
    XL: 28,
  },
  {
    name: 'Great Dane',
    weight: 100,
    total: 347,
    XS: 0,
    S: 0.57636887608069,
    M: 18.44380403458213,
    L: 45.82132564841499,
    XL: 35.15850144092219,
  },
  {
    name: 'Great Dane',
    weight: 110,
    total: 314,
    XS: 0.31847133757962,
    S: 1.27388535031847,
    M: 14.3312101910828,
    L: 38.85350318471338,
    XL: 45.22292993630573,
  },
  {
    name: 'Great Dane',
    weight: 120,
    total: 391,
    XS: 0.51150895140665,
    S: 1.0230179028133,
    M: 12.02046035805627,
    L: 31.71355498721228,
    XL: 54.73145780051151,
  },
  {
    name: 'Great Dane',
    weight: 130,
    total: 396,
    XS: 0.25252525252525,
    S: 0.75757575757576,
    M: 12.62626262626263,
    L: 24.24242424242424,
    XL: 62.12121212121212,
  },
  {
    name: 'Great Dane',
    weight: 140,
    total: 299,
    XS: 0,
    S: 0.66889632107023,
    M: 17.05685618729097,
    L: 15.38461538461538,
    XL: 66.88963210702342,
  },
  {
    name: 'Great Dane',
    weight: 150,
    total: 271,
    XS: 0,
    S: 1.8450184501845,
    M: 15.49815498154982,
    L: 14.02214022140221,
    XL: 68.63468634686348,
  },
  {
    name: 'Great Dane',
    weight: 160,
    total: 131,
    XS: 0,
    S: 1.52671755725191,
    M: 6.10687022900763,
    L: 13.74045801526718,
    XL: 78.62595419847328,
  },
  {
    name: 'Great Dane',
    weight: 170,
    total: 94,
    XS: 0,
    S: 0,
    M: 14.8936170212766,
    L: 5.31914893617021,
    XL: 79.7872340425532,
  },
  {
    name: 'Great Dane',
    weight: 180,
    total: 64,
    XS: 0,
    S: 1.5625,
    M: 15.625,
    L: 7.8125,
    XL: 75,
  },
  {
    name: 'Great Dane',
    weight: 190,
    total: 33,
    XS: 0,
    S: 3.03030303030303,
    M: 0,
    L: 3.03030303030303,
    XL: 93.93939393939394,
  },
  {
    name: 'Great Pyrenees',
    weight: 20,
    total: 34,
    XS: 0,
    S: 14.70588235294118,
    M: 41.17647058823529,
    L: 20.58823529411765,
    XL: 23.52941176470588,
  },
  {
    name: 'Great Pyrenees',
    weight: 30,
    total: 106,
    XS: 0.94339622641509,
    S: 7.54716981132075,
    M: 45.28301886792453,
    L: 26.41509433962264,
    XL: 19.81132075471698,
  },
  {
    name: 'Great Pyrenees',
    weight: 40,
    total: 145,
    XS: 0,
    S: 4.13793103448276,
    M: 47.58620689655172,
    L: 29.6551724137931,
    XL: 18.62068965517241,
  },
  {
    name: 'Great Pyrenees',
    weight: 50,
    total: 269,
    XS: 0,
    S: 1.48698884758364,
    M: 35.68773234200743,
    L: 50.18587360594796,
    XL: 12.63940520446097,
  },
  {
    name: 'Great Pyrenees',
    weight: 60,
    total: 486,
    XS: 0,
    S: 1.02880658436214,
    M: 30.04115226337449,
    L: 55.76131687242798,
    XL: 13.16872427983539,
  },
  {
    name: 'Great Pyrenees',
    weight: 70,
    total: 781,
    XS: 0.1280409731114,
    S: 0.89628681177977,
    M: 21.12676056338028,
    L: 60.69142125480154,
    XL: 17.15749039692702,
  },
  {
    name: 'Great Pyrenees',
    weight: 80,
    total: 1082,
    XS: 0,
    S: 0.92421441774492,
    M: 16.91312384473198,
    L: 52.95748613678373,
    XL: 29.20517560073937,
  },
  {
    name: 'Great Pyrenees',
    weight: 90,
    total: 1115,
    XS: 0.08968609865471,
    S: 0.89686098654709,
    M: 15.42600896860987,
    L: 42.152466367713,
    XL: 41.43497757847534,
  },
  {
    name: 'Great Pyrenees',
    weight: 100,
    total: 1221,
    XS: 0,
    S: 0.65520065520066,
    M: 14.82391482391482,
    L: 27.92792792792793,
    XL: 56.59295659295659,
  },
  {
    name: 'Great Pyrenees',
    weight: 110,
    total: 548,
    XS: 0,
    S: 0.54744525547445,
    M: 13.68613138686131,
    L: 23.17518248175182,
    XL: 62.59124087591241,
  },
  {
    name: 'Great Pyrenees',
    weight: 120,
    total: 504,
    XS: 0,
    S: 0.1984126984127,
    M: 13.69047619047619,
    L: 17.85714285714286,
    XL: 68.25396825396825,
  },
  {
    name: 'Great Pyrenees',
    weight: 130,
    total: 328,
    XS: 0,
    S: 0.60975609756098,
    M: 12.5,
    L: 10.97560975609756,
    XL: 75.91463414634146,
  },
  {
    name: 'Great Pyrenees',
    weight: 140,
    total: 143,
    XS: 0,
    S: 0,
    M: 13.98601398601399,
    L: 6.99300699300699,
    XL: 79.02097902097903,
  },
  {
    name: 'Great Pyrenees',
    weight: 150,
    total: 98,
    XS: 1.02040816326531,
    S: 0,
    M: 11.22448979591837,
    L: 7.14285714285714,
    XL: 80.61224489795919,
  },
  {
    name: 'Great Pyrenees',
    weight: 160,
    total: 34,
    XS: 0,
    S: 0,
    M: 5.88235294117647,
    L: 14.70588235294118,
    XL: 79.41176470588235,
  },
  {
    name: 'Greater Swiss Mountain Dog',
    weight: 110,
    total: 26,
    XS: 0,
    S: 0,
    M: 23.07692307692308,
    L: 26.92307692307692,
    XL: 50,
  },
  {
    name: 'Greater Swiss Mountain Dog',
    weight: 120,
    total: 27,
    XS: 0,
    S: 0,
    M: 11.11111111111111,
    L: 18.51851851851852,
    XL: 70.37037037037037,
  },
  {
    name: 'Greyhound',
    weight: 30,
    total: 28,
    XS: 0,
    S: 28.57142857142857,
    M: 64.28571428571429,
    L: 7.14285714285714,
    XL: 0,
  },
  {
    name: 'Greyhound',
    weight: 40,
    total: 50,
    XS: 0,
    S: 12,
    M: 74,
    L: 14,
    XL: 0,
  },
  {
    name: 'Greyhound',
    weight: 50,
    total: 123,
    XS: 0,
    S: 7.31707317073171,
    M: 72.35772357723577,
    L: 19.51219512195122,
    XL: 0.8130081300813,
  },
  {
    name: 'Greyhound',
    weight: 60,
    total: 270,
    XS: 0,
    S: 4.44444444444444,
    M: 70,
    L: 25.18518518518519,
    XL: 0.37037037037037,
  },
  {
    name: 'Greyhound',
    weight: 70,
    total: 444,
    XS: 0.22522522522523,
    S: 3.82882882882883,
    M: 70.04504504504504,
    L: 25.22522522522523,
    XL: 0.67567567567568,
  },
  {
    name: 'Greyhound',
    weight: 80,
    total: 336,
    XS: 0.29761904761905,
    S: 2.08333333333333,
    M: 59.22619047619048,
    L: 37.79761904761905,
    XL: 0.5952380952381,
  },
  {
    name: 'Greyhound',
    weight: 90,
    total: 105,
    XS: 0,
    S: 3.80952380952381,
    M: 45.71428571428571,
    L: 49.52380952380952,
    XL: 0.95238095238095,
  },
  {
    name: 'Havanese',
    weight: 10,
    total: 189,
    XS: 40.21164021164021,
    S: 49.20634920634921,
    M: 9.52380952380952,
    L: 1.05820105820106,
    XL: 0,
  },
  {
    name: 'Havanese',
    weight: 20,
    total: 548,
    XS: 7.48175182481752,
    S: 69.34306569343066,
    M: 21.71532846715328,
    L: 1.45985401459854,
    XL: 0,
  },
  {
    name: 'Havanese',
    weight: 30,
    total: 52,
    XS: 0,
    S: 30.76923076923077,
    M: 67.3076923076923,
    L: 1.92307692307692,
    XL: 0,
  },
  {
    name: 'Ibizan Hound',
    weight: 50,
    total: 32,
    XS: 0,
    S: 6.25,
    M: 78.125,
    L: 12.5,
    XL: 3.125,
  },
  {
    name: 'Icelandic Sheepdog',
    weight: 30,
    total: 33,
    XS: 0,
    S: 9.09090909090909,
    M: 84.84848484848484,
    L: 6.06060606060606,
    XL: 0,
  },
  {
    name: 'Irish Doodle',
    weight: 30,
    total: 27,
    XS: 0,
    S: 29.62962962962963,
    M: 66.66666666666667,
    L: 3.7037037037037,
    XL: 0,
  },
  {
    name: 'Irish Doodle',
    weight: 40,
    total: 41,
    XS: 0,
    S: 9.75609756097561,
    M: 75.60975609756098,
    L: 14.63414634146341,
    XL: 0,
  },
  {
    name: 'Irish Doodle',
    weight: 50,
    total: 54,
    XS: 0,
    S: 0,
    M: 72.22222222222221,
    L: 27.77777777777778,
    XL: 0,
  },
  {
    name: 'Irish Doodle',
    weight: 60,
    total: 42,
    XS: 0,
    S: 0,
    M: 57.14285714285714,
    L: 42.85714285714286,
    XL: 0,
  },
  {
    name: 'Irish Doodle',
    weight: 70,
    total: 30,
    XS: 0,
    S: 3.33333333333333,
    M: 30,
    L: 63.33333333333333,
    XL: 3.33333333333333,
  },
  {
    name: 'Irish Setter',
    weight: 40,
    total: 35,
    XS: 0,
    S: 8.57142857142857,
    M: 74.28571428571429,
    L: 17.14285714285714,
    XL: 0,
  },
  {
    name: 'Irish Setter',
    weight: 50,
    total: 102,
    XS: 0,
    S: 3.92156862745098,
    M: 67.6470588235294,
    L: 28.43137254901961,
    XL: 0,
  },
  {
    name: 'Irish Setter',
    weight: 60,
    total: 116,
    XS: 0,
    S: 3.44827586206897,
    M: 50,
    L: 45.68965517241379,
    XL: 0.86206896551724,
  },
  {
    name: 'Irish Setter',
    weight: 70,
    total: 116,
    XS: 0.86206896551724,
    S: 0.86206896551724,
    M: 28.44827586206897,
    L: 69.82758620689656,
    XL: 0,
  },
  {
    name: 'Irish Setter',
    weight: 80,
    total: 57,
    XS: 0,
    S: 0,
    M: 31.57894736842105,
    L: 66.66666666666667,
    XL: 1.75438596491228,
  },
  {
    name: 'Irish Setter',
    weight: 90,
    total: 33,
    XS: 0,
    S: 0,
    M: 24.24242424242424,
    L: 75.75757575757576,
    XL: 0,
  },
  {
    name: 'Irish Terrier',
    weight: 20,
    total: 26,
    XS: 0,
    S: 50,
    M: 42.30769230769231,
    L: 3.84615384615385,
    XL: 3.84615384615385,
  },
  {
    name: 'Irish Terrier',
    weight: 30,
    total: 54,
    XS: 0,
    S: 12.96296296296296,
    M: 83.33333333333333,
    L: 3.7037037037037,
    XL: 0,
  },
  {
    name: 'Irish Terrier',
    weight: 40,
    total: 66,
    XS: 0,
    S: 7.57575757575758,
    M: 80.3030303030303,
    L: 10.60606060606061,
    XL: 1.51515151515152,
  },
  {
    name: 'Irish Wolfhound',
    weight: 70,
    total: 30,
    XS: 0,
    S: 3.33333333333333,
    M: 20,
    L: 73.33333333333333,
    XL: 3.33333333333333,
  },
  {
    name: 'Irish Wolfhound',
    weight: 80,
    total: 29,
    XS: 0,
    S: 10.3448275862069,
    M: 27.58620689655172,
    L: 62.06896551724138,
    XL: 0,
  },
  {
    name: 'Irish Wolfhound',
    weight: 120,
    total: 26,
    XS: 0,
    S: 0,
    M: 11.53846153846154,
    L: 61.53846153846154,
    XL: 26.92307692307692,
  },
  {
    name: 'Italian Greyhound',
    weight: 10,
    total: 45,
    XS: 55.55555555555556,
    S: 35.55555555555556,
    M: 6.66666666666667,
    L: 2.22222222222222,
    XL: 0,
  },
  {
    name: 'Italian Greyhound',
    weight: 20,
    total: 177,
    XS: 28.8135593220339,
    S: 61.5819209039548,
    M: 9.6045197740113,
    L: 0,
    XL: 0,
  },
  {
    name: 'Italian Greyhound',
    weight: 30,
    total: 45,
    XS: 0,
    S: 44.44444444444444,
    M: 51.11111111111111,
    L: 4.44444444444444,
    XL: 0,
  },
  {
    name: 'Jack Chi',
    weight: 20,
    total: 53,
    XS: 7.54716981132075,
    S: 69.81132075471697,
    M: 22.64150943396226,
    L: 0,
    XL: 0,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 10,
    total: 274,
    XS: 21.16788321167883,
    S: 63.13868613138686,
    M: 11.31386861313869,
    L: 3.64963503649635,
    XL: 0.72992700729927,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 20,
    total: 1953,
    XS: 2.91858678955453,
    S: 67.94674859190988,
    M: 27.80337941628264,
    L: 1.12647209421403,
    XL: 0.20481310803891,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 30,
    total: 780,
    XS: 0.12820512820513,
    S: 33.07692307692308,
    M: 62.94871794871795,
    L: 3.58974358974359,
    XL: 0.25641025641026,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 40,
    total: 267,
    XS: 0.37453183520599,
    S: 8.98876404494382,
    M: 76.40449438202246,
    L: 13.48314606741573,
    XL: 0.74906367041199,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 50,
    total: 95,
    XS: 0,
    S: 1.05263157894737,
    M: 64.21052631578947,
    L: 33.68421052631579,
    XL: 1.05263157894737,
  },
  {
    name: 'Jack-Rat Terrier',
    weight: 20,
    total: 64,
    XS: 10.9375,
    S: 60.9375,
    M: 26.5625,
    L: 1.5625,
    XL: 0,
  },
  {
    name: 'Jack-Rat Terrier',
    weight: 30,
    total: 45,
    XS: 0,
    S: 33.33333333333333,
    M: 64.44444444444444,
    L: 0,
    XL: 2.22222222222222,
  },
  {
    name: 'Jagdterrier',
    weight: 20,
    total: 37,
    XS: 8.10810810810811,
    S: 45.94594594594595,
    M: 37.83783783783784,
    L: 5.40540540540541,
    XL: 2.7027027027027,
  },
  {
    name: 'Jagdterrier',
    weight: 30,
    total: 28,
    XS: 0,
    S: 17.85714285714286,
    M: 78.57142857142857,
    L: 3.57142857142857,
    XL: 0,
  },
  {
    name: 'Japanese Spitz',
    weight: 20,
    total: 40,
    XS: 0,
    S: 60,
    M: 35,
    L: 5,
    XL: 0,
  },
  {
    name: 'Jindo',
    weight: 20,
    total: 459,
    XS: 2.17864923747277,
    S: 35.94771241830065,
    M: 43.35511982570806,
    L: 16.55773420479303,
    XL: 1.96078431372549,
  },
  {
    name: 'Jindo',
    weight: 30,
    total: 567,
    XS: 0.35273368606702,
    S: 22.75132275132275,
    M: 58.73015873015873,
    L: 16.22574955908289,
    XL: 1.94003527336861,
  },
  {
    name: 'Jindo',
    weight: 40,
    total: 497,
    XS: 0,
    S: 12.27364185110664,
    M: 65.59356136820927,
    L: 21.73038229376258,
    XL: 0.40241448692153,
  },
  {
    name: 'Jindo',
    weight: 50,
    total: 292,
    XS: 0,
    S: 8.56164383561644,
    M: 55.82191780821918,
    L: 34.24657534246575,
    XL: 1.36986301369863,
  },
  {
    name: 'Jindo',
    weight: 60,
    total: 97,
    XS: 0,
    S: 4.12371134020619,
    M: 31.95876288659794,
    L: 59.79381443298969,
    XL: 4.12371134020619,
  },
  {
    name: 'Jindo',
    weight: 70,
    total: 37,
    XS: 0,
    S: 5.40540540540541,
    M: 24.32432432432432,
    L: 64.86486486486486,
    XL: 5.40540540540541,
  },
  {
    name: 'Karelian Bear Dog',
    weight: 60,
    total: 30,
    XS: 0,
    S: 0,
    M: 46.66666666666667,
    L: 53.33333333333333,
    XL: 0,
  },
  {
    name: 'Keeshond',
    weight: 30,
    total: 26,
    XS: 0,
    S: 0,
    M: 80.76923076923077,
    L: 19.23076923076923,
    XL: 0,
  },
  {
    name: 'Keeshond',
    weight: 40,
    total: 46,
    XS: 0,
    S: 4.34782608695652,
    M: 67.3913043478261,
    L: 28.26086956521739,
    XL: 0,
  },
  {
    name: 'Keeshond',
    weight: 50,
    total: 28,
    XS: 0,
    S: 0,
    M: 42.85714285714286,
    L: 57.14285714285714,
    XL: 0,
  },
  {
    name: 'Lab-Pointer',
    weight: 40,
    total: 33,
    XS: 0,
    S: 12.12121212121212,
    M: 69.6969696969697,
    L: 18.18181818181818,
    XL: 0,
  },
  {
    name: 'Lab-Pointer',
    weight: 50,
    total: 70,
    XS: 0,
    S: 1.42857142857143,
    M: 47.14285714285714,
    L: 50,
    XL: 1.42857142857143,
  },
  {
    name: 'Lab-Pointer',
    weight: 60,
    total: 93,
    XS: 0,
    S: 1.0752688172043,
    M: 35.48387096774194,
    L: 63.44086021505376,
    XL: 0,
  },
  {
    name: 'Lab-Pointer',
    weight: 70,
    total: 61,
    XS: 0,
    S: 0,
    M: 22.95081967213115,
    L: 72.1311475409836,
    XL: 4.91803278688525,
  },
  {
    name: 'Lab-Pointer',
    weight: 80,
    total: 36,
    XS: 0,
    S: 2.77777777777778,
    M: 16.66666666666667,
    L: 75,
    XL: 5.55555555555556,
  },
  {
    name: 'Labbe',
    weight: 50,
    total: 35,
    XS: 0,
    S: 2.85714285714286,
    M: 37.14285714285714,
    L: 60,
    XL: 0,
  },
  {
    name: 'Labrabull',
    weight: 40,
    total: 45,
    XS: 0,
    S: 0,
    M: 60,
    L: 40,
    XL: 0,
  },
  {
    name: 'Labrabull',
    weight: 50,
    total: 124,
    XS: 0,
    S: 0.80645161290323,
    M: 43.54838709677419,
    L: 55.64516129032258,
    XL: 0,
  },
  {
    name: 'Labrabull',
    weight: 60,
    total: 119,
    XS: 0,
    S: 0.84033613445378,
    M: 34.45378151260504,
    L: 63.02521008403361,
    XL: 1.68067226890756,
  },
  {
    name: 'Labrabull',
    weight: 70,
    total: 118,
    XS: 0,
    S: 0.84745762711864,
    M: 25.42372881355932,
    L: 72.03389830508475,
    XL: 1.69491525423729,
  },
  {
    name: 'Labrabull',
    weight: 80,
    total: 84,
    XS: 1.19047619047619,
    S: 1.19047619047619,
    M: 17.85714285714286,
    L: 72.61904761904762,
    XL: 7.14285714285714,
  },
  {
    name: 'Labrabull',
    weight: 90,
    total: 32,
    XS: 0,
    S: 0,
    M: 21.875,
    L: 50,
    XL: 28.125,
  },
  {
    name: 'Labradoodle',
    weight: 10,
    total: 39,
    XS: 10.25641025641026,
    S: 58.97435897435897,
    M: 20.51282051282051,
    L: 10.25641025641026,
    XL: 0,
  },
  {
    name: 'Labradoodle',
    weight: 20,
    total: 222,
    XS: 3.6036036036036,
    S: 48.64864864864865,
    M: 41.89189189189189,
    L: 5.85585585585586,
    XL: 0,
  },
  {
    name: 'Labradoodle',
    weight: 30,
    total: 451,
    XS: 0.44345898004435,
    S: 23.28159645232816,
    M: 68.9578713968958,
    L: 7.31707317073171,
    XL: 0,
  },
  {
    name: 'Labradoodle',
    weight: 40,
    total: 508,
    XS: 0,
    S: 4.33070866141732,
    M: 76.5748031496063,
    L: 18.89763779527559,
    XL: 0.19685039370079,
  },
  {
    name: 'Labradoodle',
    weight: 50,
    total: 597,
    XS: 0,
    S: 1.6750418760469,
    M: 55.9463986599665,
    L: 41.87604690117253,
    XL: 0.50251256281407,
  },
  {
    name: 'Labradoodle',
    weight: 60,
    total: 545,
    XS: 0,
    S: 2.20183486238532,
    M: 39.44954128440367,
    L: 57.61467889908257,
    XL: 0.73394495412844,
  },
  {
    name: 'Labradoodle',
    weight: 70,
    total: 509,
    XS: 0,
    S: 1.17878192534381,
    M: 24.16502946954813,
    L: 73.28094302554028,
    XL: 1.37524557956778,
  },
  {
    name: 'Labradoodle',
    weight: 80,
    total: 337,
    XS: 0.29673590504451,
    S: 0.89020771513353,
    M: 17.2106824925816,
    L: 76.55786350148368,
    XL: 5.04451038575668,
  },
  {
    name: 'Labradoodle',
    weight: 90,
    total: 129,
    XS: 0,
    S: 0,
    M: 17.05426356589147,
    L: 71.31782945736434,
    XL: 11.62790697674419,
  },
  {
    name: 'Labradoodle',
    weight: 100,
    total: 80,
    XS: 0,
    S: 1.25,
    M: 11.25,
    L: 76.25,
    XL: 11.25,
  },
  {
    name: 'Labrador Husky',
    weight: 40,
    total: 46,
    XS: 0,
    S: 4.34782608695652,
    M: 60.8695652173913,
    L: 34.78260869565217,
    XL: 0,
  },
  {
    name: 'Labrador Husky',
    weight: 50,
    total: 164,
    XS: 0,
    S: 1.21951219512195,
    M: 44.51219512195122,
    L: 52.4390243902439,
    XL: 1.82926829268293,
  },
  {
    name: 'Labrador Husky',
    weight: 60,
    total: 178,
    XS: 0,
    S: 1.68539325842697,
    M: 28.08988764044944,
    L: 70.2247191011236,
    XL: 0,
  },
  {
    name: 'Labrador Husky',
    weight: 70,
    total: 147,
    XS: 0,
    S: 0.68027210884354,
    M: 19.72789115646259,
    L: 77.55102040816327,
    XL: 2.04081632653061,
  },
  {
    name: 'Labrador Husky',
    weight: 80,
    total: 92,
    XS: 0,
    S: 0,
    M: 11.95652173913043,
    L: 83.69565217391305,
    XL: 4.34782608695652,
  },
  {
    name: 'Labrador Husky',
    weight: 90,
    total: 42,
    XS: 2.38095238095238,
    S: 0,
    M: 7.14285714285714,
    L: 73.80952380952381,
    XL: 16.66666666666667,
  },
  {
    name: 'Labrador Retriever',
    weight: 10,
    total: 133,
    XS: 5.26315789473684,
    S: 30.07518796992481,
    M: 34.58646616541353,
    L: 26.31578947368421,
    XL: 3.7593984962406,
  },
  {
    name: 'Labrador Retriever',
    weight: 20,
    total: 466,
    XS: 2.78969957081545,
    S: 25.32188841201717,
    M: 46.3519313304721,
    L: 25.10729613733906,
    XL: 0.42918454935622,
  },
  {
    name: 'Labrador Retriever',
    weight: 30,
    total: 931,
    XS: 0.64446831364125,
    S: 10.09667024704619,
    M: 61.43931256713212,
    L: 26.74543501611171,
    XL: 1.07411385606874,
  },
  {
    name: 'Labrador Retriever',
    weight: 40,
    total: 2180,
    XS: 0.18348623853211,
    S: 3.6697247706422,
    M: 60.13761467889908,
    L: 35.04587155963303,
    XL: 0.96330275229358,
  },
  {
    name: 'Labrador Retriever',
    weight: 50,
    total: 4905,
    XS: 0.0815494393476,
    S: 1.7737003058104,
    M: 46.25891946992864,
    L: 50.8868501529052,
    XL: 0.99898063200815,
  },
  {
    name: 'Labrador Retriever',
    weight: 60,
    total: 7192,
    XS: 0.09733036707453,
    S: 1.34872080088988,
    M: 29.68576195773081,
    L: 67.26918798665184,
    XL: 1.59899888765295,
  },
  {
    name: 'Labrador Retriever',
    weight: 70,
    total: 8758,
    XS: 0.07992692395524,
    S: 0.84494176752683,
    M: 22.63073761132679,
    L: 72.64215574332039,
    XL: 3.80223795387075,
  },
  {
    name: 'Labrador Retriever',
    weight: 80,
    total: 7596,
    XS: 0.03949447077409,
    S: 0.53975776724592,
    M: 19.04949973670353,
    L: 72.1563981042654,
    XL: 8.21484992101106,
  },
  {
    name: 'Labrador Retriever',
    weight: 90,
    total: 4634,
    XS: 0.15105740181269,
    S: 0.51791109192922,
    M: 17.13422529132499,
    L: 66.11998273629693,
    XL: 16.07682347863617,
  },
  {
    name: 'Labrador Retriever',
    weight: 100,
    total: 2350,
    XS: 0.21276595744681,
    S: 0.59574468085106,
    M: 16.29787234042553,
    L: 55.02127659574468,
    XL: 27.87234042553191,
  },
  {
    name: 'Labrador Retriever',
    weight: 110,
    total: 720,
    XS: 0.13888888888889,
    S: 0.41666666666667,
    M: 15.69444444444444,
    L: 45.41666666666667,
    XL: 38.33333333333333,
  },
  {
    name: 'Labrador Retriever',
    weight: 120,
    total: 333,
    XS: 0,
    S: 0,
    M: 14.71471471471471,
    L: 32.73273273273273,
    XL: 52.55255255255255,
  },
  {
    name: 'Labrador Retriever',
    weight: 130,
    total: 115,
    XS: 0.8695652173913,
    S: 0,
    M: 10.43478260869565,
    L: 31.30434782608696,
    XL: 57.39130434782609,
  },
  {
    name: 'Labrador Retriever',
    weight: 140,
    total: 41,
    XS: 0,
    S: 2.4390243902439,
    M: 14.63414634146341,
    L: 17.07317073170732,
    XL: 65.85365853658537,
  },
  {
    name: 'Labrador Retriever',
    weight: 150,
    total: 27,
    XS: 0,
    S: 0,
    M: 22.22222222222222,
    L: 44.44444444444444,
    XL: 33.33333333333333,
  },
  {
    name: 'Labrador Retriever',
    weight: 180,
    total: 26,
    XS: 0,
    S: 0,
    M: 15.38461538461538,
    L: 69.23076923076923,
    XL: 15.38461538461538,
  },
  {
    name: 'Labraheeler',
    weight: 40,
    total: 27,
    XS: 0,
    S: 0,
    M: 74.07407407407408,
    L: 25.92592592592593,
    XL: 0,
  },
  {
    name: 'Labraheeler',
    weight: 50,
    total: 53,
    XS: 0,
    S: 1.88679245283019,
    M: 54.71698113207547,
    L: 43.39622641509434,
    XL: 0,
  },
  {
    name: 'Labraheeler',
    weight: 60,
    total: 33,
    XS: 0,
    S: 3.03030303030303,
    M: 45.45454545454545,
    L: 51.51515151515152,
    XL: 0,
  },
  {
    name: 'Labstaff',
    weight: 60,
    total: 26,
    XS: 0,
    S: 3.84615384615385,
    M: 23.07692307692308,
    L: 73.07692307692308,
    XL: 0,
  },
  {
    name: 'Lagotto Romagnolo',
    weight: 30,
    total: 188,
    XS: 0,
    S: 15.42553191489362,
    M: 83.51063829787233,
    L: 1.06382978723404,
    XL: 0,
  },
  {
    name: 'Lagotto Romagnolo',
    weight: 40,
    total: 206,
    XS: 1.45631067961165,
    S: 2.9126213592233,
    M: 89.32038834951456,
    L: 6.31067961165049,
    XL: 0,
  },
  {
    name: 'Lagotto Romagnolo',
    weight: 50,
    total: 46,
    XS: 0,
    S: 2.17391304347826,
    M: 71.73913043478261,
    L: 26.08695652173913,
    XL: 0,
  },
  {
    name: 'Lakeland Terrier',
    weight: 20,
    total: 43,
    XS: 4.65116279069767,
    S: 60.46511627906977,
    M: 34.88372093023256,
    L: 0,
    XL: 0,
  },
  {
    name: 'Lhasa Apso',
    weight: 20,
    total: 163,
    XS: 4.9079754601227,
    S: 60.7361963190184,
    M: 31.90184049079755,
    L: 1.84049079754601,
    XL: 0.61349693251534,
  },
  {
    name: 'Lhasa Apso',
    weight: 30,
    total: 47,
    XS: 0,
    S: 27.65957446808511,
    M: 65.95744680851064,
    L: 6.38297872340426,
    XL: 0,
  },
  {
    name: 'Mal-Shi',
    weight: 20,
    total: 34,
    XS: 5.88235294117647,
    S: 67.6470588235294,
    M: 23.52941176470588,
    L: 0,
    XL: 2.94117647058824,
  },
  {
    name: 'Maltese',
    weight: 10,
    total: 292,
    XS: 46.91780821917808,
    S: 41.78082191780822,
    M: 7.87671232876712,
    L: 3.42465753424658,
    XL: 0,
  },
  {
    name: 'Maltese',
    weight: 20,
    total: 428,
    XS: 8.8785046728972,
    S: 70.09345794392523,
    M: 20.09345794392523,
    L: 0.93457943925234,
    XL: 0,
  },
  {
    name: 'Maltese',
    weight: 30,
    total: 47,
    XS: 2.12765957446809,
    S: 53.19148936170213,
    M: 42.5531914893617,
    L: 2.12765957446809,
    XL: 0,
  },
  {
    name: 'Maltipoo',
    weight: 10,
    total: 216,
    XS: 53.24074074074074,
    S: 37.03703703703704,
    M: 6.48148148148148,
    L: 2.31481481481481,
    XL: 0.92592592592593,
  },
  {
    name: 'Maltipoo',
    weight: 20,
    total: 386,
    XS: 12.69430051813472,
    S: 69.4300518134715,
    M: 16.06217616580311,
    L: 1.81347150259067,
    XL: 0,
  },
  {
    name: 'Maltipoo',
    weight: 30,
    total: 52,
    XS: 0,
    S: 40.38461538461538,
    M: 59.61538461538462,
    L: 0,
    XL: 0,
  },
  {
    name: 'Manchester Terrier',
    weight: 20,
    total: 34,
    XS: 14.70588235294118,
    S: 58.82352941176471,
    M: 26.47058823529412,
    L: 0,
    XL: 0,
  },
  {
    name: 'Manchester Terrier',
    weight: 30,
    total: 33,
    XS: 0,
    S: 42.42424242424242,
    M: 54.54545454545455,
    L: 3.03030303030303,
    XL: 0,
  },
  {
    name: 'Maremma Sheepdog',
    weight: 80,
    total: 26,
    XS: 0,
    S: 0,
    M: 15.38461538461538,
    L: 46.15384615384615,
    XL: 38.46153846153846,
  },
  {
    name: 'Maremma Sheepdog',
    weight: 90,
    total: 30,
    XS: 0,
    S: 0,
    M: 23.33333333333333,
    L: 40,
    XL: 36.66666666666667,
  },
  {
    name: 'Maremma Sheepdog',
    weight: 100,
    total: 34,
    XS: 0,
    S: 0,
    M: 5.88235294117647,
    L: 38.23529411764706,
    XL: 55.88235294117647,
  },
  {
    name: 'Mastiff',
    weight: 60,
    total: 26,
    XS: 0,
    S: 0,
    M: 38.46153846153846,
    L: 57.69230769230769,
    XL: 3.84615384615385,
  },
  {
    name: 'Mastiff',
    weight: 70,
    total: 47,
    XS: 0,
    S: 2.12765957446809,
    M: 19.14893617021277,
    L: 70.2127659574468,
    XL: 8.51063829787234,
  },
  {
    name: 'Mastiff',
    weight: 80,
    total: 63,
    XS: 0,
    S: 0,
    M: 12.6984126984127,
    L: 58.73015873015873,
    XL: 28.57142857142857,
  },
  {
    name: 'Mastiff',
    weight: 90,
    total: 40,
    XS: 0,
    S: 0,
    M: 30,
    L: 27.5,
    XL: 42.5,
  },
  {
    name: 'Mastiff',
    weight: 100,
    total: 58,
    XS: 0,
    S: 0,
    M: 20.68965517241379,
    L: 43.10344827586207,
    XL: 36.20689655172414,
  },
  {
    name: 'Mastiff',
    weight: 110,
    total: 43,
    XS: 0,
    S: 2.32558139534884,
    M: 23.25581395348837,
    L: 41.86046511627907,
    XL: 32.55813953488372,
  },
  {
    name: 'Mastiff',
    weight: 120,
    total: 44,
    XS: 0,
    S: 0,
    M: 20.45454545454545,
    L: 20.45454545454545,
    XL: 59.09090909090909,
  },
  {
    name: 'Mastiff',
    weight: 130,
    total: 31,
    XS: 0,
    S: 0,
    M: 16.12903225806452,
    L: 3.2258064516129,
    XL: 80.64516129032258,
  },
  {
    name: 'Mastiff',
    weight: 150,
    total: 26,
    XS: 0,
    S: 0,
    M: 19.23076923076923,
    L: 7.69230769230769,
    XL: 73.07692307692308,
  },
  {
    name: 'McNab',
    weight: 30,
    total: 26,
    XS: 0,
    S: 7.69230769230769,
    M: 84.61538461538461,
    L: 7.69230769230769,
    XL: 0,
  },
  {
    name: 'McNab',
    weight: 40,
    total: 53,
    XS: 0,
    S: 1.88679245283019,
    M: 73.58490566037736,
    L: 24.52830188679245,
    XL: 0,
  },
  {
    name: 'McNab',
    weight: 50,
    total: 51,
    XS: 0,
    S: 1.96078431372549,
    M: 64.70588235294117,
    L: 33.33333333333333,
    XL: 0,
  },
  {
    name: 'McNab',
    weight: 60,
    total: 36,
    XS: 0,
    S: 2.77777777777778,
    M: 41.66666666666667,
    L: 55.55555555555556,
    XL: 0,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 10,
    total: 51,
    XS: 27.45098039215686,
    S: 62.74509803921569,
    M: 9.80392156862745,
    L: 0,
    XL: 0,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 20,
    total: 294,
    XS: 3.40136054421769,
    S: 65.98639455782313,
    M: 28.57142857142857,
    L: 1.70068027210884,
    XL: 0.34013605442177,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 30,
    total: 569,
    XS: 0.17574692442882,
    S: 39.36731107205624,
    M: 57.11775043936731,
    L: 2.81195079086116,
    XL: 0.52724077328647,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 40,
    total: 424,
    XS: 0.47169811320755,
    S: 15.33018867924528,
    M: 75.70754716981132,
    L: 8.49056603773585,
    XL: 0,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 50,
    total: 130,
    XS: 0,
    S: 10.76923076923077,
    M: 70.76923076923077,
    L: 18.46153846153846,
    XL: 0,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 10,
    total: 100,
    XS: 38,
    S: 51,
    M: 11,
    L: 0,
    XL: 0,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 20,
    total: 474,
    XS: 8.64978902953586,
    S: 63.50210970464135,
    M: 25.94936708860759,
    L: 1.89873417721519,
    XL: 0,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 30,
    total: 618,
    XS: 0.97087378640777,
    S: 37.70226537216828,
    M: 58.25242718446602,
    L: 2.75080906148867,
    XL: 0.32362459546926,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 40,
    total: 445,
    XS: 0.2247191011236,
    S: 13.93258426966292,
    M: 72.35955056179775,
    L: 13.25842696629213,
    XL: 0.2247191011236,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 50,
    total: 140,
    XS: 0,
    S: 7.85714285714286,
    M: 68.57142857142857,
    L: 23.57142857142857,
    XL: 0,
  },
  {
    name: 'Miniature Bull Terrier',
    weight: 30,
    total: 32,
    XS: 0,
    S: 3.125,
    M: 71.875,
    L: 25,
    XL: 0,
  },
  {
    name: 'Miniature Bull Terrier',
    weight: 40,
    total: 35,
    XS: 0,
    S: 2.85714285714286,
    M: 65.71428571428571,
    L: 31.42857142857143,
    XL: 0,
  },
  {
    name: 'Miniature Pinscher',
    weight: 10,
    total: 119,
    XS: 54.6218487394958,
    S: 36.97478991596639,
    M: 4.20168067226891,
    L: 3.36134453781513,
    XL: 0.84033613445378,
  },
  {
    name: 'Miniature Pinscher',
    weight: 20,
    total: 500,
    XS: 9.4,
    S: 68.2,
    M: 20,
    L: 2.2,
    XL: 0.2,
  },
  {
    name: 'Miniature Pinscher',
    weight: 30,
    total: 166,
    XS: 1.20481927710843,
    S: 33.13253012048193,
    M: 61.44578313253012,
    L: 4.21686746987952,
    XL: 0,
  },
  {
    name: 'Miniature Pinscher',
    weight: 40,
    total: 37,
    XS: 0,
    S: 13.51351351351351,
    M: 62.16216216216216,
    L: 24.32432432432432,
    XL: 0,
  },
  {
    name: 'Miniature Poodle',
    weight: 10,
    total: 235,
    XS: 45.1063829787234,
    S: 42.97872340425532,
    M: 8.08510638297872,
    L: 3.40425531914894,
    XL: 0.42553191489362,
  },
  {
    name: 'Miniature Poodle',
    weight: 20,
    total: 768,
    XS: 11.97916666666667,
    S: 67.31770833333333,
    M: 19.40104166666667,
    L: 1.04166666666667,
    XL: 0.26041666666667,
  },
  {
    name: 'Miniature Poodle',
    weight: 30,
    total: 233,
    XS: 3.00429184549356,
    S: 40.7725321888412,
    M: 53.6480686695279,
    L: 2.57510729613734,
    XL: 0,
  },
  {
    name: 'Miniature Poodle',
    weight: 40,
    total: 55,
    XS: 0,
    S: 16.36363636363636,
    M: 76.36363636363636,
    L: 7.27272727272727,
    XL: 0,
  },
  {
    name: 'Miniature Schnauzer',
    weight: 10,
    total: 272,
    XS: 36.39705882352941,
    S: 50,
    M: 12.5,
    L: 0,
    XL: 1.10294117647059,
  },
  {
    name: 'Miniature Schnauzer',
    weight: 20,
    total: 1716,
    XS: 7.10955710955711,
    S: 70.33799533799534,
    M: 21.73659673659674,
    L: 0.64102564102564,
    XL: 0.17482517482517,
  },
  {
    name: 'Miniature Schnauzer',
    weight: 30,
    total: 502,
    XS: 0.79681274900398,
    S: 38.44621513944223,
    M: 58.96414342629482,
    L: 1.59362549800797,
    XL: 0.199203187251,
  },
  {
    name: 'Miniature Schnauzer',
    weight: 40,
    total: 49,
    XS: 0,
    S: 10.20408163265306,
    M: 85.71428571428571,
    L: 4.08163265306122,
    XL: 0,
  },
  {
    name: 'Morkie',
    weight: 10,
    total: 113,
    XS: 53.09734513274336,
    S: 39.82300884955752,
    M: 6.19469026548673,
    L: 0.88495575221239,
    XL: 0,
  },
  {
    name: 'Morkie',
    weight: 20,
    total: 161,
    XS: 10.55900621118012,
    S: 76.3975155279503,
    M: 13.04347826086957,
    L: 0,
    XL: 0,
  },
  {
    name: 'Mountain Cur',
    weight: 30,
    total: 56,
    XS: 0,
    S: 25,
    M: 67.85714285714286,
    L: 7.14285714285714,
    XL: 0,
  },
  {
    name: 'Mountain Cur',
    weight: 40,
    total: 145,
    XS: 0,
    S: 3.44827586206897,
    M: 75.17241379310344,
    L: 21.37931034482759,
    XL: 0,
  },
  {
    name: 'Mountain Cur',
    weight: 50,
    total: 195,
    XS: 0,
    S: 2.05128205128205,
    M: 61.53846153846154,
    L: 35.8974358974359,
    XL: 0.51282051282051,
  },
  {
    name: 'Mountain Cur',
    weight: 60,
    total: 142,
    XS: 0,
    S: 0,
    M: 39.43661971830986,
    L: 58.45070422535211,
    XL: 2.11267605633803,
  },
  {
    name: 'Mountain Cur',
    weight: 70,
    total: 90,
    XS: 0,
    S: 2.22222222222222,
    M: 27.77777777777778,
    L: 68.88888888888889,
    XL: 1.11111111111111,
  },
  {
    name: 'Mountain Cur',
    weight: 80,
    total: 44,
    XS: 0,
    S: 0,
    M: 18.18181818181818,
    L: 72.72727272727273,
    XL: 9.09090909090909,
  },
  {
    name: 'Mountain Feist',
    weight: 30,
    total: 41,
    XS: 0,
    S: 24.39024390243902,
    M: 75.60975609756098,
    L: 0,
    XL: 0,
  },
  {
    name: 'Mountain Feist',
    weight: 40,
    total: 28,
    XS: 0,
    S: 3.57142857142857,
    M: 89.28571428571429,
    L: 7.14285714285714,
    XL: 0,
  },
  {
    name: 'Newfoundland',
    weight: 60,
    total: 26,
    XS: 0,
    S: 3.84615384615385,
    M: 23.07692307692308,
    L: 65.38461538461539,
    XL: 7.69230769230769,
  },
  {
    name: 'Newfoundland',
    weight: 70,
    total: 34,
    XS: 0,
    S: 0,
    M: 14.70588235294118,
    L: 55.88235294117647,
    XL: 29.41176470588235,
  },
  {
    name: 'Newfoundland',
    weight: 80,
    total: 50,
    XS: 0,
    S: 4,
    M: 14,
    L: 62,
    XL: 20,
  },
  {
    name: 'Newfoundland',
    weight: 90,
    total: 64,
    XS: 0,
    S: 1.5625,
    M: 15.625,
    L: 39.0625,
    XL: 43.75,
  },
  {
    name: 'Newfoundland',
    weight: 100,
    total: 152,
    XS: 0,
    S: 3.28947368421053,
    M: 15.13157894736842,
    L: 30.92105263157895,
    XL: 50.65789473684211,
  },
  {
    name: 'Newfoundland',
    weight: 110,
    total: 113,
    XS: 0,
    S: 0.88495575221239,
    M: 15.04424778761062,
    L: 17.69911504424779,
    XL: 66.3716814159292,
  },
  {
    name: 'Newfoundland',
    weight: 120,
    total: 99,
    XS: 0,
    S: 3.03030303030303,
    M: 12.12121212121212,
    L: 16.16161616161616,
    XL: 68.68686868686869,
  },
  {
    name: 'Newfoundland',
    weight: 130,
    total: 107,
    XS: 0,
    S: 0.93457943925234,
    M: 14.95327102803738,
    L: 12.14953271028037,
    XL: 71.96261682242991,
  },
  {
    name: 'Newfoundland',
    weight: 140,
    total: 68,
    XS: 0,
    S: 0,
    M: 17.64705882352941,
    L: 7.35294117647059,
    XL: 75,
  },
  {
    name: 'Newfoundland',
    weight: 150,
    total: 64,
    XS: 0,
    S: 4.6875,
    M: 20.3125,
    L: 3.125,
    XL: 71.875,
  },
  {
    name: 'Norfolk Terrier',
    weight: 20,
    total: 66,
    XS: 1.51515151515152,
    S: 56.06060606060606,
    M: 39.39393939393939,
    L: 3.03030303030303,
    XL: 0,
  },
  {
    name: 'Norwegian Elkhound',
    weight: 40,
    total: 66,
    XS: 0,
    S: 6.06060606060606,
    M: 66.66666666666667,
    L: 27.27272727272727,
    XL: 0,
  },
  {
    name: 'Norwegian Elkhound',
    weight: 50,
    total: 113,
    XS: 0,
    S: 0.88495575221239,
    M: 48.67256637168142,
    L: 49.55752212389381,
    XL: 0.88495575221239,
  },
  {
    name: 'Norwegian Elkhound',
    weight: 60,
    total: 68,
    XS: 0,
    S: 0,
    M: 41.17647058823529,
    L: 57.35294117647059,
    XL: 1.47058823529412,
  },
  {
    name: 'Norwegian Elkhound',
    weight: 70,
    total: 31,
    XS: 0,
    S: 0,
    M: 25.80645161290323,
    L: 67.74193548387098,
    XL: 6.45161290322581,
  },
  {
    name: 'Norwich Terrier',
    weight: 20,
    total: 69,
    XS: 2.89855072463768,
    S: 59.42028985507246,
    M: 37.68115942028986,
    L: 0,
    XL: 0,
  },
  {
    name: 'Nova Scotia Duck Tolling Retriever',
    weight: 30,
    total: 52,
    XS: 0,
    S: 17.30769230769231,
    M: 78.84615384615385,
    L: 3.84615384615385,
    XL: 0,
  },
  {
    name: 'Nova Scotia Duck Tolling Retriever',
    weight: 40,
    total: 164,
    XS: 0.60975609756098,
    S: 4.8780487804878,
    M: 84.7560975609756,
    L: 9.75609756097561,
    XL: 0,
  },
  {
    name: 'Nova Scotia Duck Tolling Retriever',
    weight: 50,
    total: 143,
    XS: 0.6993006993007,
    S: 2.0979020979021,
    M: 64.33566433566433,
    L: 32.86713286713287,
    XL: 0,
  },
  {
    name: 'Nova Scotia Duck Tolling Retriever',
    weight: 60,
    total: 51,
    XS: 1.96078431372549,
    S: 0,
    M: 39.2156862745098,
    L: 58.82352941176471,
    XL: 0,
  },
  {
    name: 'Old English Sheepdog',
    weight: 30,
    total: 30,
    XS: 0,
    S: 23.33333333333333,
    M: 66.66666666666667,
    L: 10,
    XL: 0,
  },
  {
    name: 'Old English Sheepdog',
    weight: 40,
    total: 31,
    XS: 0,
    S: 6.45161290322581,
    M: 61.29032258064516,
    L: 32.25806451612903,
    XL: 0,
  },
  {
    name: 'Old English Sheepdog',
    weight: 50,
    total: 60,
    XS: 0,
    S: 0,
    M: 46.66666666666667,
    L: 50,
    XL: 3.33333333333333,
  },
  {
    name: 'Old English Sheepdog',
    weight: 60,
    total: 68,
    XS: 0,
    S: 4.41176470588235,
    M: 29.41176470588235,
    L: 63.23529411764706,
    XL: 2.94117647058824,
  },
  {
    name: 'Old English Sheepdog',
    weight: 70,
    total: 77,
    XS: 0,
    S: 2.5974025974026,
    M: 20.77922077922078,
    L: 72.72727272727273,
    XL: 3.8961038961039,
  },
  {
    name: 'Old English Sheepdog',
    weight: 80,
    total: 65,
    XS: 0,
    S: 0,
    M: 20,
    L: 69.23076923076923,
    XL: 10.76923076923077,
  },
  {
    name: 'Old English Sheepdog',
    weight: 90,
    total: 36,
    XS: 0,
    S: 2.77777777777778,
    M: 8.33333333333333,
    L: 72.22222222222221,
    XL: 16.66666666666667,
  },
  {
    name: 'Olde English Bulldogge',
    weight: 50,
    total: 45,
    XS: 0,
    S: 4.44444444444444,
    M: 33.33333333333333,
    L: 57.77777777777778,
    XL: 4.44444444444444,
  },
  {
    name: 'Olde English Bulldogge',
    weight: 60,
    total: 75,
    XS: 0,
    S: 1.33333333333333,
    M: 32,
    L: 53.33333333333333,
    XL: 13.33333333333333,
  },
  {
    name: 'Olde English Bulldogge',
    weight: 70,
    total: 75,
    XS: 0,
    S: 0,
    M: 34.66666666666667,
    L: 46.66666666666667,
    XL: 18.66666666666667,
  },
  {
    name: 'Olde English Bulldogge',
    weight: 80,
    total: 39,
    XS: 0,
    S: 0,
    M: 33.33333333333333,
    L: 35.8974358974359,
    XL: 30.76923076923077,
  },
  {
    name: 'Olde English Bulldogge',
    weight: 90,
    total: 27,
    XS: 3.7037037037037,
    S: 0,
    M: 25.92592592592593,
    L: 40.74074074074074,
    XL: 29.62962962962963,
  },
  {
    name: 'Other',
    weight: 10,
    total: 208,
    XS: 39.90384615384615,
    S: 32.69230769230769,
    M: 14.90384615384615,
    L: 11.05769230769231,
    XL: 1.44230769230769,
  },
  {
    name: 'Other',
    weight: 20,
    total: 309,
    XS: 17.47572815533981,
    S: 48.86731391585761,
    M: 24.27184466019417,
    L: 8.7378640776699,
    XL: 0.64724919093851,
  },
  {
    name: 'Other',
    weight: 30,
    total: 244,
    XS: 3.27868852459016,
    S: 23.77049180327869,
    M: 61.88524590163934,
    L: 10.65573770491803,
    XL: 0.40983606557377,
  },
  {
    name: 'Other',
    weight: 40,
    total: 290,
    XS: 0.68965517241379,
    S: 9.31034482758621,
    M: 72.06896551724138,
    L: 17.58620689655172,
    XL: 0.3448275862069,
  },
  {
    name: 'Other',
    weight: 50,
    total: 328,
    XS: 0.30487804878049,
    S: 6.40243902439024,
    M: 56.09756097560976,
    L: 35.36585365853659,
    XL: 1.82926829268293,
  },
  {
    name: 'Other',
    weight: 60,
    total: 176,
    XS: 0.56818181818182,
    S: 7.38636363636364,
    M: 32.95454545454545,
    L: 56.81818181818182,
    XL: 2.27272727272727,
  },
  {
    name: 'Other',
    weight: 70,
    total: 106,
    XS: 0,
    S: 1.88679245283019,
    M: 27.35849056603774,
    L: 67.9245283018868,
    XL: 2.83018867924528,
  },
  {
    name: 'Other',
    weight: 80,
    total: 58,
    XS: 0,
    S: 1.72413793103448,
    M: 25.86206896551724,
    L: 62.06896551724138,
    XL: 10.3448275862069,
  },
  {
    name: 'Papillon',
    weight: 10,
    total: 81,
    XS: 44.44444444444444,
    S: 43.20987654320988,
    M: 8.64197530864198,
    L: 2.46913580246914,
    XL: 1.23456790123457,
  },
  {
    name: 'Papillon',
    weight: 20,
    total: 139,
    XS: 5.75539568345324,
    S: 72.66187050359711,
    M: 20.14388489208633,
    L: 1.43884892086331,
    XL: 0,
  },
  {
    name: 'Parson Russell Terrier',
    weight: 20,
    total: 143,
    XS: 2.7972027972028,
    S: 70.62937062937063,
    M: 25.87412587412587,
    L: 0.6993006993007,
    XL: 0,
  },
  {
    name: 'Parson Russell Terrier',
    weight: 30,
    total: 46,
    XS: 0,
    S: 30.43478260869565,
    M: 67.3913043478261,
    L: 2.17391304347826,
    XL: 0,
  },
  {
    name: 'Patterdale Terrier',
    weight: 20,
    total: 56,
    XS: 3.57142857142857,
    S: 69.64285714285714,
    M: 25,
    L: 1.78571428571429,
    XL: 0,
  },
  {
    name: 'Patterdale Terrier',
    weight: 30,
    total: 45,
    XS: 0,
    S: 20,
    M: 73.33333333333333,
    L: 6.66666666666667,
    XL: 0,
  },
  {
    name: 'Pekingese',
    weight: 10,
    total: 26,
    XS: 19.23076923076923,
    S: 73.07692307692308,
    M: 3.84615384615385,
    L: 3.84615384615385,
    XL: 0,
  },
  {
    name: 'Pekingese',
    weight: 20,
    total: 118,
    XS: 3.38983050847458,
    S: 68.64406779661017,
    M: 25.42372881355932,
    L: 2.54237288135593,
    XL: 0,
  },
  {
    name: 'Pembroke Welsh Corgi',
    weight: 10,
    total: 102,
    XS: 10.7843137254902,
    S: 35.29411764705882,
    M: 50,
    L: 3.92156862745098,
    XL: 0,
  },
  {
    name: 'Pembroke Welsh Corgi',
    weight: 20,
    total: 734,
    XS: 0.68119891008174,
    S: 22.20708446866485,
    M: 73.433242506812,
    L: 3.40599455040872,
    XL: 0.2724795640327,
  },
  {
    name: 'Pembroke Welsh Corgi',
    weight: 30,
    total: 1987,
    XS: 0.30196275792652,
    S: 6.03925515853045,
    M: 86.0090588827378,
    L: 7.49874182184197,
    XL: 0.15098137896326,
  },
  {
    name: 'Pembroke Welsh Corgi',
    weight: 40,
    total: 758,
    XS: 0.13192612137203,
    S: 2.5065963060686,
    M: 74.93403693931398,
    L: 22.16358839050132,
    XL: 0.26385224274406,
  },
  {
    name: 'Pembroke Welsh Corgi',
    weight: 50,
    total: 107,
    XS: 0,
    S: 0.93457943925234,
    M: 50.46728971962617,
    L: 42.99065420560748,
    XL: 5.60747663551402,
  },
  {
    name: 'Pharaoh Hound',
    weight: 50,
    total: 29,
    XS: 0,
    S: 3.44827586206897,
    M: 72.41379310344828,
    L: 24.13793103448276,
    XL: 0,
  },
  {
    name: 'Pit Bull',
    weight: 20,
    total: 45,
    XS: 6.66666666666667,
    S: 28.88888888888889,
    M: 37.77777777777778,
    L: 26.66666666666667,
    XL: 0,
  },
  {
    name: 'Pit Bull',
    weight: 30,
    total: 141,
    XS: 0,
    S: 9.21985815602837,
    M: 57.4468085106383,
    L: 33.33333333333333,
    XL: 0,
  },
  {
    name: 'Pit Bull',
    weight: 40,
    total: 332,
    XS: 0,
    S: 3.01204819277108,
    M: 61.14457831325301,
    L: 35.8433734939759,
    XL: 0,
  },
  {
    name: 'Pit Bull',
    weight: 50,
    total: 814,
    XS: 0.12285012285012,
    S: 1.84275184275184,
    M: 42.87469287469287,
    L: 53.56265356265356,
    XL: 1.5970515970516,
  },
  {
    name: 'Pit Bull',
    weight: 60,
    total: 949,
    XS: 0.10537407797682,
    S: 0.63224446786091,
    M: 25.50052687038988,
    L: 70.60063224446786,
    XL: 3.16122233930453,
  },
  {
    name: 'Pit Bull',
    weight: 70,
    total: 799,
    XS: 0.37546933667084,
    S: 0.25031289111389,
    M: 18.1476846057572,
    L: 76.22027534418022,
    XL: 5.00625782227785,
  },
  {
    name: 'Pit Bull',
    weight: 80,
    total: 483,
    XS: 0,
    S: 0.82815734989648,
    M: 15.52795031055901,
    L: 72.67080745341615,
    XL: 10.97308488612836,
  },
  {
    name: 'Pit Bull',
    weight: 90,
    total: 204,
    XS: 0,
    S: 0.49019607843137,
    M: 10.7843137254902,
    L: 67.15686274509804,
    XL: 21.56862745098039,
  },
  {
    name: 'Pit Bull',
    weight: 100,
    total: 94,
    XS: 0,
    S: 1.06382978723404,
    M: 13.82978723404255,
    L: 55.31914893617021,
    XL: 29.78723404255319,
  },
  {
    name: 'Pitsky',
    weight: 40,
    total: 29,
    XS: 0,
    S: 3.44827586206897,
    M: 62.06896551724138,
    L: 31.03448275862069,
    XL: 3.44827586206897,
  },
  {
    name: 'Pitsky',
    weight: 50,
    total: 94,
    XS: 0,
    S: 3.19148936170213,
    M: 50,
    L: 46.80851063829787,
    XL: 0,
  },
  {
    name: 'Pitsky',
    weight: 60,
    total: 96,
    XS: 0,
    S: 2.08333333333333,
    M: 39.58333333333333,
    L: 56.25,
    XL: 2.08333333333333,
  },
  {
    name: 'Pitsky',
    weight: 70,
    total: 75,
    XS: 0,
    S: 0,
    M: 32,
    L: 65.33333333333333,
    XL: 2.66666666666667,
  },
  {
    name: 'Pitsky',
    weight: 80,
    total: 40,
    XS: 0,
    S: 0,
    M: 17.5,
    L: 70,
    XL: 12.5,
  },
  {
    name: 'Pitt Plott',
    weight: 50,
    total: 27,
    XS: 0,
    S: 0,
    M: 59.25925925925926,
    L: 37.03703703703704,
    XL: 3.7037037037037,
  },
  {
    name: 'Plott Hound',
    weight: 40,
    total: 113,
    XS: 0,
    S: 2.65486725663717,
    M: 78.76106194690264,
    L: 16.8141592920354,
    XL: 1.76991150442478,
  },
  {
    name: 'Plott Hound',
    weight: 50,
    total: 244,
    XS: 0.40983606557377,
    S: 2.45901639344262,
    M: 52.45901639344262,
    L: 43.44262295081967,
    XL: 1.22950819672131,
  },
  {
    name: 'Plott Hound',
    weight: 60,
    total: 208,
    XS: 0.48076923076923,
    S: 0.48076923076923,
    M: 37.5,
    L: 59.61538461538462,
    XL: 1.92307692307692,
  },
  {
    name: 'Plott Hound',
    weight: 70,
    total: 180,
    XS: 0,
    S: 1.11111111111111,
    M: 31.11111111111111,
    L: 66.66666666666667,
    XL: 1.11111111111111,
  },
  {
    name: 'Plott Hound',
    weight: 80,
    total: 91,
    XS: 0,
    S: 0,
    M: 28.57142857142857,
    L: 67.03296703296704,
    XL: 4.3956043956044,
  },
  {
    name: 'Plott Hound',
    weight: 90,
    total: 28,
    XS: 0,
    S: 0,
    M: 25,
    L: 71.42857142857143,
    XL: 3.57142857142857,
  },
  {
    name: 'Pocket Pitbull',
    weight: 40,
    total: 34,
    XS: 0,
    S: 0,
    M: 67.6470588235294,
    L: 32.35294117647059,
    XL: 0,
  },
  {
    name: 'Pocket Pitbull',
    weight: 50,
    total: 53,
    XS: 0,
    S: 1.88679245283019,
    M: 30.18867924528302,
    L: 64.15094339622642,
    XL: 3.77358490566038,
  },
  {
    name: 'Pocket Pitbull',
    weight: 60,
    total: 37,
    XS: 0,
    S: 0,
    M: 27.02702702702703,
    L: 64.86486486486486,
    XL: 8.10810810810811,
  },
  {
    name: 'Pointer',
    weight: 30,
    total: 53,
    XS: 0,
    S: 22.64150943396226,
    M: 58.49056603773585,
    L: 18.86792452830189,
    XL: 0,
  },
  {
    name: 'Pointer',
    weight: 40,
    total: 139,
    XS: 0,
    S: 5.75539568345324,
    M: 74.10071942446044,
    L: 19.42446043165468,
    XL: 0.71942446043165,
  },
  {
    name: 'Pointer',
    weight: 50,
    total: 353,
    XS: 0,
    S: 1.69971671388102,
    M: 64.87252124645892,
    L: 32.86118980169972,
    XL: 0.56657223796034,
  },
  {
    name: 'Pointer',
    weight: 60,
    total: 258,
    XS: 0,
    S: 1.55038759689922,
    M: 46.12403100775194,
    L: 52.32558139534884,
    XL: 0,
  },
  {
    name: 'Pointer',
    weight: 70,
    total: 176,
    XS: 0,
    S: 0.56818181818182,
    M: 27.27272727272727,
    L: 69.88636363636364,
    XL: 2.27272727272727,
  },
  {
    name: 'Pointer',
    weight: 80,
    total: 65,
    XS: 0,
    S: 0,
    M: 20,
    L: 73.84615384615385,
    XL: 6.15384615384615,
  },
  {
    name: 'Pomchi',
    weight: 10,
    total: 33,
    XS: 48.48484848484848,
    S: 48.48484848484848,
    M: 3.03030303030303,
    L: 0,
    XL: 0,
  },
  {
    name: 'Pomchi',
    weight: 20,
    total: 63,
    XS: 12.6984126984127,
    S: 68.25396825396825,
    M: 19.04761904761905,
    L: 0,
    XL: 0,
  },
  {
    name: 'Pomeranian',
    weight: 10,
    total: 407,
    XS: 46.43734643734644,
    S: 42.26044226044226,
    M: 8.5995085995086,
    L: 2.21130221130221,
    XL: 0.49140049140049,
  },
  {
    name: 'Pomeranian',
    weight: 20,
    total: 586,
    XS: 10.40955631399317,
    S: 63.31058020477816,
    M: 23.37883959044369,
    L: 2.38907849829352,
    XL: 0.51194539249147,
  },
  {
    name: 'Pomeranian',
    weight: 30,
    total: 130,
    XS: 0,
    S: 28.46153846153846,
    M: 67.6923076923077,
    L: 3.84615384615385,
    XL: 0,
  },
  {
    name: 'Pomsky',
    weight: 10,
    total: 91,
    XS: 28.57142857142857,
    S: 52.74725274725275,
    M: 16.48351648351648,
    L: 2.1978021978022,
    XL: 0,
  },
  {
    name: 'Pomsky',
    weight: 20,
    total: 495,
    XS: 4.64646464646465,
    S: 53.73737373737374,
    M: 40.2020202020202,
    L: 1.01010101010101,
    XL: 0.4040404040404,
  },
  {
    name: 'Pomsky',
    weight: 30,
    total: 537,
    XS: 0,
    S: 18.80819366852886,
    M: 71.69459962756052,
    L: 9.31098696461825,
    XL: 0.18621973929236,
  },
  {
    name: 'Pomsky',
    weight: 40,
    total: 264,
    XS: 0.75757575757576,
    S: 4.16666666666667,
    M: 77.65151515151516,
    L: 17.04545454545455,
    XL: 0.37878787878788,
  },
  {
    name: 'Pomsky',
    weight: 50,
    total: 94,
    XS: 0,
    S: 2.12765957446809,
    M: 62.76595744680851,
    L: 34.04255319148936,
    XL: 1.06382978723404,
  },
  {
    name: 'Pomsky',
    weight: 60,
    total: 28,
    XS: 0,
    S: 0,
    M: 42.85714285714286,
    L: 57.14285714285714,
    XL: 0,
  },
  {
    name: 'Poodle',
    weight: 10,
    total: 166,
    XS: 24.09638554216867,
    S: 51.20481927710843,
    M: 15.66265060240964,
    L: 8.43373493975904,
    XL: 0.60240963855422,
  },
  {
    name: 'Poodle',
    weight: 20,
    total: 690,
    XS: 4.20289855072464,
    S: 61.30434782608696,
    M: 30.43478260869565,
    L: 4.05797101449275,
    XL: 0,
  },
  {
    name: 'Poodle',
    weight: 30,
    total: 475,
    XS: 0.21052631578947,
    S: 24,
    M: 63.36842105263158,
    L: 12.21052631578947,
    XL: 0.21052631578947,
  },
  {
    name: 'Poodle',
    weight: 40,
    total: 620,
    XS: 0,
    S: 5,
    M: 70.64516129032258,
    L: 23.87096774193548,
    XL: 0.48387096774194,
  },
  {
    name: 'Poodle',
    weight: 50,
    total: 1221,
    XS: 0,
    S: 3.43980343980344,
    M: 52.33415233415233,
    L: 43.40704340704341,
    XL: 0.81900081900082,
  },
  {
    name: 'Poodle',
    weight: 60,
    total: 901,
    XS: 0.11098779134295,
    S: 2.21975582685905,
    M: 35.18312985571587,
    L: 61.93118756936737,
    XL: 0.55493895671476,
  },
  {
    name: 'Poodle',
    weight: 70,
    total: 541,
    XS: 0,
    S: 1.1090573012939,
    M: 26.24768946395564,
    L: 71.53419593345656,
    XL: 1.1090573012939,
  },
  {
    name: 'Poodle',
    weight: 80,
    total: 198,
    XS: 0,
    S: 0.50505050505051,
    M: 23.23232323232323,
    L: 72.72727272727273,
    XL: 3.53535353535354,
  },
  {
    name: 'Poodle',
    weight: 90,
    total: 68,
    XS: 0,
    S: 2.94117647058824,
    M: 20.58823529411765,
    L: 67.6470588235294,
    XL: 8.82352941176471,
  },
  {
    name: 'Portuguese Water Dog',
    weight: 30,
    total: 30,
    XS: 3.33333333333333,
    S: 10,
    M: 83.33333333333333,
    L: 3.33333333333333,
    XL: 0,
  },
  {
    name: 'Portuguese Water Dog',
    weight: 40,
    total: 108,
    XS: 0,
    S: 3.7037037037037,
    M: 81.48148148148148,
    L: 14.81481481481481,
    XL: 0,
  },
  {
    name: 'Portuguese Water Dog',
    weight: 50,
    total: 181,
    XS: 0,
    S: 1.10497237569061,
    M: 64.08839779005525,
    L: 34.80662983425414,
    XL: 0,
  },
  {
    name: 'Portuguese Water Dog',
    weight: 60,
    total: 142,
    XS: 0,
    S: 0.70422535211268,
    M: 38.73239436619718,
    L: 59.15492957746479,
    XL: 1.40845070422535,
  },
  {
    name: 'Portuguese Water Dog',
    weight: 70,
    total: 43,
    XS: 0,
    S: 0,
    M: 25.58139534883721,
    L: 72.09302325581395,
    XL: 2.32558139534884,
  },
  {
    name: 'Pudelpointer',
    weight: 50,
    total: 39,
    XS: 0,
    S: 5.12820512820513,
    M: 58.97435897435897,
    L: 35.8974358974359,
    XL: 0,
  },
  {
    name: 'Pudelpointer',
    weight: 60,
    total: 32,
    XS: 0,
    S: 0,
    M: 40.625,
    L: 59.375,
    XL: 0,
  },
  {
    name: 'Pudelpointer',
    weight: 70,
    total: 28,
    XS: 0,
    S: 0,
    M: 39.28571428571429,
    L: 60.71428571428571,
    XL: 0,
  },
  {
    name: 'Pug',
    weight: 10,
    total: 74,
    XS: 5.40540540540541,
    S: 59.45945945945946,
    M: 25.67567567567568,
    L: 9.45945945945946,
    XL: 0,
  },
  {
    name: 'Pug',
    weight: 20,
    total: 702,
    XS: 2.13675213675214,
    S: 52.27920227920228,
    M: 39.88603988603989,
    L: 5.27065527065527,
    XL: 0.42735042735043,
  },
  {
    name: 'Pug',
    weight: 30,
    total: 449,
    XS: 0.89086859688196,
    S: 24.94432071269488,
    M: 65.92427616926503,
    L: 8.01781737193764,
    XL: 0.22271714922049,
  },
  {
    name: 'Pug',
    weight: 40,
    total: 86,
    XS: 1.16279069767442,
    S: 5.81395348837209,
    M: 70.93023255813954,
    L: 20.93023255813953,
    XL: 1.16279069767442,
  },
  {
    name: 'Puggle',
    weight: 20,
    total: 78,
    XS: 2.56410256410256,
    S: 44.87179487179487,
    M: 51.28205128205128,
    L: 1.28205128205128,
    XL: 0,
  },
  {
    name: 'Puggle',
    weight: 30,
    total: 173,
    XS: 0,
    S: 13.8728323699422,
    M: 80.92485549132948,
    L: 5.20231213872832,
    XL: 0,
  },
  {
    name: 'Puggle',
    weight: 40,
    total: 101,
    XS: 0,
    S: 7.92079207920792,
    M: 68.31683168316832,
    L: 23.76237623762376,
    XL: 0,
  },
  {
    name: 'Rat Terrier',
    weight: 10,
    total: 75,
    XS: 30.66666666666667,
    S: 49.33333333333333,
    M: 16,
    L: 2.66666666666667,
    XL: 1.33333333333333,
  },
  {
    name: 'Rat Terrier',
    weight: 20,
    total: 512,
    XS: 5.2734375,
    S: 68.1640625,
    M: 25.390625,
    L: 1.171875,
    XL: 0,
  },
  {
    name: 'Rat Terrier',
    weight: 30,
    total: 317,
    XS: 0.31545741324921,
    S: 31.23028391167192,
    M: 64.35331230283911,
    L: 3.47003154574132,
    XL: 0.63091482649842,
  },
  {
    name: 'Rat Terrier',
    weight: 40,
    total: 127,
    XS: 0.78740157480315,
    S: 7.08661417322835,
    M: 77.16535433070867,
    L: 14.17322834645669,
    XL: 0.78740157480315,
  },
  {
    name: 'Rat Terrier',
    weight: 50,
    total: 42,
    XS: 0,
    S: 0,
    M: 57.14285714285714,
    L: 42.85714285714286,
    XL: 0,
  },
  {
    name: 'Redbone Coonhound',
    weight: 40,
    total: 67,
    XS: 1.49253731343284,
    S: 1.49253731343284,
    M: 74.6268656716418,
    L: 22.38805970149254,
    XL: 0,
  },
  {
    name: 'Redbone Coonhound',
    weight: 50,
    total: 157,
    XS: 0.63694267515924,
    S: 1.91082802547771,
    M: 54.77707006369427,
    L: 42.03821656050955,
    XL: 0.63694267515924,
  },
  {
    name: 'Redbone Coonhound',
    weight: 60,
    total: 209,
    XS: 0.47846889952153,
    S: 1.43540669856459,
    M: 39.71291866028708,
    L: 57.41626794258373,
    XL: 0.95693779904306,
  },
  {
    name: 'Redbone Coonhound',
    weight: 70,
    total: 161,
    XS: 0,
    S: 1.24223602484472,
    M: 26.08695652173913,
    L: 70.80745341614907,
    XL: 1.86335403726708,
  },
  {
    name: 'Redbone Coonhound',
    weight: 80,
    total: 121,
    XS: 0,
    S: 1.65289256198347,
    M: 22.31404958677686,
    L: 71.90082644628099,
    XL: 4.13223140495868,
  },
  {
    name: 'Redbone Coonhound',
    weight: 90,
    total: 45,
    XS: 0,
    S: 0,
    M: 22.22222222222222,
    L: 64.44444444444444,
    XL: 13.33333333333333,
  },
  {
    name: 'Redbone Coonhound',
    weight: 100,
    total: 29,
    XS: 0,
    S: 0,
    M: 13.79310344827586,
    L: 58.62068965517241,
    XL: 27.58620689655172,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 30,
    total: 30,
    XS: 0,
    S: 33.33333333333333,
    M: 50,
    L: 16.66666666666667,
    XL: 0,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 40,
    total: 69,
    XS: 0,
    S: 7.2463768115942,
    M: 59.42028985507246,
    L: 31.88405797101449,
    XL: 1.44927536231884,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 50,
    total: 169,
    XS: 0,
    S: 2.36686390532544,
    M: 48.5207100591716,
    L: 47.92899408284024,
    XL: 1.18343195266272,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 60,
    total: 208,
    XS: 0.48076923076923,
    S: 1.44230769230769,
    M: 37.98076923076923,
    L: 58.65384615384615,
    XL: 1.44230769230769,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 70,
    total: 305,
    XS: 0,
    S: 0.98360655737705,
    M: 28.52459016393443,
    L: 67.54098360655738,
    XL: 2.95081967213115,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 80,
    total: 325,
    XS: 0,
    S: 2.15384615384615,
    M: 20.92307692307692,
    L: 72.61538461538461,
    XL: 4.30769230769231,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 90,
    total: 275,
    XS: 0,
    S: 1.45454545454545,
    M: 20,
    L: 68.36363636363636,
    XL: 10.18181818181818,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 100,
    total: 246,
    XS: 0,
    S: 0,
    M: 17.47967479674797,
    L: 64.22764227642276,
    XL: 18.29268292682927,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 110,
    total: 140,
    XS: 0,
    S: 0,
    M: 20.71428571428571,
    L: 54.28571428571429,
    XL: 25,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 120,
    total: 52,
    XS: 0,
    S: 0,
    M: 19.23076923076923,
    L: 42.30769230769231,
    XL: 38.46153846153846,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 130,
    total: 31,
    XS: 0,
    S: 0,
    M: 29.03225806451613,
    L: 29.03225806451613,
    XL: 41.93548387096774,
  },
  {
    name: 'Rottweiler',
    weight: 20,
    total: 28,
    XS: 10.71428571428571,
    S: 39.28571428571429,
    M: 28.57142857142857,
    L: 14.28571428571429,
    XL: 7.14285714285714,
  },
  {
    name: 'Rottweiler',
    weight: 30,
    total: 68,
    XS: 0,
    S: 13.23529411764706,
    M: 52.94117647058824,
    L: 26.47058823529412,
    XL: 7.35294117647059,
  },
  {
    name: 'Rottweiler',
    weight: 40,
    total: 95,
    XS: 0,
    S: 2.10526315789474,
    M: 58.94736842105263,
    L: 32.63157894736842,
    XL: 6.31578947368421,
  },
  {
    name: 'Rottweiler',
    weight: 50,
    total: 181,
    XS: 0,
    S: 0.5524861878453,
    M: 45.30386740331492,
    L: 48.61878453038674,
    XL: 5.52486187845304,
  },
  {
    name: 'Rottweiler',
    weight: 60,
    total: 261,
    XS: 0.76628352490421,
    S: 1.53256704980843,
    M: 30.26819923371648,
    L: 58.62068965517241,
    XL: 8.81226053639847,
  },
  {
    name: 'Rottweiler',
    weight: 70,
    total: 360,
    XS: 0.27777777777778,
    S: 1.66666666666667,
    M: 22.22222222222222,
    L: 63.61111111111111,
    XL: 12.22222222222222,
  },
  {
    name: 'Rottweiler',
    weight: 80,
    total: 432,
    XS: 0.23148148148148,
    S: 1.38888888888889,
    M: 16.89814814814815,
    L: 57.63888888888889,
    XL: 23.84259259259259,
  },
  {
    name: 'Rottweiler',
    weight: 90,
    total: 413,
    XS: 0,
    S: 1.21065375302663,
    M: 20.09685230024213,
    L: 43.82566585956416,
    XL: 34.86682808716707,
  },
  {
    name: 'Rottweiler',
    weight: 100,
    total: 488,
    XS: 0,
    S: 1.02459016393443,
    M: 18.64754098360656,
    L: 30.32786885245902,
    XL: 50,
  },
  {
    name: 'Rottweiler',
    weight: 110,
    total: 284,
    XS: 0,
    S: 2.11267605633803,
    M: 15.49295774647887,
    L: 21.47887323943662,
    XL: 60.91549295774648,
  },
  {
    name: 'Rottweiler',
    weight: 120,
    total: 228,
    XS: 0,
    S: 1.31578947368421,
    M: 14.03508771929825,
    L: 16.66666666666667,
    XL: 67.98245614035088,
  },
  {
    name: 'Rottweiler',
    weight: 130,
    total: 133,
    XS: 0,
    S: 0,
    M: 14.28571428571429,
    L: 6.76691729323308,
    XL: 78.94736842105263,
  },
  {
    name: 'Rottweiler',
    weight: 140,
    total: 77,
    XS: 0,
    S: 0,
    M: 15.58441558441558,
    L: 7.79220779220779,
    XL: 76.62337662337661,
  },
  {
    name: 'Rottweiler',
    weight: 150,
    total: 32,
    XS: 0,
    S: 0,
    M: 18.75,
    L: 0,
    XL: 81.25,
  },
  {
    name: 'Russell Terrier',
    weight: 20,
    total: 83,
    XS: 6.02409638554217,
    S: 67.46987951807229,
    M: 24.09638554216867,
    L: 2.40963855421687,
    XL: 0,
  },
  {
    name: 'Russell Terrier',
    weight: 30,
    total: 42,
    XS: 2.38095238095238,
    S: 35.71428571428571,
    M: 61.9047619047619,
    L: 0,
    XL: 0,
  },
  {
    name: 'Saint Berdoodle',
    weight: 80,
    total: 28,
    XS: 0,
    S: 0,
    M: 21.42857142857143,
    L: 60.71428571428571,
    XL: 17.85714285714286,
  },
  {
    name: 'Saint Bernard',
    weight: 70,
    total: 28,
    XS: 0,
    S: 0,
    M: 21.42857142857143,
    L: 75,
    XL: 3.57142857142857,
  },
  {
    name: 'Saint Bernard',
    weight: 80,
    total: 61,
    XS: 0,
    S: 0,
    M: 16.39344262295082,
    L: 54.0983606557377,
    XL: 29.50819672131148,
  },
  {
    name: 'Saint Bernard',
    weight: 90,
    total: 48,
    XS: 0,
    S: 4.16666666666667,
    M: 10.41666666666667,
    L: 60.41666666666667,
    XL: 25,
  },
  {
    name: 'Saint Bernard',
    weight: 100,
    total: 68,
    XS: 1.47058823529412,
    S: 0,
    M: 19.11764705882353,
    L: 29.41176470588235,
    XL: 50,
  },
  {
    name: 'Saint Bernard',
    weight: 110,
    total: 60,
    XS: 0,
    S: 0,
    M: 13.33333333333333,
    L: 33.33333333333333,
    XL: 53.33333333333333,
  },
  {
    name: 'Saint Bernard',
    weight: 120,
    total: 85,
    XS: 0,
    S: 1.17647058823529,
    M: 14.11764705882353,
    L: 8.23529411764706,
    XL: 76.47058823529412,
  },
  {
    name: 'Saint Bernard',
    weight: 130,
    total: 54,
    XS: 0,
    S: 0,
    M: 14.81481481481481,
    L: 3.7037037037037,
    XL: 81.48148148148148,
  },
  {
    name: 'Saint Bernard',
    weight: 140,
    total: 67,
    XS: 0,
    S: 0,
    M: 13.43283582089552,
    L: 8.95522388059701,
    XL: 77.61194029850746,
  },
  {
    name: 'Saint Bernard',
    weight: 150,
    total: 44,
    XS: 0,
    S: 0,
    M: 13.63636363636364,
    L: 9.09090909090909,
    XL: 77.27272727272727,
  },
  {
    name: 'Saint Bernard',
    weight: 160,
    total: 26,
    XS: 0,
    S: 0,
    M: 11.53846153846154,
    L: 3.84615384615385,
    XL: 84.61538461538461,
  },
  {
    name: 'Saluki',
    weight: 40,
    total: 29,
    XS: 0,
    S: 13.79310344827586,
    M: 86.20689655172414,
    L: 0,
    XL: 0,
  },
  {
    name: 'Saluki',
    weight: 50,
    total: 52,
    XS: 0,
    S: 9.61538461538462,
    M: 82.6923076923077,
    L: 7.69230769230769,
    XL: 0,
  },
  {
    name: 'Saluki',
    weight: 60,
    total: 28,
    XS: 0,
    S: 0,
    M: 78.57142857142857,
    L: 21.42857142857143,
    XL: 0,
  },
  {
    name: 'Samoyed',
    weight: 30,
    total: 54,
    XS: 0,
    S: 7.40740740740741,
    M: 53.7037037037037,
    L: 35.18518518518519,
    XL: 3.7037037037037,
  },
  {
    name: 'Samoyed',
    weight: 40,
    total: 145,
    XS: 1.37931034482759,
    S: 2.06896551724138,
    M: 49.6551724137931,
    L: 46.20689655172414,
    XL: 0.68965517241379,
  },
  {
    name: 'Samoyed',
    weight: 50,
    total: 268,
    XS: 0,
    S: 1.86567164179104,
    M: 36.94029850746269,
    L: 59.32835820895522,
    XL: 1.86567164179104,
  },
  {
    name: 'Samoyed',
    weight: 60,
    total: 228,
    XS: 0,
    S: 0.87719298245614,
    M: 25,
    L: 67.10526315789474,
    XL: 7.01754385964912,
  },
  {
    name: 'Samoyed',
    weight: 70,
    total: 131,
    XS: 0,
    S: 0,
    M: 23.66412213740458,
    L: 65.64885496183206,
    XL: 10.68702290076336,
  },
  {
    name: 'Samoyed',
    weight: 80,
    total: 53,
    XS: 0,
    S: 0,
    M: 22.64150943396226,
    L: 62.26415094339623,
    XL: 15.09433962264151,
  },
  {
    name: 'Schipperke',
    weight: 20,
    total: 76,
    XS: 1.31578947368421,
    S: 52.63157894736842,
    M: 42.10526315789474,
    L: 2.63157894736842,
    XL: 1.31578947368421,
  },
  {
    name: 'Schipperke',
    weight: 30,
    total: 30,
    XS: 3.33333333333333,
    S: 16.66666666666667,
    M: 73.33333333333333,
    L: 6.66666666666667,
    XL: 0,
  },
  {
    name: 'Schnoodle',
    weight: 20,
    total: 203,
    XS: 7.38916256157635,
    S: 63.54679802955665,
    M: 28.57142857142857,
    L: 0.49261083743842,
    XL: 0,
  },
  {
    name: 'Schnoodle',
    weight: 30,
    total: 84,
    XS: 0,
    S: 27.38095238095238,
    M: 72.61904761904762,
    L: 0,
    XL: 0,
  },
  {
    name: 'Schnoodle',
    weight: 40,
    total: 26,
    XS: 0,
    S: 3.84615384615385,
    M: 76.92307692307692,
    L: 19.23076923076923,
    XL: 0,
  },
  {
    name: 'Scottish Terrier',
    weight: 20,
    total: 150,
    XS: 2,
    S: 47.33333333333333,
    M: 47.33333333333333,
    L: 2.66666666666667,
    XL: 0.66666666666667,
  },
  {
    name: 'Scottish Terrier',
    weight: 30,
    total: 144,
    XS: 0,
    S: 20.83333333333333,
    M: 72.91666666666667,
    L: 5.55555555555556,
    XL: 0.69444444444444,
  },
  {
    name: 'Seppala Siberian Sleddog',
    weight: 50,
    total: 31,
    XS: 0,
    S: 0,
    M: 51.61290322580645,
    L: 48.38709677419355,
    XL: 0,
  },
  {
    name: 'Sharberian Husky',
    weight: 40,
    total: 52,
    XS: 0,
    S: 1.92307692307692,
    M: 61.53846153846154,
    L: 36.53846153846154,
    XL: 0,
  },
  {
    name: 'Sharberian Husky',
    weight: 50,
    total: 106,
    XS: 0,
    S: 0,
    M: 41.50943396226415,
    L: 57.54716981132075,
    XL: 0.94339622641509,
  },
  {
    name: 'Sharberian Husky',
    weight: 60,
    total: 95,
    XS: 0,
    S: 0,
    M: 33.68421052631579,
    L: 65.26315789473684,
    XL: 1.05263157894737,
  },
  {
    name: 'Sharberian Husky',
    weight: 70,
    total: 49,
    XS: 0,
    S: 2.04081632653061,
    M: 24.48979591836735,
    L: 63.26530612244898,
    XL: 10.20408163265306,
  },
  {
    name: 'Sheepadoodle',
    weight: 20,
    total: 55,
    XS: 7.27272727272727,
    S: 43.63636363636364,
    M: 47.27272727272727,
    L: 1.81818181818182,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 30,
    total: 111,
    XS: 1.8018018018018,
    S: 22.52252252252252,
    M: 63.96396396396396,
    L: 11.71171171171171,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 40,
    total: 91,
    XS: 3.2967032967033,
    S: 8.79120879120879,
    M: 68.13186813186813,
    L: 19.78021978021978,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 50,
    total: 144,
    XS: 0,
    S: 2.08333333333333,
    M: 53.47222222222222,
    L: 43.75,
    XL: 0.69444444444444,
  },
  {
    name: 'Sheepadoodle',
    weight: 60,
    total: 160,
    XS: 0,
    S: 0.625,
    M: 38.75,
    L: 60.625,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 70,
    total: 131,
    XS: 0,
    S: 3.05343511450382,
    M: 22.90076335877863,
    L: 71.75572519083968,
    XL: 2.29007633587786,
  },
  {
    name: 'Sheepadoodle',
    weight: 80,
    total: 84,
    XS: 0,
    S: 2.38095238095238,
    M: 9.52380952380952,
    L: 83.33333333333333,
    XL: 4.76190476190476,
  },
  {
    name: 'Sheltie Shepherd',
    weight: 30,
    total: 30,
    XS: 0,
    S: 16.66666666666667,
    M: 76.66666666666667,
    L: 6.66666666666667,
    XL: 0,
  },
  {
    name: 'Shetland Sheepdog',
    weight: 10,
    total: 30,
    XS: 23.33333333333333,
    S: 43.33333333333333,
    M: 23.33333333333333,
    L: 10,
    XL: 0,
  },
  {
    name: 'Shetland Sheepdog',
    weight: 20,
    total: 264,
    XS: 3.40909090909091,
    S: 51.13636363636364,
    M: 44.6969696969697,
    L: 0.37878787878788,
    XL: 0.37878787878788,
  },
  {
    name: 'Shetland Sheepdog',
    weight: 30,
    total: 346,
    XS: 0.57803468208092,
    S: 21.09826589595376,
    M: 75.43352601156069,
    L: 2.89017341040462,
    XL: 0,
  },
  {
    name: 'Shetland Sheepdog',
    weight: 40,
    total: 130,
    XS: 0,
    S: 5.38461538461538,
    M: 82.3076923076923,
    L: 12.30769230769231,
    XL: 0,
  },
  {
    name: 'Shetland Sheepdog',
    weight: 50,
    total: 39,
    XS: 0,
    S: 7.69230769230769,
    M: 66.66666666666667,
    L: 25.64102564102564,
    XL: 0,
  },
  {
    name: 'Shiba Inu',
    weight: 10,
    total: 82,
    XS: 10.97560975609756,
    S: 54.8780487804878,
    M: 30.48780487804878,
    L: 2.4390243902439,
    XL: 1.21951219512195,
  },
  {
    name: 'Shiba Inu',
    weight: 20,
    total: 1112,
    XS: 2.33812949640288,
    S: 37.41007194244604,
    M: 57.46402877697842,
    L: 2.69784172661871,
    XL: 0.08992805755396,
  },
  {
    name: 'Shiba Inu',
    weight: 30,
    total: 1861,
    XS: 0.26867275658248,
    S: 10.90811391724879,
    M: 82.75120902740461,
    L: 5.91080064481462,
    XL: 0.16120365394949,
  },
  {
    name: 'Shiba Inu',
    weight: 40,
    total: 519,
    XS: 0,
    S: 3.27552986512524,
    M: 75.72254335260116,
    L: 20.80924855491329,
    XL: 0.19267822736031,
  },
  {
    name: 'Shiba Inu',
    weight: 50,
    total: 135,
    XS: 0,
    S: 0.74074074074074,
    M: 57.03703703703704,
    L: 41.48148148148148,
    XL: 0.74074074074074,
  },
  {
    name: 'Shichon',
    weight: 10,
    total: 28,
    XS: 25,
    S: 57.14285714285714,
    M: 17.85714285714286,
    L: 0,
    XL: 0,
  },
  {
    name: 'Shichon',
    weight: 20,
    total: 81,
    XS: 9.87654320987654,
    S: 72.8395061728395,
    M: 16.04938271604938,
    L: 1.23456790123457,
    XL: 0,
  },
  {
    name: 'Shih Tzu',
    weight: 10,
    total: 465,
    XS: 30.96774193548387,
    S: 53.11827956989247,
    M: 13.76344086021505,
    L: 1.29032258064516,
    XL: 0.86021505376344,
  },
  {
    name: 'Shih Tzu',
    weight: 20,
    total: 1497,
    XS: 5.07682030728123,
    S: 67.46826987307949,
    M: 25.78490313961256,
    L: 1.33600534402138,
    XL: 0.33400133600534,
  },
  {
    name: 'Shih Tzu',
    weight: 30,
    total: 183,
    XS: 0.54644808743169,
    S: 28.96174863387978,
    M: 67.75956284153006,
    L: 2.73224043715847,
    XL: 0,
  },
  {
    name: 'Shih Tzu',
    weight: 40,
    total: 26,
    XS: 0,
    S: 7.69230769230769,
    M: 76.92307692307692,
    L: 15.38461538461538,
    XL: 0,
  },
  {
    name: 'Shihpoo',
    weight: 10,
    total: 51,
    XS: 43.13725490196078,
    S: 49.01960784313725,
    M: 7.84313725490196,
    L: 0,
    XL: 0,
  },
  {
    name: 'Shihpoo',
    weight: 20,
    total: 150,
    XS: 8.66666666666667,
    S: 68.66666666666667,
    M: 20.66666666666667,
    L: 0.66666666666667,
    XL: 1.33333333333333,
  },
  {
    name: 'Shorkie Tzu',
    weight: 10,
    total: 27,
    XS: 51.85185185185185,
    S: 44.44444444444444,
    M: 0,
    L: 0,
    XL: 3.7037037037037,
  },
  {
    name: 'Shorkie Tzu',
    weight: 20,
    total: 59,
    XS: 6.77966101694915,
    S: 72.88135593220339,
    M: 20.33898305084746,
    L: 0,
    XL: 0,
  },
  {
    name: 'Siberian Husky',
    weight: 10,
    total: 56,
    XS: 3.57142857142857,
    S: 26.78571428571429,
    M: 41.07142857142857,
    L: 25,
    XL: 3.57142857142857,
  },
  {
    name: 'Siberian Husky',
    weight: 20,
    total: 300,
    XS: 3.33333333333333,
    S: 28,
    M: 42.33333333333333,
    L: 26.33333333333333,
    XL: 0,
  },
  {
    name: 'Siberian Husky',
    weight: 30,
    total: 732,
    XS: 0.40983606557377,
    S: 9.83606557377049,
    M: 59.01639344262295,
    L: 30.05464480874317,
    XL: 0.68306010928962,
  },
  {
    name: 'Siberian Husky',
    weight: 40,
    total: 2668,
    XS: 0.11244377811094,
    S: 2.28635682158921,
    M: 58.09595202398801,
    L: 38.98050974512744,
    XL: 0.52473763118441,
  },
  {
    name: 'Siberian Husky',
    weight: 50,
    total: 7086,
    XS: 0.04233700254022,
    S: 1.25599774202653,
    M: 44.08693197854925,
    L: 53.66920688681908,
    XL: 0.94552639006492,
  },
  {
    name: 'Siberian Husky',
    weight: 60,
    total: 6185,
    XS: 0.01616814874697,
    S: 0.80840743734842,
    M: 31.22069523039612,
    L: 65.78819725141472,
    XL: 2.16653193209378,
  },
  {
    name: 'Siberian Husky',
    weight: 70,
    total: 3695,
    XS: 0.05412719891746,
    S: 0.64952638700947,
    M: 25.92692828146143,
    L: 69.49932341001353,
    XL: 3.87009472259811,
  },
  {
    name: 'Siberian Husky',
    weight: 80,
    total: 1684,
    XS: 0,
    S: 0.17814726840855,
    M: 21.85273159144893,
    L: 69.53681710213777,
    XL: 8.43230403800475,
  },
  {
    name: 'Siberian Husky',
    weight: 90,
    total: 633,
    XS: 0,
    S: 0.31595576619273,
    M: 17.85150078988942,
    L: 66.82464454976304,
    XL: 15.00789889415482,
  },
  {
    name: 'Siberian Husky',
    weight: 100,
    total: 203,
    XS: 0,
    S: 0.98522167487685,
    M: 15.27093596059113,
    L: 58.62068965517241,
    XL: 25.12315270935961,
  },
  {
    name: 'Siberian Husky',
    weight: 110,
    total: 53,
    XS: 0,
    S: 0,
    M: 15.09433962264151,
    L: 62.26415094339623,
    XL: 22.64150943396226,
  },
  {
    name: 'Siberian Husky',
    weight: 120,
    total: 33,
    XS: 0,
    S: 0,
    M: 21.21212121212121,
    L: 54.54545454545455,
    XL: 24.24242424242424,
  },
  {
    name: 'Silken Windhound',
    weight: 40,
    total: 30,
    XS: 0,
    S: 33.33333333333333,
    M: 66.66666666666667,
    L: 0,
    XL: 0,
  },
  {
    name: 'Silky Terrier',
    weight: 10,
    total: 31,
    XS: 58.06451612903226,
    S: 29.03225806451613,
    M: 12.90322580645161,
    L: 0,
    XL: 0,
  },
  {
    name: 'Silky Terrier',
    weight: 20,
    total: 76,
    XS: 14.47368421052632,
    S: 63.15789473684211,
    M: 19.73684210526316,
    L: 2.63157894736842,
    XL: 0,
  },
  {
    name: 'Small Munsterlander',
    weight: 50,
    total: 28,
    XS: 0,
    S: 3.57142857142857,
    M: 67.85714285714286,
    L: 28.57142857142857,
    XL: 0,
  },
  {
    name: 'Smooth Fox Terrier',
    weight: 20,
    total: 42,
    XS: 2.38095238095238,
    S: 54.76190476190476,
    M: 42.85714285714286,
    L: 0,
    XL: 0,
  },
  {
    name: 'Smooth and Wire Fox Terrier',
    weight: 20,
    total: 27,
    XS: 3.7037037037037,
    S: 66.66666666666667,
    M: 25.92592592592593,
    L: 3.7037037037037,
    XL: 0,
  },
  {
    name: 'Soft Coated Wheaten Terrier',
    weight: 20,
    total: 35,
    XS: 2.85714285714286,
    S: 45.71428571428571,
    M: 51.42857142857143,
    L: 0,
    XL: 0,
  },
  {
    name: 'Soft Coated Wheaten Terrier',
    weight: 30,
    total: 217,
    XS: 0,
    S: 13.82488479262673,
    M: 79.72350230414747,
    L: 6.45161290322581,
    XL: 0,
  },
  {
    name: 'Soft Coated Wheaten Terrier',
    weight: 40,
    total: 383,
    XS: 0,
    S: 2.088772845953,
    M: 85.63968668407311,
    L: 12.01044386422977,
    XL: 0.26109660574413,
  },
  {
    name: 'Soft Coated Wheaten Terrier',
    weight: 50,
    total: 152,
    XS: 0.65789473684211,
    S: 0.65789473684211,
    M: 65.13157894736842,
    L: 33.55263157894737,
    XL: 0,
  },
  {
    name: 'Soft Coated Wheaten Terrier',
    weight: 60,
    total: 39,
    XS: 0,
    S: 0,
    M: 33.33333333333333,
    L: 64.1025641025641,
    XL: 2.56410256410256,
  },
  {
    name: 'Spanish Greyhound',
    weight: 50,
    total: 113,
    XS: 0,
    S: 14.15929203539823,
    M: 61.06194690265487,
    L: 24.7787610619469,
    XL: 0,
  },
  {
    name: 'Spanish Greyhound',
    weight: 60,
    total: 68,
    XS: 0,
    S: 10.29411764705882,
    M: 64.70588235294117,
    L: 25,
    XL: 0,
  },
  {
    name: 'Spanish Greyhound',
    weight: 70,
    total: 26,
    XS: 0,
    S: 3.84615384615385,
    M: 88.46153846153847,
    L: 7.69230769230769,
    XL: 0,
  },
  {
    name: 'Spinone Italiano',
    weight: 70,
    total: 28,
    XS: 0,
    S: 3.57142857142857,
    M: 17.85714285714286,
    L: 75,
    XL: 3.57142857142857,
  },
  {
    name: 'Spinone Italiano',
    weight: 80,
    total: 36,
    XS: 0,
    S: 0,
    M: 25,
    L: 72.22222222222221,
    XL: 2.77777777777778,
  },
  {
    name: 'Staffordshire Bull Terrier',
    weight: 30,
    total: 50,
    XS: 0,
    S: 10,
    M: 60,
    L: 28,
    XL: 2,
  },
  {
    name: 'Staffordshire Bull Terrier',
    weight: 40,
    total: 134,
    XS: 0.74626865671642,
    S: 2.23880597014925,
    M: 59.70149253731343,
    L: 35.82089552238806,
    XL: 1.49253731343284,
  },
  {
    name: 'Staffordshire Bull Terrier',
    weight: 50,
    total: 163,
    XS: 0,
    S: 0.61349693251534,
    M: 39.87730061349693,
    L: 56.44171779141104,
    XL: 3.06748466257669,
  },
  {
    name: 'Staffordshire Bull Terrier',
    weight: 60,
    total: 113,
    XS: 0,
    S: 0,
    M: 26.54867256637168,
    L: 70.79646017699115,
    XL: 2.65486725663717,
  },
  {
    name: 'Staffordshire Bull Terrier',
    weight: 70,
    total: 86,
    XS: 0,
    S: 0,
    M: 13.95348837209302,
    L: 82.55813953488372,
    XL: 3.48837209302326,
  },
  {
    name: 'Staffordshire Bull Terrier',
    weight: 80,
    total: 61,
    XS: 0,
    S: 0,
    M: 9.83606557377049,
    L: 78.68852459016394,
    XL: 11.47540983606557,
  },
  {
    name: 'Staffy Bull Pit',
    weight: 50,
    total: 36,
    XS: 0,
    S: 0,
    M: 27.77777777777778,
    L: 72.22222222222221,
    XL: 0,
  },
  {
    name: 'Staffy Bull Pit',
    weight: 60,
    total: 27,
    XS: 0,
    S: 3.7037037037037,
    M: 18.51851851851852,
    L: 77.77777777777779,
    XL: 0,
  },
  {
    name: 'Staffy Bull Pit',
    weight: 70,
    total: 31,
    XS: 0,
    S: 0,
    M: 9.67741935483871,
    L: 70.96774193548387,
    XL: 19.35483870967742,
  },
  {
    name: 'Standard Schnauzer',
    weight: 20,
    total: 61,
    XS: 4.91803278688525,
    S: 47.54098360655738,
    M: 44.26229508196721,
    L: 1.63934426229508,
    XL: 1.63934426229508,
  },
  {
    name: 'Standard Schnauzer',
    weight: 30,
    total: 106,
    XS: 0,
    S: 21.69811320754717,
    M: 73.58490566037736,
    L: 4.71698113207547,
    XL: 0,
  },
  {
    name: 'Standard Schnauzer',
    weight: 40,
    total: 115,
    XS: 0,
    S: 5.21739130434783,
    M: 81.73913043478261,
    L: 13.04347826086957,
    XL: 0,
  },
  {
    name: 'Standard Schnauzer',
    weight: 50,
    total: 59,
    XS: 1.69491525423729,
    S: 1.69491525423729,
    M: 67.79661016949153,
    L: 28.8135593220339,
    XL: 0,
  },
  {
    name: 'Taco Terrier',
    weight: 20,
    total: 28,
    XS: 3.57142857142857,
    S: 67.85714285714286,
    M: 25,
    L: 3.57142857142857,
    XL: 0,
  },
  {
    name: 'Taiwan Dog',
    weight: 30,
    total: 41,
    XS: 0,
    S: 21.95121951219512,
    M: 70.73170731707317,
    L: 7.31707317073171,
    XL: 0,
  },
  {
    name: 'Taiwan Dog',
    weight: 40,
    total: 77,
    XS: 0,
    S: 10.38961038961039,
    M: 72.72727272727273,
    L: 15.58441558441558,
    XL: 1.2987012987013,
  },
  {
    name: 'Taiwan Dog',
    weight: 50,
    total: 47,
    XS: 0,
    S: 0,
    M: 72.34042553191489,
    L: 27.65957446808511,
    XL: 0,
  },
  {
    name: 'Tamaskan Dog',
    weight: 70,
    total: 30,
    XS: 0,
    S: 3.33333333333333,
    M: 23.33333333333333,
    L: 73.33333333333333,
    XL: 0,
  },
  {
    name: 'Texas Heeler',
    weight: 20,
    total: 38,
    XS: 2.63157894736842,
    S: 42.10526315789474,
    M: 47.36842105263158,
    L: 7.89473684210526,
    XL: 0,
  },
  {
    name: 'Texas Heeler',
    weight: 30,
    total: 86,
    XS: 0,
    S: 8.13953488372093,
    M: 70.93023255813954,
    L: 17.44186046511628,
    XL: 3.48837209302326,
  },
  {
    name: 'Texas Heeler',
    weight: 40,
    total: 177,
    XS: 0,
    S: 5.08474576271186,
    M: 75.70621468926554,
    L: 18.07909604519774,
    XL: 1.12994350282486,
  },
  {
    name: 'Texas Heeler',
    weight: 50,
    total: 145,
    XS: 0,
    S: 2.06896551724138,
    M: 66.20689655172414,
    L: 31.72413793103448,
    XL: 0,
  },
  {
    name: 'Texas Heeler',
    weight: 60,
    total: 80,
    XS: 0,
    S: 1.25,
    M: 47.5,
    L: 50,
    XL: 1.25,
  },
  {
    name: 'Texas Heeler',
    weight: 70,
    total: 36,
    XS: 0,
    S: 2.77777777777778,
    M: 25,
    L: 69.44444444444444,
    XL: 2.77777777777778,
  },
  {
    name: 'Thai Ridgeback',
    weight: 40,
    total: 40,
    XS: 0,
    S: 17.5,
    M: 57.5,
    L: 25,
    XL: 0,
  },
  {
    name: 'Thai Ridgeback',
    weight: 50,
    total: 33,
    XS: 0,
    S: 0,
    M: 57.57575757575758,
    L: 42.42424242424242,
    XL: 0,
  },
  {
    name: 'Tibetan Spaniel',
    weight: 20,
    total: 38,
    XS: 2.63157894736842,
    S: 68.42105263157895,
    M: 26.31578947368421,
    L: 2.63157894736842,
    XL: 0,
  },
  {
    name: 'Tibetan Terrier',
    weight: 20,
    total: 36,
    XS: 5.55555555555556,
    S: 50,
    M: 44.44444444444444,
    L: 0,
    XL: 0,
  },
  {
    name: 'Tibetan Terrier',
    weight: 30,
    total: 70,
    XS: 0,
    S: 18.57142857142857,
    M: 78.57142857142857,
    L: 2.85714285714286,
    XL: 0,
  },
  {
    name: 'Tibetan Terrier',
    weight: 40,
    total: 27,
    XS: 0,
    S: 3.7037037037037,
    M: 85.18518518518519,
    L: 11.11111111111111,
    XL: 0,
  },
  {
    name: 'Toy Fox Terrier',
    weight: 10,
    total: 26,
    XS: 46.15384615384615,
    S: 38.46153846153846,
    M: 11.53846153846154,
    L: 3.84615384615385,
    XL: 0,
  },
  {
    name: 'Toy Fox Terrier',
    weight: 20,
    total: 34,
    XS: 14.70588235294118,
    S: 58.82352941176471,
    M: 26.47058823529412,
    L: 0,
    XL: 0,
  },
  {
    name: 'Toy Poodle',
    weight: 10,
    total: 280,
    XS: 61.07142857142857,
    S: 31.07142857142857,
    M: 5.71428571428571,
    L: 2.14285714285714,
    XL: 0,
  },
  {
    name: 'Toy Poodle',
    weight: 20,
    total: 218,
    XS: 14.67889908256881,
    S: 71.10091743119266,
    M: 12.38532110091743,
    L: 1.8348623853211,
    XL: 0,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 30,
    total: 39,
    XS: 2.56410256410256,
    S: 5.12820512820513,
    M: 76.92307692307692,
    L: 15.38461538461538,
    XL: 0,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 40,
    total: 171,
    XS: 0,
    S: 2.92397660818713,
    M: 79.53216374269006,
    L: 17.54385964912281,
    XL: 0,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 50,
    total: 422,
    XS: 0.23696682464455,
    S: 1.65876777251185,
    M: 62.55924170616114,
    L: 35.30805687203791,
    XL: 0.23696682464455,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 60,
    total: 450,
    XS: 0,
    S: 1.55555555555556,
    M: 40.88888888888889,
    L: 56.88888888888889,
    XL: 0.66666666666667,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 70,
    total: 360,
    XS: 0,
    S: 0.83333333333333,
    M: 30.55555555555556,
    L: 66.66666666666667,
    XL: 1.94444444444444,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 80,
    total: 194,
    XS: 0,
    S: 0.51546391752577,
    M: 22.68041237113402,
    L: 73.71134020618557,
    XL: 3.09278350515464,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 90,
    total: 82,
    XS: 0,
    S: 2.4390243902439,
    M: 23.17073170731707,
    L: 62.19512195121951,
    XL: 12.19512195121951,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 100,
    total: 28,
    XS: 0,
    S: 0,
    M: 10.71428571428571,
    L: 64.28571428571429,
    XL: 25,
  },
  {
    name: 'Vizsla',
    weight: 20,
    total: 88,
    XS: 2.27272727272727,
    S: 34.09090909090909,
    M: 55.68181818181818,
    L: 6.81818181818182,
    XL: 1.13636363636364,
  },
  {
    name: 'Vizsla',
    weight: 30,
    total: 181,
    XS: 1.10497237569061,
    S: 17.67955801104972,
    M: 71.2707182320442,
    L: 9.94475138121547,
    XL: 0,
  },
  {
    name: 'Vizsla',
    weight: 40,
    total: 700,
    XS: 0.28571428571429,
    S: 6.57142857142857,
    M: 82,
    L: 11,
    XL: 0.14285714285714,
  },
  {
    name: 'Vizsla',
    weight: 50,
    total: 1398,
    XS: 0.07153075822604,
    S: 1.85979971387697,
    M: 72.96137339055794,
    L: 24.96423462088698,
    XL: 0.14306151645207,
  },
  {
    name: 'Vizsla',
    weight: 60,
    total: 954,
    XS: 0,
    S: 1.46750524109015,
    M: 53.77358490566038,
    L: 44.65408805031447,
    XL: 0.10482180293501,
  },
  {
    name: 'Vizsla',
    weight: 70,
    total: 358,
    XS: 0,
    S: 0.83798882681564,
    M: 35.75418994413408,
    L: 62.84916201117318,
    XL: 0.55865921787709,
  },
  {
    name: 'Vizsla',
    weight: 80,
    total: 83,
    XS: 0,
    S: 0,
    M: 30.12048192771084,
    L: 68.67469879518072,
    XL: 1.20481927710843,
  },
  {
    name: 'Weimaraner',
    weight: 30,
    total: 37,
    XS: 8.10810810810811,
    S: 18.91891891891892,
    M: 54.05405405405405,
    L: 18.91891891891892,
    XL: 0,
  },
  {
    name: 'Weimaraner',
    weight: 40,
    total: 52,
    XS: 1.92307692307692,
    S: 15.38461538461538,
    M: 53.84615384615385,
    L: 28.84615384615385,
    XL: 0,
  },
  {
    name: 'Weimaraner',
    weight: 50,
    total: 168,
    XS: 0,
    S: 2.38095238095238,
    M: 57.14285714285714,
    L: 39.88095238095238,
    XL: 0.5952380952381,
  },
  {
    name: 'Weimaraner',
    weight: 60,
    total: 305,
    XS: 0,
    S: 0.98360655737705,
    M: 48.85245901639344,
    L: 50.16393442622951,
    XL: 0,
  },
  {
    name: 'Weimaraner',
    weight: 70,
    total: 402,
    XS: 0.49751243781095,
    S: 1.24378109452736,
    M: 31.09452736318408,
    L: 66.41791044776119,
    XL: 0.74626865671642,
  },
  {
    name: 'Weimaraner',
    weight: 80,
    total: 366,
    XS: 0,
    S: 0.81967213114754,
    M: 24.31693989071038,
    L: 73.77049180327869,
    XL: 1.09289617486339,
  },
  {
    name: 'Weimaraner',
    weight: 90,
    total: 182,
    XS: 0,
    S: 0,
    M: 25.82417582417582,
    L: 69.23076923076923,
    XL: 4.94505494505495,
  },
  {
    name: 'Weimaraner',
    weight: 100,
    total: 74,
    XS: 0,
    S: 0,
    M: 28.37837837837838,
    L: 59.45945945945946,
    XL: 12.16216216216216,
  },
  {
    name: 'Welsh Springer Spaniel',
    weight: 40,
    total: 33,
    XS: 0,
    S: 3.03030303030303,
    M: 87.87878787878788,
    L: 9.09090909090909,
    XL: 0,
  },
  {
    name: 'Welsh Terrier',
    weight: 20,
    total: 72,
    XS: 2.77777777777778,
    S: 62.5,
    M: 34.72222222222222,
    L: 0,
    XL: 0,
  },
  {
    name: 'Welsh Terrier',
    weight: 30,
    total: 79,
    XS: 0,
    S: 41.77215189873418,
    M: 56.9620253164557,
    L: 1.26582278481013,
    XL: 0,
  },
  {
    name: 'West Highland White Terrier',
    weight: 10,
    total: 39,
    XS: 20.51282051282051,
    S: 61.53846153846154,
    M: 17.94871794871795,
    L: 0,
    XL: 0,
  },
  {
    name: 'West Highland White Terrier',
    weight: 20,
    total: 510,
    XS: 1.96078431372549,
    S: 56.47058823529412,
    M: 40.19607843137255,
    L: 1.17647058823529,
    XL: 0.19607843137255,
  },
  {
    name: 'West Highland White Terrier',
    weight: 30,
    total: 168,
    XS: 0.5952380952381,
    S: 30.35714285714286,
    M: 63.0952380952381,
    L: 4.76190476190476,
    XL: 1.19047619047619,
  },
  {
    name: 'Whippet',
    weight: 20,
    total: 61,
    XS: 11.47540983606557,
    S: 67.21311475409836,
    M: 19.67213114754098,
    L: 1.63934426229508,
    XL: 0,
  },
  {
    name: 'Whippet',
    weight: 30,
    total: 304,
    XS: 3.61842105263158,
    S: 43.09210526315789,
    M: 48.68421052631579,
    L: 4.27631578947368,
    XL: 0.32894736842105,
  },
  {
    name: 'Whippet',
    weight: 40,
    total: 380,
    XS: 0.78947368421053,
    S: 26.31578947368421,
    M: 66.57894736842105,
    L: 5.52631578947368,
    XL: 0.78947368421053,
  },
  {
    name: 'Whippet',
    weight: 50,
    total: 155,
    XS: 0,
    S: 6.45161290322581,
    M: 70.3225806451613,
    L: 23.2258064516129,
    XL: 0,
  },
  {
    name: 'Whippet',
    weight: 60,
    total: 39,
    XS: 0,
    S: 5.12820512820513,
    M: 53.84615384615385,
    L: 41.02564102564103,
    XL: 0,
  },
  {
    name: 'White German Shepherd',
    weight: 50,
    total: 29,
    XS: 0,
    S: 3.44827586206897,
    M: 44.82758620689655,
    L: 51.72413793103448,
    XL: 0,
  },
  {
    name: 'White German Shepherd',
    weight: 60,
    total: 37,
    XS: 0,
    S: 2.7027027027027,
    M: 29.72972972972973,
    L: 59.45945945945946,
    XL: 8.10810810810811,
  },
  {
    name: 'White German Shepherd',
    weight: 70,
    total: 57,
    XS: 0,
    S: 1.75438596491228,
    M: 19.29824561403509,
    L: 71.9298245614035,
    XL: 7.01754385964912,
  },
  {
    name: 'White German Shepherd',
    weight: 80,
    total: 46,
    XS: 0,
    S: 0,
    M: 17.39130434782609,
    L: 78.26086956521739,
    XL: 4.34782608695652,
  },
  {
    name: 'White German Shepherd',
    weight: 90,
    total: 30,
    XS: 0,
    S: 0,
    M: 6.66666666666667,
    L: 86.66666666666667,
    XL: 6.66666666666667,
  },
  {
    name: 'Whoodle',
    weight: 30,
    total: 40,
    XS: 0,
    S: 32.5,
    M: 65,
    L: 2.5,
    XL: 0,
  },
  {
    name: 'Whoodle',
    weight: 40,
    total: 42,
    XS: 2.38095238095238,
    S: 7.14285714285714,
    M: 76.19047619047619,
    L: 14.28571428571429,
    XL: 0,
  },
  {
    name: 'Whoodle',
    weight: 50,
    total: 33,
    XS: 0,
    S: 0,
    M: 78.7878787878788,
    L: 18.18181818181818,
    XL: 3.03030303030303,
  },
  {
    name: 'Wire Fox Terrier',
    weight: 20,
    total: 138,
    XS: 7.97101449275362,
    S: 70.28985507246377,
    M: 21.01449275362319,
    L: 0.72463768115942,
    XL: 0,
  },
  {
    name: 'Wire Fox Terrier',
    weight: 30,
    total: 91,
    XS: 0,
    S: 41.75824175824176,
    M: 54.94505494505495,
    L: 2.1978021978022,
    XL: 1.0989010989011,
  },
  {
    name: 'Wirehaired Pointing Griffon',
    weight: 40,
    total: 29,
    XS: 0,
    S: 6.89655172413793,
    M: 72.41379310344828,
    L: 17.24137931034483,
    XL: 3.44827586206897,
  },
  {
    name: 'Wirehaired Pointing Griffon',
    weight: 50,
    total: 131,
    XS: 0,
    S: 3.05343511450382,
    M: 65.64885496183206,
    L: 29.77099236641221,
    XL: 1.52671755725191,
  },
  {
    name: 'Wirehaired Pointing Griffon',
    weight: 60,
    total: 175,
    XS: 0,
    S: 2.28571428571429,
    M: 42.28571428571429,
    L: 54.28571428571429,
    XL: 1.14285714285714,
  },
  {
    name: 'Wirehaired Pointing Griffon',
    weight: 70,
    total: 117,
    XS: 0,
    S: 1.70940170940171,
    M: 30.76923076923077,
    L: 64.95726495726495,
    XL: 2.56410256410256,
  },
  {
    name: 'Wirehaired Pointing Griffon',
    weight: 80,
    total: 41,
    XS: 0,
    S: 0,
    M: 24.39024390243902,
    L: 63.41463414634146,
    XL: 12.19512195121951,
  },
  {
    name: 'Wolfdog',
    weight: 50,
    total: 31,
    XS: 3.2258064516129,
    S: 0,
    M: 35.48387096774194,
    L: 54.83870967741935,
    XL: 6.45161290322581,
  },
  {
    name: 'Wolfdog',
    weight: 60,
    total: 37,
    XS: 0,
    S: 0,
    M: 43.24324324324324,
    L: 54.05405405405405,
    XL: 2.7027027027027,
  },
  {
    name: 'Wolfdog',
    weight: 70,
    total: 54,
    XS: 0,
    S: 0,
    M: 22.22222222222222,
    L: 72.22222222222221,
    XL: 5.55555555555556,
  },
  {
    name: 'Wolfdog',
    weight: 80,
    total: 61,
    XS: 1.63934426229508,
    S: 3.27868852459016,
    M: 24.59016393442623,
    L: 59.01639344262295,
    XL: 11.47540983606557,
  },
  {
    name: 'Wolfdog',
    weight: 90,
    total: 41,
    XS: 0,
    S: 7.31707317073171,
    M: 21.95121951219512,
    L: 58.53658536585366,
    XL: 12.19512195121951,
  },
  {
    name: 'Wolfdog',
    weight: 100,
    total: 36,
    XS: 0,
    S: 2.77777777777778,
    M: 19.44444444444444,
    L: 41.66666666666667,
    XL: 36.11111111111111,
  },
  {
    name: 'Xoloitzcuintli',
    weight: 20,
    total: 34,
    XS: 8.82352941176471,
    S: 61.76470588235294,
    M: 26.47058823529412,
    L: 2.94117647058824,
    XL: 0,
  },
  {
    name: 'Xoloitzcuintli',
    weight: 30,
    total: 30,
    XS: 3.33333333333333,
    S: 26.66666666666667,
    M: 60,
    L: 10,
    XL: 0,
  },
  {
    name: 'Xoloitzcuintli',
    weight: 40,
    total: 38,
    XS: 0,
    S: 23.68421052631579,
    M: 73.6842105263158,
    L: 2.63157894736842,
    XL: 0,
  },
  {
    name: 'Yorkipoo',
    weight: 10,
    total: 84,
    XS: 75,
    S: 17.85714285714286,
    M: 4.76190476190476,
    L: 2.38095238095238,
    XL: 0,
  },
  {
    name: 'Yorkipoo',
    weight: 20,
    total: 108,
    XS: 20.37037037037037,
    S: 65.74074074074073,
    M: 13.88888888888889,
    L: 0,
    XL: 0,
  },
  {
    name: 'Yorkshire Terrier',
    weight: 10,
    total: 808,
    XS: 57.54950495049505,
    S: 35.27227722772277,
    M: 6.43564356435644,
    L: 0.4950495049505,
    XL: 0.24752475247525,
  },
  {
    name: 'Yorkshire Terrier',
    weight: 20,
    total: 676,
    XS: 13.16568047337278,
    S: 68.04733727810651,
    M: 17.60355029585799,
    L: 1.18343195266272,
    XL: 0,
  },
  {
    name: 'Yorkshire Terrier',
    weight: 30,
    total: 70,
    XS: 0,
    S: 37.14285714285714,
    M: 61.42857142857143,
    L: 1.42857142857143,
    XL: 0,
  },
];

export const PuppySizingGuide: SizingGuideItem[] = [
  {
    name: 'Affen Terrier',
    weight: 10,
    total: 46,
    XS: 8.69565217391304,
    S: 17.39130434782609,
    M: 32.60869565217391,
    L: 36.95652173913043,
    XL: 4.34782608695652,
  },
  {
    name: 'Airedale Terrier',
    weight: 50,
    total: 55,
    XS: 0,
    S: 3.63636363636364,
    M: 45.45454545454545,
    L: 49.09090909090909,
    XL: 1.81818181818182,
  },
  {
    name: 'Airedale Terrier',
    weight: 60,
    total: 46,
    XS: 0,
    S: 2.17391304347826,
    M: 34.78260869565217,
    L: 63.04347826086957,
    XL: 0,
  },
  {
    name: 'Akita',
    weight: 50,
    total: 32,
    XS: 0,
    S: 6.25,
    M: 37.5,
    L: 56.25,
    XL: 0,
  },
  {
    name: 'Akita',
    weight: 60,
    total: 35,
    XS: 0,
    S: 0,
    M: 14.28571428571429,
    L: 77.14285714285714,
    XL: 8.57142857142857,
  },
  {
    name: 'Akita',
    weight: 70,
    total: 47,
    XS: 0,
    S: 0,
    M: 19.14893617021277,
    L: 59.57446808510638,
    XL: 21.27659574468085,
  },
  {
    name: 'Akita',
    weight: 80,
    total: 62,
    XS: 0,
    S: 1.61290322580645,
    M: 11.29032258064516,
    L: 62.90322580645161,
    XL: 24.19354838709677,
  },
  {
    name: 'Akita',
    weight: 90,
    total: 59,
    XS: 0,
    S: 3.38983050847458,
    M: 18.64406779661017,
    L: 40.67796610169492,
    XL: 37.28813559322034,
  },
  {
    name: 'Akita',
    weight: 100,
    total: 55,
    XS: 0,
    S: 1.81818181818182,
    M: 23.63636363636364,
    L: 34.54545454545455,
    XL: 40,
  },
  {
    name: 'Alaskan Husky',
    weight: 20,
    total: 34,
    XS: 2.94117647058824,
    S: 26.47058823529412,
    M: 47.05882352941176,
    L: 20.58823529411765,
    XL: 2.94117647058824,
  },
  {
    name: 'Alaskan Husky',
    weight: 30,
    total: 94,
    XS: 1.06382978723404,
    S: 14.8936170212766,
    M: 61.70212765957447,
    L: 22.34042553191489,
    XL: 0,
  },
  {
    name: 'Alaskan Husky',
    weight: 40,
    total: 173,
    XS: 0,
    S: 4.04624277456647,
    M: 53.17919075144509,
    L: 41.61849710982659,
    XL: 1.15606936416185,
  },
  {
    name: 'Alaskan Husky',
    weight: 50,
    total: 312,
    XS: 0.32051282051282,
    S: 3.84615384615385,
    M: 47.11538461538462,
    L: 47.43589743589744,
    XL: 1.28205128205128,
  },
  {
    name: 'Alaskan Husky',
    weight: 60,
    total: 219,
    XS: 0,
    S: 2.28310502283105,
    M: 31.05022831050228,
    L: 63.92694063926941,
    XL: 2.73972602739726,
  },
  {
    name: 'Alaskan Husky',
    weight: 70,
    total: 131,
    XS: 0,
    S: 0.76335877862595,
    M: 25.19083969465649,
    L: 67.93893129770991,
    XL: 6.10687022900763,
  },
  {
    name: 'Alaskan Husky',
    weight: 80,
    total: 64,
    XS: 0,
    S: 1.5625,
    M: 21.875,
    L: 71.875,
    XL: 4.6875,
  },
  {
    name: 'Alaskan Klee Kai',
    weight: 10,
    total: 46,
    XS: 32.60869565217391,
    S: 56.52173913043478,
    M: 10.8695652173913,
    L: 0,
    XL: 0,
  },
  {
    name: 'Alaskan Klee Kai',
    weight: 20,
    total: 135,
    XS: 11.11111111111111,
    S: 63.7037037037037,
    M: 25.18518518518519,
    L: 0,
    XL: 0,
  },
  {
    name: 'Alaskan Klee Kai',
    weight: 30,
    total: 40,
    XS: 0,
    S: 40,
    M: 57.5,
    L: 2.5,
    XL: 0,
  },
  {
    name: 'Alaskan Malamute',
    weight: 50,
    total: 57,
    XS: 0,
    S: 0,
    M: 28.07017543859649,
    L: 63.15789473684211,
    XL: 8.7719298245614,
  },
  {
    name: 'Alaskan Malamute',
    weight: 60,
    total: 54,
    XS: 0,
    S: 1.85185185185185,
    M: 25.92592592592593,
    L: 61.11111111111111,
    XL: 11.11111111111111,
  },
  {
    name: 'Alaskan Malamute',
    weight: 70,
    total: 89,
    XS: 0,
    S: 1.12359550561798,
    M: 21.34831460674157,
    L: 65.1685393258427,
    XL: 12.35955056179775,
  },
  {
    name: 'Alaskan Malamute',
    weight: 80,
    total: 87,
    XS: 0,
    S: 1.14942528735632,
    M: 21.83908045977011,
    L: 63.2183908045977,
    XL: 13.79310344827586,
  },
  {
    name: 'Alaskan Malamute',
    weight: 90,
    total: 66,
    XS: 0,
    S: 1.51515151515152,
    M: 22.72727272727273,
    L: 45.45454545454545,
    XL: 30.3030303030303,
  },
  {
    name: 'Alaskan Malamute',
    weight: 100,
    total: 58,
    XS: 0,
    S: 1.72413793103448,
    M: 18.96551724137931,
    L: 48.27586206896552,
    XL: 31.03448275862069,
  },
  {
    name: 'American Bulldog',
    weight: 50,
    total: 51,
    XS: 0,
    S: 9.80392156862745,
    M: 41.17647058823529,
    L: 47.05882352941176,
    XL: 1.96078431372549,
  },
  {
    name: 'American Bulldog',
    weight: 60,
    total: 51,
    XS: 0,
    S: 1.96078431372549,
    M: 23.52941176470588,
    L: 62.74509803921569,
    XL: 11.76470588235294,
  },
  {
    name: 'American Bulldog',
    weight: 70,
    total: 53,
    XS: 0,
    S: 1.88679245283019,
    M: 9.43396226415094,
    L: 73.58490566037736,
    XL: 15.09433962264151,
  },
  {
    name: 'American Bulldog',
    weight: 80,
    total: 42,
    XS: 0,
    S: 4.76190476190476,
    M: 19.04761904761905,
    L: 59.52380952380952,
    XL: 16.66666666666667,
  },
  {
    name: 'American Bulldog',
    weight: 90,
    total: 28,
    XS: 0,
    S: 0,
    M: 14.28571428571429,
    L: 50,
    XL: 35.71428571428571,
  },
  {
    name: 'American Bully',
    weight: 20,
    total: 37,
    XS: 8.10810810810811,
    S: 27.02702702702703,
    M: 40.54054054054054,
    L: 18.91891891891892,
    XL: 5.40540540540541,
  },
  {
    name: 'American Bully',
    weight: 30,
    total: 64,
    XS: 0,
    S: 7.8125,
    M: 59.375,
    L: 29.6875,
    XL: 3.125,
  },
  {
    name: 'American Bully',
    weight: 40,
    total: 96,
    XS: 0,
    S: 1.04166666666667,
    M: 51.04166666666667,
    L: 44.79166666666667,
    XL: 3.125,
  },
  {
    name: 'American Bully',
    weight: 50,
    total: 153,
    XS: 0,
    S: 0.65359477124183,
    M: 31.37254901960784,
    L: 62.09150326797386,
    XL: 5.88235294117647,
  },
  {
    name: 'American Bully',
    weight: 60,
    total: 160,
    XS: 0.625,
    S: 1.25,
    M: 22.5,
    L: 64.375,
    XL: 11.25,
  },
  {
    name: 'American Bully',
    weight: 70,
    total: 137,
    XS: 0,
    S: 0.72992700729927,
    M: 19.70802919708029,
    L: 65.69343065693431,
    XL: 13.86861313868613,
  },
  {
    name: 'American Bully',
    weight: 80,
    total: 66,
    XS: 0,
    S: 3.03030303030303,
    M: 12.12121212121212,
    L: 63.63636363636364,
    XL: 21.21212121212121,
  },
  {
    name: 'American Bully',
    weight: 90,
    total: 47,
    XS: 0,
    S: 0,
    M: 4.25531914893617,
    L: 38.29787234042553,
    XL: 57.4468085106383,
  },
  {
    name: 'American English Coonhound',
    weight: 50,
    total: 48,
    XS: 2.08333333333333,
    S: 0,
    M: 43.75,
    L: 54.16666666666667,
    XL: 0,
  },
  {
    name: 'American Eskimo Dog',
    weight: 20,
    total: 48,
    XS: 4.16666666666667,
    S: 52.08333333333333,
    M: 39.58333333333333,
    L: 4.16666666666667,
    XL: 0,
  },
  {
    name: 'American Eskimo Dog',
    weight: 30,
    total: 34,
    XS: 0,
    S: 32.35294117647059,
    M: 58.82352941176471,
    L: 5.88235294117647,
    XL: 2.94117647058824,
  },
  {
    name: 'American Foxhound',
    weight: 40,
    total: 50,
    XS: 0,
    S: 8,
    M: 62,
    L: 28,
    XL: 2,
  },
  {
    name: 'American Foxhound',
    weight: 50,
    total: 94,
    XS: 0,
    S: 4.25531914893617,
    M: 68.08510638297872,
    L: 27.65957446808511,
    XL: 0,
  },
  {
    name: 'American Foxhound',
    weight: 60,
    total: 61,
    XS: 0,
    S: 0,
    M: 50.81967213114754,
    L: 49.18032786885246,
    XL: 0,
  },
  {
    name: 'American Foxhound',
    weight: 70,
    total: 36,
    XS: 0,
    S: 2.77777777777778,
    M: 41.66666666666667,
    L: 55.55555555555556,
    XL: 0,
  },
  {
    name: 'American French Bulldog',
    weight: 10,
    total: 27,
    XS: 7.40740740740741,
    S: 74.07407407407408,
    M: 7.40740740740741,
    L: 11.11111111111111,
    XL: 0,
  },
  {
    name: 'American French Bulldog',
    weight: 20,
    total: 88,
    XS: 2.27272727272727,
    S: 50,
    M: 43.18181818181818,
    L: 4.54545454545455,
    XL: 0,
  },
  {
    name: 'American French Bulldog',
    weight: 30,
    total: 124,
    XS: 0,
    S: 30.64516129032258,
    M: 52.41935483870968,
    L: 16.12903225806452,
    XL: 0.80645161290323,
  },
  {
    name: 'American French Bulldog',
    weight: 40,
    total: 43,
    XS: 0,
    S: 11.62790697674419,
    M: 60.46511627906977,
    L: 27.90697674418605,
    XL: 0,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 20,
    total: 113,
    XS: 1.76991150442478,
    S: 24.7787610619469,
    M: 50.44247787610619,
    L: 20.35398230088496,
    XL: 2.65486725663717,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 30,
    total: 249,
    XS: 0,
    S: 16.06425702811245,
    M: 59.03614457831325,
    L: 24.8995983935743,
    XL: 0,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 40,
    total: 540,
    XS: 0.92592592592593,
    S: 3.7037037037037,
    M: 61.48148148148148,
    L: 33.14814814814815,
    XL: 0.74074074074074,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 50,
    total: 906,
    XS: 0.33112582781457,
    S: 1.76600441501104,
    M: 45.80573951434879,
    L: 50.88300220750552,
    XL: 1.21412803532009,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 60,
    total: 844,
    XS: 0,
    S: 0.9478672985782,
    M: 31.39810426540284,
    L: 65.75829383886256,
    XL: 1.8957345971564,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 70,
    total: 589,
    XS: 0,
    S: 1.01867572156197,
    M: 23.2597623089983,
    L: 70.45840407470288,
    XL: 5.26315789473684,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 80,
    total: 273,
    XS: 0,
    S: 0.73260073260073,
    M: 20.14652014652015,
    L: 72.16117216117216,
    XL: 6.95970695970696,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 90,
    total: 103,
    XS: 0,
    S: 0.97087378640777,
    M: 27.18446601941748,
    L: 58.25242718446602,
    XL: 13.59223300970874,
  },
  {
    name: 'American Pit Bull Terrier',
    weight: 100,
    total: 37,
    XS: 0,
    S: 0,
    M: 8.10810810810811,
    L: 51.35135135135135,
    XL: 40.54054054054054,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 20,
    total: 27,
    XS: 3.7037037037037,
    S: 25.92592592592593,
    M: 59.25925925925926,
    L: 11.11111111111111,
    XL: 0,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 30,
    total: 85,
    XS: 1.17647058823529,
    S: 12.94117647058824,
    M: 64.70588235294117,
    L: 21.17647058823529,
    XL: 0,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 40,
    total: 200,
    XS: 0,
    S: 6,
    M: 64,
    L: 29.5,
    XL: 0.5,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 50,
    total: 357,
    XS: 0,
    S: 1.40056022408964,
    M: 41.73669467787115,
    L: 56.02240896358543,
    XL: 0.84033613445378,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 60,
    total: 346,
    XS: 0,
    S: 2.60115606936416,
    M: 27.74566473988439,
    L: 68.78612716763006,
    XL: 0.86705202312139,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 70,
    total: 213,
    XS: 0,
    S: 0.46948356807512,
    M: 22.06572769953052,
    L: 71.83098591549296,
    XL: 5.63380281690141,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 80,
    total: 91,
    XS: 1.0989010989011,
    S: 0,
    M: 21.97802197802198,
    L: 69.23076923076923,
    XL: 7.69230769230769,
  },
  {
    name: 'American Staffordshire Terrier',
    weight: 90,
    total: 29,
    XS: 0,
    S: 0,
    M: 17.24137931034483,
    L: 68.96551724137932,
    XL: 13.79310344827586,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 70,
    total: 31,
    XS: 0,
    S: 0,
    M: 12.90322580645161,
    L: 58.06451612903226,
    XL: 29.03225806451613,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 80,
    total: 31,
    XS: 0,
    S: 0,
    M: 22.58064516129032,
    L: 38.70967741935484,
    XL: 38.70967741935484,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 90,
    total: 41,
    XS: 0,
    S: 0,
    M: 17.07317073170732,
    L: 34.14634146341463,
    XL: 48.78048780487805,
  },
  {
    name: 'Anatolian Pyrenees',
    weight: 100,
    total: 38,
    XS: 0,
    S: 2.63157894736842,
    M: 21.05263157894737,
    L: 34.21052631578947,
    XL: 42.10526315789474,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 70,
    total: 29,
    XS: 0,
    S: 6.89655172413793,
    M: 20.68965517241379,
    L: 62.06896551724138,
    XL: 10.3448275862069,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 80,
    total: 30,
    XS: 3.33333333333333,
    S: 0,
    M: 6.66666666666667,
    L: 63.33333333333333,
    XL: 26.66666666666667,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 90,
    total: 34,
    XS: 0,
    S: 0,
    M: 23.52941176470588,
    L: 41.17647058823529,
    XL: 35.29411764705882,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 100,
    total: 39,
    XS: 0,
    S: 5.12820512820513,
    M: 10.25641025641026,
    L: 41.02564102564103,
    XL: 43.58974358974359,
  },
  {
    name: 'Anatolian Shepherd',
    weight: 120,
    total: 28,
    XS: 0,
    S: 0,
    M: 21.42857142857143,
    L: 10.71428571428571,
    XL: 67.85714285714286,
  },
  {
    name: 'Aussie-Corgi',
    weight: 20,
    total: 41,
    XS: 4.8780487804878,
    S: 39.02439024390244,
    M: 53.65853658536585,
    L: 2.4390243902439,
    XL: 0,
  },
  {
    name: 'Aussie-Corgi',
    weight: 30,
    total: 40,
    XS: 0,
    S: 22.5,
    M: 70,
    L: 7.5,
    XL: 0,
  },
  {
    name: 'Aussiedoodle',
    weight: 10,
    total: 65,
    XS: 26.15384615384615,
    S: 52.30769230769231,
    M: 16.92307692307692,
    L: 4.61538461538462,
    XL: 0,
  },
  {
    name: 'Aussiedoodle',
    weight: 20,
    total: 268,
    XS: 7.46268656716418,
    S: 60.07462686567164,
    M: 29.85074626865672,
    L: 2.61194029850746,
    XL: 0,
  },
  {
    name: 'Aussiedoodle',
    weight: 30,
    total: 275,
    XS: 1.81818181818182,
    S: 30.18181818181818,
    M: 62.18181818181818,
    L: 5.45454545454545,
    XL: 0.36363636363636,
  },
  {
    name: 'Aussiedoodle',
    weight: 40,
    total: 250,
    XS: 1.2,
    S: 7.2,
    M: 78.8,
    L: 12.8,
    XL: 0,
  },
  {
    name: 'Aussiedoodle',
    weight: 50,
    total: 250,
    XS: 0,
    S: 4.8,
    M: 61.2,
    L: 33.6,
    XL: 0.4,
  },
  {
    name: 'Aussiedoodle',
    weight: 60,
    total: 145,
    XS: 0,
    S: 2.06896551724138,
    M: 51.72413793103448,
    L: 46.20689655172414,
    XL: 0,
  },
  {
    name: 'Aussiedoodle',
    weight: 70,
    total: 61,
    XS: 0,
    S: 3.27868852459016,
    M: 44.26229508196721,
    L: 49.18032786885246,
    XL: 3.27868852459016,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 10,
    total: 55,
    XS: 14.54545454545455,
    S: 43.63636363636364,
    M: 38.18181818181818,
    L: 3.63636363636364,
    XL: 0,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 20,
    total: 261,
    XS: 3.83141762452107,
    S: 35.24904214559387,
    M: 54.40613026819923,
    L: 5.74712643678161,
    XL: 0.76628352490421,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 30,
    total: 651,
    XS: 0.61443932411674,
    S: 14.59293394777266,
    M: 74.19354838709677,
    L: 10.13824884792627,
    XL: 0.46082949308756,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 40,
    total: 1132,
    XS: 0.26501766784452,
    S: 5.21201413427562,
    M: 78.886925795053,
    L: 15.19434628975265,
    XL: 0.4416961130742,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 50,
    total: 964,
    XS: 0.20746887966805,
    S: 3.52697095435685,
    M: 63.90041493775934,
    L: 31.84647302904564,
    XL: 0.51867219917012,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 60,
    total: 398,
    XS: 0.50251256281407,
    S: 2.26130653266332,
    M: 49.24623115577889,
    L: 46.73366834170854,
    XL: 1.25628140703518,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 70,
    total: 121,
    XS: 0,
    S: 1.65289256198347,
    M: 34.71074380165289,
    L: 60.33057851239669,
    XL: 3.30578512396694,
  },
  {
    name: 'Australian Cattle Dog',
    weight: 80,
    total: 53,
    XS: 0,
    S: 0,
    M: 26.41509433962264,
    L: 69.81132075471697,
    XL: 3.77358490566038,
  },
  {
    name: 'Australian Kelpie',
    weight: 30,
    total: 30,
    XS: 0,
    S: 26.66666666666667,
    M: 56.66666666666667,
    L: 16.66666666666667,
    XL: 0,
  },
  {
    name: 'Australian Kelpie',
    weight: 40,
    total: 43,
    XS: 0,
    S: 2.32558139534884,
    M: 76.74418604651163,
    L: 20.93023255813953,
    XL: 0,
  },
  {
    name: 'Australian Kelpie',
    weight: 50,
    total: 38,
    XS: 0,
    S: 2.63157894736842,
    M: 73.6842105263158,
    L: 23.68421052631579,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 10,
    total: 41,
    XS: 12.19512195121951,
    S: 46.34146341463415,
    M: 39.02439024390244,
    L: 2.4390243902439,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 20,
    total: 205,
    XS: 2.92682926829268,
    S: 56.09756097560976,
    M: 38.04878048780488,
    L: 2.92682926829268,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 30,
    total: 399,
    XS: 1.2531328320802,
    S: 28.57142857142857,
    M: 66.1654135338346,
    L: 3.50877192982456,
    XL: 0.50125313283208,
  },
  {
    name: 'Australian Labradoodle',
    weight: 40,
    total: 249,
    XS: 0,
    S: 6.42570281124498,
    M: 82.32931726907631,
    L: 11.24497991967871,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 50,
    total: 107,
    XS: 0,
    S: 5.60747663551402,
    M: 59.81308411214953,
    L: 34.57943925233645,
    XL: 0,
  },
  {
    name: 'Australian Labradoodle',
    weight: 60,
    total: 40,
    XS: 2.5,
    S: 5,
    M: 42.5,
    L: 50,
    XL: 0,
  },
  {
    name: 'Australian Shepherd',
    weight: 10,
    total: 140,
    XS: 12.14285714285714,
    S: 51.42857142857143,
    M: 31.42857142857143,
    L: 5,
    XL: 0,
  },
  {
    name: 'Australian Shepherd',
    weight: 20,
    total: 575,
    XS: 3.82608695652174,
    S: 42.95652173913043,
    M: 45.73913043478261,
    L: 7.1304347826087,
    XL: 0.34782608695652,
  },
  {
    name: 'Australian Shepherd',
    weight: 30,
    total: 934,
    XS: 0.85653104925054,
    S: 21.09207708779443,
    M: 68.95074946466809,
    L: 8.8865096359743,
    XL: 0.21413276231263,
  },
  {
    name: 'Australian Shepherd',
    weight: 40,
    total: 1445,
    XS: 0,
    S: 7.47404844290657,
    M: 79.72318339100346,
    L: 12.17993079584775,
    XL: 0.62283737024221,
  },
  {
    name: 'Australian Shepherd',
    weight: 50,
    total: 1789,
    XS: 0.27948574622694,
    S: 3.18613750698714,
    M: 72.94577976523198,
    L: 23.19731693683622,
    XL: 0.39128004471772,
  },
  {
    name: 'Australian Shepherd',
    weight: 60,
    total: 929,
    XS: 0.10764262648009,
    S: 2.58342303552207,
    M: 54.14424111948332,
    L: 42.51883745963402,
    XL: 0.64585575888052,
  },
  {
    name: 'Australian Shepherd',
    weight: 70,
    total: 317,
    XS: 0,
    S: 1.57728706624606,
    M: 48.58044164037855,
    L: 47.63406940063091,
    XL: 2.20820189274448,
  },
  {
    name: 'Australian Shepherd',
    weight: 80,
    total: 88,
    XS: 0,
    S: 0,
    M: 37.5,
    L: 56.81818181818182,
    XL: 5.68181818181818,
  },
  {
    name: 'Basenji',
    weight: 20,
    total: 68,
    XS: 4.41176470588235,
    S: 61.76470588235294,
    M: 29.41176470588235,
    L: 4.41176470588235,
    XL: 0,
  },
  {
    name: 'Basenji',
    weight: 30,
    total: 84,
    XS: 1.19047619047619,
    S: 36.9047619047619,
    M: 60.71428571428571,
    L: 1.19047619047619,
    XL: 0,
  },
  {
    name: 'Basset Hound',
    weight: 20,
    total: 26,
    XS: 0,
    S: 23.07692307692308,
    M: 61.53846153846154,
    L: 11.53846153846154,
    XL: 3.84615384615385,
  },
  {
    name: 'Basset Hound',
    weight: 30,
    total: 52,
    XS: 0,
    S: 13.46153846153846,
    M: 55.76923076923077,
    L: 30.76923076923077,
    XL: 0,
  },
  {
    name: 'Basset Hound',
    weight: 40,
    total: 102,
    XS: 0,
    S: 3.92156862745098,
    M: 59.80392156862745,
    L: 34.31372549019608,
    XL: 1.96078431372549,
  },
  {
    name: 'Basset Hound',
    weight: 50,
    total: 142,
    XS: 0,
    S: 2.8169014084507,
    M: 37.32394366197183,
    L: 55.63380281690141,
    XL: 4.22535211267606,
  },
  {
    name: 'Basset Hound',
    weight: 60,
    total: 76,
    XS: 0,
    S: 2.63157894736842,
    M: 28.94736842105263,
    L: 61.84210526315789,
    XL: 6.57894736842105,
  },
  {
    name: 'Beagle',
    weight: 10,
    total: 63,
    XS: 15.87301587301587,
    S: 36.50793650793651,
    M: 41.26984126984127,
    L: 6.34920634920635,
    XL: 0,
  },
  {
    name: 'Beagle',
    weight: 20,
    total: 682,
    XS: 3.51906158357771,
    S: 40.02932551319648,
    M: 50.87976539589443,
    L: 4.98533724340176,
    XL: 0.58651026392962,
  },
  {
    name: 'Beagle',
    weight: 30,
    total: 1137,
    XS: 0.52770448548813,
    S: 14.77572559366755,
    M: 78.80386983289358,
    L: 5.54089709762533,
    XL: 0.35180299032542,
  },
  {
    name: 'Beagle',
    weight: 40,
    total: 450,
    XS: 0.22222222222222,
    S: 4.22222222222222,
    M: 84.22222222222221,
    L: 10.88888888888889,
    XL: 0.44444444444444,
  },
  {
    name: 'Beagle',
    weight: 50,
    total: 142,
    XS: 0,
    S: 6.33802816901408,
    M: 66.19718309859155,
    L: 26.05633802816901,
    XL: 1.40845070422535,
  },
  {
    name: 'Beagle',
    weight: 60,
    total: 45,
    XS: 0,
    S: 0,
    M: 46.66666666666667,
    L: 53.33333333333333,
    XL: 0,
  },
  {
    name: 'Belgian Malinois',
    weight: 10,
    total: 42,
    XS: 7.14285714285714,
    S: 21.42857142857143,
    M: 47.61904761904762,
    L: 21.42857142857143,
    XL: 2.38095238095238,
  },
  {
    name: 'Belgian Malinois',
    weight: 20,
    total: 112,
    XS: 0,
    S: 24.10714285714286,
    M: 43.75,
    L: 30.35714285714286,
    XL: 1.78571428571429,
  },
  {
    name: 'Belgian Malinois',
    weight: 30,
    total: 188,
    XS: 0,
    S: 17.5531914893617,
    M: 45.74468085106383,
    L: 35.1063829787234,
    XL: 1.59574468085106,
  },
  {
    name: 'Belgian Malinois',
    weight: 40,
    total: 288,
    XS: 1.38888888888889,
    S: 10.76388888888889,
    M: 51.38888888888889,
    L: 33.68055555555556,
    XL: 2.77777777777778,
  },
  {
    name: 'Belgian Malinois',
    weight: 50,
    total: 584,
    XS: 0.17123287671233,
    S: 7.87671232876712,
    M: 51.54109589041096,
    L: 38.52739726027397,
    XL: 1.88356164383562,
  },
  {
    name: 'Belgian Malinois',
    weight: 60,
    total: 716,
    XS: 0,
    S: 5.72625698324022,
    M: 37.84916201117318,
    L: 55.02793296089385,
    XL: 1.39664804469274,
  },
  {
    name: 'Belgian Malinois',
    weight: 70,
    total: 593,
    XS: 0.50590219224283,
    S: 3.87858347386172,
    M: 32.54637436762226,
    L: 61.04553119730185,
    XL: 2.02360876897133,
  },
  {
    name: 'Belgian Malinois',
    weight: 80,
    total: 289,
    XS: 0,
    S: 4.84429065743945,
    M: 24.22145328719723,
    L: 66.08996539792388,
    XL: 4.84429065743945,
  },
  {
    name: 'Belgian Malinois',
    weight: 90,
    total: 85,
    XS: 0,
    S: 3.52941176470588,
    M: 29.41176470588235,
    L: 62.35294117647059,
    XL: 4.70588235294118,
  },
  {
    name: 'Belgian Malinois',
    weight: 100,
    total: 37,
    XS: 0,
    S: 2.7027027027027,
    M: 27.02702702702703,
    L: 59.45945945945946,
    XL: 10.81081081081081,
  },
  {
    name: 'Belgian Tervuren',
    weight: 50,
    total: 31,
    XS: 0,
    S: 0,
    M: 61.29032258064516,
    L: 38.70967741935484,
    XL: 0,
  },
  {
    name: 'Bernedoodle',
    weight: 10,
    total: 62,
    XS: 6.45161290322581,
    S: 46.7741935483871,
    M: 40.32258064516129,
    L: 6.45161290322581,
    XL: 0,
  },
  {
    name: 'Bernedoodle',
    weight: 20,
    total: 259,
    XS: 5.01930501930502,
    S: 57.14285714285714,
    M: 33.97683397683398,
    L: 3.08880308880309,
    XL: 0.77220077220077,
  },
  {
    name: 'Bernedoodle',
    weight: 30,
    total: 434,
    XS: 1.61290322580645,
    S: 24.65437788018433,
    M: 65.89861751152074,
    L: 7.83410138248848,
    XL: 0,
  },
  {
    name: 'Bernedoodle',
    weight: 40,
    total: 484,
    XS: 0.41322314049587,
    S: 13.4297520661157,
    M: 71.28099173553719,
    L: 14.46280991735537,
    XL: 0.41322314049587,
  },
  {
    name: 'Bernedoodle',
    weight: 50,
    total: 324,
    XS: 0.30864197530864,
    S: 6.48148148148148,
    M: 57.71604938271605,
    L: 34.5679012345679,
    XL: 0.92592592592593,
  },
  {
    name: 'Bernedoodle',
    weight: 60,
    total: 270,
    XS: 0,
    S: 6.2962962962963,
    M: 38.14814814814815,
    L: 54.07407407407407,
    XL: 1.48148148148148,
  },
  {
    name: 'Bernedoodle',
    weight: 70,
    total: 269,
    XS: 0,
    S: 3.3457249070632,
    M: 28.62453531598513,
    L: 65.0557620817844,
    XL: 2.97397769516729,
  },
  {
    name: 'Bernedoodle',
    weight: 80,
    total: 186,
    XS: 0,
    S: 3.2258064516129,
    M: 18.27956989247312,
    L: 72.58064516129032,
    XL: 5.91397849462366,
  },
  {
    name: 'Bernedoodle',
    weight: 90,
    total: 95,
    XS: 0,
    S: 3.15789473684211,
    M: 15.78947368421053,
    L: 67.36842105263158,
    XL: 13.68421052631579,
  },
  {
    name: 'Bernedoodle',
    weight: 100,
    total: 51,
    XS: 0,
    S: 3.92156862745098,
    M: 29.41176470588235,
    L: 56.86274509803922,
    XL: 9.80392156862745,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 20,
    total: 35,
    XS: 5.71428571428571,
    S: 25.71428571428571,
    M: 34.28571428571429,
    L: 22.85714285714286,
    XL: 11.42857142857143,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 30,
    total: 65,
    XS: 1.53846153846154,
    S: 23.07692307692308,
    M: 49.23076923076923,
    L: 21.53846153846154,
    XL: 4.61538461538462,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 40,
    total: 49,
    XS: 0,
    S: 8.16326530612245,
    M: 59.18367346938776,
    L: 28.57142857142857,
    XL: 4.08163265306122,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 50,
    total: 86,
    XS: 1.16279069767442,
    S: 3.48837209302326,
    M: 38.37209302325581,
    L: 50,
    XL: 6.97674418604651,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 60,
    total: 93,
    XS: 1.0752688172043,
    S: 2.1505376344086,
    M: 22.58064516129032,
    L: 60.21505376344086,
    XL: 13.97849462365591,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 70,
    total: 120,
    XS: 0,
    S: 3.33333333333333,
    M: 21.66666666666667,
    L: 54.16666666666667,
    XL: 20.83333333333333,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 80,
    total: 172,
    XS: 0,
    S: 1.74418604651163,
    M: 16.27906976744186,
    L: 56.3953488372093,
    XL: 25.58139534883721,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 90,
    total: 166,
    XS: 0.60240963855422,
    S: 1.20481927710843,
    M: 18.07228915662651,
    L: 48.19277108433735,
    XL: 31.92771084337349,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 100,
    total: 138,
    XS: 0,
    S: 1.44927536231884,
    M: 16.66666666666667,
    L: 27.53623188405797,
    XL: 54.34782608695652,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 110,
    total: 75,
    XS: 0,
    S: 0,
    M: 21.33333333333333,
    L: 20,
    XL: 58.66666666666667,
  },
  {
    name: 'Bernese Mountain Dog',
    weight: 120,
    total: 42,
    XS: 0,
    S: 0,
    M: 16.66666666666667,
    L: 16.66666666666667,
    XL: 66.66666666666667,
  },
  {
    name: 'Bichon Frise',
    weight: 10,
    total: 27,
    XS: 40.74074074074074,
    S: 48.14814814814815,
    M: 7.40740740740741,
    L: 3.7037037037037,
    XL: 0,
  },
  {
    name: 'Bichon Frise',
    weight: 20,
    total: 89,
    XS: 7.86516853932584,
    S: 71.91011235955057,
    M: 17.97752808988764,
    L: 2.24719101123596,
    XL: 0,
  },
  {
    name: 'Black Mouth Cur',
    weight: 40,
    total: 74,
    XS: 0,
    S: 2.7027027027027,
    M: 72.97297297297297,
    L: 24.32432432432432,
    XL: 0,
  },
  {
    name: 'Black Mouth Cur',
    weight: 50,
    total: 110,
    XS: 0,
    S: 0,
    M: 60,
    L: 39.09090909090909,
    XL: 0.90909090909091,
  },
  {
    name: 'Black Mouth Cur',
    weight: 60,
    total: 83,
    XS: 0,
    S: 0,
    M: 34.93975903614458,
    L: 65.06024096385542,
    XL: 0,
  },
  {
    name: 'Black Mouth Cur',
    weight: 70,
    total: 61,
    XS: 0,
    S: 0,
    M: 27.86885245901639,
    L: 68.85245901639344,
    XL: 3.27868852459016,
  },
  {
    name: 'Black and Tan Coonhound',
    weight: 50,
    total: 42,
    XS: 0,
    S: 0,
    M: 52.38095238095238,
    L: 45.23809523809524,
    XL: 2.38095238095238,
  },
  {
    name: 'Black and Tan Coonhound',
    weight: 60,
    total: 41,
    XS: 0,
    S: 0,
    M: 39.02439024390244,
    L: 56.09756097560976,
    XL: 4.8780487804878,
  },
  {
    name: 'Black and Tan Coonhound',
    weight: 70,
    total: 37,
    XS: 0,
    S: 0,
    M: 27.02702702702703,
    L: 70.27027027027027,
    XL: 2.7027027027027,
  },
  {
    name: 'Bloodhound',
    weight: 60,
    total: 63,
    XS: 0,
    S: 0,
    M: 25.3968253968254,
    L: 60.31746031746032,
    XL: 14.28571428571429,
  },
  {
    name: 'Bloodhound',
    weight: 70,
    total: 68,
    XS: 0,
    S: 1.47058823529412,
    M: 26.47058823529412,
    L: 48.52941176470588,
    XL: 23.52941176470588,
  },
  {
    name: 'Bloodhound',
    weight: 80,
    total: 71,
    XS: 0,
    S: 0,
    M: 14.08450704225352,
    L: 63.38028169014085,
    XL: 22.53521126760563,
  },
  {
    name: 'Bloodhound',
    weight: 90,
    total: 58,
    XS: 0,
    S: 0,
    M: 15.51724137931034,
    L: 50,
    XL: 34.48275862068966,
  },
  {
    name: 'Bloodhound',
    weight: 100,
    total: 59,
    XS: 0,
    S: 0,
    M: 13.55932203389831,
    L: 33.89830508474576,
    XL: 52.54237288135593,
  },
  {
    name: 'Bloodhound',
    weight: 110,
    total: 35,
    XS: 0,
    S: 0,
    M: 11.42857142857143,
    L: 20,
    XL: 68.57142857142857,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 40,
    total: 42,
    XS: 0,
    S: 7.14285714285714,
    M: 69.04761904761905,
    L: 23.80952380952381,
    XL: 0,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 50,
    total: 96,
    XS: 0,
    S: 5.20833333333333,
    M: 41.66666666666667,
    L: 53.125,
    XL: 0,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 60,
    total: 62,
    XS: 0,
    S: 1.61290322580645,
    M: 38.70967741935484,
    L: 59.67741935483871,
    XL: 0,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 70,
    total: 62,
    XS: 0,
    S: 1.61290322580645,
    M: 33.87096774193548,
    L: 64.51612903225806,
    XL: 0,
  },
  {
    name: 'Bluetick Coonhound',
    weight: 80,
    total: 40,
    XS: 0,
    S: 7.5,
    M: 35,
    L: 55,
    XL: 2.5,
  },
  {
    name: 'Border Collie',
    weight: 10,
    total: 40,
    XS: 15,
    S: 20,
    M: 60,
    L: 5,
    XL: 0,
  },
  {
    name: 'Border Collie',
    weight: 20,
    total: 254,
    XS: 1.96850393700787,
    S: 26.37795275590551,
    M: 64.1732283464567,
    L: 7.48031496062992,
    XL: 0,
  },
  {
    name: 'Border Collie',
    weight: 30,
    total: 675,
    XS: 1.03703703703704,
    S: 13.77777777777778,
    M: 78.51851851851852,
    L: 6.37037037037037,
    XL: 0.2962962962963,
  },
  {
    name: 'Border Collie',
    weight: 40,
    total: 1222,
    XS: 0.16366612111293,
    S: 4.90998363338789,
    M: 83.30605564648118,
    L: 11.12929623567921,
    XL: 0.49099836333879,
  },
  {
    name: 'Border Collie',
    weight: 50,
    total: 889,
    XS: 0,
    S: 3.1496062992126,
    M: 75.14060742407199,
    L: 21.25984251968504,
    XL: 0.44994375703037,
  },
  {
    name: 'Border Collie',
    weight: 60,
    total: 314,
    XS: 0,
    S: 1.91082802547771,
    M: 52.54777070063694,
    L: 44.5859872611465,
    XL: 0.95541401273885,
  },
  {
    name: 'Border Collie',
    weight: 70,
    total: 119,
    XS: 0,
    S: 0,
    M: 36.97478991596639,
    L: 60.50420168067227,
    XL: 2.52100840336134,
  },
  {
    name: 'Border Collie',
    weight: 80,
    total: 26,
    XS: 0,
    S: 3.84615384615385,
    M: 34.61538461538462,
    L: 57.69230769230769,
    XL: 3.84615384615385,
  },
  {
    name: 'Border Terrier',
    weight: 20,
    total: 80,
    XS: 7.5,
    S: 61.25,
    M: 30,
    L: 1.25,
    XL: 0,
  },
  {
    name: 'Border Terrier',
    weight: 30,
    total: 29,
    XS: 0,
    S: 41.37931034482759,
    M: 58.62068965517241,
    L: 0,
    XL: 0,
  },
  {
    name: 'Border-Aussie',
    weight: 40,
    total: 34,
    XS: 0,
    S: 5.88235294117647,
    M: 73.52941176470588,
    L: 20.58823529411765,
    XL: 0,
  },
  {
    name: 'Border-Aussie',
    weight: 50,
    total: 40,
    XS: 0,
    S: 0,
    M: 72.5,
    L: 27.5,
    XL: 0,
  },
  {
    name: 'Bordoodle',
    weight: 40,
    total: 28,
    XS: 0,
    S: 3.57142857142857,
    M: 82.14285714285714,
    L: 10.71428571428571,
    XL: 3.57142857142857,
  },
  {
    name: 'Boston Terrier',
    weight: 10,
    total: 68,
    XS: 13.23529411764706,
    S: 69.11764705882354,
    M: 16.17647058823529,
    L: 1.47058823529412,
    XL: 0,
  },
  {
    name: 'Boston Terrier',
    weight: 20,
    total: 375,
    XS: 3.2,
    S: 65.06666666666668,
    M: 30.13333333333333,
    L: 1.33333333333333,
    XL: 0.26666666666667,
  },
  {
    name: 'Boston Terrier',
    weight: 30,
    total: 218,
    XS: 0.45871559633028,
    S: 43.11926605504587,
    M: 50.45871559633028,
    L: 5.96330275229358,
    XL: 0,
  },
  {
    name: 'Boston Terrier',
    weight: 40,
    total: 36,
    XS: 0,
    S: 13.88888888888889,
    M: 66.66666666666667,
    L: 19.44444444444444,
    XL: 0,
  },
  {
    name: 'Boxer',
    weight: 10,
    total: 35,
    XS: 8.57142857142857,
    S: 51.42857142857143,
    M: 22.85714285714286,
    L: 14.28571428571429,
    XL: 2.85714285714286,
  },
  {
    name: 'Boxer',
    weight: 20,
    total: 70,
    XS: 1.42857142857143,
    S: 41.42857142857143,
    M: 32.85714285714286,
    L: 24.28571428571429,
    XL: 0,
  },
  {
    name: 'Boxer',
    weight: 30,
    total: 152,
    XS: 1.97368421052632,
    S: 10.52631578947368,
    M: 50,
    L: 36.84210526315789,
    XL: 0.65789473684211,
  },
  {
    name: 'Boxer',
    weight: 40,
    total: 244,
    XS: 0,
    S: 7.37704918032787,
    M: 55.32786885245902,
    L: 36.88524590163934,
    XL: 0.40983606557377,
  },
  {
    name: 'Boxer',
    weight: 50,
    total: 507,
    XS: 0,
    S: 2.76134122287968,
    M: 38.26429980276134,
    L: 57.79092702169625,
    XL: 1.18343195266272,
  },
  {
    name: 'Boxer',
    weight: 60,
    total: 491,
    XS: 0.40733197556008,
    S: 2.44399185336049,
    M: 31.9755600814664,
    L: 63.34012219959267,
    XL: 1.83299389002037,
  },
  {
    name: 'Boxer',
    weight: 70,
    total: 351,
    XS: 0.28490028490028,
    S: 1.70940170940171,
    M: 25.64102564102564,
    L: 68.37606837606837,
    XL: 3.98860398860399,
  },
  {
    name: 'Boxer',
    weight: 80,
    total: 138,
    XS: 0,
    S: 1.44927536231884,
    M: 23.18840579710145,
    L: 72.46376811594203,
    XL: 2.89855072463768,
  },
  {
    name: 'Boxer',
    weight: 90,
    total: 41,
    XS: 0,
    S: 2.4390243902439,
    M: 19.51219512195122,
    L: 63.41463414634146,
    XL: 14.63414634146341,
  },
  {
    name: 'Boykin Spaniel',
    weight: 20,
    total: 36,
    XS: 2.77777777777778,
    S: 36.11111111111111,
    M: 55.55555555555556,
    L: 2.77777777777778,
    XL: 2.77777777777778,
  },
  {
    name: 'Boykin Spaniel',
    weight: 30,
    total: 86,
    XS: 1.16279069767442,
    S: 16.27906976744186,
    M: 77.90697674418605,
    L: 4.65116279069767,
    XL: 0,
  },
  {
    name: 'Boykin Spaniel',
    weight: 40,
    total: 108,
    XS: 0.92592592592593,
    S: 5.55555555555556,
    M: 83.33333333333333,
    L: 10.18518518518519,
    XL: 0,
  },
  {
    name: 'Boykin Spaniel',
    weight: 50,
    total: 36,
    XS: 0,
    S: 8.33333333333333,
    M: 63.88888888888889,
    L: 27.77777777777778,
    XL: 0,
  },
  {
    name: 'Brittany',
    weight: 20,
    total: 62,
    XS: 4.83870967741935,
    S: 32.25806451612903,
    M: 56.45161290322581,
    L: 4.83870967741935,
    XL: 1.61290322580645,
  },
  {
    name: 'Brittany',
    weight: 30,
    total: 234,
    XS: 0.85470085470085,
    S: 20.51282051282051,
    M: 76.4957264957265,
    L: 1.70940170940171,
    XL: 0.42735042735043,
  },
  {
    name: 'Brittany',
    weight: 40,
    total: 296,
    XS: 0,
    S: 8.10810810810811,
    M: 86.14864864864865,
    L: 5.74324324324324,
    XL: 0,
  },
  {
    name: 'Brittany',
    weight: 50,
    total: 120,
    XS: 0,
    S: 2.5,
    M: 85,
    L: 12.5,
    XL: 0,
  },
  {
    name: 'Brittany',
    weight: 60,
    total: 26,
    XS: 0,
    S: 0,
    M: 61.53846153846154,
    L: 38.46153846153846,
    XL: 0,
  },
  {
    name: 'Bull Terrier',
    weight: 40,
    total: 31,
    XS: 0,
    S: 3.2258064516129,
    M: 64.51612903225806,
    L: 32.25806451612903,
    XL: 0,
  },
  {
    name: 'Bull Terrier',
    weight: 50,
    total: 49,
    XS: 0,
    S: 4.08163265306122,
    M: 46.93877551020408,
    L: 46.93877551020408,
    XL: 2.04081632653061,
  },
  {
    name: 'Bull Terrier',
    weight: 60,
    total: 39,
    XS: 0,
    S: 0,
    M: 46.15384615384615,
    L: 53.84615384615385,
    XL: 0,
  },
  {
    name: 'Bullboxer Pit',
    weight: 50,
    total: 29,
    XS: 0,
    S: 6.89655172413793,
    M: 37.93103448275862,
    L: 55.17241379310345,
    XL: 0,
  },
  {
    name: 'Bullboxer Pit',
    weight: 60,
    total: 27,
    XS: 0,
    S: 0,
    M: 22.22222222222222,
    L: 74.07407407407408,
    XL: 3.7037037037037,
  },
  {
    name: 'Bulldog',
    weight: 20,
    total: 37,
    XS: 5.40540540540541,
    S: 40.54054054054054,
    M: 48.64864864864865,
    L: 2.7027027027027,
    XL: 2.7027027027027,
  },
  {
    name: 'Bulldog',
    weight: 30,
    total: 74,
    XS: 0,
    S: 17.56756756756757,
    M: 56.75675675675676,
    L: 25.67567567567568,
    XL: 0,
  },
  {
    name: 'Bulldog',
    weight: 40,
    total: 64,
    XS: 0,
    S: 1.5625,
    M: 60.9375,
    L: 32.8125,
    XL: 4.6875,
  },
  {
    name: 'Bulldog',
    weight: 50,
    total: 89,
    XS: 0,
    S: 5.61797752808989,
    M: 35.95505617977528,
    L: 50.56179775280899,
    XL: 7.86516853932584,
  },
  {
    name: 'Bulldog',
    weight: 60,
    total: 81,
    XS: 0,
    S: 2.46913580246914,
    M: 29.62962962962963,
    L: 55.55555555555556,
    XL: 12.34567901234568,
  },
  {
    name: 'Bulldog',
    weight: 70,
    total: 48,
    XS: 0,
    S: 4.16666666666667,
    M: 29.16666666666667,
    L: 47.91666666666667,
    XL: 18.75,
  },
  {
    name: 'Bullypit',
    weight: 50,
    total: 34,
    XS: 0,
    S: 2.94117647058824,
    M: 35.29411764705882,
    L: 55.88235294117647,
    XL: 5.88235294117647,
  },
  {
    name: 'Bullypit',
    weight: 60,
    total: 45,
    XS: 2.22222222222222,
    S: 0,
    M: 17.77777777777778,
    L: 77.77777777777779,
    XL: 2.22222222222222,
  },
  {
    name: 'Bullypit',
    weight: 70,
    total: 31,
    XS: 0,
    S: 3.2258064516129,
    M: 29.03225806451613,
    L: 58.06451612903226,
    XL: 9.67741935483871,
  },
  {
    name: 'Cairn Terrier',
    weight: 20,
    total: 116,
    XS: 1.72413793103448,
    S: 72.41379310344828,
    M: 22.41379310344828,
    L: 3.44827586206897,
    XL: 0,
  },
  {
    name: 'Cane Corso',
    weight: 40,
    total: 27,
    XS: 0,
    S: 11.11111111111111,
    M: 40.74074074074074,
    L: 44.44444444444444,
    XL: 3.7037037037037,
  },
  {
    name: 'Cane Corso',
    weight: 50,
    total: 48,
    XS: 0,
    S: 6.25,
    M: 29.16666666666667,
    L: 50,
    XL: 14.58333333333333,
  },
  {
    name: 'Cane Corso',
    weight: 60,
    total: 42,
    XS: 2.38095238095238,
    S: 2.38095238095238,
    M: 21.42857142857143,
    L: 57.14285714285714,
    XL: 16.66666666666667,
  },
  {
    name: 'Cane Corso',
    weight: 70,
    total: 60,
    XS: 0,
    S: 6.66666666666667,
    M: 23.33333333333333,
    L: 50,
    XL: 20,
  },
  {
    name: 'Cane Corso',
    weight: 80,
    total: 79,
    XS: 0,
    S: 1.26582278481013,
    M: 8.86075949367089,
    L: 50.63291139240506,
    XL: 39.24050632911392,
  },
  {
    name: 'Cane Corso',
    weight: 90,
    total: 109,
    XS: 0,
    S: 0.91743119266055,
    M: 12.84403669724771,
    L: 42.20183486238532,
    XL: 44.03669724770642,
  },
  {
    name: 'Cane Corso',
    weight: 100,
    total: 124,
    XS: 0,
    S: 2.41935483870968,
    M: 17.74193548387097,
    L: 34.67741935483871,
    XL: 45.16129032258065,
  },
  {
    name: 'Cane Corso',
    weight: 110,
    total: 88,
    XS: 0,
    S: 4.54545454545455,
    M: 22.72727272727273,
    L: 20.45454545454545,
    XL: 52.27272727272727,
  },
  {
    name: 'Cane Corso',
    weight: 120,
    total: 62,
    XS: 0,
    S: 0,
    M: 19.35483870967742,
    L: 16.12903225806452,
    XL: 64.51612903225806,
  },
  {
    name: 'Cane Corso',
    weight: 130,
    total: 58,
    XS: 0,
    S: 0,
    M: 15.51724137931034,
    L: 17.24137931034483,
    XL: 67.24137931034483,
  },
  {
    name: 'Cardigan Welsh Corgi',
    weight: 20,
    total: 76,
    XS: 3.94736842105263,
    S: 23.68421052631579,
    M: 67.10526315789474,
    L: 3.94736842105263,
    XL: 1.31578947368421,
  },
  {
    name: 'Cardigan Welsh Corgi',
    weight: 30,
    total: 122,
    XS: 0,
    S: 10.65573770491803,
    M: 79.50819672131148,
    L: 9.83606557377049,
    XL: 0,
  },
  {
    name: 'Cardigan Welsh Corgi',
    weight: 40,
    total: 41,
    XS: 0,
    S: 2.4390243902439,
    M: 78.04878048780488,
    L: 19.51219512195122,
    XL: 0,
  },
  {
    name: 'Carolina Dog',
    weight: 50,
    total: 39,
    XS: 0,
    S: 0,
    M: 53.84615384615385,
    L: 46.15384615384615,
    XL: 0,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 30,
    total: 76,
    XS: 1.31578947368421,
    S: 9.21052631578947,
    M: 69.73684210526316,
    L: 18.42105263157895,
    XL: 1.31578947368421,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 40,
    total: 102,
    XS: 0,
    S: 5.88235294117647,
    M: 68.62745098039215,
    L: 25.49019607843137,
    XL: 0,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 50,
    total: 185,
    XS: 0,
    S: 3.78378378378378,
    M: 63.78378378378378,
    L: 31.89189189189189,
    XL: 0.54054054054054,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 60,
    total: 183,
    XS: 0,
    S: 1.63934426229508,
    M: 34.97267759562842,
    L: 62.84153005464481,
    XL: 0.54644808743169,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 70,
    total: 133,
    XS: 0,
    S: 0,
    M: 33.08270676691729,
    L: 65.41353383458647,
    XL: 1.50375939849624,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 80,
    total: 56,
    XS: 0,
    S: 0,
    M: 25,
    L: 71.42857142857143,
    XL: 3.57142857142857,
  },
  {
    name: 'Catahoula Leopard Dog',
    weight: 90,
    total: 29,
    XS: 0,
    S: 0,
    M: 31.03448275862069,
    L: 65.51724137931033,
    XL: 3.44827586206897,
  },
  {
    name: 'Cavachon',
    weight: 20,
    total: 69,
    XS: 11.59420289855072,
    S: 69.56521739130434,
    M: 18.84057971014493,
    L: 0,
    XL: 0,
  },
  {
    name: 'Cavalier King Charles Spaniel',
    weight: 10,
    total: 110,
    XS: 20,
    S: 70.9090909090909,
    M: 8.18181818181818,
    L: 0.90909090909091,
    XL: 0,
  },
  {
    name: 'Cavalier King Charles Spaniel',
    weight: 20,
    total: 477,
    XS: 4.82180293501048,
    S: 74.63312368972746,
    M: 20.12578616352201,
    L: 0.41928721174004,
    XL: 0,
  },
  {
    name: 'Cavalier King Charles Spaniel',
    weight: 30,
    total: 76,
    XS: 0,
    S: 57.89473684210526,
    M: 40.78947368421053,
    L: 1.31578947368421,
    XL: 0,
  },
  {
    name: 'Cavapoo',
    weight: 10,
    total: 139,
    XS: 41.72661870503597,
    S: 51.79856115107914,
    M: 5.03597122302158,
    L: 0.71942446043165,
    XL: 0.71942446043165,
  },
  {
    name: 'Cavapoo',
    weight: 20,
    total: 415,
    XS: 8.91566265060241,
    S: 74.21686746987952,
    M: 15.90361445783133,
    L: 0.72289156626506,
    XL: 0.24096385542169,
  },
  {
    name: 'Cavapoo',
    weight: 30,
    total: 98,
    XS: 0,
    S: 51.02040816326531,
    M: 45.91836734693878,
    L: 3.06122448979592,
    XL: 0,
  },
  {
    name: 'Chesapeake Bay Retriever',
    weight: 60,
    total: 27,
    XS: 3.7037037037037,
    S: 3.7037037037037,
    M: 22.22222222222222,
    L: 66.66666666666667,
    XL: 3.7037037037037,
  },
  {
    name: 'Chesapeake Bay Retriever',
    weight: 70,
    total: 36,
    XS: 0,
    S: 2.77777777777778,
    M: 36.11111111111111,
    L: 58.33333333333333,
    XL: 2.77777777777778,
  },
  {
    name: 'Chihuahua',
    weight: 10,
    total: 367,
    XS: 50.13623978201635,
    S: 36.78474114441417,
    M: 9.80926430517711,
    L: 2.72479564032698,
    XL: 0.5449591280654,
  },
  {
    name: 'Chihuahua',
    weight: 20,
    total: 497,
    XS: 10.26156941649899,
    S: 71.0261569416499,
    M: 15.49295774647887,
    L: 3.01810865191147,
    XL: 0.20120724346076,
  },
  {
    name: 'Chihuahua',
    weight: 30,
    total: 117,
    XS: 1.70940170940171,
    S: 33.33333333333333,
    M: 59.82905982905983,
    L: 4.27350427350427,
    XL: 0.85470085470085,
  },
  {
    name: 'Chihuahua',
    weight: 40,
    total: 30,
    XS: 0,
    S: 3.33333333333333,
    M: 90,
    L: 6.66666666666667,
    XL: 0,
  },
  {
    name: 'Chinese Shar-Pei',
    weight: 40,
    total: 30,
    XS: 0,
    S: 0,
    M: 53.33333333333333,
    L: 43.33333333333333,
    XL: 3.33333333333333,
  },
  {
    name: 'Chinese Shar-Pei',
    weight: 50,
    total: 48,
    XS: 0,
    S: 2.08333333333333,
    M: 45.83333333333333,
    L: 50,
    XL: 2.08333333333333,
  },
  {
    name: 'Chiweenie',
    weight: 20,
    total: 50,
    XS: 12,
    S: 72,
    M: 16,
    L: 0,
    XL: 0,
  },
  {
    name: 'Chow Chow',
    weight: 40,
    total: 41,
    XS: 0,
    S: 9.75609756097561,
    M: 46.34146341463415,
    L: 41.46341463414634,
    XL: 2.4390243902439,
  },
  {
    name: 'Chow Chow',
    weight: 50,
    total: 69,
    XS: 0,
    S: 2.89855072463768,
    M: 42.02898550724638,
    L: 50.72463768115942,
    XL: 4.34782608695652,
  },
  {
    name: 'Chow Chow',
    weight: 60,
    total: 51,
    XS: 0,
    S: 3.92156862745098,
    M: 23.52941176470588,
    L: 60.7843137254902,
    XL: 11.76470588235294,
  },
  {
    name: 'Cockapoo',
    weight: 10,
    total: 52,
    XS: 32.69230769230769,
    S: 48.07692307692308,
    M: 17.30769230769231,
    L: 1.92307692307692,
    XL: 0,
  },
  {
    name: 'Cockapoo',
    weight: 20,
    total: 236,
    XS: 8.05084745762712,
    S: 65.67796610169492,
    M: 25.42372881355932,
    L: 0.84745762711864,
    XL: 0,
  },
  {
    name: 'Cockapoo',
    weight: 30,
    total: 101,
    XS: 1.98019801980198,
    S: 35.64356435643564,
    M: 55.44554455445545,
    L: 6.93069306930693,
    XL: 0,
  },
  {
    name: 'Cocker Spaniel',
    weight: 20,
    total: 142,
    XS: 4.22535211267606,
    S: 54.22535211267606,
    M: 40.14084507042254,
    L: 0.70422535211268,
    XL: 0.70422535211268,
  },
  {
    name: 'Cocker Spaniel',
    weight: 30,
    total: 136,
    XS: 1.47058823529412,
    S: 30.88235294117647,
    M: 65.44117647058825,
    L: 2.20588235294118,
    XL: 0,
  },
  {
    name: 'Cocker Spaniel',
    weight: 40,
    total: 38,
    XS: 0,
    S: 10.52631578947368,
    M: 78.94736842105263,
    L: 10.52631578947368,
    XL: 0,
  },
  {
    name: 'Collie',
    weight: 40,
    total: 38,
    XS: 0,
    S: 2.63157894736842,
    M: 71.05263157894737,
    L: 23.68421052631579,
    XL: 2.63157894736842,
  },
  {
    name: 'Collie',
    weight: 50,
    total: 64,
    XS: 0,
    S: 3.125,
    M: 57.8125,
    L: 37.5,
    XL: 1.5625,
  },
  {
    name: 'Collie',
    weight: 60,
    total: 55,
    XS: 0,
    S: 3.63636363636364,
    M: 36.36363636363636,
    L: 56.36363636363636,
    XL: 3.63636363636364,
  },
  {
    name: 'Collie',
    weight: 70,
    total: 43,
    XS: 0,
    S: 0,
    M: 32.55813953488372,
    L: 60.46511627906977,
    XL: 6.97674418604651,
  },
  {
    name: 'Corgi Cattle Dog',
    weight: 20,
    total: 33,
    XS: 9.09090909090909,
    S: 21.21212121212121,
    M: 66.66666666666667,
    L: 3.03030303030303,
    XL: 0,
  },
  {
    name: 'Corgi Cattle Dog',
    weight: 30,
    total: 42,
    XS: 0,
    S: 11.9047619047619,
    M: 85.71428571428571,
    L: 2.38095238095238,
    XL: 0,
  },
  {
    name: 'Coton De Tulear',
    weight: 20,
    total: 33,
    XS: 3.03030303030303,
    S: 81.81818181818181,
    M: 15.15151515151515,
    L: 0,
    XL: 0,
  },
  {
    name: 'Dachshund',
    weight: 10,
    total: 428,
    XS: 46.72897196261682,
    S: 43.92523364485981,
    M: 6.54205607476636,
    L: 2.33644859813084,
    XL: 0.46728971962617,
  },
  {
    name: 'Dachshund',
    weight: 20,
    total: 741,
    XS: 14.30499325236167,
    S: 67.88124156545209,
    M: 15.24966261808367,
    L: 1.75438596491228,
    XL: 0.80971659919028,
  },
  {
    name: 'Dachshund',
    weight: 30,
    total: 131,
    XS: 1.52671755725191,
    S: 38.16793893129771,
    M: 58.01526717557252,
    L: 2.29007633587786,
    XL: 0,
  },
  {
    name: 'Dachshund',
    weight: 40,
    total: 34,
    XS: 0,
    S: 8.82352941176471,
    M: 85.29411764705883,
    L: 5.88235294117647,
    XL: 0,
  },
  {
    name: 'Dalmatian',
    weight: 20,
    total: 36,
    XS: 11.11111111111111,
    S: 33.33333333333333,
    M: 41.66666666666667,
    L: 11.11111111111111,
    XL: 2.77777777777778,
  },
  {
    name: 'Dalmatian',
    weight: 30,
    total: 56,
    XS: 1.78571428571429,
    S: 12.5,
    M: 64.28571428571429,
    L: 19.64285714285714,
    XL: 1.78571428571429,
  },
  {
    name: 'Dalmatian',
    weight: 40,
    total: 78,
    XS: 1.28205128205128,
    S: 10.25641025641026,
    M: 62.82051282051282,
    L: 24.35897435897436,
    XL: 1.28205128205128,
  },
  {
    name: 'Dalmatian',
    weight: 50,
    total: 190,
    XS: 0,
    S: 5.26315789473684,
    M: 53.68421052631579,
    L: 40.52631578947368,
    XL: 0.52631578947368,
  },
  {
    name: 'Dalmatian',
    weight: 60,
    total: 153,
    XS: 0,
    S: 3.26797385620915,
    M: 48.36601307189542,
    L: 48.36601307189542,
    XL: 0,
  },
  {
    name: 'Dalmatian',
    weight: 70,
    total: 69,
    XS: 0,
    S: 4.34782608695652,
    M: 27.53623188405797,
    L: 66.66666666666667,
    XL: 1.44927536231884,
  },
  {
    name: 'Doberman Pinscher',
    weight: 20,
    total: 56,
    XS: 5.35714285714286,
    S: 32.14285714285714,
    M: 41.07142857142857,
    L: 19.64285714285714,
    XL: 1.78571428571429,
  },
  {
    name: 'Doberman Pinscher',
    weight: 30,
    total: 104,
    XS: 2.88461538461538,
    S: 17.30769230769231,
    M: 46.15384615384615,
    L: 32.69230769230769,
    XL: 0.96153846153846,
  },
  {
    name: 'Doberman Pinscher',
    weight: 40,
    total: 130,
    XS: 0,
    S: 10,
    M: 49.23076923076923,
    L: 40.76923076923077,
    XL: 0,
  },
  {
    name: 'Doberman Pinscher',
    weight: 50,
    total: 160,
    XS: 0.625,
    S: 3.75,
    M: 35.625,
    L: 56.875,
    XL: 3.125,
  },
  {
    name: 'Doberman Pinscher',
    weight: 60,
    total: 231,
    XS: 0,
    S: 3.8961038961039,
    M: 38.96103896103896,
    L: 54.11255411255411,
    XL: 3.03030303030303,
  },
  {
    name: 'Doberman Pinscher',
    weight: 70,
    total: 363,
    XS: 0,
    S: 3.85674931129477,
    M: 30.85399449035813,
    L: 62.8099173553719,
    XL: 2.47933884297521,
  },
  {
    name: 'Doberman Pinscher',
    weight: 80,
    total: 378,
    XS: 0,
    S: 2.38095238095238,
    M: 24.33862433862434,
    L: 70.8994708994709,
    XL: 2.38095238095238,
  },
  {
    name: 'Doberman Pinscher',
    weight: 90,
    total: 231,
    XS: 0,
    S: 1.2987012987013,
    M: 22.51082251082251,
    L: 71.86147186147186,
    XL: 4.32900432900433,
  },
  {
    name: 'Doberman Pinscher',
    weight: 100,
    total: 94,
    XS: 0,
    S: 2.12765957446809,
    M: 21.27659574468085,
    L: 65.95744680851064,
    XL: 10.63829787234043,
  },
  {
    name: 'Doberman Pinscher',
    weight: 110,
    total: 33,
    XS: 0,
    S: 0,
    M: 18.18181818181818,
    L: 66.66666666666667,
    XL: 15.15151515151515,
  },
  {
    name: 'Dogo Argentino',
    weight: 80,
    total: 27,
    XS: 0,
    S: 3.7037037037037,
    M: 7.40740740740741,
    L: 77.77777777777779,
    XL: 11.11111111111111,
  },
  {
    name: 'Dogo Argentino',
    weight: 90,
    total: 29,
    XS: 0,
    S: 3.44827586206897,
    M: 27.58620689655172,
    L: 41.37931034482759,
    XL: 27.58620689655172,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 20,
    total: 30,
    XS: 0,
    S: 23.33333333333333,
    M: 63.33333333333333,
    L: 10,
    XL: 3.33333333333333,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 30,
    total: 32,
    XS: 0,
    S: 21.875,
    M: 46.875,
    L: 28.125,
    XL: 3.125,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 40,
    total: 55,
    XS: 0,
    S: 12.72727272727273,
    M: 50.90909090909091,
    L: 34.54545454545455,
    XL: 1.81818181818182,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 50,
    total: 117,
    XS: 0,
    S: 5.12820512820513,
    M: 48.71794871794872,
    L: 43.58974358974359,
    XL: 2.56410256410256,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 60,
    total: 143,
    XS: 0,
    S: 4.8951048951049,
    M: 32.86713286713287,
    L: 61.53846153846154,
    XL: 0.6993006993007,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 70,
    total: 104,
    XS: 0,
    S: 4.80769230769231,
    M: 33.65384615384615,
    L: 59.61538461538462,
    XL: 1.92307692307692,
  },
  {
    name: 'Dutch Shepherd Dog',
    weight: 80,
    total: 44,
    XS: 0,
    S: 2.27272727272727,
    M: 25,
    L: 65.9090909090909,
    XL: 6.81818181818182,
  },
  {
    name: 'English Bulldog',
    weight: 30,
    total: 40,
    XS: 0,
    S: 12.5,
    M: 47.5,
    L: 40,
    XL: 0,
  },
  {
    name: 'English Bulldog',
    weight: 40,
    total: 32,
    XS: 0,
    S: 6.25,
    M: 50,
    L: 37.5,
    XL: 6.25,
  },
  {
    name: 'English Bulldog',
    weight: 50,
    total: 75,
    XS: 0,
    S: 4,
    M: 26.66666666666667,
    L: 50.66666666666667,
    XL: 18.66666666666667,
  },
  {
    name: 'English Bulldog',
    weight: 60,
    total: 49,
    XS: 0,
    S: 2.04081632653061,
    M: 24.48979591836735,
    L: 51.02040816326531,
    XL: 22.44897959183673,
  },
  {
    name: 'English Cocker Spaniel',
    weight: 20,
    total: 61,
    XS: 1.63934426229508,
    S: 52.45901639344262,
    M: 44.26229508196721,
    L: 1.63934426229508,
    XL: 0,
  },
  {
    name: 'English Cocker Spaniel',
    weight: 30,
    total: 115,
    XS: 0.8695652173913,
    S: 29.56521739130435,
    M: 67.82608695652173,
    L: 1.73913043478261,
    XL: 0,
  },
  {
    name: 'English Cocker Spaniel',
    weight: 40,
    total: 42,
    XS: 0,
    S: 7.14285714285714,
    M: 88.0952380952381,
    L: 4.76190476190476,
    XL: 0,
  },
  {
    name: 'English Pointer',
    weight: 50,
    total: 56,
    XS: 0,
    S: 5.35714285714286,
    M: 64.28571428571429,
    L: 28.57142857142857,
    XL: 1.78571428571429,
  },
  {
    name: 'English Setter',
    weight: 30,
    total: 36,
    XS: 2.77777777777778,
    S: 19.44444444444444,
    M: 61.11111111111111,
    L: 16.66666666666667,
    XL: 0,
  },
  {
    name: 'English Setter',
    weight: 40,
    total: 95,
    XS: 1.05263157894737,
    S: 8.42105263157895,
    M: 83.15789473684211,
    L: 7.36842105263158,
    XL: 0,
  },
  {
    name: 'English Setter',
    weight: 50,
    total: 118,
    XS: 0,
    S: 4.23728813559322,
    M: 75.42372881355932,
    L: 18.64406779661017,
    XL: 1.69491525423729,
  },
  {
    name: 'English Setter',
    weight: 60,
    total: 62,
    XS: 0,
    S: 4.83870967741935,
    M: 48.38709677419355,
    L: 46.7741935483871,
    XL: 0,
  },
  {
    name: 'English Shepherd',
    weight: 40,
    total: 30,
    XS: 0,
    S: 6.66666666666667,
    M: 86.66666666666667,
    L: 3.33333333333333,
    XL: 3.33333333333333,
  },
  {
    name: 'English Shepherd',
    weight: 50,
    total: 42,
    XS: 0,
    S: 9.52380952380952,
    M: 69.04761904761905,
    L: 21.42857142857143,
    XL: 0,
  },
  {
    name: 'English Shepherd',
    weight: 60,
    total: 30,
    XS: 0,
    S: 10,
    M: 36.66666666666667,
    L: 53.33333333333333,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 20,
    total: 33,
    XS: 0,
    S: 24.24242424242424,
    M: 72.72727272727273,
    L: 3.03030303030303,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 30,
    total: 73,
    XS: 1.36986301369863,
    S: 13.6986301369863,
    M: 75.34246575342466,
    L: 9.58904109589041,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 40,
    total: 195,
    XS: 0.51282051282051,
    S: 9.23076923076923,
    M: 82.56410256410255,
    L: 7.69230769230769,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 50,
    total: 189,
    XS: 0,
    S: 2.11640211640212,
    M: 68.78306878306879,
    L: 29.1005291005291,
    XL: 0,
  },
  {
    name: 'English Springer Spaniel',
    weight: 60,
    total: 52,
    XS: 0,
    S: 11.53846153846154,
    M: 51.92307692307692,
    L: 36.53846153846154,
    XL: 0,
  },
  {
    name: 'French Bulldog',
    weight: 10,
    total: 229,
    XS: 10.91703056768559,
    S: 66.8122270742358,
    M: 20.08733624454148,
    L: 2.18340611353712,
    XL: 0,
  },
  {
    name: 'French Bulldog',
    weight: 20,
    total: 863,
    XS: 0.92699884125145,
    S: 46.00231749710313,
    M: 45.42294322132097,
    L: 7.18424101969873,
    XL: 0.46349942062572,
  },
  {
    name: 'French Bulldog',
    weight: 30,
    total: 1190,
    XS: 0.33613445378151,
    S: 26.05042016806723,
    M: 53.02521008403361,
    L: 20,
    XL: 0.58823529411765,
  },
  {
    name: 'French Bulldog',
    weight: 40,
    total: 224,
    XS: 0,
    S: 13.39285714285714,
    M: 47.76785714285714,
    L: 37.5,
    XL: 1.33928571428571,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 40,
    total: 27,
    XS: 0,
    S: 0,
    M: 40.74074074074074,
    L: 55.55555555555556,
    XL: 3.7037037037037,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 50,
    total: 46,
    XS: 0,
    S: 2.17391304347826,
    M: 34.78260869565217,
    L: 63.04347826086957,
    XL: 0,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 60,
    total: 61,
    XS: 0,
    S: 0,
    M: 39.34426229508197,
    L: 59.01639344262295,
    XL: 1.63934426229508,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 70,
    total: 53,
    XS: 0,
    S: 1.88679245283019,
    M: 24.52830188679245,
    L: 69.81132075471697,
    XL: 3.77358490566038,
  },
  {
    name: 'Gerberian Shepsky',
    weight: 80,
    total: 27,
    XS: 0,
    S: 0,
    M: 18.51851851851852,
    L: 55.55555555555556,
    XL: 25.92592592592593,
  },
  {
    name: 'German Shepherd Dog',
    weight: 10,
    total: 83,
    XS: 3.6144578313253,
    S: 27.71084337349398,
    M: 34.93975903614458,
    L: 30.12048192771084,
    XL: 3.6144578313253,
  },
  {
    name: 'German Shepherd Dog',
    weight: 20,
    total: 237,
    XS: 3.37552742616034,
    S: 24.89451476793249,
    M: 42.61603375527426,
    L: 27.0042194092827,
    XL: 2.10970464135021,
  },
  {
    name: 'German Shepherd Dog',
    weight: 30,
    total: 521,
    XS: 1.34357005758157,
    S: 10.74856046065259,
    M: 51.43953934740883,
    L: 35.50863723608445,
    XL: 0.95969289827255,
  },
  {
    name: 'German Shepherd Dog',
    weight: 40,
    total: 812,
    XS: 0.12315270935961,
    S: 5.78817733990148,
    M: 51.35467980295567,
    L: 40.88669950738916,
    XL: 1.84729064039409,
  },
  {
    name: 'German Shepherd Dog',
    weight: 50,
    total: 1499,
    XS: 0.26684456304203,
    S: 3.33555703802535,
    M: 41.16077384923282,
    L: 53.56904603068712,
    XL: 1.66777851901268,
  },
  {
    name: 'German Shepherd Dog',
    weight: 60,
    total: 1912,
    XS: 0.05230125523013,
    S: 2.30125523012552,
    M: 29.02719665271967,
    L: 66.26569037656904,
    XL: 2.35355648535565,
  },
  {
    name: 'German Shepherd Dog',
    weight: 70,
    total: 2248,
    XS: 0,
    S: 2.04626334519573,
    M: 22.4644128113879,
    L: 71.3967971530249,
    XL: 4.09252669039146,
  },
  {
    name: 'German Shepherd Dog',
    weight: 80,
    total: 2039,
    XS: 0.04904364884747,
    S: 1.47130946542423,
    M: 19.27415399705738,
    L: 71.35850907307504,
    XL: 7.84698381559588,
  },
  {
    name: 'German Shepherd Dog',
    weight: 90,
    total: 1172,
    XS: 0.08532423208191,
    S: 0.93856655290102,
    M: 18.77133105802048,
    L: 68.9419795221843,
    XL: 11.26279863481229,
  },
  {
    name: 'German Shepherd Dog',
    weight: 100,
    total: 591,
    XS: 0,
    S: 0.84602368866328,
    M: 17.25888324873096,
    L: 61.59052453468697,
    XL: 20.30456852791878,
  },
  {
    name: 'German Shepherd Dog',
    weight: 110,
    total: 165,
    XS: 0,
    S: 0.60606060606061,
    M: 20.60606060606061,
    L: 52.12121212121212,
    XL: 26.66666666666667,
  },
  {
    name: 'German Shepherd Dog',
    weight: 120,
    total: 55,
    XS: 0,
    S: 1.81818181818182,
    M: 9.09090909090909,
    L: 58.18181818181818,
    XL: 30.90909090909091,
  },
  {
    name: 'German Shepherd Dog',
    weight: 130,
    total: 28,
    XS: 0,
    S: 0,
    M: 28.57142857142857,
    L: 35.71428571428571,
    XL: 35.71428571428571,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 10,
    total: 33,
    XS: 3.03030303030303,
    S: 33.33333333333333,
    M: 45.45454545454545,
    L: 18.18181818181818,
    XL: 0,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 20,
    total: 134,
    XS: 2.23880597014925,
    S: 38.05970149253731,
    M: 44.77611940298507,
    L: 14.92537313432836,
    XL: 0,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 30,
    total: 197,
    XS: 1.01522842639594,
    S: 17.25888324873096,
    M: 63.45177664974619,
    L: 17.76649746192893,
    XL: 0.50761421319797,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 40,
    total: 492,
    XS: 0.40650406504065,
    S: 5.48780487804878,
    M: 70.1219512195122,
    L: 23.57723577235772,
    XL: 0.40650406504065,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 50,
    total: 1008,
    XS: 0.5952380952381,
    S: 3.07539682539683,
    M: 63.78968253968254,
    L: 31.74603174603175,
    XL: 0.79365079365079,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 60,
    total: 833,
    XS: 0.36014405762305,
    S: 2.88115246098439,
    M: 48.73949579831933,
    L: 47.41896758703481,
    XL: 0.60024009603842,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 70,
    total: 364,
    XS: 0,
    S: 1.0989010989011,
    M: 39.83516483516484,
    L: 58.24175824175824,
    XL: 0.82417582417582,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 80,
    total: 125,
    XS: 0,
    S: 0.8,
    M: 33.6,
    L: 64.8,
    XL: 0.8,
  },
  {
    name: 'German Shorthaired Pointer',
    weight: 90,
    total: 26,
    XS: 0,
    S: 0,
    M: 23.07692307692308,
    L: 73.07692307692308,
    XL: 3.84615384615385,
  },
  {
    name: 'German Wirehaired Pointer',
    weight: 50,
    total: 69,
    XS: 0,
    S: 2.89855072463768,
    M: 53.6231884057971,
    L: 43.47826086956522,
    XL: 0,
  },
  {
    name: 'German Wirehaired Pointer',
    weight: 60,
    total: 74,
    XS: 0,
    S: 2.7027027027027,
    M: 47.2972972972973,
    L: 47.2972972972973,
    XL: 2.7027027027027,
  },
  {
    name: 'German Wirehaired Pointer',
    weight: 70,
    total: 50,
    XS: 0,
    S: 2,
    M: 42,
    L: 52,
    XL: 4,
  },
  {
    name: 'Giant Schnauzer',
    weight: 70,
    total: 42,
    XS: 0,
    S: 0,
    M: 40.47619047619048,
    L: 59.52380952380952,
    XL: 0,
  },
  {
    name: 'Giant Schnauzer',
    weight: 80,
    total: 45,
    XS: 0,
    S: 2.22222222222222,
    M: 22.22222222222222,
    L: 62.22222222222222,
    XL: 13.33333333333333,
  },
  {
    name: 'Giant Schnauzer',
    weight: 90,
    total: 41,
    XS: 0,
    S: 0,
    M: 17.07317073170732,
    L: 73.17073170731707,
    XL: 9.75609756097561,
  },
  {
    name: 'Golden Retriever',
    weight: 10,
    total: 93,
    XS: 7.52688172043011,
    S: 35.48387096774194,
    M: 35.48387096774194,
    L: 18.27956989247312,
    XL: 3.2258064516129,
  },
  {
    name: 'Golden Retriever',
    weight: 20,
    total: 291,
    XS: 4.46735395189003,
    S: 27.4914089347079,
    M: 43.6426116838488,
    L: 23.36769759450172,
    XL: 1.03092783505155,
  },
  {
    name: 'Golden Retriever',
    weight: 30,
    total: 522,
    XS: 1.34099616858238,
    S: 15.13409961685824,
    M: 49.42528735632184,
    L: 32.75862068965517,
    XL: 1.34099616858238,
  },
  {
    name: 'Golden Retriever',
    weight: 40,
    total: 685,
    XS: 0.29197080291971,
    S: 6.27737226277372,
    M: 42.33576642335766,
    L: 49.63503649635036,
    XL: 1.45985401459854,
  },
  {
    name: 'Golden Retriever',
    weight: 50,
    total: 1317,
    XS: 0.45558086560364,
    S: 4.17615793470008,
    M: 35.53530751708428,
    L: 58.69400151860289,
    XL: 1.13895216400911,
  },
  {
    name: 'Golden Retriever',
    weight: 60,
    total: 1952,
    XS: 0.20491803278689,
    S: 2.30532786885246,
    M: 26.63934426229508,
    L: 68.95491803278689,
    XL: 1.89549180327869,
  },
  {
    name: 'Golden Retriever',
    weight: 70,
    total: 2210,
    XS: 0.13574660633484,
    S: 1.8552036199095,
    M: 19.09502262443439,
    L: 75.83710407239819,
    XL: 3.07692307692308,
  },
  {
    name: 'Golden Retriever',
    weight: 80,
    total: 1504,
    XS: 0.13297872340426,
    S: 1.52925531914894,
    M: 17.68617021276596,
    L: 74.46808510638299,
    XL: 6.18351063829787,
  },
  {
    name: 'Golden Retriever',
    weight: 90,
    total: 561,
    XS: 0,
    S: 0.53475935828877,
    M: 17.11229946524064,
    L: 72.01426024955437,
    XL: 10.33868092691622,
  },
  {
    name: 'Golden Retriever',
    weight: 100,
    total: 208,
    XS: 0,
    S: 1.92307692307692,
    M: 13.46153846153846,
    L: 67.78846153846153,
    XL: 16.82692307692308,
  },
  {
    name: 'Golden Retriever',
    weight: 110,
    total: 34,
    XS: 0,
    S: 2.94117647058824,
    M: 17.64705882352941,
    L: 55.88235294117647,
    XL: 23.52941176470588,
  },
  {
    name: 'Goldendoodle',
    weight: 10,
    total: 229,
    XS: 20.96069868995633,
    S: 46.2882096069869,
    M: 24.89082969432314,
    L: 7.86026200873362,
    XL: 0,
  },
  {
    name: 'Goldendoodle',
    weight: 20,
    total: 1022,
    XS: 4.40313111545988,
    S: 56.84931506849315,
    M: 34.83365949119374,
    L: 3.91389432485323,
    XL: 0,
  },
  {
    name: 'Goldendoodle',
    weight: 30,
    total: 1349,
    XS: 1.18606375092661,
    S: 29.65159377316531,
    M: 62.04595997034841,
    L: 6.89399555226093,
    XL: 0.22238695329874,
  },
  {
    name: 'Goldendoodle',
    weight: 40,
    total: 1101,
    XS: 0.36330608537693,
    S: 9.80926430517711,
    M: 70.48138056312443,
    L: 18.80108991825613,
    XL: 0.5449591280654,
  },
  {
    name: 'Goldendoodle',
    weight: 50,
    total: 1200,
    XS: 0.33333333333333,
    S: 3.58333333333333,
    M: 49.91666666666667,
    L: 45.5,
    XL: 0.66666666666667,
  },
  {
    name: 'Goldendoodle',
    weight: 60,
    total: 949,
    XS: 0.10537407797682,
    S: 2.31822971548999,
    M: 36.67017913593256,
    L: 60.16859852476291,
    XL: 0.73761854583772,
  },
  {
    name: 'Goldendoodle',
    weight: 70,
    total: 613,
    XS: 0.163132137031,
    S: 2.12071778140294,
    M: 24.14355628058728,
    L: 70.96247960848287,
    XL: 2.61011419249592,
  },
  {
    name: 'Goldendoodle',
    weight: 80,
    total: 278,
    XS: 0,
    S: 1.07913669064748,
    M: 20.50359712230216,
    L: 73.02158273381295,
    XL: 5.39568345323741,
  },
  {
    name: 'Goldendoodle',
    weight: 90,
    total: 83,
    XS: 0,
    S: 1.20481927710843,
    M: 13.25301204819277,
    L: 78.3132530120482,
    XL: 7.2289156626506,
  },
  {
    name: 'Goldendoodle',
    weight: 100,
    total: 26,
    XS: 0,
    S: 0,
    M: 19.23076923076923,
    L: 65.38461538461539,
    XL: 15.38461538461538,
  },
  {
    name: 'Great Dane',
    weight: 30,
    total: 40,
    XS: 0,
    S: 12.5,
    M: 42.5,
    L: 25,
    XL: 20,
  },
  {
    name: 'Great Dane',
    weight: 40,
    total: 34,
    XS: 0,
    S: 8.82352941176471,
    M: 52.94117647058824,
    L: 29.41176470588235,
    XL: 8.82352941176471,
  },
  {
    name: 'Great Dane',
    weight: 50,
    total: 54,
    XS: 0,
    S: 1.85185185185185,
    M: 50,
    L: 37.03703703703704,
    XL: 11.11111111111111,
  },
  {
    name: 'Great Dane',
    weight: 60,
    total: 73,
    XS: 0,
    S: 2.73972602739726,
    M: 30.13698630136986,
    L: 47.94520547945205,
    XL: 19.17808219178082,
  },
  {
    name: 'Great Dane',
    weight: 70,
    total: 89,
    XS: 1.12359550561798,
    S: 5.61797752808989,
    M: 23.59550561797753,
    L: 51.68539325842697,
    XL: 17.97752808988764,
  },
  {
    name: 'Great Dane',
    weight: 80,
    total: 97,
    XS: 0,
    S: 2.06185567010309,
    M: 22.68041237113402,
    L: 53.60824742268041,
    XL: 21.64948453608247,
  },
  {
    name: 'Great Dane',
    weight: 90,
    total: 105,
    XS: 0,
    S: 0.95238095238095,
    M: 22.85714285714286,
    L: 42.85714285714286,
    XL: 33.33333333333333,
  },
  {
    name: 'Great Dane',
    weight: 100,
    total: 159,
    XS: 0,
    S: 1.25786163522013,
    M: 19.49685534591195,
    L: 40.25157232704403,
    XL: 38.9937106918239,
  },
  {
    name: 'Great Dane',
    weight: 110,
    total: 159,
    XS: 0.62893081761006,
    S: 1.88679245283019,
    M: 15.72327044025157,
    L: 29.55974842767296,
    XL: 52.20125786163522,
  },
  {
    name: 'Great Dane',
    weight: 120,
    total: 181,
    XS: 0.5524861878453,
    S: 2.20994475138122,
    M: 17.67955801104972,
    L: 22.09944751381215,
    XL: 57.4585635359116,
  },
  {
    name: 'Great Dane',
    weight: 130,
    total: 145,
    XS: 0,
    S: 1.37931034482759,
    M: 15.17241379310345,
    L: 19.31034482758621,
    XL: 64.13793103448276,
  },
  {
    name: 'Great Dane',
    weight: 140,
    total: 101,
    XS: 0,
    S: 0,
    M: 19.8019801980198,
    L: 16.83168316831683,
    XL: 63.36633663366337,
  },
  {
    name: 'Great Dane',
    weight: 150,
    total: 92,
    XS: 0,
    S: 1.08695652173913,
    M: 19.56521739130435,
    L: 14.1304347826087,
    XL: 65.21739130434783,
  },
  {
    name: 'Great Dane',
    weight: 160,
    total: 31,
    XS: 0,
    S: 6.45161290322581,
    M: 6.45161290322581,
    L: 25.80645161290323,
    XL: 61.29032258064516,
  },
  {
    name: 'Great Dane',
    weight: 170,
    total: 26,
    XS: 0,
    S: 0,
    M: 23.07692307692308,
    L: 3.84615384615385,
    XL: 73.07692307692308,
  },
  {
    name: 'Great Pyrenees',
    weight: 20,
    total: 33,
    XS: 0,
    S: 15.15151515151515,
    M: 42.42424242424242,
    L: 21.21212121212121,
    XL: 21.21212121212121,
  },
  {
    name: 'Great Pyrenees',
    weight: 30,
    total: 96,
    XS: 1.04166666666667,
    S: 6.25,
    M: 46.875,
    L: 25,
    XL: 20.83333333333333,
  },
  {
    name: 'Great Pyrenees',
    weight: 40,
    total: 125,
    XS: 0,
    S: 4.8,
    M: 44.8,
    L: 31.2,
    XL: 19.2,
  },
  {
    name: 'Great Pyrenees',
    weight: 50,
    total: 185,
    XS: 0,
    S: 1.08108108108108,
    M: 30.81081081081081,
    L: 52.43243243243243,
    XL: 15.67567567567568,
  },
  {
    name: 'Great Pyrenees',
    weight: 60,
    total: 285,
    XS: 0,
    S: 1.40350877192982,
    M: 30.17543859649123,
    L: 49.82456140350877,
    XL: 18.59649122807018,
  },
  {
    name: 'Great Pyrenees',
    weight: 70,
    total: 405,
    XS: 0,
    S: 1.48148148148148,
    M: 21.7283950617284,
    L: 52.09876543209877,
    XL: 24.69135802469136,
  },
  {
    name: 'Great Pyrenees',
    weight: 80,
    total: 521,
    XS: 0,
    S: 1.34357005758157,
    M: 17.08253358925144,
    L: 45.68138195777351,
    XL: 35.89251439539347,
  },
  {
    name: 'Great Pyrenees',
    weight: 90,
    total: 434,
    XS: 0,
    S: 1.15207373271889,
    M: 16.35944700460829,
    L: 35.25345622119816,
    XL: 47.23502304147465,
  },
  {
    name: 'Great Pyrenees',
    weight: 100,
    total: 477,
    XS: 0,
    S: 1.0482180293501,
    M: 14.25576519916143,
    L: 26.83438155136268,
    XL: 57.86163522012579,
  },
  {
    name: 'Great Pyrenees',
    weight: 110,
    total: 156,
    XS: 0,
    S: 0.64102564102564,
    M: 12.17948717948718,
    L: 19.23076923076923,
    XL: 67.94871794871796,
  },
  {
    name: 'Great Pyrenees',
    weight: 120,
    total: 139,
    XS: 0,
    S: 0.71942446043165,
    M: 15.8273381294964,
    L: 23.02158273381295,
    XL: 60.43165467625899,
  },
  {
    name: 'Great Pyrenees',
    weight: 130,
    total: 89,
    XS: 0,
    S: 0,
    M: 20.2247191011236,
    L: 8.98876404494382,
    XL: 70.78651685393258,
  },
  {
    name: 'Great Pyrenees',
    weight: 140,
    total: 33,
    XS: 0,
    S: 0,
    M: 12.12121212121212,
    L: 9.09090909090909,
    XL: 78.7878787878788,
  },
  {
    name: 'Great Pyrenees',
    weight: 150,
    total: 28,
    XS: 0,
    S: 0,
    M: 7.14285714285714,
    L: 10.71428571428571,
    XL: 82.14285714285714,
  },
  {
    name: 'Greyhound',
    weight: 50,
    total: 31,
    XS: 0,
    S: 9.67741935483871,
    M: 74.19354838709677,
    L: 12.90322580645161,
    XL: 3.2258064516129,
  },
  {
    name: 'Greyhound',
    weight: 60,
    total: 30,
    XS: 0,
    S: 10,
    M: 60,
    L: 30,
    XL: 0,
  },
  {
    name: 'Greyhound',
    weight: 70,
    total: 51,
    XS: 0,
    S: 7.84313725490196,
    M: 62.74509803921569,
    L: 29.41176470588235,
    XL: 0,
  },
  {
    name: 'Greyhound',
    weight: 80,
    total: 28,
    XS: 0,
    S: 0,
    M: 67.85714285714286,
    L: 32.14285714285714,
    XL: 0,
  },
  {
    name: 'Havanese',
    weight: 10,
    total: 89,
    XS: 47.19101123595506,
    S: 46.06741573033708,
    M: 5.61797752808989,
    L: 1.12359550561798,
    XL: 0,
  },
  {
    name: 'Havanese',
    weight: 20,
    total: 147,
    XS: 10.20408163265306,
    S: 72.10884353741497,
    M: 17.00680272108844,
    L: 0.68027210884354,
    XL: 0,
  },
  {
    name: 'Irish Doodle',
    weight: 40,
    total: 28,
    XS: 0,
    S: 14.28571428571429,
    M: 71.42857142857143,
    L: 14.28571428571429,
    XL: 0,
  },
  {
    name: 'Irish Doodle',
    weight: 50,
    total: 31,
    XS: 0,
    S: 0,
    M: 74.19354838709677,
    L: 25.80645161290323,
    XL: 0,
  },
  {
    name: 'Irish Setter',
    weight: 50,
    total: 47,
    XS: 0,
    S: 4.25531914893617,
    M: 63.82978723404255,
    L: 31.91489361702128,
    XL: 0,
  },
  {
    name: 'Irish Setter',
    weight: 60,
    total: 43,
    XS: 0,
    S: 6.97674418604651,
    M: 58.13953488372093,
    L: 34.88372093023256,
    XL: 0,
  },
  {
    name: 'Irish Setter',
    weight: 70,
    total: 39,
    XS: 0,
    S: 2.56410256410256,
    M: 35.8974358974359,
    L: 61.53846153846154,
    XL: 0,
  },
  {
    name: 'Italian Greyhound',
    weight: 10,
    total: 27,
    XS: 55.55555555555556,
    S: 29.62962962962963,
    M: 11.11111111111111,
    L: 3.7037037037037,
    XL: 0,
  },
  {
    name: 'Italian Greyhound',
    weight: 20,
    total: 49,
    XS: 36.73469387755102,
    S: 61.22448979591837,
    M: 2.04081632653061,
    L: 0,
    XL: 0,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 10,
    total: 133,
    XS: 19.54887218045113,
    S: 66.1654135338346,
    M: 11.2781954887218,
    L: 2.25563909774436,
    XL: 0.75187969924812,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 20,
    total: 498,
    XS: 3.21285140562249,
    S: 73.49397590361446,
    M: 22.08835341365462,
    L: 1.20481927710843,
    XL: 0,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 30,
    total: 140,
    XS: 0,
    S: 39.28571428571429,
    M: 57.14285714285714,
    L: 2.85714285714286,
    XL: 0.71428571428571,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 40,
    total: 71,
    XS: 0,
    S: 9.85915492957746,
    M: 71.83098591549296,
    L: 15.49295774647887,
    XL: 2.8169014084507,
  },
  {
    name: 'Jack Russell Terrier',
    weight: 50,
    total: 33,
    XS: 0,
    S: 0,
    M: 48.48484848484848,
    L: 48.48484848484848,
    XL: 3.03030303030303,
  },
  {
    name: 'Jindo',
    weight: 20,
    total: 288,
    XS: 1.73611111111111,
    S: 29.86111111111111,
    M: 45.83333333333333,
    L: 20.13888888888889,
    XL: 2.43055555555556,
  },
  {
    name: 'Jindo',
    weight: 30,
    total: 297,
    XS: 0.67340067340067,
    S: 24.91582491582492,
    M: 50.16835016835017,
    L: 21.21212121212121,
    XL: 3.03030303030303,
  },
  {
    name: 'Jindo',
    weight: 40,
    total: 212,
    XS: 0,
    S: 16.0377358490566,
    M: 56.60377358490566,
    L: 26.88679245283019,
    XL: 0.47169811320755,
  },
  {
    name: 'Jindo',
    weight: 50,
    total: 95,
    XS: 0,
    S: 15.78947368421053,
    M: 46.31578947368421,
    L: 36.84210526315789,
    XL: 1.05263157894737,
  },
  {
    name: 'Jindo',
    weight: 60,
    total: 27,
    XS: 0,
    S: 11.11111111111111,
    M: 29.62962962962963,
    L: 51.85185185185185,
    XL: 7.40740740740741,
  },
  {
    name: 'Labrabull',
    weight: 40,
    total: 27,
    XS: 0,
    S: 0,
    M: 59.25925925925926,
    L: 40.74074074074074,
    XL: 0,
  },
  {
    name: 'Labrabull',
    weight: 50,
    total: 41,
    XS: 0,
    S: 2.4390243902439,
    M: 41.46341463414634,
    L: 56.09756097560976,
    XL: 0,
  },
  {
    name: 'Labrabull',
    weight: 60,
    total: 33,
    XS: 0,
    S: 0,
    M: 30.3030303030303,
    L: 69.6969696969697,
    XL: 0,
  },
  {
    name: 'Labradoodle',
    weight: 10,
    total: 33,
    XS: 12.12121212121212,
    S: 60.60606060606061,
    M: 18.18181818181818,
    L: 9.09090909090909,
    XL: 0,
  },
  {
    name: 'Labradoodle',
    weight: 20,
    total: 151,
    XS: 3.97350993377483,
    S: 45.6953642384106,
    M: 42.3841059602649,
    L: 7.94701986754967,
    XL: 0,
  },
  {
    name: 'Labradoodle',
    weight: 30,
    total: 205,
    XS: 0.48780487804878,
    S: 26.34146341463415,
    M: 62.92682926829268,
    L: 10.24390243902439,
    XL: 0,
  },
  {
    name: 'Labradoodle',
    weight: 40,
    total: 231,
    XS: 0,
    S: 5.19480519480519,
    M: 67.53246753246754,
    L: 27.27272727272727,
    XL: 0,
  },
  {
    name: 'Labradoodle',
    weight: 50,
    total: 254,
    XS: 0,
    S: 2.36220472440945,
    M: 52.36220472440945,
    L: 44.48818897637795,
    XL: 0.78740157480315,
  },
  {
    name: 'Labradoodle',
    weight: 60,
    total: 201,
    XS: 0,
    S: 4.97512437810945,
    M: 37.3134328358209,
    L: 57.21393034825871,
    XL: 0.49751243781095,
  },
  {
    name: 'Labradoodle',
    weight: 70,
    total: 165,
    XS: 0,
    S: 2.42424242424242,
    M: 22.42424242424242,
    L: 73.33333333333333,
    XL: 1.81818181818182,
  },
  {
    name: 'Labradoodle',
    weight: 80,
    total: 107,
    XS: 0.93457943925234,
    S: 0.93457943925234,
    M: 20.5607476635514,
    L: 71.96261682242991,
    XL: 5.60747663551402,
  },
  {
    name: 'Labradoodle',
    weight: 90,
    total: 30,
    XS: 0,
    S: 0,
    M: 20,
    L: 73.33333333333333,
    XL: 6.66666666666667,
  },
  {
    name: 'Labrador Husky',
    weight: 50,
    total: 78,
    XS: 0,
    S: 2.56410256410256,
    M: 47.43589743589744,
    L: 48.71794871794872,
    XL: 1.28205128205128,
  },
  {
    name: 'Labrador Husky',
    weight: 60,
    total: 63,
    XS: 0,
    S: 3.17460317460317,
    M: 22.22222222222222,
    L: 74.6031746031746,
    XL: 0,
  },
  {
    name: 'Labrador Husky',
    weight: 70,
    total: 41,
    XS: 0,
    S: 2.4390243902439,
    M: 19.51219512195122,
    L: 75.60975609756098,
    XL: 2.4390243902439,
  },
  {
    name: 'Labrador Retriever',
    weight: 10,
    total: 115,
    XS: 3.47826086956522,
    S: 30.43478260869565,
    M: 35.65217391304348,
    L: 27.82608695652174,
    XL: 2.60869565217391,
  },
  {
    name: 'Labrador Retriever',
    weight: 20,
    total: 413,
    XS: 1.93704600484262,
    S: 24.45520581113801,
    M: 46.97336561743341,
    L: 26.1501210653753,
    XL: 0.48426150121065,
  },
  {
    name: 'Labrador Retriever',
    weight: 30,
    total: 723,
    XS: 0.69156293222683,
    S: 10.65006915629322,
    M: 57.39972337482711,
    L: 30.01383125864454,
    XL: 1.2448132780083,
  },
  {
    name: 'Labrador Retriever',
    weight: 40,
    total: 1267,
    XS: 0.15785319652723,
    S: 5.20915548539858,
    M: 53.1965272296764,
    L: 40.25256511444357,
    XL: 1.18389897395422,
  },
  {
    name: 'Labrador Retriever',
    weight: 50,
    total: 2165,
    XS: 0.18475750577367,
    S: 2.63279445727483,
    M: 43.3256351039261,
    L: 52.84064665127021,
    XL: 1.0161662817552,
  },
  {
    name: 'Labrador Retriever',
    weight: 60,
    total: 2621,
    XS: 0.15261350629531,
    S: 2.59442960702022,
    M: 28.69133918351774,
    L: 66.53948874475391,
    XL: 2.02212895841282,
  },
  {
    name: 'Labrador Retriever',
    weight: 70,
    total: 2619,
    XS: 0.11454753722795,
    S: 1.75639557082856,
    M: 21.99312714776632,
    L: 71.78312332951508,
    XL: 4.35280641466208,
  },
  {
    name: 'Labrador Retriever',
    weight: 80,
    total: 2022,
    XS: 0.04945598417409,
    S: 1.08803165182987,
    M: 20.32640949554896,
    L: 70.37586547972305,
    XL: 8.16023738872404,
  },
  {
    name: 'Labrador Retriever',
    weight: 90,
    total: 1038,
    XS: 0.19267822736031,
    S: 1.54142581888247,
    M: 20.23121387283237,
    L: 63.19845857418112,
    XL: 14.83622350674374,
  },
  {
    name: 'Labrador Retriever',
    weight: 100,
    total: 476,
    XS: 0.21008403361345,
    S: 1.68067226890756,
    M: 17.64705882352941,
    L: 55.67226890756303,
    XL: 24.78991596638655,
  },
  {
    name: 'Labrador Retriever',
    weight: 110,
    total: 127,
    XS: 0,
    S: 1.5748031496063,
    M: 14.17322834645669,
    L: 56.69291338582677,
    XL: 27.55905511811024,
  },
  {
    name: 'Labrador Retriever',
    weight: 120,
    total: 38,
    XS: 0,
    S: 0,
    M: 15.78947368421053,
    L: 31.57894736842105,
    XL: 52.63157894736842,
  },
  {
    name: 'Lagotto Romagnolo',
    weight: 30,
    total: 125,
    XS: 0,
    S: 18.4,
    M: 80.8,
    L: 0.8,
    XL: 0,
  },
  {
    name: 'Lagotto Romagnolo',
    weight: 40,
    total: 84,
    XS: 3.57142857142857,
    S: 4.76190476190476,
    M: 85.71428571428571,
    L: 5.95238095238095,
    XL: 0,
  },
  {
    name: 'Lhasa Apso',
    weight: 20,
    total: 32,
    XS: 12.5,
    S: 40.625,
    M: 40.625,
    L: 3.125,
    XL: 3.125,
  },
  {
    name: 'Maltese',
    weight: 10,
    total: 87,
    XS: 55.17241379310345,
    S: 34.48275862068966,
    M: 8.04597701149425,
    L: 2.29885057471264,
    XL: 0,
  },
  {
    name: 'Maltese',
    weight: 20,
    total: 71,
    XS: 8.45070422535211,
    S: 73.2394366197183,
    M: 15.49295774647887,
    L: 2.8169014084507,
    XL: 0,
  },
  {
    name: 'Maltipoo',
    weight: 10,
    total: 122,
    XS: 59.01639344262295,
    S: 33.60655737704918,
    M: 7.37704918032787,
    L: 0,
    XL: 0,
  },
  {
    name: 'Maltipoo',
    weight: 20,
    total: 123,
    XS: 11.38211382113821,
    S: 71.54471544715447,
    M: 16.26016260162602,
    L: 0.8130081300813,
    XL: 0,
  },
  {
    name: 'Mastiff',
    weight: 80,
    total: 26,
    XS: 0,
    S: 0,
    M: 15.38461538461538,
    L: 38.46153846153846,
    XL: 46.15384615384615,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 10,
    total: 42,
    XS: 26.19047619047619,
    S: 64.28571428571429,
    M: 9.52380952380952,
    L: 0,
    XL: 0,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 20,
    total: 155,
    XS: 3.87096774193548,
    S: 71.61290322580645,
    M: 21.29032258064516,
    L: 2.58064516129032,
    XL: 0.64516129032258,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 30,
    total: 249,
    XS: 0.40160642570281,
    S: 51.00401606425703,
    M: 46.98795180722892,
    L: 1.20481927710843,
    XL: 0.40160642570281,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 40,
    total: 191,
    XS: 0.52356020942408,
    S: 25.13089005235602,
    M: 69.63350785340315,
    L: 4.71204188481675,
    XL: 0,
  },
  {
    name: 'Miniature American Shepherd',
    weight: 50,
    total: 62,
    XS: 0,
    S: 16.12903225806452,
    M: 70.96774193548387,
    L: 12.90322580645161,
    XL: 0,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 10,
    total: 86,
    XS: 37.2093023255814,
    S: 52.32558139534884,
    M: 10.46511627906977,
    L: 0,
    XL: 0,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 20,
    total: 313,
    XS: 8.30670926517572,
    S: 65.17571884984027,
    M: 24.92012779552716,
    L: 1.59744408945687,
    XL: 0,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 30,
    total: 365,
    XS: 1.36986301369863,
    S: 40,
    M: 56.16438356164384,
    L: 2.46575342465753,
    XL: 0,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 40,
    total: 224,
    XS: 0.44642857142857,
    S: 20.08928571428571,
    M: 67.85714285714286,
    L: 11.16071428571429,
    XL: 0.44642857142857,
  },
  {
    name: 'Miniature Australian Shepherd',
    weight: 50,
    total: 62,
    XS: 0,
    S: 12.90322580645161,
    M: 66.12903225806453,
    L: 20.96774193548387,
    XL: 0,
  },
  {
    name: 'Miniature Pinscher',
    weight: 10,
    total: 47,
    XS: 57.4468085106383,
    S: 31.91489361702128,
    M: 4.25531914893617,
    L: 4.25531914893617,
    XL: 2.12765957446809,
  },
  {
    name: 'Miniature Pinscher',
    weight: 20,
    total: 91,
    XS: 7.69230769230769,
    S: 76.92307692307692,
    M: 13.18681318681319,
    L: 1.0989010989011,
    XL: 1.0989010989011,
  },
  {
    name: 'Miniature Pinscher',
    weight: 30,
    total: 39,
    XS: 5.12820512820513,
    S: 28.20512820512821,
    M: 64.1025641025641,
    L: 2.56410256410256,
    XL: 0,
  },
  {
    name: 'Miniature Poodle',
    weight: 10,
    total: 122,
    XS: 44.26229508196721,
    S: 41.80327868852459,
    M: 10.65573770491803,
    L: 3.27868852459016,
    XL: 0,
  },
  {
    name: 'Miniature Poodle',
    weight: 20,
    total: 295,
    XS: 13.89830508474576,
    S: 71.86440677966102,
    M: 13.22033898305085,
    L: 0.67796610169492,
    XL: 0.33898305084746,
  },
  {
    name: 'Miniature Poodle',
    weight: 30,
    total: 84,
    XS: 3.57142857142857,
    S: 42.85714285714286,
    M: 51.19047619047619,
    L: 2.38095238095238,
    XL: 0,
  },
  {
    name: 'Miniature Schnauzer',
    weight: 10,
    total: 183,
    XS: 39.34426229508197,
    S: 47.54098360655738,
    M: 12.02185792349727,
    L: 0,
    XL: 1.09289617486339,
  },
  {
    name: 'Miniature Schnauzer',
    weight: 20,
    total: 521,
    XS: 8.25335892514395,
    S: 72.7447216890595,
    M: 18.61804222648752,
    L: 0.38387715930902,
    XL: 0,
  },
  {
    name: 'Miniature Schnauzer',
    weight: 30,
    total: 83,
    XS: 1.20481927710843,
    S: 44.57831325301205,
    M: 49.39759036144578,
    L: 4.81927710843373,
    XL: 0,
  },
  {
    name: 'Morkie',
    weight: 10,
    total: 39,
    XS: 53.84615384615385,
    S: 30.76923076923077,
    M: 12.82051282051282,
    L: 2.56410256410256,
    XL: 0,
  },
  {
    name: 'Morkie',
    weight: 20,
    total: 27,
    XS: 18.51851851851852,
    S: 74.07407407407408,
    M: 7.40740740740741,
    L: 0,
    XL: 0,
  },
  {
    name: 'Mountain Cur',
    weight: 30,
    total: 26,
    XS: 0,
    S: 15.38461538461538,
    M: 80.76923076923077,
    L: 3.84615384615385,
    XL: 0,
  },
  {
    name: 'Mountain Cur',
    weight: 40,
    total: 53,
    XS: 0,
    S: 5.66037735849057,
    M: 67.9245283018868,
    L: 26.41509433962264,
    XL: 0,
  },
  {
    name: 'Mountain Cur',
    weight: 50,
    total: 68,
    XS: 0,
    S: 2.94117647058824,
    M: 55.88235294117647,
    L: 41.17647058823529,
    XL: 0,
  },
  {
    name: 'Mountain Cur',
    weight: 60,
    total: 32,
    XS: 0,
    S: 0,
    M: 31.25,
    L: 65.625,
    XL: 3.125,
  },
  {
    name: 'Newfoundland',
    weight: 90,
    total: 32,
    XS: 0,
    S: 3.125,
    M: 12.5,
    L: 37.5,
    XL: 46.875,
  },
  {
    name: 'Newfoundland',
    weight: 100,
    total: 77,
    XS: 0,
    S: 3.8961038961039,
    M: 18.18181818181818,
    L: 24.67532467532468,
    XL: 53.24675324675325,
  },
  {
    name: 'Newfoundland',
    weight: 110,
    total: 51,
    XS: 0,
    S: 0,
    M: 13.72549019607843,
    L: 23.52941176470588,
    XL: 62.74509803921569,
  },
  {
    name: 'Newfoundland',
    weight: 120,
    total: 36,
    XS: 0,
    S: 2.77777777777778,
    M: 11.11111111111111,
    L: 25,
    XL: 61.11111111111111,
  },
  {
    name: 'Newfoundland',
    weight: 130,
    total: 37,
    XS: 0,
    S: 2.7027027027027,
    M: 10.81081081081081,
    L: 8.10810810810811,
    XL: 78.37837837837839,
  },
  {
    name: 'Norwegian Elkhound',
    weight: 40,
    total: 32,
    XS: 0,
    S: 9.375,
    M: 59.375,
    L: 31.25,
    XL: 0,
  },
  {
    name: 'Norwegian Elkhound',
    weight: 50,
    total: 40,
    XS: 0,
    S: 2.5,
    M: 37.5,
    L: 60,
    XL: 0,
  },
  {
    name: 'Nova Scotia Duck Tolling Retriever',
    weight: 30,
    total: 29,
    XS: 0,
    S: 20.68965517241379,
    M: 75.86206896551724,
    L: 3.44827586206897,
    XL: 0,
  },
  {
    name: 'Nova Scotia Duck Tolling Retriever',
    weight: 40,
    total: 72,
    XS: 1.38888888888889,
    S: 6.94444444444444,
    M: 83.33333333333333,
    L: 8.33333333333333,
    XL: 0,
  },
  {
    name: 'Nova Scotia Duck Tolling Retriever',
    weight: 50,
    total: 50,
    XS: 2,
    S: 2,
    M: 72,
    L: 24,
    XL: 0,
  },
  {
    name: 'Old English Sheepdog',
    weight: 50,
    total: 37,
    XS: 0,
    S: 0,
    M: 43.24324324324324,
    L: 51.35135135135135,
    XL: 5.40540540540541,
  },
  {
    name: 'Old English Sheepdog',
    weight: 60,
    total: 35,
    XS: 0,
    S: 5.71428571428571,
    M: 20,
    L: 68.57142857142857,
    XL: 5.71428571428571,
  },
  {
    name: 'Olde English Bulldogge',
    weight: 70,
    total: 26,
    XS: 0,
    S: 0,
    M: 34.61538461538462,
    L: 42.30769230769231,
    XL: 23.07692307692308,
  },
  {
    name: 'Other',
    weight: 10,
    total: 97,
    XS: 24.74226804123711,
    S: 38.14432989690722,
    M: 19.58762886597938,
    L: 15.4639175257732,
    XL: 2.06185567010309,
  },
  {
    name: 'Other',
    weight: 20,
    total: 133,
    XS: 12.03007518796992,
    S: 52.63157894736842,
    M: 22.55639097744361,
    L: 12.03007518796992,
    XL: 0.75187969924812,
  },
  {
    name: 'Other',
    weight: 30,
    total: 106,
    XS: 1.88679245283019,
    S: 31.13207547169811,
    M: 55.66037735849057,
    L: 10.37735849056604,
    XL: 0.94339622641509,
  },
  {
    name: 'Other',
    weight: 40,
    total: 99,
    XS: 0,
    S: 15.15151515151515,
    M: 62.62626262626263,
    L: 21.21212121212121,
    XL: 1.01010101010101,
  },
  {
    name: 'Other',
    weight: 50,
    total: 105,
    XS: 0.95238095238095,
    S: 11.42857142857143,
    M: 50.47619047619048,
    L: 35.23809523809524,
    XL: 1.9047619047619,
  },
  {
    name: 'Other',
    weight: 60,
    total: 54,
    XS: 0,
    S: 9.25925925925926,
    M: 38.88888888888889,
    L: 48.14814814814815,
    XL: 3.7037037037037,
  },
  {
    name: 'Other',
    weight: 70,
    total: 26,
    XS: 0,
    S: 3.84615384615385,
    M: 26.92307692307692,
    L: 61.53846153846154,
    XL: 7.69230769230769,
  },
  {
    name: 'Parson Russell Terrier',
    weight: 20,
    total: 51,
    XS: 5.88235294117647,
    S: 68.62745098039215,
    M: 25.49019607843137,
    L: 0,
    XL: 0,
  },
  {
    name: 'Pembroke Welsh Corgi',
    weight: 10,
    total: 94,
    XS: 11.70212765957447,
    S: 35.1063829787234,
    M: 48.93617021276596,
    L: 4.25531914893617,
    XL: 0,
  },
  {
    name: 'Pembroke Welsh Corgi',
    weight: 20,
    total: 550,
    XS: 0.90909090909091,
    S: 22.90909090909091,
    M: 73.0909090909091,
    L: 2.90909090909091,
    XL: 0.18181818181818,
  },
  {
    name: 'Pembroke Welsh Corgi',
    weight: 30,
    total: 933,
    XS: 0.42872454448017,
    S: 8.03858520900322,
    M: 85.53054662379421,
    L: 6.0021436227224,
    XL: 0,
  },
  {
    name: 'Pembroke Welsh Corgi',
    weight: 40,
    total: 239,
    XS: 0,
    S: 5.85774058577406,
    M: 77.40585774058577,
    L: 16.31799163179916,
    XL: 0.418410041841,
  },
  {
    name: 'Pit Bull',
    weight: 20,
    total: 35,
    XS: 2.85714285714286,
    S: 28.57142857142857,
    M: 37.14285714285714,
    L: 31.42857142857143,
    XL: 0,
  },
  {
    name: 'Pit Bull',
    weight: 30,
    total: 97,
    XS: 0,
    S: 9.27835051546392,
    M: 52.57731958762887,
    L: 38.14432989690722,
    XL: 0,
  },
  {
    name: 'Pit Bull',
    weight: 40,
    total: 163,
    XS: 0,
    S: 3.06748466257669,
    M: 60.12269938650307,
    L: 36.80981595092025,
    XL: 0,
  },
  {
    name: 'Pit Bull',
    weight: 50,
    total: 311,
    XS: 0.32154340836013,
    S: 2.89389067524116,
    M: 41.80064308681672,
    L: 53.05466237942122,
    XL: 1.92926045016077,
  },
  {
    name: 'Pit Bull',
    weight: 60,
    total: 255,
    XS: 0,
    S: 0.7843137254902,
    M: 29.41176470588235,
    L: 66.66666666666667,
    XL: 3.13725490196078,
  },
  {
    name: 'Pit Bull',
    weight: 70,
    total: 189,
    XS: 0.52910052910053,
    S: 0,
    M: 19.57671957671958,
    L: 75.66137566137566,
    XL: 4.23280423280423,
  },
  {
    name: 'Pit Bull',
    weight: 80,
    total: 106,
    XS: 0,
    S: 0,
    M: 15.09433962264151,
    L: 76.41509433962264,
    XL: 8.49056603773585,
  },
  {
    name: 'Pit Bull',
    weight: 90,
    total: 42,
    XS: 0,
    S: 2.38095238095238,
    M: 16.66666666666667,
    L: 69.04761904761905,
    XL: 11.9047619047619,
  },
  {
    name: 'Pitsky',
    weight: 50,
    total: 43,
    XS: 0,
    S: 4.65116279069767,
    M: 48.83720930232558,
    L: 46.51162790697674,
    XL: 0,
  },
  {
    name: 'Pitsky',
    weight: 60,
    total: 30,
    XS: 0,
    S: 6.66666666666667,
    M: 33.33333333333333,
    L: 60,
    XL: 0,
  },
  {
    name: 'Plott Hound',
    weight: 40,
    total: 47,
    XS: 0,
    S: 4.25531914893617,
    M: 76.59574468085106,
    L: 17.02127659574468,
    XL: 2.12765957446809,
  },
  {
    name: 'Plott Hound',
    weight: 50,
    total: 80,
    XS: 0,
    S: 5,
    M: 46.25,
    L: 47.5,
    XL: 1.25,
  },
  {
    name: 'Plott Hound',
    weight: 60,
    total: 44,
    XS: 0,
    S: 0,
    M: 40.90909090909091,
    L: 59.09090909090909,
    XL: 0,
  },
  {
    name: 'Plott Hound',
    weight: 70,
    total: 46,
    XS: 0,
    S: 2.17391304347826,
    M: 21.73913043478261,
    L: 76.08695652173913,
    XL: 0,
  },
  {
    name: 'Pointer',
    weight: 30,
    total: 28,
    XS: 0,
    S: 25,
    M: 46.42857142857143,
    L: 28.57142857142857,
    XL: 0,
  },
  {
    name: 'Pointer',
    weight: 40,
    total: 50,
    XS: 0,
    S: 10,
    M: 68,
    L: 20,
    XL: 2,
  },
  {
    name: 'Pointer',
    weight: 50,
    total: 88,
    XS: 0,
    S: 3.40909090909091,
    M: 59.09090909090909,
    L: 35.22727272727273,
    XL: 2.27272727272727,
  },
  {
    name: 'Pointer',
    weight: 60,
    total: 51,
    XS: 0,
    S: 0,
    M: 56.86274509803922,
    L: 43.13725490196078,
    XL: 0,
  },
  {
    name: 'Pointer',
    weight: 70,
    total: 31,
    XS: 0,
    S: 0,
    M: 29.03225806451613,
    L: 70.96774193548387,
    XL: 0,
  },
  {
    name: 'Pomeranian',
    weight: 10,
    total: 153,
    XS: 54.2483660130719,
    S: 35.29411764705882,
    M: 6.5359477124183,
    L: 3.26797385620915,
    XL: 0.65359477124183,
  },
  {
    name: 'Pomeranian',
    weight: 20,
    total: 109,
    XS: 19.26605504587156,
    S: 59.63302752293578,
    M: 20.18348623853211,
    L: 0,
    XL: 0.91743119266055,
  },
  {
    name: 'Pomsky',
    weight: 10,
    total: 74,
    XS: 31.08108108108108,
    S: 52.7027027027027,
    M: 14.86486486486486,
    L: 1.35135135135135,
    XL: 0,
  },
  {
    name: 'Pomsky',
    weight: 20,
    total: 277,
    XS: 4.69314079422383,
    S: 57.40072202166065,
    M: 36.10108303249097,
    L: 1.08303249097473,
    XL: 0.72202166064982,
  },
  {
    name: 'Pomsky',
    weight: 30,
    total: 223,
    XS: 0,
    S: 21.52466367713004,
    M: 70.4035874439462,
    L: 7.62331838565022,
    XL: 0.44843049327354,
  },
  {
    name: 'Pomsky',
    weight: 40,
    total: 98,
    XS: 1.02040816326531,
    S: 8.16326530612245,
    M: 77.55102040816327,
    L: 13.26530612244898,
    XL: 0,
  },
  {
    name: 'Pomsky',
    weight: 50,
    total: 27,
    XS: 0,
    S: 7.40740740740741,
    M: 59.25925925925926,
    L: 29.62962962962963,
    XL: 3.7037037037037,
  },
  {
    name: 'Poodle',
    weight: 10,
    total: 81,
    XS: 24.69135802469136,
    S: 54.32098765432099,
    M: 16.04938271604938,
    L: 4.93827160493827,
    XL: 0,
  },
  {
    name: 'Poodle',
    weight: 20,
    total: 267,
    XS: 5.61797752808989,
    S: 55.0561797752809,
    M: 32.58426966292135,
    L: 6.74157303370787,
    XL: 0,
  },
  {
    name: 'Poodle',
    weight: 30,
    total: 243,
    XS: 0.41152263374486,
    S: 20.98765432098765,
    M: 61.31687242798354,
    L: 17.28395061728395,
    XL: 0,
  },
  {
    name: 'Poodle',
    weight: 40,
    total: 347,
    XS: 0,
    S: 6.62824207492795,
    M: 66.28242074927954,
    L: 26.51296829971182,
    XL: 0.57636887608069,
  },
  {
    name: 'Poodle',
    weight: 50,
    total: 548,
    XS: 0,
    S: 6.02189781021898,
    M: 48.54014598540146,
    L: 44.52554744525547,
    XL: 0.91240875912409,
  },
  {
    name: 'Poodle',
    weight: 60,
    total: 328,
    XS: 0,
    S: 2.74390243902439,
    M: 34.45121951219512,
    L: 61.89024390243902,
    XL: 0.91463414634146,
  },
  {
    name: 'Poodle',
    weight: 70,
    total: 187,
    XS: 0,
    S: 2.13903743315508,
    M: 24.06417112299465,
    L: 72.19251336898397,
    XL: 1.60427807486631,
  },
  {
    name: 'Poodle',
    weight: 80,
    total: 65,
    XS: 0,
    S: 1.53846153846154,
    M: 21.53846153846154,
    L: 73.84615384615385,
    XL: 3.07692307692308,
  },
  {
    name: 'Portuguese Water Dog',
    weight: 30,
    total: 26,
    XS: 3.84615384615385,
    S: 7.69230769230769,
    M: 84.61538461538461,
    L: 3.84615384615385,
    XL: 0,
  },
  {
    name: 'Portuguese Water Dog',
    weight: 40,
    total: 72,
    XS: 0,
    S: 5.55555555555556,
    M: 80.55555555555556,
    L: 13.88888888888889,
    XL: 0,
  },
  {
    name: 'Portuguese Water Dog',
    weight: 50,
    total: 73,
    XS: 0,
    S: 1.36986301369863,
    M: 69.86301369863014,
    L: 28.76712328767123,
    XL: 0,
  },
  {
    name: 'Portuguese Water Dog',
    weight: 60,
    total: 39,
    XS: 0,
    S: 2.56410256410256,
    M: 46.15384615384615,
    L: 51.28205128205128,
    XL: 0,
  },
  {
    name: 'Pug',
    weight: 10,
    total: 61,
    XS: 6.55737704918033,
    S: 60.65573770491803,
    M: 22.95081967213115,
    L: 9.83606557377049,
    XL: 0,
  },
  {
    name: 'Pug',
    weight: 20,
    total: 292,
    XS: 3.76712328767123,
    S: 58.9041095890411,
    M: 33.9041095890411,
    L: 3.08219178082192,
    XL: 0.34246575342466,
  },
  {
    name: 'Pug',
    weight: 30,
    total: 117,
    XS: 1.70940170940171,
    S: 40.17094017094017,
    M: 52.13675213675214,
    L: 5.98290598290598,
    XL: 0,
  },
  {
    name: 'Puggle',
    weight: 20,
    total: 33,
    XS: 0,
    S: 51.51515151515152,
    M: 48.48484848484848,
    L: 0,
    XL: 0,
  },
  {
    name: 'Rat Terrier',
    weight: 10,
    total: 36,
    XS: 41.66666666666667,
    S: 47.22222222222222,
    M: 8.33333333333333,
    L: 0,
    XL: 2.77777777777778,
  },
  {
    name: 'Rat Terrier',
    weight: 20,
    total: 130,
    XS: 10,
    S: 62.30769230769231,
    M: 25.38461538461538,
    L: 2.30769230769231,
    XL: 0,
  },
  {
    name: 'Rat Terrier',
    weight: 30,
    total: 76,
    XS: 1.31578947368421,
    S: 30.26315789473684,
    M: 67.10526315789474,
    L: 1.31578947368421,
    XL: 0,
  },
  {
    name: 'Rat Terrier',
    weight: 40,
    total: 26,
    XS: 3.84615384615385,
    S: 7.69230769230769,
    M: 69.23076923076923,
    L: 19.23076923076923,
    XL: 0,
  },
  {
    name: 'Redbone Coonhound',
    weight: 40,
    total: 27,
    XS: 3.7037037037037,
    S: 0,
    M: 62.96296296296296,
    L: 33.33333333333333,
    XL: 0,
  },
  {
    name: 'Redbone Coonhound',
    weight: 50,
    total: 63,
    XS: 0,
    S: 3.17460317460317,
    M: 55.55555555555556,
    L: 41.26984126984127,
    XL: 0,
  },
  {
    name: 'Redbone Coonhound',
    weight: 60,
    total: 60,
    XS: 0,
    S: 3.33333333333333,
    M: 40,
    L: 55,
    XL: 1.66666666666667,
  },
  {
    name: 'Redbone Coonhound',
    weight: 70,
    total: 37,
    XS: 0,
    S: 2.7027027027027,
    M: 35.13513513513514,
    L: 62.16216216216216,
    XL: 0,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 40,
    total: 32,
    XS: 0,
    S: 9.375,
    M: 56.25,
    L: 34.375,
    XL: 0,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 50,
    total: 66,
    XS: 0,
    S: 3.03030303030303,
    M: 43.93939393939394,
    L: 51.51515151515152,
    XL: 1.51515151515152,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 60,
    total: 68,
    XS: 0,
    S: 2.94117647058824,
    M: 30.88235294117647,
    L: 64.70588235294117,
    XL: 1.47058823529412,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 70,
    total: 106,
    XS: 0,
    S: 2.83018867924528,
    M: 23.58490566037736,
    L: 69.81132075471697,
    XL: 3.77358490566038,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 80,
    total: 111,
    XS: 0,
    S: 4.5045045045045,
    M: 25.22522522522523,
    L: 64.86486486486486,
    XL: 5.40540540540541,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 90,
    total: 119,
    XS: 0,
    S: 0.84033613445378,
    M: 22.6890756302521,
    L: 67.22689075630252,
    XL: 9.2436974789916,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 100,
    total: 82,
    XS: 0,
    S: 0,
    M: 18.29268292682927,
    L: 63.41463414634146,
    XL: 18.29268292682927,
  },
  {
    name: 'Rhodesian Ridgeback',
    weight: 110,
    total: 41,
    XS: 0,
    S: 0,
    M: 36.58536585365854,
    L: 43.90243902439024,
    XL: 19.51219512195122,
  },
  {
    name: 'Rottweiler',
    weight: 30,
    total: 60,
    XS: 0,
    S: 11.66666666666667,
    M: 53.33333333333333,
    L: 28.33333333333333,
    XL: 6.66666666666667,
  },
  {
    name: 'Rottweiler',
    weight: 40,
    total: 58,
    XS: 0,
    S: 3.44827586206897,
    M: 58.62068965517241,
    L: 32.75862068965517,
    XL: 5.17241379310345,
  },
  {
    name: 'Rottweiler',
    weight: 50,
    total: 83,
    XS: 0,
    S: 1.20481927710843,
    M: 39.75903614457831,
    L: 50.60240963855422,
    XL: 8.43373493975904,
  },
  {
    name: 'Rottweiler',
    weight: 60,
    total: 111,
    XS: 1.8018018018018,
    S: 1.8018018018018,
    M: 29.72972972972973,
    L: 53.15315315315315,
    XL: 13.51351351351351,
  },
  {
    name: 'Rottweiler',
    weight: 70,
    total: 154,
    XS: 0.64935064935065,
    S: 3.24675324675325,
    M: 18.18181818181818,
    L: 57.79220779220779,
    XL: 20.12987012987013,
  },
  {
    name: 'Rottweiler',
    weight: 80,
    total: 183,
    XS: 0,
    S: 1.09289617486339,
    M: 18.5792349726776,
    L: 52.45901639344262,
    XL: 27.86885245901639,
  },
  {
    name: 'Rottweiler',
    weight: 90,
    total: 161,
    XS: 0,
    S: 2.48447204968944,
    M: 22.36024844720497,
    L: 33.54037267080745,
    XL: 41.61490683229814,
  },
  {
    name: 'Rottweiler',
    weight: 100,
    total: 180,
    XS: 0,
    S: 1.11111111111111,
    M: 19.44444444444444,
    L: 23.33333333333333,
    XL: 56.11111111111111,
  },
  {
    name: 'Rottweiler',
    weight: 110,
    total: 79,
    XS: 0,
    S: 0,
    M: 12.65822784810127,
    L: 26.58227848101266,
    XL: 60.75949367088608,
  },
  {
    name: 'Rottweiler',
    weight: 120,
    total: 64,
    XS: 0,
    S: 3.125,
    M: 17.1875,
    L: 17.1875,
    XL: 62.5,
  },
  {
    name: 'Rottweiler',
    weight: 130,
    total: 35,
    XS: 0,
    S: 0,
    M: 17.14285714285714,
    L: 5.71428571428571,
    XL: 77.14285714285714,
  },
  {
    name: 'Russell Terrier',
    weight: 20,
    total: 29,
    XS: 10.3448275862069,
    S: 72.41379310344828,
    M: 17.24137931034483,
    L: 0,
    XL: 0,
  },
  {
    name: 'Saint Bernard',
    weight: 80,
    total: 34,
    XS: 0,
    S: 0,
    M: 14.70588235294118,
    L: 52.94117647058824,
    XL: 32.35294117647059,
  },
  {
    name: 'Saint Bernard',
    weight: 100,
    total: 29,
    XS: 0,
    S: 0,
    M: 17.24137931034483,
    L: 20.68965517241379,
    XL: 62.06896551724138,
  },
  {
    name: 'Saint Bernard',
    weight: 110,
    total: 27,
    XS: 0,
    S: 0,
    M: 7.40740740740741,
    L: 29.62962962962963,
    XL: 62.96296296296296,
  },
  {
    name: 'Saint Bernard',
    weight: 120,
    total: 35,
    XS: 0,
    S: 2.85714285714286,
    M: 25.71428571428571,
    L: 5.71428571428571,
    XL: 65.71428571428571,
  },
  {
    name: 'Samoyed',
    weight: 30,
    total: 41,
    XS: 0,
    S: 7.31707317073171,
    M: 46.34146341463415,
    L: 41.46341463414634,
    XL: 4.8780487804878,
  },
  {
    name: 'Samoyed',
    weight: 40,
    total: 85,
    XS: 2.35294117647059,
    S: 3.52941176470588,
    M: 40,
    L: 52.94117647058824,
    XL: 1.17647058823529,
  },
  {
    name: 'Samoyed',
    weight: 50,
    total: 143,
    XS: 0,
    S: 2.7972027972028,
    M: 30.76923076923077,
    L: 64.33566433566433,
    XL: 2.0979020979021,
  },
  {
    name: 'Samoyed',
    weight: 60,
    total: 84,
    XS: 0,
    S: 1.19047619047619,
    M: 27.38095238095238,
    L: 65.47619047619048,
    XL: 5.95238095238095,
  },
  {
    name: 'Samoyed',
    weight: 70,
    total: 31,
    XS: 0,
    S: 0,
    M: 19.35483870967742,
    L: 77.41935483870968,
    XL: 3.2258064516129,
  },
  {
    name: 'Schnoodle',
    weight: 20,
    total: 55,
    XS: 7.27272727272727,
    S: 74.54545454545455,
    M: 18.18181818181818,
    L: 0,
    XL: 0,
  },
  {
    name: 'Scottish Terrier',
    weight: 20,
    total: 60,
    XS: 1.66666666666667,
    S: 50,
    M: 48.33333333333333,
    L: 0,
    XL: 0,
  },
  {
    name: 'Scottish Terrier',
    weight: 30,
    total: 31,
    XS: 0,
    S: 29.03225806451613,
    M: 67.74193548387098,
    L: 3.2258064516129,
    XL: 0,
  },
  {
    name: 'Sharberian Husky',
    weight: 50,
    total: 40,
    XS: 0,
    S: 0,
    M: 37.5,
    L: 62.5,
    XL: 0,
  },
  {
    name: 'Sharberian Husky',
    weight: 60,
    total: 29,
    XS: 0,
    S: 0,
    M: 37.93103448275862,
    L: 62.06896551724138,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 20,
    total: 50,
    XS: 8,
    S: 42,
    M: 48,
    L: 2,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 30,
    total: 87,
    XS: 1.14942528735632,
    S: 22.98850574712644,
    M: 65.51724137931033,
    L: 10.3448275862069,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 40,
    total: 65,
    XS: 3.07692307692308,
    S: 10.76923076923077,
    M: 66.15384615384615,
    L: 20,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 50,
    total: 91,
    XS: 0,
    S: 3.2967032967033,
    M: 47.25274725274725,
    L: 48.35164835164835,
    XL: 1.0989010989011,
  },
  {
    name: 'Sheepadoodle',
    weight: 60,
    total: 93,
    XS: 0,
    S: 1.0752688172043,
    M: 39.78494623655914,
    L: 59.13978494623656,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 70,
    total: 78,
    XS: 0,
    S: 5.12820512820513,
    M: 26.92307692307692,
    L: 67.94871794871796,
    XL: 0,
  },
  {
    name: 'Sheepadoodle',
    weight: 80,
    total: 37,
    XS: 0,
    S: 2.7027027027027,
    M: 5.40540540540541,
    L: 86.48648648648648,
    XL: 5.40540540540541,
  },
  {
    name: 'Shetland Sheepdog',
    weight: 20,
    total: 121,
    XS: 4.95867768595041,
    S: 58.67768595041322,
    M: 36.36363636363636,
    L: 0,
    XL: 0,
  },
  {
    name: 'Shetland Sheepdog',
    weight: 30,
    total: 102,
    XS: 0.98039215686275,
    S: 31.37254901960784,
    M: 63.72549019607843,
    L: 3.92156862745098,
    XL: 0,
  },
  {
    name: 'Shetland Sheepdog',
    weight: 40,
    total: 26,
    XS: 0,
    S: 7.69230769230769,
    M: 84.61538461538461,
    L: 7.69230769230769,
    XL: 0,
  },
  {
    name: 'Shiba Inu',
    weight: 10,
    total: 72,
    XS: 12.5,
    S: 54.16666666666667,
    M: 29.16666666666667,
    L: 2.77777777777778,
    XL: 1.38888888888889,
  },
  {
    name: 'Shiba Inu',
    weight: 20,
    total: 562,
    XS: 3.20284697508897,
    S: 39.32384341637011,
    M: 55.16014234875445,
    L: 2.31316725978648,
    XL: 0,
  },
  {
    name: 'Shiba Inu',
    weight: 30,
    total: 625,
    XS: 0.64,
    S: 15.2,
    M: 80,
    L: 4.16,
    XL: 0,
  },
  {
    name: 'Shiba Inu',
    weight: 40,
    total: 117,
    XS: 0,
    S: 7.69230769230769,
    M: 77.77777777777779,
    L: 14.52991452991453,
    XL: 0,
  },
  {
    name: 'Shih Tzu',
    weight: 10,
    total: 201,
    XS: 32.83582089552239,
    S: 51.74129353233831,
    M: 12.93532338308458,
    L: 2.48756218905473,
    XL: 0,
  },
  {
    name: 'Shih Tzu',
    weight: 20,
    total: 325,
    XS: 5.23076923076923,
    S: 71.07692307692308,
    M: 23.07692307692308,
    L: 0.61538461538462,
    XL: 0,
  },
  {
    name: 'Shih Tzu',
    weight: 30,
    total: 28,
    XS: 0,
    S: 46.42857142857143,
    M: 50,
    L: 3.57142857142857,
    XL: 0,
  },
  {
    name: 'Shihpoo',
    weight: 20,
    total: 44,
    XS: 13.63636363636364,
    S: 72.72727272727273,
    M: 11.36363636363636,
    L: 0,
    XL: 2.27272727272727,
  },
  {
    name: 'Siberian Husky',
    weight: 10,
    total: 47,
    XS: 4.25531914893617,
    S: 27.65957446808511,
    M: 40.42553191489362,
    L: 23.40425531914894,
    XL: 4.25531914893617,
  },
  {
    name: 'Siberian Husky',
    weight: 20,
    total: 247,
    XS: 0,
    S: 27.53036437246964,
    M: 41.7004048582996,
    L: 30.76923076923077,
    XL: 0,
  },
  {
    name: 'Siberian Husky',
    weight: 30,
    total: 540,
    XS: 0.55555555555556,
    S: 10.37037037037037,
    M: 53.7037037037037,
    L: 34.44444444444444,
    XL: 0.92592592592593,
  },
  {
    name: 'Siberian Husky',
    weight: 40,
    total: 1226,
    XS: 0.24469820554649,
    S: 3.58890701468189,
    M: 52.28384991843393,
    L: 43.14845024469821,
    XL: 0.73409461663948,
  },
  {
    name: 'Siberian Husky',
    weight: 50,
    total: 2352,
    XS: 0,
    S: 2.63605442176871,
    M: 42.68707482993197,
    L: 54.0391156462585,
    XL: 0.63775510204082,
  },
  {
    name: 'Siberian Husky',
    weight: 60,
    total: 1706,
    XS: 0,
    S: 1.64126611957796,
    M: 30.89097303634232,
    L: 65.53341148886284,
    XL: 1.93434935521688,
  },
  {
    name: 'Siberian Husky',
    weight: 70,
    total: 775,
    XS: 0,
    S: 1.29032258064516,
    M: 25.29032258064516,
    L: 69.41935483870968,
    XL: 4,
  },
  {
    name: 'Siberian Husky',
    weight: 80,
    total: 284,
    XS: 0,
    S: 0.70422535211268,
    M: 23.23943661971831,
    L: 69.36619718309859,
    XL: 6.69014084507042,
  },
  {
    name: 'Siberian Husky',
    weight: 90,
    total: 99,
    XS: 0,
    S: 0,
    M: 19.19191919191919,
    L: 63.63636363636364,
    XL: 17.17171717171717,
  },
  {
    name: 'Siberian Husky',
    weight: 100,
    total: 29,
    XS: 0,
    S: 3.44827586206897,
    M: 20.68965517241379,
    L: 55.17241379310345,
    XL: 20.68965517241379,
  },
  {
    name: 'Soft Coated Wheaten Terrier',
    weight: 30,
    total: 78,
    XS: 0,
    S: 16.66666666666667,
    M: 74.35897435897436,
    L: 8.97435897435897,
    XL: 0,
  },
  {
    name: 'Soft Coated Wheaten Terrier',
    weight: 40,
    total: 91,
    XS: 0,
    S: 3.2967032967033,
    M: 83.51648351648352,
    L: 13.18681318681319,
    XL: 0,
  },
  {
    name: 'Soft Coated Wheaten Terrier',
    weight: 50,
    total: 26,
    XS: 0,
    S: 0,
    M: 76.92307692307692,
    L: 23.07692307692308,
    XL: 0,
  },
  {
    name: 'Spanish Greyhound',
    weight: 50,
    total: 32,
    XS: 0,
    S: 15.625,
    M: 56.25,
    L: 28.125,
    XL: 0,
  },
  {
    name: 'Staffordshire Bull Terrier',
    weight: 40,
    total: 56,
    XS: 1.78571428571429,
    S: 5.35714285714286,
    M: 58.92857142857143,
    L: 30.35714285714286,
    XL: 3.57142857142857,
  },
  {
    name: 'Staffordshire Bull Terrier',
    weight: 50,
    total: 38,
    XS: 0,
    S: 0,
    M: 50,
    L: 50,
    XL: 0,
  },
  {
    name: 'Standard Schnauzer',
    weight: 30,
    total: 31,
    XS: 0,
    S: 22.58064516129032,
    M: 67.74193548387098,
    L: 9.67741935483871,
    XL: 0,
  },
  {
    name: 'Standard Schnauzer',
    weight: 40,
    total: 39,
    XS: 0,
    S: 7.69230769230769,
    M: 87.17948717948718,
    L: 5.12820512820513,
    XL: 0,
  },
  {
    name: 'Taiwan Dog',
    weight: 40,
    total: 28,
    XS: 0,
    S: 10.71428571428571,
    M: 78.57142857142857,
    L: 10.71428571428571,
    XL: 0,
  },
  {
    name: 'Texas Heeler',
    weight: 20,
    total: 33,
    XS: 3.03030303030303,
    S: 39.39393939393939,
    M: 48.48484848484848,
    L: 9.09090909090909,
    XL: 0,
  },
  {
    name: 'Texas Heeler',
    weight: 30,
    total: 52,
    XS: 0,
    S: 13.46153846153846,
    M: 65.38461538461539,
    L: 15.38461538461538,
    XL: 5.76923076923077,
  },
  {
    name: 'Texas Heeler',
    weight: 40,
    total: 83,
    XS: 0,
    S: 7.2289156626506,
    M: 75.90361445783132,
    L: 14.4578313253012,
    XL: 2.40963855421687,
  },
  {
    name: 'Texas Heeler',
    weight: 50,
    total: 52,
    XS: 0,
    S: 3.84615384615385,
    M: 65.38461538461539,
    L: 30.76923076923077,
    XL: 0,
  },
  {
    name: 'Toy Poodle',
    weight: 10,
    total: 130,
    XS: 63.84615384615385,
    S: 27.69230769230769,
    M: 6.15384615384615,
    L: 2.30769230769231,
    XL: 0,
  },
  {
    name: 'Toy Poodle',
    weight: 20,
    total: 70,
    XS: 21.42857142857143,
    S: 64.28571428571429,
    M: 11.42857142857143,
    L: 2.85714285714286,
    XL: 0,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 40,
    total: 68,
    XS: 0,
    S: 5.88235294117647,
    M: 76.47058823529412,
    L: 17.64705882352941,
    XL: 0,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 50,
    total: 115,
    XS: 0,
    S: 1.73913043478261,
    M: 60,
    L: 38.26086956521739,
    XL: 0,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 60,
    total: 105,
    XS: 0,
    S: 0.95238095238095,
    M: 46.66666666666667,
    L: 51.42857142857143,
    XL: 0.95238095238095,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 70,
    total: 72,
    XS: 0,
    S: 2.77777777777778,
    M: 33.33333333333333,
    L: 62.5,
    XL: 1.38888888888889,
  },
  {
    name: 'Treeing Walker Coonhound',
    weight: 80,
    total: 33,
    XS: 0,
    S: 3.03030303030303,
    M: 27.27272727272727,
    L: 63.63636363636364,
    XL: 6.06060606060606,
  },
  {
    name: 'Vizsla',
    weight: 20,
    total: 83,
    XS: 1.20481927710843,
    S: 34.93975903614458,
    M: 55.42168674698795,
    L: 7.2289156626506,
    XL: 1.20481927710843,
  },
  {
    name: 'Vizsla',
    weight: 30,
    total: 161,
    XS: 1.24223602484472,
    S: 18.63354037267081,
    M: 69.56521739130434,
    L: 10.55900621118012,
    XL: 0,
  },
  {
    name: 'Vizsla',
    weight: 40,
    total: 386,
    XS: 0.51813471502591,
    S: 7.51295336787565,
    M: 77.2020725388601,
    L: 14.76683937823834,
    XL: 0,
  },
  {
    name: 'Vizsla',
    weight: 50,
    total: 566,
    XS: 0.17667844522968,
    S: 3.71024734982332,
    M: 71.20141342756185,
    L: 24.73498233215548,
    XL: 0.17667844522968,
  },
  {
    name: 'Vizsla',
    weight: 60,
    total: 331,
    XS: 0,
    S: 3.02114803625378,
    M: 55.28700906344411,
    L: 41.69184290030211,
    XL: 0,
  },
  {
    name: 'Vizsla',
    weight: 70,
    total: 100,
    XS: 0,
    S: 2,
    M: 39,
    L: 59,
    XL: 0,
  },
  {
    name: 'Weimaraner',
    weight: 30,
    total: 30,
    XS: 10,
    S: 20,
    M: 50,
    L: 20,
    XL: 0,
  },
  {
    name: 'Weimaraner',
    weight: 40,
    total: 45,
    XS: 2.22222222222222,
    S: 15.55555555555556,
    M: 53.33333333333333,
    L: 28.88888888888889,
    XL: 0,
  },
  {
    name: 'Weimaraner',
    weight: 50,
    total: 99,
    XS: 0,
    S: 4.04040404040404,
    M: 56.56565656565657,
    L: 39.39393939393939,
    XL: 0,
  },
  {
    name: 'Weimaraner',
    weight: 60,
    total: 122,
    XS: 0,
    S: 1.63934426229508,
    M: 50,
    L: 48.36065573770492,
    XL: 0,
  },
  {
    name: 'Weimaraner',
    weight: 70,
    total: 124,
    XS: 0,
    S: 4.03225806451613,
    M: 29.03225806451613,
    L: 66.12903225806453,
    XL: 0.80645161290323,
  },
  {
    name: 'Weimaraner',
    weight: 80,
    total: 97,
    XS: 0,
    S: 2.06185567010309,
    M: 29.89690721649485,
    L: 67.01030927835052,
    XL: 1.03092783505155,
  },
  {
    name: 'Weimaraner',
    weight: 90,
    total: 29,
    XS: 0,
    S: 0,
    M: 34.48275862068966,
    L: 65.51724137931033,
    XL: 0,
  },
  {
    name: 'Welsh Terrier',
    weight: 20,
    total: 31,
    XS: 3.2258064516129,
    S: 64.51612903225806,
    M: 32.25806451612903,
    L: 0,
    XL: 0,
  },
  {
    name: 'West Highland White Terrier',
    weight: 10,
    total: 28,
    XS: 17.85714285714286,
    S: 64.28571428571429,
    M: 17.85714285714286,
    L: 0,
    XL: 0,
  },
  {
    name: 'West Highland White Terrier',
    weight: 20,
    total: 142,
    XS: 3.52112676056338,
    S: 68.30985915492958,
    M: 26.05633802816901,
    L: 2.11267605633803,
    XL: 0,
  },
  {
    name: 'Whippet',
    weight: 20,
    total: 36,
    XS: 11.11111111111111,
    S: 69.44444444444444,
    M: 16.66666666666667,
    L: 2.77777777777778,
    XL: 0,
  },
  {
    name: 'Whippet',
    weight: 30,
    total: 128,
    XS: 5.46875,
    S: 47.65625,
    M: 42.96875,
    L: 3.125,
    XL: 0.78125,
  },
  {
    name: 'Whippet',
    weight: 40,
    total: 132,
    XS: 0.75757575757576,
    S: 31.06060606060606,
    M: 62.87878787878788,
    L: 5.3030303030303,
    XL: 0,
  },
  {
    name: 'Whippet',
    weight: 50,
    total: 42,
    XS: 0,
    S: 11.9047619047619,
    M: 71.42857142857143,
    L: 16.66666666666667,
    XL: 0,
  },
  {
    name: 'Wire Fox Terrier',
    weight: 20,
    total: 51,
    XS: 5.88235294117647,
    S: 70.58823529411765,
    M: 23.52941176470588,
    L: 0,
    XL: 0,
  },
  {
    name: 'Wirehaired Pointing Griffon',
    weight: 50,
    total: 66,
    XS: 0,
    S: 4.54545454545455,
    M: 72.72727272727273,
    L: 22.72727272727273,
    XL: 0,
  },
  {
    name: 'Wirehaired Pointing Griffon',
    weight: 60,
    total: 77,
    XS: 0,
    S: 5.19480519480519,
    M: 41.55844155844156,
    L: 51.94805194805195,
    XL: 1.2987012987013,
  },
  {
    name: 'Wirehaired Pointing Griffon',
    weight: 70,
    total: 41,
    XS: 0,
    S: 4.8780487804878,
    M: 19.51219512195122,
    L: 73.17073170731707,
    XL: 2.4390243902439,
  },
  {
    name: 'Wolfdog',
    weight: 80,
    total: 26,
    XS: 3.84615384615385,
    S: 3.84615384615385,
    M: 23.07692307692308,
    L: 61.53846153846154,
    XL: 7.69230769230769,
  },
  {
    name: 'Yorkipoo',
    weight: 10,
    total: 39,
    XS: 82.05128205128204,
    S: 10.25641025641026,
    M: 5.12820512820513,
    L: 2.56410256410256,
    XL: 0,
  },
  {
    name: 'Yorkshire Terrier',
    weight: 10,
    total: 246,
    XS: 63.82113821138211,
    S: 30.08130081300813,
    M: 5.28455284552846,
    L: 0,
    XL: 0.8130081300813,
  },
  {
    name: 'Yorkshire Terrier',
    weight: 20,
    total: 90,
    XS: 12.22222222222222,
    S: 74.44444444444444,
    M: 11.11111111111111,
    L: 2.22222222222222,
    XL: 0,
  },
];

export interface GenericSizingGuideInterface {
  [weight: string]: SizingGuideItem;
}

export const GenericAdultsSizingGuide: GenericSizingGuideInterface = {
  '10': {
    total: 10995,
    XS: 35.90723055934516,
    S: 43.81082310140973,
    M: 14.87039563437926,
    L: 4.58390177353342,
    XL: 0.82764893133242,
  },
  '20': {
    total: 41414,
    XS: 6.04384990582895,
    S: 57.13768290916115,
    M: 32.50108658907616,
    L: 3.87791568068769,
    XL: 0.43946491524605,
  },
  '30': {
    total: 42833,
    XS: 0.63969369411435,
    S: 20.12933952793407,
    M: 68.53127261690751,
    L: 10.16972894730698,
    XL: 0.52996521373707,
  },
  '40': {
    total: 44918,
    XS: 0.20259138875284,
    S: 5.18945634266886,
    M: 72.18709648693174,
    L: 21.75074580346409,
    XL: 0.67010997818247,
  },
  '50': {
    total: 63168,
    XS: 0.13931104356636,
    S: 2.07225177304965,
    M: 54.78406788247214,
    L: 41.90254559270517,
    XL: 1.10182370820669,
  },
  '60': {
    total: 59037,
    XS: 0.09654962142385,
    S: 1.46179514541728,
    M: 35.52517912495554,
    L: 60.86691396920575,
    XL: 2.04956213899758,
  },
  '70': {
    total: 51861,
    XS: 0.09448333044099,
    S: 1.08366595322111,
    M: 25.05350841672933,
    L: 69.67470739091031,
    XL: 4.09363490869825,
  },
  '80': {
    total: 37154,
    XS: 0.07536200678258,
    S: 0.83974807557733,
    M: 19.63718576734672,
    L: 70.55498734994886,
    XL: 8.89271680034451,
  },
  '90': {
    total: 21053,
    XS: 0.10924808815846,
    S: 0.69823778083884,
    M: 17.74093953355816,
    L: 63.79138365078611,
    XL: 17.66019094665843,
  },
  '100': {
    total: 12897,
    XS: 0.10079863534155,
    S: 0.76762037683182,
    M: 16.4611925253935,
    L: 51.21346049468869,
    XL: 31.45692796774444,
  },
  '110': {
    total: 5246,
    XS: 0.13343499809379,
    S: 0.70529927563858,
    M: 15.30690049561571,
    L: 38.81052230270682,
    XL: 45.0438429279451,
  },
  '120': {
    total: 3435,
    XS: 0.14556040756914,
    S: 0.84425036390102,
    M: 14.55604075691412,
    L: 28.5589519650655,
    XL: 55.89519650655022,
  },
  '130': {
    total: 2212,
    XS: 0.18083182640145,
    S: 0.85895117540687,
    M: 15.50632911392405,
    L: 18.17359855334539,
    XL: 65.28028933092224,
  },
  '140': {
    total: 1232,
    XS: 0.16233766233766,
    S: 0.97402597402597,
    M: 16.31493506493506,
    L: 14.77272727272727,
    XL: 67.77597402597404,
  },
  '150': {
    total: 1019,
    XS: 0.29440628066732,
    S: 1.27576054955839,
    M: 18.05691854759568,
    L: 15.21099116781158,
    XL: 65.16192345436703,
  },
  '160': {
    total: 509,
    XS: 0.39292730844794,
    S: 0.58939096267191,
    M: 15.12770137524558,
    L: 20.82514734774067,
    XL: 63.06483300589391,
  },
  '170': {
    total: 347,
    XS: 0,
    S: 0.57636887608069,
    M: 20.17291066282421,
    L: 29.68299711815562,
    XL: 49.56772334293948,
  },
  '180': {
    total: 263,
    XS: 0,
    S: 1.52091254752852,
    M: 15.5893536121673,
    L: 31.55893536121673,
    XL: 51.33079847908745,
  },
  '190': {
    total: 134,
    XS: 0.74626865671642,
    S: 1.49253731343284,
    M: 11.94029850746269,
    L: 26.86567164179104,
    XL: 58.95522388059701,
  },
  '200': {
    total: 99,
    XS: 7.07070707070707,
    S: 4.04040404040404,
    M: 17.17171717171717,
    L: 13.13131313131313,
    XL: 58.58585858585859,
  },
};

export const GenericPuppySizingGuide: GenericSizingGuideInterface = {
  '10': {
    total: 6023,
    XS: 32.19325917316952,
    S: 43.21766561514196,
    M: 17.98107255520505,
    L: 5.66163041673585,
    XL: 0.94637223974763,
  },
  '20': {
    total: 18305,
    XS: 5.74160065555859,
    S: 52.4938541382136,
    M: 35.11062551215515,
    L: 6.03113903305108,
    XL: 0.62278066102158,
  },
  '30': {
    total: 19854,
    XS: 0.88647124005238,
    S: 21.5724790974111,
    M: 63.47335549511433,
    L: 13.29706860078574,
    XL: 0.77062556663645,
  },
  '40': {
    total: 20339,
    XS: 0.30483307930577,
    S: 7.29632725306062,
    M: 67.11736073553271,
    L: 24.30306308078077,
    XL: 0.97841585132012,
  },
  '50': {
    total: 25176,
    XS: 0.1866857324436,
    S: 3.52319669526533,
    M: 51.7318080711789,
    L: 43.19192882109946,
    XL: 1.36638068001271,
  },
  '60': {
    total: 21035,
    XS: 0.12360351794628,
    S: 2.57190396957452,
    M: 35.01307344901355,
    L: 59.83836463037794,
    XL: 2.45305443308771,
  },
  '70': {
    total: 16759,
    XS: 0.11933886270064,
    S: 2.03472760904589,
    M: 25.16856614356465,
    L: 67.67110209439704,
    XL: 5.00626529029178,
  },
  '80': {
    total: 11291,
    XS: 0.07085289168364,
    S: 1.62075989726331,
    M: 20.46762908511204,
    L: 67.69993800371978,
    XL: 10.14082012222124,
  },
  '90': {
    total: 5964,
    XS: 0.06706908115359,
    S: 1.25754527162978,
    M: 19.13145539906103,
    L: 60.2280348759222,
    XL: 19.3158953722334,
  },
  '100': {
    total: 3719,
    XS: 0.02688894864211,
    S: 1.58644796988438,
    M: 17.55848346329659,
    L: 46.62543694541543,
    XL: 34.2027426727615,
  },
  '110': {
    total: 1503,
    XS: 0.06653359946773,
    S: 1.26413838988689,
    M: 16.36726546906188,
    L: 35.46240851630073,
    XL: 46.83965402528277,
  },
  '120': {
    total: 987,
    XS: 0.10131712259372,
    S: 1.31712259371834,
    M: 16.61600810536981,
    L: 25.63323201621074,
    XL: 56.3323201621074,
  },
  '130': {
    total: 648,
    XS: 0,
    S: 1.08024691358025,
    M: 18.51851851851852,
    L: 16.82098765432099,
    XL: 63.58024691358025,
  },
  '140': {
    total: 343,
    XS: 0,
    S: 0.58309037900875,
    M: 16.61807580174927,
    L: 15.16034985422741,
    XL: 67.63848396501459,
  },
  '150': {
    total: 297,
    XS: 0,
    S: 2.35690235690236,
    M: 17.84511784511785,
    L: 15.15151515151515,
    XL: 64.64646464646465,
  },
  '160': {
    total: 138,
    XS: 0.72463768115942,
    S: 2.17391304347826,
    M: 13.04347826086957,
    L: 23.18840579710145,
    XL: 60.8695652173913,
  },
  '170': {
    total: 89,
    XS: 0,
    S: 0,
    M: 23.59550561797753,
    L: 26.96629213483146,
    XL: 49.43820224719101,
  },
  '180': {
    total: 63,
    XS: 0,
    S: 1.58730158730159,
    M: 15.87301587301587,
    L: 26.98412698412698,
    XL: 55.55555555555556,
  },
  '190': {
    total: 27,
    XS: 0,
    S: 0,
    M: 14.81481481481481,
    L: 25.92592592592593,
    XL: 59.25925925925926,
  },
  '200': {
    total: 30,
    XS: 6.66666666666667,
    S: 3.33333333333333,
    M: 13.33333333333333,
    L: 10,
    XL: 66.66666666666667,
  },
};
