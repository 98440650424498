import React from 'react';

import styles from './ProductSummary.module.scss';
import { Image as FiImage } from '../../../../../components/Image';
import ValuePropsBox from '../ValuePropsBox';
import { ReactComponent as IconKit } from '../../../../../assets/images/icons/icon_kit.svg';
import { ReactComponent as IconBase } from '../../../../../assets/images/icons/icon_base.svg';
import { ReactComponent as IconBatteryCharging } from '../../../../../assets/images/icons/icon_battery_charging.svg';
import { ReactComponent as IconLte } from '../../../../../assets/images/icons/icon_lte.svg';
import { ReactComponent as IconDrip } from '../../../../../assets/images/icons/icon_drip.svg';
import { ReactComponent as IconReinforced } from '../../../../../assets/images/icons/icon_reinforced.svg';
import { ReactComponent as IconFeather } from '../../../../../assets/images/icons/icon_feather.svg';
import { ReactComponent as IconBroadcast } from '../../../../../assets/images/icons/icon_broadcast.svg';
import { ReactComponent as IconStats } from '../../../../../assets/images/icons/icon_stats.svg';
import { ReactComponent as IconVets } from '../../../../../assets/images/icons/icon_vets.svg';
import { ReactComponent as IconRank } from '../../../../../assets/images/icons/icon_rank.svg';
import { ReactComponent as IconUpdate } from '../../../../../assets/images/icons/icon_update.svg';
import { showNanoVariant } from '../../../../../components/NanoBanner/NanoBanner';

export default function ProductSummary() {
  const hideMobileAppSection = showNanoVariant();
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Product details</h1>
      <div className={styles.hardwareImage}>
        <FiImage
          image={{
            type: 'image',
            url: '/product_images/smart-collar-v3/unboxing.jpg',
            descriptors: ['1500w', '3000w', '4000w'],
          }}
        />
      </div>
      <h2 className={styles.subHeading}>Collar specifications</h2>
      <div className={styles.valueProps}>
        <ValuePropsBox
          valueProps={[
            {
              icon: <IconKit />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>All-New Fi Series 3</span> — device + collar included
                    for free.
                  </span>
                </div>
              ),
            },
            {
              icon: <IconBase />,
              text: (
                <div className={styles.valueProp}>
                  <div className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>Charging base</span> with USB cable.
                  </div>
                </div>
              ),
            },
            {
              icon: <IconBatteryCharging />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>Long battery life</span> with up to 3 months from one
                    charge.
                  </span>
                </div>
              ),
            },
            {
              icon: <IconLte />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>Unlimited GPS + LTE-M coverage.</span> Track in most
                    areas and on hikes.
                  </span>
                </div>
              ),
            },
            {
              icon: <IconDrip />,
              text: (
                <div className={styles.valueProp}>
                  <div className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>Let them swim</span> with IP68 and IP66k water
                    resistance.
                  </div>
                </div>
              ),
            },
            {
              icon: <IconReinforced />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>400lbs+ resistance.</span> Handles the worst chewers
                    and pullers.
                  </span>
                </div>
              ),
            },
            {
              icon: <IconFeather />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    <span className={styles.valuePropHeaderBold}>Light, low-profile</span> and comfortably designed for
                    small to large dogs.
                  </span>
                </div>
              ),
            },
          ]}
        />
      </div>
      {!hideMobileAppSection && (
        <>
          <div className={styles.mobileAppImage}>
            <FiImage
              image={{
                type: 'image',
                url: '/product_images/smart-collar-v3/app-ui-homescreen.jpg',
                descriptors: ['1000w'],
              }}
            />
          </div>
          <h2 className={styles.subHeading}>Fi mobile app</h2>
          <div className={styles.valueProps}>
            <ValuePropsBox
              valueProps={[
                {
                  icon: <IconBroadcast />,
                  text: (
                    <div className={styles.valueProp}>
                      <span className={styles.valuePropHeader}>
                        <span className={styles.valuePropHeaderBold}>24/7 access to your dog</span> with Instant Escape
                        Alerts and Live GPS tracking.
                      </span>
                    </div>
                  ),
                },
                {
                  icon: <IconStats />,
                  text: (
                    <div className={styles.valueProp}>
                      <div className={styles.valuePropHeader}>
                        <span className={styles.valuePropHeaderBold}>Keep them active and healthy</span> with daily step
                        goals and sleep tracking.
                      </div>
                    </div>
                  ),
                },
                {
                  icon: <IconVets />,
                  text: (
                    <div className={styles.valueProp}>
                      <span className={styles.valuePropHeader}>
                        <span className={styles.valuePropHeaderBold}>Connect your Veterinarian information</span> to
                        share your dog's health over time.
                      </span>
                    </div>
                  ),
                },
                {
                  icon: <IconRank />,
                  text: (
                    <div className={styles.valueProp}>
                      <div className={styles.valuePropHeader}>
                        <span className={styles.valuePropHeaderBold}>Share your walks, celebrate milestones</span> with
                        the Fi community.
                      </div>
                    </div>
                  ),
                },
                {
                  icon: <IconUpdate />,
                  text: (
                    <div className={styles.valueProp}>
                      <span className={styles.valuePropHeader}>
                        <span className={styles.valuePropHeaderBold}>Unlimited software updates</span> on iOS and
                        Android.
                      </span>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
}
