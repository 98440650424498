import classNames from 'classnames';
import React, { useRef } from 'react';
import Popup from 'reactjs-popup';
import { ReactComponent as CloseIcon } from '../../assets/images/close-button.svg';
import styles from './modals.module.scss';

interface ISimpleDismissableModalProps {
  trigger: JSX.Element;
  children: string | JSX.Element;
  onOpen?(): void;
  onClose?(): void;
}

export default function SimpleDismissableModal({ trigger, children, onOpen, onClose }: ISimpleDismissableModalProps) {
  const bodyRef = useRef<HTMLDivElement>(null);

  // Use static class name for Popup instead of css module because that component munges the class name internally
  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      onOpen={() => {
        if (onOpen) {
          onOpen();
        }

        // Hack (nick): When the modal is opened, if the body content is long enough to have overflow, it's
        // automatically scrolled to the bottom. I have no idea why. This hack scrolls back to the top.
        if (bodyRef.current) {
          bodyRef.current.scrollTop = 0;
        }
      }}
      onClose={onClose}
      modal
      closeOnDocumentClick={true}
    >
      {(close: () => void) => (
        <div className={classNames(styles.container, styles.dismissable)}>
          <div className={classNames('s3-popup-close-icon', styles.closeIcon)} onClick={close}>
            <CloseIcon />
          </div>
          <div className={classNames('s3-popup-body', styles.body)} ref={bodyRef}>
            {React.isValidElement(children)
              ? React.cloneElement(children as React.ReactElement<any>, { close })
              : { children }}
          </div>
        </div>
      )}
    </Popup>
  );
}
