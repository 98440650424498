import React from 'react';
import { Image } from '../../../../components/Image';
import TrackedExternalLink from '../../../../components/TrackedExternalLink/TrackedExternalLink';
import * as events from '../../../../lib/analytics/events';
import { IImage } from '../../../../types';
import styles from './MakerTile.module.scss';

export interface MakerItem {
  title: string;
  description: string;
  link: string;
  image: IImage;
}

const MakerTile = ({ description, image, link, title }: MakerItem) => {
  return (
    <div className={styles.accessoriesModuleItemContainer + ' ' + styles.maker}>
      <TrackedExternalLink
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        {...events.accessories.fiMakerClick(title)}
      >
        <div className={styles.accessoriesModuleItem}>
          <div className={styles.accessoriesModuleItemImage}>
            <Image image={image} alt="A Fi maker product" />
          </div>
          <img
            className={styles.makerExtLink}
            width="48px"
            height="48px"
            src="/ext_link_black.svg"
            alt="Links to an external site"
          />
          <div className={styles.accessoriesModuleItemDescription}>
            <h5>{title}</h5>
            <p>{description}</p>
          </div>
        </div>
      </TrackedExternalLink>
    </div>
  );
};

export default MakerTile;
