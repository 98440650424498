import React from 'react';
import styles from './PurchaseSubscriptionBlocker.module.scss';
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg';

export default function PurchaseSubscriptionBlocker() {
  return (
    <div className={styles.main}>
      <div className={styles.avatar}></div>

      <div className={styles.content}>
        <h2>Join the pack</h2>
        <p>Before activating your Fi device, select a payment plan that best fits your needs.</p>
        <h3>Included with each plan</h3>
        <ul>
          <li>
            <CheckIcon className={styles.check} />
            GPS / LTE location tracking
          </li>
          <li>
            <CheckIcon className={styles.check} />
            Escape detection & dog walker tracking
          </li>
          <li>
            <CheckIcon className={styles.check} />
            Steps, sleep and strain monitoring
          </li>
          <li>
            <CheckIcon className={styles.check} />
            Health benchmarks and goals
          </li>
          <li>
            <CheckIcon className={styles.check} />
            Fi community access
          </li>
        </ul>
      </div>
    </div>
  );
}
