import React from 'react';
import { Switch } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import { CartModeProvider, CartModes } from '../../lib/cartModes';
import { PurchaseSubscriptionContextProvider } from './context/PurchaseSubscriptionContext';
import PurchaseSubscriptionCheckout from './PurchaseSubscriptionCheckout/PurchaseSubscriptionCheckout';
import PurchaseSubscriptionHome from './PurchaseSubscriptionHome/PurchaseSubscriptionHome';
import PurchaseSubscriptionPayment from './PurchaseSubscriptionPayment/PurchaseSubscriptionPayment';
import PurchaseSubscriptionComplete from './PurchaseSubscriptionComplete/PurchaseSubscriptionComplete';

export default function PurchaseSubscriptionRoutes() {
  return (
    <PurchaseSubscriptionContextProvider>
      <CartModeProvider mode={CartModes.purchaseSubscription}>
        <Switch>
          <SentryRoute
            exact
            path={AppPaths.PurchaseSubscription.PlanSelection()}
            component={PurchaseSubscriptionHome}
          />
          <SentryRoute exact path={AppPaths.PurchaseSubscription.Checkout()} component={PurchaseSubscriptionCheckout} />
          <SentryRoute exact path={AppPaths.PurchaseSubscription.Payment()} component={PurchaseSubscriptionPayment} />
          <SentryRoute exact path={AppPaths.PurchaseSubscription.Complete()} component={PurchaseSubscriptionComplete} />
        </Switch>
      </CartModeProvider>
    </PurchaseSubscriptionContextProvider>
  );
}
