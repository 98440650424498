import React, { useContext, useEffect, useState } from 'react';
import ErrorMessage, { useError } from '../components/ErrorMessage';
import Loading from '../components/Loading';
import * as types from '../types';

const CartPricingContext = React.createContext<types.CartPricing | undefined>(undefined);
export default CartPricingContext;

interface CartPricingProviderProps {
  children: React.ReactNode;
  fetchPricing: () => Promise<types.CartPricing | undefined>;
  trackError?: (errorMessage: string) => void;
}

export function CartPricingProvider({ children, fetchPricing, trackError }: CartPricingProviderProps) {
  const [cartPricing, setCartPricing] = useState<types.CartPricing | undefined>(undefined);
  const { error, errorID, setError, clearError } = useError();

  useEffect(() => {
    fetchPricing()
      .then((fetchedPricing) => {
        setCartPricing(fetchedPricing);
        clearError();
      })
      .catch((err) => setError(err.message));
  }, [fetchPricing, setError, clearError]);

  if (error) {
    return <ErrorMessage errors={[error]} trackError={trackError} errorID={errorID} />;
  }

  if (!cartPricing) {
    return <Loading />;
  }

  return <CartPricingContext.Provider value={cartPricing}>{children}</CartPricingContext.Provider>;
}

export function useCartPricing(): types.CartPricing {
  const cartPricing = useContext(CartPricingContext);
  if (!cartPricing) {
    throw new Error('useCartPricing must be used within a CartPricingProvider');
  }

  return cartPricing;
}
