import React, { useState } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import { TextArea } from '../../../../components/TextArea/TextArea';

import * as gqlTypes from '../../../../types/gql-op-types';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';
import { useHistory, useLocation } from 'react-router-dom';
import { BaseInAppCancellationState, SubscriptionCategory } from '../util/types';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../types';
import AppPaths from '../../../../AppPaths';
import ErrorScreen from '../../components/ErrorScreen/ErrorScreen';

export default function CancelOtherReason() {
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const [otherText, setOtherText] = useState('');
  const location = useLocation<BaseInAppCancellationState>();
  const { subscriptionCategory } = location.state;
  const showMonthToMonthSpecialOffersFlag = useSelector(
    (state: AppState) => state.config.siteConfig.showMonthToMonthSpecialOffers ?? false,
  );

  const { mutation, loading } = useZendeskCancellationTicket({
    analyticsEvent: events.cancellationReasonOtherSelectedAndClicked,
    selectedCancelReason: gqlTypes.zendeskCancellationReason.OTHER,
    device,
    subscriptionCategory,
    otherText,
  });

  if (loading) {
    return loading;
  }

  if (!device.subscription?.id) {
    return <ErrorScreen />;
  }

  const handleSpecialOffers = () => {
    history.push({
      pathname: AppPaths.Subscription.SpecialOffers(device.moduleId),
      state: {
        selectedCancelReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE,
        subscriptionCategory,
        otherText,
      },
      search: window.location.search,
    });
  };

  const handleCancel = () => {
    if (!otherText || !device.subscription?.id) {
      throw new Error('Something went wrong.');
    }
    mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription.id,
          cancellationReason: gqlTypes.zendeskCancellationReason.OTHER,
          additionalText: otherText,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() =>
            events.cancellationReasonOtherBack({ petId: device.pet?.id, subscriptionCategory })
          }
          closeButtonAnalyticsEvent={() => events.closeFromReasonPage({ petId: device.pet?.id, subscriptionCategory })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Let us know what happened</h1>
          <div className={styles.content}>
            <p>
              Can you please describe your issue? We’ll have a Customer Success Agent contact you to discuss next steps.
            </p>
            <div className={styles.textArea}>
              <TextArea
                onChange={(value) => {
                  value && setOtherText(value);
                }}
                rows={12}
                placeholderText="Type your response here..."
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {/* If we're going to route to special offers page next, don't surface cancel confirmation modal */}
          {subscriptionCategory === SubscriptionCategory.MONTHLY && showMonthToMonthSpecialOffersFlag ? (
            <Button
              className={styles.button}
              disabled={otherText === null || otherText.length === 0}
              onClick={handleSpecialOffers}
            >
              Submit
            </Button>
          ) : (
            <CancelConfirmationModal
              onContinue={() => handleCancel()}
              pageName={'Manage Membership > Other Reason'}
              petId={device.pet!.id}
              trigger={
                <Button className={styles.button} disabled={otherText === null || otherText.length === 0}>
                  Submit
                </Button>
              }
            />
          )}
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
