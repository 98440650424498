import React from 'react';
import styles from './Footer.module.scss';

interface Props {
  icon: React.ReactChild;
  href: string;
}

export function SocialButton({ href, icon }: Props) {
  return (
    <a href={href} className={styles.socialLink} target="_blank" rel="noreferrer">
      {icon}
    </a>
  );
}
