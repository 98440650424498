import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInPersonEvent } from '../types';

const name = 'inPersonEvent' as const;

const { actions, reducer } = createSlice({
  name,
  initialState: null as IInPersonEvent | null,
  reducers: {
    setInPersonEvent(inPersonEvent: IInPersonEvent | null, { payload }: PayloadAction<IInPersonEvent>) {
      return payload;
    },

    clearInPersonEvent(inPersonEvent: IInPersonEvent | null, _action: PayloadAction<{}>) {
      return null;
    },
  },
});

export const inPersonEventActions = actions;
export const inPersonEventReducer = reducer;
export const inPersonEventInitialState = {
  [name]: null as IInPersonEvent | null,
};
