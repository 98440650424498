import React from 'react';
import { Switch } from 'react-router';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import { CartModeProvider, CartModes } from '../../lib/cartModes';
import PurchaseSubscriptionCheckout from '../PurchaseSubscription/PurchaseSubscriptionCheckout/PurchaseSubscriptionCheckout';
import PurchaseSubscriptionComplete from '../PurchaseSubscription/PurchaseSubscriptionComplete/PurchaseSubscriptionComplete';
import PurchaseSubscriptionPayment from '../PurchaseSubscription/PurchaseSubscriptionPayment/PurchaseSubscriptionPayment';
import { PurchaseSubscriptionContextProvider } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import MembershipUpgradeSelection from './MembershipUpgradeSelection';

export default function MembershipUpgrade() {
  return (
    <PurchaseSubscriptionContextProvider>
      <CartModeProvider mode={CartModes.membershipUpgrade}>
        <Switch>
          <SentryRoute exact path={AppPaths.MembershipUpgrade.PlanSelection()} component={MembershipUpgradeSelection} />
          <SentryRoute exact path={AppPaths.MembershipUpgrade.Checkout()} component={PurchaseSubscriptionCheckout} />
          <SentryRoute exact path={AppPaths.MembershipUpgrade.Payment()} component={PurchaseSubscriptionPayment} />
          <SentryRoute exact path={AppPaths.MembershipUpgrade.Complete()} component={PurchaseSubscriptionComplete} />
        </Switch>
      </CartModeProvider>
    </PurchaseSubscriptionContextProvider>
  );
}
