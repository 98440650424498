import * as qs from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { skusForCartItem } from '../../../../lib/cart';
import { getProductsBySku } from '../../../../lib/product';
import { centsToDollars } from '../../../../lib/util';
import * as types from '../../../../types';
import { ProductCategory } from '../../../../types';

/* eslint-disable jsx-a11y/iframe-has-title */

interface ICJEventIFrameProps {
  cart: types.Cart;
  cartPricing: types.CartPricing;
  cjevent?: string;
  orderId: string;
  products: types.IProduct[];
}

// TODO: Convert this to FC
class CJEventIFrame extends React.Component<ICJEventIFrameProps> {
  public static containerParams = {
    containerTagId: '33287',
    TYPE: '414548',
    CID: '1555901',
  };

  public render() {
    return (
      <iframe
        height="1"
        width="1"
        frameBorder="0"
        scrolling="no"
        src={this.iframeUrl}
        name="cj_conversion"
        style={{ display: 'none' }}
      />
    );
  }

  private getItemParams() {
    const cartItems = Object.values(this.props.cart.cartItems);
    const productsBySku = getProductsBySku(this.props.products);

    let itemParams = {};
    let index = 0;

    for (const cartItem of cartItems) {
      const skus = skusForCartItem(cartItem);
      for (const sku of skus) {
        const product = productsBySku.get(sku);
        if (!product) {
          continue;
        }

        if (product.category === ProductCategory.SUBSCRIPTION && product.freeTrial) {
          continue;
        }

        index++;
        itemParams = {
          ...itemParams,
          [`ITEM${index}`]: sku,
          [`AMT${index}`]: centsToDollars(product.priceInCents),
          [`QTY${index}`]: cartItem.quantity,
        };
      }
    }

    return itemParams;
  }

  private get iframeUrl() {
    const discountParams: any = {};
    if (this.props.cartPricing.appliedDiscountInCents > 0) {
      discountParams.DISCOUNT = centsToDollars(this.props.cartPricing.appliedDiscountInCents);
    }
    if (this.props.cart.couponCode) {
      discountParams.COUPON = this.props.cart.couponCode;
    }
    return `https://www.emjcd.com/tags/c?${qs.stringify({
      ...CJEventIFrame.containerParams,
      ...this.getItemParams(),
      CURRENCY: 'USD',
      OID: this.props.orderId,
      CJEVENT: this.props.cjevent || undefined,
      ...discountParams,
    })}`;
  }
}

const mapStateToProps = (state: types.AppState) => ({
  cjevent: state.affiliateData.cjevent,
  products: state.config.products,
});

export default connect(mapStateToProps)(CJEventIFrame);
