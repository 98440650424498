import React, { useState } from 'react';

import Button from '../../../../components/Button';
import Popup from 'reactjs-popup';
import styles from '../../../../components/modals/modals.module.scss';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateTime } from 'luxon';
import { BillingSubscription } from '../SplashPage';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'classnames';
import { supplementsManagement as events } from '../../../../lib/analytics/events';

interface ISelectShipDateModalProps {
  onContinue: (date: string) => void;
  trigger: React.ReactElement;
  subscription: BillingSubscription;
  referrer?: string;
}

export default function SelectShipDateModal({
  onContinue,
  trigger,
  subscription,
  referrer,
}: ISelectShipDateModalProps) {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [buttonText, setButtonText] = useState('Update shipment date');

  const maxDate = subscription.supplementShipmentDates?.latestSkipDate;

  const onSelectDate = (date: any) => {
    setSelectedDate(date);
    setButtonEnabled(true);
    const dateText = DateTime.fromISO(date.format()).toFormat('LLL d');
    setButtonText(`Update shipment to ${dateText}`);
  };

  // Disable weekends and current day
  function disableInvalidShippingDays(date: Dayjs) {
    const isToday = dayjs(date).isSame(dayjs(), 'day');
    const isWeekend = date.day() === 0 || date.day() === 6;
    return isToday || isWeekend;
  }

  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      onOpen={() => events.updateShipmentDateTapped({ subscriptionId: subscription.id }, referrer)}
    >
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={classNames(styles.body, styles.shrink)}>
            {
              <>
                <div className={styles.header}>
                  <h3>Your upcoming shipment</h3>
                  <CloseIcon
                    onClick={() => {
                      events.modalCloseEvent({}, 'Set Shipment Date');
                      close();
                    }}
                  />
                </div>
                <hr className={styles.divider} />
                <DateCalendar
                  disablePast={true}
                  onChange={(newValue) => onSelectDate(newValue)}
                  maxDate={dayjs(maxDate)}
                  defaultValue={
                    buttonEnabled ? dayjs(selectedDate) : dayjs(subscription.supplementShipmentDates?.nextShipmentDate)
                  }
                  shouldDisableDate={(date: Dayjs) => disableInvalidShippingDays(date)}
                />
              </>
            }
          </div>
          <div className={classNames(styles.actions, styles.shrink)}>
            <Button
              className={styles.button}
              disabled={!buttonEnabled}
              onClick={() => {
                onContinue(selectedDate.toISOString());
              }}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
}
