import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import { CartPricingProvider } from '../../contexts/CartPricingContext';
import { CheckoutContextProvider } from '../../lib/CheckoutContext';
import { CartModeProvider, CartModes, useSubscriptionCartMode } from '../../lib/cartModes';
import { priceCartForCheckout } from '../../lib/pricing';
import { PurchaseSubscriptionContextProvider } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import GiftFiActivationConfirmation from './GiftFiActivationConfirmation';
import GiftFiActivationCreditCard from './GiftFiActivationCreditCard';

function GiftFiActivationRouter() {
  const { cartSelector, checkoutType } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  const fetchPricing = useCallback(() => priceCartForCheckout(cart, { checkoutType }), [cart, checkoutType]);

  return (
    <CartPricingProvider fetchPricing={fetchPricing}>
      <Switch>
        <SentryRoute exact path={AppPaths.GiftFiActivation.AddCreditCard()} component={GiftFiActivationCreditCard} />
        <SentryRoute exact path={AppPaths.GiftFiActivation.Confirmation()} component={GiftFiActivationConfirmation} />
      </Switch>
    </CartPricingProvider>
  );
}

export default function GiftFiActivation() {
  return (
    <PurchaseSubscriptionContextProvider>
      <CartModeProvider mode={CartModes.purchaseSubscription}>
        <CheckoutContextProvider>
          <GiftFiActivationRouter />
        </CheckoutContextProvider>
      </CartModeProvider>
    </PurchaseSubscriptionContextProvider>
  );
}
