import React from 'react';
import classnames from 'classnames';
import Button from '../../../../components/Button';
import { BandVariantGroup } from '../BandVariantOptions/BandVariantOptions';
import styles from './BandVariantGroupSelector.module.scss';

interface BandDescriptionOptionsProps {
  selectedBandVariantGroup: BandVariantGroup;
  onVariantGroupSelect: (bandVariantGroup: BandVariantGroup) => void;
}

export default function BandVariantGroupSelector({
  selectedBandVariantGroup,
  onVariantGroupSelect,
}: BandDescriptionOptionsProps) {
  return (
    <div className={styles.variantSelectorButtonGroup}>
      <Button
        className={classnames(styles.variantSelectorButton, {
          [styles.variantSelectorButtonSelected]: selectedBandVariantGroup === BandVariantGroup.FiOriginals,
        })}
        secondary={true}
        onClick={() => onVariantGroupSelect(BandVariantGroup.FiOriginals)}
      >
        Fi Originals
      </Button>
      <Button
        className={classnames(styles.variantSelectorButton, {
          [styles.variantSelectorButtonSelected]: selectedBandVariantGroup === BandVariantGroup.MakersEdition,
        })}
        secondary={true}
        onClick={() => onVariantGroupSelect(BandVariantGroup.MakersEdition)}
      >
        Premium Editions
      </Button>
    </div>
  );
}
