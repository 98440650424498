import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import Button from '../../../../components/Button';
import { SubscriptionAppBarSpacer } from '../../components/SubscriptionAppBar';

export default function DowngradeS2Confirmed() {
  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBarSpacer />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <h1>Your plan has been updated!</h1>

            <p>
              Thank you for being a Fi member. Your Series 2 subscription will be updated to a monthly plan at $9/month
              at the end of your current subscription.
            </p>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              window.open('fi://closeappview');
            }}
          >
            Done
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
