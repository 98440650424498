import React from 'react';
import { gql } from '@apollo/client';
import { gqlTypes } from '../../../types';
import { useHistory, Redirect } from 'react-router-dom';

export const rescuePlacementPath = '/rescue/placement';

export type RescuePlacementNavigationState =
  | {
      placementView: 'petSelector';
    }
  | {
      placementView: 'petPlacement';
      petId: string;
    }
  | {
      placementView: 'petTemporaryPlacement';
      petId: string;
    }
  | {
      placementView: 'petPermanentPlacement';
      petId: string;
    }
  | {
      placementView: 'petRecall';
      petId: string;
    };

export const defaultRescuePlacementNavigationState: RescuePlacementNavigationState = {
  placementView: 'petSelector',
};

export function RedirectToRescuePlacementHome() {
  return (
    <Redirect
      to={{
        pathname: rescuePlacementPath,
        state: defaultRescuePlacementNavigationState,
      }}
    />
  );
}

export const rescuePlacementMainQuery = gql`
  query ECOMMERCE_rescuePlacementMain {
    currentUser {
      id
      userHouseholds {
        household {
          id
          pets {
            ...petDetails
          }
          bases {
            ...baseDetails
          }
        }
        firstCreated
      }
    }
  }

  fragment baseDetails on ChargingBase {
    id
    baseId
    name
  }

  fragment petDetails on Pet {
    id
    name
    photos {
      first {
        image {
          fullSize
        }
      }
    }
    household {
      id
      users {
        id
        firstName
        lastName
        email
      }
    }
    device {
      id
      moduleId
    }
  }
`;

export interface RescuePlacementNavigator {
  go(state: RescuePlacementNavigationState): void;
}

export function useRescuePlacementNavigator(): RescuePlacementNavigator {
  const history = useHistory();
  return {
    go(state) {
      history.push(rescuePlacementPath, state);
    },
  };
}

export class RescuePlacementModel {
  readonly data: gqlTypes.ECOMMERCE_rescuePlacementMain;
  private readonly petsById: Map<string, gqlTypes.petDetails>;
  private readonly petIdToHouseholdId: Map<string, string>;
  private readonly unassignableBasesByHouseholdId: Map<string, gqlTypes.baseDetails[]>;

  constructor(data: gqlTypes.ECOMMERCE_rescuePlacementMain) {
    this.data = data;
    this.petsById = new Map();
    this.petIdToHouseholdId = new Map();
    this.unassignableBasesByHouseholdId = new Map();

    for (const { household } of this.data.currentUser.userHouseholds) {
      for (const pet of household.pets) {
        this.petsById.set(pet.id, pet);
        this.petIdToHouseholdId.set(pet.id, household.id);
      }
      if (household.pets.length > 1) {
        // If the household has multiple pets, rescue org may also need to unassign bases.
        for (const base of household.bases) {
          this.unassignableBasesByHouseholdId.set(
            household.id,
            (this.unassignableBasesByHouseholdId.get(household.id) ?? []).concat(base),
          );
        }
      }
    }
  }

  getPetById(id: string) {
    return this.petsById.get(id);
  }

  getUnassignableBasesForPet(petId: string) {
    const householdId = this.petIdToHouseholdId.get(petId);
    return householdId ? this.unassignableBasesByHouseholdId.get(householdId) ?? [] : [];
  }

  associatedUserForPet(pet: gqlTypes.petDetails) {
    const associatedUsers = pet.household.users.filter((u) => u.id !== this.data.currentUser.id);
    return associatedUsers.length > 0 ? associatedUsers[0] : undefined;
  }

  get petsToBePlaced(): gqlTypes.petDetails[] {
    const firstUserHousehold = this.data.currentUser.userHouseholds.find((h) => h.firstCreated);
    return firstUserHousehold ? firstUserHousehold.household.pets : [];
  }

  get petsToBeRecalled(): gqlTypes.petDetails[] {
    return this.data.currentUser.userHouseholds
      .filter((uh) => !uh.firstCreated)
      .flatMap(({ household }) => household.pets);
  }
}

export function transferringOwnershipTitle(pet: gqlTypes.petDetails) {
  return `Transferring ${pet.name}’s Ownership`;
}

export function AccountInformation({
  data,
}: {
  data: {
    accountCreated: boolean;
    newAccountTemporaryPassword?: string | null;
  };
}) {
  return data.accountCreated ? (
    <p>
      An account was created for this user with the temporary password{' '}
      <strong>{data.newAccountTemporaryPassword}</strong>
      .<br />
      They can log in using this password, or click "Forgot Password" in the app.
    </p>
  ) : (
    <p>This user already has an account so they should use their existing credentials to log in.</p>
  );
}
