import React from 'react';
import Popup from 'reactjs-popup';
import Button from '../Button';
import styles from './modals.module.scss';
import classNames from 'classnames';

interface IConfirmModalProps {
  trigger: JSX.Element;
  body: string | JSX.Element;
  onAccept?(): void;
  onDecline?(): void;
  acceptText?: string;
  declineText?: string;
  open?: boolean; // Force open or close
}

/**
 * An "OK/Cancel" modal.
 */
export default function ConfirmModal({
  trigger,
  body,
  onAccept,
  onDecline,
  acceptText,
  declineText,
  open,
}: IConfirmModalProps) {
  // Use static class name for Popup instead of css module because that component munges the class name internally
  return (
    <Popup className="s3-popup" trigger={trigger} modal closeOnDocumentClick={false} open={open}>
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={styles.body}>{body}</div>
          <div className={classNames(styles.actions, styles.shrink)}>
            <Button
              className={styles.button}
              onClick={() => {
                close();
                onAccept && onAccept();
              }}
            >
              {acceptText ?? 'OK'}
            </Button>
            <Button
              className={styles.button}
              tertiary
              onClick={() => {
                close();
                onDecline && onDecline();
              }}
            >
              {declineText ?? 'Cancel'}
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
}
