import React from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import Loading from '../../components/Loading';

export function mkError(message: string) {
  return (
    <div className="error-page-container">
      <ErrorMessage errors={[message]} />
    </div>
  );
}

export function getLoadingOrErrorElement(loading: boolean, error: Error | undefined | null) {
  if (loading) {
    return (
      <div className="column">
        <Loading />
      </div>
    );
  } else if (error) {
    return mkError(error.message);
  }

  return undefined;
}
