import React from 'react';
import styles from '../../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import Button from '../../../../../components/Button';
import { SubscriptionAppBarSpacer } from '../../../components/SubscriptionAppBar';
import { inAppCancellation as events } from '../../../../../lib/analytics/events';
import { centsToDollarsNumber, expectUnreachable } from '../../../../../lib/util';
import { useLocation } from 'react-router-dom';
import { BaseInAppCancellationState } from '../../util/types';
import { SpecialOfferKey } from './SpecialOffers';

export interface SpecialOffersConfirmationState extends BaseInAppCancellationState {
  selectedOffer: SpecialOfferKey;
}

export default function SpecialOffersConfirmation() {
  const { device } = usePurchaseSubscriptionContext();
  const location = useLocation<SpecialOffersConfirmationState>();
  const { selectedOffer } = location.state;

  const descriptionText = () => {
    switch (selectedOffer) {
      case SpecialOfferKey.ONE_MONTH:
        return `You'll save $${centsToDollarsNumber(
          device.subscription?.subscriptionOption.priceInCents ?? 0,
        )} on your next
        month of Fi membership and be charged $0.`;
      case SpecialOfferKey.TWO_MONTHS:
        return `You'll save $${centsToDollarsNumber(
          device.subscription?.subscriptionOption.priceInCents ?? 0,
        )} on your next two months of Fi membership and be charged $${(
          centsToDollarsNumber(device.subscription?.subscriptionOption.priceInCents ?? 0) / 2
        ).toFixed(2)}/mo.`;
      default:
        expectUnreachable(selectedOffer);
        throw new Error(`Unknown special offer type: ${selectedOffer}`);
    }
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBarSpacer />
        <div className={styles.subscriptionContainer}>
          <h1>You're all set!</h1>
          <p>{descriptionText()}</p>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              events.specialOffersConfirmationDoneClicked({ petId: device.pet?.id });
              window.open('fi://closeappview');
            }}
          >
            Done
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
