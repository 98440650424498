import React, { useMemo } from 'react';
import { colorAndDesignVariantName, colorVariantDescription } from '../../../../lib/product';
import * as types from '../../../../types';
import { BandVariantGroup } from '../BandVariantOptions/BandVariantOptions';
import ColorOptionButton from '../ColorOptionButton';
import styles from './ColorOptions.module.scss';
import BandVariantGroupSelector from '../BandVariantGroupSelector/BandVariantGroupSelector';

interface IColorOptionsProps {
  selectedColorOption: types.ColorOption;
  selectedVariant: types.IVariant;
  onSelect: (colorOption: types.ColorOption) => void;
  variants: types.IVariant[];
  hasNonFiBandMaker: boolean;
  hasAnyUpchargedBands: boolean;
  selectedBandVariantGroup: BandVariantGroup;
  onVariantGroupSelect: (bandVariantGroup: BandVariantGroup) => void;
  showTitle?: boolean;
  selectedColorLabelPosition?: 'top' | 'bottom';
}

function buttonClassNameForColorOption(colorOption: types.ColorOption) {
  if (colorOption === types.ColorOption.Yellow) {
    return styles.yellowButton;
  } else if (colorOption === types.ColorOption.Gray) {
    return styles.grayButton;
  } else if (colorOption === types.ColorOption.Blue) {
    return styles.blueButton;
  } else if (colorOption === types.ColorOption.Pink) {
    return styles.pinkButton;
  } else if (colorOption === types.ColorOption.YellowScribble) {
    return styles.yellowScribbleButton;
  } else if (colorOption === types.ColorOption.GrayScribble) {
    return styles.grayScribbleButton;
  } else if (colorOption === types.ColorOption.BlueScribble) {
    return styles.blueScribbleButton;
  } else if (colorOption === types.ColorOption.PinkScribble) {
    return styles.pinkScribbleButton;
  } else if (colorOption === types.ColorOption.Strava) {
    return styles.stravaButton;
  } else {
    return styles.button;
  }
}

export default function ColorOptions({
  selectedColorOption,
  selectedVariant,
  onSelect,
  variants,
  hasNonFiBandMaker,
  hasAnyUpchargedBands,
  selectedBandVariantGroup,
  onVariantGroupSelect,
  showTitle = true,
  selectedColorLabelPosition = 'top',
}: IColorOptionsProps) {
  const availableColorOptions = useMemo(() => {
    // If no variant has been selected, return all the colors
    const variant = selectedVariant ?? variants[0];

    return variants.flatMap((v) => {
      // Eliminate all colors not available in this selected size or type
      if (v.options.size !== variant.options.size || v.options.collarType !== variant.options.collarType) {
        return [];
      }

      return [v.options.color];
    });
  }, [selectedVariant, variants]);

  const colorVariations = useMemo(
    () =>
      availableColorOptions.map((colorOption, idx) => {
        const selected = colorOption === selectedColorOption;

        return (
          <div className={buttonClassNameForColorOption(colorOption)} key={idx}>
            <ColorOptionButton
              colorOption={colorOption}
              selected={selected}
              available={true}
              onSelect={(option) => {
                onSelect(option);
              }}
            />
          </div>
        );
      }),
    [availableColorOptions, onSelect, selectedColorOption],
  );

  const variantTitleText = hasNonFiBandMaker ? 'Select Your Style' : 'Select your color';
  const additionalPriceText = selectedVariant.additionalPriceInCents
    ? '+ $' + Math.round(selectedVariant.additionalPriceInCents / 100)
    : null;

  const selectedVariantColorName = (
    <>
      {selectedVariant && (
        <div className={styles.selectedVariantColorName}>
          {colorAndDesignVariantName(selectedVariant.options.color)}
          {selectedVariant && selectedVariant.bandMaker !== types.BandMaker.Fi && additionalPriceText && (
            <span className={styles.selectedVariantAdditonalPrice}>{additionalPriceText}</span>
          )}
        </div>
      )}
    </>
  );

  return (
    <div className={styles.variant}>
      <div className={styles.variantTitleGroup}>
        {showTitle && <div className={styles.variantTitle}>{variantTitleText}</div>}
        {hasNonFiBandMaker && (
          <BandVariantGroupSelector
            selectedBandVariantGroup={selectedBandVariantGroup}
            onVariantGroupSelect={onVariantGroupSelect}
          />
        )}
        {selectedVariant &&
          selectedVariant.bandMaker !== types.BandMaker.Fi &&
          !!selectedVariant.additionalPriceInCents && (
            <>
              <div className={styles.selectedVariantMakerDisclaimer}>
                Additional Cost - Premium collars handcrafted by our community of Makers.
              </div>
              <div className={styles.selectedVariantMakerName}>{selectedVariant.bandMaker}</div>
            </>
          )}
        {selectedVariant &&
          hasNonFiBandMaker &&
          selectedVariant.bandMaker === types.BandMaker.Fi &&
          hasAnyUpchargedBands && (
            <div className={styles.selectedVariantFiDisclaimer}>
              Included for free with your Series 3. High quality crafted, water-proof nylon collars.
            </div>
          )}
        {selectedColorLabelPosition === 'top' && selectedVariantColorName}
        {selectedVariant && selectedVariant.bandMaker !== types.BandMaker.Fi && (
          <div className={styles.selectedVariantDescription}>
            {colorVariantDescription(selectedVariant.options.color)}
          </div>
        )}
      </div>
      <div className={styles.roundVariantSelector}>{colorVariations}</div>
      {selectedColorLabelPosition === 'bottom' && selectedVariantColorName}
    </div>
  );
}
