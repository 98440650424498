import React from 'react';
import { ReactComponent as AmexIcon } from '../../assets/images/icons/icon_amex.svg';
import { ReactComponent as MastercardIcon } from '../../assets/images/icons/icon_mastercard.svg';
import { ReactComponent as VisaIcon } from '../../assets/images/icons/icon_visa.svg';
import { ReactComponent as GenericCreditCardIcon } from '../../assets/images/icons/icon_credit_card.svg';
import styles from './CreditCardLogo.module.scss';

interface CreditCardLogoProps {
  cardType: string;
}

export default function CreditCardLogo({ cardType }: CreditCardLogoProps) {
  switch (cardType) {
    case 'Visa':
      return <VisaIcon className={styles.cardIcon} />;
      break;

    case 'American Express':
      return <AmexIcon className={styles.cardIcon} />;
      break;

    case 'MasterCard':
      return <MastercardIcon className={styles.cardIcon} />;
      break;

    default:
      return <GenericCreditCardIcon className={styles.cardIcon} />;
      break;
  }
}
