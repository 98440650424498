import { MakerItem } from './components/Maker/MakerTile';

export const MAKER_ITEMS: MakerItem[] = [
  {
    title: 'Stunt Puppy',
    description:
      "One of a kind collars with your dog's info built right in! Available in multiple colors and sizes for a custom look.",
    image: {
      type: 'image',
      url: '/fimakers/stunt_puppy_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://www.stuntpuppy.com/products/fi-ready-stunt-puppyid-pattern-collar',
  },
  {
    title: 'Mimi Green',
    description:
      'The perfect mix of tech and fashion - Personalized Fi compatible collars available in a wide variety of styles, designs, colors, and sizes.',
    image: {
      type: 'image',
      url: '/fimakers/mimi_green_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://www.shopmimigreen.com/fi-compatible-collars/',
  },
  {
    title: 'Lucky + Dog',
    description:
      'For active, yet stylish pups! Handcrafted in Austin, TX and a portion of all sales are donated to dog rescue shelters. Available in a variety of sizes and colors.',
    image: {
      type: 'image',
      url: '/fimakers/lucky_dog_s3_1.png',
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: 'https://luckyplusdog.com/collections/all-fi-collar-bands',
  },
  {
    title: 'Rope Hounds',
    description: 'Colorful, adventure-ready climbing rope collars available in a variety of sizes and colors.',
    image: {
      type: 'image',
      url: '/fimakers/rope_hounds_s3_1.png',
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: 'https://ropehounds.com/collections/fi-collars?utm_source=Fi_Website&utm_medium=Website&utm_campaign=Fi_Collars',
  },
  {
    title: 'Landshark Supply',
    description:
      'Quality gear for active dogs - especially those particularly mouthy “landsharks” that love to gnaw! Available in a variety of colors and sizes. Personalization is also available.',
    image: {
      type: 'image',
      url: '/fimakers/landshark_supply_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://landsharksupply.com/collections/fi-compatible?utm_source=Fi_Website&utm_medium=DirectLink&utm_campaign=Fi_Website',
  },
  {
    title: 'Duke & Fox',
    description:
      "Handmade in the USA personalized dog collars combine style and functionality. Choose from a variety of unique patterns and sizes to match your dog's personality and breed.",
    image: {
      type: 'image',
      url: '/fimakers/duke_and_fox_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://dukeandfox.com/collections/embroidered-dog-collars/products/personalized-fi-dog-collar',
  },
  {
    title: 'High Tail Hikes',
    description:
      'The highest quality waterproof BioThane adventure gear for active pups and their people! Proudly designed and made by hand in Oakland, California by R+ dog professionals.',
    image: {
      type: 'image',
      url: '/fimakers/high_tail_hikes_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://hightailhikes.com/collections/shop-collars',
  },
  {
    title: 'Zaley Designs',
    description:
      "Dog collars for every kind of dog & lifestyle! From waterproof, to personalized engraving, thinner straps, webbing, fabric & more… We've got a collar as fun & unique as your dog!",
    image: {
      type: 'image',
      url: '/fimakers/zaley_designs_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://zaleydesigns.com/collections/fi-compatible-dog-collars',
  },
  {
    title: 'The Canine Threads',
    description: 'High quality, durable, and waterproof dog gear meant to last through all of your pups adventures! ',
    image: {
      type: 'image',
      url: '/fimakers/canine_threads_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://thecaninethreads.com/collections/fi-compatible-collars',
  },
  {
    title: `Hypnotic Dog Co.`,
    description: `Handcrafted and made-to-order beaded collars, available in a variety of sizes, styles, and colors. Every purchase helps animal rescues!`,
    image: {
      type: 'image',
      url: `/fimakers/hypnotic_dog_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://hypnoticdog.co/collections/all`,
  },
  {
    title: 'Vivid Canine',
    description:
      'Vivid Canine ultimate adventure dog gear, made with genuine BioThane® is a great choice for the active dog.',
    image: {
      type: 'image',
      url: '/fimakers/vivid_canine_s3_1.png',
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: 'https://www.vividcanine.com',
  },
  {
    title: 'Bark & Willow',
    description:
      'These genuine leather bands easily snap into your Fi module and are available in a variety of sizes. Choose from single color or two-tone.',
    image: {
      type: 'image',
      url: '/fimakers/bark_and_willow_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://www.barkandwillow.com/fi-bands',
  },
  {
    title: 'Champ Dog Gear',
    description:
      'Handmade and personalized BioThane collars and harnesses for function and style. Several colors, styles, and sizes!',
    image: {
      type: 'image',
      url: '/fimakers/champ_dog_gear_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://champdoggear.com',
  },
  {
    title: 'Alpinhound',
    description:
      'Handcrafted, ultra-durable Cordura collars with martingale and Cobra Pro buckles. Available in a variety of colors and sizes.',
    image: {
      type: 'image',
      url: '/fimakers/alpinhound_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://alpinhound.com/collections/fi-collars',
  },
  {
    title: `Cookies & Co.`,
    description: `Handmade Biothane collars and leashes designed to be seamless, durable, and classy. Every purchase helps a rescue!`,
    image: {
      type: 'image',
      url: `/fimakers/cookies_and_co_s3_1.png`,
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: `https://cookiesandco.shop/collections/fi-compatible-collars/`,
  },
  {
    title: 'Sunthreads',
    description:
      'Modern-boho collars that were designed for dogs and owners who love the outdoors. Handmade with high-quality polyester and zinc alloy metal.',
    image: {
      type: 'image',
      url: '/fimakers/sunthreads_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://www.etsy.com/shop/MySunthreads',
  },
  {
    title: `PawTies`,
    description: `Colorful and unique Fi bands made in New York City. Available in a variety of sizes and patterns.`,
    image: {
      type: 'image',
      url: `/fimakers/pawties_s3_1.png`,
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: `https://pawties.com/collections/fi-collars`,
  },
  {
    title: 'Mutt Pack',
    description:
      'Dog gear for the wild-hearted - customizable BioThane collars & leashes, Cobra buckle collars, & more!',
    image: {
      type: 'image',
      url: '/fimakers/mutt_pack_outfitters_s3_1.png',
      retinaWidth: 1053,
      retinaHeight: 660,
    },
    link: 'https://muttpack.com/collections/mutt-pack-fi-compatible-collars',
  },
  {
    title: "Ruff N' Fluff",
    description:
      'Proudly handmade in the USA. Our collars are waterproof, odor proof and built for even the toughest adventures! Choose from solid brass hardware in a variety of finishes or Cobra® buckles.',
    image: {
      type: 'image',
      url: '/fimakers/ruff_n_fluff_s3_1.png',
      retinaWidth: 1080,
      retinaHeight: 1064,
    },
    link: 'https://ruffnfluff.com/collections/fi-compatible-collars',
  },
  {
    title: 'Canyon Creek Company',
    description:
      'Gear up for your next adventure with our variety of Biothane Collars, Leashes, and Bandanas. Handmade in the Heart of the Blue Ridge Mountains, we are here to help your pup explore the great outdoors!',
    image: {
      type: 'image',
      url: '/fimakers/canyon_creek_company_s3_1.png',
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: 'https://www.canyoncreekcompany.com',
  },
  {
    title: 'Evergreen Dog Co.',
    description:
      'Evergreen Dog Co. offers waterproof, tough, eco-friendly BioThane gear for adventurous dogs, inspired by years on rugged trails and mountains.',
    image: {
      type: 'image',
      url: '/fimakers/evergreen_dog_co_s3_1.png',
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: 'https://evergreendogco.com/products/fi-compatible-biothane-dog-collars',
  },
  {
    title: `Atlas Pet Company`,
    description: `Atlas Pet Company designs its products to last forever, builds everything by hand in Colorado, and stands behind its gear with the best warranty in the industry.`,
    image: {
      type: 'image',
      url: `/fimakers/atlas_pet_co_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://atlaspetcompany.com/products/lifetime-pro-collar`,
  },
  {
    title: `ValorWearUSA`,
    description: `Handcrafted with genuine Austri Alpin and Kong buckles and attachments, using only USA-made textiles. Say goodbye to expensive, inferior products. We build your gear to your exact specifications.`,
    image: {
      type: 'image',
      url: `/fimakers/valor_wear_s3_1.png`,
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: `https://www.valorwearusa.com/product-page/fi-series-3-valor-adventure-collar-1-75`,
  },
  {
    title: 'Dippy Designs',
    description:
      'Take us on your adventures! Our biothane gear is assured to be stink free, waterproof, easy to clean and stylish! Available in seasonal colors, splatter paint, and sizes for all breeds!',
    image: {
      type: 'image',
      url: '/fimakers/dippy_design_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://dippysdesigns.myshopify.com',
  },
  {
    title: 'Sexy Beast Dog Collars',
    description:
      'Genuine leather and Biothane collars, available in a variety of styles, sizes, and colors. Nameplates are also available.',
    image: {
      type: 'image',
      url: '/fimakers/sexy_beast_s3_1.png',
      retinaWidth: 1060,
      retinaHeight: 660,
    },
    link: 'https://sexybeastdogcollars.com/',
  },
  {
    title: 'FKN Pretty',
    description:
      'Bright, bold, and elegantly inappropriate designs - handmade with polyester webbing. Available in a variety of colors and sizes.',
    image: {
      type: 'image',
      url: '/fimakers/fkn_pretty_s3_1.png',
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: 'https://fknpretty.com/shop-1/ols/categories/fi-dog-collar',
  },
  {
    title: 'Beastly Moon',
    description:
      'BIPOC owned with exclusive designs inspired by the Pacific Northwest. Dog collars and leashes made for adventure dogs.',
    image: {
      type: 'image',
      url: '/fimakers/beastly_moon_s3_1.png',
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: 'https://beastlymoon.com/',
  },
  {
    title: `SeaFlower Co.`,
    description: `Stylish & durable dog gear, made for all those adventure-loving pups. Available in a variety of exclusive designs, sizes, styles, and colors.`,
    image: {
      type: 'image',
      url: `/fimakers/seaflower_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://seaflowercompany.com/collections/fi-compatible-collars`,
  },
  {
    title: `Beau & Nora`,
    description: `Beau & Nora collars are made with the softest line of BioThane for a leather-like feel that gives you a waterproof, durable, and easy-to-clean option that will last through all your pup's adventures!`,
    image: {
      type: 'image',
      url: `/fimakers/beau_nora_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://beauandnora.com/collections/fi-dogs-compatible-collars-collection`,
  },
  {
    title: `Elite Dog Gear`,
    description: `Elite Dog Gear is focused on creating environmentally friendly gear, using renewable and upcycled products. Fi compatible collars are available in traditional, martingale, arctic, and inline designs.`,
    image: {
      type: 'image',
      url: `/fimakers/elite_dog_gear_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://elitedoggear.com/collections/fi-collars-1`,
  },
  {
    title: `Lead Dog Designs`,
    description: `Lead Dog Design collars are built tough and ready for outdoor adventures with fun designs and phrases that stand out from the crowd.`,
    image: {
      type: 'image',
      url: `/fimakers/lead_dog_designs_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://www.leaddogdesigns.com/figpsdogcollars.html`,
  },
  {
    title: `Pimped Out Pup`,
    description: `Pimped Out Pup's BioThane dog collars make going on adventures with your pup so much easier. Say goodbye to the stinky collar and adios to yucky leashes. Collars are made by hand and ensure they will be the best product for you and your adventure buddy.`,
    image: {
      type: 'image',
      url: `/fimakers/pimped_out_pup_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://pimpedoutpup.com/fi-compatible-collars/`,
  },
  {
    title: `The French Dog`,
    description: `The French Dog offers custom collars in fun and unique patterns and materials. A portion of all sales are donated to help end human trafficking.`,
    image: {
      type: 'image',
      url: `/fimakers/the_french_dog_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://www.etsy.com/shop/TheFrenchDog?utm_source=google&utm_medium=cpc&utm_campaign=Search_US_DSA_GGL_ENG_Pets_Categories_All&utm_ag=Dog%252BCollars&utm_custom1=_k_CjwKCAiAq4KuBhA6EiwArMAw1A_p3exrosvniB8f_4BCi_QjjWsOGHrdr9IvdZmSKlrfCIBYTj0f3xoCBosQAvD_BwE_k_&utm_content=go_1875987786_71100484318_544453585894_dsa-463558451496_c_&utm_custom2=1875987786&gad_source=1&gclid=CjwKCAiAq4KuBhA6EiwArMAw1A_p3exrosvniB8f_4BCi_QjjWsOGHrdr9IvdZmSKlrfCIBYTj0f3xoCBosQAvD_BwE&section_id=33233619`,
  },
  {
    title: `Pawsome Tails`,
    description: `Pawsome Tails offers high-quality, personalized dog collars made from BioThane in chic color combos.`,
    image: {
      type: 'image',
      url: `/fimakers/pawsome_tails_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://www.pawsometailsco.com/collections/fi-compatible-collars`,
  },
  {
    title: `Merely Mavy`,
    description: `Specializing in hand stamped pet ID tags, biothane pet gear & custom laser engraving. Made with love in small town Kentucky.`,
    image: {
      type: 'image',
      url: `/fimakers/merely_mavy_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://merelymavy.com/collections/stickers`,
  },
  {
    title: `Guardian Gear`,
    description: `Custom, handmade leashes, collars, and accessories to keep pets safe and happy on all their adventures! Guardian Gear uses BioThane material because it is easy to clean and strong making it perfect for you and your pups needs!`,
    image: {
      type: 'image',
      url: `/fimakers/guardian_gear_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://www.guardian--gear.com/collars`,
  },
  {
    title: `Hoot & Co`,
    description: `Easy to Clean Dog Gear. Made in Colorado. Built to Last.`,
    image: {
      type: 'image',
      url: `/fimakers/hoot_and_co_s3_1.png`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://www.hootandco.com/collections/fi-collars?gad_source=1&gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS39o0cF9ygGEAJIESC6Tt3gKA6wggxxrcX5t0qiE0NcbuHjULDW_GEwaAsirEALw_wcB`,
  },
  {
    title: 'Harbour Hound',
    description:
      'Harbor Hound Co. was started in 2015 in Denver, CO, with the goal of providing unique, high quality, fashionable dog accessories to fit you and your hounds personal style.',
    image: {
      type: 'image',
      url: `/fimakers/harbour_hound_s3_1.jpg`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://harborhoundco.com/collections/fi-compatible-collars`,
  },
  {
    title: 'Major Darling',
    description:
      'Handme in Austin, TX, all sewn goods (collars, leashes, fanny packs, bandanas and more) empower local refugee women through fair-wage employment',
    image: {
      type: 'image',
      url: `/fimakers/major_darling_s3_1.jpg`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://majordarling.com/collections/fi-compatible-collars`,
  },
  {
    title: 'Oliver & Ash',
    description: `Every piece is custom made for your pup! Over 30 colors to mix and match for your biothane Fi collar.`,
    image: {
      type: 'image',
      url: `/fimakers/oliver_and_ash_s3_1.jpg`,
      retinaWidth: 1080,
      retinaHeight: 1080,
    },
    link: `https://www.oliverandashdesigns.com/shop/p/the-fi-compatible-collection`,
  },
];
