import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as queryString from 'query-string';
import { Link, Redirect, useLocation } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import Button from '../../components/Button';
import ErrorMessage, { useError } from '../../components/ErrorMessage';
import { login } from '../../lib/authentication';
import * as types from '../../types';
import styles from './Login.module.scss';
import { UserFacingError } from '../../lib/errors';

function ForgotPasswordLink() {
  return <Link to={AppPaths.ForgotPassword}>Forgot your password?</Link>;
}

interface LoginLocationState {
  /**
   * Should be true in the situation where the user tried to make a purchase with Apple Pay while not logged in,
   * but we've determined the email address used to make the purchase is already registered.
   */
  applePayError?: boolean;
  /**
   * Should be true in the situation where the user tried to create a new account and we encountered an error
   * when creating their billing account which happens as a separate mutation after the user creation.
   */
  billingAccountCreationError?: boolean;
}

export default function Login() {
  const location = useLocation<LoginLocationState>();
  const { error, errorID, setError } = useError();
  const [submitting, setSubmitting] = React.useState(false);
  const loggedIn = useSelector((state: types.AppState) => !!state.session);

  const queryParams = queryString.parse(location.search);
  const returnTo = queryParams.returnTo ? (queryParams.returnTo as string) : undefined;
  const applePayError = !!location.state?.applePayError;
  const billingAccountCreationError = !!location.state?.billingAccountCreationError;

  const handleLogin = useCallback(
    (event: any) => {
      event.preventDefault();
      if (submitting || loggedIn) {
        return;
      }

      setSubmitting(true);

      const email = event.target.email.value.trim();
      const password = event.target.password.value.trim();

      login(email, password)
        .catch((err) => {
          if (err instanceof UserFacingError) {
            setError(err.message);
            return;
          }

          setError(
            'An error occurred while logging in. Please try again or contact support@tryfi.com if the problem persists.',
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [loggedIn, setError, submitting],
  );

  if (loggedIn) {
    return <Redirect to={returnTo ?? AppPaths.Home} />;
  }

  return (
    <div className={styles.container}>
      <h2>Login</h2>
      {!error && applePayError && (
        <div>
          <span className={styles.signUpError}>
            It looks like you already have a Fi account. Please log in and try your purchase again.
          </span>
          <ForgotPasswordLink />
        </div>
      )}
      {!error && billingAccountCreationError && (
        <div className={styles.signUpError}>
          {/* This isn't technically true, but by having them log in again it will make another attempt at billing account creation */}
          We have created your account successfully, but had trouble logging you in. Please try logging in again.
        </div>
      )}
      <form onSubmit={handleLogin} className={styles.loginForm}>
        <div className={styles.formSection}>
          <input type="text" name="email" placeholder="Email" />
          <input type="password" name="password" placeholder="Password" />
        </div>
        <Button className={styles.formActions} type="submit" disabled={submitting}>
          Login
        </Button>
        {error && <ErrorMessage errors={[error]} errorID={errorID} />}
      </form>
      <div className={styles.actionLinks}>
        <Link to={returnTo ?? '/'}>Nevermind, take me back</Link>
        <ForgotPasswordLink />
      </div>
    </div>
  );
}
