import classNames from 'classnames';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';
import styles from './CheckoutValueProps.module.scss';
import React from 'react';

export const SERIES3_VALUE_PROPS = [
  'Free shipping',
  '50% the size of Fi Series 2',
  '2x LTE network distance covered',
  '40% more GPS satellites',
  '50% more pull force resistance (400lbs+)',
];
export const SERIES2_VALUE_PROPS = ['Free shipping'];

// Yeah you're reading that right -- PropsProps
interface CheckoutValuePropsProps {
  horizontalLayout?: boolean;
  valueProps: string[];
}

export default function CheckoutValueProps({ horizontalLayout, valueProps }: CheckoutValuePropsProps) {
  const listClassNames = classNames(styles.valuePropList, {
    [styles.horizontalLayout]: horizontalLayout,
  });

  return (
    <div className={listClassNames}>
      {valueProps.map((valueProp, index) => (
        <div className={styles.valuePropListItem} key={index}>
          <CheckIcon />
          <span>{valueProp}</span>
        </div>
      ))}
    </div>
  );
}
