import React from 'react';
import useDefaultProduct from '../../hooks/useDefaultProduct';
import AppPaths from '../../AppPaths';
import { Redirect, useLocation } from 'react-router-dom';

export default function Home() {
  const defaultProduct = useDefaultProduct();
  const location = useLocation();
  if (!defaultProduct) {
    return <Redirect to={AppPaths.NotFound} />;
  }

  return <Redirect to={{ pathname: AppPaths.Products(defaultProduct.id), search: location.search }} />;
}
