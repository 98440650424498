import * as Sentry from '@sentry/react';
import { billingAccountQuery, createBillingAccountMutation } from '../graphql-operations';
import { configActions } from '../reducers/config';
import { sessionActions } from '../reducers/session';
import * as types from '../types';
import { gqlTypes } from '../types';
import { identifyUser } from './analytics/identifyUser';
import * as postie from './analytics/postie';
import {
  ApplePayAccountExistsError,
  BillingAccountCreationError,
  GenericApplePayError,
  UserExistsSignUpError,
  UserFacingError,
  logInternalError,
} from './errors';
import { isAxiosError, webApiClient } from './fi-api/apiUtils';
import client from './fi-api/client';
import store from './reduxStore';
import { clearUserSessionData } from './session';

export const MINIMUM_PASSWORD_LENGTH = 8;

async function initializeAndCreateSession(session: types.Session) {
  await createBillingAccountAndIdentifyUser(session);

  // We don't want to consider the user as "logged in" until we've successfully created a billing account for them.
  store.dispatch(sessionActions.createSession({ session }));
}

export async function login(email: string, password: string): Promise<types.Session> {
  if (!email || !password) {
    throw new UserFacingError('Please provide both an email and password');
  }

  try {
    const response = await webApiClient.post('/auth/login', {
      email,
      password,
    });

    const session: types.Session = {
      userId: response.data.userId,
      email: response.data.email,
    };

    await initializeAndCreateSession(session);
    store.dispatch(configActions.clearConfig());

    return session;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      throw new UserFacingError('Invalid email or password');
    } else {
      logInternalError(error, {
        fingerprint: ['{{ default }}', error.message],
        tags: {
          checkoutStep: 'login',
        },
      });
      throw new Error('An unknown error occurred');
    }
  }
}

/**
 * The ecommerce app relies on session information in the Redux store to determine if the user is logged in.
 * It's possible that the user is already authenticated with tryfi.com but doesn't have a session data in the ecommerce
 * Redux store. An example is when the user is logged into nano.tryfi.com then comes to the store at shop.tryfi.com.
 *
 * This method will attempt to grab user information from a REST endpoint and store it in Redux. This endpoint will
 * return 401 if the user does not have a session cookie or has an invalid/expired session.
 */
export async function createSessionFromExistingCredentials(): Promise<types.Session | undefined> {
  try {
    // The /auth/currentuser endpoint will return session data if the user has a valid session cookie.
    // TODO: Add small number of retries for network errors?
    const response = await webApiClient.get('/auth/currentuser');
    const session: types.Session = {
      userId: response.data.userId,
      email: response.data.email,
    };

    // If we're already set this same user id in the session, that means we've already gone through the session
    // and billing account initialization
    if (session.userId === store.getState().session?.userId) {
      return session;
    }

    await initializeAndCreateSession(session);

    return session;
  } catch (err) {
    // If we get a 401, it means the user's session has expired or does not have a session cookie in the first place,
    // so we should remove any expired session data from Redux and clear any other user-specific information we have
    // stored.
    if (isAxiosError(err) && err.response?.status === 401) {
      clearUserSessionData();
      return;
    }

    logInternalError(err, {
      fingerprint: ['{{ default }}', err.message],
      tags: {
        checkoutStep: 'createSession',
      },
    });
    throw err;
  }
}

export async function setPassword(newPassword: string) {
  await webApiClient.post('/auth/setpassword', { password: newPassword });
  store.dispatch(sessionActions.didSetPassword({}));
}

export async function logout() {
  try {
    await webApiClient.post('/auth/logout');
    clearUserSessionData();

    // Reset any identified traits & user ids
    analytics.reset();
    Sentry.setUser(null);
  } catch (error) {
    logInternalError(error, {
      fingerprint: ['{{ default }}', error.message],
      tags: {
        checkoutStep: 'logout',
      },
    });
    throw new Error('We were unable to log you out');
  }
}

export async function signup(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  tenancy: string | undefined,
): Promise<types.Session> {
  if (!email || !password) {
    throw new UserFacingError('Please provide both an email and password');
  }
  if (!firstName || !lastName) {
    throw new UserFacingError('Please provide your first and last name');
  }

  let session: types.Session;
  try {
    const response = await webApiClient.post('/auth/signup', {
      email,
      password,
      firstName,
      lastName,
      tenancy,
    });

    postie.trackEmailSignup(email);
    identifyUser(response.data.userId, {
      email,
      name: { firstName, lastName },
    });

    session = {
      userId: response.data.userId,
      email,
    };
  } catch (error) {
    if (error.response.status === 409) {
      throw new UserExistsSignUpError();
    } else {
      logInternalError(error, {
        fingerprint: ['{{ default }}', error.message],
        tags: {
          checkoutStep: 'signup',
        },
      });
      throw new Error('An unknown error occurred');
    }
  }

  // Make sure to wait for the billing account here so that it's available
  // when the dispatch updates component props
  try {
    await createBillingAccountAndIdentifyUser(session);
  } catch (error) {
    logInternalError(error, {
      fingerprint: ['{{ default }}', error.message],
      tags: {
        checkoutStep: 'createBillingAccount',
      },
    });

    // If we were unable to create their billing account after signing up, remove any session cookies we have already
    // set because we're gonna want to have them login again which would retry creating their billing account.
    store.dispatch(sessionActions.destroySession());

    throw new BillingAccountCreationError();
  }

  store.dispatch(sessionActions.createSession({ session }));

  return session;
}

export async function sendPasswordResetEmail(email: string) {
  await webApiClient.post('/auth/sendpasswordreset', { email });
}

async function createBillingAccountAndIdentifyUser(session: types.Session): Promise<void> {
  /**
   * NOTE: We're not catching errors within this method to allow for callers to handle them in a more specific way.
   * Sign up needs to handle errors here differently than login, for example.
   */
  const billingAccount = await client.query<gqlTypes.billingAccount>({
    query: billingAccountQuery,
    fetchPolicy: 'network-only',
  });
  if (billingAccount.data.currentUser.billingAccount) {
    identifyUser(session.userId, {
      email: session.email,
      billingInfo: billingAccount.data.currentUser.billingAccount?.billingInfo,
      shippingAddress: billingAccount.data.currentUser.billingAccount?.address,
    });
  } else {
    // If they don't already have a billing account we must create one and since we know they won't
    // have any billing info, we can just identify them with the session data we have.
    await client.mutate<gqlTypes.ECOMMERCE_createUserBillingAccount['createBillingAccount']>({
      mutation: createBillingAccountMutation,
    });
    identifyUser(session.userId, { email: session.email });
  }
}

function applePayContactToParams(contact: ApplePayJS.ApplePayPaymentContact) {
  return {
    email: contact.emailAddress,
    firstName: contact.givenName,
    lastName: contact.familyName,
    address1: contact.addressLines && contact.addressLines[0],
    address2: contact.addressLines && contact.addressLines[1],
    city: contact.locality,
    state: contact.administrativeArea,
    phone: contact.phoneNumber,
    zip: contact.postalCode,
    country: (contact.countryCode && contact.countryCode.toUpperCase()) || 'US',
  };
}

export async function applePay(
  token: string,
  shippingAddressDetails: ApplePayJS.ApplePayPaymentContact,
  tenancy: string | undefined,
): Promise<void> {
  try {
    const flattenedParams = applePayContactToParams(shippingAddressDetails);
    const response = await webApiClient.post('/api/ecommerce/applepay', {
      token,
      tenancy,
      ...flattenedParams,
    });
    const email = shippingAddressDetails.emailAddress;
    const session: types.Session = {
      userId: response.data.userId,
      noPassword: !response.data.hasPassword,
      email,
    };
    if (email) {
      postie.trackEmailSignup(email);
    }

    identifyUser(response.data.userId, {
      email,
      name: {
        firstName: flattenedParams.firstName,
        lastName: flattenedParams.lastName,
      },
      shippingAddress: {
        line1: flattenedParams.address1,
        line2: flattenedParams.address2,
        city: flattenedParams.city,
        state: flattenedParams.state,
        zip: flattenedParams.zip,
        country: flattenedParams.country,
        phone: flattenedParams.phone,
      },
    });

    store.dispatch(sessionActions.createSession({ session }));
  } catch (error) {
    if (error.response.status === 409) {
      throw new ApplePayAccountExistsError();
    } else if (
      isAxiosError(error) &&
      error.response?.status === 400 &&
      error.response.data?.error?.code === 'customer_error' &&
      error.response.data?.error?.message
    ) {
      throw new UserFacingError(error.response.data.error.message);
    }

    logInternalError(error, {
      fingerprint: ['{{ default }}', error.message],
      tags: {
        paymentMethod: 'applePay',
        checkoutStep: 'updateBillingInfo',
      },
    });
    throw new GenericApplePayError();
  }
}
