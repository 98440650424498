import { useState, useEffect } from 'react';
import { IGiftCardProduct } from '../../types';
import * as events from '../../lib/analytics/events';

export default function useTracking(product: IGiftCardProduct | undefined) {
  const [lastTrackedId, setLastTrackedId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!product || product.id === lastTrackedId) {
      return;
    }

    const eventProperties: events.ProductEventProps = {
      priceInCents: product.priceInCents,
      productName: product.name,
      sku: product.sku,
    };

    if (lastTrackedId) {
      events.giftCardSwitch(eventProperties);
    } else {
      events.giftCardViewed(eventProperties);
    }

    setLastTrackedId(product.id);
  }, [product, lastTrackedId]);
}
