import React from 'react';
import styles from './FatalErrorMessage.module.scss';

interface FatalErrorMessageProps {
  errorMessage?: string;

  // This component is used as a fallback for Sentry.ErrorBoundary. These props are passed by Sentry.ErrorBoundary.
  error?: Error;
  componentStack?: string;
  eventId?: string;
  resetError?: () => void;
}

export default function FatalErrorMessage({ errorMessage }: FatalErrorMessageProps) {
  return (
    <div className={styles.container}>
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        {errorMessage ?? `An error occurred loading this page. If problem persists, reach out to support@tryfi.com.`}
      </div>
    </div>
  );
}
