import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Series3App.module.scss';
import analytics from '../lib/analytics';
import { lookupAndIdentifyUser } from '../lib/analytics/identifyUser';
import { createSessionFromExistingCredentials } from '../lib/authentication';
import { getRemoteConfig } from '../lib/fi-api/configApi';
import { configActions } from '../reducers/config';
import * as types from '../types';

// Turning on MAINTENANCE_MODE will display the message below on all page requeusts.
// No other content will be rendered, and users will not be able to checkout.
const MAINTENANCE_MODE = false;
const MAINTENANCE_MODE_MESSAGE = 'The Fi store is temporarily down for maintenance. Check back soon.';

interface Series3ContextType {
  appInitialized: boolean;
  initializing: boolean;
  initializationError?: string;
  retryInitialization: () => void;
}

const Series3Context = React.createContext<Series3ContextType>({
  appInitialized: false,
  retryInitialization: () => {},
  initializing: false,
});

function MaintenanceModeMessage() {
  return (
    <div className={styles.container}>
      <div style={{ textAlign: 'center', padding: '2rem' }}>{MAINTENANCE_MODE_MESSAGE}</div>
    </div>
  );
}

interface Series3ContextProviderProps {
  children: React.ReactNode;
  /**
   * If true, we will fetch the remote config with the upgradeFlow parameter set to true. The upgrade flow can have
   * different product attributes (e.g. waived activation fee)
   */
  upgradeFlow?: boolean;
}

export function Series3ContextProvider({ children, upgradeFlow }: Series3ContextProviderProps) {
  const dispatch = useDispatch();

  // We should re-initialize the app if we previously loaded products outside the upgrade flow and now are inside
  // the upgrade flow, or vice versa.
  const appInitialized = useSelector(
    (state: types.AppState) => !!state.config.loaded && !!upgradeFlow === state.config.upgradeFlow,
  );
  const [initializing, setInitializing] = useState<boolean>(false);
  const [initializationError, setInitializationError] = useState<string | undefined>(undefined);

  // This callback executes initial app load, or can be executed on-demand in case there was an error and we present
  // user with a UI element to retry.
  const initialize = useCallback(() => {
    if (initializing) {
      return;
    }

    setInitializing(true);
    setInitializationError(undefined);

    // It's possible that the cookie for the anonymous ID has not yet been set, so we need to get it directly from
    // Segment analytics instance
    let anonymousId: string | undefined;
    if (window.analytics && typeof window.analytics.user === 'function') {
      anonymousId = window.analytics.user().anonymousId();
    }

    // These 2 things are required for us to render the app:
    // 1) determining whether or not the user is logged in
    // 2) fetching the remote config from our api which includes products and other app settings
    Promise.all([createSessionFromExistingCredentials(), getRemoteConfig({ upgradeFlow: !!upgradeFlow, anonymousId })])
      .then(([session, remoteConfig]) => {
        // Most code currently relies on the config being set in the redux store.
        // TODO: Move away from storing the config in Redux and just keep it in state here, then components can
        // can get it from the context. When initializing the Redux store from browser local storage, we currently
        // overwrite the persisted site config with the initial/empty state to make sure it's refreshed upon app
        // load anyways.
        dispatch(configActions.setConfig(remoteConfig, !!upgradeFlow));

        // Reset anonymous ID, but preserve user ID (by re-identifying).
        if (remoteConfig.config.shouldResetAnonymousId) {
          analytics.reset();

          if (session) {
            return lookupAndIdentifyUser(session).catch(() => {
              // This is just for analytics so if we weren't able to re-identify the user, we can still continue.
            });
          }
        }
      })
      .catch((error) => {
        setInitializationError(error.message);
      })
      .finally(() => {
        setInitializing(false);
      });
  }, [dispatch, initializing, upgradeFlow]);

  // Initialize on mount
  useEffect(() => {
    if (MAINTENANCE_MODE || initializing) {
      return;
    }

    if (!initializationError && !appInitialized) {
      initialize();
    }
  }, [appInitialized, initializationError, initialize, initializing]);

  if (MAINTENANCE_MODE) {
    return <MaintenanceModeMessage />;
  }

  return (
    <Series3Context.Provider
      value={{
        appInitialized,
        initializing,
        initializationError,
        retryInitialization: initialize,
      }}
    >
      {children}
    </Series3Context.Provider>
  );
}

export function useSeries3Context() {
  return useContext(Series3Context);
}

export default Series3Context;
