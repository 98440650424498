import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import * as types from '../../types';
import CouponPill from '../CouponPill/CouponPill';
import ReferralPill from '../ReferralPill/ReferralPill';
import { CouponPillDisplay } from '../SubscriptionOptions/SubscriptionOptions';
import styles from './Chooser.module.scss';

interface ChooserOption<T> {
  content: React.ReactNode;
  disabled?: boolean;
  value: T;
}

export interface ChooserGroup<T> {
  footer?: React.ReactNode;
  label?: string;
  options: ChooserOption<T>[];
  description?: string;
  showReferralPill?: boolean;
  couponPill?: CouponPillDisplay;
}

interface ChooserProps<T> {
  /**
   * Items are rendered with automatic height that fits the content instead of fixed height.
   */
  autoHeight?: boolean;
  compact?: boolean;
  horizontal?: boolean;
  groups: ChooserGroup<T>[];
  onSelect: (value: T) => void;
  selectedOption?: T;
  showRadio?: boolean;
  isDisabled?: boolean;
}

function RadioButton({ selected }: { selected: boolean }) {
  return (
    <div className={styles.radioContainer}>
      {selected ? <div className={styles.radioSelected} /> : <div className={styles.radio} />}
    </div>
  );
}

export default function Chooser<T>({
  autoHeight,
  selectedOption,
  groups,
  onSelect,
  showRadio,
  compact,
  horizontal,
  isDisabled,
}: ChooserProps<T>) {
  const referreeFreeMonthEnabled = useSelector((state: types.AppState) => !!state.config.siteConfig.referreeFreeMonth);
  const containerClassName = classNames(styles.container, {
    [styles.autoHeight]: autoHeight,
    [styles.horizontal]: horizontal,
    [styles.compact]: compact,
  });
  return (
    <div className={containerClassName}>
      {groups.map((group, groupIndex) => (
        <div className={styles.chooserGroup} key={groupIndex}>
          {group.label && (
            <div className={styles.chooserGroupLabelContainer}>
              <span className={styles.chooserGroupLabel}>{group.label}</span>
              <div className={styles.chooserGroupLabelLine} />
            </div>
          )}
          {group.description && <div className={styles.chooserGroupDescription}>{group.description}</div>}
          {group.showReferralPill && referreeFreeMonthEnabled && <ReferralPill />}
          {group.couponPill?.shouldShow && (
            <CouponPill title={group.couponPill.title} subtitle={group.couponPill.subtitle} />
          )}
          {group.options.map((option, optionIndex) => {
            const isSelected = option.value === selectedOption && !isDisabled;

            const optionClassNames = classNames(styles.chooserOption, {
              'selected-chooser-option': option.value === selectedOption,
              [styles.selected]: isSelected,
              [styles.disabled]: !!option.disabled,
            });
            const onClick: React.MouseEventHandler<HTMLElement> = (_event) => {
              if (option.disabled) return;
              onSelect(option.value);
            };

            return (
              <div className={optionClassNames} key={optionIndex} onClick={onClick}>
                {showRadio && <RadioButton selected={isSelected} />}
                {option.content}
              </div>
            );
          })}
          {group.footer}
        </div>
      ))}
    </div>
  );
}
