import React, { useEffect } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';

import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';
import heartIconImg from '../../../../assets/images/in_app_cancellation/heart-yellow-bg.png';
import kitIconImg from '../../../../assets/images/in_app_cancellation/kit-yellow-bg.png';
import { useLocation } from 'react-router-dom';
import * as gqlTypes from '../../../../types/gql-op-types';
import AppPaths from '../../../../AppPaths';
import makersS3Image from '../../../../assets/images/in_app_cancellation/s3_makers_flat.png';
import classNames from 'classnames';
import { BaseInAppCancellationState } from '../util/types';
import ErrorScreen from '../../components/ErrorScreen/ErrorScreen';

export interface MeetMakersState extends BaseInAppCancellationState {
  selectedCancelReason: gqlTypes.zendeskCancellationReason;
}

export default function MeetMakers() {
  const { device } = usePurchaseSubscriptionContext();
  const location = useLocation<MeetMakersState>();
  const { selectedCancelReason, subscriptionCategory } = location.state;
  const petName = device.pet!.name;
  events.pageLoad({ petId: device.pet!.id, subscriptionCategory }, 'Discover Fi Makers');

  // Sometimes, this page loads halfway down the screen. This should mitigate those instances.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { mutation, loading } = useZendeskCancellationTicket({
    analyticsEvent: events.cancelFromContactPage,
    selectedCancelReason,
    device,
    subscriptionCategory,
  });

  if (loading) {
    return loading;
  }

  if (!device.subscription?.id) {
    return <ErrorScreen />;
  }

  const handleCancel = () => {
    events.meetMakersContinue({ petId: device.pet?.id, subscriptionCategory });
    if (!device.subscription?.id) {
      throw new Error('Something went wrong.');
    }
    mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription.id,
          cancellationReason: selectedCancelReason,
          additionalText: undefined,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.meetMakersBack({ petId: device.pet?.id, subscriptionCategory })}
          closeButtonAnalyticsEvent={() => events.meetMakersClose({ petId: device.pet?.id, subscriptionCategory })}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.largeHeader}>
            <h1>Meet our Premium Maker Editions</h1>
          </div>
          <div className={styles.featureDescription}>
            <img src={heartIconImg} alt="Heart icon" className={styles.icon} />
            <p>We partner with a community of independent creators to offer more styles and variety.</p>
          </div>
          <div className={styles.featureDescription}>
            <img src={kitIconImg} alt="Kit icon" className={styles.icon} />
            <p>Select for comfort, material, and swagger to give {petName} the best fit possible.</p>
          </div>
        </div>

        <img
          className={classNames(styles.collarAvatar, styles.makersCollarAvatar)}
          src={makersS3Image}
          alt="Makers collar flat"
        />
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              events.meetMakersExplore({ petId: device.pet?.id, subscriptionCategory });
              window.open(AppPaths.FiMakers, '_blank');
            }}
          >
            Explore Maker Editions
          </Button>
          <CancelConfirmationModal
            onContinue={() => handleCancel()}
            pageName={'Manage Membership > Other Reason'}
            petId={device.pet!.id}
            trigger={
              <Button className={styles.button} tertiary={true}>
                Continue with Cancellation
              </Button>
            }
          />
        </div>
      </div>
    </>
  );
}
