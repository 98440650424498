import classNames from 'classnames';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import CheckoutContext from '../../lib/CheckoutContext';
import { centsToDollars } from '../../lib/util';
import styles from '../../styles/lineItem.module.scss';
import RemoveLineItemButton from '../RemoveLineItemButton';
import ErrorMessage from '../ErrorMessage';

interface GiftCardAppliedLineItemProps {
  allowRemoveGiftCard?: boolean;
  appliedGiftCardAmountInCents?: number;
  redeemedGiftCardCode: string;
  totalValueOfRedeemedGiftCardInCents?: number;
  validationError?: string | null;
}

export default function GiftCardAppliedLineItem({
  allowRemoveGiftCard,
  appliedGiftCardAmountInCents,
  redeemedGiftCardCode,
  totalValueOfRedeemedGiftCardInCents,
  validationError,
}: GiftCardAppliedLineItemProps) {
  const dispatch = useDispatch();
  const { checkoutActions } = useContext(CheckoutContext);

  if (validationError) {
    return null;
  }

  const giftCardApplied = !!appliedGiftCardAmountInCents;

  return (
    <div className={styles.lineItem}>
      {allowRemoveGiftCard && (
        <div className={styles.removeButtonRow}>
          <RemoveLineItemButton
            onClick={() => {
              dispatch(checkoutActions.removeGiftCard({}));
            }}
          />
        </div>
      )}
      <div className={styles.itemRow}>
        <div className={classNames(styles.lineItemImage, styles.empty)}></div>{' '}
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            <div className={styles.lineItemPriceRow}>
              <div>
                <div>Gift card {giftCardApplied && `applied`}</div>
                <div>
                  <strong>{redeemedGiftCardCode}</strong>
                  {totalValueOfRedeemedGiftCardInCents && (
                    <span> (${centsToDollars(totalValueOfRedeemedGiftCardInCents)} value)</span>
                  )}
                </div>
              </div>
              {!!appliedGiftCardAmountInCents && (
                <div className={styles.lineItemPrice}>-${centsToDollars(appliedGiftCardAmountInCents)}</div>
              )}
            </div>
            {!!validationError && (
              <div className={styles.lineItemPriceRow}>
                <ErrorMessage errors={[validationError]} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
