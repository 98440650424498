import EventEmitter from 'eventemitter3';
import analytics from '.';

type AnalyticsState = 'uninitialized' | 'loading' | 'ready' | 'finished';

class AnalyticsCoordinator {
  private state: AnalyticsState;
  private readonly bus: EventEmitter<AnalyticsState>;

  constructor() {
    this.state = 'uninitialized';
    this.bus = new EventEmitter();
  }

  public on(state: AnalyticsState, fn: () => void) {
    this.bus.on(state, fn);
    return this;
  }

  public start() {
    analytics.page();
    this.advance('finished');
  }

  public beforePageChange() {
    // Noop
  }

  public pageChange() {
    analytics.page();
  }

  private advance(newState: AnalyticsState) {
    this.state = newState;
    this.bus.emit(this.state);
  }
}

export const analyticsCoordinator = new AnalyticsCoordinator();
