import AppPaths from '../../../../../../AppPaths';
import React from 'react';
import buttonStyles from '../../../../../../components/Button/Button.module.scss';
import styles from './S3UpgradeSplash.module.scss';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Button from '../../../../../../components/Button';
import { usePurchaseSubscriptionContext } from '../../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { inAppCancellation as events } from '../../../../../../lib/analytics/events';
import { SeriesAbbrev } from '../../../../../../types/Series';

export default function S3UpgradeSplash() {
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const showUpgradeVersions: SeriesAbbrev[] = ['S1', 'S2'];
  const collarModel = device.currentCollarModel as SeriesAbbrev;

  const showUpgrade = showUpgradeVersions.includes(collarModel);
  if (!showUpgrade) {
    return null;
  }

  const avatarUrl = '/s3_yellow_collar.png';
  return (
    <>
      <div className={classNames(styles.s3UpgradeSplash)}>
        <div className={styles.avatarContainer}>
          <img className={styles.avatar} src={avatarUrl} alt="Dog avatar" />
          <div className={styles.text}>
            <span className={styles.primary}>Series 3 unleashed.</span>
            <br />
            <span className={styles.secondary}>Half the size, 2X the power.</span>
          </div>
          <Button
            className={classNames(styles.upgrade, buttonStyles.secondary)}
            onClick={() => {
              events.upgradeToS3Clicked({ petId: device.pet!.id });
              history.push({
                pathname: AppPaths.Series3Upgrade.SplashPageForPet(device.pet!.id),
                search: window.location.search,
              });
            }}
          >
            Upgrade
          </Button>
        </div>
      </div>
    </>
  );
}
