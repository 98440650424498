import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import { MARTINGALE_BAND_PRODUCT_ID } from '../../lib/product';
import { cartActions as storeCartActions } from '../../reducers/storeCart';
import * as types from '../../types';
import { series2CollarId, series3CollarId } from '../../types';
import EndlinksDetails from '../Endlinks';
import NotFound from '../NotFound';
import BandDetails from './BandDetails';
import ChargingBaseDetails from './ChargingBaseDetails';
import MartingaleBandDetails from './MartingaleBandDetails';
import NanoDetails from './NanoDetails';
import Series2CollarDetails from './Series2CollarDetails';
import Series3CollarDetails from './Series3CollarDetails';
import { FeaturesReady } from '@growthbook/growthbook-react';

export default function ProductDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: productId } = useParams<{ id: string }>();

  const products = useSelector((state: types.AppState) => state.config.products);

  const product = useMemo(() => products.find((p) => p.id === productId), [productId, products]);
  if (!product) {
    return <NotFound />;
  }

  if (product.category === types.ProductCategory.CHARGING_BASE) {
    return (
      <ChargingBaseDetails
        productId={product.id}
        productName={product.name}
        priceInCents={product.priceInCents}
        sku={product.sku}
      />
    );
  }

  if (product.category === types.ProductCategory.MICROCHIP) {
    return <NanoDetails productName={product.name} priceInCents={product.priceInCents} sku={product.sku} />;
  }

  if (product.category === types.ProductCategory.ENDLINK) {
    return <EndlinksDetails />;
  }

  if (product.category === types.ProductCategory.COLLAR_KIT && product.id === series3CollarId) {
    return (
      <FeaturesReady>
        <Series3CollarDetails
          addBundleCartItem={(variantSku, subscriptionSku) => {
            dispatch(
              storeCartActions.addBundleCartItem({
                sku: variantSku,
                subscriptionSku: subscriptionSku,
              }),
            );

            history.push(AppPaths.Bag);
          }}
          priceInCents={product.priceInCents}
          productName={product.name}
          variants={product.variants}
        />
      </FeaturesReady>
    );
  }

  if (product.category === types.ProductCategory.COLLAR_KIT && product.id === series2CollarId) {
    return (
      <Series2CollarDetails
        priceInCents={product.priceInCents}
        productName={product.name}
        variants={product.variants}
      />
    );
  }

  if (product.category === types.ProductCategory.BAND && product.id === MARTINGALE_BAND_PRODUCT_ID) {
    return (
      <MartingaleBandDetails
        priceInCents={product.priceInCents}
        productName={product.name}
        variants={product.variants}
      />
    );
  }

  if (product.category === types.ProductCategory.BAND) {
    return (
      <BandDetails
        bandSeries={product.bandSeries}
        priceInCents={product.priceInCents}
        productId={product.id}
        productName={product.name}
        variants={product.variants}
      />
    );
  }

  return <NotFound />;
}
