import React, { createContext, ReactNode, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { gqlTypes } from '../../../types';
import { purchaseSubscriptionDeviceQuery } from '../../../graphql-operations';
import { getLoadingOrErrorElement, mkError } from '../../../lib/util';
import { getFiGrowthBook } from '../../../lib/growthbook';

interface PurchaseSubscriptionContextType {
  device: gqlTypes.purchaseSubscriptionDeviceDetails;
  subscriptionOptions: gqlTypes.subscriptionOption[];
  monthlyUpgrade: boolean;
  couponCode?: string | null;
}

export const PurchaseSubscriptionContext = createContext<PurchaseSubscriptionContextType | null>(null);

interface PurchaseSubscriptionRouteParams {
  moduleId: string;
}

export function PurchaseSubscriptionContextProvider({ children }: { children: ReactNode }) {
  /**
   * If true, we will fetch the subscriptions available to S3 monthly plan users who are upgrading
   * and surface savings based on upgrading from monthly plan.
   */
  const monthlyUpgrade = window.location.pathname.includes('monthlyupgrade');

  const { moduleId } = useParams<PurchaseSubscriptionRouteParams>();

  const couponCode = getFiGrowthBook().getFeatureValue<boolean>('in-app-mtm-6-month-upsell', false)
    ? '6mo-in-app-upgrade'
    : null;

  const { loading, error, data } = useQuery<
    gqlTypes.ECOMMERCE_purchaseSubscriptionDevice,
    gqlTypes.ECOMMERCE_purchaseSubscriptionDeviceVariables
  >(purchaseSubscriptionDeviceQuery, {
    variables: {
      moduleId,
      inAppFlow: true,
      monthlyUpgrade,
      couponCode: couponCode ?? undefined,
    },
  });

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, error);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const device = data?.device;
  if (!data || !device) {
    return mkError(`An unknown error occurred`);
  }

  return (
    <PurchaseSubscriptionContext.Provider
      value={{
        device,
        subscriptionOptions: device.purchasableSubscriptionOptions,
        monthlyUpgrade,
        couponCode,
      }}
    >
      {children}
    </PurchaseSubscriptionContext.Provider>
  );
}

export function usePurchaseSubscriptionContext() {
  const contextValue = useContext(PurchaseSubscriptionContext);
  if (!contextValue) {
    throw new Error(`Must be called from within a PurchaseSubscriptionContextProvider!`);
  }
  return contextValue;
}
