import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ActionContainer from '../../../../components/ActionContainer';
import Button from '../../../../components/Button';
import * as events from '../../../../lib/analytics/events';
import CheckoutContext from '../../../../lib/CheckoutContext';
import { expectUnreachable } from '../../../../lib/util';
import * as types from '../../../../types';
import styles from './Billing.module.scss';

function CreditCardPaymentInfo(props: { paymentInfo: types.CreditCardInfo }) {
  return (
    <>
      <div className={styles.creditCardBrand}>{props.paymentInfo.type}</div>
      {/* sentry-mask class is used to mask text in Sentry replays */}
      <div className={styles.creditCardNumber}>
        Card ending in <span className="sentry-mask">{props.paymentInfo.lastFour}</span>
      </div>
      <div className={styles.creditCardExp}>
        Exp{' '}
        <span className="sentry-mask">
          {props.paymentInfo.month}/{props.paymentInfo.year}
        </span>
      </div>
    </>
  );
}

export function PayPalPaymentInfo() {
  const dispatch = useDispatch();

  const {
    checkoutActions,
    checkoutState: { billingInfo },
  } = useContext(CheckoutContext);

  const handleUnlink = useCallback(() => {
    dispatch(checkoutActions.unlinkBillingInfo({}));
    events.payment.paypalUnlink();
  }, [checkoutActions, dispatch]);

  if (!billingInfo) {
    return null;
  }

  return (
    <>
      <div>
        Paid with{' '}
        <img
          src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
          alt="Pay with PayPal"
          style={{ height: '1em', verticalAlign: 'text-bottom' }}
        />
      </div>
      <div>
        <span className={styles.unlinkBillingCta} onClick={handleUnlink}>
          Unlink PayPal
        </span>
      </div>
    </>
  );
}

interface ViewBillingInfoProps {
  billingInfo: types.BillingInfo;
  onEdit(): void;
  onContinue(): void;
}

export default function ViewBillingInfo({ billingInfo, onContinue, onEdit }: ViewBillingInfoProps) {
  const paymentInfo = useMemo(() => {
    if (billingInfo.paymentInfo.__typename === 'ObfuscatedCardInfo') {
      return <CreditCardPaymentInfo paymentInfo={billingInfo.paymentInfo} />;
    } else if (billingInfo.paymentInfo.__typename === 'PayPalInfo') {
      return <PayPalPaymentInfo />;
    } else {
      expectUnreachable(billingInfo.paymentInfo);
    }
  }, [billingInfo]);

  const handleEdit = useCallback(
    (event) => {
      event.preventDefault();
      onEdit();
    },
    [onEdit],
  );

  const handleContinue = useCallback(
    (event) => {
      event.preventDefault();
      onContinue();
    },
    [onContinue],
  );

  return (
    <div className={styles.viewBillingInfo}>
      <div className={styles.billingDetails}>
        {/* sentry-mask class is used to mask text in Sentry replays */}
        <div className="sentry-mask">
          {billingInfo.firstName} {billingInfo.lastName}
        </div>
        {paymentInfo}
      </div>
      <ActionContainer>
        <Button secondary onClick={handleEdit}>
          Edit
        </Button>
        <Button onClick={handleContinue}>Next</Button>
      </ActionContainer>
    </div>
  );
}
