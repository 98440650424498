import React from 'react';
import * as events from '../../../../lib/analytics/events';
import { getShareInformation } from '../../Referrals';
import styles from './InviteList.module.scss';
import { ReactComponent as SendIcon } from '../../../../assets/images/icons/icon_send.svg';

interface Invite {
  name: string;
  profileImageUrl?: string;
}

function InviteLine({ name, profileImageUrl }: Invite): JSX.Element {
  return (
    <li>
      <div className={styles.inviteImage}>
        <img src={profileImageUrl} width={40} height={40} alt={'profile'} />
      </div>
      <div className={styles.inviteName}>{name}</div>
    </li>
  );
}

interface EmptyInviteLineProps {
  code: string;
  message: string;
  index: number;
}
function EmptyInviteLine({ code, message, index }: EmptyInviteLineProps): JSX.Element {
  const handleShare = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    events.referrals.shareClicked(true);
    // Web share API: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
    (navigator as any).share(getShareInformation(code, message));
  };

  return (
    <li>
      <div className={styles.inviteIndex}>
        <p>{index}</p>
      </div>
      <div className={styles.shareButton} onClick={handleShare}>
        <SendIcon />
        <p>Invite friend</p>
      </div>
    </li>
  );
}

interface InviteListProps {
  maxInvites: number;
  invites: Invite[];
  message: string;
  code: string;
}

export default function InviteList({ maxInvites, invites, code, message }: InviteListProps): JSX.Element {
  const invitesRemaining = maxInvites - invites.length;
  const emptyLines: JSX.Element[] = [];

  for (let i = 1; i <= invitesRemaining; i++) {
    emptyLines.push(<EmptyInviteLine index={invites.length + i} code={code} message={message} />);
  }

  return (
    <div className={styles.inviteList}>
      <h3>{invitesRemaining} invites remaining</h3>
      <ol>
        {invites.map((invite) => (
          <InviteLine name={invite.name} profileImageUrl={invite.profileImageUrl} />
        ))}
        {emptyLines}
      </ol>
    </div>
  );
}
