import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import ActionContainer from '../../../../components/ActionContainer';
import Button from '../../../../components/Button';
import ErrorMessage from '../../../../components/ErrorMessage';
import CheckoutContext from '../../../../lib/CheckoutContext';
import { addressValidationSchema } from '../../../../lib/addressValidation';
import * as events from '../../../../lib/analytics/events';
import { signup } from '../../../../lib/authentication';
import { cartHasGiftAndNonGiftItems, cartOnlyHasGiftItems, skusForCartItem } from '../../../../lib/cart';
import {
  BillingAccountCreationError,
  UserExistsSignUpError,
  UserFacingError,
  logInternalError,
} from '../../../../lib/errors';
import { isPreorderSku } from '../../../../lib/isPreorderSku';
import { isExpeditedShippingAvailable } from '../../../../lib/shipping';
import { generateID, validateEmail } from '../../../../lib/util';
import styles from '../../../../styles/form.module.scss';
import * as types from '../../../../types';
import { gqlTypes } from '../../../../types';
import ShippingOptionsList from '../../components/ShippingOptionsList';
import {
  AccountDetails,
  ContactInfo,
  GiftingAccountDetails,
  GiftingContactInfo,
  ShippingAddressDetails,
} from './ShippingFormFields';
import AppPaths from '../../../../AppPaths';
import { useHistory } from 'react-router';
import useShippingOptions from '../../../../hooks/useShippingOptions';
import { DEFAULT_SHIPPING_OPTION } from '../../../../reducers/checkout';
import { getNanoReferralPetNameCookie } from '../../../../lib/util/nanoReferralPetNameCookie';
import classNames from 'classnames';
import { PaymentOptions } from '../../../../components/CartSummary/CartActions';
import { billingAccountQuery } from '../../../../graphql-operations';
import { Query } from '@apollo/client/react/components';
import Loading from '../../../../components/Loading';
import useTenancy from '../../../../hooks/useTenancy';

enum GiftDeliveryState {
  YOUR_ADDRESS = 'YOUR_ADDRESS',
  SOMEONE_ELSE = 'SOMEONE_ELSE',
}

interface GiftDeliveryShippingOptionsProps {
  giftDeliveryState: GiftDeliveryState | undefined;
  setGiftDeliveryState: React.Dispatch<React.SetStateAction<GiftDeliveryState | undefined>>;
}

const GiftDeliveryShippingOptions: React.FC<GiftDeliveryShippingOptionsProps> = ({
  giftDeliveryState,
  setGiftDeliveryState,
}) => {
  return (
    <div className={styles.giftDeliveryShippingOptions}>
      <label
        className={classNames(styles.giftDeliveryShippingOption, {
          [styles.giftDeliveryShippingOptionSelected]: giftDeliveryState === GiftDeliveryState.YOUR_ADDRESS,
        })}
      >
        <input
          type="radio"
          name="shipping"
          checked={giftDeliveryState === GiftDeliveryState.YOUR_ADDRESS}
          onChange={() => setGiftDeliveryState(GiftDeliveryState.YOUR_ADDRESS)}
        />
        Ship to your address
      </label>

      <label
        className={classNames(styles.giftDeliveryShippingOption, {
          [styles.giftDeliveryShippingOptionSelected]: giftDeliveryState === GiftDeliveryState.SOMEONE_ELSE,
        })}
      >
        <input
          type="radio"
          name="shipping"
          checked={giftDeliveryState === GiftDeliveryState.SOMEONE_ELSE}
          onChange={() => setGiftDeliveryState(GiftDeliveryState.SOMEONE_ELSE)}
        />
        Ship to someone else
      </label>
    </div>
  );
};

interface ShippingAddressFormProps {
  loggedInUserId?: string;
  submitting: boolean;
  onComplete(addressUpdate?: {
    firstName: string;
    lastName: string;
    address: types.Address;
    addressValidationStatus: types.AddressValidationNeedsVerify;
    shippingCode: string;
    giftRecipient: { firstName: string; lastName: string } | null;
  }): void;
}

interface FormFields {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  shippingCode: types.ShippingCode | undefined;
  giftFirstName?: string;
  giftLastName?: string;
}

export default function ShippingAddressForm({ loggedInUserId, submitting, onComplete }: ShippingAddressFormProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const shippingOptions = useShippingOptions();
  const { cart, checkoutState, checkoutActions, session, requiresShippingDetails } = useContext(CheckoutContext);
  const isOnlyGiftingCart = cartOnlyHasGiftItems(cart);
  const isMixedGiftingCart = cartHasGiftAndNonGiftItems(cart);
  const [giftDeliveryState, setGiftDeliveryState] = useState<GiftDeliveryState | undefined>(
    isOnlyGiftingCart ? GiftDeliveryState.YOUR_ADDRESS : undefined,
  );
  const tenancy = useTenancy();

  let initialFirstName = checkoutState.customerName?.firstName ?? '';
  // If the user is a nano user and the first name is an email, set it to an empty string
  if (getNanoReferralPetNameCookie() && validateEmail(initialFirstName)) {
    initialFirstName = '';
  }

  const initialFormValues: FormFields = useMemo(() => {
    const addressFields =
      giftDeliveryState !== GiftDeliveryState.SOMEONE_ELSE
        ? {
            line1: checkoutState.shippingAddress?.line1 ?? '',
            line2: checkoutState.shippingAddress?.line2 ?? '',
            city: checkoutState.shippingAddress?.city ?? '',
            state: checkoutState.shippingAddress?.state ?? '',
            zip: checkoutState.shippingAddress?.zip ?? '',
            phone: checkoutState.shippingAddress?.phone ?? '',
            country: checkoutState.shippingAddress?.country ?? 'US',
          }
        : {
            line1: '',
            line2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            country: 'US',
          };

    return {
      ...addressFields,
      email: '',
      password: '',
      firstName: initialFirstName ?? '',
      lastName: checkoutState.customerName?.lastName ?? '',
      shippingCode: checkoutState.shippingCode,
    };
  }, [
    checkoutState.shippingAddress,
    checkoutState.customerName,
    checkoutState.shippingCode,
    initialFirstName,
    giftDeliveryState,
  ]);

  const [errors, setErrors] = useState<string[]>([]);
  const [errorID, setErrorID] = useState<string | undefined>(undefined);
  const [userExistsSignUpError, setUserExistsSignUpError] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<FormFields>(initialFormValues);
  const [submittingForm, setSubmittingForm] = useState(false);

  const loggedIn = !!session;
  const requiresAccountDetails = !loggedIn;
  const loginUrl = `${AppPaths.Login}?returnTo=${AppPaths.CheckoutShipping}`;

  const anyPreorder = useMemo(
    () =>
      Object.values(cart.cartItems)
        .flatMap((cartItem) => skusForCartItem(cartItem))
        .some(isPreorderSku),
    [cart.cartItems],
  );

  const canExpedite = useMemo(() => {
    // If there's no address yet, we don't know if we can or cannot expedite, so don't disable the expedited ones yet.
    if (!checkoutState.shippingAddress && !formValues.line1 && !formValues.line2) {
      return true;
    }

    return isExpeditedShippingAvailable({
      ...checkoutState.shippingAddress,
      ...formValues,
    });
  }, [checkoutState.shippingAddress, formValues]);

  // Update form when checkoutState shippingAddress changes (e.g. it's loaded from the user account)
  useEffect(() => {
    setFormValues(initialFormValues);
  }, [initialFormValues]);

  // Reset form to empty on user change (impersonation)
  const [previousUserId, setPreviousUserId] = useState(loggedInUserId);

  useEffect(() => {
    if (loggedInUserId && loggedInUserId !== previousUserId) {
      setFormValues(initialFormValues);
      setPreviousUserId(loggedInUserId);
    }
  }, [previousUserId, loggedInUserId, initialFormValues]);

  // Automatically switch to standard shipping option if address changes in a way that makes expedited shipping
  // unavailable
  useEffect(() => {
    const selectedShippingOption = shippingOptions.find((so) => so.code === formValues.shippingCode);
    if (selectedShippingOption?.isExpedited && !canExpedite) {
      setFormValues({
        ...formValues,
        shippingCode: DEFAULT_SHIPPING_OPTION,
      });
    }
  }, [canExpedite, checkoutActions, dispatch, formValues, formValues.shippingCode, shippingOptions]);

  const onChange = useCallback(
    (fieldName: string, value: string) => {
      setFormValues({
        ...formValues,
        [fieldName]: value,
      });
    },
    [formValues],
  );

  const handleErrors = useCallback(
    (newErrors: string[]) => {
      if (newErrors.length > 0) {
        setErrors(newErrors);
        setErrorID(generateID());

        events.shipping.continueError(newErrors.join('; '));
      }
    },
    [setErrorID, setErrors],
  );

  const handleSubmit = useCallback(async () => {
    if (submitting) {
      return;
    }

    setSubmittingForm(true);

    // Reset errors
    setErrors([]);
    setUserExistsSignUpError(false);

    const email = formValues.email.trim();
    const password = formValues.password.trim();
    const firstName = formValues.firstName.trim();
    const lastName = formValues.lastName.trim();
    const giftFirstName = formValues.giftFirstName?.trim();
    const giftLastName = formValues.giftLastName?.trim();
    const shippingCode = formValues.shippingCode;

    const addressInput: gqlTypes.AddressInput = {
      line1: formValues.line1.trim(),
      line2: formValues.line2.trim(),
      city: formValues.city.trim(),
      state: formValues.state.trim(),
      zip: formValues.zip.trim(),
      phone: formValues.phone.trim(),
      country: formValues.country.trim(),
    };

    try {
      addressValidationSchema.validateSync(
        {
          ...addressInput,
          email,
          password,
          firstName,
          lastName,
          shippingCode,
          giftFirstName,
          giftLastName,
        },
        {
          abortEarly: false,
          context: {
            requiresFirstAndLastName: !isOnlyGiftingCart || requiresAccountDetails,
            requiresAccountDetails,
            requiresShippingDetails,
            requiresGiftRecipient: giftDeliveryState === GiftDeliveryState.SOMEONE_ELSE,
          },
        },
      );
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        handleErrors(err.errors);
        setSubmittingForm(false);
        return;
      }

      logInternalError(err);
      handleErrors(['An unknown error occurred.']);
      setSubmittingForm(false);
      return;
    }

    if (requiresAccountDetails) {
      try {
        await signup(email, password, firstName, lastName, tenancy);
      } catch (error) {
        /**
         * Our signup helper will make a couple of API calls
         * 1) create the Fi user account
         * 2) create a Recurly billing account
         *
         * If the second call fails, we want to redirect the user to the login page because they already have a
         * Fi user account so they can't signup again and logging in will make another attempt at creating their
         * Recurly billing account if they don't already have one.
         */
        if (error instanceof BillingAccountCreationError) {
          history.push(loginUrl, {
            billingAccountCreationError: true,
          });
          return;
        }

        if (error instanceof UserExistsSignUpError) {
          // Don't automatically redirect to the login screen for this error. We'll show a special error message
          // with a link to login, but by keeping them here they also have the option to sign up with a different
          // email instead.
          setUserExistsSignUpError(true);
        } else {
          const errorMessage =
            error instanceof UserFacingError
              ? error.message
              : 'An error occurred while signing up. Please try again or contact support@tryfi.com if the problem persists.';

          handleErrors([errorMessage]);
        }

        setSubmittingForm(false);
        return;
      }
    }

    if (!requiresShippingDetails) {
      dispatch(checkoutActions.setCustomerName({ firstName, lastName }));
      onComplete();
      setSubmittingForm(false);
      return;
    }

    onComplete({
      firstName,
      lastName,
      address: addressInput,
      // If this is the first time they're entering an address (i.e. creating a new account), address validation
      // should allow an autocorrect of the address without prompting the user as long as the validation backend
      // said it was a verified address
      addressValidationStatus: requiresAccountDetails
        ? types.AddressValidationStatus.NeedsVerifyWithAutocorrect
        : types.AddressValidationStatus.NeedsVerifyWithPrompt,
      shippingCode: shippingCode!,
      giftRecipient:
        giftDeliveryState === GiftDeliveryState.SOMEONE_ELSE
          ? { firstName: giftFirstName!, lastName: giftLastName! }
          : null,
    });
    setSubmittingForm(false);
  }, [
    submitting,
    formValues.email,
    formValues.password,
    formValues.firstName,
    formValues.lastName,
    formValues.giftFirstName,
    formValues.giftLastName,
    formValues.shippingCode,
    formValues.line1,
    formValues.line2,
    formValues.city,
    formValues.state,
    formValues.zip,
    formValues.phone,
    formValues.country,
    requiresAccountDetails,
    requiresShippingDetails,
    onComplete,
    giftDeliveryState,
    isOnlyGiftingCart,
    handleErrors,
    history,
    loginUrl,
    dispatch,
    checkoutActions,
    tenancy,
  ]);

  // wrap handleSubmit in another callback for the form action because onSubmit expects a void return type but
  // handleSubmit is async
  const onSubmitCallback = useCallback(
    (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault();

      // handleSubmit should already have its own error handling but we catch and log here for good measure
      handleSubmit().catch((err) => {
        logInternalError(err);
      });
    },
    [handleSubmit],
  );

  return (
    <Query<gqlTypes.billingAccount> query={billingAccountQuery} fetchPolicy="network-only">
      {({ data, loading }) => {
        if (loading) {
          return <Loading />;
        }
        return (
          <>
            <form className={styles.form} onSubmit={onSubmitCallback} autoComplete="on">
              {isMixedGiftingCart && (
                <div className={styles.mixedGiftingCartMessage}>
                  All cart items, including gift purchases, will be shipped to a single address.
                </div>
              )}
              {isOnlyGiftingCart && (
                <>
                  <GiftDeliveryShippingOptions
                    giftDeliveryState={giftDeliveryState}
                    setGiftDeliveryState={setGiftDeliveryState}
                  />
                  {giftDeliveryState === GiftDeliveryState.YOUR_ADDRESS &&
                    !data?.currentUser.billingAccount?.billingInfo && (
                      <PaymentOptions onCheckOut={() => {}} showDigitalWallets={true} digitalWalletsOnly={true} />
                    )}
                  {requiresAccountDetails && <GiftingAccountDetails onChange={onChange} {...formValues} />}
                  {giftDeliveryState === GiftDeliveryState.SOMEONE_ELSE && (
                    <GiftingContactInfo onChange={onChange} {...formValues} />
                  )}
                </>
              )}
              {requiresAccountDetails && !isOnlyGiftingCart && <AccountDetails onChange={onChange} {...formValues} />}
              {!isOnlyGiftingCart && (
                <ContactInfo onChange={onChange} showPhoneNumberInput={requiresShippingDetails} {...formValues} />
              )}
              {requiresShippingDetails && (
                <>
                  <ShippingAddressDetails
                    title={
                      isOnlyGiftingCart
                        ? giftDeliveryState === GiftDeliveryState.YOUR_ADDRESS
                          ? 'Your address'
                          : `Recipient's address`
                        : 'Shipping address'
                    }
                    includePhoneField={isOnlyGiftingCart}
                    onChange={onChange}
                    {...formValues}
                  />
                  <ShippingOptionsList
                    anyPreorder={anyPreorder}
                    canExpedite={canExpedite}
                    currentlySelected={formValues.shippingCode}
                    onSelect={(shippingCode) => onChange('shippingCode', shippingCode)}
                  />
                </>
              )}
              <ActionContainer>
                <Button disabled={submitting || submittingForm} type="submit">
                  Save and continue
                </Button>
              </ActionContainer>
              {/* Special case error because it contains HTML elements (link to login page) */}
              {userExistsSignUpError && (
                <div className={styles.formError}>
                  A Fi account already exists for the email address you entered. Please <a href={loginUrl}>sign in</a>{' '}
                  with your existing account.
                </div>
              )}
              <ErrorMessage errorID={errorID} errors={errors} />
            </form>
          </>
        );
      }}
    </Query>
  );
}
