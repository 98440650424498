import React, { useMemo, useRef, ReactNode, useEffect, MutableRefObject } from 'react';
import Flickity, { FlickityOptions } from 'react-flickity-component';

import 'flickity/dist/flickity.min.css';
import styles from './Carousel.module.scss';
import classNames from 'classnames';

interface CarouselProps {
  cellAlign?: 'left' | 'center' | 'right';
  children: ReactNode[];
  onChange?(index: number): void;
  onSettle?(index: number): void;
  className?: string;
  initialIndex?: number;
  controllerRef?: MutableRefObject<CarouselController | undefined>;
  pageDots?: boolean;
  prevNextButtons?: boolean;
  friction?: number;
}

function useFlickityListener(
  ref: React.MutableRefObject<Flickity | undefined>,
  eventName: string,
  callback: ((...args: any[]) => void) | undefined,
) {
  useEffect(() => {
    let listener: ((...args: any[]) => void) | undefined;
    const flickity = (ref.current as any).flkty as Flickity;
    if (callback) {
      listener = (...args: any[]) => callback(...args);
      flickity.on(eventName, listener);
    }
    return () => {
      if (listener) {
        flickity.off(eventName, listener);
      }
    };
  }, [ref, eventName, callback]);
}

export interface CarouselController {
  select(index: number, instant?: boolean): void;
}

export function Carousel({
  cellAlign,
  children,
  className,
  onChange,
  onSettle,
  initialIndex,
  controllerRef,
  pageDots,
  prevNextButtons,
  friction,
}: CarouselProps) {
  // Note: actual flickity instance is flickityRef.current.flkty
  const flickityRef = useRef<Flickity>();

  const flickityOptions = useMemo(
    (): FlickityOptions => ({
      prevNextButtons: prevNextButtons ?? false,
      pageDots: pageDots ?? false,
      cellAlign: cellAlign ?? 'left',
      friction: friction ?? 0.28,
      initialIndex,
      // https://github.com/metafizzy/flickity/issues/366#issuecomment-213642947
      setGallerySize: false,
    }),
    [cellAlign, initialIndex, pageDots, prevNextButtons, friction],
  );

  useFlickityListener(flickityRef, 'change', onChange);
  useFlickityListener(flickityRef, 'settle', onSettle);

  useEffect(() => {
    if (flickityRef.current && controllerRef) {
      const flickity = (flickityRef.current as any).flkty as Flickity;
      controllerRef.current = {
        select(index, instant) {
          flickity.select(index, false, instant);
          if (instant) {
            flickity.reloadCells();
          }
        },
      };
    }
  }, [flickityRef, controllerRef]);

  return (
    <Flickity
      className={classNames(styles.main, className)}
      options={flickityOptions}
      ref={(f) => {
        flickityRef.current = f || undefined;
      }}
      disableImagesLoaded
    >
      {children}
    </Flickity>
  );
}
