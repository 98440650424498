import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../lib/authentication';
import { logInternalError } from '../../lib/errors';
import * as types from '../../types';
import styles from './AppBar.module.scss';

interface LoginLogoutButtonProps {
  onClick?: () => void;
}

export default function LoginLogoutButton({ onClick }: LoginLogoutButtonProps) {
  const loggedIn = useSelector((state: types.AppState) => !!state.session);
  const handleClick = useCallback(
    (evt: any) => {
      if (loggedIn) {
        evt.preventDefault();

        logout().catch((err: Error) => logInternalError(err));
      }

      if (onClick) {
        onClick();
      }
    },
    [loggedIn, onClick],
  );

  return (
    <Link className={styles.link} to={loggedIn ? '#' : '/login'} onClick={handleClick}>
      {loggedIn ? 'Sign out' : 'Sign in'}
    </Link>
  );
}
