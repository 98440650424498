import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import styles from './GiftFi.module.scss';
import ProductSummary from '../ProductDetails/Series3CollarDetails/components/ProductSummary';
import FAQ from '../ProductDetails/Series3CollarDetails/components/FAQ';
import { getFiGrowthBook } from '../../lib/growthbook';
import { Redirect, useHistory } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import CollarAddToBagButton from '../ProductDetails/Series3CollarDetails/components/CollarAddToBagButton';
import MembershipValueProps from '../ProductDetails/Series3CollarDetails/components/MembershipValueProps';
import HideChatWidget from '../../components/ZendeskChat/HideChatWidget';
import classNames from 'classnames';
import { useOnChangeHandlers } from '../ProductDetails/Series3CollarDetails/Series3CollarDetails';
import useVariantSelector from '../ProductDetails/hooks/useVariantSelector';
import { CarouselController } from '../../components/Carousel';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../types';
import useSubscriptionProducts from '../../hooks/useSubscriptionProducts';
import useSubscriptionSelector from '../ProductDetails/hooks/useSubscriptionSelector';
import { DesktopImageGallery } from '../ProductDetails/Series3CollarDetails/components/ImageGallery';
import NavBar from '../../components/AppBar/NavBar';
import * as events from '../../lib/analytics/events';
import { cartActions as storeCartActions } from '../../reducers/storeCart';
import { commonDetailsForSize } from '../../lib/size';
import { commonDetailsForSubscription } from '../../lib/subscription';
import ColorOptions from '../ProductDetails/components/ColorOptions';
import SizeOptions from '../ProductDetails/components/SizeOptions';
import {
  BandVariantGroup,
  getFilteredVariants,
} from '../ProductDetails/components/BandVariantOptions/BandVariantOptions';
import GiftFiSubscriptionOptions from '../../components/SubscriptionOptions/GiftFiSubscriptionOptions';

const ctaButtonText = (hasUpdatedSize: boolean, hasUpdatedSubscriptionSku: boolean): string => {
  if (!hasUpdatedSubscriptionSku) {
    return 'Pick Your Membership';
  }

  if (!hasUpdatedSize) {
    return 'Pick Your Size';
  }

  return 'Add To Cart';
};

const ctaDescriptionText = (
  selectedVariant: types.IVariant,
  selectedSubscription: types.ISubscriptionProduct | undefined,
): string => {
  const toTitleCase = (str: string): string => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  let description =
    toTitleCase(selectedVariant.options.color) + ' · ' + commonDetailsForSize(selectedVariant.options.size).sizeName;
  if (selectedSubscription) {
    description += ' · ' + commonDetailsForSubscription(selectedSubscription).subscriptionName;
  }
  return description;
};

export default function GiftFi() {
  const dispatch = useDispatch();
  const history = useHistory();

  const sizePickerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const subscriptionPickerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const desktopCarouselControllerRef = useRef<CarouselController>();
  const [currentCarouselImage, setCurrentCarouselImage] = useState<number>(0);

  const products = useSelector((state: types.AppState) => state.config.products);
  const product = useMemo(
    () => products.find((p) => p.id === 'smart-collar-v3') as types.ICollarKitProduct,
    [products],
  );
  const subscriptionProducts: types.ISubscriptionProduct[] = useSubscriptionProducts(types.series3CollarId);

  const { selectedSubscription, selectSubscriptionSku, hasUpdatedSubscriptionSku } = useSubscriptionSelector(
    subscriptionProducts,
    undefined,
    undefined,
  );
  const { onColorChange, onSizeChange, onSelectedVariantChange } = useOnChangeHandlers({
    desktopCarouselControllerRef,
    priceInCents: product.priceInCents,
    productName: product.name,
    subscriptionPriceInCents: selectedSubscription?.priceInCents,
    currentCarouselImage,
    isUpgradePath: false,
  });

  const { changeVariantOptions, selectedVariant, hasUpdatedSize } = useVariantSelector({
    onColorChange,
    onSelectedVariantChange,
    onSizeChange,
    variants: product.variants,
    useQueryParams: false,
  });

  useEffect(() => {
    events.series3CollarViewed();
  }, []);

  const addToBag = useCallback(
    (variant: types.IVariant, subscriptionProduct: types.ISubscriptionProduct) => {
      events.series3CollarAddedToBag({
        color: variant.options.color,
        priceInCents: product.priceInCents,
        productName: product.name,
        size: variant.options.size,
        sku: variant.sku,
      });
      events.series3CollarActionButtonClick({
        color: variant.options.color,
        priceInCents: product.priceInCents,
        productName: product.name,
        size: variant.options.size,
        sku: variant.sku,
      });

      events.series3SubscriptionAddedToBag({
        priceInCents: subscriptionProduct.priceInCents,
        productId: subscriptionProduct.id,
        productName: subscriptionProduct.name,
        sku: subscriptionProduct.sku,
      });

      dispatch(
        storeCartActions.addBundleCartItem({
          sku: variant.sku,
          subscriptionSku: subscriptionProduct.sku,
          isGift: true,
        }),
      );

      history.push(AppPaths.Bag);
    },
    [product.priceInCents, product.name, dispatch, history],
  );

  const onAddToBagClicked = useCallback(() => {
    const offsetY = Array.from(document.querySelectorAll('.promotionBanner')).reduce(
      (total, el) => (total + (el as HTMLElement).style.zIndex === '-1' ? 0 : (el as HTMLElement).offsetHeight),
      0,
    );

    if (!selectedSubscription || !hasUpdatedSubscriptionSku) {
      if (subscriptionPickerRef.current) {
        gsap.to(window, {
          duration: 1.2,
          scrollTo: { y: subscriptionPickerRef.current, offsetY: offsetY },
          ease: 'scrollTo',
        });
      }
      return false;
    }

    if (!hasUpdatedSize) {
      if (sizePickerRef.current) {
        gsap.to(window, {
          duration: 1.2,
          scrollTo: { y: sizePickerRef.current, offsetY: offsetY },
          ease: 'scrollTo',
        });
      }
      return false;
    }

    addToBag(selectedVariant, selectedSubscription);
    return true;
  }, [selectedSubscription, addToBag, selectedVariant, hasUpdatedSize, hasUpdatedSubscriptionSku]);

  const giftFiEnabled = getFiGrowthBook().getFeatureValue<boolean>('ecom-gift-fi', false);
  if (!giftFiEnabled) {
    return <Redirect to={AppPaths.NotFound} />;
  }

  return (
    <>
      <div
        className={classNames(styles.productContainer, styles.ecomFlowRevamp, 'ecom-flow-revamp', {
          'ecom-flow-revamp-light': currentCarouselImage === 2 || currentCarouselImage === 5,
        })}
      >
        <div className={classNames(styles.galleryContainer, { [styles.nanoGalleryContainer]: true })}>
          <DesktopImageGallery
            carouselControllerRef={desktopCarouselControllerRef}
            selectedVariant={selectedVariant}
            onChange={(idx) => setCurrentCarouselImage(idx)}
          >
            <NavBar />
          </DesktopImageGallery>
        </div>
        <div className={classNames(styles.productDetails, 'product-details')}>
          <div className={styles.productTitleContainer}>
            <div className={styles.productTitle}>Gift a Fi Series 3</div>
            <div className={styles.productDescription}>
              Give a Series 3 collar to your friends and family to keep their dog safe, healthy and active—we’ll even
              wrap it for you.
            </div>
            <div className={styles.productTitleDescription}>Pick a color to get started</div>
          </div>
          <div className={styles.variantOptions}>
            <ColorOptions
              hasNonFiBandMaker={false}
              hasAnyUpchargedBands={false}
              selectedColorOption={selectedVariant.options.color}
              selectedVariant={selectedVariant}
              selectedBandVariantGroup={BandVariantGroup.FiOriginals}
              onVariantGroupSelect={() => {}}
              onSelect={(newColorOption: types.ColorOption) => {
                changeVariantOptions({ color: newColorOption });
              }}
              variants={getFilteredVariants(BandVariantGroup.FiOriginals, product.variants)}
              showTitle={false}
              selectedColorLabelPosition={'bottom'}
            />
          </div>
          <GiftFiSubscriptionOptions
            onSubscriptionChange={selectSubscriptionSku}
            selectedSubscriptionSku={selectedSubscription?.sku}
            subscriptionProducts={subscriptionProducts}
            title={'Pick a gift value'}
            subscriptionPickerRef={subscriptionPickerRef}
            startAsUnselected={true}
          />
          <div
            ref={sizePickerRef}
            className={classNames({ [styles.disabledSection]: !selectSubscriptionSku || !hasUpdatedSubscriptionSku })}
          >
            <SizeOptions
              bandSeries={types.BandSeries.F3}
              selectedVariant={selectedVariant}
              onOptionChange={changeVariantOptions}
              title={'Pick a size'}
              variants={getFilteredVariants(BandVariantGroup.FiOriginals, product.variants)}
              startAsUnselected={true}
              showGiftFi={false}
            />
          </div>
          <MembershipValueProps />
          <CollarAddToBagButton
            disabled={!selectedSubscription}
            onAddToBag={onAddToBagClicked}
            ctaText={ctaButtonText(hasUpdatedSize, hasUpdatedSubscriptionSku)}
            fadeInRef={!hasUpdatedSize ? sizePickerRef : undefined}
            isVisibleOverride={hasUpdatedSize}
            descriptionText={
              hasUpdatedSize && hasUpdatedSubscriptionSku
                ? ctaDescriptionText(selectedVariant, selectedSubscription)
                : null
            }
          />
        </div>
      </div>
      <HideChatWidget />
      <ProductSummary />
      <FAQ />
    </>
  );
}
