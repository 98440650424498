import classNames from 'classnames';
import React from 'react';
import * as types from '../../../../types';
import RoundOptionButton from '../RoundOptionButton';
import styles from './ColorOptionButton.module.scss';

interface ColorOptionButtonProps {
  available: boolean;
  colorOption: types.ColorOption;
  selected: boolean;
  onSelect: (colorOption: types.ColorOption) => void;
}

function contentsForColorOption(colorOption: types.ColorOption) {
  if (colorOption === types.ColorOption.Yellow) {
    return <div className={styles.colorYellow} />;
  } else if (colorOption === types.ColorOption.Gray) {
    return <div className={styles.colorGray} />;
  } else if (colorOption === types.ColorOption.Blue) {
    return <div className={styles.colorBlue} />;
  } else if (colorOption === types.ColorOption.Pink) {
    return <div className={styles.colorPink} />;
  } else if (colorOption === types.ColorOption.YellowScribble) {
    return <div className={classNames(styles.colorYellow, styles.scribble)} />;
  } else if (colorOption === types.ColorOption.GrayScribble) {
    return <div className={classNames(styles.colorGray, styles.scribble)} />;
  } else if (colorOption === types.ColorOption.BlueScribble) {
    return <div className={classNames(styles.colorBlue, styles.scribble)} />;
  } else if (colorOption === types.ColorOption.PinkScribble) {
    return <div className={classNames(styles.colorPink, styles.scribble)} />;
  } else if (colorOption === types.ColorOption.Strava) {
    return <div className={classNames(styles.colorStrava, styles.strava)} />;
  } else if (colorOption === types.ColorOption.LandsharkSupplyBlackBlue) {
    return <div className={styles.LandsharkSupplyBlackBlue} />;
  } else if (colorOption === types.ColorOption.LandsharkSupplyBlackRed) {
    return <div className={styles.LandsharkSupplyBlackRed} />;
  } else if (colorOption === types.ColorOption.MimiGreenPinkReflective) {
    return <div className={styles.MimiGreenPinkReflective} />;
  } else if (colorOption === types.ColorOption.MimiGreenRedBiothane) {
    return <div className={styles.MimiGreenRedBiothane} />;
  } else if (colorOption === types.ColorOption.RopeHoundsBubblegumPop) {
    return <div className={styles.RopeHoundsBubblegumPop} />;
  } else if (colorOption === types.ColorOption.RopeHoundsMountainTop) {
    return <div className={styles.RopeHoundsMountainTop} />;
  } else if (colorOption === types.ColorOption.StuntPuppyFishtailTeal) {
    return <div className={styles.StuntPuppyFishtailTeal} />;
  } else if (colorOption === types.ColorOption.StuntPuppyFloraFrolic) {
    return <div className={styles.StuntPuppyFloraFrolic} />;
  } else if (colorOption === types.ColorOption.StuntPuppyGoDog) {
    return <div className={styles.StuntPuppyGoDog} />;
  } else if (colorOption === types.ColorOption.StuntPuppyPinesBlue) {
    return <div className={styles.StuntPuppyPinesBlue} />;
  } else if (colorOption === types.ColorOption.StuntPuppyTealBiothane) {
    return <div className={styles.StuntPuppyTealBiothane} />;
  } else if (colorOption === types.ColorOption.ZeeDogPhantom) {
    return <div className={styles.ZeeDogPhantom} />;
  }
}

export default function ColorOptionButton({ available, selected, colorOption, onSelect }: ColorOptionButtonProps) {
  return (
    <RoundOptionButton
      available={available}
      contents={contentsForColorOption(colorOption)}
      onSelect={onSelect}
      selected={selected}
      value={colorOption}
    />
  );
}
