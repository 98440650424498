import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as PayPalLogo } from '../../../../assets/images/icons/paypal.svg';
import CreditCardLogo from '../../../../components/CreditCardLogo/CreditCardLogo';
import useCheckoutPaths from '../../../../hooks/useCheckoutPaths';
import { expectUnreachable } from '../../../../lib/util';
import styles from '../../../../styles/lineItem.module.scss';
import * as types from '../../../../types';

interface BillingLineItemProps {
  billingInfo: types.BillingInfo;
}

function PayPalBillingInfo() {
  return (
    <div className={styles.lineItem}>
      <div className={styles.itemRow}>
        <div className={styles.lineItemImage}>
          <PayPalLogo />
        </div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>Pay with PayPal</div>
        </div>
      </div>
    </div>
  );
}

function CreditCardBillingInfo({ type, lastFour, month, year }: types.CreditCardInfo) {
  const checkoutPaths = useCheckoutPaths();

  return (
    <div className={styles.lineItem}>
      <div className={styles.itemRow}>
        <div className={styles.lineItemImage}>
          <CreditCardLogo cardType={type} />
        </div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            {/* sentry-mask class is used to mask text in Sentry replays */}
            <div>
              {type} ending in <span className="sentry-mask">{lastFour}</span>
            </div>
            <div>
              Expires{' '}
              <span className="sentry-mask">
                {month}/{year}
              </span>
            </div>
          </div>
          <div className={styles.removeButtonRow}>
            <Link to={checkoutPaths.Payment}>Edit</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function BillingLineItem({ billingInfo: { paymentInfo } }: BillingLineItemProps) {
  if (paymentInfo.__typename === 'ObfuscatedCardInfo') {
    return <CreditCardBillingInfo {...paymentInfo} />;
  } else if (paymentInfo.__typename === 'PayPalInfo') {
    return <PayPalBillingInfo />;
  } else {
    expectUnreachable(paymentInfo);
    return null;
  }
}
