import React, { useMemo } from 'react';
import Select, { GroupedOptionsType, OptionsType, OptionTypeBase } from 'react-select';
import { ReactComponent as DropdownIndicator } from '../../../../assets/images/icons/chevron_icon.svg';
import usStates from '../../../../lib/usStates';
import styles from '../../../../components/Dropdown/Dropdown.module.scss';

interface DropdownProps<T extends OptionTypeBase> {
  id?: string;
  placeholder?: React.ReactNode;
  options?: OptionsType<T> | GroupedOptionsType<T>;
  selectedOption?: T;
  onChange?: (selectedValue: T | null | undefined) => void;
}

/**
 * A wrapper around react-select component to add consistent CSS
 */
function Dropdown<T extends OptionTypeBase>({ id, placeholder, options, selectedOption, onChange }: DropdownProps<T>) {
  return (
    <Select
      className={styles.container}
      classNamePrefix={styles.container}
      inputId={id}
      options={options}
      value={selectedOption}
      placeholder={placeholder}
      onChange={onChange}
      components={{
        DropdownIndicator: () => <DropdownIndicator />,
      }}
      styles={{
        option: (base) => ({
          ...base,
          cursor: 'pointer',
        }),
      }}
      menuShouldScrollIntoView={false}
    />
  );
}

interface USStateSelectorProps {
  state: string | undefined;
  onChange: (newState: string) => void;
}

export default function USStateSelector({ state, onChange }: USStateSelectorProps) {
  const options = useMemo(
    () =>
      usStates.map((item) => ({
        value: item.abbreviation,
        label: item.name,
      })),
    [],
  );

  const selectedState = useMemo(() => {
    if (!state) {
      return;
    }

    return options.find(({ value }) => value === state);
  }, [options, state]);

  return (
    <Dropdown
      id="state"
      options={options}
      selectedOption={selectedState}
      placeholder="State"
      onChange={(option) => {
        if (option) {
          onChange(option.value);
        }
      }}
    />
  );
}
