import classNames from 'classnames';
import React from 'react';
import { centsToDollars } from '../../lib/util';
import styles from '../../styles/lineItem.module.scss';

interface AccountCreditAppliedLineItemProps {
  appliedAccountBalanceInCents: number;
}

export default function AccountCreditAppliedLineItem({
  appliedAccountBalanceInCents,
}: AccountCreditAppliedLineItemProps) {
  if (appliedAccountBalanceInCents <= 0) {
    return null;
  }

  return (
    <div className={styles.lineItem}>
      <div className={styles.itemRow}>
        <div className={classNames(styles.lineItemImage, styles.empty)}></div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            <div className={styles.lineItemPriceRow}>
              <div>Account balance applied!</div>
              <div className={styles.lineItemPrice}>-${centsToDollars(appliedAccountBalanceInCents)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
