import React from 'react';
import styles from './NanoProductDetails.module.scss';
import { ReactComponent as Star } from '../../../../../assets/images/icons/star.svg';
import featureImageOne from '../../../../../assets/images/nano/feature_1.png';
import featureImageTwo from '../../../../../assets/images/nano/feature_2.png';
import featureImageThree from '../../../../../assets/images/nano/feature_3.png';
import featureIconOne from '../../../../../assets/images/nano/feature_1_icon.png';
import featureIconTwo from '../../../../../assets/images/nano/feature_2_icon.png';
import featureIconThree from '../../../../../assets/images/nano/feature_3_icon.png';
import socialProofZero from '../../../../../assets/images/nano/social_proof_0.png';
import socialProofOne from '../../../../../assets/images/nano/social_proof_1.png';
import socialProofTwo from '../../../../../assets/images/nano/social_proof_2.png';
import socialProofThree from '../../../../../assets/images/nano/social_proof_3.png';
import socialProofFour from '../../../../../assets/images/nano/social_proof_4.png';
import socialProofFive from '../../../../../assets/images/nano/social_proof_5.png';
import socialProofSix from '../../../../../assets/images/nano/social_proof_6.png';
import socialProofSeven from '../../../../../assets/images/nano/social_proof_7.png';
import Flickity from 'react-flickity-component';

export default function NanoProductDetails() {
  return (
    <div className={styles.nanoProductDetailsContainer}>
      <div className={styles.testimonialContainer}>
        <div className={styles.testimonialTitle}>The smartest collar ever built</div>
        <div className={styles.testimonialImageContainer}>
          <div className={styles.testimonialStars}>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </div>
          <div className={styles.testimonialMessage}>
            “Within minutes of our dog wandering off, I was able to locate him in the app and find him.”
          </div>
          <div className={styles.testimonialUser}>Buddy’s Dad</div>
        </div>
      </div>
      <div className={styles.featuresContainer}>
        <div className={styles.featureContainer}>
          <div className={styles.featureDescription}>
            <img className={styles.featureIcon} src={featureIconOne} alt="Live tracking icon" />
            Get them home as soon as possible with Escape Alerts + Live Tracking.
          </div>
          <img className={styles.featureImage} src={featureImageOne} alt="Live tracking feature" />
        </div>
        <div className={styles.featureContainer}>
          <div className={styles.featureDescription}>
            <img className={styles.featureIcon} src={featureIconTwo} alt="Activity tracking icon" />
            Stay motivated with steps and activity goals personalized to your dog.
          </div>
          <img className={styles.featureImage} src={featureImageTwo} alt="Activity tracking feature" />
        </div>
        <div className={styles.featureContainer}>
          <div className={styles.featureDescription}>
            <img className={styles.featureIcon} src={featureIconThree} alt="Health report icon" />
            Share a detailed health report with your vet, to spot trends and potential issues.
          </div>
          <img className={styles.featureImage} src={featureImageThree} alt="Health report feature" />
        </div>
      </div>
      <div className={styles.socialProofContainer}>
        <div className={styles.socialProofTitle}>Connect with dogs + dog lovers across the country</div>
        <div className={styles.socialProofImageContainer}>
          <img className={styles.socialProofImage} src={socialProofZero} alt="Social pet zero" />
          <img className={styles.socialProofImage} src={socialProofOne} alt="Social pet one" />
          <img className={styles.socialProofImage} src={socialProofTwo} alt="Social pet two" />
          <img className={styles.socialProofImage} src={socialProofThree} alt="Social pet three" />
          <img className={styles.socialProofImage} src={socialProofFour} alt="Social pet four" />
          <img className={styles.socialProofImage} src={socialProofFive} alt="Social pet five" />
          <img className={styles.socialProofImage} src={socialProofSix} alt="Social pet six" />
          <img className={styles.socialProofImage} src={socialProofSeven} alt="Social pet seven" />
        </div>
        <div className={styles.socialProofImageCarousel}>
          <Flickity options={{ autoPlay: 3000, wrapAround: true, prevNextButtons: false }}>
            <div className={styles.carouselCell}>
              <img className={styles.socialProofImage} src={socialProofZero} alt="Social pet zero" />
            </div>
            <div className={styles.carouselCell}>
              <img className={styles.socialProofImage} src={socialProofOne} alt="Social pet one" />
            </div>
            <div className={styles.carouselCell}>
              <img className={styles.socialProofImage} src={socialProofTwo} alt="Social pet two" />
            </div>
            <div className={styles.carouselCell}>
              <img className={styles.socialProofImage} src={socialProofThree} alt="Social pet three" />
            </div>
            <div className={styles.carouselCell}>
              <img className={styles.socialProofImage} src={socialProofFour} alt="Social pet four" />
            </div>
            <div className={styles.carouselCell}>
              <img className={styles.socialProofImage} src={socialProofFive} alt="Social pet five" />
            </div>
            <div className={styles.carouselCell}>
              <img className={styles.socialProofImage} src={socialProofSix} alt="Social pet six" />
            </div>
            <div className={styles.carouselCell}>
              <img className={styles.socialProofImage} src={socialProofSeven} alt="Social pet seven" />
            </div>
          </Flickity>
        </div>
      </div>
    </div>
  );
}
