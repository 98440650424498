import * as React from 'react';
import styles from './CouponBanner.module.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../types';

export interface CouponBannerProps {
  bannerText: string;
  onDismiss(): void;
  error?: boolean;
}

export default function CouponBanner({ bannerText, onDismiss, error }: CouponBannerProps) {
  const showPromoBannerImprovements = useSelector(
    (state: AppState) => !!state.config.siteConfig.enableEcommPromoBannerImprovements,
  );
  /**
   * Show dismiss button if:
   * 1. showPromoBannerImprovements feature flag is off.
   * 2. showPromoBannerImprovements feature flag is on and an error occurred.
   */
  const showDismissButton = showPromoBannerImprovements ? (error ? true : false) : true;

  return (
    <>
      <div className={styles.main}>
        <div className={styles.content}>{bannerText}</div>
        {showDismissButton && (
          <div className={styles.dismissButton} onClick={onDismiss}>
            <span className={styles.dismissIcon} />
          </div>
        )}
      </div>
    </>
  );
}
