import { useSelector } from 'react-redux';
import * as types from '../types';
import { isKiosk } from '../lib/util';

function useIsInPersonEvent(): boolean {
  return useSelector((state: types.AppState) => !!state.inPersonEvent);
}

export default function useIsShoppingInPerson(): boolean {
  const isInPersonEvent = useIsInPersonEvent();
  return isKiosk() || isInPersonEvent;
}
