import React, { useState, useEffect, useMemo, useContext } from 'react';
import { DateTime, Info } from 'luxon';
import Chooser from '../../../../components/Chooser';
import GiftCardContext, { clearError, setDeliverAtIsSet, updateGiftCardDelivery } from '../../GiftCardContext';
import SimpleError from '../SimpleError/SimpleError';
import styles from './DeliveryDate.module.scss';

const DELIVERY_HOUR = 11;

/**
 * Allow the user to select a delivery date, or to deliver immediately.
 */
export default function DeliveryDate() {
  const now = DateTime.local();

  const { giftCardState, giftCardDispatch } = useContext(GiftCardContext);
  const [month, setMonth] = useState<number | null>(null);
  const [day, setDay] = useState<number | null>(null);
  const [year, setYear] = useState(now.year);
  const [deliverNow, setDeliverNow] = useState(false);

  // Set the `deliverAt` date in the gift card store.
  useEffect(() => {
    if (deliverNow) {
      giftCardDispatch(updateGiftCardDelivery({ deliverAt: null }));
      giftCardDispatch(setDeliverAtIsSet(true));
    } else {
      if (day !== null && month !== null) {
        const date = DateTime.fromObject({
          day,
          month,
          year,
          hour: DELIVERY_HOUR,
        });
        giftCardDispatch(updateGiftCardDelivery({ deliverAt: date.toISO() }));
        giftCardDispatch(setDeliverAtIsSet(true));
      } else {
        giftCardDispatch(setDeliverAtIsSet(false));
      }
    }
  }, [day, month, year, deliverNow, giftCardDispatch]);

  const dayOptions = useMemo(() => {
    const result: JSX.Element[] = [];
    let daysInMonth = 31;
    if (month !== null) {
      daysInMonth = DateTime.fromObject({ month, day: 1, year }).daysInMonth;
    }
    for (let i = 1; i <= daysInMonth; i++) {
      result.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return result;
  }, [month, year]);

  const yearOptions = useMemo(() => {
    const result: JSX.Element[] = [];
    for (
      let yearOption = now.year;
      DateTime.fromObject({ year: yearOption, month: 1, day: 1 }).diff(now, 'years').years < 1;
      yearOption++
    ) {
      result.push(
        <option key={yearOption} value={yearOption}>
          {yearOption}
        </option>,
      );
    }
    return result;
  }, [now]);

  /**
   * Ensure the day is valid within a newly set month/year.
   */
  const ensureValidDay = (newValues: { month?: number; year?: number }) => {
    if (month !== null && day !== null) {
      const daysInNewMonth = DateTime.fromObject({
        month,
        year,
        ...newValues,
        day: 1,
      }).daysInMonth;
      if (day > daysInNewMonth) {
        setDay(daysInNewMonth);
      }
    }
  };

  const error = giftCardState.errors.deliverAt;
  const clearDeliverAtError = () => giftCardDispatch(clearError('deliverAt'));

  return (
    <div className={styles.deliveryDateInputContainer}>
      <h3>Delivery date</h3>
      <Chooser
        onSelect={(value) => {
          setDeliverNow(value);
          clearDeliverAtError();
        }}
        selectedOption={deliverNow}
        groups={[
          {
            options: [
              {
                content: 'Now',
                value: true,
              },
              {
                content: 'Choose Date',
                value: false,
              },
            ],
          },
        ]}
        showRadio
        compact
      />
      <div className={styles.deliveryDateSelector}>
        <select
          name="month"
          disabled={deliverNow}
          value={month || 'unselected'}
          onChange={(e) => {
            const value = e.currentTarget.value;
            const newMonth = value === 'unselected' ? null : parseInt(value, 10);
            if (newMonth !== null) {
              ensureValidDay({ month: newMonth });
            }
            setMonth(newMonth);
            clearDeliverAtError();
          }}
        >
          <option value="unselected">Month</option>
          {Info.months().map((monthOption, i) => (
            <option key={i} value={i + 1}>
              {monthOption}
            </option>
          ))}
        </select>
        <select
          name="day"
          disabled={deliverNow}
          value={day || 'unselected'}
          onChange={(e) => {
            const value = e.currentTarget.value;
            const newDay = value === 'unselected' ? null : parseInt(value, 10);
            setDay(newDay);
            clearDeliverAtError();
          }}
        >
          <option value="unselected">Day</option>
          {dayOptions}
        </select>
        <select
          name="year"
          disabled={deliverNow}
          value={year}
          onChange={(e) => {
            const newYear = parseInt(e.currentTarget.value, 10);
            ensureValidDay({ year: newYear });
            setYear(newYear);
            clearDeliverAtError();
          }}
        >
          {yearOptions}
        </select>
      </div>
      <SimpleError error={error} />
    </div>
  );
}
