import * as qs from 'query-string';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../../AppPaths';
import SeriesToggle from '../../../../components/SeriesToggle/SeriesToggle';
import * as types from '../../../../types';
import { Series } from '../../../../types/Series';
import styles from '../../ProductDetails.module.scss';

interface BandSeriesToggleProps {
  selectedSeries: Series;
  seriesProductIdMap: Map<Series, string>;
  variantOptions: types.IVariantOptions;
}

export default function BandSeriesToggle({
  selectedSeries,
  seriesProductIdMap,
  variantOptions,
}: BandSeriesToggleProps) {
  const history = useHistory();

  const seriesOptionSet = useMemo(() => {
    return new Set(seriesProductIdMap.keys());
  }, [seriesProductIdMap]);

  if (!selectedSeries) {
    return null;
  }

  return (
    <div className={styles.seriesToggle}>
      <div className={styles.seriesToggleTitleGroup}>
        <div className={styles.seriesToggleTitle}>Model</div>
      </div>
      <SeriesToggle
        selectedSeries={selectedSeries}
        onSeriesChange={(series) => {
          if (series === selectedSeries) {
            return;
          }

          const productId = seriesProductIdMap.get(series);
          if (!productId) {
            return;
          }

          const queryString = qs.stringify(variantOptions);
          history.replace(`${AppPaths.Products(productId)}?${queryString}`, {
            noScroll: true,
          });
        }}
        seriesOptionSet={seriesOptionSet}
      />
    </div>
  );
}
