import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as types from '../types';

interface UsePromoCodeEligibilityProps {
  cart: types.Cart;
  showCoupons: boolean;
}

interface PromoCodeEligible {
  canApplyCode: true;
  ineligibilityReason?: undefined;
}

interface PromoCodeIneligible {
  canApplyCode: false;
  ineligibilityReason: string;
}

export type IndividualPromoCodeEligibility = PromoCodeEligible | PromoCodeIneligible;

interface PromoCodeEligibility {
  couponCodeEligibility: IndividualPromoCodeEligibility;
  giftCardEligibility: IndividualPromoCodeEligibility;
  referralCodeEligibility: IndividualPromoCodeEligibility;
}

export default function usePromoCodeEligibility({
  cart,
  showCoupons,
}: UsePromoCodeEligibilityProps): PromoCodeEligibility {
  const enableGiftCards = useSelector((state: types.AppState) => !!state.config.siteConfig.enableGiftCards);

  const giftCardEligibility: IndividualPromoCodeEligibility = useMemo(() => {
    if (!enableGiftCards) {
      return {
        canApplyCode: false,
        ineligibilityReason: 'Gift cards cannot be redeemed at this time.',
      };
    }

    return { canApplyCode: true };
  }, [enableGiftCards]);

  const referralCodeEligibility: IndividualPromoCodeEligibility = useMemo(() => {
    if (!showCoupons) {
      return {
        canApplyCode: false,
        ineligibilityReason: 'A referral code may not be applied to this order.',
      };
    }

    return { canApplyCode: true };
  }, [showCoupons]);

  const couponCodeEligibility: IndividualPromoCodeEligibility = useMemo(() => {
    if (!showCoupons) {
      return {
        canApplyCode: false,
        ineligibilityReason: 'A coupon may not be applied to this order.',
      };
    }

    return { canApplyCode: true };
  }, [showCoupons]);

  return {
    couponCodeEligibility,
    giftCardEligibility,
    referralCodeEligibility,
  };
}
