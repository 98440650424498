import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

export type Tenancy = 'prod' | 'test';

/**
 * @returns which tenancy to use for this ecommerce session (recurly instance,
 * user creation, etc).
 */
export default function useTenancy(): Tenancy | undefined {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  if (queryParams.tenancy === 'test') {
    console.info('Using test tenancy');
    return 'test';
  }
  // ignore any other values
  return undefined;
}
