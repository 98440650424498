import classNames from 'classnames';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import CheckoutContext from '../../lib/CheckoutContext';
import styles from '../../styles/lineItem.module.scss';
import { ReactComponent as GiftBox } from '../../assets/images/icons/icon_gift_box.svg';
import RemoveLineItemButton from '../RemoveLineItemButton';
import { inCartS3CollarCount } from './CouponLineItemV2';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import { getCartV2FormattedTotal } from '../CartSummary/CartActions';
import { householdDiscountReason } from '../MultidogDiscount/MultidogDiscountExistingCollars';
import { useCartPricing } from '../../contexts/CartPricingContext';

interface ReferralCodeLineItemProps {
  allowRemoveReferralCode?: boolean;
  appliedDiscountInCents: number;
  validationError?: string | null;
  referreeFreeMonth?: boolean;
  refereeCartDiscount?: boolean;
}

export default function ReferralCodeLineItem({
  allowRemoveReferralCode: allowRemoveCode,
  appliedDiscountInCents,
  validationError,
  referreeFreeMonth,
  refereeCartDiscount,
}: ReferralCodeLineItemProps) {
  const dispatch = useDispatch();
  const cartPricing = useCartPricing();
  const { checkoutActions, cart } = useContext(CheckoutContext);
  // It's possible that using a referral code will only get the user a free month and no discount on the cart price
  // (e.g. activation fee is already waived)
  const willReceiveAnyBenefits = referreeFreeMonth || refereeCartDiscount;
  const cartHouseholdDiscount = householdDiscountReason(cartPricing);
  if (validationError || !willReceiveAnyBenefits || cartHouseholdDiscount) {
    return null;
  }
  const s3CollarCount = inCartS3CollarCount(Object.values(cart.cartItems));

  return (
    <div className={classNames('coupon-line-item-v2', styles.lineItem, { [styles.noRemove]: !allowRemoveCode })}>
      {allowRemoveCode && (
        <div className={styles.removeButtonRow}>
          <RemoveLineItemButton
            onClick={() => {
              dispatch(checkoutActions.removeReferral({}));
            }}
          />
        </div>
      )}
      <div className={styles.itemRow}>
        <div className={classNames(styles.lineItemImage)}>
          <GiftBox />
        </div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            <div className={styles.lineItemPriceRow}>
              <div className={styles.lineItemPromoContainer}>
                <div className={styles.lineItemPromoApplied}>Referral code applied</div>
                {referreeFreeMonth && (
                  <div className={styles.lineItemPrice}>
                    <CheckIcon /> +1 extra month FREE (${getCartV2FormattedTotal(appliedDiscountInCents)} value)
                  </div>
                )}
                {s3CollarCount && (
                  <div className={styles.lineItemPrice}>
                    <CheckIcon /> No activation fee (${s3CollarCount * 20} off)
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
