import { useCartPricing } from '../contexts/CartPricingContext';

/**
 * Returns true if we should use the recurring payment request for Apple Pay.
 */
export default function useApplePayRecurring() {
  const cartPricing = useCartPricing();

  const recurringCharges = cartPricing.totalRecurringCharges;
  if (!recurringCharges) {
    return false;
  }

  /**
   * Apple Pay recurring payment request provides a better billing token for recurring charges. However, it
   * only supports recurring charges on a single billing cadence. The cart pricing API groups recurring charges
   * by billing cadence, so if there are multiple billing cadences, we should not use the recurring payment request.
   */
  return recurringCharges.length === 1;
}
