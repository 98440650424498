import Cookies from 'js-cookie';
import { DEV_MODE } from './util';
import { DateTime } from 'luxon';
import { REFERRAL_COOKIE_EXPIRATION_HOURS, REFERRAL_COOKIE_NAME, ReferralCookieData } from './util/referralCookie';

export default function setReferralCookie(code: string, name?: string) {
  const data: ReferralCookieData = { code, name };
  Cookies.set(REFERRAL_COOKIE_NAME, JSON.stringify(data), {
    expires: DateTime.utc().plus({ hours: REFERRAL_COOKIE_EXPIRATION_HOURS }).toJSDate(),
    domain: DEV_MODE ? undefined : 'tryfi.com',
    secure: !DEV_MODE,
  });
}
