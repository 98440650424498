import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import AppPaths from '../../../../AppPaths';
import { useHistory, useLocation } from 'react-router-dom';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import { petsWithActiveSubscriptionQuery, applyMultidogDiscountMutation } from '../../../../graphql-operations';
import { gqlTypes } from '../../../../types';
import { useQuery, useMutation } from '@apollo/client';
import { getLoadingOrErrorElement } from '../../../../lib/util';
import classNames from 'classnames';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';
import { BaseInAppCancellationState } from '../util/types';

function monthlyBillingCandenceString(renewalMonths: number) {
  switch (renewalMonths) {
    case 1:
      return 'Billed monthly';
    case 3:
      return 'Billed quarterly';
    case 6:
      return 'Billed semi-annually';
    case 12:
      return 'Billed annually';
    default:
      return `Billed every ${renewalMonths} months`;
  }
}
function yearlyBillingCandenceString(renewalMonths: number) {
  switch (renewalMonths) {
    case 12:
      return 'Billed annually';
    default:
      return `Billed every ${renewalMonths / 12} years`;
  }
}

function subscriptionLengthString(renewalMonths?: number | null, renewalYears?: number | null) {
  if (renewalMonths && renewalMonths >= 12 && !renewalYears) {
    renewalYears = Math.round(renewalMonths / 12);
  }

  if (renewalYears !== null) {
    return `${renewalYears} year${renewalYears === 1 ? '' : 's'}`;
  } else if (renewalMonths !== null) {
    return `${renewalMonths} month${renewalMonths === 1 ? '' : 's'}`;
  } else {
    return 'Unknown';
  }
}

function PetCurrentSubscriptionDetails(pet: any, idx: number) {
  const subDetails = pet.device?.subscription?.subscriptionOption ?? null;
  if (!subDetails) {
    return null;
  }
  const currentRate = subDetails.priceInCents / 100;
  const discountedRate = Math.round((subDetails.priceInCents * 0.95) / 100);
  const renewalMonths = subDetails.renewalMonths;

  let billingCadenceString = '';
  switch (subDetails.billingCadence) {
    case gqlTypes.BillingCadence.MONTH:
      billingCadenceString = monthlyBillingCandenceString(renewalMonths);
      break;
    case gqlTypes.BillingCadence.YEAR:
      billingCadenceString = yearlyBillingCandenceString(renewalMonths);
      break;
  }

  const subscriptionLength = subscriptionLengthString(subDetails.renewalMonths, subDetails.renewalYears);

  return (
    <div className={styles.multidogDiscount} key={idx}>
      <div className={classNames(styles.column, styles.avatar)}>
        {pet.photos.first?.image?.fullSize && <img alt={pet.name} src={pet.photos.first.image.fullSize} />}
      </div>
      <div className={classNames(styles.column)}>
        <div className={classNames(styles.row, styles.name)}>{pet.name}</div>
        <div className={classNames(styles.row, styles.name)}>{subscriptionLength}</div>
        <div className={classNames(styles.row)}>{billingCadenceString}</div>
        <div className={classNames(styles.row)}>Multi-dog discount: 5% off</div>
      </div>
      <div className={classNames(styles.column, styles.prices)}>
        <div className={classNames(styles.row)}>&nbsp;</div>
        <div className={classNames(styles.row)}>&nbsp;</div>
        <div className={classNames(styles.row, styles.current)}>${currentRate}</div>
        <div className={classNames(styles.row, styles.discounted)}>${discountedRate}</div>
      </div>
    </div>
  );
}

export default function MultidogDiscount() {
  const history = useHistory();
  const location = useLocation<BaseInAppCancellationState>();
  const { subscriptionCategory } = location.state;
  const { device } = usePurchaseSubscriptionContext();
  events.pageLoad({ petId: device.pet!.id }, 'Multidog Discount');

  const activePetsQuery = useQuery<gqlTypes.ECOMMERCE_petsWithActiveSubscription>(petsWithActiveSubscriptionQuery, {});
  const zendeskMutation = useZendeskCancellationTicket({
    analyticsEvent: events.multidogDiscountCancelClicked,
    selectedCancelReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE,
    device,
    subscriptionCategory,
  });
  const [multidogDiscountMutation, multidogDiscountState] = useMutation<gqlTypes.ECOMMERCE_applyMultidogDiscount>(
    applyMultidogDiscountMutation,
    {
      onCompleted: (data) => {
        if (data.applyMultidogDiscount?.success) {
          events.multidogDiscountApplied({
            petId: device.pet?.id,
          });
          history.push({
            pathname: AppPaths.Subscription.MultidogDiscountConfirmation(device.moduleId),
            search: window.location.search,
          });
        } else {
          throw new Error('Something went wrong.');
        }
      },
    },
  );

  const loadingOrErrorElement =
    zendeskMutation.loading ||
    getLoadingOrErrorElement(
      activePetsQuery.loading || multidogDiscountState.loading,
      activePetsQuery.error || multidogDiscountState.error,
    );
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const handleCancel = () => {
    events.multidogDiscountCancelClicked({ petId: device.pet!.id });
    zendeskMutation.mutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription!.id,
          cancellationReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE,
        },
      },
    });
  };

  const petsWithActiveSubscription = activePetsQuery.data?.petsWithActiveSubscription ?? [];
  const isMultidogHousehold = petsWithActiveSubscription.length > 1;
  if (!isMultidogHousehold) {
    throw new Error('This page should only be shown to multi-dog households.');
  }
  const offerFreeMonth = device.shouldOfferFreeMonthDuringCancellation ?? false;
  const isS3Device = device.currentCollarModel === 'S3';
  const offerS3Downgrade = isS3Device && !!device.shouldOfferDowngradeDuringCancellation;

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.multidogDiscountBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.multidogDiscountClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <div className={styles.largeHeader}>
              <h1>
                Discount for
                <br />
                your pack
              </h1>
            </div>
            <p>Enjoy a 5% discount on all your memberships as a multi-dog customer!</p>
            {petsWithActiveSubscription.map((pet, i) => PetCurrentSubscriptionDetails(pet, i))}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              multidogDiscountMutation({
                variables: {
                  input: {
                    petId: device.pet?.id,
                  },
                },
              });
            }}
          >
            Claim multi-dog discount
          </Button>

          {offerS3Downgrade || offerFreeMonth ? (
            <Button
              className={styles.button}
              tertiary={true}
              onClick={() => {
                if (!device.subscription?.id) {
                  throw new Error('Something went wrong.');
                }

                events.multidogDiscountCancelClicked({ petId: device.pet!.id });

                if (offerS3Downgrade) {
                  history.push({
                    pathname: AppPaths.Subscription.DowngradeS3(device.moduleId),
                    state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE },
                    search: window.location.search,
                  });
                } else if (offerFreeMonth) {
                  history.push({
                    pathname: AppPaths.Subscription.FreeMonthOffer(device.moduleId),
                    state: { selectedCancelReason: gqlTypes.zendeskCancellationReason.TOO_EXPENSIVE },
                    search: window.location.search,
                  });
                } else {
                  throw new Error('Something went wrong.');
                }
              }}
            >
              Continue with Cancellation
            </Button>
          ) : (
            <CancelConfirmationModal
              onContinue={() => handleCancel()}
              pageName={'Multidog Discount'}
              petId={device.pet!.id}
            />
          )}
        </div>
      </div>
    </>
  );
}
