import classNames from 'classnames';
import React from 'react';
import Loading from '../Loading';
import styles from './Button.module.scss';

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  elementRef?: React.Ref<HTMLButtonElement>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDisabledClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onTouchEnd?: (event: React.TouchEvent<HTMLButtonElement>) => void;
  secondary?: boolean;
  tertiary?: boolean;
  type?: 'button' | 'submit';
  loading?: boolean;
}

export default function Button({
  children,
  className,
  disabled,
  elementRef,
  onClick,
  onDisabledClick,
  onTouchEnd,
  secondary,
  tertiary,
  type = 'button',
  loading,
}: ButtonProps) {
  return (
    <button
      className={classNames(className, styles.button, {
        [styles.disabled]: disabled || loading,
        [styles.secondary]: secondary,
        [styles.tertiary]: tertiary,
      })}
      // If there is a disabled click action, don't disable the button so that we can still capture the click. It
      // will still be styled as a disabled button and onClick event is still protected by checking `disabled`
      disabled={disabled && onDisabledClick === undefined}
      onClick={(event) => {
        if (onClick && !disabled) {
          onClick(event);
        }

        if (onDisabledClick && disabled) {
          onDisabledClick(event);
        }
      }}
      onTouchEnd={onTouchEnd}
      ref={elementRef}
      type={type}
    >
      {loading ? <Loading className={styles.loading} /> : children}
    </button>
  );
}
