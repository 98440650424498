const S3_UPGRADE_ROOT = '/series3upgrade';
const PURCHASE_SUBSCRIPTION_ROOT = '/purchasesubscription';
const MEMBERSHIP_UPGRADE_ROOT = '/membershipupgrade';
const MONTHLY_UPGRADE_ROOT = '/monthlyupgrade';
const MANAGE_COLLAR_SUBSCRIPTION_ROOT = '/subscription';
const MANAGE_SUPPLEMENTS_SUBSCRIPTION_ROOT = '/supplements';
const TROUBLESHOOT_ROOT = '/troubleshoot';
export const FI_MAKERS_ANCHOR = 'fimakers';

/**
 * Common paths for subscription flows
 */
export interface SubscriptionCheckoutPaths {
  Checkout: (moduleId?: string) => string;
  Complete: (moduleId?: string) => string;
  Payment: (moduleId?: string) => string;
  PlanSelection: (moduleId?: string) => string;
  Root: string;
}

export interface GiftFiActivationPaths {
  AddCreditCard: (moduleId?: string) => string;
  Confirmation: (moduleId?: string) => string;
}

export interface SubscriptionManageCheckoutPaths extends SubscriptionCheckoutPaths {
  RenewConfirmation: (moduleId?: string) => string;
  Cancel: (moduleId?: string) => string;
  CancelOtherReason: (moduleId?: string) => string;
  RequestSent: (moduleId?: string) => string;
  ContactCx: (moduleId?: string) => string;
  SplashPageForDevice: (moduleId?: string) => string;
  UpdatePayment: (moduleId?: string) => string;
  MultidogDiscount: (moduleId?: string) => string;
  MultidogDiscountConfirmation: (moduleId?: string) => string;
  Upgrade: (moduleId?: string) => string;
  Replace: (moduleId?: string) => string;
  DogDoesNotRunAway: (moduleId?: string) => string;
  FreeMonthOffer: (moduleId?: string) => string;
  FreeMonthConfirmed: (moduleId?: string) => string;
  KeepMembershipConfirmation: (moduleId?: string) => string;
  DowngradeS2: (moduleId?: string) => string;
  DowngradeS2Confirmed: (moduleId?: string) => string;
  DowngradeS3: (moduleId?: string) => string;
  DowngradeS3Confirmed: (moduleId?: string) => string;
  TooExpensive: (moduleId?: string) => string;
  TellUsMore: (moduleId?: string) => string;
  MeetMakers: (moduleId?: string) => string;
  SpecialOffers: (moduleId?: string) => string;
  SpecialOffersConfirmed: (moduleId?: string) => string;
  PauseMembership: (moduleId?: string) => string;
  PauseMembershipConfirmed: (moduleId?: string) => string;
  ResumeMembershipConfirmed: (moduleId?: string) => string;
}

export interface SupplementsManageCheckoutPaths {
  Manage: (recurlySubscriptionUuid?: string) => string;
  Survey: (recurlySubscriptionUuid?: string) => string;
  SkipShipment: (recurlySubscriptionUuid?: string) => string;
  ChangeFrequency: (recurlySubscriptionUuid?: string) => string;
  Discount: (recurlySubscriptionUuid?: string) => string;
  AddPayment: string;
  Root: string;
}

export interface TroubleshootPaths {
  Start: (moduleId?: string) => string;
  TellUsMore: (moduleId?: string) => string;
  ContactCx: (moduleId?: string) => string;
  Replace: (moduleId?: string) => string;
  Upgrade: (moduleId?: string) => string;
  RequestSent: (moduleId?: string) => string;
  Other: (moduleId?: string) => string;
  Root: string;
}

/**
 * PurchaseSubscription will be for starting a new subscription on a device
 */
const purchaseSubscriptionPaths: SubscriptionCheckoutPaths = {
  Checkout: (moduleId = ':moduleId') => `${PURCHASE_SUBSCRIPTION_ROOT}/${moduleId}/checkout`,
  Complete: (moduleId = ':moduleId') => `${PURCHASE_SUBSCRIPTION_ROOT}/${moduleId}/complete`,
  Payment: (moduleId = ':moduleId') => `${PURCHASE_SUBSCRIPTION_ROOT}/${moduleId}/payment`,
  PlanSelection: (moduleId = ':moduleId') => `${PURCHASE_SUBSCRIPTION_ROOT}/${moduleId}`,
  Root: PURCHASE_SUBSCRIPTION_ROOT,
};

/**
 * Starting a new subscription on a device when the device was purchased as a gift
 */
const giftFiActivationPaths: GiftFiActivationPaths = {
  AddCreditCard: (moduleId = ':moduleId') => `/gift-fi/activation/${moduleId}`,
  Confirmation: (moduleId = ':moduleId') => `/gift-fi/activation/${moduleId}/confirmation`,
};

/**
 * MembershipUpgrade will be for upgrading the plan on an existing subscription
 */
const membershipUpgradePaths: SubscriptionCheckoutPaths = {
  Checkout: (moduleId = ':moduleId') => `${MEMBERSHIP_UPGRADE_ROOT}/${moduleId}/checkout`,
  Complete: (moduleId = ':moduleId') => `${MEMBERSHIP_UPGRADE_ROOT}/${moduleId}/complete`,
  Payment: (moduleId = ':moduleId') => `${MEMBERSHIP_UPGRADE_ROOT}/${moduleId}/payment`,
  PlanSelection: (moduleId = ':moduleId') => `${MEMBERSHIP_UPGRADE_ROOT}/${moduleId}`,
  Root: MEMBERSHIP_UPGRADE_ROOT,
};

/**
 * MonthlyUpgrade is for a promotion for users on S3 monthly plans to upgrade their plans. It's different
 * from MembershipUpgrade because 1) upgrades aren't applied immediately and 2) users will receive free months
 * as part of the promotion.
 */
const monthlyUpgradePaths: SubscriptionCheckoutPaths & { CompleteV2: (moduleId?: string) => string } = {
  Checkout: (moduleId = ':moduleId') => `${MONTHLY_UPGRADE_ROOT}/${moduleId}/checkout`,
  Complete: (moduleId = ':moduleId') => `${MONTHLY_UPGRADE_ROOT}/${moduleId}/complete`,
  CompleteV2: (moduleId = ':moduleId') => `${MONTHLY_UPGRADE_ROOT}/${moduleId}/completeV2`,
  Payment: (moduleId = ':moduleId') => `${MONTHLY_UPGRADE_ROOT}/${moduleId}/payment`,
  PlanSelection: (moduleId = ':moduleId') => `${MONTHLY_UPGRADE_ROOT}/${moduleId}`,
  Root: MONTHLY_UPGRADE_ROOT,
};

/**
 * Subscription is for managing the plan on an existing subscription via the collar settings page in the app
 */
const subscriptionPaths: SubscriptionManageCheckoutPaths = {
  SplashPageForDevice: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}`,
  PlanSelection: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/change`,
  RenewConfirmation: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/renew/confirmation`,
  Cancel: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/cancel`,
  CancelOtherReason: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/cancel/other`,
  Upgrade: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/cancel/upgrade`,
  RequestSent: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/request`,
  ContactCx: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/cancel/contact`,
  Checkout: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/checkout`,
  Complete: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/complete`,
  Payment: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/payment`,
  UpdatePayment: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/payment/update`,
  MultidogDiscount: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/multidogdiscount`,
  MultidogDiscountConfirmation: (moduleId = ':moduleId') =>
    `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/multidogdiscount/confirmation`,
  Replace: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/replace`,
  DogDoesNotRunAway: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/dogdoesnotrunaway`,
  FreeMonthOffer: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/freemonth`,
  FreeMonthConfirmed: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/freemonth/confirmed`,
  KeepMembershipConfirmation: (moduleId = ':moduleId') =>
    `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/keepmembership`,
  DowngradeS2: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/downgradeS2`,
  DowngradeS2Confirmed: (moduleId = ':moduleId') =>
    `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/downgradeS2/confirmed`,
  DowngradeS3: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/downgradeS3`,
  DowngradeS3Confirmed: (moduleId = ':moduleId') =>
    `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/downgradeS3/confirmed`,
  TooExpensive: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/tooexpensive`,
  TellUsMore: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/tellusmore`,
  MeetMakers: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/makers`,
  SpecialOffers: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/specialoffers`,
  SpecialOffersConfirmed: (moduleId = ':moduleId') =>
    `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/specialoffers/confirmed`,
  PauseMembership: (moduleId = ':moduleId') => `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/pausemembership`,
  PauseMembershipConfirmed: (moduleId = ':moduleId') =>
    `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/pausemembership/confirmed`,
  ResumeMembershipConfirmed: (moduleId = ':moduleId') =>
    `${MANAGE_COLLAR_SUBSCRIPTION_ROOT}/${moduleId}/resumemembership/confirmed`,
  Root: MANAGE_COLLAR_SUBSCRIPTION_ROOT,
};

/**
 * Supplements paths are for managing supplements plans via the supplements settings page in the app
 */
const manageSupplementsPaths: SupplementsManageCheckoutPaths = {
  Manage: (recurlySubscriptionUuid = ':recurlySubscriptionUuid') =>
    `${MANAGE_SUPPLEMENTS_SUBSCRIPTION_ROOT}/${recurlySubscriptionUuid}/manage`,
  Survey: (recurlySubscriptionUuid = ':recurlySubscriptionUuid') =>
    `${MANAGE_SUPPLEMENTS_SUBSCRIPTION_ROOT}/${recurlySubscriptionUuid}/survey`,
  SkipShipment: (recurlySubscriptionUuid = ':recurlySubscriptionUuid') =>
    `${MANAGE_SUPPLEMENTS_SUBSCRIPTION_ROOT}/${recurlySubscriptionUuid}/cancel/skip`,
  ChangeFrequency: (recurlySubscriptionUuid = ':recurlySubscriptionUuid') =>
    `${MANAGE_SUPPLEMENTS_SUBSCRIPTION_ROOT}/${recurlySubscriptionUuid}/cancel/changefrequency`,
  Discount: (recurlySubscriptionUuid = ':recurlySubscriptionUuid') =>
    `${MANAGE_SUPPLEMENTS_SUBSCRIPTION_ROOT}/${recurlySubscriptionUuid}/cancel/discount`,
  AddPayment: `${MANAGE_SUPPLEMENTS_SUBSCRIPTION_ROOT}/payment`,
  Root: MANAGE_SUPPLEMENTS_SUBSCRIPTION_ROOT,
};

const troubleshootPaths: TroubleshootPaths = {
  Start: (moduleId = ':moduleId') => `${TROUBLESHOOT_ROOT}/${moduleId}`,
  TellUsMore: (moduleId = ':moduleId') => `${TROUBLESHOOT_ROOT}/${moduleId}/tellusmore`,
  ContactCx: (moduleId = ':moduleId') => `${TROUBLESHOOT_ROOT}/${moduleId}/contact`,
  Replace: (moduleId = ':moduleId') => `${TROUBLESHOOT_ROOT}/${moduleId}/replace`,
  Upgrade: (moduleId = ':moduleId') => `${TROUBLESHOOT_ROOT}/${moduleId}/upgrade`,
  RequestSent: (moduleId = ':moduleId') => `${TROUBLESHOOT_ROOT}/${moduleId}/request`,
  Other: (moduleId = ':moduleId') => `${TROUBLESHOOT_ROOT}/${moduleId}/other`,
  Root: TROUBLESHOOT_ROOT,
};

const AppPaths = {
  Accessories: '/accessories',
  ApplyReferralCode: '/r/:code',
  Bag: '/bag',
  Checkout: '/checkout',
  CheckoutPayment: '/checkout/payment',
  CheckoutReview: '/checkout/review',
  CheckoutShipping: '/checkout/shipping',
  CheckoutThankYou: '/checkout/thank-you',
  CheckoutSupplements: '/checkout/supplements',
  FiMakers: `/accessories#${FI_MAKERS_ANCHOR}`,
  ForgotPassword: '/forgotPassword',
  Gift: '/gift',
  GiftFi: '/gift-fi',
  GiftFiActivation: giftFiActivationPaths,
  Home: '/',
  Login: '/login',
  MembershipUpgrade: membershipUpgradePaths,
  MonthlyUpgrade: monthlyUpgradePaths,
  NotFound: '/notfound',
  Products: (productId: string) => `/products/${productId}`,
  PurchaseSubscription: purchaseSubscriptionPaths,
  Referrals: '/referrals',
  Rescue: {
    PurchaseGiftCards: '/rescue',
    Placement: '/rescue/placement',
  },
  Series3Upgrade: {
    Bag: `${S3_UPGRADE_ROOT}/bag`,
    Checkout: `${S3_UPGRADE_ROOT}/checkout`,
    ProductSelector: (moduleId = ':moduleId') => `${S3_UPGRADE_ROOT}/product/${moduleId}`,
    SplashPageForPet: (petId = ':petId') => `${S3_UPGRADE_ROOT}/pets/${petId}`,
    Root: S3_UPGRADE_ROOT,
  },
  Subscription: subscriptionPaths,
  Supplements: manageSupplementsPaths,
  Troubleshoot: troubleshootPaths,
};

export default AppPaths;
