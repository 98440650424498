import React from 'react';
import styles from './Footer.module.scss';

export function Copyright() {
  return (
    <div className={styles.copyright}>
      <p>7 days a week&emsp;10AM - 8PM EST</p>
      <p>© Barking Labs</p>
    </div>
  );
}
