import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

interface Input {
  initialIndex?: number;
  total: number;
  autoproceedInterval?: number;
}

interface Output {
  current: number;
  setCurrent: (current: SetStateAction<number>) => void;
}

export default function useAutoTimer({ initialIndex, total, autoproceedInterval }: Input): Output {
  const [currentIndex, setCurrentIndexInternal] = useState(total <= 0 || !initialIndex ? 0 : initialIndex % total);
  const timerRef = useRef<null | number>(null);

  const setCurrentIndex = useCallback(
    (v: SetStateAction<number>) => {
      setCurrentIndexInternal((oldValue) => {
        if (total <= 0) {
          return 0;
        }
        const newValue = (typeof v === 'function' ? v(oldValue) : v) % total;
        if (isNaN(newValue) || !isFinite(newValue)) {
          return 0;
        } else if (newValue < 0) {
          return newValue + total;
        } else {
          return newValue;
        }
      });
    },
    [setCurrentIndexInternal, total],
  );

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      window.clearInterval(timerRef.current);
    }

    if ((autoproceedInterval ?? -1) > 0) {
      timerRef.current = window.setInterval(() => {
        setCurrentIndex((ci) => ci + 1);
      }, autoproceedInterval);
    }
  }, [setCurrentIndex, autoproceedInterval]);

  useEffect(() => {
    resetTimer();
    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
    };
  }, [resetTimer]);

  return {
    current: currentIndex,
    setCurrent: (c) => {
      resetTimer();
      setCurrentIndex(c);
    },
  };
}
