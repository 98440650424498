import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAutoApplyCoupon from '../../hooks/useAutoApplyCoupon';
import useApplyGiftCardFromCookie from '../../hooks/useApplyGiftCardFromCookie';
import useAutoApplyReferral from '../../hooks/useAutoApplyReferral';
import CouponBanner from './CouponBanner';

export default function AutoAppliedCouponBanner() {
  const location = useLocation();
  const [couponBannerText, setCouponBannerText] = useState<string | undefined>(undefined);
  const dismissCouponBanner = () => {
    setCouponBannerText(undefined);
  };

  // https://app.asana.com/0/1202415244913402/1202925167670125/f
  const { bannerText: bannerTextFromCookie, finished: autoApplyCouponFinished } = useAutoApplyCoupon();

  useEffect(() => {
    setCouponBannerText(bannerTextFromCookie);
  }, [bannerTextFromCookie]);

  // Clear coupon banner on nav.
  useEffect(() => dismissCouponBanner(), [location.pathname]);

  // Gift card code could be set on a cookie by the /set_gift_card endpoint on our web API
  useApplyGiftCardFromCookie();

  // Referral code could be set on a cookie by the endpoint on our web API
  useAutoApplyReferral();

  if (couponBannerText && autoApplyCouponFinished) {
    return <CouponBanner bannerText={couponBannerText} onDismiss={() => dismissCouponBanner()} />;
  }

  return null;
}
