import React from 'react';

import Button from '../../../../components/Button';
import Popup from 'reactjs-popup';
import styles from '../../../../components/modals/modals.module.scss';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import { DateTime } from 'luxon';
import SelectShipDateModal from './SelectShipDateModal';
import { BillingSubscription } from '../SplashPage';
import classNames from 'classnames';
import { supplementsManagement as events } from '../../../../lib/analytics/events';

interface ISendNowModalProps {
  onSendNow: (date: string) => void;
  onChooseDifferentDate: (date: string) => void;
  trigger: React.ReactElement;
  subscription: BillingSubscription;
}

export default function SendNowModal({ onSendNow, onChooseDifferentDate, trigger, subscription }: ISendNowModalProps) {
  const TIMEZONE = 'America/New_York';
  // Recurly doesn't permit setting the next bill date to the same day it's invoked, so next ship date will have to be the following morning.
  const nextShipDate = DateTime.now().setZone(TIMEZONE).set({ hour: 6, minute: 0 }).plus({ days: 1 });

  function expectedDeliveryDateText() {
    const threeDaysOut = nextShipDate.plus({ days: 3 }).toFormat('M/dd');
    const fiveDaysOut = nextShipDate.plus({ days: 5 }).toFormat('M/dd');
    return `Delivery expected ${threeDaysOut} - ${fiveDaysOut}`;
  }

  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      onOpen={() => events.sendNowTapped({ subscriptionId: subscription.id })}
    >
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={classNames(styles.body, styles.shrink)}>
            <div className={styles.header}>
              <h3>Send your shipment now?</h3>
              <CloseIcon
                onClick={() => {
                  events.modalCloseEvent({}, 'Send Now');
                  close();
                }}
              />
            </div>
            <hr className={styles.divider} />
            <p>This will update your scheduled shipment date:</p>
            <div className={styles.highlight}>
              <div className={styles.title}>{nextShipDate.toFormat('cccc, LLL d')}</div>
              <div className={styles.subtitle}>{expectedDeliveryDateText()}</div>
            </div>
          </div>
          <div className={classNames(styles.actions, styles.shrink)}>
            <Button className={styles.button} onClick={() => onSendNow(nextShipDate.toISO())}>
              Update shipment date
            </Button>
            <SelectShipDateModal
              trigger={
                <Button className={styles.button} tertiary>
                  Choose different shipment date
                </Button>
              }
              onContinue={(date: string) => onChooseDifferentDate(date)}
              subscription={subscription}
              referrer="Send now"
            />
          </div>
        </div>
      )}
    </Popup>
  );
}
