import {
  ApplePayConfig,
  ApplePayErrorUpdate,
  ApplePayInstance,
  ApplePayPaymentAuthorizedEvent,
  ApplePayPaymentMethodSelectedEvent,
  ApplePaySelectionUpdate,
  ApplePayShippingContactSelectedEvent,
  ApplePayShippingMethodSelectedEvent,
} from '@recurly/recurly-js';
import { IWindow } from './Window';

const PUBLIC_KEY = process.env.REACT_APP_RECURLY_PUBLIC_KEY || '';

export interface IError {
  name: string;
  code: string;
  message: string;
  fields: string[];
}

export interface IToken {
  id: string;
}

export interface IConfiguration {
  publicKey?: string;
  required?: string[];
}

// https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/2216121-requiredshippingcontactfields
export type ShippingContactField = 'email' | 'name' | 'postalAddress' | 'phone';

export interface FiApplePayError {
  code: 'accountExists' | 'fiApiError';
  message: string;
}

/**
 * ApplePayErrorUpdate that includes customErrors for custom error handling.
 */
export interface FiApplePayErrorUpdate extends ApplePayErrorUpdate {
  customErrors?: FiApplePayError[];
}

export type FiApplePaySelectionUpdate = ApplePaySelectionUpdate | FiApplePayErrorUpdate;

interface RecurlyApplePayCallbacks {
  onPaymentAuthorized?: (event: ApplePayPaymentAuthorizedEvent) => Promise<FiApplePayErrorUpdate>;
  onPaymentMethodSelected?: (event: ApplePayPaymentMethodSelectedEvent) => Promise<FiApplePaySelectionUpdate>;
  onShippingContactSelected?: (event: ApplePayShippingContactSelectedEvent) => Promise<FiApplePaySelectionUpdate>;
  onShippingMethodSelected?: (event: ApplePayShippingMethodSelectedEvent) => Promise<FiApplePaySelectionUpdate>;
}

interface IRecurlyApplePayOptions extends ApplePayConfig {
  callbacks?: RecurlyApplePayCallbacks;
}

export interface IRecurlyApplePayInstance extends ApplePayInstance {
  // Private undocumented Recurly methods that we need to override.
  onPaymentAuthorized: (event: ApplePayPaymentAuthorizedEvent, result?: FiApplePayErrorUpdate) => void;
  onPaymentMethodSelected: (event: ApplePayPaymentMethodSelectedEvent, result?: FiApplePaySelectionUpdate) => void;
  onShippingContactSelected: (event: ApplePayShippingContactSelectedEvent, result?: FiApplePaySelectionUpdate) => void;
  onShippingMethodSelected: (event: ApplePayShippingMethodSelectedEvent, result?: FiApplePaySelectionUpdate) => void;

  session: ApplePaySession;
}

interface IRecurlyPayPalOptions {
  display: {
    displayName: string;
  };
}

export interface IRecurlyPayPal {
  start(): void;
  on(event: string, listener: Function): Function;
  once(event: string, listener: Function): Function;
  off(): Function;
}

export type IRecurlyApplePay = (options: IRecurlyApplePayOptions) => IRecurlyApplePayInstance;

export interface IRecurly {
  Pricing: {
    Checkout: () => Recurly.CheckoutPricing;
    Subscription: () => Recurly.SubscriptionPricing;
  };
  ApplePay: IRecurlyApplePay;
  PayPal: (options: IRecurlyPayPalOptions) => IRecurlyPayPal;
  configure(configuration: IConfiguration): void;
  token(form: any, callback: (err?: IError, token?: IToken) => void): void;
}

export class Provider {
  private static instance: Provider;

  public value: IRecurly;

  constructor(source: IWindow = window) {
    if (Provider.instance) {
      this.value = Provider.instance.value;
      return Provider.instance;
    }

    if (!source.recurly) {
      throw new Error('Please load Recurly.js (https://js.recurly.com/v4/recurly.js) on this page');
    } else {
      this.value = source.recurly;
      this.value.configure({ publicKey: PUBLIC_KEY, required: ['cvv'] });
    }
  }
}

export default Provider;
