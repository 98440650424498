import { gql } from '@apollo/client';

export const previewMembershipUpgradeQuery = gql`
  query ECOMMERCE_previewMembershipUpgrade($input: UpgradeMembershipInput!) {
    previewMembershipUpgrade(input: $input) {
      appliedAccountBalanceInCents
      appliedDiscountInCents
      proratedCreditAmountInCents
      subtotalInCents
      taxInCents
      totalInCents
    }
  }
`;

export const purchaseMembershipUpgradeMutation = gql`
  mutation ECOMMERCE_purchaseMembershipUpgrade($input: UpgradeMembershipInput!) {
    purchaseMembershipUpgrade(input: $input) {
      chargeInvoice {
        number
      }
      giftCardInvoices {
        number
      }
      isReturningCustomer
    }
  }
`;
