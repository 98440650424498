import React from 'react';
import { useHistory } from 'react-router';
import { gqlTypes } from '../../../../types';
import CreditCardLogo from '../../../../components/CreditCardLogo/CreditCardLogo';
import { expectUnreachable } from '../../../../lib/util';
import { usePurchaseSubscriptionContext } from '../../context/PurchaseSubscriptionContext';

import styles from './PaymentInfo.module.scss';

import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as AddIcon } from '../../../../assets/images/icons/add.svg';
import { useSubscriptionCartMode } from '../../../../lib/cartModes';

interface PaymentInfoProps {
  billingAccount: gqlTypes.billingAccount['currentUser']['billingAccount'];
  loading: boolean;
}

export default function PaymentInfo({ billingAccount, loading }: PaymentInfoProps) {
  const { checkoutPaths } = useSubscriptionCartMode();
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();

  const doUpdatePayment = () => {
    history.push(checkoutPaths.Payment(device.moduleId));
  };

  let paymentPeek: JSX.Element;
  if (loading) {
    paymentPeek = <React.Fragment />;
  } else if (billingAccount?.billingInfo) {
    let paymentDisplay: JSX.Element;
    const { paymentInfo } = billingAccount.billingInfo;
    if (paymentInfo.__typename === 'ObfuscatedCardInfo') {
      paymentDisplay = (
        <>
          {/* sentry-mask class is used to mask text in Sentry replays */}
          <div className="sentry-mask">{paymentInfo.lastFour.toString().padStart(4, '0')}</div>
          <CreditCardLogo cardType={paymentInfo.type} />
        </>
      );
    } else if (paymentInfo.__typename === 'PayPalInfo') {
      paymentDisplay = (
        <img
          src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
          alt="Pay with PayPal"
          style={{ height: '1em', verticalAlign: 'text-bottom' }}
        />
      );
    } else {
      expectUnreachable(paymentInfo);
      paymentDisplay = <React.Fragment />;
    }

    paymentPeek = (
      <>
        <div className={styles.paymentDetails}>
          {paymentDisplay}
          <span onClick={doUpdatePayment}>
            <EditIcon />
          </span>
        </div>
      </>
    );
  } else {
    paymentPeek = (
      <div>
        <span onClick={doUpdatePayment}>
          <AddIcon />
        </span>
      </div>
    );
  }

  return (
    <div className={styles.peekContainer}>
      <div className={styles.header}>Payment</div>
      {paymentPeek}
    </div>
  );
}
