import React from 'react';

import AppPaths from '../../../AppPaths';
import { BillingSubscription } from './SplashPage';
import Button from '../../../components/Button';
import ErrorMessage, { useError } from '../../../components/ErrorMessage';
import { centsToDollars, centsToDollarsNumber, getLoadingOrErrorElement } from '../../../lib/util';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import { supplementsManagement as events } from '../../../lib/analytics/events';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import * as types from '../../../types';
import { claimSupplementDiscountDeterrentMutation, supplementsSubscriptionsQuery } from '../../../graphql-operations';
import Loading from '../../../components/Loading';
import { DateTime } from 'luxon';
import CancelDeterrentConfirmationModal from './components/CancelDeterrentConfirmationModal';
import { CancellationDeterrents } from './util/types';

interface DiscountDeterrentState {
  subscription: BillingSubscription;
}

export default function DiscountDeterrent() {
  const pageName = '33% Off Discount Deterrent';
  const history = useHistory();
  const location = useLocation<DiscountDeterrentState>();
  const { subscription } = location.state;
  const { error, setError } = useError();

  events.pageLoad({ subscriptionId: subscription.id }, pageName);

  const [claimDiscountMutation, claimSupplementDiscountDeterrentMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_claimSupplementDiscount,
    types.gqlTypes.ECOMMERCE_claimSupplementDiscountVariables
  >(claimSupplementDiscountDeterrentMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.claimSupplementDiscount?.success;
      if (!success) {
        setError(
          data.claimSupplementDiscount?.reasonForFailure ?? 'Unable to claim discount. Please contact customer support',
        );
      } else {
        history.push({
          pathname: AppPaths.Supplements.Root,
          search: window.location.search,
          state: { toast: { success: true, change: 'discounted' }, refetch: false },
        });
      }
    },
  });

  const loading = getLoadingOrErrorElement(claimSupplementDiscountDeterrentMutationState.loading, null);

  const handleClaim = () => {
    claimDiscountMutation({
      variables: {
        input: {
          recurlySubscriptionId: subscription.id,
        },
      },
    });
  };

  const handleCancel = () => {
    events.deterrentDeclined({ subscriptionId: subscription.id }, CancellationDeterrents.DISCOUNT);
    history.push({
      pathname: AppPaths.Supplements.Survey(subscription.id),
      state: { subscription },
      search: window.location.search,
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.goBackFromPage({ subscriptionId: subscription.id }, pageName)}
          closeButtonAnalyticsEvent={() => events.closePage({ subscriptionId: subscription.id }, pageName)}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.largeHeader}>
            <h1>How about 33% off your next shipment?</h1>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className={styles.content}>
                <p>
                  Don't lose out on benefits to your dog's health: Here's a discount on your next bag to keep your
                  subscription going.
                </p>

                <div className={styles.highlight}>
                  <div className={styles.header}>Next shipment with $10 discount!</div>
                  <div className={styles.discountedPrice}>
                    ${centsToDollars((subscription.subscriptionOption.priceInCents / 3) * 2)}{' '}
                    <span className={styles.originalPrice}>
                      ${centsToDollars(subscription.subscriptionOption.priceInCents)}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          {error && <ErrorMessage errors={[error]} />}
        </div>
        <div className={styles.buttonContainer}>
          <CancelDeterrentConfirmationModal
            onContinue={handleClaim}
            header="Confirm shipment discount"
            body={`Your next shipment on
            ${DateTime.fromISO(subscription.supplementShipmentDates!.nextShipmentDate).toFormat('cccc, LLLL d')}
            will be discounted 33% and billed as $${centsToDollarsNumber(
              (subscription.subscriptionOption.priceInCents * 2) / 3,
            )}.`}
            trigger={<Button className={styles.button}>Claim 33% discount</Button>}
            deterrent={CancellationDeterrents.DISCOUNT}
          />
          <Button className={styles.button} onClick={() => handleCancel()} tertiary>
            Continue with Cancellation
          </Button>
        </div>
      </div>
    </>
  );
}
