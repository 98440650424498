import React, { MutableRefObject } from 'react';
import { SizeOptionProps, commonDetailsForSize, sizeDetailsForSizeOption } from '../../../../lib/size';
import styles from './SizeOptionDetails.module.scss';

export interface SizeOptionButtonProps extends SizeOptionProps {
  sizeOptionRef?: MutableRefObject<HTMLDivElement | null>;
}

export default function SizeOptionButton(props: SizeOptionButtonProps) {
  const sizeDetails = sizeDetailsForSizeOption(props);

  if (!sizeDetails) {
    const commonDetails = commonDetailsForSize(props.sizeOption);
    return (
      <div className={styles.container} ref={props.sizeOptionRef}>
        <div className={styles.sizeName}>{commonDetails.sizeName}</div>
        <div className={styles.sizeDescription}>
          <div>Not offered by this maker</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container} ref={props.sizeOptionRef}>
      <div className={styles.sizeName}>{sizeDetails.sizeName}</div>
      <div className={styles.sizeDescription}>
        <div>{sizeDetails.neckSize}</div>
        <div>{sizeDetails.bandWidth} wide collar</div>
      </div>
    </div>
  );
}
