import React, { useState } from 'react';
import { ReactComponent as Checkmark } from '../../../../../../assets/images/check_black_bg_circle.svg';
import styles from '../../ProductSelector/ProductSelector.module.scss';
import * as types from '../../../../../../types';
import { SimpleDismissableModal } from '../../../../../../components/modals';
import * as events from '../../../../../../lib/analytics/events';
import {
  SupplementPillPurchaseOption,
  getRecommendedText,
} from '../../../../../ManageSubscription/Supplements/components/SupplementPill';
import { SupplementCheckoutFlow } from '../../../../../../types';

interface DesktopPlanSelectionProps {
  supplementProducts: types.ISupplementSubscriptionProduct[];
  selected: types.ISupplementSubscriptionProduct;
  setSelected: (product: types.ISupplementSubscriptionProduct) => void;
}
interface MobilePlanSelectionProps {
  supplementProducts: types.ISupplementSubscriptionProduct[];
  selected: types.ISupplementSubscriptionProduct;
  setSelected: (product: types.ISupplementSubscriptionProduct) => void;
  handlePurchase: () => Promise<void>;
  handlePurchaseLoading: boolean;
  flow: SupplementCheckoutFlow;
}
function RadioButton({ selected }: { selected: boolean }) {
  return <div>{selected ? <Checkmark className={styles.check} /> : <div className={styles.radio} />}</div>;
}

export function DesktopPlanSelection({ supplementProducts, selected, setSelected }: DesktopPlanSelectionProps) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={styles.desktopPlanSelection}>
      <div className={styles.supplementProductSelector}>
        <div>
          <SupplementPillPurchaseOption
            subscriptionOption={selected}
            handleClick={() => {
              events.supplements.openPlanSelection('ecomm');
              setShowMenu(!showMenu);
            }}
            selected={true}
            showDownArrow={true}
          />
        </div>
        {showMenu && (
          <ul className={styles.allProducts}>
            {supplementProducts.map((product, i) => (
              <li key={i}>
                <div
                  className={styles.product}
                  onClick={() => {
                    setSelected(product);
                    setShowMenu(false);
                  }}
                >
                  <div className={styles.left}>
                    <div className={styles.size}>{product.name}</div>
                    <div className={styles.shippingCadence}>
                      Ships every {product.weeksBetweenShipments} {product.displayedRateUnit}s
                    </div>
                    <div className={styles.recommended}>
                      {' '}
                      {getRecommendedText(product.recommendedForDogWeightRangePounds)}
                    </div>
                    <div className={styles.freeLabel}>FIRST BAG FREE</div>
                  </div>
                  <div className={styles.right}>
                    <RadioButton selected={selected.id === product.id} />
                  </div>
                </div>
                <hr className={styles.divider} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export function MobilePlanSelection({ selected, setSelected, flow, supplementProducts }: MobilePlanSelectionProps) {
  return (
    <div className={styles.mobilePlanSelection}>
      <SimpleDismissableModal
        trigger={
          <div>
            <SupplementPillPurchaseOption
              subscriptionOption={selected}
              selected={true}
              showDownArrow={true}
              flow={flow}
            />
          </div>
        }
        onOpen={() => {
          events.supplements.openPlanSelection(flow);
        }}
        onClose={() => {
          events.supplements.closePlanSelection(flow);
        }}
      >
        <div className={styles.mobilePlanSelection}>
          <h3>Choose your plan</h3>
          {supplementProducts &&
            supplementProducts.map((subOption: types.ISupplementSubscriptionProduct) => (
              <SupplementPillPurchaseOption
                subscriptionOption={subOption}
                handleClick={() => {
                  setSelected(subOption);
                }}
                selected={subOption.sku === selected.sku}
                showDownArrow={false}
                flow={flow}
                key={subOption.sku}
              />
            ))}
        </div>
      </SimpleDismissableModal>
    </div>
  );
}
