import { FeaturesReady } from '@growthbook/growthbook-react';
import { AppState } from '../../types';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePathBasedAppCustomization } from '../../hooks/usePathBasedAppCustomization';
import { useCartMode } from '../../lib/cartModes';
import ReferralBanner from '../ReferralBanner/ReferralBanner';
import styles from './AppBar.module.scss';
import ShopPromotionBanner from '../ShopPromotionBanner';
import NavBar from './NavBar';
import { useLocation } from 'react-router-dom';

export default function AppBar() {
  const { showPromotionBanner } = usePathBasedAppCustomization();
  const [navBarOpacity, setNavBarOpacity] = useState<number>(1);
  const [canHideNavBar, setCanHideNavBar] = useState<boolean>(true);
  const { cartSelector } = useCartMode();
  const cart = useSelector(cartSelector);
  const showPromoBannerImprovements = useSelector(
    (state: AppState) => !!state.config.siteConfig.enableEcommPromoBannerImprovements,
  );
  const { pathname } = useLocation();

  // The S3 PDP has a custom nav bar, so we don't want to render the default nav bar on that page
  // Only render nav bar on the supplements page if we're not surfacing the subscribe and save iteration
  // of the supplements PDP.
  const renderNavBar = !(pathname.includes('products/smart-collar-v3') || pathname.includes('gift-fi'));

  useEffect(() => {
    // Calculate the opacity of the nav bar based on the scroll position so that it fades out as user scrolls down
    const listenToScroll = () => {
      const heightToHideFrom = 80;
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const newOpacity = 1 - winScroll / heightToHideFrom;

      setNavBarOpacity(newOpacity);
    };

    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, [setNavBarOpacity]);

  return (
    <>
      {!showPromoBannerImprovements && showPromotionBanner && !cart.referralCode && (
        <header className={classNames(styles.promotion, 'promotionBanner')}>
          <FeaturesReady>
            <ShopPromotionBanner />
          </FeaturesReady>
        </header>
      )}
      {cart.referralCode && (
        <header className={classNames(styles.promotion, 'promotionBanner')}>
          <ReferralBanner />
        </header>
      )}
      <div className={styles.navBarContainer}>
        {showPromoBannerImprovements && showPromotionBanner && (
          <header className={classNames(styles.promotion, 'promotionBanner')}>
            <FeaturesReady>
              <ShopPromotionBanner
                showBannerOnApply={true}
                bannerShownCallback={(shown) =>
                  // If promo banner is shown, we can hide the nav bar, otherwise we can't
                  setCanHideNavBar(shown)
                }
              />
            </FeaturesReady>
          </header>
        )}
        {renderNavBar && (
          <NavBar
            navBarOpacity={
              showPromoBannerImprovements && showPromotionBanner && canHideNavBar ? navBarOpacity : undefined
            }
          />
        )}
      </div>
    </>
  );
}
