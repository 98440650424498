import { useQuery } from '@apollo/client';
import React from 'react';
import { useSelector } from 'react-redux';
import { referralsQuery } from '../../../../graphql-operations';
import * as types from '../../../../types';
import { gqlTypes } from '../../../../types';
import ReferralCode from '../../../../components/ReferralCode';
import styles from './ThankYou.module.scss';

export default function ReferralsSection() {
  const { data } = useQuery<gqlTypes.ECOMMERCE_referrals>(referralsQuery);
  const referreeFreeMonthEnabled = useSelector((state: types.AppState) => !!state.config.siteConfig.referreeFreeMonth);

  const referralCode = data?.currentUser.referrals.referralCode;
  if (!referralCode) {
    return null;
  }

  const referralHeading = referreeFreeMonthEnabled ? 'Give 1 Month / Get 1 Month' : 'Give $20, Get 1 month free';

  return (
    <div className={styles.referralsSection}>
      <h2>Refer a friend</h2>
      <div className={styles.referralsSubtitle}>{referralHeading}</div>
      <div className={styles.referralsDescription}>
        Invite friends to Fi and earn up to $100 in Fi credit per referral!
      </div>
      <div className={styles.referralsContainer}>
        <ReferralCode code={referralCode} source="thankyou" />
      </div>
    </div>
  );
}
