import React from 'react';
import { Switch } from 'react-router';
import SentryRoute from '../../SentryRoute';
import AppPaths from '../../AppPaths';
import TroubleshootStart from './TroubleshootStart/TroubleshootStart';
import { PurchaseSubscriptionContextProvider } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import TellUsMore from './TellUsMore/TellUsMore';
import ContactCx from './ContactCx/ContactCx';
import ReplaceCollar from './ReplaceCollar/ReplaceCollar';
import UpgradeSeries2 from './UpgradeSeries2/UpgradeSeries2';
import TroubleshootOtherReason from './TroubleshootOtherReason/TroubleshootOtherReason';

export default function Troubleshoot() {
  return (
    <PurchaseSubscriptionContextProvider>
      <Switch>
        <SentryRoute exact path={AppPaths.Troubleshoot.Start()} component={TroubleshootStart} />
        <SentryRoute exact path={AppPaths.Troubleshoot.TellUsMore()} component={TellUsMore} />
        <SentryRoute exact path={AppPaths.Troubleshoot.Replace()} component={ReplaceCollar} />
        <SentryRoute exact path={AppPaths.Troubleshoot.Other()} component={TroubleshootOtherReason} />
        <SentryRoute exact path={AppPaths.Troubleshoot.ContactCx()} component={ContactCx} />
        <SentryRoute exact path={AppPaths.Troubleshoot.Upgrade()} component={UpgradeSeries2} />
      </Switch>
    </PurchaseSubscriptionContextProvider>
  );
}
