import Cookies from 'js-cookie';
import { CouponCookieData, COUPON_COOKIE_NAME, DEV_MODE, COUPON_COOKIE_EXPIRATION_HOURS } from './util';
import { DateTime } from 'luxon';

export default function setCouponCookie(code: string) {
  const data: CouponCookieData = { code };
  Cookies.set(COUPON_COOKIE_NAME, JSON.stringify(data), {
    expires: DateTime.utc().plus({ hours: COUPON_COOKIE_EXPIRATION_HOURS }).toJSDate(),
    domain: DEV_MODE ? undefined : 'tryfi.com',
    secure: !DEV_MODE,
  });
}
