import React from 'react';

import Button from '../../../../components/Button';
import { BillingSubscription } from '../SplashPage';
import Popup from 'reactjs-popup';
import styles from '../../../../components/modals/modals.module.scss';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import classNames from 'classnames';
import { supplementsManagement as events } from '../../../../lib/analytics/events';
import { AddressFields } from './UpdateShippingAddressModal';

interface IConfirmAddressChangeModalProps {
  onUpdateThisSubscription: (shippingAddress: AddressFields) => void;
  onUpdateAllSubscriptions: (shippingAddress: AddressFields) => void;
  setConfirmAddressChangeModalOpen: (open: boolean) => void;
  setButtonDisabled: (open: boolean) => void;
  shippingAddress: AddressFields;
  subscription: BillingSubscription;
  trigger: React.ReactElement;
  open: boolean;
}

export default function ConfirmAddressChangeModal({
  onUpdateThisSubscription,
  onUpdateAllSubscriptions,
  setConfirmAddressChangeModalOpen,
  setButtonDisabled,
  shippingAddress,
  trigger,
  subscription,
  open,
}: IConfirmAddressChangeModalProps) {
  events.shipmentFrequencyTapped({ subscriptionId: subscription.id });
  return (
    <Popup
      className="s3-popup"
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      open={!!open}
      onOpen={() => {
        setConfirmAddressChangeModalOpen(true);
        events.updateShipmentAddress({ subscriptionId: subscription.id });
      }}
    >
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={classNames(styles.body, styles.shrink)}>
            <div className={styles.header}>
              <h3>Update all addresses?</h3>
              <CloseIcon onClick={close} />
            </div>
            <p>
              Would you like to make these address updates for all of your supplement subscriptions or just this one?
            </p>
          </div>

          <div className={classNames(styles.actions, styles.shrink)}>
            <Button
              className={styles.button}
              onClick={() => {
                setButtonDisabled(true);
                onUpdateAllSubscriptions(shippingAddress);
              }}
            >
              All subscriptions
            </Button>
            <Button
              tertiary
              className={styles.button}
              onClick={() => {
                setButtonDisabled(true);
                onUpdateThisSubscription(shippingAddress);
              }}
            >
              Just this subscription
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
}
