import React from 'react';
import styles from './ReferralPill.module.scss';
import { ReactComponent as ReferralIcon } from '../../assets/images/icons/icon_gift_box.svg';
export default function ReferralPill() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <ReferralIcon width={14} height={12} className={styles.icon} />
        Your referral offer
      </div>
      <div className={styles.body}>Activation fee waived + 1 Month FREE membership</div>
    </div>
  );
}
