import React from 'react';
import * as types from '../../types';
import CJEventIFrame from '../../views/Checkout/pages/ThankYou/CJEventIFrame';
import { ImpactTrackConversionComponent } from './index';

interface TrackerProps {
  cart: types.Cart;
  cartPricing: types.CartPricing;
  orderId: string;
  session: types.Session | null;
}

export function Tracker({ cart, cartPricing, orderId, session }: TrackerProps) {
  return (
    <>
      <CJEventIFrame orderId={orderId} cart={cart} cartPricing={cartPricing} />
      <ImpactTrackConversionComponent orderId={orderId} cart={cart} cartPricing={cartPricing} session={session} />
    </>
  );
}
