import React from 'react';
import WebViewAppBar from '../../../components/WebViewAppBar';
import PurchaseSubscriptionPaymentBody from './PurchaseSubscriptionPaymentBody';

import styles from './PurchaseSubscriptionPayment.module.scss';

export default function PurchaseSubscriptionPayment() {
  return (
    <div className={styles.paymentContainer}>
      <WebViewAppBar />
      <h1>Payment</h1>
      <PurchaseSubscriptionPaymentBody />
    </div>
  );
}
