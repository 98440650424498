import React from 'react';
import styles from './FeatureList.module.scss';

interface FeatureIconSpec {
  title: string;
  icon: React.ReactNode;
}

interface FeatureListProps {
  iconRows: FeatureIconSpec[][];
}

function Icon({ title, icon }: FeatureIconSpec) {
  return (
    <div className={styles.icon}>
      <div className={styles.iconWrapper}>{icon}</div>
      <div className={styles.iconTitle}>{title}</div>
    </div>
  );
}

export default function FeatureList({ iconRows }: FeatureListProps) {
  return (
    <div className={styles.main}>
      {iconRows.map((row, i) => (
        <div className={styles.row} key={i}>
          {row.map((col, j) => (
            <Icon {...col} key={j} />
          ))}
        </div>
      ))}
    </div>
  );
}
