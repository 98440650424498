import React, { useCallback, useEffect, useState } from 'react';
import { generateID } from '../../lib/util';
import styles from './ErrorMessage.module.scss';

interface ErrorProps {
  errors?: string[];
  trackError?: (message: string) => void;
  errorID?: string;
}

export function useError() {
  const [error, setErrorInternal] = useState<string | undefined>(undefined);
  const [errorID, setErrorID] = useState<string | undefined>(undefined);

  const setError = useCallback(
    (message: string) => {
      setErrorInternal(message);
      setErrorID(generateID());
    },
    [setErrorInternal, setErrorID],
  );

  const clearError = useCallback(() => setErrorInternal(undefined), [setErrorInternal]);

  return { error, errorID, setError, setErrorID, clearError };
}

export default function ErrorMessage({ errors, trackError, errorID }: ErrorProps) {
  const [stateErrorId, setStateErrorId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!trackError || !errors?.length || stateErrorId === errorID) {
      return;
    }

    setStateErrorId(errorID);
    trackError(errors.join(', '));
  }, [trackError, errors, errorID, stateErrorId]);

  if (!errors?.length) {
    return null;
  }

  return (
    <div className={styles.errorMessage}>
      {errors.map((errorMessage, index) => (
        <div key={index}>{errorMessage}</div>
      ))}
    </div>
  );
}
