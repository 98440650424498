import { ApolloError, useLazyQuery } from '@apollo/client';
import { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { validateAddressQuery } from '../../../../graphql-operations';
import { gqlTypes } from '../../../../types';
import CheckoutContext from '../../../../lib/CheckoutContext';
import { logInternalError } from '../../../../lib/errors';
import * as types from '../../../../types';

interface ConfirmAddressProps {
  status: gqlTypes.ValidateAddressResponseStatus;
  matchedAddress: types.Address | null;
}

interface UseAddressValidationProps {
  addressValidationStatus: types.AddressValidationStatus | undefined;
  saveConfirmedShipping: (shippingInfo: types.ShippingInfo) => void;
}

function addressToAddressInput(address: types.Address | null): gqlTypes.AddressInput {
  return {
    line1: address?.line1 || '',
    line2: address?.line2 || '',
    city: address?.city || '',
    state: address?.state || '',
    zip: address?.zip || '',
    country: address?.country || '',
    phone: address?.phone || '',
  };
}

export default function useAddressValidation({
  addressValidationStatus,
  saveConfirmedShipping,
}: UseAddressValidationProps) {
  const dispatch = useDispatch();
  const { checkoutActions } = useContext(CheckoutContext);
  const [unconfirmedShipping, setUnconfirmedShipping] = useState<types.ShippingInfo | null>(null);
  const [pendingAddressUserConfirmation, setPendingAddressUserConfirmation] = useState<ConfirmAddressProps | null>(
    null,
  );

  const onValidateAddressError = useCallback(
    (err: ApolloError) => {
      // If there is an issue with the address validation API, we shouldn't consider this a blocker from being able to
      // checkout. We'll save the address to Recurly as-is just as we had been doing before introducing address
      // validation.
      if (unconfirmedShipping) {
        saveConfirmedShipping(unconfirmedShipping);
      }

      // ... but still log the error for analytics
      logInternalError(err, {
        fingerprint: ['{{ default }}', err.message],
        tags: {
          checkoutStep: 'addressValidation',
        },
      });
    },
    [saveConfirmedShipping, unconfirmedShipping],
  );

  const onValidateAddressCompleted = useCallback(
    (validationData: gqlTypes.validateAddress) => {
      if (!unconfirmedShipping) {
        return;
      }

      const responseStatus = validationData.validateAddress.status;
      const matchedAddress = validationData.validateAddress.matchedAddress;

      const canAutocorrect =
        addressValidationStatus === types.AddressValidationStatus.NeedsVerifyWithAutocorrect &&
        responseStatus === gqlTypes.ValidateAddressResponseStatus.CORRECTED;

      if (!responseStatus) {
        // Having no status means the backend caught an error during address validation so that's another case
        // where we'll just save their address as-is.
        saveConfirmedShipping(unconfirmedShipping);
      } else if (
        matchedAddress &&
        (responseStatus === gqlTypes.ValidateAddressResponseStatus.VERIFIED || canAutocorrect)
      ) {
        saveConfirmedShipping({
          customerName: unconfirmedShipping.customerName,
          address: addressToAddressInput(matchedAddress),
        });
      } else {
        setPendingAddressUserConfirmation({
          status: responseStatus,
          matchedAddress: matchedAddress ? addressToAddressInput(matchedAddress) : null,
        });
      }
    },
    [addressValidationStatus, saveConfirmedShipping, setPendingAddressUserConfirmation, unconfirmedShipping],
  );

  const [validateAddress, { loading: addressValidationLoading }] = useLazyQuery<
    gqlTypes.validateAddress,
    gqlTypes.validateAddressVariables
  >(validateAddressQuery, {
    fetchPolicy: 'network-only',
    onError: onValidateAddressError,
    onCompleted: onValidateAddressCompleted,
  });

  const executeAddressValidation = useCallback(
    (shippingInfo: types.ShippingInfo, newStatus: types.AddressValidationStatus) => {
      dispatch(checkoutActions.setAddressValidationStatus(newStatus));

      setUnconfirmedShipping(shippingInfo);
      validateAddress({ variables: { address: shippingInfo.address } });
    },
    [checkoutActions, dispatch, validateAddress],
  );

  return {
    addressValidationLoading,
    executeAddressValidation,
    pendingAddressUserConfirmation,
    setPendingAddressUserConfirmation,
    unconfirmedShipping,
  };
}
