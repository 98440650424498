import { useParams } from 'react-router-dom';
import * as types from '../types';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

/**
 * Returns the id for the specific pet we would like to upgrade to to S3.
 */
export default function useUpgradePetId(): string | undefined {
  const { petId: paramsPetId } = useParams<{ petId: string | undefined }>();
  const contextPetId = useSelector((state: types.AppState) => state.series3UpgradeShop.upgradePetContext.petId);

  return useMemo(() => paramsPetId ?? contextPetId, [paramsPetId, contextPetId]);
}
