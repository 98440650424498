export interface ReviewData {
  quote: string;
  author: { icon?: string; name: string };
}

export const BrianReview: ReviewData = {
  author: { name: 'Brian O.' },
  quote: "It's like Airtags but with GPS",
};

export const PressReviewData: ReviewData[] = [
  {
    author: { icon: '/newsbrand_images/forbes.png', name: 'Forbes' },
    quote: 'The longest-lasting tracking device on the market.',
  },
  {
    author: {
      icon: '/newsbrand_images/biz-insider.png',
      name: 'Business Insider',
    },
    quote: "The first GPS dog collar that searches for your canine companion without killing the collar's battery.",
  },
  {
    author: { icon: '/newsbrand_images/pcmag.png', name: 'PC Mag' },
    quote: 'A nicer, more streamlined design than much of the competition',
  },
  {
    author: { icon: '/newsbrand_images/engadget_logo.png', name: 'Engadget' },
    quote: 'Your dog will be as happy wearing it as you are knowing they’re in safe hands.',
  },
];
