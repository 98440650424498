import { IWindow } from '../Window';
import { Address, BillingInfo, CartItem, CartPricing } from '../../types';
import { skusForCartItem } from '../cart';
import { variantDescriptionForAnalytics, getProductsAndVariantsBySku } from '../product';
import store from '../reduxStore';
import { centsToDollars } from '../util';

export function trackEmailSignup(email: string) {
  const extendedWindow: IWindow = window;
  if (!extendedWindow.letterpress) {
    return;
  }

  extendedWindow.letterpress('trackUnstructEvent', {
    schema: 'iglu:com.getletterpress/email/jsonschema/1-0-0',
    data: {
      email,
    },
  });
}

export function trackPurchase(
  cartItems: CartItem[],
  cartPricing: CartPricing,
  orderId: string,
  shippingAddress: Address,
  billingInfo: BillingInfo,
) {
  const extendedWindow: IWindow = window;
  if (!extendedWindow.letterpress) {
    return;
  }

  const session = store.getState().session;
  extendedWindow.letterpress(
    'addTrans',
    orderId, // order ID
    'Fi', // affiliation or store name
    centsToDollars(cartPricing.totalInCents), // grand total
    centsToDollars(cartPricing.taxInCents ?? 0), // tax amount
    '0', // shipping amount
    shippingAddress.city, // city
    shippingAddress.state, // state or province
    shippingAddress.country, // country
    'USD', // currency
    [
      {
        schema: 'iglu:com.getletterpress/address/jsonschema/1-0-0',
        data: {
          name: `${billingInfo.firstName} ${billingInfo.lastName}`,
          street: shippingAddress.line1,
          street_2: shippingAddress.line2 ?? '',
          zip: shippingAddress.zip,
          city: shippingAddress.city,
          state: shippingAddress.state,
          country: shippingAddress.country,
          email: session?.email ?? '',
        },
      },
    ],
  );

  const productAndVariantsBySku = getProductsAndVariantsBySku(store.getState().config.products);
  const cartItemSkus = cartItems.flatMap((cartItem) => skusForCartItem(cartItem));
  for (const sku of cartItemSkus) {
    const productAndVariant = productAndVariantsBySku.get(sku);
    if (!productAndVariant) {
      continue;
    }

    const { product, variant } = productAndVariant;

    extendedWindow.letterpress(
      'addItem',
      orderId, // order ID
      sku, // SKU
      product.name, // product name
      variant ? variantDescriptionForAnalytics(variant) : '', // category or variation
      centsToDollars(product.priceInCents), // unit price
      '1', // quantity
      'USD', // currency
    );
  }

  extendedWindow.letterpress('trackTrans');
}
