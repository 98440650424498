import * as types from '../types';
import { CouponError, GiftCardError, throwUserFacingOrFallbackError } from './errors';
import { webApiClient } from './fi-api/apiUtils';
import client from './fi-api/client';
import RecurlyProvider from './RecurlyProvider';
import { dollarsToCents, expectUnreachable } from './util';
import { previewSubscriptionQuery } from '../graphql-operations';
import { isForModuleCartItem } from './cart';
import { previewMembershipUpgradeQuery } from '../graphql-operations/MembershipUpgrade.graphql';

interface CartPricingOptions {
  checkoutType?: types.CheckoutType;
  /**
   * Price in the selected shipping code.
   */
  shippingCode?: types.ShippingCode;
  /**
   * Apply taxes according to this address.
   */
  address?: {
    country: string;
    postalCode: string;
  };
  /**
   * Try to apply the specified coupon.
   */
  applyCoupon?: string;
  /**
   * Try to apply the specified referral code.
   */
  applyReferralCode?: string;
  /**
   * Try to apply the specified gift card redemption code.
   */
  applyGiftCard?: string;
  /**
   * True if we want to exclude tax from the pricing calculation (e.g. this is for the cart page before checkout flow)
   */
  excludeTax?: boolean;
}

// Pricing endpoint specific for series 3 upgrade flow. It is different from the default pricing endpoint in that it
// considers prorated refunds when switching plans.
const UPGRADE_CART_PRICING_ENDPOINT = '/api/ecommerce/series3upgradepricing';

// Pricing endpoint for all other checkout types.
const DEFAULT_PRICING_ENDPOINT = '/api/ecommerce/pricing';

function pricingEndpointForCheckoutType(checkoutType: types.CheckoutType): string {
  if (checkoutType === types.CheckoutType.Upgrade) {
    return UPGRADE_CART_PRICING_ENDPOINT;
  }

  return DEFAULT_PRICING_ENDPOINT;
}

async function fetchPricingFromFiApi(cart: types.Cart, options: CartPricingOptions = {}) {
  const shippingCode = options.shippingCode;
  const couponCode = options.applyCoupon ?? cart.couponCode;
  const referralCode = options.applyReferralCode ?? cart.referralCode;
  const redeemedGiftCardCode = options.applyGiftCard ?? cart.redeemedGiftCardCode;
  const cartItems = Object.values(cart.cartItems);
  const endpoint = pricingEndpointForCheckoutType(options.checkoutType ?? types.CheckoutType.Store);

  const { data } = await webApiClient.post<types.CartPricing>(endpoint, {
    excludeTax: options.excludeTax,
    address: options.address,
    couponCode,
    referralCode,
    redeemedGiftCardCode,
    shippingCode,
    cartItems,
  });
  return data;
}

async function fetchPricingForSubscriptionCart(cart: types.Cart): Promise<types.CartPricing> {
  // The subscription checkout flow only allows for purchasing subscriptions for one module at a time
  const cartItem = Object.values(cart.cartItems)[0];
  if (!cartItem || !isForModuleCartItem(cartItem)) {
    return {
      appliedDiscountInCents: 0,
      appliedGiftCardAmountInCents: 0,
      subtotalInCents: 0,
      taxInCents: 0,
      totalInCents: 0,
    };
  }

  const queryResults = await client.query<types.gqlTypes.ECOMMERCE_previewSubscription>({
    query: previewSubscriptionQuery,
    variables: {
      input: {
        moduleId: cartItem.forModuleId,
        sku: cartItem.lineItem.sku,
        redeemGiftCard: cart.redeemedGiftCardCode
          ? {
              redemptionCode: cart.redeemedGiftCardCode,
            }
          : null,
        couponCode: cart.couponCode,
      },
    },
  });

  const pricing = queryResults.data.previewSubscription;
  const giftCardAmountInCents = pricing.giftCardAmountInCents ?? 0;
  const couponAmountInCents = pricing.couponAmountInCents ?? 0;

  return {
    activationFeeInCents: pricing.activationFeeInCents ?? undefined,
    appliedDiscountInCents: couponAmountInCents,
    appliedGiftCardAmountInCents: giftCardAmountInCents,
    subtotalInCents: pricing.totalInCents - pricing.taxInCents + (giftCardAmountInCents + couponAmountInCents),
    taxInCents: pricing.taxInCents,
    totalInCents: pricing.totalInCents,
  };
}

export async function fetchPricingForMembershipUpgrade(cart: types.Cart): Promise<types.CartPricing> {
  // The subscription checkout flow only allows for purchasing subscriptions for one module at a time
  const cartItem = Object.values(cart.cartItems)[0];
  if (!cartItem || !isForModuleCartItem(cartItem)) {
    return {
      appliedDiscountInCents: 0,
      appliedGiftCardAmountInCents: 0,
      subtotalInCents: 0,
      taxInCents: 0,
      totalInCents: 0,
    };
  }

  const queryResults = await client.query<
    types.gqlTypes.ECOMMERCE_previewMembershipUpgrade,
    types.gqlTypes.ECOMMERCE_previewMembershipUpgradeVariables
  >({
    query: previewMembershipUpgradeQuery,
    variables: {
      input: {
        moduleId: cartItem.forModuleId,
        sku: cartItem.lineItem.sku,
      },
    },
  });

  const pricing = queryResults.data.previewMembershipUpgrade;

  return {
    appliedAccountBalanceInCents: pricing.appliedAccountBalanceInCents,
    appliedDiscountInCents: pricing.appliedDiscountInCents,
    subtotalInCents: pricing.subtotalInCents,
    taxInCents: pricing.taxInCents,
    totalInCents: pricing.totalInCents,
    upgradeCreditAmountInCents: pricing.proratedCreditAmountInCents,
  };
}

/**
 * @throws {UserFacingError} if there is an error fetching pricing information
 */
export async function priceCartForPreview(
  cart: types.Cart,
  options: CartPricingOptions = {},
): Promise<types.CartPricing> {
  try {
    return await fetchPricingFromFiApi(cart, {
      ...options,
      excludeTax: true,
    });
  } catch (err) {
    throwUserFacingOrFallbackError(err, undefined /* use the default fallback error */, {
      tags: {
        checkoutStep: 'pricing',
      },
    });
  }
}

/**
 * @throws {UserFacingError} if there is an error fetching pricing information
 */
export async function priceCartForCheckout(
  cart: types.Cart,
  options: CartPricingOptions = {},
): Promise<types.CartPricing> {
  try {
    // For subscription purchase flow, we use a GraphQL query to fetch pricing information
    if (options.checkoutType === types.CheckoutType.Subscription) {
      return await fetchPricingForSubscriptionCart(cart);
    }

    if (options.checkoutType === types.CheckoutType.MembershipUpgrade) {
      return await fetchPricingForMembershipUpgrade(cart);
    }

    return await fetchPricingFromFiApi(cart, options);
  } catch (err) {
    throwUserFacingOrFallbackError(err, undefined /* use the default fallback error */, {
      tags: {
        checkoutStep: 'pricing',
      },
    });
  }
}

/**
 * @deprecated After adding this information to be available from the Fi pricing API, this function will be removed.
 */
export async function fetchGiftCardDetailsFromRecurly(giftCardCode: string): Promise<types.GiftCardDetails> {
  const recurlyPricingProvider = new RecurlyProvider().value.Pricing.Checkout();
  try {
    const giftCardData = await recurlyPricingProvider.giftCard(giftCardCode);
    return {
      redemptionCode: giftCardCode,
      totalValueOfGiftCardInCents: dollarsToCents(giftCardData.unit_amount), // unit_amount is in USD
    };
  } catch (err) {
    throw err.code === 'not-found' ? GiftCardError.notFound(err) : GiftCardError.unknown(err);
  }
}

interface RecurlyAmountDiscount {
  type: 'dollars';
  amount: {
    USD: number;
  };
}

interface RecurlyPercentDiscount {
  type: 'percent';
  rate: number;
}

function discountFromRecurlyDiscount(
  discount: RecurlyAmountDiscount | RecurlyPercentDiscount,
  code: string,
): types.CouponDiscount {
  if (discount.type === 'dollars') {
    return {
      name: code,
      type: 'dollars',
      discountAmountInCents: dollarsToCents(discount.amount.USD),
    };
  } else if (discount.type === 'percent') {
    return {
      name: code,
      type: 'percent',
      percent: discount.rate,
    };
  } else {
    expectUnreachable(discount);
    throw new Error('Unknown discount type');
  }
}

/**
 * @deprecated After adding this information to be available from the Fi pricing API, this function will be removed.
 */
export async function fetchCouponDetailsFromRecurly(couponCode: string): Promise<types.CouponDetails> {
  const recurlyPricingProvider = new RecurlyProvider().value.Pricing.Checkout();
  try {
    const couponData = await recurlyPricingProvider.coupon(couponCode);
    return {
      code: couponCode,
      discount: discountFromRecurlyDiscount(couponData.discount, couponCode),
    };
  } catch (err) {
    throw err.code === 'not-found' ? CouponError.notFound(err) : CouponError.unknown(err);
  }
}
