import React, { useCallback } from 'react';
import { logInternalError } from '../../lib/errors';
import Button from '../Button';
import styles from './PromoCode.module.scss';

interface IPromoCodeFormProps {
  /**
   * Label for the button used to apply the promo code.
   */
  actionLabel: string;
  disabled: boolean;
  onChange: (code: string) => void;
  onSubmit(): Promise<void>;
  pendingCodeValue: string;
  placeholder: string;
}

export default function PromoCodeForm({
  actionLabel,
  disabled,
  onChange,
  onSubmit,
  pendingCodeValue,
  placeholder,
}: IPromoCodeFormProps) {
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(evt.currentTarget.value.trim());
  };

  const formOnSubmit = useCallback(
    (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault();

      // onSubmit should already have its own error handling but we catch and log here for good measure
      onSubmit().catch((err) => {
        logInternalError(err);
      });
    },
    [onSubmit],
  );

  return (
    <form onSubmit={formOnSubmit} className={styles.form}>
      {/* sentry-ignore class is used to unmask the input in replays. Masking is on for all inputs by default */}
      <input
        className="sentry-ignore"
        name="code"
        type="text"
        autoCorrect={'off'}
        autoCapitalize={'off'}
        autoComplete={'off'}
        placeholder={placeholder}
        value={pendingCodeValue}
        onChange={handleChange}
        data-hj-whitelist
      />
      <div className={styles.button}>
        <Button type="submit" disabled={disabled} secondary>
          {actionLabel}
        </Button>
      </div>
    </form>
  );
}
